@import "src/include";
#Detail.company {
    width: 910px;

    .form {

        .field {
              padding: 9px 0 11px 0;
              border-radius: 5px;
              box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
              border: solid 1px #e7e7e7;
              background-color: #ffffff;

              .process_step {
                  position: absolute;
                  top: 18px;
                  right: 920px;
                  display: flex;
                  align-items: center;

                  .circle.green {
                      width: 12px;
                      height: 12px;
                      border-radius: 50%;
                      background-color: #00ae02;
                  }

                  .f_title {
                      margin-left: 10px;
                      font-size: 20px;
                      font-family: Pretendard_bold;
                      white-space: pre;
                  }
              }
        }

        .rows {
          padding: 0 12px 0 15px;
          flex-wrap: wrap;
        }
    }

    .btn_mypage_product {
        width: 100px;
        line-height: 24px;
        text-align: center;
        border: 2px solid var(--color-Web-Orange);
        @include border-radius(16px);
    }
}

#Detail.company,
#Detail.isNew {

    .process_manage {
        margin: 17px 0 13px 0;
        background-color: rgba(29, 153, 230, 0.03);

        .p_menu {
            padding-left: 20px;
            display: flex;
            align-items: center;
            height: 50px;
            background-color: #f1f9fd;

            i {
                margin-right: 10px;
            }

            p {
                margin-right: 5px;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #1d81ee;
            }
        }

        .ToggleButtonWithSpan {
            span {
                width: 25px;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #1d81ee;
            }

            .ToggleButton {
                border: 1px solid #1ca0e3;
                background-color: #ffffff;

                .dialog-button {
                    top: 1px;
                }

                .off {
                    left: 0;
                    background-color: #1ca0e3;
                }
            }
            .ToggleButton.on {
                background-color: #1ca0e3;
            }
        }

        .rows:last-child {
            padding-bottom: 15px;
        }
    }
}

#Detail.no_tip {
    .row {
        .title {
            i {
                display: none !important;
            }
        }
    }
}

#Detail.isNew {
    width: 910px;

    .area_box {
        padding: 9px 0 11px 0 !important;
        border: solid 1px #e7e7e7;
        @include border-radius(5px);
        box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
        background-color: #ffffff;

        .header {
            padding: 0 12px 0 15px;

            h2 {
                font-family: Pretendard_bold;
            }
        }

        .rows {
            padding: 0 12px 0 15px;
        }
    }

    #InventionDetail {
        width: 910px;

        > h2 {
            margin-bottom: 8px;
            padding: 0 12px 0 15px;
            font-family: Pretendard_bold;
        }

        .field {
            padding: 0 12px 0 15px;

            > h2 {
                font-family: Pretendard_bold;
            }

            .rows {
                padding: 0;
            }

        }
    }
}

#Detail {
    margin: auto;
    padding-top: 40px;
    width: 880px;

    > .header {
        position: relative;
        margin-bottom: 29px;
        min-height: 120px;

        > .title {
            margin-bottom: 11px;
            font-size: 22px;
        }

        .info {
            position: relative;
            font-size: 16px;

            .manage_no {
                display: flex;
                align-items: center;

                i {
                    margin-left: 10px;
                }
            }

            .option {
                margin-top: 10px;
                display: flex;
                align-items: center;

                .link_tab {
                    display: flex;

                    li {
                        a {
                            padding: 0 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            min-width: 110px;
                            height: 30px;
                            border-radius: 5px;
                            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                            background-color: #ffffff;

                            .title {
                                font-size: 14px;
                                font-family: Pretendard_bold;
                            }
                        }
                    }
                    li+li {
                        margin-left: 10px;
                    }
                }
            }

            .state {
                position: relative;
                margin-top: 10px;
                padding: 0 6px;
                min-width: 106px;
                line-height: 24px;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                @include border-radius(16px);
                background-color: #00ae02;
                cursor: pointer;

                .icon_more {
                    position: absolute;
                    top: 0;
                    right: -24px;
                    width: 24px;
                    height: 24px;
                }
            }

            > ul {
                position: absolute;
                top: 100%;
                height: 400px;
                overflow-x: hidden;
                overflow-y: scroll;
                background-color: #ffffff;
                z-index: 9;

                button {
                    display: block;
                    width: 120px;
                    height: 32px;
                    font-size: 12px;
                    border: solid 1px #d8d8d8;
                    background-color: #ffffff;
                }
                button+button {
                    border-top: none;
                }

                button:hover {
                    background-color: rgba(0, 149, 174, 0.2);
                    transition-duration: 0.5s;
                }
            }
        }

        .btns {
            position: absolute;
            top: 36px;
            right: 0;
            float: right;
            display: flex;
            align-items: center;
            z-index: 2;

            button {
                font-size: 16px;
                color: #ffffff;
            }
            .btn_edit {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #96c719;
            }
            .btn_save {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #19c77c;
            }
            .btn_cancle {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #96c719;
            }
            .btn_add {
                margin-left: 8px;
                width: 120px;
                line-height: 32px;
                background-color: var(--color-Alizarin-Crimson);
            }
            .btn_add_category {
                width: 120px;
                line-height: 32px;
                border: solid 1px var(--color-Alizarin-Crimson);
                color: #000000;
                background-color: #ffffff;
            }
            ul {
                position: absolute;
                right: 0;
                bottom: -34px;
                width: 120px;
            }
        }

        .tip {
            position: absolute;
            top: 85px;
            right: 0;
            display: flex;

            i {
                margin-right: 10px;
            }
        }
    }

    .form {
        clear: both;

        #InventionDetail {
            margin: 0;
            padding-top: 0;

            > h2 {
                font-size: 22px;
            }

            select {
                margin-right: 0;
            }
        }

        .field {
            position: relative;

            .header {
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: auto;

                > .title {
                    margin: 0;
                    display: flex;
                    font-size: 22px;

                    i {
                        margin-left: 10px;
                    }
                }
            }
        }

        .field+.field {
            margin-top: 61px;
        }

        .field.company {
            .header {
                padding: 0 12px 0 15px;
            }
        }

        .rows {
            display: flex;

            .row {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 283px;
                min-height: 45px;
                font-size: 14px;
                border-bottom: solid 1px #e7e7e7;

                .title {
                    display: flex;
                    align-items: center;
                    white-space: pre;
                    font-family: Pretendard_bold;

                    i.icon_info {
                        display: none;
                        margin-left: 4px;
                        width: 24px;
                        height: 24px;
                        background: url("../../../../assets/images/common/icon-badge-information-black.png");
                    }

                    i.icon_info:hover:after {
                          content: attr(data-content);
                          position: absolute;
                          white-space: pre-line;
                          padding: 4px 8px;
                          top: 100%;
                          left: 0;
                          min-width: 50%;
                          max-width: 392px;
                          line-height: 22px;
                          font-size: 12px;
                          font-style: normal;
                          color: white;
                          z-index: 2;
                          border-radius: 9px;
                          background-color: rgba(0, 0, 0, 0.85);
                    }
                }

                > p {
                  max-width: 150px;
                  word-break: break-word;
                  //overflow: hidden;
                  //text-overflow: ellipsis;
                  //display: -webkit-box;
                  //-webkit-line-clamp: 2;
                  //-webkit-box-orient: vertical;
                }

                .icon_badge_del {
                  margin-left: 15px;
                }

                .btns {
                    //padding-right: 25px;
                    display: flex;
                    align-items: center;

                    span {
                        white-space: pre;
                    }

                    .date_txt {
                        margin-right: 5px;
                        width: 40px;
                        line-height: 24px;
                        font-size: 12px;
                        color: #ffffff;
                        text-align: center;
                        @include border-radius(16px);
                        background-color: var(--color-Niagara);
                    }
                    button {
                        margin-left: 5px;
                    }
                    .ToggleButton {
                        margin-left: 10px;
                    }
                }

                .btns.prior {
                    .ToggleButtonWithSpan {
                        margin: 0;

                        span {
                            margin: 0;
                        }
                    }
                }
                .ToggleButtonWithSpan {
                    span {
                        font-size: 14px;
                    }
                }
                input {
                    padding: 0 8px;
                    width: 135px;
                    height: 30px;
                    font-size: 14px;
                    text-align: left;
                    border: solid 1px rgba(0, 0, 0, 0.56);
                }
                input.long {
                    width: 480px !important;
                }
                select {
                    //margin-right: 25px;
                    padding-right: 20px;
                    width: 135px;
                    height: 32px;
                    font-size: 14px;
                    white-space: pre-wrap;
                }
                .btn_order {
                    //margin-right: 25px;
                    width: 121px;
                    height: 30px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    color: #ffffff;
                    background-color: var(--color-Niagara);
                }
                .icon_plus {
                    margin-left: 15px;

                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
            }

            .row:hover .title > i{
                display: block;
            }

            .row.no_data {
                border-bottom: none;
            }

            .row.colspan2 {
                justify-content: space-between;
                width: 580px;

                .title {
                    width: 100px;
                }
                p {
                    width: 450px;
                    max-width: 450px;
                }
            }

            .row.colspan3 {
                width: 870px;

                input.long {
                    width: 720px !important;
                }
            }

            .row+.row {
                margin-left: 15px;
            }

            .row.disable {
                opacity: .3;
                pointer-events: none;
            }

            .row.translate {
                font-family: Pretendard_bold;
            }
        }

        .rows.inventor {
            .row:nth-child(3n + 1) {
                margin-left: 0;
            }
        }
    }

    .form.hidden_download {
        .icon_download {
            display: none;
        }
    }

    .btn_remove {
        width: 120px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: var(--color-Alizarin-Crimson);
    }

    .not_editing {
        display: none;
    }

    .no_click {
        pointer-events: none;
        opacity: 0.4;
    }

    .icon_upload,
    .icon_download,
    .icon_download_attached {
        min-width: 24px;
        height: 24px;
    }
}
