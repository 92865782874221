.category_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //width: 1280px;
  //width:100%;
  margin:0 50px;
  border-bottom: solid 1px #f0f0f0;
  height: 47.5px;
  //margin: 0 auto;
  .links {
    display: flex;
    align-items: center;
    padding-right: 30px;
    a {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding-right: 20px;
      font-family: Pretendard_semiBold;
      span {
        padding-right: 8px;
      }
      i {
        width: 18px;
        height: 18px;
      }
    }
    .btn_my {
      color: #4593f5;
    }
    .btn_write {
      color: #343434;
    }
  }
  p {
    padding-left: 28px;
    display: flex;
    align-items: center;
    font-family: Pretendard_bold;
    font-size: 12px;
    line-height: 2;
    color: #343434;
  }
}
