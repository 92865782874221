.area {
    .header {
        .btns {
            display: flex;

            button + button {
                margin-left: 8px;
            }
        }

        button, a {
            background-color: #96c719;
        }

        .btn_del {
            width: 100px;
        }
    }

    button, a {
        width: 64px;
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
    }

    .btn_save {
        background-color: #19c77c !important;
    }

    .btn_del {
        background-color: #e92f2c !important;
    }
}
