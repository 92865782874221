@import "src/mixin";

#SignupTypeFindBiz {
  margin: auto;
  padding-top: 50px;
  max-width: 1280px;

  @include mobile {
    padding-top: 60px;
  }

  .page_title {
    margin: 0 auto 30px auto;
    max-width: 540px;
    font-size: 30px;
    font-family: Pretendard_extraBold;
    color: var(--color-Cloud-Burst);

    @include mobile {
      margin: 30px 0 6px 0;
      font-size: 24px;
      text-align: center;
    }
  }

  .flex {
    column-gap: 70px;
  }

  .wrap_type {
    margin: auto;
    padding: 30px;
    width: 540px;
    height: 380px;
    border: 1px solid #f0f0f0;

    @include mobile {
      padding: 0 5%;
      width: 100vw;
      height: auto;
      border: none;
    }

    .type_head {
      margin-bottom: 30px;
      font-size: 18px;
      font-family: Pretendard_bold;
      text-align: center;
      color: #343434;

      @include mobile {
        font-size: 12px;
        color: var(--color-Dusty-Gray);
      }
    }

    .wrap_box {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;

      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 230px;
        height: 250px;
        border-radius: 8px;
        box-shadow: 0 2px 3px 1px #d8d8d8;
        background-color: #ffffff;

        @include mobile {
          width: 50%;
          height: 50vw;
        }

        i[class^="icon"] {
          margin-bottom: 24px;
          width: 65px;
          height: 60px;
          background-size: contain;

          @include mobile {
            width: 47px;
            height: 42px;
          }
        }

        .icon_user_outline {
          background: url("../../../assets/images/login/icon-user-outline-blue.svg") no-repeat;
        }

        .icon_business_outline {
          background: url("../../../assets/images/login/icon-business-outline.svg") no-repeat;
        }

        h2 {
          margin-bottom: 7px;
          font-size: 18px;
          font-family: Pretendard_bold;
          line-height: 26px;
          color: #343434;

          @include mobile {
            font-size: 14px;
          }
        }

        p {
          font-size: 14px;
          font-family: Pretendard_bold;
          color: var(--color-Scorpion);

          @include mobile {
            font-size: 12px;
          }
        }

        .desc_text {
          margin-top: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          width: 200px;
          height: 26px;
          padding: 4px 12px;
          border-radius: 14px;
          background-image: linear-gradient(to right, #4593f5, #6bd3ff);
          font-family: Pretendard_semiBold, sans-serif;
          font-size: 16px;
          letter-spacing: 1px;
          @include mobile{
            width: 90%;
            font-size: 0.6em;
          }
          span {
            font-family: Pretendard_bold, sans-serif;
          }
        }


      }
    }
  }

  .btn_login {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    max-width: 450px;
    height: 50px;
    font-size: 16px;
    font-family: Pretendard_bold;
    color: #ffffff;
    background-color: var(--color-Dodger-Blue);
  }

  .bottom_button_wrap {
    margin: 22px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: 14px;
      color: #959595;

      @include mobile {
        font-size: 12px;
      }
    }

    .line {
      margin: 0 18px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background-color: #dadada;
    }
  }
}
