@import "src/mixin";
@import "src/include";

#IntroService {
  //margin: auto;
  //max-width: 1280px;
  width: 100%;

  @include mobile {
    padding: 0;
  }
  .banner_top {
    width: 100%;
    margin-bottom: 60px;
    padding : 40px;
    @include mobile {
      margin-bottom: 0px;
    }
  }

  .gov_project{

    max-width: 1200px;
    margin:0 auto;
    padding: 60px 0 0 0;

    .work_tit {
      font-size: 28px;
      color: #343434;
      margin-bottom: 24px;

      @include mobile {
        font-size: 18px;
      }
    }

    .list {
      //width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      // margin: 30px 50px 0 50px;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 24px;
      margin-bottom: 60px;

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    .item {
      position: relative;
      padding: 0 40px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      //width: 293px;
      height: 100px;
      border-radius: 5px;
      box-shadow: 0 2px 3px 1px #d8d8d8;
      background-color: #fff;
      cursor: pointer;

      @include mobile {
        margin: auto;
        justify-content: space-between;
        width: 100%;
        height: 75px;
        justify-content: unset;
        border-radius: 15px;
        background-color: #fff;
      }

      .txt_area {
        display: flex;
        align-items: center;
        column-gap: 10px;

        @include mobile {
          row-gap: 10px;
          align-items: center;

          h2 {
            width: 100px;
            font-size: 24px;
            font-family: Pretendard_extraBold;
            color: var(--color-Tarawera);

            @include mobile {
              @include text-gradient-green;
              background-color:#4593F5;
              background-image: none;

            }
          }

          p {
            font-size: 14px;
            font-family: Pretendard_medium;
            color: #343434;
          }
        }
      }

      h2 {
        font-size: 30px;
        color: var(--color-Cornflower-Blue);

        @include mobile {
          font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        font-family: NanumSquareOTF_acB;

        @include mobile {
          font-size: 10px;
        }
      }

      button {
        width: 12px;
        height: 12px;
        position: absolute;
        right: 5%;
        background-color: #fff;
        
        >img{
          width: 10px;
        }
      }
    }
  }

  .upload_area {
    //margin: 30px auto 0 auto;
    //max-width: 100%;
    width: 1200px;
    //margin:30px 50px 0 50px;
    margin: 0 auto 60px;

    @include mobile {
      width: 100%;
      padding: 0 16px;
    }

    .work_tit {
      font-size: 28px;
      color: #343434;
      margin-bottom: 24px;

      @include mobile {
        font-size: 28px;
      }
    }

    .title {
      margin-bottom: 20px;
      font-size: 22px;
      font-family: NanumSquareOTF_acB;

      @include mobile {
        margin: 0 auto 20px auto;
        width: 90%;
        font-family: NanumSquareOTF_acEB;
      }
    }

    .upload_list {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 100%;

      .upload_item {
        padding: 0 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        row-gap: 10px;
        // width: 1800px;
        height: 140px;
        border-radius: 10px;
        background-color: #ffffff;
        border: 1px solid #bebebe;
        position: relative;

        @include mobile {
          padding: 0 20px;
          flex-direction: column;
          justify-content: center;
          z-index: 10;
        }

        >p {
          color: #1d334e;
          font-size: 22px;
          font-weight: 800;
          position: relative;
          z-index: 60;
          max-width: calc(100% - 220px);
          text-overflow: ellipsis;
          overflow: hidden;

          @include mobile {
            max-width: none;
            word-break: break-all;
            overflow: inherit;
            text-overflow: inherit;
            font-size: 20px;
          }
        }
      }

      .images {
        opacity: 0.3;
        position: absolute;
        left: 25px;
        z-index: 6;
      }

      @include mobile {
        margin: auto;
        justify-content: center;
        flex-direction: column;
        row-gap: 5px;
        padding: 0 8.5px 0 14.5px;
        width: calc(90vw + 2px);
        background-image: none;
        border-radius: 15px;
        // box-shadow: 0 2px 4px 0 #d8d8d8;
        // border: solid 1.3px #0078f1;
        // background-color: #f3f9ff;
        position: relative;

        img {
          position: absolute;
          left: 2%;
        }
      }
    }

    p {
      // width: calc(100% - 180px);
      font-size: 22px;
      // font-family: NanumSquareOTF_acEB;
      color: #606060;
      font-size: 14px;
      font-weight: normal;
      // position: absolute;
      // left: 47px;

      @include mobile {
        width: 100%;
        font-size: 18px;
      }
    }

    .btn_pdf_download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 125px;
      height: 55px;
      border-radius: 50px;
      background-color: #4593f5;
      color: #FFFFFF;
      font-size: 16px;

      i {
        margin-left: 10px;
      }

      @include mobile {
        // margin-left: auto;
         width: 264px;
         height: 43px;
        // height: 32px;
        // font-size: 12px;
        // margin-top: 10px;
        z-index: 10;
        i {
          margin-left: 5px;
        }
      }
    }
  }
}