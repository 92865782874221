@import "src/include";

#BannerFindX {
    position: relative;
    display: flex;
    width: 100%;
    height: 280px;
    background: url("../../../assets/images/common/bg_main_hadns.png") no-repeat;
    background-size: cover;

    .bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 280px;
        background-image: linear-gradient(to right, #002657, #007bab 77%, #00c6f5);
        opacity: 0.61;
    }

    .wrap {
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: 960px;
        z-index: 1;

        .inner {
            position: relative;

            > img {
                margin-bottom: 25px;
            }

            h2 {
                margin-bottom: 2px;
                font-size: 22px;
                font-family: Pretendard_extraBold;
                line-height: 25px;
                color: #ffffff;
            }

            p {
                margin-bottom: 20px;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
            }

            .search_form {
                display: flex;
                align-items: center;

                input {
                    padding: 0 14px;
                    width: 445px;
                    height: 45px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.87);
                    border: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                input:focus {
                    outline: none;
                }

                input::placeholder {
                    color: #343434;
                }

                button {
                    width: 65px;
                    height: 45px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    background-color: #1bb3f3;
                    background-position: center;
                }
            }
        }

        .links {
            position: absolute;
            left: 85%;
            bottom: 6px;
            display: flex;
            align-items: center;
            column-gap: 22px;

            .link_br {
                width: 2px;
                height: 14px;
                background-color: #ffffff;
            }
        }

        .wrap_image {
            position: absolute;
            width: 960px;
            height: 100%;
            pointer-events: none;

            .icon_findx {
                position: absolute;
                top: 26px;
                right: 0;
            }
        }
    }
}
