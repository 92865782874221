#MarketInfoUpdateChart {
  //width: 1280px;
  //margin: 0 auto;
  width:100%;
  padding:0 50px;
  display: flex;
  position: relative;
  .chart_section {
    padding: 44px 0;
    //width: 1080px;
    width:100%;
    header {
      color: #1d334e;
      h2 {
        font-family: Pretendard_extraBold;
        font-size: 30px;
        letter-spacing: -1px;
        margin-left: 28px;
      }
      h5 {
        font-family: Pretendard_bold;
        font-size: 16px;
        margin: 10px 0 0 40px;
      }
    }
    section {
      padding: 0 50px 0 55px;
      article {
        margin: 27px 0 ;
      }
      .article_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p:nth-child(1) {
          font-family: Pretendard_bold;
          font-size: 22px;
          color: #4593f5;
        }
      }
    }

    .update_chart_wrapper {
      .update_chart_header {
        p:nth-child(2) {
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
          font-family: Pretendard_bold;
          font-size: 12px;
          color: #606060;
        }
      }
      .graph_input {
        display: flex;
        margin-top: 16px;
        color: #343434;
        p {
          min-width: 120px;
          margin-top: 12px;
          font-family: Pretendard_extraBold;
          font-size: 14px;
        }
        input[type="text"] {
          //width: 851px;
          width:100%;
        }
        .graph_section {
          //width: 851px;
          width:100%;
          height: 400px;
          border-radius: 4px;
          border: solid 1px #d8d8d8;
        }
      }
    }
    .chart_data_wrapper {
      //width: 980px;
      width:100%;
    }
    .chart_data_header {
      margin-bottom: 12px;
      .chart_add_btn_wrapper {
        display: flex;
        align-items: center;
      }
      button {
        height: 40px;
        border-radius: 4px;
        background-color: #4593f5;
        font-family: Pretendard_bold;
        font-size: 14px;
        padding: 10px 22px;
        color: white;
        margin-left: 10px;
      }
      .chart_stacked_btn {
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 4px;
        background-color: #4593f5;
        padding: 10px 22px;
        margin-left: 10px;
        cursor: pointer;
        span {
          font-family: Pretendard_bold;
          font-size: 14px;
          color: white;
        }
      }
      input[type="checkbox"] {
        display: none;
      }
    }
    div.chart_data_item:nth-child(odd) {
      background-color: rgba(240,240,240,0.2);
    }
    .chart_data_body {
      width: 1025px;
      padding: 5px 0 5px 14px;
      .chart_data_item {
        display: flex;
        padding: 5px 0;
        &_aside {
          .graph_title,
          .graph_color {
            display: flex;
            align-items: center;
          }
          .graph_title {
            input[type="text"] {
              width: 138px;
              height: 24px;
            }
          }
          .graph_color {
            margin-top: 12px;
            p {
              font-family: Pretendard_extraBold;
              font-size: 14px;
              color: #343434;
            }
            .show_color {
              margin: 0 8px;
            }
            button {
              height: 24px;
              border-radius: 14px;
              background-color: rgba(107,211,255,0.2);
              font-family: Pretendard_extraBold;
              font-size: 12px;
              color: #4593f5;
              padding: 3px 8px;
            }
            .color_popover {
              position: absolute;
              z-index: 2;
              top: 30px;
              .color_cover {
                position: fixed;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
              }
            }
          }
          .graph_type {
            display: flex;
            margin-top: 5px;
            button {
              height: 24px;
              border-radius: 14px;
              font-family: Pretendard_extraBold;
              font-size: 11px;
              padding: 3px 5px;
              color: #FFFFFF;
            }
            button:nth-child(1) {
              background-color: #10ac84;
            }
            button:nth-child(2) {
              background-color: #2e86de;
            }
          }
        }
        &_body {
          width: 950px;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          row-gap: 5px;
          margin-left: 12px;
          .column_title {
            display: flex;
            align-items: center;
            input[type="text"] {
              width: 120px;
              height: 24px;
            }
          }
          .column_data {
            width: 150px;
            margin-top: 4px;
          }
        }
      }
      .show_color {
        width: 15px;
        height: 15px;
      }
    }
    .graph_content_body {
      display: flex;
      margin: 14px 0 14px 8px;
      div {
        min-width: 110px;
        p {
          font-family: Pretendard_bold;
          font-size: 14px;
          color: #343434;
        }
        button {
          height: 24px;
          border-radius: 14px;
          background-color: rgba(107,211,255,0.2);
          font-family: Pretendard_extraBold;
          font-size: 12px;
          color: #4593f5;
          padding: 3px 12px;
          margin-top: 5px;
        }
      }
      textarea {
        //width: 858px;
        width:100%;
        height: 277px;
        border-radius: 4px;
        padding: 12px 0 0 9px;
        color: #959595;
        font-family: Pretendard_semiBold;
        font-size: 14px;
      }
    }
    footer {
      border-top: inherit;
      margin: 84px 0 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 40px;
        font-family: Pretendard_bold;
        font-size: 14px;
        color: #fff;
        margin-right: 24px;
        border-radius: 4px;
        padding: 10px 22px;
      }
      .graph_image {
        background-color: #6bd3ff;
      }
      .graph_cart {
        background-color: #4593f5;
      }
    }
  }
  aside {
    //position: absolute;
    right: -100px;
    width: 342px;
    padding: 53px 25px;
    color: #343434;
    h2 {
      font-family: Pretendard_extraBold;
      font-size: 20px;
    }
    .aside_body {
      margin: 16px 0;
      padding: 16px 0;
      border-top: 1px solid #f0f0f0;
      .aside_body_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h5 {
          font-family: Pretendard_bold;
          font-size: 20px;
        }
        button {
          height: 32px;
          font-family: Pretendard_bold;
          font-size: 12px;
          color: #fff;
          padding: 6px 12px;
          background-color: #4593f5;
          border-radius: 4px;
        }
      }
      .default_graph {
        margin: 9px 0;
        width: 290px;
        height: 175px;
        border-radius: 4px;
        border: solid 1px #d8d8d8;
      }
    }
    .aside_footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      button {
        width: 280px;
        height: 42px;
        font-family: Pretendard_extraBold;
        font-size: 16px;
        text-align: center;
        color: #fff;
        border-radius: 8px;
        margin-top: 10px;
      }
      button:nth-child(1) {
        background-color: #6bd3ff;
      }
      button:nth-child(2) {
        background-color: #4593f5;
      }
      button:nth-child(3) {
        background-color: #00cec9;
      }
    }
  }
}
