@import "src/include";

#consultComMain{
    width: 1280px;
    margin: 0 auto;
    .consultComTitle {
        background-color: rgba(107,211,255,0.14);
        height: 180px;
        color: #1d334e;
        padding: 37px 0 0 45px;
        p:nth-child(1) {
            font-family: Pretendard_extraBold;
            font-size: 30px;
        }
        p:nth-child(2) {
            font-family: Pretendard_bold;
            font-size: 16px;
            margin-top: 19px;
        }
    }
    .sitemap_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        border-bottom: solid 1px #f0f0f0;
        padding: 0 30px;
        .sitemap {
            display: flex;
            align-items: center;
            font-family: Pretendard_bold;
            font-size: 12px;
            color: #343434;
        }
        .consult_nav {
            display: flex;
            p {
                display: flex;
                align-items: center;
                font-family: Pretendard_bold;
                font-size: 14px;
                margin-left: 20px;
                i {
                    margin-left: 10px;
                }
            }
            p:nth-child(1) {
                color: #4593f5;
                i {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    .consultComMain {
        display: flex;

        .consult_content_wrapper {
            width: 1060px;
        }
        .consult_tab {
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Pretendard_bold;
                font-size: 16px;
                color: #343434;
                width: 50%;
                border-bottom: solid 1px #f0f0f0;
                padding: 17px 0;
            }
            .tab_focus_on {
                border-bottom: solid 3px #4593f5;
                padding: 15px 0;
            }
        }
        .consult_list_sort {
            display: flex;
            align-items: center;
            width: 200px;
            height: 28px;
            background:url('../../../assets/images/common/icon-expand-more-gray.svg') no-repeat;//
            select {
                width:100%;
                height:100%;
                padding:0 20px 0 40px;
                font-size: 15px;
                border:0; //기본 스타일 제거
                -webkit-appearance:none; /* for chrome */
                -moz-appearance:none; /*for firefox*/
                appearance:none;
                box-sizing: border-box;//select 박스의 크기 방식 지정.
                background:transparent;//배경색 투명 처리
            }
        }
        .consult_search_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            .search_bar {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 270px;
                height: 40px;
                border-radius: 20px;
                border: solid 2px #4593f5;
                background-color: #fff;
                input[type="text"] {
                    width: 201px;
                    height: 24px;
                    border: none;
                }
            }
        }
        .consult_items {
            background-color: rgba(240,240,240,0.2);
            padding: 16px 0 16px 20px;
        }
        .consult_item {
            display: flex;
            border-radius: 5px;
            border: solid 1px #f0f0f0;
            background-color: #fff;
            width: 1010px;
            margin: 10px 0;
            img {
                width: 220px;
                height: 146px;
                border-radius: 5px;
            }
        }
        .consult_item_content {
            display: flex;
        }
        .content_data {
            width: 640px;
            padding: 10px;
            div {
                display: flex;
                margin-bottom: 10px;
            }
            div:nth-child(1) {
                font-family: Pretendard_bold;
                font-size: 16px;
                color: #343434;
            }
            div:nth-child(2) {
                font-family: Pretendard_semiBold;
                font-size: 13px;
                color: #4593f5;
            }
            p {
                font-family: Pretendard_semiBold;
                font-size: 14px;
                min-width: 100px;
                color: #606060;
            }
            span {
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
                margin-bottom: 35px;
            }
            &_list{
                position: relative;
                display: flex;
                width: 1010px;
                height: 145px;
                justify-content: flex-start;
                align-items: center;
                background-color: #fff;
                margin-bottom: 10px;
                border-radius: 5px;
                border: solid 1px #f0f0f0;
                &:last-of-type{
                    margin-bottom: 0;
                }
                &_img{
                    width: 255px;
                    height: 100%;
                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width : 100%;
                        height: 100%;
                    }
                    img{
                        max-width: 185px;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &_text{
                    padding: 12px 10px;
                    width: 80%;
                    height: 100%;
                    &_title{
                        font-family: Pretendard_bold;
                        font-size: 16px;
                        color: #343434;
                        margin-bottom: 10px;
                    }
                    .table_list{
                        display: flex;
                        flex-direction: column;
                        .table_list_field {
                            color: #4593F5;
                            font-family: Pretendard_semiBold;
                            font-size: 13px;
                            padding-bottom: 3px;
                        }
                        .table_list_item {
                            display: flex;
                            padding-top: 8px;
                        }
                        .table_list_title {
                            width: 100px;
                            font-family: Pretendard_semiBold;
                            font-size: 14px;
                            color: #606060;
                            padding-left: 5px;
                            border-left: 1px solid #f0f0f0;
                        }
                        .table_list_info {
                            font-family: Pretendard_bold;
                            font-size: 14px;
                            letter-spacing: -0.47px;
                            color: #343434;
                        }
                    }
                    .btnMore{
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 117px;
                        height: 32px;
                        right: 19px;
                        bottom: 13px;
                        font-family: Pretendard_bold;
                        font-size: 12px;
                        color: #fff;
                        background-color: #4593f5;
                        border-radius: 4px;
                    }
                }
            }
            &_pagination-wrap{
                margin-top: 68px;
            }
        }
        .content_icon {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            padding: 14px 0;
            i {
                cursor: pointer;
            }
            button {
                width: 128px;
                height: 32px;
                border-radius: 4px;
                font-family: Pretendard_bold;
                font-size: 12px;
                background-color: #4593f5;
                color: #FFFFFF;
            }
        }
    }
}
