#ProductSelectPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    height: 500px;
    padding: 43px 61px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 22px;
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_confirm {
                margin-right: 20px;
                width: 120px;
                line-height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                background-color: #0095ae;
            }
        }
    }


    .wrap_table {
        height: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    table {
        thead {
            th {
                text-align: left;

                .checkbox {
                    input:checked ~ .checkmark {
                        background-color: #ffffff !important;
                    }
                    .checkmark:after {
                        width: 5px;
                        height: 10px;
                        border: solid black;
                        border-width: 0 3px 3px 0;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    text-align: left;
                }
            }
        }
    }

    .search {
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            margin-right: 20px;
            padding-left: 20px;
            width: 510px;
            height: 32px;
            font-size: 14px;
            border: solid 0.5px rgba(0, 0, 0, 0.54);
        }

        input::placeholder {
            color: rgba(0,0,0,0.56);
        }

        .keyword_list {
            position: absolute;
            width: 510px;
            max-height: 300px;
            padding: 10px 22px 0 22px;
            border: solid 1px #d8d8d8;
            border-top: none;
            background-color: #ffffff;
            overflow-y: scroll;

            li {
                line-height: 36px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
                border-bottom: solid 1px #e7e7e7;
                cursor: pointer;
            }

            li:hover {
                font-family: Pretendard_bold;
                color: var(--color-Web-Orange);;
            }
        }
    }

    .checkbox {
        .checkmark {
          margin-right: 0;
        }
    }
}
