#ResearchTechSiteMap {
  //width: 1280px;
  //width: 100vw;
  margin: 0 50px 0 50px;
  height: 48px;
  //margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: end;
  border-bottom: solid 1px #f0f0f0;
  button {
    display: flex;
    align-items: center;
    width: 170px;
    font-family: Pretendard_bold;
    font-size: 14px;
    background-color: #fff;
    i {
      margin-left: 8px;
    }
  }
  .btn_go_my {
    color: #4593f5;
  }
  .btn_go_request {
    color: #343434;
  }
}
