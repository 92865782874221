#AdminIntegrationTaskList {
  padding-top: 40px;

  .head {
    margin-bottom: 34px;

    .title {
      font-size: 22px;
      font-family: Pretendard_extraBold;
    }
  }

  .btns_order {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
  }

  .btn_order {
    width: 20px;
  }
}
