#MobileMore {
    padding-top: var(--mobile-header-height);
    height: var(--mobile-min-content-height);
    background-color: var(--color-Gallery-opacity-02);

    .account_area {
        padding: 22px 15px;
        width: 100%;
        height: 105px;
        background-color: var(--color-Piction-Blue);

        .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        p {
            display: flex;
            align-items: baseline;
            column-gap: 15px;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            color: #ffffff;
        }

        .btn_change {
            margin: 15px 0 0 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 105px;
            height: 24px;
            border: 2px solid #ffffff;
            border-radius: 12px;
            background-color: var(--color-Piction-Blue);

            p {
                font-size: 12px;
                font-family: Pretendard_extraBold;
            }

            i {
                width: 16px;
                height: 16px;
                background-size: contain;
            }
        }
    }

    .btns {
        margin: 10px 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 45px;
            font-size: 14px;
            font-family: Pretendard_bold;
            border-radius: 5px;
            border: 1px solid var(--color-Alto);
            background-color: #ffffff;
        }
    }
}
