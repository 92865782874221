#AdminContentList {
    margin: auto;
    width: 780px;
    position: relative;

    > .btns {
        position: absolute;
        top: 41px;
        right: 0;

        > button {
            width: 132px;
            height: 32px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
        }

        .btn_toggle {
            background-color: #4593f5;
        }

        .btn_write {
            background-color: #e92f2c;
        }
    }

    .content {
        display: flex;
        column-gap: 10px;

        img {
            width: 115px;
            height: 65px;
        }
    }

    th:nth-child(1),
    td:nth-child(1) {
        width: 15%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        text-align: left;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 15%;
    }
}
