#InventorList {
  width: 1280px;
  margin: 45px auto;
  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    h2 {
      font-size: 22px;
    }
    button {
      width: 150px;
      height: 32px;
      background-color: #e92f2c;
      border-radius: 4px;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .inventor_search_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin: 20px 0;
    border-radius: 5px;
    border: solid 0.5px #d8d8d8;
    background-color: #fff;
    width: 400px;
    input[type="text"] {
      border: none;
      width: 90%;
    }
  }
  .inventor_list {
    thead {
      background-color: black;
      tr th{
        color: #FFFFFF;
      }
    }
    tbody tr:hover {
      background-color: rgba(197, 223, 248, 0.5);
      cursor: pointer;
    }
  }
}
