#BannerIpnow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(240, 240, 240, 0.2);
  width: 1280px;
  margin: 0 auto;
  .banner_image {
    width: 764px;
  }
  .txt {
    padding-left: 48px;
    .title {
      color: #443018;
      line-height: 1.08;
      margin-bottom: 6px;
      p {
        font-family: NotoSansCJKkr-D;
        font-size: 18px;
      }

      em {
        font-family: NotoSansCJKkr-M;
        font-size: 24px;
      }
    }
    .banner_btn {
      width: 230px;
      height: 50px;
      border-radius: 4px;
      background-image: linear-gradient(to right, #ff7a00 32%, #ffa600);
      color: white;
      font-family: Pretendard_bold;
      font-size: 16px;
      margin-top: 15px;
    }

    .semi_title {
      font-family: NotoSansCJKkr-B;
      font-size: 48px;
      margin-bottom: 11px;
      color: #443018;
      line-height: 1.04;
      letter-spacing: -1.6px;
      span {
        color: #f7830e;
      }
    }

    .s_title {
      font-family: NotoSansCJKkr-D;
      color: #343434;
      font-size: 19px;
      line-height: 1.47;
      letter-spacing: -0.66px;
    }
    .count_wrapper {
      margin-top: 36px;
    }
    .head_text {
      font-family: NotoSansCJKkr-R;
      font-size: 22px;
      letter-spacing: -0.5px;
      color: #443018;
    }
    .count {
      line-height: 1;
      margin-bottom: 6px;
      span:nth-child(1) {
        font-family: NotoSansKR;
        font-size: 40px;
        color: #4792f7;
        font-weight: bold;
        letter-spacing: -2px;
      }
      span:nth-child(2) {
        font-family: NotoSansCJKkr-R;
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #443018;
        line-height: 1.22;
        margin-left: 3px;
      }
      span:nth-child(3) {
        font-family: NotoSansCJKkr-B;
        font-size: 24px;
        color: #f7830e;
      }
    }
    .semi_text {
      font-family: NotoSansCJKkr-R;
      font-size: 18px;
      letter-spacing: -0.5px;
      color: #443018;
      span {
        font-size: 22px;
        font-family: NotoSansCJKkr-B;
      }
    }
  }
}
/*
#BannerIpnow {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 245px;
    height: 229px;
    background-color: #FFF2D9;

    .txt {

        .title {
            font-family: Pretendard_bold;
            font-size: 22px;
            color: #606060;
            margin-bottom: 5px;

            em {
                font-family: Pretendard_extraBold;
                font-size: 24px;
                color: #606060;
            }
        }

        .semi_title {
            font-family: Pretendard_extraBold;
            font-size: 30px;
            margin-bottom: 5px;
            color: #443018;
        }

        img{
            position: relative;
            height: 65px;
            left: -15px;
        }

        .s_title {
            font-family: Pretendard_bold;
            font-size: 18px;
            line-height: 24px;
            color: #343434;
        }
    }
}
*/
