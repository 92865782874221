@import "src/include";
#TaskMyAddList {
  margin: auto;
  padding: 0px 0 68px 0;
  //width: 1280px;
  width: 100%;
  .header_inner_search {
    width: 100%;
    padding: 0 50px 0 50px;
    height: 125px;
    background-color: #f0f8ff;
    .header_keyword {
      display: flex;
      padding: 15px 0 0 36px;
      p {
        display: inline-block;
        text-align: center;
        height: 26px;
        margin-right: 4px;
        border-radius: 14px;
        box-shadow: 0 2px 4px 0 #f0f0f0;
        padding: 7px 12px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
      }
      p:nth-child(odd) {
        color: #0078f1;
        background-color: rgba(47, 244, 78, 0.35);
      }
      p:nth-child(even) {
        color: #fff;
        background-color: #0078f1;
      }
    }
    .search_area {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 0 36px;
      //width: 1190px;
      height: 50px;
      border-radius: 15px;
      box-shadow: 1px 2px 2px 0 #d8d8d8;
      border-style: solid;
      border-width: 2px;
      border-image-source: linear-gradient(to left, #0d7, #0078f1);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff),
        linear-gradient(to left, #0d7, #0078f1);
      background-origin: border-box;
      background-clip: content-box, border-box;
      input[type="text"] {
        width: 870px;
        height: 24px;
        border: none;
      }
      input[type="text"]::placeholder {
        font-family: Pretendard_regular;
        color: #aaaaaa;
      }
      button {
        margin-right: 10px;
      }
    }
  }
  .inner {
    display: flex;
    //align-items: center;
    //margin: 15px 0 15px 28px;
    flex-direction: column;
    margin: 15px 50px 15px 50px;

    h2 {
      font-size: 22px;
      font-family: Pretendard_bold;
    }
  }
  .header {
    padding: 0 50px 0 50px;
    margin-bottom: 15px;
    display: flex;
    justify-content: end;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      width: 200px;
      height: 48px;
      font-size: 16px;
      font-family: Pretendard_bold;
    }
    .btn_go {
      background-color: #fff;
    }
    .btn_mail {
      width: 187px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #f0f0f0;
      background-color: #fff;
      p {
        @include text-gradient-green;
      }
    }
  }

  .list {
    .item {
      .dday {
        font-family: Pretendard_extraBold;
        font-size: 12px;
        border-radius: 5px;
        background-color: #00ba78;
        padding: 3px 8px;
        color: white;
      }
      background-color: rgba(240, 240, 240, 0.14);
      //width: 1060px;
      //width:100%;
      margin: 0 50px;
      //margin: auto;
      .title_area {
        width: 100%;
        padding-top: 22px;
        .post_title {
          display: flex;
          align-items: center;
          justify-content: center;
          h2 {
            margin-left: 9px;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            color: #343434;
          }
          button {
            width: 64px;
            height: 32px;
            font-size: 16px;
            color: #ffffff;
            background-color: #e92f2c;
          }
        }

        .title_state_inner {
          margin-bottom: 8px;

          .desc {
            display: flex;
            align-items: center;
            width: 745px;
          }
        }

        .title_state_inner.endDate {
          background-color: #e92f2c;
        }
      }

      .detail_area {
        width: 100%;
        padding: 25px 0;

        .wrap_data {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          background-color: #fff;
          border-radius: 5px;
          border: solid 1px #f0f0f0;
          //width: 1015px;
          width: 100%;
          //padding: 15px 0 15px 25px;
          padding: 15px 50px 15px 50px;
          margin: 0 auto;
          .datas {
            display: flex;
            justify-content: space-between;
            .data {
              display: flex;
              align-items: center;
              column-gap: 20px;
              width: 28%;
              //width:calc(33% - 338px);
              //width:250px;
              //width:200px;
              min-width: 200px;
              font-size: 14px;
              line-height: 20px;
              border-left: 1px solid #f0f0f0;
              padding-left: 9px;
              h2 {
                min-width: 80px;
                font-family: Pretendard_semiBold;
                color: #606060;
              }
              .dday {
                color: white;
              }
              p {
                color: #343434;
                font-family: Pretendard_bold;
                word-break: keep-all;
              }

              button {
                width: 140px;
                height: 32px;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #ffffff;
                @include border-radius(4px);
              }

              .btn_go {
                background-color: var(--color-Web-Orange);
              }

              .btn_add {
                background-color: #4593f5;
              }
              .amount_color_blue {
                color: #4593f5;
                font-size: 20px;
                font-family: Pretendard_extraBold;
              }
            }

            .data.files {
              .inner {
                p {
                  display: flex;
                  align-items: flex-start;
                  column-gap: 5px;
                  cursor: pointer;

                  i {
                    margin-top: 4px;
                    min-width: 24px;
                  }
                }

                p:nth-child(n + 2) {
                  padding-left: 29px;
                }
              }
            }

            .data.rowspan2 {
              width: 68%;
            }

            .data.rowspan3 {
              width: 100%;
            }
          }
        }
        .contact_wrap_data {
          display: flex;
          flex-direction: column;
          row-gap: 18px;
          background-color: rgba(240, 240, 240, 0.4);
          border-radius: 5px;
          border: solid 1px #f0f0f0;
          //width: 1015px;
          width: 100%;
          //padding: 15px 0 15px 25px;
          padding: 15px 50px 15px 50px;
          margin: 12px auto 24px auto;
          .contact_data {
            display: flex;
            padding-left: 9px;
            column-gap: 20px;
            color: #606060;
            font-size: 14px;
            h2 {
              min-width: 80px;
              letter-spacing: -0.47px;
              color: #606060;
            }
            p {
              font-family: Pretendard_bold;
              color: #343434;
              line-height: 24px;
            }
          }
        }
        .file_wrap_data {
          width: 100%;
          // width: 1015px;
          //padding: 0 0 0 35px;
          padding: 0 50px 0 50px;
          margin: 12px auto 0 auto;
          display: flex;
          .file_data {
            display: flex;
            width: 100%;
            //width: 80%;
            > h2 {
              margin-left: 10px;
              font-size: 14px;
              letter-spacing: -0.47px;
              color: #606060;
            }
          }
          .link_btn {
            //width: 20%;
            display: flex;
            flex-direction: column;
            button {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              border-radius: 15px;
              border: solid 1px #d8d8d8;
              background-color: #fff;
              width: 200px;
              height: 50px;
              margin-top: 7px;
              position: relative;
              span {
                font-family: Pretendard_bold;
                font-size: 16px;
                color: #343434;
                padding-left: 5px;
              }
              > i {
                position: absolute;
                left: 12px;
                top: 12px;
              }
              .fi-rs-calendar-check {
                &::before {
                  font-size: 24px;
                  color: var(--color-Cornflower-Blue);
                }
              }
            }
            button:nth-child(1) {
              margin-top: 0;
            }
          }
          h2 {
            min-width: 80px;
          }
          .inner {
            margin-left: 30px;
            p {
              display: flex;
              align-items: center;
              color: #343434;
              padding-bottom: 15px;
              font-family: Pretendard_bold;
              font-size: 14px;
              cursor: pointer;
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }

      .content_area {
        padding: 0;
        height: 0;
        font-size: 14px;
        overflow: hidden;

        h2 {
          margin-bottom: 10px;
          font-family: Pretendard_bold;
        }

        .content {
          line-height: 25px;
        }
      }

      .btn_toggle {
        margin: 15px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        width: 230px;
        height: 32px;
        font-size: 16px;
        font-family: Pretendard_bold;
        color: #ffffff;
        @include border-radius(16px);
        background-color: #4593f5;
      }
    }

    .item + .item {
      margin-top: 30px;
    }

    .item.animation {
      .content_area {
        padding: 24px 18px;
        height: auto;
      }
    }
  }

  .btn_more {
    margin: 30px auto 0 auto;
    display: block;
    width: 190px;
    height: 40px;
    font-size: 16px;
    font-family: Pretendard_extraBold;
    color: #747474;
    text-align: center;
    @include border-radius(20px);
    border: solid 2px #959595;
    background-color: #ffffff;
  }
}
