#DashBoardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 10px 14px;
  p {
    font-family: GmarketSans;
    font-size: 20px;
    letter-spacing: -0.83px;
    color: #0078f1;
    display: flex;
    align-items: center;
  }
}
