.field+.field {
  margin-top: 60px;
}

.rows {
    display: flex;
    flex-wrap: wrap;

    .row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 283px;
        min-height: 45px;
        font-size: 14px;
        border-bottom: solid 1px #e7e7e7;

        .title {
            display: flex;
            align-items: center;
            white-space: pre;
            font-family: Pretendard_bold;
        }

        > p {
            padding-right: 25px;
            max-width: 150px;
            word-break: break-word;
        }

        .btns {
            padding-right: 25px;
            display: flex;
            align-items: center;

            button {
                margin-left: 5px;
            }

            .ToggleButton {
                margin-left: 10px;
            }
        }

        .btns.prior {

            .ToggleButtonWithSpan {
                margin: 0;

                span {
                    margin: 0;
                }
            }
        }

        .ToggleButtonWithSpan {
            span {
                font-size: 14px;
            }
        }

        input {
            padding: 0 8px;
            width: 135px;
            height: 30px;
            font-size: 14px;
            text-align: left;
            border: solid 1px rgba(0, 0, 0, 0.56);
        }

        input.long {
            width: 450px !important;
        }

        select {
            margin-right: 25px;
            padding-right: 20px;
            width: 135px;
            min-height: 32px;
            font-size: 14px;
            white-space: pre-wrap;
        }
    }

    .row.long {
        width: 588px;

        > p {
            width: 450px;
            max-width: 450px;
        }
    }

    .row.no_data {
        border-bottom: none;
    }

    .row+.row {
        margin-left: 15px;
    }
}
