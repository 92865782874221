@keyframes slidein {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

#UnEventView {
    margin: auto;
    padding-top: 42px;
    width: 970px;

    .event_toast {
        width: 666px;
        height: 45px;
        display: flex;
        align-items: center;
        background-color: #4593f5;
        border-radius: 5px;
        padding: 10px 0 10px 14px;
        justify-content: space-between;
        margin: 0 auto 0 auto;
        position: absolute;
        left: 50%;
        transform:translateX(-50%);
        z-index: 2;
        top: 0;
        animation-duration: 1s;
        animation-name: slidein;
        .event_toast_text {
            display: flex;
            align-items: center;
            p {
                padding-left: 5px;
                font-family: Pretendard_semiBold;
                font-size: 14px;
                color: #fff;
            };
        }
        .event_toast_del {
            display: flex;
            align-items: center;
            padding: 14px 18px 14px 0;
        }
    }
    @media screen and (max-width: 850px) {
        width: 100VW;
    }

    .pc {

        .area_box {
            display: flex;
            flex-direction: column;
            column-gap: 51px;
            width: 100%;
            border-radius: 20px;
            h2 {
                margin-bottom: 9px;
                font-size: 22px;
                font-family: Pretendard_bold;
                color: #343434;
            }

            p {
                font-size: 14px;
                font-family: Pretendard_bold;
                line-height: 20px;
                color: #343434;
            }
        }

        .header {
            margin: 5px 0 25px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                font-size: 14px;
                font-family: Pretendard_semiBold;
                color: #606060;
                span {
                    font-size: 22px;
                    font-family: Pretendard_extraBold;
                    color: #54b7e0;
                }
            }
            button {
                width: 127px;
                height: 40px;
                border-radius: 4px;
                border: solid 1px #d8d8d8;
                background-color: #fff;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
            }
        }

        table {
            table-layout: auto;
            thead {
                background-color: rgba(84, 183, 224, 0.15);
            }
            th {
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
            }
            th:nth-child(1) {
                padding-left: 50px;
                text-align: left;
            }
            td:nth-child(1) {
                width: 790px;
                color: #343434;
                font-size: 14px;
                font-family: Pretendard_semiBold;
                div {
                    padding: 0 21px 0 24px;
                    line-height: 1.57;
                    display: flex;
                    justify-content: left;
                    span {
                        text-align: left;
                    }
                }
            }

            th:nth-child(2) {
                width: 79px;
                text-align: center;
            }

            td:nth-child(2) {
                position: relative;
                width: 79px;
                font-family: Pretendard_semiBold;
                font-size: 14px;
                color: #606060;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                button {
                    position: absolute;
                    right: 17px;
                }
            }
            th:nth-child(3) {
                width: 81px;
            }
            td:nth-child(3) {
                width: 81px;
            }
            tbody {
                tr {
                    min-height: 44px;
                }
                td {
                    cursor: pointer;
                }
            }
        }
    }

    .mo {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: #ffffff;

        > .header {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 60px;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
            background-color: #ffffff;

            .icon_more_arrow_left_black {
                position: absolute;
                left: 20px;
                background-size: contain;
            }

            h2 {
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #443018;
            }
        }
        .read {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px 0 12px;
            background-color: rgba(240, 240, 240, 0.2);
            height: 40px;
            button {
                width: 68px;
                height: 24px;
                border-radius: 9px;
                border: solid 1px #81cae9;
                background-color: #fff;
                font-family: Pretendard_bold;
                font-size: 12px;
                color: #606060;
            }
            p {
                font-family: Pretendard_semiBold;
                font-size: 14px;
                color: #606060;
                span {
                    font-family: Pretendard_extraBold;
                    font-size: 22px;
                    color: #54b7e0;
                }
            }
        }
        .list {
            height: calc(100vh - 60px);
            overflow-x: hidden;
            overflow-y: scroll;
            thead {
                background: rgba(84, 183, 224, 0.15);
                height: 46px;
                th {
                    color: #343434;
                    font-family: Pretendard_bold;
                    font-size: 14px
                }
            }
            th:nth-child(1) {
                width: 80%;
                text-align: left;
                padding-left: 20px;
            }
            th:nth-child(2) {
                width: 20%;
            }
            td:nth-child(1) {
                width: 80%;
            }
            td:nth-child(2) {
                width: 20%;
            }
            .item {
                border-bottom: 1px solid #d8d8d8;
                .item_info {
                    margin-right: 10px;
                    text-align: left;
                    padding-left: 20px;
                    font-size: 12px;
                    font-family: Pretendard_semiBold;
                    h2 {
                        color: #443018;
                        word-break: break-all;
                    }
                    p {
                        font-size: 12px;
                        line-height: 18px;
                        color: #707070;
                    }
                }
            }
        }
    }

    .circle {
        margin-right: 5px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    .circle.new {
        background-color: red;
    }
}
