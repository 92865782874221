@import "src/_mixin";
@import "src/variables";

#registrationPopUp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    .popup {
        margin: auto;
        position: relative;
        top: 15%;
        width: 803px;
        height: 631px;
        border-radius: 21px;
        background-color: #ffffff;
        overflow: hidden;
        padding: 24px;
        
        @media (min-width: 0) and (max-width: #{$breakpoint-tablet - 1px}) {
            top: 5%;
            width: 90%;
            height: fit-content;
        }
      
        @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
            top: 5%;
            width: 90%;
            height: 80%;
        }

        section{
            color:  #000;
            text-align: center;
            padding: 0  0 24px 0;
            
            h1{ 
                font-size: 30px;
                span{
                    color: #2F58FF;
                }
                @media (min-width: 0) and (max-width: #{$breakpoint-tablet - 1px}) {
                    font-size: 25px;
                }   
            }

            p{
                font-size: 24px;
                span{
                    color: #2F58FF;
                }
                @media (min-width: 0) and (max-width: #{$breakpoint-tablet - 1px}) {
                    font-size: 20px;
                } 
            }
        }

        header{
            padding:24px 0;
            border-bottom: 1px solid #000; 
        }

        > body{
            padding:24px 0; 

            section{
                padding: 24px 0 0 0;
            }
            section:last-child{
                padding:48px 0 0 0; 
            }

            button{
                width: 376px;
                height: 70px;
                border-radius: 50px;
                font-size: 24px;
                font-weight: 400;
                color: #fff;
                background: #2F58FF;
                cursor: pointer;
                @media (min-width: 0) and (max-width: #{$breakpoint-tablet - 1px}) {
                    width: 100%;
                }
            }
        }
    }
}