@import "src/include";

#CalcAnnualFeePopup {
    position: fixed;
    background-color: #ffffff;
    z-index: 9999;

    @media screen and (min-width: 850px){
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1280px;
        width: 90%;
        max-height: 800px;
        padding: 25px 39px;
        border: solid 1px #d8d8d8;
    }

    @media screen and (max-width: 850px){
        padding-bottom: 36px;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
    }

    table {
        tbody {
            tr {
                &.blur {
                    opacity: 0.5;
                    -webkit-filter: blur(6px);
                    filter: blur(6px);
                }
            }
        }
    }

    .pc {
        > .header {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 22px;
            }
        }

        .desc {
            margin-top: 5px;
            font-size: 18px;
            text-align: center;
        }

        .btn_signup {
            margin: auto;
        }

        #AnnualFeeChart {
            max-height: 250px;
            height: 30vh;

            .bar {
                p {
                    font-size: 10px;
                }
            }
        }

        .wrap_table {
            margin-top: 52px;

            .table_body {
                max-height: 200px;
                overflow-y: auto;
            }

            .header {
                display: flex;
                justify-content: space-between;

                h2 {
                    margin-bottom: 10px;
                    font-size: 22px;

                    .sub_txt {
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                    }
                }
            }

            table {
                tbody {
                    tr {
                        td {
                            color: rgba(0, 0, 0, 0.87);
                        }
                    }
                }
            }
        }

        .hide_txt {
            margin: 9px 0;
            font-size: 22px;
            font-family: Pretendard_bold;
            text-align: center;
        }

        .hide_border {
            border: 2px solid #989898;
        }
    }

    .mo {
        > .header {
            position: relative;
            padding: 19px 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 60px;
            text-align: center;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

          h2 {
              font-size: 16px;
              font-family: Pretendard_extraBold;
              color: #443018;
          }

          .icon_exit {
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
          }
        }

        .title {
            margin: 24px auto 30px auto;
            width: 90%;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            line-height: 26px;
            color: #443018;
        }

        .chart {
            margin: auto;
            width: 90%;
            height: 250px;
            border-bottom: 2px dashed #d8d8d8;
        }

        .notify {
            margin: 30px auto 0 auto;
            display: flex;
            align-items: center;
            width: 90%;

            i {
                margin-right: 4px;
            }

            p {
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #959595;
            }
        }

        .wrap_table {
            position: relative;
            height: 208px;

            .title {
                margin: 16px auto 20px auto;
            }

            .hide {
                position: absolute;
                left: 50%;
                bottom: 20px;
                transform: translate(-50%);
                margin: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 8px;
                width: 60%;
                height: 44px;
                background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange));
                border-radius: 21px;
                z-index: 2;

              p {
                    font-size: 16px;
                    font-family: Pretendard_extraBold;
                    line-height: 22px;
                    color: #ffffff;;
                }
            }

            table {
                tbody {
                    td:nth-child(3) {
                        font-family: Pretendard_bold;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
