#PaymentHistory {
  padding: 0 20px 0 20px;

  .header_wrap {
    display: flex;
    column-gap: 20px;

    .button_wrap {
      margin-top: 25px;
      display: flex;
      column-gap: 20px;

      button {
        border: black 1px solid;
        width: 200px;
        height: 40px;
      }
    }

    .input_wrap {
      margin-top: 25px;
      display: flex;
      column-gap:  5px;
      button {
        border: black 1px solid;
        width: 50px;
        height: 40px;
      }
    }
  }

  .table_wrap {
    margin-top: 25px;
  }

}