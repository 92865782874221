#InfoChoice {
  height: auto;
  .free_text {
    margin-top: 50px;
    text-align: center;
    line-height: 1.5;
    i {
      width: 55px;
      height: 55px;
      cursor: pointer;
    }
    p {
      font-family: Pretendard_regular;
      font-size: 13px;
      letter-spacing: -0.13px;
      color: #9e9e9e;
    }
  }
  .section_items {
    > div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      div {
        .name {
          color: #4a4a4a;
          font-size: 20px;
          font-family: Pretendard_bold;
        }
        > p {
          margin-top: 10px;
          color: #9e9e9e;
          font-size: 15px;
          font-family: Pretendard_regular;
        }
      }
      > p {
        min-width: 120px;
        margin-top: 10px;
        font-size: 20px;
        font-family: Pretendard_bold;
      }
      select {
        margin-top: 5px;
        min-width: 170px;
        border-radius: 5px;
        background-color: #eee;
        color: #9e9e9e;
        padding: 0 0 0 8px;
        height: 34px;
      }
    }
    .option_text {
      display: flex;
      flex-direction: column;
      > p {
        margin-top: 10px;
        color: #9e9e9e;
        font-size: 15px;
        font-family: Pretendard_regular;
      }
    }
  }
}
