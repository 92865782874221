.News {
  #ContentData {
    margin: 0 auto;
    padding-bottom: 30px;
    .wrap_list {
      border-bottom: 1px solid #e7e7e7;
    }
    table {
      tbody {
        tr {
          td {
            padding: 20px 10px;
            &:nth-child(2) {
              h2 {
                padding: 0 0 8px;
              }
              .content {
                align-items: center;
                p {
                  font-family: "Pretendard_light";
                }
                img {
                  width: 200px;
                  max-height: 200px;
                  height: auto;
                  flex: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
