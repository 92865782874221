#FreeRequestList {
    margin: auto;
    width: 1080px;

    .page_title {
        margin: 45px auto 9px auto;
        font-size: 24px;
        font-family: Pretendard_extraBold;
        line-height: 30px;
        color: #343434;
    }

    .area_box {
        margin: 0 auto 35px auto;
        padding: 19px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 40px;
        max-width: 1080px;
        height: 210px;
        border-radius: 20px;
        background-color: rgb(241, 241, 241, 0.3);
    }

    .header {
        margin: 30px 0 20px 0;
        position: relative;

        .title {
            font-size: 24px;
            font-family: Pretendard_extraBold;
            color: #343434;
        }
    }

    table {
        table-layout: auto;

        th:nth-child(1),
        td:nth-child(1),
        th:nth-child(3),
        td:nth-child(3),
        th:nth-child(4),
        td:nth-child(4) {
            width: 10%;
        }

        th:nth-child(2),
        td:nth-child(2) {
            text-align: left;
        }
    }

    .btn_add {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 32px;
        font-size: 15px;
        color: #ffffff;
        background-color: #4593f5;
    }

    .color_red {

    }

    .color_blue {

    }
}
