#DocTradePopup{
	position: fixed;
	top: 50%;
	left: 50%;
	width: 600px;
	max-height: 80vh;
	transform: translateX(-50%) translateY(-50%);
	padding: 46px 36px;
	background-color: #fff;
	border: 1px solid #d8d8d8;
	z-index: 999;

	.btnPopupClose{
		position: absolute;
		width: 24px;
		height: 24px;
		top: 46px;
		right: 36px;
		text-indent: -9999px;
		background: url(../../../assets/images/common/icon-exit.svg) no-repeat 50% 50%;
		background-size: cover;
	}
	.popup-content{
		color: #343434;
		.title{
			font-family: Pretendard_bold;
			font-size: 22px;
			margin-bottom: 40px;
		}
		.text{
			max-height: 50vh;
			font-size: 14px;
			line-height: 1.29;
			letter-spacing: .25px;
			color: #343434;
			word-break: keep-all;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
}
