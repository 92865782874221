@import "src/include";

#GroupwareAdminCommute {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  .commute_wrapper {
    width: 1000px;
    background-color: rgba(216, 216, 216, 0.28);
    padding: 37px 41px;

    > h2 {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
    }

    > div {
      width: 915px;
      min-height: 245px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      margin-top: 15px;
      padding: 30px;
      position: relative;
      h5 {
        font-size: 16px;
        letter-spacing: -0.8px;
        color: #959595;
        span {
          font-size: 22px;
          letter-spacing: -1.1px;
          background-image: linear-gradient(to right, #0078f1, #01bdb4);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-right: 20px;
        }
      }
    }
    .link_detail_btn {
      width: 140px;
      height: 32px;
      font-size: 14px;
      color: #fff;
      border-radius: 16px;
      background-image: linear-gradient(to right, #0078f1, #01bdb4);
      position: absolute;
      bottom: 30px;
      right: 30px;
    }
    .commute_check {
      display: flex;
      margin-top: 10px;
      > div:nth-child(-n+2) {
        border-right: 1px solid #f0f0f0;
      }
    }
    .commute_check_items {
      > div {
        display: flex;
        align-items: center;
        width: 280px;
        padding: 10px 30px;
        p:nth-of-type(1) {
          width: 150px;
          font-size: 20px;
          font-family: Pretendard_bold;
          color: #343434;
          letter-spacing: -0.23px;
        }
        p:nth-of-type(2) {
          font-size: 16px;
          color: #606060;
          text-align: right;
          width: 100px;
          span {
            font-family: GmarketSansTTF_bold;
            font-size: 26px;
            color: #0078f1;
            margin-right: 5px;
          }
        }
      }
      > ul {
        li {
          display: flex;
          padding: 10px 30px;
          justify-content: space-between;
          p:nth-of-type(1) {
            font-size: 16px;
            font-family: Pretendard_regular;
            color: #343434;
          }
          p:nth-of-type(2) {
            font-size: 16px;
            letter-spacing: -0.8px;
            color: #606060;
            span {
              font-family: GmarketSansTTF_bold;
              color: #0078f1;
              margin-left: 5px;
            }
          }
        }
      }
    }
    .commute_company_info {
      > div {
        display: flex;
        margin-top: 20px;
        > div {
          display: flex;
          align-items: center;
          font-family: Pretendard_medium;
          p:nth-of-type(1) {
            color: #0078f1;
            font-size: 16px;
            min-width: 90px;
            height: 30px;
            background-color: #f3f7fd;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p:nth-of-type(2) {
            min-width: 120px;
            font-size: 20px;
            letter-spacing: -0.23px;
            color: #343434;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
