@import "src/include";
@import "src/mixin";

#TaskMain {
  margin: 0 auto;
  width: 100%;
  .tutorial-box {
    display: flex;
    width: 100vw;
    height: 307px;
    background-image: url("../../../assets/images/common/banner.png");
    background-size: 1868px;
    margin: 0 auto;
    .wrap_text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 73px;
      margin-top: 55px;
      width: 295.7px;
      height: 59px;
    }
    h2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard_Bold;
      font-size: 49px;
      //@include text-gradient-green;
      color: #232439;
      margin-bottom: 4px;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
    .content {
      margin-top: 43px;
      margin-left: 10%;
      justify-content: center;
      align-items: center;
      width: 703px;
      height: 237px;
    }
    .title p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;
    }
    .title span:nth-child(1) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(3) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(5) {
      font-family: Pretendard_SemiBold;
      font-size: 27px;
      color: #232439;
    }
    .subtitle{
      color: white;
      display: flex;
      margin-top: 75px;
      font-size: 18px;
      p:nth-of-type(1) {
        min-width: 50px;
        color: #FFC900;
      }
      p:nth-of-type(2) {
        line-height: 1.5;
      }
    }
    img {
      position: absolute;
      top: 33px; /* Adjust the top position as needed */
      right: 83px; /* Adjust the left position as needed */
      width: 348px;
      height: 260px;
    }
  }

  .wrap_list::-webkit-scrollbar {
    width: 12px !important;
  }
  .table-box-wrap {
    .wrap_list {
      width: 100%; /* Subtract 50px from the total width */
      padding: 0;
      max-height: 800px;
      margin-right: 50px; /* Add 50px margin to the right */
    }
  }

  > .header {
    background-color: var(--color-Malibu-opacity-015);
    overflow: hidden;
    //width: 1280px;
    //margin: 0 auto;
    width:100vw;
    padding:0 50px 0 50px;
    .header_inner_search {
      height: 125px;
      background-color: #f0f8ff;
      .header_keyword {
        display: flex;
        padding: 15px 0 0 36px;
        p {
          display: inline-block;
          text-align: center;
          height: 26px;
          margin-right: 4px;
          border-radius: 14px;
          box-shadow: 0 2px 4px 0 #f0f0f0;
          padding: 7px 12px;
          font-family: Pretendard_extraBold;
          font-size: 12px;
        }
        p:nth-child(odd) {
          color: #0078f1;
          background-color: rgba(47, 244, 78, 0.35);
        }
        p:nth-child(even) {
          color: #fff;
          background-color: #0078f1;
        }
      }
      .search_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0 0 36px;
        //width: 1190px;
        height: 50px;
        border-radius: 15px;
        box-shadow: 1px 2px 2px 0 #d8d8d8;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(to left, #0d7, #0078f1);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, #fff, #fff),
          linear-gradient(to left, #0d7, #0078f1);
        background-origin: border-box;
        background-clip: content-box, border-box;
        input[type="text"] {
          width: 870px;
          height: 24px;
        }
        input[type="text"]::placeholder {
          font-family: Pretendard_regular;
          color: #aaaaaa;
        }
      }
    }
    .header_inner {
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 260px;
      background: url("../../../assets/images/common/bg_findbiz_main.svg")
        no-repeat right;
      background-size: 527px 374px;

      @include mobile {
        padding: 0 5%;
        align-items: baseline;
        width: 100vw;
        height: 180px;
        background-position: calc(100% + 40px);
        background-size: 240px;
        background: url("../../../assets/images/common/bg_findbiz_main-mo.svg")
          no-repeat right;
      }
    }

    .header_title {
      margin-bottom: 10px;
      font-size: 35px;
      line-height: 40px;

      @include mobile {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 30px;
      }

      span {
        font-family: Pretendard_extraBold;
        color: var(--color-Cloud-Burst);
      }
    }

    .header_desc {
      margin-bottom: 15px;
      font-size: 16px;
      font-family: Pretendard_bold;
      line-height: 24px;
      color: var(--color-Scorpion);
      text-align: center;
    }

    .header_menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto 5px auto;
      width: 700px;
      .header_keyword {
        display: flex;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 82px;
          height: 29px;
          border-radius: 14px;
          background-color: #6bd3ff;
          color: #ffffff;
          font-family: Pretendard_extraBold;
          font-size: 14px;
          margin-right: 4px;
        }
      }
      .btn_mail {
        display: flex;
        align-items: center;
        column-gap: 8px;
        width: 150px;
        height: 20px;
        font-size: 16px;
        font-family: Pretendard_extraBold;
        color: var(--color-Cornflower-Blue);
      }
    }

    .search_area {
      position: relative;

      input {
        padding: 0 50px 0 20px;
        width: 700px;
        height: 50px;
        border: none;
        border-radius: 5px;
        background-color: #fff;

        @include mobile {
          width: 90vw;
          height: 40px;
          font-size: 14px;
        }
      }

      input::placeholder {
        font-family: Pretendard_bold;
        color: var(--color-Mine_Shaft);
      }

      button {
        position: absolute;
        top: 12px;
        right: 20px;

        @include mobile {
          top: 7px;
          right: 10px;
        }
      }
    }
  }

  .wrap {
    .head {
      .inner {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //width: 1280px;
        //width:100%;
        margin: 0 50px 0 50px;
        //padding:0 50px 0 50px;
        background-color: #fff;
        border-bottom: 1px solid #f0f0f0;
        @include mobile {
          margin: 0;
          width: 100vw;
          overflow-x: scroll;
          overflow-y: hidden;
        }

        .tabs {
          display: flex;
          padding: 0 20px;
          height: 58px;
          @include mobile {
            margin: 10px 0;
          }

          button {
            width: 200px;
            font-family: Pretendard_bold;
            font-size: 16px;
            color: #343434;
            background-color: #fff;
            @include mobile {
              width: 100%;
              font-size: 14px;
              white-space: pre;
            }

            span {
              position: relative;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              font-family: Pretendard_extraBold;
              font-size: 12px;
              color: #aaa;
            }
          }
          .active {
            border-bottom: 3px solid #4593f5;
          }
          .icon {
            padding-left: 40px;
            justify-content: center;
            &::before {
              position: absolute;
              content: "";
              width: 18px;
              height: 18px;
              top: 46%;
              left: 15px;
              transform: translateY(-50%);
              background: url(../../../assets/images/mobile/icon_alert_circle.svg)
                no-repeat 50% 50%;
              background-size: contain;
            }
          }
        }

        .count {
          font-size: 16px;
          font-family: Pretendard_bold;
          color: var(--color-Scorpion);

          span {
            margin: 0 8px;
            font-size: 30px;
            font-family: Pretendard_extraBold;
            color: #54b7e0;
          }
        }
      }

      .wrap_btns {
        margin:0 50px;
        //margin: 0 auto;
        //width: 1280px;
        //width:100vw;
        line-height: 40px;
        border-bottom: 1px solid var(--color-Gallery);

        .btns {
          //margin: auto;
          display: flex;
          justify-content: end;
          align-items: center;
          //width: 1280px;
          margin:0 50px;
          //width:100vw;
          height: 49px;
          .btn_view {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 187px;
            height: 100%;
            column-gap: 10px;
            font-family: Pretendard_bold;
            color: #343434;

            i {
              width: 20px;
              height: 20px;
              background-size: cover;
            }
          }
          .btn_mail {
            width: 187px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid #f0f0f0;
            p {
              @include text-gradient-green;
            }
          }
        }
        .exclude_btn {
          margin: auto;
          display: flex;
          justify-content: end;
          align-items: center;
          //width: 1280px;
          //width:100vw;
          font-family: Pretendard_bold;
          font-size: 14px;
          color: #343434;
          i {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .not_login_msg.show {
    display: flex;
  }

  .not_login_msg {
    position: absolute;
    top: calc(50% + 20px);
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    column-gap: 8px;
    width: 270px;
    height: 140px;
    border-radius: 8px;
    box-shadow: 0 0 7px 3px #d8d8d8;
    background: white;
    z-index: 1;
    cursor: pointer;
    p {
      font-family: Pretendard_semiBold;
      font-size: 13px;
      color: #343434;
      span {
        color: #4792f7;
        font-family: Pretendard_bold;
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 241px;
      height: 40px;
      background-color: #00ba78;
      box-shadow: 0 2px 2px 0 #f0f0f0;
      font-size: 14px;
      color: white;
      border-radius: 8px;
      font-family: Pretendard_bold;
    }
  }

  .animation {
    animation: bell 0.05s linear alternate-reverse 15;
  }

  @keyframes bell {
    0% {
      margin-left: 5px;
    }
    100% {
      margin-left: 0;
    }
  }

  .category_list {
    margin: 20px auto 0 auto;
    max-width: 1280px;

    img {
      width: 140px;

      @include mobile {
        width: 100px;
      }
    }

    a:focus {
      outline: none;
    }
  }

  .table-box-wrap {
    //margin: auto;
    margin-left:50px;
    max-width: 100%;
    margin-right:50px;
  }

  @include mobile {
    padding-top: 60px;

    .wrap_list {
      margin-bottom: 20px;
      border-bottom: 1px solid #d8d8d8;
    }

    .title {
      margin: 35px 0 6px 0;
      font-size: 20px;
      font-family: Pretendard_extraBold;
      text-align: center;
      color: #1d334e;
    }

    .desc {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      font-size: 14px;
      font-family: Pretendard_bold;
      line-height: 20px;
      color: #606060;
      background-color: rgba(240, 240, 240, 0.5);
    }

    .tabs {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      button {
        width: 28%;
        height: 38px;
        font-size: 12px;
        font-family: Pretendard_extraBold;
        color: #443018;
        @include border-radius(8px);
        box-shadow: 0 2px 3px 1px rgb(216 216 216 / 30%);
      }

      .active {
        color: #ffffff;
        background-color: #ffa600;
      }
    }

    .table-box-wrap {
      margin-bottom: 30px;
      .wrap_list {
        max-height: 350px;
        table {
          thead {
            background: #1d334e;
          }
        }
      }
    }

    table {
      .table_title {
        padding-left: 10px;
        width: 65% !important;
        text-align: left;
      }

      .not_styled {
        td {
          text-align: center;
        }
      }
    }

    .btnDown_wrap {
      position: relative;
      margin: 0 auto;
    }

    .btnDown {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-indent: -9999px;
      margin: 0;
      text-align: center;
      background: url(../../../assets/images/common/icon-download_mobile.svg)
        no-repeat 50% 50%;
      background-size: cover;
    }
  }
}
