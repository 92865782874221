@import "src/mixin";

#GovProjectBySubject {
  margin-bottom: 60px;
  max-width: 1200px;
  margin: 0 auto;

  .work_tit{
    font-size: 28px;

    @include mobile {
      font-size: 18px;
    }
  }

  .subject_list {
    >ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      column-gap: 16px;
      row-gap: 16px;

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
      }

      >li {
        display: flex;
        align-items: stretch;

        >a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #F5F7FA;
          color: #212121;
          font-size: 20px;
          text-align: center;
          padding: 24px;
          border-radius: 8px;
          line-height: 1.6;
          
          img{
            width:10px;
          }

          @include mobile {
            font-size: 18px;
          }
        }
      }
    }
  }
}