#HeaderPersonal {
  position: relative;
  z-index: 50;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    .menu_box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menus {
        display: flex;
        align-items: center;
        font-family: "Pretendard_light";

        > li {
          margin-left: 2rem;
          > a {
            font-family: "Pretendard_light";
            color: #333;
          }
        }

        .btn_item {
          color: #333;
          position: relative;
          cursor: pointer;
          .menu_item {
            .child_tab {
              display: none;
              position: absolute;
              top: 100%;
              left: 0;
              background-color: #fff;
              border: 1px solid #e5e5e5;
              border-radius: 0.375rem;
              box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
              padding: 1rem;
              z-index: 100;
              > div {
                width: 180px;
              }
            }
          }
          &:hover {
            .child_tab {
              display: block;
            }
          }
        }
      }
    }

    .service_menus {
      display: flex;
      align-items: center;
      .business {
        display: flex;
        align-items: center;
        > li {
          margin-left: 2rem;
          > a {
            font-family: "Pretendard_light";
            color: #9369cd;
          }
        }
      }
    }

    .member_menus {
      > a,
      button {
        margin-left: 2rem;
        border-radius: 0.375rem;
        padding: 0.4rem 1rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: #9369cd;
        color: #fff;
        font-family: "Pretendard_light";
      }
      .signup {
        margin-left: 16px;
      }
      .login {
        background-color: #4e5968;
      }
    }
  }
  .gnb {
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    > ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > li {
        > button {
        }
      }
    }
  }
  .logo_img {
    width: 120px;
  }
}
