#Withdrawal {
    margin: auto;
    padding-top: 40px;
    width: 910px;

    .header {
        margin-bottom: 30px;

        h2 {
            margin-bottom: 8px;
            font-size: 22px;
            font-family: Pretendard_extraBold;
        }

        p {
            font-size: 14px;
            font-family: Pretendard_bold;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .notify {
        width: 910px;
        line-height: 76px;
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #e7e7e7;
        background-color: #ffffff;

        p {
            font-size: 24px;
            font-family: Pretendard_bold;
            text-align: center;
            color: rgba(0, 0, 0, 0.87);

            span {
                color: var(--color-Web-Orange);
            }
        }
    }

    .desc {
        margin: 30px 0;
        padding-left: 15px;
        height: 92px;
        line-height: 1.3;
        font-size: 14px;
        color: #343434;

        span {
            font-family: Pretendard_bold;
        }
    }

    .checkbox {
        padding-right: 16px;
        justify-content: end;
        flex-direction: row-reverse;

        .checkmark {
            margin-left: 20px;
            margin-right: 0;
        }

        .value {
            font-family: Pretendard_bold;
        }
    }

    .btn_del {
        margin: 84px auto 0 auto;
        display: block;
        width: 300px;
        height: 49px;
        font-size: 16px;
        color: #ffffff;
        background-color: var(--color-Alizarin-Crimson);
    }
}
