#TermsMobile {
  padding: 14px 14px 60px 14px;
  color: rgba(102, 102, 102, 0.8);
  word-break: keep-all;
  a {
    color: rgba(102, 102, 102, 0.8);
  }
  h2 {
    font-size: 1.4rem;
    font-family: "Pretendard_ExtraBold";
    text-align: center;
    padding: 30px;
    color: #000;
  }
  table {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-top: 10px;
    tr {
      &:last-child {
        border-bottom: none;
      }
    }
    th {
      color: #444;
      font-size: 14px;
      width: 80px;
      background-color: #f7f7f7;
      padding-left: 14px;
      &.long {
        width: auto;
      }
    }
    td {
      color: rgba(102, 102, 102, 0.8);
      font-size: 14px;
      text-align: left;
      font-family: "Pretendard_Regular";
      padding-left: 14px;
      word-break: keep-all;
    }
  }
  .summary {
    background: #faf9f6;
    font-size: 14px;
    padding: 30px 20px;
    margin-bottom: 30px;
    line-height: 1.5384;
    font-family: "Pretendard_Regular";
    > strong {
      display: block;
      color: #000;
    }
  }
  .textlist {
    > ul {
      > li {
        margin-top: 2rem;
        font-size: 14px;
        line-height: 1.5384;
        font-family: "Pretendard_Regular";
        &:first-child {
          margin-top: 0;
        }
        h3 {
          margin-bottom: 14px;
          font-size: 14px;
          font-family: "Pretendard_Bold";
          color: #000;
        }
        p {
          margin-top: 10px;
        }
        .list {
          > li {
            margin: 5px 0 10px;
          }
          .inner_list {
            > li {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .inner_list {
      li {
        padding-left: 14px;
      }
    }
  }
}
