#PatentRegistPop {
  width: 1020px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  z-index: 9999;
  box-shadow: 2px 2px 12px 0px #bebebe;
  button {
    background-color: transparent;
  }
  .pop_wrap {
    padding: 20px;
    position: relative;
  }
  .tit {
    display: inline-block;
    font-size: 20px;
  }
  .tbl_box {
    margin-bottom: 20px;
    &.patent_tbl {
      max-height: 295px;
      overflow-y: auto;
    }
  }
  .tit_box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .tag {
    display: inline-block;
    color: var(--color-Bright-Blue);
    border: 1px solid var(--color-Bright-Blue);
    padding: 8px 12px;
    border-radius: 24px;
    margin-left: 10px;
  }
  .no_tag {
    padding-left: 10px;
    color: #6c6c6c;
  }
  .regist_btn {
    background-color: var(--color-Bright-Blue);
    color: #fff;
    padding: 8px 18px;
    border-radius: 24px;
    font-size: 16px;
    margin-left: 10px;
  }
  .keyword {
    width: calc(100% - 80px);
    background-color: #f2f4f6;
    border: none;
    border-radius: 24px;
    padding: 0 16px;
  }
  .patent_sch {
    background-color: #f2f4f6;
    border: none;
    border-radius: 24px;
    padding: 0 16px;
    display: inline-flex;
    align-items: center;
    input {
      background-color: transparent;
      border: none;
    }
  }
  .product_sch {
    margin-bottom: 10px;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .btn {
    padding: 4px 12px;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    & + .btn {
      margin-left: 6px;
    }
    &.btn_save {
      color: #fff;
      background-color: var(--color-Bright-Blue);
      border-color: var(--color-Bright-Blue);
    }
    &.btn_cancel {
      color: #606266;
      border-color: #606266;
    }
    &.btn_edit {
      border-color: var(--color-Bright-Blue);
      color: var(--color-Bright-Blue);
    }
    &.btn_del {
      color: var(--color-Alizarin-Crimson);
      border-color: var(--color-Alizarin-Crimson);
    }
  }
  .table-box-wrap {
    .wrap_list {
      max-height: 264px;
    }
    table {
      thead {
        position: sticky;
        top: 0;
        left: 0;
      }
      th,
      td {
        padding: 7px;
      }
    }
  }
  .list_box {
    max-height: 264px;
    overflow-y: auto;
    table {
      thead {
        position: sticky;
        top: 0;
        left: 0;
      }
    }
  }
}
