@import "src/include";
.docTradeMyTransactionUsageList{
	.table_content{
		&_number{
			color: #343434;
		}
		&_title{
			padding-left: 26px;
			text-align: left;

			&_text{
				display: inline-block;
				height: 36px;
				line-height: 36px;
				color: #343434;
				@include text-ellipsis(1)
			}
		}
		&_holding-points{
			&_text{
				display: inline-block;
				margin-right: 20px;
			}
		}
	}
	td{
		height: 50px;
		color: rgba(0, 0, 0, 0.87);
	}
}