#MarketInfoPopupCart {
  @import "../../../include";
  padding: 22px 55px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1010px;
  min-height: 460px;
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  z-index: 5;

  .header {
    padding-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;

    h2 {
      font-size: 22px;
      font-family: Pretendard_bold;
      color: #343434;
    }
  }

  .body_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    h2 {
      font-size: 14px;
      font-family: Pretendard_extraBold;
      color: #343434;
    }

    .upload {
      display: flex;
      align-items: center;
      column-gap: 10px;

      p {
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-size: 12px;
        color: #606060;
      }

      button {
        width: 132px;
        height: 32px;
        font-size: 14px;
        font-family: Pretendard_bold;
        color: #ffffff;
        border-radius: 4px;
        background-color: #1bb3f3;
      }
    }
  }

  .list {
    padding: 18px 0 24px 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid #e7e7e7;
    overflow-x: scroll;
    overflow-y: hidden;

    li {
      padding: 10px;
      width: 184px;
      height: 210px;
      border: 1px solid #e6e6e6;

      img {
        width: 162px;
        height: 110px;
        object-fit: contain;
      }

      h2 {
        height: 24px;
        font-size: 14px;
        font-family: Pretendard_bold;
        color: #343434;
        @include text-ellipsis(2);
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;

        p {
          display: flex;
          justify-content: center;
          align-content: center;
          column-gap: 5px;
          cursor: pointer;
          &.show_detail {
            border: 1px solid var(--color-Web-Orange);
            color: var(--color-Web-Orange);
            padding: 4px;
            border-radius: 6px;
          }
        }
        > span {
          display: inline-block;
          font-size: 12px;
          color: var(--color-Web-Orange);
        }
      }

      p {
        height: 24px;
        font-size: 12px;
        font-family: Pretendard_bold;
        line-height: 24px;
        color: #606060;
        text-align: right;
      }
    }

    .checkbox {
      justify-content: left;
    }
  }

  .btn_select_success {
    margin: 24px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 4px;
    background-color: #4593f5;
  }
}
