#ResearchTechMyKeyword {
  //width: 1280px;
  width:100%;
  padding:0 50px 100px 50px;
  //padding-bottom: 100px;
  display: flex;
  //margin: 0 auto;
  .keyword_wrapper {
    margin-top: 18px;
  }
  h2 {
    font-family: Pretendard_bold;
    font-size: 22px;
    color: #343434;
    span {
      font-family: Pretendard_semiBold;
      font-size: 14px;
    }
  }
  table {
    margin-top: 24px;
    th:nth-child(2),td:nth-child(2),th:nth-child(3),td:nth-child(3) {
      text-align: left;
    }
  }
}
