#RenewalServiceMainMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px 0;
  margin: 15px 0;
  div {
    width: 90%;
    height: 100px;
    background-color: #0984e3;
    border-radius: 15px;
    padding: 15px;
    position: relative;
    color: white;
    span {
      font-size: 18px;
    }
    button {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      background-color: inherit;
      color: white;
      i {
        margin-left: 20px;
      }
    }
  }
}
