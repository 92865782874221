#GroupwareVacationDashBoard {
  margin: 0 auto;
  display: flex;

  .vacation_wrapper {
    width: 100%;
    background-color: #fff;
    background-color: rgba(216, 216, 216, 0.28);
    padding: 37px 41px;
    margin-bottom: 40px;

    h5 {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
      margin-bottom: 23px;
    }
  }

  .vacation_grid_apply {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;

    >div {
      width: calc(100% - 20px);
      height: 140px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      padding: 23px 15px 0 32px;
      position: relative;

      >p {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-family: Pretendard_bold;
        letter-spacing: -1.1px;
        color: #343434;

        i {
          margin-right: 5px;
        }
      }

      .item_head_title {
        font-size: 16px;
        font-family: Pretendard_regular;
        color: #343434;
        margin-top: 15px;
      }

      .item_sub_title {
        font-size: 14px;
        font-family: Pretendard_regular;
        letter-spacing: -0.7px;
        color: #959595;
        position: absolute;
        bottom: 25px;
        left: 25px;
      }

      button {
        width: 80px;
        height: 30px;
        border-radius: 16px;
        background-image: linear-gradient(to right, #0078f1, #01bdb4);
        color: #FFFFFF;
        position: absolute;
        bottom: 21px;
        right: 15px;
      }
    }
  }
}