#InnerAlertPopup {
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 283px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 68px;
        display: flex;
        justify-content: space-between;
        height: auto;
        min-height: auto;

        h2 {
            font-size: 22px;
        }
    }
    p {
        font-size: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
    }

      .btn_success {
          margin: 58px auto 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 32px;
          font-size: 16px;
          color: #ffffff;
          background-color: #96c719;
    }
}
