#CompetitorAccount {
  width: 400px;
  padding: 30px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 99;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 30px;
  .tit {
    > strong {
      font-size: 20px;
      color: var(--color-Bright-Blue);
    }
  }
  .current_comp {
    display: block;
    padding: 20px 0;
    font-size: 24px;
    color: var(--color-Bright-Blue);
  }
  .list {
    background-color: #f4f4f4;
    border: 1px solid #ababab;
    border-radius: 10px;
    padding: 20px;
    > div {
      > div {
        margin-bottom: 6px;
      }
    }
    > .custom_scroll {
      max-height: 200px;
      overflow-y: auto;
    }
    .sub {
      padding-left: 30px;
      cursor: pointer;
      > .folder_list {
        max-height: 82px;
        overflow-y: auto;
        background: #fff;
        border-radius: 10px;
        padding: 10px;
        > .folder {
          & + .folder {
            padding-top: 4px;
          }
        }
      }
    }
    .first-level-folder {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .num {
      padding-left: 4px;
    }
  }
  .fd_icon {
    width: 24px;
    flex: none;
    background-size: contain;
    margin-right: 6px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    button {
      color: var(--color-Bright-Blue);
      border: 1px solid var(--color-Bright-Blue);
      padding: 10px 16px;
      width: 120px;
      border-radius: 24px;
      font-size: 16px;
      &.save {
        color: #fff;
        background-color: var(--color-Bright-Blue);
        margin-left: 24px;
      }
    }
  }
  .new_folder {
    margin-top: 10px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn_box {
      display: flex;
      button {
        padding: 8px 14px;
        color: #fff;
        background-color: var(--color-Bright-Blue);
        border-radius: 6px;
        &.cancel {
          color: #7d7d7d;
          background-color: transparent;
          border: 1px solid #adadad;
          margin-left: 4px;
        }
      }
    }
  }
}
