#InventorStepThree {
  margin: 30px auto;
  width: 1280px;
  .inventor_info_box_title {
    > h5 {
      font-size: 22px;
      color: #343434;
    }
    > p {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  .inventor_patent_table {
    margin-top: 30px;
    > thead, tbody {
      height: 40px;
    }
    thead {
      background-color: #000;
      font-size: 14px;
      th {
        color: white;
      }
    }
  }
  .btns {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;
    button {
      width: 93px;
      height: 45px;
      border-radius: 4px;
      font-size: 16px;
      background-color: #fff;
    }
    > button:nth-of-type(1) {
      color: #343434;
      border: solid 1px #959595;
    }
    > button:nth-of-type(2) {
      background-color: #e92f2c;
      color: #fff;
    }
  }
}
