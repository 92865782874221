#GroupwareDocDashBoard {
  display: flex;
  min-height: 100vh;
 // background-color: #f5f6f8;

  .main-content {
    // flex: 1;
    // padding: 2rem 3%;
    // background-color: rgba(216,216,216,0.28);
    width: 100%;
    //background-color: #fff;
    background-color: rgba(216, 216, 216, 0.28);
    padding: 37px 41px;
    >h2 {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
      margin-bottom: 23px;
    }

    .section {
      margin :2rem 0;
      position: relative;

      >h3 {
        font-size: 19px;
        letter-spacing: -0.25px;
        color: #6B7684;
        margin-bottom: 23px;
      }

      &:not(:first-of-type) {
        padding-top: 2rem;
        margin-top: 1rem;
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #bcc1c77c;
        }
      }



      .doc-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;

        .doc-item {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          background-color: white;
          border-radius: 0.5rem;
         // border: 1px solid #e2e8f0;
          transition: all 0.2s;
          cursor: pointer;

          &:hover {
            border-color: #3182ce;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          i {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            transform: translateY(4px);
          }

          span {
            flex: 1;
            color: #4a5568;
            word-break: keep-all;
          }

          .star {
            color: #a0aec0;
            cursor: pointer;
            font-size: 1.25rem;
            
            &.active {
              color: #ecc94b;
            }
          }
        }
      }
    }
  }
}