#CashflowFind {
  color: #333d4b;
  font-family: "Pretendard_regular";
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 100px;
  strong {
    font-family: "Pretendard_bold";
  }
  .no_data {
    text-align: center;
    padding: 30px;
  }
  .top {
    background-color: #f2f4f6;
    padding: 10px 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    .txt {
      .title {
        display: block;
        font-size: 32px;
        padding-bottom: 10px;
      }
    }
    > img {
      margin-bottom: -10px;
      height: 160px;
    }
  }
  .sch {
    text-align: right;
    margin-bottom: 30px;
    .box {
      background-color: #f2f4f6;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 26px;
      padding: 0 20px;
      > input {
        background-color: transparent;
        border: none;
        width: 300px;
      }
    }
  }
  .list {
    > ul {
      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #d1d6db;
        padding: 30px;
        border-radius: 10px;
        margin-bottom: 20px;
        .logo_box {
          display: flex;
          align-items: center;
          > img {
            max-width: 240px;
            margin-right: 40px;
          }
        }
        .info {
          .name_tag {
            padding-bottom: 6px;
            .name {
              font-size: 18px;
              display: inline-block;
            }
            .tag {
              background-color: #f4f7ff;
              color: var(--color-Bright-Blue);
              border: 1px solid var(--color-Bright-Blue);
              padding: 4px 12px;
              border-radius: 24px;
              display: inline-block;
              margin-left: 10px;
            }
          }
          .desc {
            color: #6b7684;
            margin-bottom: 16px;
          }
          .detail {
            display: flex;
            align-items: center;
            > strong {
              margin-right: 20px;
            }
            > p {
              color: #6b7684;
            }
            &.contact {
              margin-bottom: 10px;
            }
          }
        }
        .more {
          color: #fff;
          background-color: var(--color-Bright-Blue);
          padding: 10px 20px;
          border-radius: 36px;
        }
      }
    }
  }
  #pagination {
    .item-wrap {
      gap: 4px;
    }
    .item {
      .link {
        background-color: transparent;
        border: none;
        border-radius: 100%;
      }
      &.end,
      &.start {
        width: 36px;
      }
      &.on {
        .link {
          background-color: var(--color-Bright-Blue);
        }
      }
      &:hover {
        .link {
          background-color: var(--color-Bright-Blue);
        }
      }
    }
  }
}
