.holdings_common {
  .part {
    padding: 30px;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  }
  .part_tit {
    color: #333d4b;
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
  }
  .distribution {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 20px;
    #HoldingsLevel,
    #HoldingsInvestDistribution {
      height: 100%;
    }
    #HoldingsInvestDistribution {
      grid-column: 2 / -1;
    }
  }
}
