@use "src/headerBase";
@import "src/include";
#CompetitorInfo {
  width: 100vw;
  margin: 0 auto;
  .tutorial-box {
    display: flex;
    width: 100vw;
    height: 307px;
    background-image: url("../../../assets/images/common/banner.png");
    background-size: 1868px;
    .wrap_text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 73px;
      margin-top: 55px;
      width: 295.7px;
      height: 59px;
      align-items: center;
      justify-content: center;
    }
    h2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard_Bold;
      font-size: 49px;
      //@include text-gradient-green;
      color: #232439;
      margin-bottom: 4px;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
    .content {
      margin-top: 43px;
      margin-left: 10%;
      justify-content: center;
      align-items: center;
      width: 703px;
      height: 237px;
    }
    .title p {
      width: 703px;
      height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;
    }
    .title span:nth-child(1) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(3) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(5) {
      font-family: Pretendard_SemiBold;
      font-size: 27px;
      color: #232439;
    }

    img {
      position: absolute;
      top: 30px; /* Adjust the top position as needed */
      right: 83px; /* Adjust the left position as needed */
      width: 348px;
      height: 260px;
    }
  }
  > .company_info_wrapper {
    width: 1280px;
    margin: 0 auto;
    > div {
      position: relative;
      h5 {
        display: flex;
        align-items: center;
        font-family: Pretendard_bold;
        font-size: 28px;
        letter-spacing: -0.88px;
        padding: 22px 0 22px 95px;
        span {
          color: #0078f1;
        }
      }
      .btn_back {
        position: absolute;
        right: 30px;
        top: 20px;
        width: 131px;
        height: 40px;
        border-radius: 10px;
        background-color: #0078f1;
        color: #fff;
      }
      .show_competitor_btn {
        margin-left: 30px;
        padding: 10px 20px;
        border-radius: 10px;
        background-color: #0078f1;
        color: #fff;
      }
      .company_info_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        padding: 33px 0;
        max-height: 600px;
        overflow-y: auto;
      }
      .company_info_item {
        display: flex;
        align-items: center;
        width: 1043px;
        height: 102px;
        border-radius: 15px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
        position: relative;
        img {
          width: 63px;
          height: 63px;
          margin: 13px;
        }
        button {
          position: absolute;
          right: 17px;
          padding: 8px 14px;
          height: 40px;
          font-size: 14px;
          color: #fff;
          border-radius: 10px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
        }
        > .item_text {
          line-height: 1.5;
          > p:nth-child(1) {
            font-size: 18px;
            letter-spacing: -0.5px;
            color: #343434;
          }
          > p:nth-child(2) {
            font-size: 14px;
            width: 310px;
            @include text-ellipsis(1)
          }
          > .item_text_category {
            > p {
              display: inline-block;
              font-family: Pretendard_bold;
              padding: 3px 9px;
              margin-right: 7px;
              font-size: 12px;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              border: 1px solid transparent;
              border-radius: 14px;
              background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
              background-origin: border-box;
            }
          }
        }
      }
    }
    > div:nth-child(even) {
      background-color: rgba(240,240,240,0.44);
    }
  }
}
