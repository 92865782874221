@import "../../../include";

#ContentData {
    padding-top: 30px;
    width: 1080px;

    .ban_keyword {
        display: flex;
        justify-content: space-between;
    }
    .page_wrap {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .btn_page {
            background: blanchedalmond;
            height: 30px;
            width: 100px;
        }
    }

    > h2 {
        margin-top: 10px;
        margin-bottom: 34px;
        font-size: 22px;
        font-family: Pretendard_extraBold;
    }

    .wrap_list {
        max-height: 600px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    table {
        table-layout: auto;

        th:first-child,
        td:first-child {
            width: 10%;
        }

        th:nth-child(2),
        td:nth-child(2) {
            text-align: left;
        }


        th:nth-child(n + 3),
        td:nth-child(n + 3) {
            width: 10%;
        }

        td:nth-child(7){
            z-index: 10;
        }

        tbody {

            tr {
                height: 40px;
                cursor: pointer;

                td:nth-child(2) {
                    font-size: 14px;

                    h2 {
                        padding: 1px 0;
                        font-family: Pretendard_bold;
                        @include text-ellipsis(3);
                    }

                    .content {
                        display: flex;
                        column-gap: 10px;

                        img {
                            min-width: 100px;
                            width: 100px;
                            height: 50px;
                            object-fit: contain;
                        }

                        p {
                            @include text-ellipsis(2);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 850px) {
        padding-top: 70px;
        width: 100%;

        > h2 {
            margin-bottom: 15px;
            padding-left: 15px;
        }

        .wrap_list {
            max-height: 300px;
        }

        .table-box-wrap {
            width: 90%;
            margin: 15px auto 0;
        }

        table {
            th:first-child,
            td:first-child {
                width: 10%;
            }

            th:last-child,
            td:last-child {
                width: 25%;
            }
        }
    }
}
