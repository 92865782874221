.checkbox_label {
  display: flex;
  width: 402px;
  height: 94px;
  padding: 22px 0 0 10px;
  position: relative;
  color: #4a4a4a;;
  border-radius: 8px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 17px;
  background-color: #FFFFFF;
  i {
    width: 58px;
    height: 58px;
    background-size: contain;
    background-position: center center;
    margin-right: 10px;
  }
  h2 {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: Pretendard_Medium;
    letter-spacing: -0.42px;
  }
  h5 {
    font-size: 14px;
    letter-spacing: -0.15px;
    font-family: Pretendard_Light;
    margin-top: 6px;
  }
  input[type="checkbox"] {
    position: absolute;
    top: 22px;
    right: 25px;
    width: 20px;
    height: 20px;
  }
}
