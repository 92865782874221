@import "src/include";

#CostList {
    margin: auto;
    margin-top: 36px;
    width: 1280px;

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;

        .title {
            font-size: 22px;
        }

        .btns {
            display: flex;
            align-items: center;

            & > button {
                width: 132px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
            }

            .btn_trademark_add {
                margin-right: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 132px;
                height: 32px;
                font-size: 15px;
                color: #ffffff;
                background-color: #4593f5;
            }

            .btn_tab_setting, .btn_tab_setting_save {
              background-color: var(--color-Alizarin-Crimson);
            }

            .btn_pay_input {
                margin-right: 6px;
                background-color: #96c719;
            }

            .btn_domestic_list {
                margin-right: 6px;
                background-color: var(--color-Web-Orange);
            }

            .btn_foreign_list {
                margin-right: 6px;
                background-color: #4593f5;
            }

            .ToggleButtonWithSpan {
                margin-right: 31px;
            }
        }
    }

    .wrap_search {
        margin: 18px 0 13px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        select {
            padding-left: 10px;
            width: 100px;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
        }
        .input_search {
            padding: 0px 10px;
            min-width: 330px;
            width: 40%;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
            border-left: 0;
        }
        .icon_search_black {
            margin-left: 15px;
            width: 32px;
            height: 32px;
            background: url("../../../assets/images/common/icon_search_black.svg") no-repeat;
        }
        select::placeholder, input::placeholder {
          color: rgba(0, 0, 0, 0.56);
        }
    }

    .wrap_list {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 590px;
    }

    table {
        display: table;
        table-layout: fixed;

        th {
            height: 40px;
            word-break: keep-all;

            ul {
                overflow-y: scroll;
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                max-height: 150px;
                background-color: white;
                color: black;
                border: 1px solid rgb(0, 149, 174);
                z-index: 2;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                li {
                    display: flex;
                    align-items: center;
                    min-height: 32px;
                    font-size: 12px;
                    font-family: Pretendard_semiBold;
                    cursor: pointer;

                    .checkbox {
                        padding: 0 10px;
                        width: 100%;
                        height: 100%;
                        justify-content: flex-start;

                        .checkmark {
                           min-width: 20px;
                        }
                    }

                    span {
                        font-size: 12px;
                        text-align: left;
                    }
                }
                li+li {
                    border-top: 1px solid rgb(0, 149, 174);
                }
            }
            ul::-webkit-scrollbar {
                display: none;
            }
        }

        th.select_tab {
            position: relative;

            span {
                display: inline-block;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            ul.date {
                overflow-y: visible;
                width: 289px;

                li {
                    padding: 0 8px;
                    justify-content: space-between;

                    .select_tab_date {
                        display: flex;
                        align-items: center;

                        span {
                            margin-right: 6px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        th.dp_none {
            ul {
                display: none;
            }
        }

        tbody {

            tr {
                height: 50px;

                td {
                    word-break: break-all;

                    > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            width: 50%;
                        }
                    }

                    select {
                        width: 65px;
                        height: 30px;
                        font-size: 12px;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                    }
                    a > div{
                        width: 30px;
                        height: 30px;
                    }
                }

                btn_success {
                    width: 64px;
                    height: 30px;
                    font-size: 14px;
                    line-height: 1.07;
                    color: #ffffff;
                    background-color: var(--color-Web-Orange);
                }
            }
        }
    }

    .wrap_tab_btns {

        .checkbox {
            width: 142px;

            .value {
                white-space: pre;
            }
        }
    }

    i {
        position: absolute;
        top: 2px;
        right: 12px;
    }

    .sorting {
        margin-bottom: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;

        .checkbox+.checkbox {
            margin-left: 35px;
        }
    }
}

