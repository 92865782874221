@import "src/include";
#findBizChange{
	position: relative;
	min-width: 351px;
	height: 175px;
	border-radius: 16px;
	overflow: hidden;
	border: solid 2px #f3fafd;
	z-index: 1;
	.change{
		width: 100%;
		height: 100%;
		border-left: 14px solid #4593f5;
		background-color: #f3fafd;
		padding: 10px 15px;
		&_text{
			margin-bottom: 10px;
			.small{
				display: inline-block;
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #606060;
				margin-bottom: 5px;
			}
			.strong{
				display: inline-block;
				font-family: Pretendard_extraBold;
				font-size: 22px;
				color: #4593f5;
				margin-bottom: 5px;
				letter-spacing: -.92px;
			}
			.middle{
				display: inline-block;
				font-family: Pretendard_bold;
				font-size: 12px;
				color: #606060;
				line-height: 18px;
			}
		}
		&_img{
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}
		&_logo{
			width: 120px;
			height: 27px;
			text-indent: -9999px;
			background: url(../../../assets/images/common/logo_findBiz.svg) no-repeat 50% 50%;
			background-size: cover;
		}
		&_logo_text{
			font-family: Pretendard_bold;
			font-size: 18px;
			color: #606060;
		}
		&_btn-link{
			text-indent: -9999px;
			width: 88px;
			height: 30px;
			background: url(../../../assets/images/common/ico_change_blue.png)no-repeat 50% 50%;
			background-size: cover;
		}
		&_subtitle{
			font-family: Pretendard_bold;
			font-size: 12px;
			color: #606060;
			text-align: right;
		}
	}
}
