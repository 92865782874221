#AdminPosting {
    margin: auto;
    padding-top: 42px;
    width: 880px;

    .header {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 22px;
        }

        .btns {
            display: flex;

            > button {
                width: 132px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
            }

            .btn_cancle {
                background-color: #96c719;
            }

            .btn_delete {
                margin-left: 8px;
                background-color: #e92f2c;
            }

            .btn_write {
                margin-left: 8px;
                background-color: #4593f5;
            }

        }
    }

    .title {
        margin-bottom: 12px;
        display: flex;
        align-items: center;

        h2 {
            width: 95px;
            line-height: 30px;
            font-family: Pretendard_bold;
            letter-spacing: 11px;
        }

        input {
            width: 786px;
        }
    }

    .content {
        margin-bottom: 9px;
        display: flex;
        justify-content: space-between;

        > h2 {
            width: 95px;
            line-height: 30px;
            font-family: Pretendard_bold;
            letter-spacing: 11px;
        }

        .btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 350px;

            h2 {
                width: 95px;
                font-size: 14px;
                font-family: Pretendard_extraBold;
            }

            div {
                display: flex;

                button+button {
                    margin-left: 8px;
                }
            }
        }
    }

    video {
        width: 100%;
    }

    .file {
        margin-top: 41px;
        display: flex;
        align-items: center;

        h2 {
            margin-right: 20px;
            width: 100px;
            line-height: 30px;
            font-size: 14px;
            font-family: Pretendard_bold;
        }
    }

    input {
        padding: 0 14px;
        height: 30px;
    }

    input[type="text"], textarea {
        border: solid 0.5px rgba(0, 0, 0, 0.56);
    }

    textarea {
        padding: 14px;
        width: 100%;
        height: 711px;
        resize: none;
    }
}
