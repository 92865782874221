#ResearchTechEdit {
  //margin: auto;
  //width: 1280px;
  width:100%;
  padding: 0 50px;
  display: flex;
  > .edit_wrapper {
    //width: 1060px;
    width:100%;
    padding: 11px 25px 0 25px;
    background-color: rgba(216,216,216,0.28);
    > div {
      //width: 1010px;
      width:100%;
      border-radius: 15px;
      box-shadow: 0 2px 3px 1px #f0f0f0;
      background-color: #fff;
      padding: 18px 0 33px 21px;
      margin-bottom: 20px;
      > h5 {
        font-size: 22px;
        color: #4593f5;
        margin-bottom: 21px;
      }
    }
  }
  .category_title {
    div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      p {
        min-width: 120px;
        font-family: Pretendard_extraBold;
        font-size: 14px;
        color: #343434;
      }
    }
    .category_name {
      input[type="text"] {
        width: 225px;
        margin-right: 10px;
      }
    }
    .research_tech_name {
      input[type="text"] {
        //width: 851px;
        width:100%;
        margin-right:20px;
      }
    }
  }


  .graph_item+.graph_item {
    margin-top: 30px;
  }

  .semi_title {
    margin-bottom: 10px;
    padding-left: 13px;
    font-size: 16px;
    color: #343434;
    span {
      color: #4593f5;
    }
  }

  .description_area {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .description_title {
      position: absolute;
      left: calc(50% + 10px);
    }

    .cur_letter_count {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        width: 120px;
        font-size: 14px;
        font-family: Pretendard_bold;
      }

      p {
        font-size: 14px;
        font-family: Pretendard_bold;

        span {
          color: var(--color-Dodger-Blue);
        }
      }
    }
  }

  .content_area {
    display: flex;
    align-items: center;
    column-gap: 21px;
    position: relative;
    .graph {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 375px;
      height: 360px;
      border-radius: 15px;
      border: solid 1px #d8d8d8;

      h2 {
        font-size: 14px;
        font-family: Pretendard_bold;
        color: var(--color-Scorpion);
      }
    }
    .text_count {
      position: absolute;
      bottom: 11px;
      right: 15px;
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #606060;
    }
    textarea {
      margin-right:20px;
      padding: 15px;
      //width: 574px;
      width:100%;
      height: 360px;
      font-size: 14px;
      line-height: 1.3;
      border-radius: 15px;
      border: solid 1px #d8d8d8;
      resize: none;
      word-break: keep-all;
    }
  }

  table {
    margin-bottom: 34px;
    width: calc(100% - 20px);
    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }
    th:nth-child(3),
    td:nth-child(3) {
      padding-left: 15px;
      text-align: left;
    }
  }

  .choice_btn {
    display: flex;
    align-items: center;
    .semi_title {
      margin-bottom: 0;
    }
    > div {
      display: flex;
      align-items: center;
      border-radius: 14px;
      border: solid 0.8px #f0f0f0;
      background-color: rgba(240, 240, 240, 0.3);
      padding: 6px 10px;
      margin-left: 15px;
      font-family: Pretendard_regular;
      font-size: 14px;
      letter-spacing: -0.5px;
      color: #343434;
      input[type="radio"] {
        vertical-align: middle;
        appearance: none;
        width: 18px;
        height: 18px;
        border: solid 1px #959595;
        background-color: #f0f0f0;
        border-radius: 50%;
      }
      input[type="radio"]:checked {
        border: 0.3em solid #0078f1;
        background-color: #fff;
      }
      label {
        margin-left: 5px;
        span {
          border-radius: 5px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
          margin-left: 4px;
          padding: 2px 5px;
          font-size: 10px;
          color: #fff;
        }
      }
    }
  }
  .check_list {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    > p:nth-child(2) {
      margin-right: 20px;
      font-size: 14px;
      font-family: Pretendard_regular;
      span {
        margin-left: 5px;
        font-family: GmarketSans;
        font-weight: 500;
        color: #0078f1;
      }
    }
  }
  .btn_save {
    margin: 30px auto 31px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 38px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 10px;
    background-image: linear-gradient(to left, #0d7, #0078f1);
  }

  canvas {
    background-color: #ffffff;
  }
}
