.researchTechMy {
  margin-top: 18px;
  .researchTechMy_menu {
    width: 250px;
    .category_title {
      display: flex;
      align-items: center;
      font-family: Pretendard_bold;
      font-size: 16px;
      height: 49px;
      i {
        margin-right: 16px;
      }
    }

    ul {
      li {
        display: flex;
        align-items: center;
        margin-left: 40px;
        font-size: 14px;
        height: 49px;
        font-family: Pretendard_bold;
        color: #606060;
        cursor: pointer;

        &.active {
          color: #4593f5;
        }
      }
    }
  }
}
