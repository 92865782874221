#GroupwareSideBar {
  width: 280px;
  padding: 22px 0 0 0;
  border-left: 1px solid #f0f0f0;
  min-height: 100vh;
  flex: none;

  a,
  div {
    display: flex;
    align-items: center;
  }

  iframe {
    border-right: 1px solid #ebecf1;
  }

  iframe.bd_none {
    border-right: none;
  }

  // .sidebar_depth2_item:last-of-type>div {
  //   border-bottom: 1px solid #f0f0f0;
  // }
  // .sidebar_depth2_item:last-of-type>ul {
  //   border-bottom: 1px solid #f0f0f0;
  // }
  .sidebar_depth2_item:last-of-type {
    > div {
      border-bottom: 1px solid #f0f0f0;
    }

    > ul {
      border-bottom: 1px solid #f0f0f0;
      border-top: none !important;
    }
  }

  .sidebar_depth3_item:first-of-type {
    padding-top: 20px !important;
  }

  .sidebar_depth3_item:last-of-type {
    padding-bottom: 20px !important;
  }
}

.holdings_left #GroupwareSideBar {
  padding: 30px 0 0 0;
  .sidebar_depth1 {
    border: none !important;
    padding: 0 20px;
  }
  .sidebar_depth1_item {
    > div {
      display: none;
    }
  }
  .sidebar_depth2_item {
    div {
      border: none !important;
      padding-left: 8px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      &.on {
        border-radius: 10px;
        background-color: #f6f5ff;
        a {
          color: #605bff;
        }
      }
      a {
        color: #8b95a1;
        i {
          margin-right: 10px;
        }
      }
    }
  }
}
