#Counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 125px;
  height: 42px;
  padding: 0 5px;
  border-radius: 21px;
  border: solid 2px #969797;
  background-color: #fff;
  i {
    cursor: pointer;
  }
}
