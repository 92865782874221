.signupPop {
  width: calc(100% - 6px);
  max-width: 380px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 60px 40px 30px 40px;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;

  pre {
    margin-bottom: 30px;
    line-height: 1.5;
  }

  p{
    color: #fff;
  }

  .btn {
    max-width: 250px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #3464eb;
    border-radius: 30px;
    color: #fff;
    padding: 16px 24px;
  }

  .close {
    cursor: pointer;
    color: #999999;
  }
}
