#login_inquiry {
  min-height: 900px;
  width: 1280px;
  margin: 0 auto;
  padding: 50px 0;
  background: url("../../../assets/images/intro/bg-service.png") center center;
  .login_inner {
    width: 540px;
    margin: 0 auto;
  }
  .login_box {
    padding: 25px 34px 35px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    + {
      .login_box {
        margin-top: 20px;
      }
    }
    .title {
      padding-bottom: 22px;
      h3 {
        overflow: hidden;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.14;
        letter-spacing: -1px;
        img {
          vertical-align: middle;
          margin-right: 5px;
        }
        span {
          float: right;
          display: inline-block;
          margin-top: 9px;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.83;
          color: #959595;
        }
      }
      p {
        padding-top: 15px;
        font-size: 16px;
        line-height: 1.38;
      }
    }
    .box_inner {
      padding: 0 10px;
    }
    .input_form {
      > li {
        + {
          li {
            margin-top: 7px;
          }
        }
        label {
          display: inline-block;
          position: relative;
          margin-bottom: 2px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.14;
          letter-spacing: -0.25px;
        }
        label.required {
          &:after {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #e72222;
            position: absolute;
            top: -1px;
            right: -10px;
            content: "";
          }
        }
        .input {
          position: relative;
          input {
            width: 100%;
            height: 42px;
            border: 1px solid #f0f0f0;
            border-width: 0 0 1px;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43;
            color: #959595;
            outline: none !important;
            padding: 0;
          }
        }
        .textarea {
          textarea {
            width: 100%;
            height: 130px;
            padding: 15px 10px;
            border-radius: 5px;
            border: 1px solid #d8d8d8;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43;
            color: #959595;
            outline: none !important;
          }
        }
      }
    }
    .agree {
      padding-top: 15px;
      > li {
        + {
          li {
            margin-top: 4px;
          }
        }
        label {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.26px;
          color: #959595;
          display: flex;
          align-items: center;
          input[type="checkbox"] {
            display: none;
          }
          strong {
            font-weight: 600;
          }
          strong.red {
            color: #ff4949;
          }
          span {
            color: #0078f1;
          }
        }
      }
    }
    .btn {
      padding-top: 20px;
      > * {
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #0078f1;
        border: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -1.33px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .login_box.contact {
    ul {
      li {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.33;
        letter-spacing: -1px;
        color: #0078f1;
        span {
          padding-right: 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.38;
          color: #343434;
        }
      }
    }
  }
  &.mo {
    min-width: auto;
    width: 100%;
    .login_inner {
      width: 100%;
      padding: 0 10px;
    }
    .login_box {
      .title {
        h3 {
          span {
            float: none;
          }
        }
      }
      .agree {
        > li {
          label {
            flex-wrap: wrap;
          }
        }
      }
      &.contact {
        ul {
          li {
            font-size: 20px;
          }
        }
      }
    }
  }
}
