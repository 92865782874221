.board_main {
  width: 1280px;
  border-left: 1px solid #f0f0f0;
  padding: 40px;
  > h5 {
    font-size: 22px;
    letter-spacing: -0.25px;
    color: #343434;
    margin-bottom: 23px;
  };

  .section_items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .section_item {
    min-height: 160px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    position: relative;
    padding: 23px 15px 0 32px;
    > h5 {
      font-size: 22px;
      font-family: Pretendard_bold;
      letter-spacing: -1.1px;
      color: #343434;
    }
    > p {
      font-size: 16px;
      font-family: Pretendard_regular;
      color: #343434;
      margin-top: 15px;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 30px;
      border-radius: 16px;
      background-image: linear-gradient(to right, #0078f1, #01bdb4);
      font-family: Pretendard_medium;
      color: #FFFFFF;
      position: absolute;
      bottom: 21px;
      right: 15px;
    }
  }
}
