@import "src/include";
#docTradeMyTransactionWithdrawal{
	.withdrawal{
		&_box{
			padding: 20px 50px;
            margin-bottom: 40px;
			background-color: #f1f1f1;
			&_title{
				height: 30px;
				line-height: 30px;
				font-family: Pretendard_extraBold;
				font-size: 18px;
			}
			&_list-wrap{
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #606060;
			}
			&_list{
				font-family: Pretendard_bold;
				height: 20px;
				line-height: 20px;
				.style{
					&_blue{
						font-family: Pretendard_bold;
						color: var(--color-Niagara);
					}
					&_red{
						font-family: Pretendard_bold;
						color: var(--color-Alizarin-Crimson);
					}
				}
			}
		}
		&_content{
			&_title-wrap{
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 24px;
				line-height: 24px;
				margin-bottom: 30px;
			}
			&_title{
				width: calc(100% - 487px);
				font-family: Pretendard_bold;
				font-size: 22px;
				color: #343434;
			}
			&_info{
				position: relative;
				width: 487px;
				font-size: 12px;
				color: #606060;
				padding-left: 24px;
				&::after{
					position: absolute;
					content: '';
					width: 24px;
					height: 24px;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					background: url(../../../assets/images/common/icon-badge-information-gray.svg) no-repeat 50% 50%;
				}
			}
		}
	}
}
