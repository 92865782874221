#Renewal {
  width: 1280px;
  min-height: 1140px;
  margin: 0 auto;
  background: linear-gradient(45deg, rgba(86,195,34,0.1881127450980392) 0%, rgba(255,255,255,1) 100%);
  display: flex;
}
#RenewalIntro {
  width: 1040px;
  .search_form {
    width: 751px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 0 #d8d8d8;
    border-style: solid;
    border-width: 2px;
    border-image-source: linear-gradient(to right, #ff7a00 32%, #ffa600);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #fff, #fff), linear-gradient(to right, #ff7a00 32%, #ffa600);
    background-origin: border-box;
    background-clip: content-box, border-box;
    input {
      width: 100%;
      height: 100%;
    }
  }
  .grid_wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(250px, auto);
    gap: 20px;
    margin-top: 30px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      width: 100%;
      height: 100%;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      border-radius: 30px;
      font-size: 28px;
    }
    div:nth-child(1) {
      grid-column: 1 / span 2;
    }
    div:nth-child(4) {
      grid-column: 1 / span 2;
      grid-row: 2 / span 2;
    }
    div:nth-child(7) {
      grid-column: 3 / span 2;
    }
  }
  iframe {
    background-color: #fff;
  }
}
