@import "src/mixin";

#MyPageContainer {
  margin: auto;
  padding-top: 40px;
  display: flex;
  max-width: 1280px;
  column-gap: 30px;

  @include mobile {
    padding-top: 80px;

    #MyPageTab {
      display: none;
    }
  }
}