@import "src/include";
@import "src/mixin";

#RenewalLogin {
  margin: auto;
  max-width: 1280px;
  height: 900px;
  background: url("../../assets/images/login/bg-login.png");

  .flex {
    column-gap: 70px;
  }

  .login_form {
    margin: 0 auto;
    padding-top: 50px;
    width: 540px;
    height: 426px;

    @include mobile {
      height: auto;
    }

    .form {
      margin-bottom: 10px;
      padding: 30px 45px;
      position: relative;
      width: 100%;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;

      .input_wrap {
        margin-top: 40px;
        p {
          font-family: Pretendard_semiBold;
          font-size: 14px;
          color: #343434;
          margin-top: 13px;
        }
      }
      @include mobile {
        border: none;
        box-shadow: none;
      }
      h2 {
        font-family: Pretendard_bold;
        font-size: 28px;
        color: #343434;
      }
      .input_area {
        input {
          height: 60px;
          width: 450px;
          border: none;
          border-bottom: 1px solid #f0f0f0;
          font-family: Pretendard_semiBold;
          font-size: 14px;
          color: #959595;
          padding: 0;
        }
        input:focus {
          border: none;
        }
      }
      .btn_kakao {
        margin: 25px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        font-size: 16px;
        font-family: Pretendard_bold;
        color: #343434;
        background-color: #ffffff;

        i {
          width: 49px;
          height: 49px;
          background-size: cover;
        }
      }

      .btn_login {
        margin-top: 22px;
        display: block;
        width: 450px;
        height: 50px;
        font-size: 16px;
        font-family: Pretendard_bold;
        color: #ffffff;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #0078f1;
      }
    }

    .error {
      margin-top: 22px;
      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.5px;
      text-align: center;
      color: var(--color-Alizarin-Crimson);
    }
  }

  .account_search {
    margin: 22px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    a {
      color: #959595;
      margin-right: 22px;
      font-size: 12px;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
      font-family: Pretendard_semiBold;
      color: #606060;
      @include mobile {
        font-size: 12px;
      }
    }

    .line {
      margin: 0 18px;
      display: inline-block;
      width: 1px;
      height: 12px;
      background-color: #dadada;
    }
  }
  .signup {
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #959595;
    span {
      font-family: Pretendard_extraBold;
    }
  }
  @include mobile {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &.mo {
    width: 100%;
    padding: 0 10px;
    .login_form {
      width: 100%;
      .form {
        h2 {
          font-size: 20px;
        }
        .input_wrap {
          margin-top: 20px;
        }
        .input_area {
          input {
            width: 100%;
          }
        }
        .btn_login {
          width: 100%;
        }
      }
    }
    .account_search {
      align-items: center;
      > a {
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
