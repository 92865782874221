#HoldingsSituation {
  .tabs {
    display: flex;
    margin-bottom: 36px;
    align-items: center;
    justify-content: center;
    button {
      padding: 10px 20px;
      margin-right: 10px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid var(--color-Bright-Blue);
      border-radius: 5px;
      border-radius: 24px;
      &.active {
        background-color: var(--color-Bright-Blue);
        color: white;
        border-color: var(--color-Bright-Blue);
      }
    }
  }
}
