#CyberHeader {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    padding: 0 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 45px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    background-color: #F2F2F2;
    z-index: 2;
}