@import "src/include";

#ResearchTechKeywordSearch {
  .search_wrapper_border {
    background-color: #f4fffa;
    padding: 14px 0;
    border-bottom: solid 1px #0d7;
  }
  .search_wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    > p {
      display: flex;
      align-items: center;
      font-family: Pretendard_bold;
      font-size: 22px;
      min-width: 220px;
      color: #343434;
    }
    .search_info {
      position: absolute;
      width: 242px;
      border-radius: 8px;
      background-color: rgba(52, 52, 52, 0.88);
      top: 100%;
      padding: 8px 5px;
      font-family: Pretendard_bold;
      font-size: 14px;
      color: #fff;
      div {
        display: flex;
        margin-top: 7px;
        color: #fff;
        p {
          margin-left: 10px;
        }
        span {
          color: #06e30b;
        }
      }
    }
    .search {
      margin-right: 20px;
      width: 100%;
      //width: 850px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border-radius: 15px;
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(to left, #0d7, #0078f1);
      border-image-slice: 1;
      background-image: linear-gradient(to bottom, #fff, #fff),
        linear-gradient(to left, #0d7, #0078f1);
      background-origin: border-box;
      background-clip: content-box, border-box;
      input[type="text"] {
        width: 743px;
        height: 21px;
        border: none;
      }
    }
  }
  .btn_history {
    width: 150px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Pretendard_bold;
    font-size: 12px;
    color: #959595;
    height: 24px;
    padding: 0 6px;
    border-radius: 9px;
    background-color: #eff3fb;
  }
  .keyword_search_sub {
    display: flex;
    justify-content: space-between;
    //width: 850px;
    margin: 11px 0px 0 240px;
  }
  .keyword_add_btn {
    display: flex;
    button {
      width: 66px;
      height: 24px;
      border-radius: 14px;
      font-family: Pretendard_extraBold;
      font-size: 12px;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        width: 18px;
        height: 18px;
      }
    }
    button:nth-child(1) {
      color: #4593f5;
      background-color: rgba(107, 211, 255, 0.2);
      border: solid 0.5px #0078f1;
    }
    button:nth-child(2) {
      background: rgba(255, 166, 0, 0.2);
      color: #ffa600;
      border: solid 0.5px #ffa600;
    }
  }
  .keyword_search_wrapper {
    //width: 1280px;
    //padding: 0 50px 0 0;
    //width: calc(100vw - 50px);
    width: 100%;
    margin: 21px 0 13px 0;
    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 22px;
      border-radius: 8px;
    }
    h2 {
      font-family: Pretendard_semiBold;
      font-size: 16px;
      color: #606060;
      letter-spacing: 0.3px;
      span {
        font-family: GmarketSans;
        font-size: 24px;
        color: #0078f1;
      }
    }
    table {
      tbody {
        tr:hover {
          background-color: #e6f2ff;
          cursor: pointer;
        }
      }
    }
  }
  .keyword_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    select {
      width: 100px;
      font-family: Pretendard_semiBold;
      font-size: 14px;
      padding: 0 5px;
    }
    .util_btn {
      display: flex;
      .range_wrapper {
        display: flex;
        align-items: center;
        margin-right: 10px;
        p {
          font-family: Pretendard_medium;
          font-size: 14px;
          letter-spacing: -0.25px;
          color: #959595;
          margin-right: 15px;
        }
        .MuiSlider-thumb {
          background: url("../../../assets/images/common/icon-slider-thumb.png")
            no-repeat center center;
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 40px;
      font-size: 16px;
      color: #fff;
      border-radius: 10px;
      background-image: linear-gradient(to left, #0d7, #0078f1);
    }
  }
  .keyword_btn {
    margin: 10px 0;
    button {
      padding: 5px;
      margin-right: 10px;
      border-radius: 4px;
      color: #ffffff;
      font-family: Pretendard_semiBold;
      font-size: 12px;
    }
    button:nth-child(1) {
      background-color: #4792f7;
    }
    button:nth-child(2) {
      background-color: #e92f2c;
    }
  }
  .research_util_btn {
    //width: 1280px;
    width: 95vw;
    padding: 0 0 0 50px;
    position: relative;
    margin-bottom: 50px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 185px;
      height: 40px;
      font-family: Pretendard_bold;
      font-size: 16px;
      color: #343434;
      background-color: #fff;
      border-radius: 10px;
      border: solid 1px #d8d8d8;
      margin-left: 16px;
      margin-right: 30px;
    }
  }
  .company_list {
    position: absolute;
    width: 850px;
    height: 206px;
    overflow-y: scroll;
    bottom: -210px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #f0f0f0;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    z-index: 5;
    > li {
      display: flex;
      padding: 12px 7px;
      > p {
        font-size: 12px;
        color: #959595;
        border-right: 1px solid #0078f1;
        padding: 0 4px;
        span {
          font-size: 14px;
          color: #343434;
        }
      }
      > p:nth-child(2),
      p:nth-child(3) {
        min-width: 190px;
      }
      > p:nth-child(4) {
        min-width: 100px;
      }
      > p:nth-child(5) {
        min-width: 150px;
        border-right: none;
      }
      > .company_name {
        font-size: 14px;
        letter-spacing: -0.25px;
        color: #0078f1;
        width: 166px;
        @include text-ellipsis(1);
        border-right: none;
      }
    }
    > li:hover {
      background-color: #e6f2ff;
    }
  }
  .no_data_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 32px;
    text-align: center;
    .login_btn {
      border-radius: 0.375rem;
      padding: 0.6rem 1rem;
      font-size: 1rem;
      line-height: 1.25rem;
      background-color: #4e5968;
      color: #fff;
      font-family: "Pretendard_light";
      display: block;
      margin-top: 14px;
    }
  }
  .report_btn {
    background-color: #6698cb;
    padding: 3px;
    width: 70%;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
  }
}
