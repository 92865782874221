#Find {
    @media screen and (max-width: 850px){
        padding: 32px 0 100px 0;
    }

    .pc {
        .form {
            margin: 0 auto;
            width: 1280px;
            height: 900px;
            background: url("../../../assets/images/login/bg-login.png") no-repeat center center;
            padding: 50px 0;
            > div {
                margin: 0 auto 0 auto;
                width: 540px;
                height: 448px;
                padding-top: 27px;
                border-radius: 5px;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                border: solid 1px #e7e7e7;
                background-color: #ffffff;
            }

            .find_format {
                > h2 {
                    margin-bottom: 15px;
                    font-size: 26px;
                    font-family: Pretendard_bold;
                    padding-left: 34px;
                    img {
                        margin-right: 3px;
                    }
                }
                > h5 {
                    font-family: Pretendard_regular;
                    padding-left: 34px;
                    font-size: 16px;
                    line-height: 1.38;
                    margin-bottom: 30px;
                    color: #343434;
                }
                label {
                    display: block;
                    width: 100px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    color: #000000;
                }

                select, input {
                    padding: 8px;
                    width: 250px;
                    height: 45px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.75);
                    border: none;
                    border-bottom: solid 1px #e7e7e7;
                }

                .input_area {
                    display: flex;
                    flex-direction: column;
                    height: 72px;
                    width: 450px;
                    padding-left: 45px;
                    input[type="text"] {
                        width: 450px;
                        margin: 13px 0;
                    }
                }

                .input_area+.input_area {
                    margin-top: 8px;
                }
                .input_area, .btn_find {
                    padding: 0 45px;
                }
                .btn_find {
                    margin-top: 24px;
                }
            }

            .show_find_txt {
                height: 384px;
                text-align: center;

                h2 {
                    height: 63px;
                    font-size: 24px;
                    font-family: Pretendard_extraBold;
                }

                p {
                    margin-top: 67px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                }

                .btn_login {
                    margin-top: 91px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .btn {
            margin: auto;
            display: block;
            width: 450px;
            height: 50px;
            color: #ffffff;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 #d8d8d8;
            background-color: #0078f1;
            font-size: 16px;
        }
    }

    .mo {

        .form {
            margin: auto;
            width: 90%;

            h2 {
                margin-bottom: 28px;
                font-size: 24px;
                font-family: Pretendard_extraBold;
                line-height: 30px;
                color: #443018;
                text-align: center;
            }

            .input_area {
                margin: auto;
                width: 90%;

                p {
                    margin-bottom: 8px;
                    font-size: 12px;
                    font-family: Pretendard_extraBold;
                    line-height: 18px;
                    color: #443018;
                }

                select,
                input {
                    padding: 0 12px;
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                    border: 2px solid #d8d8d8;
                    border-radius: 4px;
                 }
             }

            .input_area+.input_area {
                margin-top: 20px;
            }

            .btn {
                margin: 40px auto 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 208px;
                height: 42px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                line-height: 22px;
                color: #ffffff;
                border-radius: 21px;
                background-color: var(--color-Web-Orange);
            }

            .show_find_txt {
                p {
                    text-align: center;
                }
            }
         }
    }

    .account_search {
        margin: 25px auto 0 auto;
        padding-right: 27px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-family: Pretendard_regular;
        font-size: 12px;
        color: #959595;
        a {
            display: flex;
            align-items: center;
            font-family: Pretendard_extraBold;
            color: #959595;
        }
    }
}
