#ResearchTechEditSideBar {
  width: 220px;
  min-height: 100vh;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  > ul{
    display: flex;
    flex-direction: column;
    > li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 0 20px 10px;
      font-size: 16px;
      color: #343434;
      > ul li{
        padding: 20px 0 20px 15px;
        font-size: 14px;
        color: #606060;
      }
    }
  }
}
