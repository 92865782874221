#MarketInfoEdit {
  margin: auto;
  //padding: 42px 0 0 28px;
  //width: 1280px;
  width: 100%;
  padding: 42px 50px 0 50px;

  .header {
    display: flex;
    h2 {
      font-family: Pretendard_extraBold;
      font-size: 30px;
      letter-spacing: -1px;
      color: #1d334e;
    }
  }
  section {
    margin-top: 59px;
    padding-left: 16px;
    h5 {
      font-family: Pretendard_extraBold;
      font-size: 22px;
      color: #1d334e;
    }
  }
  article {
    margin: 30px 0;
    .step_header {
      display: flex;
      margin-bottom: 20px;
      button {
        padding: 3px 7px;
        height: 24px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #4593f5;
        margin-left: 10px;
        border-radius: 14px;
        display: flex;
        align-items: center;
      }
      i {
        width: 18px;
        height: 18px;
        margin-left: 3px;
      }
    }
    .step_title {
      font-family: Pretendard_bold;
      font-size: 22px;
      color: #4593f5;
    }
    .step_subtitle {
      min-width: 110px;
      font-family: Pretendard_extraBold;
      font-size: 14px;
    }
    .step1_input {
      display: flex;
      align-items: center;
      input[type="text"] {
        //width: 860px;
        width: 100%;
      }
    }
  }

  .step1 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    column-gap: 10px;

    h2 {
      width: 58px;
      font-size: 14px;
      font-family: Pretendard_bold;
      color: #343434;
    }

    input {
      padding: 0 9px;
      width: 812px;
      height: 32px;
      font-size: 14px;
      color: #343434;
      border: 1px solid #d8d8d8;
    }
  }

  .step2 {
    margin-bottom: 20px;
    &_body {
      display: flex;
    }
    &_body_head {
      margin: 12px 0;
      display: flex;

      .desc_info {
        column-gap: 4px;

        h2 {
          min-width: 110px;
          font-size: 14px;
          font-family: Pretendard_bold;
          color: #343434;
        }

        p {
          font-size: 12px;
          color: #343434;
        }
        .btn_crawled {
          display: block;
          height: 24px;
          font-size: 12px;
          color: #4593f5;
          padding: 3px 7px;
          margin-top: 6px;
          border-radius: 14px;
        }
        .btn_delete {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 24px;
          font-size: 12px;
          font-family: Pretendard_extraBold;
          color: #4593f5;
          border-radius: 14px;
          background-color: rgba(107, 211, 255, 0.2);
          margin-top: 6px;
        }
      }

      .txt_info {
        display: flex;
        align-items: center;

        h2 {
          width: 120px;
          font-size: 14px;
          font-family: Pretendard_bold;
          color: #343434;
          text-align: right;
        }

        p {
          width: 85px;
          font-size: 14px;
          font-family: Pretendard_bold;
          color: #343434;
          text-align: right;

          span {
            color: #1bb3f3;
          }
        }
      }
    }

    .image_attach_list {
      border: 1px solid #d8d8d8;

      .item {
        position: relative;
        width: 240px;
        height: 169px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .icon_badge_del {
          position: absolute;
          top: 8px;
          right: 8px;
        }

        &.get_image {
          padding-top: 60px;
          display: flex;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          p {
            margin-top: 20px;
            font-size: 14px;
            font-family: Pretendard_extraBold;
            color: #343434;
          }
        }
      }
    }
    .content_wrapper {
      position: relative;
    }
    textarea {
      width: 858px;
      height: 277px;
      padding: 12px 0 0 6px;
      font-family: Pretendard_semiBold;
      font-size: 14px;
      color: #959595;
    }
    .value_length {
      position: absolute;
      bottom: 13px;
      right: 21px;
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #606060;
    }
  }

  .btns {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    button {
      display: flex;
      align-items: center;
      height: 32px;
      font-size: 14px;
      font-family: Pretendard_bold;
      color: #ffffff;
      border-radius: 4px;
      background-color: #4593f5;

      &.btn_image {
        width: 160px;
      }

      &.btn_txt {
        width: 140px;
      }

      i {
        width: 34px !important;
        height: 34px !important;
        background-size: contain;
      }
    }
  }

  .btn_save {
    margin: 0 auto 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 4px;
    background-color: #4593f5;
    color: #fff;
    font-family: Pretendard_bold;
    font-size: 14px;
    padding: 10px 22px;
  }
}
