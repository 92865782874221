@import "src/include";

#RenewalServiceMain {
  margin: 0 auto;
  // width: 1280px;
  width: 100%;
  position: relative;

  .off {
    opacity: 0.2;
    pointer-events: none;
    position: relative;
  }

  .soon {
    position: relative;
  }

  .soon:before {
    content: "SOON";
    opacity: 1;
    position: absolute;
    border-radius: 14px;
    background-color: #0078f1;
    padding: 6px 8px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 2;
    font-family: Pretendard_extraBold;
  }

  .modal {
    padding: 12px 25px;
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 #d8d8d8, 0 2px 4px 0 #d8d8d8;
    background-color: #0078f1;
    line-height: 1.78;
    color: white;
    z-index: 10;
    position: absolute;
    width: 450px;
    text-align: left;

    > p {
      font-size: 14px !important;
    }
  }

  .empty_list {
    width: 100%;
    height: 140px;
    border-radius: 15px;
    background-color: #e6f2ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 8px;

    > p {
      text-align: center;
      font-size: 16px;
      color: #343434;
      line-height: 1.22;
      margin-bottom: 14px;
    }

    > button {
      padding: 11px 22px;
      border-radius: 10px;
      background-image: linear-gradient(to left, #0d7, #0078f1);
      font-size: 16px;
      color: #fff;
    }
  }

  > header {
    background: url("../../../assets/images/intro/renewal/bg-service-main.png");
    height: 226px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;

    > div {
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: rgba(255, 255, 255, 0.3);
    }

    .header_profile {
      width: 200px;
      height: 200px;
      background-color: #fff;

      .profile_info {
        display: flex;
        align-items: center;
        margin: 40px 0 0 10px;

        > img {
          width: 48px;
          height: 48px;
          background-color: #d8d8d8;
          border-radius: 50%;
        }
      }

      .user_profile {
        margin-left: 14px;

        .department {
          display: inline-block;
          padding: 2px 4px;
          font-size: 10px;
          border-radius: 5px;
          background-color: rgba(107, 211, 255, 0.2);
        }

        .user_name {
          display: flex;
          align-items: center;
          font-size: 20px;

          span {
            margin-left: 3px;
            font-size: 13px;
            color: #959595;
          }
        }
      }

      .oval {
        width: 48px;
        height: 48px;
        background-color: #d8d8d8;
        border-radius: 50%;
      }

      .line {
        height: 18px;
        border: solid 1px #f0f0f0;
        margin: 0 2px;
      }

      .date_time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;

        time {
          font-size: 12px;
          color: #959595;
        }

        .to_work {
          font-size: 14px;

          span {
            font-size: 12px;
          }
        }
      }

      .check_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 14px;

        > button {
          width: 97px;
          height: 40px;
          border-radius: 15px;
          background-color: #0078f1;
          color: #fff;
        }
      }
    }

    .header_fav {
      // width: 1043px;
      width: 720px;
      height: 200px;
      margin-left: 23px;
      padding: 14px;

      h5 {
        font-size: 24px;
        color: #0078f1;
        display: flex;
        align-items: center;
      }

      .fav_list {
        max-width: 100%;
        display: inline-flex;
        justify-content: flex-start;
        margin-top: 24px;
        gap: 10px;
        overflow-x: auto;
        padding-bottom: 18px;

        li {
          width: 72px;
          text-align: center;
          font-size: 16px;
          color: #343434;
          cursor: pointer;
          flex: none;
          > i {
            width: auto;
            // height: 64px;
            height: auto;
            background-size: contain;
            &::before {
              font-size: 28px;
              color: #838383;
              background: #ffffff;
              padding: 16px;
              border-radius: 46px;
              display: inline-block;
            }
          }
          > p {
            padding-top: 6px;
          }
        }
      }
      &.slider {
        .slick-slider {
          margin-top: 14px;
          .slick-track {
            display: flex;
            .slick-slide {
              text-align: center;
              > div {
                padding: 10px;
                > a {
                  > i {
                    width: auto;
                    height: auto;
                    background-size: contain;
                    &::before {
                      font-size: 28px;
                      color: #838383;
                      background: #ffffff;
                      padding: 16px;
                      border-radius: 46px;
                      display: inline-block;
                    }
                  }
                  > p {
                    padding-top: 6px;
                    word-break: auto-phrase;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        .slick-prev {
          right: 40px;
          left: auto;
          top: -20px;
        }
        .slick-next {
          right: 14px;
          top: -20px;
        }
        .slick-prev:before,
        .slick-next:before {
          color: #0078f1;
        }
      }
    }
  }

  section {
    background-color: rgba(240, 240, 240, 0.5);
    padding-bottom: 70px;

    .section_wrap {
      max-width: 1280px;
      margin: 0 auto;
    }

    .section_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;

      p {
        background-image: linear-gradient(to right, #0078f1, #0d7);
        font-size: 28px;
        font-family: GmarketSansTTF_bold;
        letter-spacing: -1.6px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .setting_button {
        button {
          height: 21px;
          border-radius: 14px;
          background-color: #959595;
          padding: 4px 12px;
          color: #ffffff;
          font-size: 12px;
          margin-left: 23px;
        }

        .active {
          background-color: #00ba78;
        }
      }
    }

    .task_item_state {
      width: 46px;
      margin-right: 9px;
      font-size: 10px;
      font-family: Pretendard_bold;
      border-radius: 5px;
      text-align: center;
      padding: 2px 0;
      color: #ffffff;
    }

    .schedule {
      background: #00ba78;
    }

    .ing {
      background: #0078f1;
    }

    .end {
      background: #959595;
    }
  }

  .new_schedule {
    width: 280px;
    background-color: #fff;
    margin-left: 23px;
    .half_box_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 21px 15px;
      font-size: 20px;
      letter-spacing: -0.5px;
      color: #343434;
      a {
        font-size: 12px;
      }
    }
  }

  .schedule_item_list {
    padding: 0 10px;
    height: 100px;
    overflow-y: scroll;

    > li {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #f0f0f0;
      padding: 10px 0;

      > p {
        margin-right: 10px;
      }
    }

    .schedule_classification {
      font-family: NanumSquareOTF_acB;
      font-size: 10px;
      border-radius: 5px;
      background-color: #0078f1;
      padding: 2px 4px;
      color: #ffffff;
    }

    .schedule_time {
      min-width: 100px;
      letter-spacing: -0.35px;
      color: #0078f1;
      text-align: center;
    }

    .schedule_name {
      margin-left: 4px;
      font-size: 14px;
      color: #343434;
      width: 149px;
      @include text-ellipsis(1);
    }
  }

  .dashboard_wrapper {
    display: flex;

    .h195 {
      height: 195px;
    }

    .h325 {
      height: 325px;
    }

    .h335 {
      height: 335px;
      overflow: auto;
    }

    .h378 {
      height: 378px;
    }

    .h405 {
      height: 405px;
    }

    .h535 {
      height: 535px;
    }

    .h597 {
      height: 597px;
    }

    .half_box_wrapper {
      display: flex;
      gap: 22px;
      margin-bottom: 15px;
    }

    .divide_box {
      width: 297px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .dashboard_left,
    .dashboard_right {
      width: 50%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .half_box {
      width: 297px;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
    }

    .half_box_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 21px 15px;

      p {
        display: flex;
        align-items: center;
      }

      p:nth-of-type(1) {
        font-size: 20px;
        letter-spacing: -0.5px;
        color: #343434;
      }

      p:nth-of-type(2) {
        font-size: 12px;
        color: #606060;
      }

      .add_to_do {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #0078f1;
        border-radius: 14px;
        border: solid 0.8px #f0f0f0;
        background-color: rgba(240, 240, 240, 0.3);
        padding: 3px 0 3px 7px;
      }

      .add_to_do.report {
        color: #fff;
        background: #0078f1;
        padding: 5px 12px;
      }
    }

    .todo_input {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      input[type="text"] {
        height: 30px;
      }

      i {
        margin-left: 10px;
      }
    }

    .todo_item_list {
      padding: 0 10px;
      height: 280px;
      overflow-y: scroll;

      > li {
        display: flex;
        align-items: center;
        border-bottom: solid 1px #f0f0f0;
        padding: 10px 0;

        > input[type="text"] {
          width: 250px;
          height: 30px;
        }

        i {
          cursor: pointer;
          flex: none;
        }
      }

      p {
        margin-left: 4px;
        font-size: 14px;
        width: 220px;
        color: #343434;
        @include text-ellipsis(1);
        display: flex;
        align-items: center;

        i {
          margin-right: 6px;
        }
      }

      p.done {
        text-decoration: line-through;
        color: #959595;
      }
    }

    .board_item_list {
      padding: 0 5px 0 18px;
      height: 300px;
      overflow-y: scroll;

      > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        border-bottom: solid 1px #f0f0f0;
        font-family: Pretendard_medium;
        font-size: 14px;
        letter-spacing: -0.35px;
        color: #343434;

        > a {
          display: flex;
          align-items: center;
        }
      }
    }

    .dashboard_box {
      width: 618px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      margin-bottom: 15px;

      .line {
        height: 2px;
        border: solid 1px #f0f0f0;
        margin: 60px 27px;
      }
    }

    .no_text {
      > ul {
        > li:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 124px;
          height: 159px;
          border-radius: 30px;
          background-color: rgba(230, 242, 255, 0.5);

          p:nth-of-type(1) {
            color: #0078f1;
            margin-top: 10px;
          }
        }
      }
    }

    .dashboard_box_tab {
      width: 618px;
      margin-bottom: 15px;

      .dashboard_tab {
        display: flex;
        width: 100%;
        position: relative;

        > div {
          background-size: contain;
          width: 163px;
          height: 50px;
          position: relative;
          .cnt {
            color: #fff;
            background-color: var(--color-Alizarin-Crimson);
            display: inline-block;
            padding: 4px 8px;
            border-radius: 10px;
            position: absolute;
            right: 18px;
            top: 15px;
            font-size: 12px;
          }
        }
      }

      .dashboard_tab_item {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #fff;

        .dashboard_tab_item_list {
          > li {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 10px;
            border-bottom: solid 1px #f0f0f0;
          }

          p {
            margin-right: 10px;
          }

          .item_address {
            width: 78px;
            color: #606060;
            @include text-ellipsis(1);
          }

          .item_title {
            width: 380px;
            @include text-ellipsis(1);
          }

          .item_date {
            color: #606060;
          }
        }
      }
    }

    .quick_item_list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      padding: 0 32px;
      margin-top: 10px;
      text-align: center;
      row-gap: 10px;
    }

    .notification_item_list {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      text-align: center;
      gap: 20px;

      > li {
        cursor: pointer;

        p:nth-of-type(1) {
          font-size: 20px;
          letter-spacing: -0.5px;
          color: #343434;
          font-family: Pretendard_semiBold;
        }

        p:nth-of-type(2) {
          font-size: 22px;
          color: #0078f1;
        }
      }
    }

    .dashboard_item_list {
      display: grid;
      justify-content: flex-start;
      align-items: flex-end;
      grid-template-columns: repeat(4, 124px);
      row-gap: 20px;
      margin-top: 10px;
      text-align: center;
      padding: 0 0 10px 15px;

      li:nth-of-type(1) {
        font-family: Pretendard_semiBold;
        font-size: 18px;

        p {
          margin-top: 0;
        }
      }

      li {
        font-family: Pretendard_regular;
        font-size: 14px;
        min-height: 126px;

        p {
          margin-top: 4px;
        }
      }
    }
  }

  .h195 {
    height: 195px;
  }

  .h220 {
    height: 220px;
  }

  .h405 {
    height: 405px;
  }

  .my_section {
    display: flex;
  }

  .full_box {
    width: 1255px;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    margin: 0 auto 15px auto;
    padding: 18px 24px 0 0;
    &.my {
      width: 936px;
      margin: 0 0 15px 12px;
    }
    &.room {
      width: 297px;
      margin: 0 0 15px 23px;
      padding: 18px 10px 10px;
      > h5 {
        padding-left: 10px;
      }
    }

    > h5 {
      padding-left: 26px;
      font-family: GmarketSansTTF_bold;
      @include text-gradient-green;
      font-size: 28px;
    }
    .full_box_list {
      display: flex;
      align-items: flex-start;
      padding: 1px 7px 14px 13px;
      gap: 20px;
      text-align: center;

      > li {
        min-width: 103px;
      }

      p {
        font-size: 14px;
        padding-top: 10px;
      }

      i {
        width: auto;
        height: auto;
        cursor: pointer;
        &::before {
          font-size: 36px;
          color: #7c7c7c;
          background: #f0f7ff;
          padding: 16px;
          border-radius: 46px;
          display: inline-block;
        }
      }

      .highlight {
        background-color: rgba(230, 242, 255, 1);
        border-radius: 16px;
        // width: 124px;
        // height: 159px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #0078f1;
        pointer-events: none;

        > p {
          // margin-top: 10px;
          font-size: 16px !important;
        }

        i {
          &::before {
            font-size: 36px;
            color: #0078f1;
            background: transparent;
            padding: 0;
            border-radius: 0;
          }
        }
      }
    }

    .box_list {
      display: flex;
      padding: 15px 30px 20px 30px;
      gap: 45px;
      text-align: center;
    }
  }

  .full_box_none_bg {
    width: 1255px;
    margin: 0 auto 15px auto;
    display: flex;
    justify-content: space-between;

    .w831 {
      width: 831px !important;
    }

    .h195 {
      height: 195px;
    }

    .dashboard_box {
      width: 402px;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      margin-bottom: 15px;
      padding: 14px 30px 14px 0;

      .line {
        height: 2px;
        border: solid 1px #f0f0f0;
        margin: 60px 27px;
      }

      .quick_item_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 32px 0 60px;
        // margin-top: 10px;
        margin-top: 50px;
        text-align: center;
        // row-gap: 101px;
        row-gap: 41px;
        column-gap: 41px;

        > li {
          > p {
            padding-top: 10px;
          }
        }
        i {
          cursor: pointer;
          width: auto;
          height: auto;
          &::before {
            font-size: 28px;
            color: #7c7c7c;
            background: #f0f7ff;
            padding: 16px;
            border-radius: 46px;
            display: inline-block;
          }
        }
      }

      .box_list {
        display: flex;
        padding: 15px 30px 20px 30px;
        gap: 40px;
        text-align: center;

        > li {
          width: 80px;
          > p {
            padding-top: 10px;
          }
        }

        i {
          cursor: pointer;
          width: auto;
          height: auto;
          &::before {
            font-size: 28px;
            color: #7c7c7c;
            background: #f0f7ff;
            padding: 16px;
            border-radius: 46px;
            display: inline-block;
          }
        }
      }
    }
  }

  .my_work_wrapper {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // grid-auto-rows: minmax(150px, auto);
    // gap: 10px;
    // padding-bottom: 30px;

    > div {
      margin-top: 25px;
    }

    > div:nth-of-type(2) {
      grid-column: 2 / 4;
    }

    h5 {
      font-family: GmarketSans;
      font-size: 19px;
      color: #0078f1;
      letter-spacing: -0.83px;
      margin: 0 0 4px 10px;
    }

    .my_work_task {
      height: 100px;
      overflow-y: auto;

      > li {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 10px;
        border-bottom: 1px solid #f0f0f0;

        > * {
          margin-right: 8px;
        }

        .task_item_title {
          width: 171px;
          font-size: 14px;
          @include text-ellipsis(1);
        }
      }
    }

    .search_keyword_list {
      height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      > li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
        width: 281px;
        border-bottom: 1px solid #f0f0f0;

        p {
          width: 180px;
          font-size: 14px;
          letter-spacing: -0.7px;
          color: #343434;
          @include text-ellipsis(1);
        }
      }
    }

    .empty_list {
      > li {
        display: flex;
        align-items: center;
        padding: 6px;
        border-bottom: 1px solid #f0f0f0;
        width: 296px;

        > p {
          margin-right: 8px;
        }

        p:nth-of-type(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          padding: 2px 4px;
          background-color: rgba(84, 183, 224, 0.15);
          font-size: 10px;
          letter-spacing: 0.71px;
          color: #343434;
          width: 46px;
        }

        p:nth-of-type(2) {
          width: 190px;
          @include text-ellipsis(1);
        }
      }
    }

    .empty_box {
      width: 100%;
      height: 220px;
      // height: 90px;
      border-radius: 16px;
      background-color: #e6f2ff;
      padding: 30px 0 0 0;
      // padding: 20px 0 0 0;
      z-index: 10;

      .slick-slider {
        width: 100%;

        .cart_slide_item {
          width: 95% !important;
          height: 166px;
          border-radius: 16px;
          border: solid 1px #f0f0f0;
          background-color: #fff;
          display: flex !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        img {
          width: 144px;
          height: 100px;
        }

        p {
          height: 40px;
          padding: 7px 8px;
          font-size: 14px;
          width: 150px;
          line-height: 1.29;
          letter-spacing: -0.25px;
          color: #343434;
          @include text-ellipsis(2);
        }
      }
    }

    .empty_box_2 {
      width: 300px;
      height: 100px;
      border-radius: 15px;
      background-color: #e6f2ff;
    }
  }

  .my_data_room {
    .my_data_wrapper {
      display: flex;
      gap: 21px;
      padding-left: 27px;
      padding-bottom: 30px;

      > div {
        // width: 50%;
      }

      .my_data_task {
        width: 475px;
      }

      .my_data_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 21px;

        p:nth-child(1) {
          font-family: Pretendard_semiBold;
          font-size: 20px;
          letter-spacing: -0.83px;
          color: #0078f1;

          span {
            margin-left: 10px;
            font-family: Pretendard_semiBold;
            font-size: 15px;
            color: #343434;
          }
        }

        p:nth-child(2) {
          font-family: Pretendard_semiBold;
          font-size: 15px;
          color: #343434;
          display: flex;
          align-items: center;
          background-color: #ffffd8;

          span {
            font-size: 22px;
            color: #0078f1;
            margin-left: 10px;
          }
        }

        .slick-slider {
          width: 300px;
          height: 20px;

          .slick-slide {
            width: 300px;
            display: inline-block;
          }

          p {
            font-family: Pretendard_semiBold;
            font-size: 15px;
            color: #343434;
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffd8;
            padding-left: 7px;

            span {
              font-size: 22px;
              color: #0078f1;
              margin-left: 50px;
            }
          }
        }
      }
    }

    .my_data_task {
      .task_search {
        // width: 600px;
        height: 50px;
        margin-top: 11px;
        border-radius: 5px;
        box-shadow: 1px 2px 5px 0 #d8d8d8;
        border-style: solid;
        border-width: 2px;
        border-image-source: linear-gradient(to left, #00e5ff, #ef06d5);
        border-image-slice: 1;
        background-image: linear-gradient(to bottom, #fff, #fff),
          linear-gradient(to left, #00e5ff, #ef06d5);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        align-items: center;

        input[type="text"] {
          width: 94%;
          border: none;
        }
      }

      ul {
        margin-top: 10px;

        li {
          border-bottom: solid 1px #f0f0f0;
          padding: 0 11px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          height: 44px;

          &.mini_loading {
            justify-content: center;
            border: none;
            padding-top: 60px;
          }

          .task_item_title {
            width: 270px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            font-family: Pretendard_bold;
            margin-right: 9px;
          }

          .task_item_amount {
            width: 86px;
            font-size: 14px;
            font-family: Pretendard_bold;
            color: #0078f1;
            margin-right: 9px;
            text-align: center;
          }
        }
      }
    }

    .my_data_market {
      .tag {
        margin-top: 18px;

        li {
          display: inline-block;
          padding-right: 4px;
          vertical-align: top;

          span {
            display: inline-block;
            padding: 0 12px;
            height: 29px;
            border-radius: 14px;
            background: #6bd3ff;
            font-size: 14px;
            font-weight: 800;
            color: #fff;
            line-height: 29px;
            cursor: pointer;
          }

          &:nth-child(2n) {
            span {
              background: rgba(84, 183, 224, 0.3);
              color: #1d334e;
            }
          }
        }
      }

      .market_data {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 12px;
        margin-top: 24px;
        height: 475px;
        overflow-y: auto;
        overflow-x: hidden;

        > div {
          width: 180px;
          height: 166px;
          border-radius: 10px;
          border: solid 1px #f0f0f0;
          background-color: #fff;
          padding: 0 5px;
          &.mini_loading {
            border: none;
            text-align: center;
            margin-top: 34px;
            width: 100%;
            grid-column: 1 / 3;
          }

          img {
            width: 170px;
            height: 110px;
          }

          p {
            margin-top: 10px;
            font-size: 14px;
            color: #343434;
          }
        }
      }
    }
  }
  .todo_list {
    height: 378px;
  }
}
