@import "../../../include";

#Home {
    .inventor_menu {

        .investor_tab_my_info {
            margin-bottom: 7px !important;
            height: 107px !important;
        }

        .tab_date_manage, .tab_invention {
            width: 284px !important;
            height: 117px !important;
        }

        .tab_date_manage {
            margin-bottom: 7px !important;
        }

        .tab_invention {

            a {
                padding: 9px 25px;
            }
        }

        .wrap_tab {

            .tab_date_manage.investor_tab_date_manage {
                height: 61px !important;
            }

            .tab_invention.investor_tab_invention {
                height: 61px !important;
            }
        }
    }
}
