#MyCltGroupPop {
  width: 640px;
  background-color: #fff;
  position: fixed;
  top: 240px;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  button {
    background-color: transparent;
  }
  h4 {
    color: var(--color-Bright-Blue);
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
  }
  .close {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .group_list {
    max-height: 354px;
    overflow-y: auto;
    > ul {
      > li {
        display: flex;
        align-items: center;
        & + li {
          margin-top: 10px;
        }
        input {
          width: 400px;
        }
      }
    }
  }
  .add_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .add_input {
      width: 400px;
    }
  }
  .btns {
    .btn {
      padding: 6px 12px;
      border-radius: 32px;
      min-width: 60px;
      text-align: center;
      margin-left: 10px;
      &.del {
        border: 1px solid var(--color-Alizarin-Crimson);
        color: var(--color-Alizarin-Crimson);
      }
      &.mod,
      &.add {
        border: 1px solid var(--color-Bright-Blue);
        color: var(--color-Bright-Blue);
      }
    }
  }
}
