@import "src/include";
$local-indigo-color: #1d334e;
$local-green-color: #05ac9e;

#MyPrice {
    margin: auto;
    padding-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    width: 1280px;

    .form {
        width: 880px;
    }

    .area {
        margin-bottom: 35px;

        .header {
            display: flex;
            justify-content: space-between;
            height: 30px;

            .page_title {
                font-size: 22px;
                color: #343434;
                font-family: Pretendard_extraBold;
                p {
                    font-family: Pretendard_bold;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
    }

    .box {
        width: 970px;
        background-color: #ffffff;
    }

    .date {
        margin-bottom: 29px;

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 76px;
            font-size: 24px;
            font-family: Pretendard_bold;

            span {
                margin: 0 8px;
                color: $local-green-color;
            }
        }
    }

    .standard {
        margin-bottom: 34px;
        padding: 13px 15px;
        table{
            tbody{
                tr {
                    height: 50px;
                    .payment_area {
                        padding: 20px 40px 0 10px;
                    }
                }
            }
        }
        .header {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            height: 42px;

           .title {
               font-family: Pretendard_semiBold;
               font-size: 27px;
               color: #606060;
           }

            .has_ip_cnt {
                padding: 12px 13px 12px 18px;
                display: flex;
                align-items: center;
                width: 522px;
                font-family: Pretendard_bold;
                color: #343434;
                border-radius: 10px;
                background-color: rgba(240, 240, 240, 0.2);

                .has_ip_cnt_txt {
                    width: 170px;
                    font-family: Pretendard_extraBold;
                    font-size: 16px;
                }

                .list {
                    display: flex;
                    align-items: center;
                    column-gap: 20px;
                }

                .item {
                    position: relative;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    width: 110px;
                    column-gap: 10px;
                    height: 40px;
                    border-left: solid 1px #f0f0f0;
                    padding-left: 10px;
                    h2 {
                        font-size: 14px;
                        font-family: Pretendard_bold;
                    }

                    p {
                        font-size: 16px;
                        font-family: Pretendard_extraBold;
                        color: #4792f7;
                        text-align: right;
                        span {
                            color: #343434;
                            font-family: Pretendard_bold;
                            font-size:14px;
                            padding-left: 4px;
                        }
                    }
                }
            }
        }
        .wrap_txt {
            .wrap_txt_header {
                color: #ffa600;
                padding-top: 12px;
            }
            .wrap_txt_body {
                padding-top: 16px;
            }
            .first {
                padding-top: 16px;
            }
            .wrap_txt_footer {
                font-size: 12px;
                padding-bottom: 10px;
                div:nth-child(1) {
                    padding-top: 42px;
                    line-height: 18px;
                }
                div:nth-child(2) {
                    padding-top: 12px;
                    text-align: right;
                    p {
                        color: #606060;
                    }
                    span:nth-child(1) {
                        text-decoration: underline;
                    }
                    span:nth-child(2) {
                        color:#343434;
                    }
                }
            }
        }
    }

    .wrap_now_plus {

        > .header {
            margin-bottom: 15px;
            padding-left: 15px;
            display: flex;
            color: #343434;

            .inner {
                margin-right: 95px;
                display: flex;
                align-items: center;
                column-gap: 14px;

                h2 {
                    width: 110px;
                    font-size: 22px;
                    font-family: Pretendard_extraBold;
                    color: #343434;
                }

                p {
                    width: 39px;
                    font-family: Pretendard_bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #007d72;
                    text-align: center;
                    @include border-radius(20px);
                    background-color: rgba(128, 206, 183, 0.3);
                }
            }

            .desc {
                font-size: 16px;
                font-family: Pretendard_bold;

                span {
                    font-size: 18px;
                    font-family: Pretendard_extraBold;
                    color: $local-indigo-color;
                }
            }
        }

        .box {
            padding: 25px 16px 20px 10px;

            .header {
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .title {
                    font-size: 27px;
                    font-family: Pretendard_semiBold;
                    color: #606060;
                }

                .inner {
                    h2 {
                        font-size: 16px;
                        font-family: Pretendard_bold;
                        color: #343434;
                    }

                    p {
                        width: 39px;
                        font-family: Pretendard_bold;
                        font-size: 18px;
                        line-height: 24px;
                        color: #007d72;
                        text-align: center;
                        @include border-radius(20px);
                        background-color: rgba(128, 206, 183, 0.3);
                    }
                }
            }
        }
        table {
            th:nth-child(2),
            td:nth-child(2) {
                width: 25%;
            }
        }
        .box+.box {
            margin-top: 25px;
        }
    }

    table {
        thead {
            height: 46px;
            font-family: Pretendard_bold;
            font-size: 14px;
            background-color: #f0f0f0;
            th {
                color: #343434;
            }
            th:last-child {
                text-align: left;
                padding-left: 47px;
            }
        }
        th:first-child,
        td:first-child {
            width: 8%;
        }
        th:last-child,
        td:last-child {
            width: 50%;
        }

        tbody {

            tr {
                height: 72px;

                .td_content {
                    padding-left: 29px;
                    border-left: 1px solid #e7e7e7;
                    text-align: left;

                    > h2 {
                        margin-bottom: 24px;
                        font-size: 16px;
                        font-family: Pretendard_semiBold;
                        color: $local-green-color;
                    }
                }

                td {
                    font-size: 16px;
                    color: #343434;
                }

            }

            tr:last-child {
                height: 55px;
            }
        }
    }

    .info_table {

        th:first-child,
        td:first-child {
            padding-left: 15px;
            width: 40%;
            text-align: left;
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: 40%;
        }

        th:nth-child(3),
        td:nth-child(3) {
            width: 20%;
        }

        td {
            span {
                font-family: Pretendard_bold;
                color: $local-green-color;
            }
        }
    }

    .payment_area {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .wrap_price,
        .wrap_payment {
            display: flex;
            align-items: center;
        }

        .wrap_price {
            padding: 0 22px 0 15px;
            width: 600px;
            display: flex;
            align-items: flex-end;
            h2 {
                font-size: 18px;
                font-family: Pretendard_bold;
                color: #343434;
            }

            .vat {
                width: 80px;
                font-family: Pretendard_semiBold;
                font-size: 11px;
                color: #959595;
                letter-spacing: -0.31px;
                margin-left: 5px;
            }

            .sum_price {
                width: 300px;
                font-size: 22px;
                font-family: Pretendard_extraBold;
                text-align: right;
                color: #343434;
            }
        }

        .wrap_payment {
            padding-left: 27px;
            justify-content: flex-end;
            min-width: 482px;
            width: 100%;
        }

        .terms {
            width: 145px;
            font-size: 12px;
            font-family: Pretendard_bold;
            color: #343434;
            opacity: 0.5;
            margin-right: 20px;

            a {
                font-family: Pretendard_extraBold;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .btn_payment {
            display: block;
            width: 93px;
            height: 45px;
            line-height: 45px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            @include border-radius(4px);
            background-color: var(--color-Alizarin-Crimson);
        }
    }

    .wrap_txt {
        display: flex;
        flex-direction: column;
        .wrap_txt_header {
            color: #4792f7;
            font-size: 18px;
            font-family: Pretendard_extraBold;
        }
        .wrap_txt_body {
            display: flex;
            margin: 10px 0 0 9px;
            font-family: Pretendard_semiBold;
            p {
                color: #606060;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.4px;
                span {
                    font-family: Pretendard_extraBold;
                    color: #343434;
                }
            }
            .icon_badge_open{
                width: 58px;
                height: 18px;
            }
        }
    }

    .wrap_txt+.wrap_txt {
      margin-top: 24px;
    }

    .btn_change {
        width: 132px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: #4593f5;
    }

    .checkbox {
        .checkmark {
            margin-right: 0;
            width: 18px;
            height: 18px;
            border: solid 2px #606060;
        }
        input:checked ~ .checkmark {
            border: none;
            background-color: #4593f5;
        }
    }
    .checkbox .checkmark:after {
        left: 5px;
        top: 1px;
        border-width: 0 2px 2px 0;
    }
    .payment_cost {
        .payment_area {
            padding-top: inherit;
            h2 {
                width: 150px;
                color: #073e55;
                font-family: Pretendard_extraBold;
                font-size: 30px;
            }
            .vat {
                width: 100px;
                font-family: Pretendard_semiBold;
                letter-spacing: -0.4px;
                font-size: 14px;
            }
            .sum_price {
                width: 200px;
                color: #4593F5;
                font-size: 30px;
                font-family: Pretendard_extraBold;
            }
            .wrap_payment {
                padding-right: 40px;
            }
        }
    }
}
