@import "../../../include";

#InvestmentProcess {
    margin: auto;
    padding-top: 43px;
    width: 880px;

    .page_title {
        margin-bottom: 10px;
        font-size: 24px;
        font-family: Pretendard_extraBold;
        line-height: 30px;
        color: #343434;
    }

    .area_box {
        padding: 16px 91px 15px 92px;
        width: 880px;
        height: 207px;
        text-align: center;
        @include border-radius(20px);
        background-color: rgba(241, 241, 241, 0.3);
    }

    .btn_next {
        margin: 50px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 233px;
        height: 52px;
        font-size: 18px;
        font-family: Pretendard_bold;
        color: #ffffff;
        @include border-radius(27px);
        background-image: linear-gradient(to right, #ff7a00, #ffa600 100%);

        span {
            display: block;
            width: 168px;
            text-align: center;
        }
    }
}
