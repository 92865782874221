#MarketInfoHeader {
  margin: 0 auto;
  width: 100vw;
  .tutorial-box {
    display: flex;
    width: 100vw;
    height: 307px;
    background-image: url("../../../assets/images/common/banner.png");
    background-size: 1868px;
    height: 307px;
    margin: 0 auto;
    .wrap_text {
      display: flex;
      justify-content: center;
      margin-left: 73px;
      margin-top: 55px;
      width: 295.7px;
      height: 59px;
      align-items: center;
      justify-content: center;
    }
    h2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard_Bold;
      font-size: 49px;
      //@include text-gradient-green;
      color: #232439;
      margin-bottom: 4px;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
    .content {
      margin-top: 43px;
      margin-left: 10%;
      justify-content: center;
      align-items: center;
    }
    .title p {
      height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-items: center;
      line-height: 20px;
    }
    .title span:nth-child(1) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(3) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(5) {
      font-family: Pretendard_SemiBold;
      font-size: 27px;
      color: #232439;
    }
    .title span:nth-child(6) {
      font-family: Pretendard_Regular;
      font-size: 18px;
      color: #343434;
    }
    .subtitle{
      color: white;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      font-size: 18px;
      p:nth-of-type(1) {
        min-width: 50px;
        color: #FFC900;
      }
      p:nth-of-type(2) {
        line-height: 1.5;
      }
    }
    img {
      position: absolute;
      top: 30px; /* Adjust the top position as needed */
      right: 83px; /* Adjust the left position as needed */
      width: 348px;
      height: 260px;
    }
  }
}
