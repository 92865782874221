@import "src/include";

#PopupTaskDetail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 18px 0;
    width: 1060px;
    max-height: 90vh;
    @include border-radius(5px);
    border: solid 1px #f0f0f0;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    z-index: 10000;
    overflow-y: scroll;
    .detail_dday {
        display: inline-block;
        padding: 3px 8px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #fff;
        border-radius: 5px;
        background-color: #00ba78;
        height: 18px;
    }
    .header_menu {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 55px;
        margin-right: 21px;
        i {
            width: 25px;
            height: 25px;
            cursor: pointer;
        }
    }
    .detail_area {
        padding: 25px 0 0 0;
        background-color: rgba(240,240,240,0.15);
        border-bottom: solid 1px #e7e7e7;
        .post_title {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 25px;
            h2 {
                font-family: Pretendard_extraBold;
                font-size: 20px;
                color: #343434;
                max-width: 844px;
                margin-left: 8px;
            }
        }
    }
    .data_wrap {
        display: flex;
        padding: 0 20px;
        .data_wrap_left {
            > div {
                width: 600px;
                height: 115px;
                border-radius: 5px;
                margin-bottom: 15px;
            }
        }
        .data_wrap_right {
            width: 400px;
            height: 250px;
            padding: 11px 15px 10px 10px;
            border-radius: 5px;
            border: solid 1px #f0f0f0;
            background-color: #fff;
            margin-left: 15px;
            > div {
                display: flex;
                margin-bottom: 16px;
                > p:nth-child(1) {
                    min-width: 80px;
                    letter-spacing: -0.47px;
                    color: #606060;
                    font-size: 14px;
                }
                > p:nth-child(2) {
                    letter-spacing: -0.47px;
                    color: #343434;
                    font-size: 14px;
                    margin-left: 23px;
                }
            }
            .data_btns {
                display: flex;
                button {
                    width: 185px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                    border: solid 1px #d8d8d8;
                    background-color: #fff;
                    margin-bottom: 7px;
                    font-family: Pretendard_bold;
                    font-size: 16px;
                    color: #343434;
                    margin-left: 5px;
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
        .item_date {
            border: solid 1px #f0f0f0;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 25px;
            > div {
                text-align: center;
                > p:nth-child(1) {
                    font-size: 16px;
                    color: #343434;
                }
                > p:nth-child(2) {
                    font-size: 20px;
                    color: #343434;
                    margin-top: 14px;
                    > span:nth-child(1) {
                        font-family: Pretendard_extraBold;
                        font-size: 22px;
                        color: #4593f5;
                    }
                    > span:nth-child(2) {
                        font-family: Pretendard_medium;
                        font-size: 14px;
                        color: #343434;
                    }
                }

            }
        }
        .item_inquries {
            background-color: rgba(240,240,240,0.4);
            padding: 21px 36px 21px 28px;
            font-size: 14px;
            line-height: 1.71;
            letter-spacing: -0.47px;
            color: #343434;
        }
    }
    .data_files {
        width: 1015px;
        margin: 12px auto 0 auto;
        padding: 23px 35px 14px 35px;
        display: flex;
        .data_file_item {
            display: flex;
            width: 80%;
            h2 {
                min-width: 100px;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #606060;
            }
            p {
                display: flex;
                align-items: center;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
                i {
                    margin-right: 5px;
                }
            }
        }
    }
    .content_tab {
        width: 1050px;
        height: 60px;
        border-bottom: 1px solid #f0f0f0;
        padding-left: 20px;
        display: flex;
        a {
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Pretendard_bold;
            font-size: 16px;
            color: #343434;
        }
        .active {
            border-bottom: 3px solid #4593f5;
        }
    }
    .content_area {
        padding: 0 0 24px 0;
        position: relative;
        font-size: 14px;

        button {
            margin-left: auto;
            margin-bottom: 20px;
            display: block;
            width: 140px;
            height: 32px;
            font-size: 14px;
            font-family: Pretendard_extraBold;
            color: #ffffff;
            @include border-radius(4px);
            background-color: #05ac9e;
        }


        .inner {
            .title {
                padding-left: 10px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                height: 32px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #4792f7;
                background-color: rgba(216, 216, 216, 0.5);
            }
        }

        .content {
            padding: 10px 52px 60px 38px;
            line-height: 25px;
        }

        .content * {
            font-size: 13px !important;
        }
    }

    .btn_close {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 185px;
        height: 50px;
        font-size: 16px;
        border-radius: 15px;
        border: solid 1px #d8d8d8;
        background-color: #fff;
    }
}
