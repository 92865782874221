@import "src/include";

#MyPageIpnowAccountEdit {
  margin: auto;
  padding-top: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  width: 1280px;

  .form {
    width: 880px;
  }

  .area {
    .header {
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      height: 30px;

      h2 {
        font-size: 22px;
        font-family: Pretendard_bold;
      }
    }

    .info {
      position: relative;
      padding-left: 49px;
      display: flex;
      flex-flow: wrap;

      .data {
        display: flex;
        align-items: center;
        width: 50%;

        .title {
          width: 100px;
          line-height: 32px;
          font-size: 14px;
          font-family: Pretendard_bold;
        }

        input {
          padding: 0 21px;
          width: 250px;
          height: 32px;
          font-size: 14px;
          color: #343434;
          border: none;
          border-bottom: 1px solid #d8d8d8;
        }

        .value {
          padding: 0 21px;
          display: flex;
          align-items: center;
          width: 250px;
          height: 32px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.87);
        }

        input.btn {
          width: 200px;
        }

        button {
          position: relative;
          right: -6px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 64px;
          height: 24px;
          font-size: 12px;
          font-family: Pretendard_extraBold;
          color: #ffffff;
          @include border-radius(4px);
          background-color: var(--color-Web-Orange);
        }
      }

      .data:nth-child(n + 3) {
        margin-top: 31px;
      }

      .data.msg {
        padding-left: 125px;
        font-size: 11px;
        line-height: 32px;
        color: rgba(255, 0, 0, 0.8);
        opacity: 0;
      }

      .data.show {
        opacity: 1;
      }
    }
  }

  .area+.area {
    margin-top: 112px;
  }

  @import "Common";

  .btn_ip_register {
    width: 100px !important;
  }

  .no_click {
    border: none !important;
    pointer-events: none;
  }

  #KeywordListWithPerson {
    top: 30px;
    left: 130px;
  }
}
