#PopupGrade {
    padding: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 830px;
    height: 95vh;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 9999;

    > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 22px;
        }
    }

    #GradeCompany {

        .header {
            margin: 26px auto 41px auto;
            width: 100%;

            > h2,
            > p {
                display: none;
            }
        }

        .chart {
            width: 650px;
            height: 195px;

            img {
                width: 100%;
            }

            .active_chart {
                width: 650px;

                img {
                    width: 25px;
                }

                img+img {
                    margin-left: 14px;
                }
            }
        }

        div[class^=grade] {
            width: 24px;
            height: 24px;
        }

        .table_grade {

            .data + .data {
                margin-left: 4px;
            }

            .data.active:not(.not):before {
                top: -30px;
            }

            .data.active {

                .border_left_circle,
                .border_right_circle {
                    top: -32px;
                }
            }
        }
    }

    .wrap_table {
        margin-top: 40px;

        h2 {
            margin-bottom: 20px;
            font-size: 22px;
        }

        .table-box-wrap {

            .wrap_list {
                max-height: 200px;

                table {
                    th:nth-child(1),
                    td:nth-child(1),
                    th:nth-child(2),
                    td:nth-child(2),
                    th:nth-child(3),
                    td:nth-child(3),
                    th:nth-child(4),
                    td:nth-child(4),
                    th:nth-child(6),
                    td:nth-child(6),
                    th:nth-child(7),
                    td:nth-child(7) {
                        width: 10%;
                    }
                }
            }
        }

        .circle {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 24px;
            border-radius: 16px;
            pointer-events: none;
        }
    }

    .alert_msg {
        margin-top: 11px;
        display: flex;
        align-items: center;

        i {
            margin-right: 8px;
        }

        p {
          font-size: 14px;
          color: #e72222;
        }
    }

    .icon_download.disable {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}
