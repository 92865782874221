#RenewalSignupCompany {
  min-height: 900px;
  background: url("../../../assets/images/login/bg-login.png") no-repeat center
    center;
  background-size: contain;
  padding-top: 50px;
  #SignupCompanyFirst {
    width: 540px;
    margin: 0 auto;
    .login_box {
      padding: 25px 34px 35px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      margin-bottom: 30px;
      .title {
        padding-bottom: 22px;
        h3 {
          overflow: hidden;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.14;
          letter-spacing: -1px;
          img {
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        p {
          padding-top: 15px;
          font-size: 16px;
          line-height: 1.38;
          color: #343434;
        }
      }
      .box_inner {
        padding: 0 10px;
      }
      .input_form {
        > li {
          + {
            li {
              margin-top: 7px;
            }
          }
          &.type {
            display: grid;
            align-items: center;
            justify-content: center;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            margin-bottom: 28px;
            > button {
              background-color: transparent;
              padding: 12px 16px;
              border: 1px solid #d8d8d8;
              border-radius: 6px;
              &.on {
                background-color: #0078f1;
                color: #fff;
              }
            }
          }
          label {
            display: inline-block;
            position: relative;
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.14;
            letter-spacing: -0.25px;
          }
          label.required {
            &:after {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #e72222;
              position: absolute;
              top: -1px;
              right: -10px;
              content: "";
            }
          }
          .input {
            position: relative;
            input {
              width: 100%;
              padding: 0;
              height: 42px;
              border: 1px solid #f0f0f0;
              border-width: 0 0 1px;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.43;
              color: #959595;
              outline: none !important;
            }
            i {
              display: block;
              width: 26px;
              height: 24px;
              position: absolute;
              top: 50%;
              right: 30px;
              margin-top: -12px;
              cursor: pointer;
              content: "";
            }
            .certify_btn {
              position: absolute;
              top: 50%;
              right: 0;
              display: block;
              width: 71px;
              height: 32px;
              margin-top: -16px;
              border-radius: 4px;
              background-color: #0078f1;
              font-size: 12px;
              line-height: 32px;
              letter-spacing: 0.8px;
              text-align: center;
              color: #fff;
            }
          }
          .msg {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 6px;
            p {
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              font-size: 12px;
              font-weight: 500;
              line-height: 1.5;
              color: #606060;
            }
            p.error {
              color: #e92f2c;
            }
          }
        }
      }
      .agree {
        padding-top: 15px;
        > li {
          + {
            li {
              margin-top: 4px;
            }
          }
          label {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.17;
            letter-spacing: 0.26px;
            color: #959595;
            display: flex;
            align-items: center;
            input[type="checkbox"] {
              display: none;
            }
            strong {
              font-weight: 600;
            }
            strong.red {
              color: #ff4949;
            }
            span {
              color: #0078f1;
            }
          }
        }
      }
      .btn {
        padding-top: 20px;
        > * {
          display: block;
          width: 100%;
          height: 50px;
          line-height: 50px;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 #d8d8d8;
          background-color: #0078f1;
          border: none;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -1.33px;
          text-align: center;
          color: #fff;
        }
        .btn_disabled {
          background-color: var(--color-Alto);
          pointer-events: none;
        }
      }
      .join {
        padding-top: 30px;
        font-size: 12px;
        letter-spacing: 0.26px;
        color: #959595;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a {
          display: flex;
          align-items: center;
          font-weight: 800;
          color: #959595;
        }
      }
    }
    .certify_layer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(52, 52, 52, 0.6);
      .inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 477px;
        padding: 32px 34px 20px;
        border-radius: 30px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #fff;
        text-align: center;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        h3 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 25px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          letter-spacing: -0.5px;
          color: #606060;
          .close {
            cursor: pointer;
          }
        }
        p {
          font-size: 16px;
          line-height: 1.5;
        }
        .input {
          position: relative;
          margin-top: 45px;
          padding: 0 12px;
          input {
            width: 100%;
            padding: 0 70px 0 10px;
            height: 42px;
            border-radius: 5px;
            border: 1px solid #d8d8d8;
            font-size: 14px;
            font-weight: 300;
            line-height: 1.43;
            color: #959595;
            outline: none !important;
          }
          span {
            position: absolute;
            top: 50%;
            right: 32px;
            margin-top: -9px;
            text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
            font-size: 12px;
            line-height: 1.5;
            color: #e92f2c;
          }
        }
        .btn {
          padding-top: 20px;
          > * {
            display: inline-block;
            width: 75px;
            height: 40px;
            border-radius: 4px;
            background-color: rgba(0, 120, 241, 0.2);
            font-size: 14px;
            line-height: 40px;
            color: #fff;
            border: none;
          }
        }
      }
    }

    &.mo {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 20px;
      .login_box {
        .input_form {
          > li {
            .msg {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
        .agree {
          > li {
            label {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  #RenewalSignupCompanySecond {
    width: 540px;
    margin: 0 auto;
    .login_box {
      padding: 25px 34px 35px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      .title {
        padding-bottom: 22px;
        h3 {
          overflow: hidden;
          font-size: 28px;
          font-weight: 700;
          line-height: 1.14;
          letter-spacing: -1px;
          img {
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        p {
          padding-top: 15px;
          font-size: 16px;
          line-height: 1.38;
        }
      }
      .box_inner {
        padding: 0 10px;
      }
      .input_form {
        > li {
          position: relative;
          + {
            li {
              margin-top: 7px;
            }
          }
          label {
            display: inline-block;
            position: relative;
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.14;
            letter-spacing: -0.25px;
          }
          label.required {
            &:after {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #e72222;
              position: absolute;
              top: -1px;
              right: -10px;
              content: "";
            }
          }
          .input {
            position: relative;
            input {
              width: 100%;
              padding: 0;
              height: 42px;
              border: 1px solid #f0f0f0;
              border-width: 0 0 1px;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.43;
              color: #959595;
              outline: none !important;
            }
            input:disabled {
              opacity: 0.4;
            }
            select {
              width: 100%;
              padding-right: 70px;
              height: 42px;
              border: 1px solid #f0f0f0;
              border-width: 0 0 1px;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.43;
              color: #959595;
              outline: none !important;
              //background: url('../images/join_step02/select-arrow.svg') no-repeat right center;
              -webkit-appearance: none;
              appearance: none;
            }
            .search_btn {
              position: absolute;
              top: 50%;
              right: 0;
              display: block;
              margin-top: -16px;
            }
          }
          .setting {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            right: 0;
            font-size: 12px;
            letter-spacing: 0.26px;
            color: #0078f1;
            .switch {
              display: inline-block;
              width: 42px;
              height: 18px;
              padding: 3px 4px;
              margin-left: 5px;
              border-radius: 12.5px;
              border: solid 1px #959595;
              vertical-align: middle;
              cursor: pointer;
              &:after {
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #0078f1;
                content: "";
              }
            }
            .switch.on {
              border-color: #0078f1;
              background: #0078f1;
              &:after {
                margin-left: 22px;
                background: #fff;
              }
            }
            .hover {
              position: absolute;
              bottom: calc(100% + 8px);
              left: 0;
              width: 422px;
              padding: 12px 15px 15px;
              border-radius: 15px;
              background-color: rgba(52, 52, 52, 0.9);
              text-align: left;
              h5 {
                padding-bottom: 6px;
                font-size: 14px;
                font-weight: 800;
                color: #4593f5;
              }
              p {
                font-size: 14px;
                font-weight: 300;
                color: #fff;
              }
            }
          }
        }
      }
      .btn {
        padding-top: 20px;
        > * {
          display: block;
          width: 100%;
          height: 50px;
          line-height: 50px;
          border-radius: 4px;
          box-shadow: 0 2px 4px 0 #d8d8d8;
          background-color: #0078f1;
          border: none;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: -1.33px;
          text-align: center;
          color: #fff;
        }
        .btn_disabled {
          background-color: var(--color-Alto);
          pointer-events: none;
        }
      }
      .btn.white {
        padding-top: 8px;
        > * {
          box-shadow: none;
          background-color: #fff;
          border: 1px solid #959595;
          color: #606060;
        }
      }
    }
    &.mo {
      width: 100%;
      .login_box {
        .input_form {
          > li {
            position: static;
          }
        }
      }
    }
  }
}
