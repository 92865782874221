#EstimatePopUp {
  width: 100vw;
  height: 120vh;
  background-color: rgba(88,88,88, 0.5);
  position: fixed;
  z-index: 9998;
  > div {
    width: 600px;
    min-height: 350px;
    padding: 30px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    i {
      position: absolute;
      top: 30px;
      right: 30px;
    }
    h2 {
      font-size: 28px;
      font-family: Pretendard_bold;
    }
    p {
      margin: 20px 0;
      line-height: 1.5;
    }
  }
}
