@import "src/include";
@import "src/mixin";

#TaskTable {
    th {
      position: relative;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .use_button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
      }
    }

    thead {
      background-color: #f0f0f0;
      th{
        color: #343434;
        font-family: Pretendard_bold;
        height: 46px;
      }
      tr {
        width: calc(100% - 12px);
      }
    }

    tbody {
      td {
        padding: 0;
        > div {
          position: relative;

          .hover_show {
            position: absolute;
            top: 100%;
            left: 0;
            padding: 20px 17px 19px;
            display: none;
            max-width: 800px;
            width: 100vw;
            font-size: 14px;
            line-height: 21px;
            color: #343434;
            text-align: left;
            border-radius: 9px;
            background-color: rgba(0, 0, 0, 0.85);
            z-index: 1000;
            pointer-events: none;

            .summary_content {
              @include text-ellipsis(2);
              word-break: keep-all;
            }

            * {
              font-size: 14px !important;
              font-family: Pretendard_semiBold !important;
              letter-spacing: 0 !important;
              text-indent: 0 !important;
              color: #ffffff !important;
              background-color: transparent !important;
            }

            h2 {
              font-family: Pretendard_extraBold;
              color: var(--color-Web-Orange) !important;
            }

            table {
              td {
                border-color: #ffffff !important;
                background: transparent !important;
              }
              tbody {
                tr {
                  border: 1px solid #ffffff !important;
                }
              }
            }
          }

          i:hover ~ .hover_show {
            display: block;
          }

          .hover_show:hover {
            display: block;
          }
        }

        > div:hover ~ .hover_show {
          display: block;
        }

        > p {
          @include text-ellipsis(2);
          line-height: 21px;
        }

      }

      tr {
        height: 50px;

        td {
          font-size: 13px;
          @include mobile {
            font-size:12px;
          }
        }

      }
    }

    .table_title {
      text-align: left;
    }

    .money {
      font-family: Pretendard_bold;
    }

    td.day {
      font-family: Pretendard_bold;
      color: var(--color-Cornflower-Blue);
    }
    .reception_deadline_date {
      font-family: Pretendard_semiBold;
    }
    .state {
      .end,
      .schedule,
      .ing,
      .all {
        display: inline-block;
        width: 62px;
        font-size: 12px;
        font-family: Pretendard_semiBold;
        color: #ffffff;
        border-radius: 10px;
        letter-spacing: 1px;
      }

      .end {
        background-color: var(--color-Dusty-Gray);
      }

      .schedule {
        background-color: var(--color-Cornflower-Blue);
      }

      .ing {
        background-color: var(--color-Web-Orange);
      }

      .all {
        background-color: var(--color-Malibu);
      }
    }

    th:nth-child(n + 4),
    td:nth-child(n + 4) {
      width: 100px;
    }

    th:nth-child(9),
    td:nth-child(9) {
      width: 60px;
    }

    th:nth-child(10),
    td:nth-child(10),
    th:last-child,
    td:last-child {
      width: 60px;
    }

    .interest,
    .summary {
      max-width: 50px;
      width: 40px;
    }
}
