@import "src/include";

#Family {
    margin: auto;
    margin-top: 67px;
    width: 90vw;

    .header {
        display: flex;
        justify-content: space-between;

        .title {
            margin-bottom: 13px;
            font-size: 22px;
        }
        .patent_no_list {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87);

            li {
                display: flex;
                align-items: center;

                .flag {
                    margin-left: 10px;
                }
            }
        }
        .btns {
            text-align: right;
            button {
                height: 32px;
                font-size: 16px;
                color: #ffffff;
            }
            .btn_cancle {
                margin-right: 8px;
                width: 64px;
                background-color: #96c719;
            }

            .btn_add {
                margin-bottom: 9px;
                width: 120px;
                background-color: #e92f2c;
            }

            .btn_add_family {
                margin-right: 8px;
                width: 120px;
                background-color: var(--color-Cornflower-Blue);
            }

            p {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                color: rgba(255, 0, 0, 0.8);
            }
        }
    }

    .tree {
        margin-top: 91px;
        overflow-x: scroll;

        .root {
            margin-bottom: 51px;
            position: relative;

            ul {
                li {
                    margin-bottom: 10px;
                    position: relative;
                    display: flex;
                }
            }

            ul:last-child {
                li:last-child {
                    margin-bottom: 0;
                }
            }

            [class^=level_] {
                margin-left: 27px;
                position: relative;
            }
            .level_1 {
                margin-left: 0;
                margin-bottom: 51px;
                display: flex;

                [class^=level_]:before {
                    position: absolute;
                    top: 35px;
                    left: -14.5px;
                    width: 2px;
                    height: calc(100% - 71px);
                    content: '';
                    background-color: var(--color-Web-Orange);
                }
                .level_2 li:last-child:after {
                    position: absolute;
                    top: 37px;
                      left: -14.5px;
                    width: 2px;
                    height: calc(100% - 72px);
                    content: '';
                    background-color: #ffffff;
                }
            }
            .level_1:last-child:after {
                position: absolute;
                top: 37px;
                left: 255px;
                width: 2px;
                height: calc(100% - 72px);
                content: "";
                 //background-color: #ffffff;
                z-index: 99;
            }
            .level_1:not(.single):before {
                position: absolute;
                top: 35px;
                left: 242px;
                width: 15px;
                height: 2px;
                content: '';
                background-color: var(--color-Web-Orange);
            }
            .level_2 li:first-child:before {
                left: -27px;
                width: 27px;
            }
            [class^=level_] li::before {

                position: absolute;
                top: 35px;
                left: -13.5px;
                width: 13.5px;
                height: 2px;
                content: '';
                background-color: var(--color-Web-Orange);
            }
            .level_2 li.dp_0::before {
                background-color: transparent;
                border-bottom: var(--color-Web-Orange) 2px dashed;
            }
            .node {
                display: table;
                min-width: 242px;
                width: 242px;
                height: 72px;
                border: solid 1px #d8d8d8;

                .idx {
                    width: 39px;
                    font-size: 16px;
                }
                .flag {
                    width: 42px;
                    .flag_txt {
                        font-size: 14px;
                        font-family: Pretendard_bold;
                    }
                }
                .info {
                    width: 159px;
                    padding:0 6px;

                    div {
                        line-height: 30px;
                        text-align: left;
                    }
                    .no {
                        height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.87);
                    }
                    .date {
                        font-size: 12px;
                        color: var(--color-Web-Orange);
                    }
                }
                & > div {
                    display: table-cell;
                    vertical-align: middle;
                    height: 100%;
                    text-align: center;
                }
                & > div+div {
                    border-left: solid 1px #d8d8d8;
                }
            }
        }
        .root:not(.single):after {
            position: absolute;
            top: 35px;
            left: 255px;
            width: 2px;
            height: calc(100% - 71px);
            content: "";
            background-color: var(--color-Web-Orange);
        }
    }

    .checkbox {
        margin-bottom: 3px;

        .checkmark {
            margin-right: 0;
            background-color: white;
            border: 2px solid #000000;
            border-radius: 3px;

            &:after {
                top: 0px;
                left: 4px;
            }
        }

        input:checked ~ .checkmark {
            background-color: #000000 !important;
        }
    }

    .disabled {
        pointer-events: none;
    }
}
