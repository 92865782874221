@import "../../../include";

#CyberLogin {
    padding-top: 86px;

    .head {
        margin: auto;
        display: flex;
        align-items: center;
        column-gap: 17px;
        width: 330px;

        .info {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            h2 {
                font-size: 24px;
                font-family: Pretendard_extraBold;
                text-align: center;
            }
        }
    }

    .form {
        margin: 41px auto 8px auto;
        width: 318px;

        .field {
            font-size: 12px;
            font-family: Pretendard_extraBold;
            line-height: 24px;

            h2 {
                font-size: 12px;
                font-family: Pretendard_extraBold;
                line-height: 24px;
            }

            input {
                padding: 0 10px;
                width: 318px;
                height: 38px;
                font-size: 12px;
                color: #343434;
                border: 2px solid #d8d8d8;
                @include border-radius(3px);
            }
        }

        .field+.field {
            margin-top: 15px;
        }
    }

    .btn_login {
        margin: 15px auto 21px auto;
        display: block;
        width: 330px;
        height: 52px;
        font-size: 16px;
        font-family: Pretendard_extraBold;
        line-height: 24px;
        color: #ffffff;
        @include border-radius(5px);
        background-color: #00a545;
    }

    ul {
        li {
            text-align: center;

            a {
                font-size: 12px;
                font-family: Pretendard_bold;
            }
        }
    }
}
