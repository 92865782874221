@import "src/include";

#MatchingBizEdit{
	.MatchingBizEdit{
		width: 880px;
		margin: 0 auto;
		padding: 85px 0 65px;
		&_title-wrap{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #e7e7e7;
			padding-bottom: 7px;
			margin-bottom: 60px;
		}
		&_title{
			font-family: Pretendard_bold;
			font-size: 22px;
			color: #343434;
		}
		&_btn-wrap{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.btn{
				&_save{
					width: 64px;
					height: 32px;
					line-height: 32px;
					background-color: #19c77c;
					text-align: center;
					font-size: 16px;
					font-family: Pretendard_bold;
					color: #fff;
					border-radius: 4px;
					margin-right: 8px;
				}
				&_cancle{
					width: 64px;
					height: 32px;
					line-height: 32px;
					background-color: #343434;
					text-align: center;
					font-size: 16px;
					font-family: Pretendard_bold;
					color: #fff;
					border-radius: 4px;
				}
			}
		}

		&_content{
			.comInfo{
				&_title-wrap{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 15px;
				}
				&_title{
					font-family: Pretendard_extraBold;
					font-size: 14px;
					color: var(--color-Web-Orange);
				}
				&_btn{
					width: 64px;
					height: 24px;
					line-height: 24px;
					font-size: 12px;
					color: #fff;
					background-color: var(--color-Web-Orange);
					border-radius: 4px;
				}
				&_content{
					height: 38px;
					margin-bottom: 60px;
					&_list-wrap{
						display: flex;
						height: 100%;
						justify-content: space-between;
						align-items: center;
					}
					&_list{
						display: flex;
						height: 100%;
						justify-content: flex-start;
						align-items: center;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #343434;
						border-bottom: 1px solid #e7e7e7;
						padding-bottom: 8px;
						&.comTitle{
							.comInfo_content_info{
								width: 200px;
								height: 16px;
								@include text-ellipsis(1);
							}
						}
					}
					&_title{
						display: inline-flex;
						height: 100%;
						justify-content: flex-start;
						align-items: center;
						margin: 0 15px 0 10px;
					}
					&_info{
						display: flex;
						height: 100%;
						justify-content: space-between;
						align-items: center;
						padding: 0 15px 0 13px;
						&:last-of-type{
							padding-right: 0;
						}
						&_label{
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
							margin-right: 10px;
						}
						&_input{
							padding: 0 10px;
							border: .5px solid #d8d8d8;
							width: 185px;
							height: 30px;
							font-size: 14px;
							color: #343434;
						}
					}
				}
			}
			.inputInfo{
				&_title-wrap{
					display: flex;
					justify-content: space-between;
					margin-bottom: 15px;
				}
				&_title{
					font-family: Pretendard_extraBold;
					font-size: 14px;
					color: #4593f5;
				}
				&_button{
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					.icon_i{
						width: 24px;
						height: 24px;
					}
					.style{
						font-size: 12px;
						color: #606060;
					}
				}
				&_content{
					margin-bottom: 35px;
					&_list-wrap{
						.badge-style{
							display: flex;
							justify-content: flex-end;
							align-items: center;
							position: relative;
							margin-top: 25px;
							text-align: right;
							font-size: 12px;
							color: rgba(255, 0, 0, 0.8);
							.icon_red_dot{
								display: inline-block;
								width: 8px;
								height: 13px;
								background-size: cover;
							}
						}
						&:nth-of-type(2){
							margin-bottom: 60px;
						}
						&.date{
							display: flex;
							justify-content: space-between;
							align-items: flex-start;
							margin-bottom: 50px;
							.inputInfo_content_list-fixed{
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 280px;
								height: 46px;
								padding: 8px 10px 8px 0;
								font-size: 14px;
								border-bottom: 1px solid #e7e7e7;
								margin-right: 13px;
								.firmDate_title{
									display: inline-block;
									width: 85px;
									font-family: Pretendard_bold;
									color: #343434;
								}
								.firmDate_text{
									display: inline-block;
									width: calc(100% - 85px);
									padding-left: 15px;
									color: rgba(0, 0, 0, 0.87);
								}
							}
							.inputInfo_content_list{
								width: calc(100% - 293px);
								height: auto;
								border-bottom: none;
								padding: 0;
								flex-wrap: wrap;
								.list-wrap{
									display: flex;
									justify-content: space-between;
									align-items: center;
									width: 50%;
									height: 46px;
									padding: 8px 30px 8px 0;
									border-bottom: 1px solid #e7e7e7;
								}
								.list-date-wrap{
									display: flex;
									justify-content: center;
									align-items: center;
								}
								.list-date{
									display: flex;
									align-items: center;
									width: 140px;
									height: 30px;
									padding: 0 10px;
									margin-right: 10px;
									font-size: 14px;
									color: rgba(0, 0, 0, 0.87);
								}
								.react-datepicker-wrapper{
									width: auto;
								}
							}
						}
						&.explain{
							margin-bottom: 25px;
							.inputInfo_content_list-title{
								display: flex;
								justify-content: space-between;
								align-items: center;
								margin-bottom: 12px;
								.list_title{
									display: flex;
									justify-content: flex-start;
									align-items: center;
									&_text{
										font-family: Pretendard_bold;
										font-size: 14px;
										color: #343434;
										margin-right: 10px;
									}
									&_sub{
										font-size: 12px;
										color: #343434;
									}
								}
								.list_btn{
									display: flex;
									justify-content: space-between;
									align-items: center;
									width: 252px;
									&_text{
										font-family: Pretendard_bold;
										font-size: 14px;
										color: #343434;
										margin-right: 30px;
									}
									&_file{
										display: flex;
										justify-content: center;
										align-items: center;
										.upload-name{
											font-size: 14px;
											color: #343434;
											border: none;
											background-color: transparent;
										}
										&_label{
											text-indent: -9999px;
											width: 24px;
											height: 24px;
											cursor: pointer;
										}
									}
								}
							}
							.inputInfo_content_list-content{
								.textArea{
									width: 100%;
									height: 245px;
									border: .5px solid #d8d8d8;
									resize: none;
									padding: 10px;
								}
							}
						}
						&.price{
							.inputInfo_content_list{
								display: flex;
								justify-content: space-between;
								align-items: center;
								width: 280px;
								height: 46px;
								padding: 8px 0;
								.price_label{
									font-family: Pretendard_bold;
									font-size: 14px;
									color: #343434;
								}
								.price_input-wrap{
									height: 100%;
								}
								.price_input{
									width: 160px;
									height: 100%;
									border: .5px solid #d8d8d8;
									font-size: 14px;
									color: rgba(0, 0, 0, 0.87);
									margin-right: 8px;
								}
								.price_input + span{
									font-family: Pretendard_bold;
									font-size: 14px;
								}
							}
						}
					}
					&_list{
						display: flex;
						height: 46px;
						justify-content: space-between;
						align-items: center;
						padding: 8px 0;
						border-bottom: 1px solid #e7e7e7;
						.list-title{
							display: flex;
							align-items: center;
							width: 85px;
							height: 100%;
							font-family: Pretendard_bold;
							font-size: 14px;
							color: #343434;
						}
						.list-info{
							width: calc(100% - 85px);
							height: 100%;
							padding-left: 10px;
							border: .5px solid #d8d8d8;
							color: #343434;

						}
						&.radio-style{
							height: auto;
							align-items: flex-start;
							.list-title{
								width: 60px;
								height: 30px;
							}
							.list-info{
								display: flex;
								justify-content: flex-start;
								align-items: flex-start;
								width: calc(100% - 60px);
								border: none;
								.list-info-fixed{
									width: 100px;
								}
								.list-info_content{
									display: flex;
									flex-wrap: wrap;
									justify-content: flex-start;
									align-items: center;
								}
							}
							input[type="radio"] {
								width: 24px;
								height: 24px;
								margin-right: 5px;
							}

							input[type="checkbox"] {
								margin: 0 5px;
							}

							label{
								height: 30px;
								line-height: 30px;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.87);
								margin-right: 14px;
							}
						}
					}

				}
				&_agree-wrap{
					margin-bottom: 45px;
				}
				&_agree{
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-family: Pretendard_bold;
					font-size: 14px;
					color: #343434;
					margin-bottom: 25px;
					&_check{
						label{
							padding-left: 0;
							padding-right: 48px;
							font-family: Pretendard_bold;
							font-size: 14px;
							color: #343434;
							margin-right: 0px;
							&::before{
								left: unset;
								right: 0;
							}
						}
					}
				}
				&_agree_more{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					&_btn{
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-right: 20px;
						font-family: Pretendard_bold;
						font-size: 12px;
						color: #999;
						button[type="button"]{
							font-family: Pretendard_extraBold;
							color: #999;
							background-color: transparent;
							font-size: 12px;
							padding-left: 5px;
							text-decoration: underline;
						}
					}
				}
				&_btn{
					button[type="submit"]{
						display: block;
						width: 120px;
						height: 32px;
						line-height: 32px;
						background-color: #19c77c;
						font-size: 16px;
						color: #fff;
						text-align: center;
						border-radius: 4px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
