@import "src/include";

#MarketInfoMain {
  margin: auto;
  //padding: 10px 0 100px 0;
  //width: 1280px;
  width: 100%;
  padding: 10px 50px 100px 50px;
  display: flex;
  &.show {
    .MarketCart {
      display: block !important;
    }
  }
  .marketInfoMain {
    width: 100%;
    //width: 1060px;
    .wrap_category {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .category {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-size: 22px;
        font-family: Pretendard_extraBold;
        color: #343434;
      }

      a {
        padding: 0 0 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 30px;
        border-radius: 9px;
        background-color: #eff3fb;

        span {
          display: block;
          width: 87px;
          font-size: 12px;
          font-family: Pretendard_bold;
          color: #606060;
        }
      }
      .keyword_find {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        height: 40px;
        border-radius: 20px;
        border: solid 2px #4593f5;
        background-color: #fff;
        input {
          width: 201px;
          height: 24px;
          color: #343434;
          border: none;
          font-family: Pretendard_bold;
          font-size: 14px;
        }
        button {
          margin-left: 10px;
        }
      }
      .btn_marketinfo_report {
        height: 42px;
        margin-left: 15px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        border-radius: 4px;
        border: solid 1px #d8d8d8;
        background-color: #ffffff;
        color: #343434;
        i {
          margin-right: 5px;
        }
      }
    }

    &_header {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      //width: 1060px;
      height: 60px;
      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #f0f0f0;
      }

      .s_category {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 60px;
          font-size: 18px;
          font-family: Pretendard_bold;
          color: #343434;
          cursor: pointer;
        }

        li.active {
          border-bottom: 3px solid #4593f5;
        }
      }
    }

    &_list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 10px;
      @media screen and (min-width: 1920px) {
        grid-template-columns: repeat(5, 1fr);
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 90%;
      height: 285px;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      .chart_isComplete {
        width: 85px;
        height: 24px;
        line-height: 21px;
        text-align: center;
        padding: 2px;
        font-size: 12px;
        background-color: #ff6d6e;
        color: #ffffff;
        font-family: Pretendard_semiBold;
        flex: none;
        border-radius: 14px;
        margin-top: 4px;
      }
      img {
        width: 100%;
        height: 140px;
      }

      .info {
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        // border-bottom: 1px solid black;
        h2 {
          width: 100%;
          font-size: 14px;
          font-family: Pretendard_bold;
          min-height: 40px;
          line-height: 1.43;
          color: #343434;
          @include text-ellipsis(2);
          word-break: break-word;
        }
      }
      .gpt_summary {
        font-size: 14px;
        @include text-ellipsis(2);
        word-break: break-word;
        margin: 8px 0;
        line-height: 14px;
        font-family: "Pretendard_regular";
      }
      .etc {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 6px;
        .etc_btn {
          display: flex;
          flex-wrap: wrap;
        }
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          margin-right: 5px;
        }
        .content_btn {
          width: 85px;
          height: 24px;
          font-family: Pretendard_extraBold;
          font-size: 12px;
          color: #4593f5;
          border-radius: 14px;
          background-color: rgba(107, 211, 255, 0.2);
        }
        .chart_btn {
          width: 85px;
          height: 24px;
          font-family: Pretendard_extraBold;
          font-size: 12px;
          color: #4593f5;
          border-radius: 14px;
          border: solid 0.8px #f0f0f0;
          background-color: rgba(240, 240, 240, 0.3);
        }
      }
      input[type="checkbox"] {
        display: none;
      }
    }
  }

  @media screen and (max-width: 850px) {
    padding-top: 80px;
    padding-bottom: 200px;

    .marketInfoMain {
      .wrap_category {
        margin: 0 5vw 10px 5vw;
        width: 90vw;

        .category {
          i {
            display: none;
          }

          p {
            font-size: 18px;
            font-family: Pretendard_extraBold;
            color: #1d334e;
          }
        }
      }

      .marketInfoMain_header {
        margin-bottom: 25px;
        padding: 0 20px;
        width: 100vw;

        .s_category {
          li {
            font-size: 16px;
          }
        }
      }
    }

    .floating_cart {
      position: fixed;
      right: 20px;
      bottom: 42px;
      display: flex !important;
      align-content: center;
      justify-content: center;
      width: 150px;
      font-size: 14px;
      font-family: Pretendard_extraBold;
      color: #ffffff;
      border-radius: 28px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
      background-color: #ffa600;

      i {
        height: 56px;
        background-position: center;
      }

      p {
        line-height: 56px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .marketInfoMain_item {
      width: 45vw;
      height: 202px;

      .info,
      .etc {
        display: block;
      }

      .info {
        h2 {
          font-family: Pretendard_extraBold;
        }

        p {
          font-size: 12px;
        }
      }

      .etc {
        button {
          margin-left: auto;

          span {
            font-size: 12px;
            color: #606060;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .checkbox {
      justify-content: left;
    }
  }
}
