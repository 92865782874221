#GroupwareBoard {
  width: 100%;
  padding: 40px;
  .title {
    font-size: 24px;
    margin-bottom: 40px;
  }
  .board_items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: none;
    gap: 15px;
  }
  .board_item {
    width: 350px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    position: relative;
    > img {
      width: 165px;
      height: 120px;
      border: 1px solid #d8d8d8;
      margin-right: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 120px;
      h2 {
        padding: 5px 0 0;
        font-size: 14px;
        color: #343434;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      h5 {
        font-size: 14px;
        color: #343434;
      }
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 25px;
      border-radius: 16px;
      background-image: linear-gradient(to right, #0078f1, #01bdb4);
      font-family: Pretendard_medium;
      color: #FFFFFF;
      font-size: 12px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}
