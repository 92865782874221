#CashflowIntro {
  color: #333d4b;
  font-family: "Pretendard_regular";
  background: url("../../../../assets/images/cashflow/cashflow_intro_bg.png")
    no-repeat center top;
  background-size: contain;
  background-color: #fff;
  padding: 100px;
  strong {
    font-family: "Pretendard_bold";
  }
  .top {
    text-align: center;
    .start {
      margin: 30px 0 40px;
    }
  }
  .title {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to left, #0d7, #0078f1);
    font-size: 36px;
    display: block;
    text-align: center;
    padding-top: 30px;
  }
  .start {
    color: #fff;
    background-color: var(--color-Bright-Blue);
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 36px;
  }
  .caculator {
    padding: 40px 30px 0;
    margin-bottom: 60px;
    background: linear-gradient(to right, #f0ecff, #cfdeff);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .img {
      margin-right: 80px;
      > img {
        width: 460px;
      }
    }
    .desc {
      font-size: 20px;
      .cal_tit {
        color: var(--color-Bright-Blue);
        display: block;
        padding-bottom: 30px;
        font-size: 36px;
      }
      .highlight {
        color: var(--color-Bright-Blue);
      }
      > p {
        padding-bottom: 20px;
        line-height: 1.4;
      }
    }
  }
  .start_box {
    background-color: #f9fafb;
    padding: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 18px;
    .link_list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-right: 60px;
      > li {
        border-radius: 30px;
        max-width: 420px;
        &:nth-child(1) {
          background-color: #dce5fb;
        }
        &:nth-child(2) {
          background-color: #e8e2fd;
        }
        &:nth-child(3) {
          background-color: #ccf2f3;
        }
        &:nth-child(4) {
          background-color: #ffeae3;
        }
        > a {
          padding: 30px;
          display: block;
          > div {
            > strong {
              font-size: 24px;
            }
            > p {
              word-break: keep-all;
              padding: 16px 0;
              line-height: 1.6;
            }
          }
          .go {
            text-align: right;
          }
        }
      }
    }
    .text {
      > p {
        line-height: 1.4;
        font-size: 20px;
      }
      > strong {
        font-size: 32px;
        display: block;
        margin: 30px 0;
      }
    }
  }
}
