#BusinessPlanMain {
  //margin: 0 auto;
  //width: 1280px;
  width:100%;
  padding:0 50px 0 50px;
  .banner_wrapper {
    //display:flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
    gap: 10px;
    .banner_item {
      flex-grow: 1;
      height: 200px;
      background-color: #0078f1;
      border-radius: 15px;
      color: white;
      padding: 10px;
      position: relative;
      p {
        //font-size: 22px;
        font-size: 28px;
      }
      button {
        position: absolute;
        right: 10px;
        bottom: 10px;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 10px;
      }
    }
  }
  .categoryList {
    //width: 1310px;
    width:100%;
    margin: 24px auto;
    .category_items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 4px;
      row-gap: 10px;
    }
    .category_item {
      //width: 400px;
      width:90%;
      height: 100px;
      border-radius: 5px;
      box-shadow: 0 2px 3px 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
    }
    .category_item_name {
      display: flex;
      align-items: center;
      i {
        width: 36px;
        height: 36px;
      }
      p {
        margin: 0 5px;
        font-family: Pretendard_bold;
        font-size: 13px;
        color: #606060;
        span {
          font-size: 24px;
          color: #4593f5;
          margin: 0 5px;
        }
      }
    }
  }

  .businessPlanList {
    //width: 970px;
    width:100%;
    margin: 0 auto 50px auto;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      margin-bottom: 20px;
      p {
        font-family: Pretendard_semiBold;
        font-size: 16px;
        color: #343434;
        span {
          font-family: Pretendard_extraBold;
          font-size: 20px;
          color: #54b7e0;
        }
      }
    }
    section {
      i {
        cursor: pointer;
      }
    }
  }

}
