@import "src/include";

#InventionDetail {
    margin: auto;
    padding-top: 40px;
    width: 880px;

    > .header {
        margin-bottom: 75px;
        height: 61px;

        .title {
          margin-bottom: 4px;
          font-size: 22px;
        }

        .info {
            margin-top: 4px;
            position: relative;
            float: left;

            .state {
                padding: 0 6px;
                min-width: 106px;
                line-height: 24px;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                @include border-radius(16px);
                background-color: #00ae02;
                cursor: pointer;

                .icon_more {
                    position: absolute;
                    top: -4px;
                    right: -24px;
                    width: 24px;
                    height: 24px;
                }
            }
            ul {
                position: absolute;
                top: 30px;
                background-color: #ffffff;
                z-index: 9;

                button {
                    display: block;
                    width: 120px;
                    height: 32px;
                    font-size: 12px;
                    border: solid 1px #d8d8d8;
                    background-color: #ffffff;
                }
                button+button {
                    border-top: none;
                }

                button:hover {
                    background-color: rgba(0, 149, 174, 0.2);
                    transition-duration: 0.5s;
                }
            }
        }

        .btns {
            float: right;
            display: flex;
            position: relative;
            align-items: center;

            button {
                line-height: 32px;
                font-size: 16px;
                color: #ffffff;
            }
            button+button {
                margin-left: 8px;
            }
            .btn_edit {
                margin-left: 12px;
                width: 64px;
                background-color: #96c719;
            }
            .btn_save {
                margin-left: 12px;
                width: 64px;
                background-color: #05ac9e;
            }
            .btn_cancle {
                margin-left: 12px;
                width: 64px;
                background-color: #96c719;
            }
            .btn_add {
                width: 130px;
                background-color: #e92f2c;
            }
            ul {
                position: absolute;
                right: 0;
                bottom: -68px;
                width: 120px;
            }
        }
    }

    .form {
        .field {
            .header {
                margin-top: 60.5px;
                margin-bottom: 42px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 32px;

                .title {
                    font-size: 22px;
                }
                .btn_add {
                    width: 120px;
                    line-height: 32px;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #05ac9e;
                }
            }
            .rows {
                display: flex;
                flex-wrap: wrap;
                .row {
                    display: flex;
                    align-items: center;
                    width: 283px;
                    min-height: 45px;
                    font-size: 14px;
                    border-bottom: solid 1px #e7e7e7;

                    .title {
                        position: relative;
                        width: 100px;
                        line-height: 45px;
                        font-family: Pretendard_bold;

                        .icon_badge_del {
                            position: absolute;
                            top: 10px;
                            right: 0px;
                        }
                    }

                    .wrap {
                        display: flex;
                        align-items: center;

                        > span {
                            margin-right: 6px;
                        }
                    }
                    button+button {
                        margin-left: 6px;
                    }

                    input {
                        padding: 0 8px;
                        width: 150px;
                        height: 30px;
                        font-size: 14px;
                        text-align: left;
                        border: solid 1px rgba(0, 0, 0, 0.56);
                    }
                    .long {
                        margin-right: 0 !important;
                        width: 480px !important;
                    }
                    select {
                        padding-left: 8px;
                        width: 150px;
                        height: 30px;
                        font-size: 14px;
                    }
                    p {
                        max-width: 450px;
                        font-size: 14px;
                        word-break: break-word;
                    }
                }
                .row+.row {
                    margin-left: 15px;
                }
                .row.no_data {
                    border: none;
                }
                .row.between {
                    justify-content: space-between;
                }
                .row.colspan2 {
                    width: 581px;
                }

                ul {
                    position: absolute;
                    background-color: #ffffff;

                    li {
                        button {
                            width: 150px;
                            height: 32px;
                            border: 1px solid rgb(0, 149, 174);
                            background-color: rgb(255, 255, 255);
                            text-align: right;
                            padding: 0px 8px;
                        }
                        button:hover {
                            transition-duration: .5s;
                            background-color: rgba(0, 149, 174, 0.2);
                        }
                    }
                }
            }
            .rows.publish {
                .row:nth-child(4n) {
                    margin-left: 0;
                }
            }
            .rows.center {
                justify-content: center;
            }
            .rows.right {
                justify-content: flex-end;
            }
        }
    }
}

#InventionDetail.isNew {
    .icon_download {
        display: none;
    }
}
