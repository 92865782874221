@import "src/include";

#consultComDetail{
    .pc{
		padding: 46px 0 0;
		.consultComDetail{
			width: 880px;
			margin: 0 auto;
			&_title{
				font-size: 22px;
				font-family: Pretendard_bold;
				margin-bottom: 30px;
			}
			&_content{
				&_info{
					display: flex;
					height: 350px;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 25px;
					&_img{
						width: 350px;
						height: 100%;
						background-color: #f1f1f1;
						img{
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					&_content{
						width: 500px;
						height: 100%;
						&_title{
							height: 48px;
							line-height: 30px;
							font-family: Pretendard_extraBold;
							font-size: 16px;
							color: #343434;
							padding: 9px 0;
							border-bottom: 1px solid #e7e7e7;
						}
						.list{
							display: flex;
							justify-content: space-between;
							align-items: center;
							flex-wrap: wrap;
							margin-bottom: 5px;
							&_title{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: 80px;
								height: 48px;
								padding: 9px 0;
								font-family: Pretendard_bold;
								font-size: 14px;
								color: #343434;
								border-bottom: 1px solid #e7e7e7;
							}
							&_info{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: calc(100% - 80px);
								height: 48px;
								padding: 9px 0;
								font-family: Pretendard_semiBold;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.87);
								border-bottom: 1px solid #e7e7e7;
							}
						}
						&_text-wrap{
							.text{
								overflow: hidden;
								height: 65px;
								font-size: 14px;
								color: rgba(0, 0, 0, 0.87);
								line-height: 1.5;
								padding: 0 5px;
								margin-bottom: 4px;
								@include text-ellipsis(3);
							}
							.btn-wrap{
								display: flex;
								justify-content: flex-end;
								align-items: center;
								.copy{
									display: flex;
									justify-content: center;
									align-items: center;
									width: 140px;
									height: 32px;
									text-align: center;
									background-color: #1bb3f3;
									border-radius: 4px;
									font-family: Pretendard_extraBold;
									font-size: 14px;
									color: #fff;
								}
							}
						}
					}
				}
				&_intro{
                    &_title-wrap{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding-bottom: 8px;
                    }
					&_title{
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #343434;
					}
                    &_file{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 281px;
                        padding-right: 12px;
                        &_title{
                            font-family: Pretendard_bold;
                            font-size: 14px;
                            color: #343434;
                        }
                        &_btn{
                            width: 24px;
                            height: 24px;
                            text-indent: -9999px;
                            background-color: transparent;
                            background: url(../../../assets/images/common/icon-upload.svg) no-repeat 50% 50%;
                            background-size: cover;
                        }
                    }
					&_section{
						margin-bottom: 40px;
                        border: 1px solid #e7e7e7;
						.content{
							font-family: Pretendard_semiBold;
							font-size: 14px;
							line-height: 24px;
							width: 100%;
                            min-height: 300px;
							color: rgba(0, 0, 0, 0.87);
                            padding: 16px;
							white-space: break-spaces;
							.se-image-container{
								margin-top: 10px;
							}
						}
					}
				}

                &_character{
					&_title{
						height: 38px;
                        line-height: 38px;
						padding-bottom: 8px;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #343434;
					}
					&_section{
						margin-bottom: 40px;
                        border: 1px solid #e7e7e7;
						.content{
							font-family: Pretendard_semiBold;
							font-size: 14px;
							line-height: 24px;
							width: 100%;
                            min-height: 300px;
							color: rgba(0, 0, 0, 0.87);
                            padding: 16px;
							.se-image-container{
								margin-top: 10px;
							}
						}
					}
				}

				&_map{
					padding-bottom: 25px;
					margin-bottom: 60px;
					border-bottom: 1px solid #e7e7e7;
					&_title{
						height: 38px;
                        line-height: 38px;
						padding-bottom: 8px;
						border-bottom: 1px solid #e7e7e7;
						margin-bottom: 25px;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #343434;
						&_title{
							font-family: Pretendard_bold;
							font-size: 14px;
							color: #343434;
						}
					}
					&_section{
						#map{
							width: 100%;
							height: 300px;
							background-color: #f1f1f1;
							margin-bottom: 25px;
						}
						&_addr{
							font-size: 14px;
							color: rgba(0, 0, 0, 0.87);
						}
					}
				}
				&_btn-wrap{
					text-align: center;
					.btn_list{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 120px;
						height: 32px;
						margin: 0 auto;
						background-color: #05ac9e;
						color: #fff;
						font-size: 16px;
						text-align: center;
						border-radius: 3px;
					}
				}
			}
		}
	}
}
