#ResearchTechIntro {
  margin: 100px auto;
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-family: Pretendard_bold;
    font-size: 22px;
    color: #343434;
    margin-bottom: 20px;
  }
  section {
    display: flex;
    .search_type {
      width: 242px;
      height: 245px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 15px;
      box-shadow: 0 2px 3px 1px #f0f0f0;
      background-color: #fff;
      margin-left: 15px;
      padding-top: 17px;
      cursor: pointer;
      div {
        display: flex;
        align-items: center;
        min-height: 114px;
      }
    }
    p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 33px;
    }
    h3 {
      font-family: Pretendard_bold;
      font-size: 16px;
      color: #343434;
    }
    h5 {
      font-family: Pretendard_semiBold;
      font-size: 13px;
      color: #606060;
      line-height: 1.54;
      letter-spacing: -0.28px;
      margin-top: 8px;
    }
  }
}
