.marketInfoMy {
    //margin: 0 auto;
    //padding-top: 18px;
   // width: 1280px;
    width:100%;
    padding:18px 50px 0 50px;
    display: flex;
    &_menu {
        width: 250px;
        h2 {
            display: flex;
            align-items: center;
            font-family: Pretendard_bold;
            font-size: 16px;
            height: 49px;
            color: #343434;
            i {
                margin: 0 5px 0 10px;
            }
        }

        ul {
            li {
                display: flex;
                align-items: center;
                height: 49px;
                margin-left: 40px;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #606060;
                cursor: pointer;

                &.active {
                    color: #4593f5;
                }
            }
        }
    }
}
