#ResearchTechCategory {
  width: 220px;
  .parent_category {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;
  }
  .parent_category_item {
    font-family: Pretendard_bold;
    font-size: 18px;
    color: #343434;
    width: 220px;
    padding-left: 23px;
    margin-bottom: 30px;
    cursor: pointer;
    -webkit-transition: height .3s ease;
  }
  .child_category {
    -webkit-transition: height .3s ease;
  }
  .child_category_item {
    width: 220px;
    font-family: Pretendard_bold;
    font-size: 16px;
    margin-left: 10px;
    padding: 10px;
  }
}
