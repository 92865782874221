#MyPageTab {
  .myPage_title {
    margin-bottom: 15px;
    font-size: 30px;
    font-family: Pretendard_extraBold;
    color: var(--color-Cloud-Burst);
  }

  .tab_list {
    .tab_title,
    .child_tab_item {
      display: flex;
      align-items: center;
      width: 220px;
      height: 49px;
      font-family: Pretendard_bold;
      cursor: pointer;
    }

    .tab_title {
      column-gap: 10px;
      font-size: 16px;
    }

    .tab_item {
      &.active {
        .tab_title {
          color: var(--color-Cornflower-Blue);
        }

        .child_tab_item {
          display: flex;
        }
      }
    }

    .child_tab_item {
      display: none;
      padding-left: 30px;
      font-size: 14px;
      color: var(--color-Scorpion);
      background-color: var(--color-Gallery-opacity-02);

      &.active {
        color: var(--color-Cornflower-Blue);
      }
    }
  }
}
