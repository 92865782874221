#AdminCalcYearPay {
    margin: auto;
    padding-top: 40px;
    width: 980px;
    @import "src/include";

    .header {
        margin-bottom: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: Pretendard_extraBold;
        }

        ul {
            display: flex;
            align-items: center;
            column-gap: 20px;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 35px;
                font-size: 14px;
                font-family: Pretendard_bold;
                @include border-radius(4px);
                box-shadow: 0 2px 3px 1px rgb(0 0 0 / 20%);
                cursor: pointer;

                &.active {
                    color: #ffffff;
                    background-color: var(--color-Web-Orange);
                }
            }
        }
    }

    table {
        margin-bottom: 20px;
    }
}
