@import "src/include";

@keyframes  handUpdown {
    from {
        transform: translateY(-0%);
    }
    to {
        transform: translateY(-20%);
    }
}

#IntroContainer {
    position: relative;

    //.keyword_list {
    //    position: relative;
    //    top: 100%;
    //    width: 560px;
    //    max-height: 445px;
    //    overflow-x: hidden;
    //    overflow-y: scroll;
    //    border-left: solid 1px #e7e7e7;
    //    border-right: solid 1px #e7e7e7;
    //    border-bottom: solid 1px #e7e7e7;
    //    background-color: #ffffff;
    //
    //    li {
    //        margin-top: 3px;
    //        padding-bottom: 3px;
    //        width: 100%;
    //        text-align: left;
    //        background-color: #ffffff;
    //        cursor: pointer;
    //
    //        div {
    //            margin: 0 15px;
    //            padding: 3px 0;
    //            display: flex;
    //            border-bottom: solid 1px #e7e7e7;
    //
    //            h2,
    //            p {
    //                padding: 4px 0;
    //                display: flex;
    //                align-items: center;
    //                min-height: 32px;
    //            }
    //
    //            h2 {
    //                padding-right: 8px;
    //                min-width: 82px;
    //                width: 82px;
    //                font-size: 14px;
    //                font-family: Pretendard_bold;
    //            }
    //
    //            p {
    //                width: 446px;
    //                font-size: 12px;
    //            }
    //        }
    //    }
    //
    //    li:first-child {
    //        padding-top: 7px;
    //        border-top: solid 1px #e7e7e7;
    //    }
    //
    //    li:last-child {
    //        padding-bottom: 7px;
    //        border-bottom: solid 1px #e7e7e7;
    //    }
    //
    //    .active:hover div {
    //        background-color: rgba(255, 166, 0, 0.1);
    //    }
    //
    //    .active:hover h2 {
    //        color: var(--color-Web-Orange);
    //    }
    //}

    .pc {
        .page {
            width: 100%;

            .bg_image {
                position: absolute;
                width: 100%;
                z-index: -1;
            }

            .txt {
                .title {
                    font-size: 46px;
                    font-family: Pretendard_bold;
                }

                .s_title {
                    font-size: 24px;
                    font-family: Pretendard_bold;
                    color: rgba(0, 0, 0, 0.87);
                }

                .desc {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        .banner-wrap{
            width: 100%;
            margin: 0 auto;
        }

        .have_data {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 72px;
            background-color: rgba(107, 211, 255, 0.3);

            p {
                font-size: 18px;
                color: #606060;

                b {
                    font-family: Pretendard_extraBold;
                    color: #1d334e;
                }
            }

            .b_txt {
                margin-left: 10px;
                font-size: 22px;
                font-family: Pretendard_extraBold;
                color: #1d334e;
            }

            .count {
                margin-left: 10px;
                margin-right: 5px;
                padding: 0 13px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 173px;
                height: 50px;
                font-size: 22px;
                font-family: Pretendard_extraBold;
                color: #1bb3f3;
                @include border-radius(15px);
                background-color: #ffffff;
            }

            &.ip {
                background-color: rgba(255, 201, 60, 0.3);

                b {
                    font-family: Pretendard_bold;
                    color: #206a5d !important;
                }

                .b_txt {
                    color: #206a5d;
                }

                .count {
                    color: #81b214;
                }
            }
        }

        .search {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 920px;
            height: 60px;
            margin: 0 auto 40px;

            .search_form {
                display: flex;
                height: 45px;
                justify-content: start;
                align-items: center;
            }

            .search_input {
                width: 557px;
                height: 100%;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 2px solid #d8d8d8;
                border-right: none;
                padding-left: 14px;
                font-size: 14px;
                &::placeholder {
                    font-size: 14px;
                    color: #959595;
                }
            }
            .icon_search {
                position: relative;
                width: 65px;
                height: 100%;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #1bb3f3;
                &::after {
                    position: absolute;
                    content: "";
                    width: 24px;
                    height: 24px;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../../../assets/images/common/icon-search-white.svg)
                        no-repeat 50% 50%;
                    background-size: cover;
                }
            }
            .links {
                width: 264px;
                height: 60px;
                &_btn {
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    background-image: linear-gradient(
                        to right,
                        #ff7a00 32%,
                        #ffa600
                    );
                    text-align: center;
                    &_text {
                        display: inline-flex;
                        align-items: center;
                        position: relative;
                        height: 100%;
                        font-family: Pretendard_extraBold;
                        font-size: 16px;
                        color: #fff;
                        text-align: center;
                        padding: 0 55px 0 60px;

                        &::before {
                            position: absolute;
                            content: "";
                            width: 38px;
                            height: 38px;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                            background: url(../../../assets/images/intro/ico_list.svg)
                                no-repeat 50% 50%;
                            background-size: cover;
                        }

                        &::after {
                            position: absolute;
                            content: "";
                            width: 45px;
                            height: 38px;
                            top: 50%;
                            right: 5px;
                            transform: translateY(-50%);
                            background: url(../../../assets/images/intro/ico_hand.svg)
                                no-repeat 50% 50%;
                            background-size: cover;
                            animation: handUpdown 600ms ease-in-out infinite alternate;
                        }
                    }

                    .btn_pointer {
                        position: relative;
                        top: 20px;
                        width: 45px;
                        animation: up-bounce 1s;
                        animation-iteration-count: infinite;
                        animation-direction: alternate-reverse;
                    }

                    @keyframes up-bounce {
                        0% {
                            top: 20px;
                        }
                        100% {
                            top: 5px;
                        }
                    }
                }
            }
        }

        .page2 {
            margin: auto;
            display: flex;
            align-items: center;
            width: 1280px;
            padding: 0 0 30px 60px;
        }

        .auto_calc_annual_fee {
            position: relative;
            width: 915px;
            height: 184px;
            .bg_image {
                position: absolute;
                top: 0;
                height: 100%;
                @include border-radius(8px);
            }
            .dimmed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                @include border-radius(10px);
            }

            .txt {
                margin: 10px 0 18px 272px;
                .desc {
                    font-size: 24px;
                    font-family: Pretendard_bold;
                    color: #343434;
                    letter-spacing: 0.3px;
                    line-height: 1.2;
                    .color_blue {
                        font-family: Pretendard_extraBold;
                        font-size: 49px;
                        letter-spacing: 0.53px;
                        color: #4792f7
                    }

                    .color_yellow {
                        font-family: Pretendard_extraBold;
                        font-size: 28px;
                        letter-spacing: 0.65px;
                        color: #ffa600;
                    }
                }
            }

            .search {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 620px;
                height: 50px;
                background-color: #ffffff;
                border-radius: 5px;
                margin-left: 272px;
                input {
                    padding-left: 17px;
                    width: 90%;
                    font-family: Pretendard_semiBold;
                    font-size: 16px;
                    border: none;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                input::placeholder {
                    color: #aaa;
                }

                .btn_search {
                    width: 60px;
                    height: 50px;
                    font-size: 16px;
                    background-position: center;
                }
                ul {
                    position: absolute;
                    width: 90%;
                    margin-top: 2px;
                    border-radius: 5px;
                }
            }
        }
        .search_company {
            width: 220px;
            height: 184px;
            background-color: #f6f5f9;
            margin-left: 25px;
            border-radius: 15px;
            position: relative;
            p {
                margin: 12px 0 0 12px;
                font-family: Pretendard_bold;
                font-size: 18px;
                color: #343434;
                position: relative;
                z-index: 2;
                span:nth-of-type(2) {
                    font-family: Pretendard_extraBold;
                    font-size: 22px;
                    line-height: 1.5;
                    color: #ffa600;
                }
                span:nth-of-type(3) {
                    font-family: Pretendard_extraBold;
                    font-size: 37px;
                    color: #807aff;
                }
            }
            img {
                right: -10px;
                bottom: 0;
                position: absolute;
                z-index: 1;
            }
        }
        .page3 {
            width: 1280px;
            height: 560px;
            margin: 0 auto;
            background-image: url("../../../assets/images/intro/bg-intro-page3.png");

            .txt {
                padding: 61px 0 0 68px;

                .title {
                    margin-bottom: 9px;
                    font-family: Pretendard_extraBold;

                    span {
                        font-size: 28px;
                    }
                }
                .desc {
                    font-family: Pretendard_bold;
                    font-size: 24px;
                    line-height: 1.33;
                    letter-spacing: 0.5px;
                    color: #343434;
                }
                button {
                    width: 230px;
                    height: 50px;
                    border-radius: 4px;
                    background-image: linear-gradient(to right, #ff7a00 32%, #ffa600);
                    color: #fff;
                    font-family: Pretendard_bold;
                    font-size: 16px;
                    margin-top: 25px;
                }
            }
        }

        .page4 {
            padding: 50px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            > .title {
                margin-bottom: 30px;
                font-size: 46px;
                font-family: Pretendard_extraBold;
                text-align: center;
            }

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                width: 60%;

                li {
                    flex: 1;
                    position: relative;
                    text-align: center;

                    img {
                        margin: 42px 0 30px 0;
                        height: 205px;
                    }

                    .title {
                        margin-bottom: 3px;
                        font-size: 22px;
                        font-family: Pretendard_extraBold;
                        letter-spacing: 1px;
                        text-align: center;
                        color: #343434;
                    }

                    .desc {
                        font-family: Pretendard_bold;
                        line-height: 1.5;
                        text-align: center;
                        color: #606060;
                        font-size: 16px;
                    }
                }
                li:last-child {
                    img {
                        margin-top: 66px;
                    }
                }

            }

            button {
                margin-top: 22px;
                width: 230px;
                height: 50px;
                border-radius: 4px;
                background-image: linear-gradient(to right, #ff7a00 32%, #ffa600);
                font-family: Pretendard_bold;
                font-size: 16px;
                color: #fff;
            }
        }

        .page5 {
            width: 1280px;
            height: 560px;
            margin: 0 auto;
            display: flex;
            background-image: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(255,255,255,0.1)),
            url("../../../assets/images/intro/bg-intro-page5.png");

            .txt {
                margin: 50px 0 0 68px;
                .title {
                    font-family: Pretendard_extraBold;
                    font-size: 46px;
                    color: #000;
                }

                .desc {
                    margin-top: 14px;
                    font-family: Pretendard_bold;
                    font-size: 24px;
                    line-height: 1.33;
                    letter-spacing: 0.5px;
                    color: #343434;
                }
            }
        }

        .page6 {
            width: 1280px;
            margin: 0 auto;

            > .title {
                margin-bottom: 100px;
                font-size: 46px;
                font-family: Pretendard_extraBold;
                text-align: center;
            }

            .inner {
                display: flex;
                .image_wrapper {
                    width: 764px;
                    height: 600px;
                    position: relative;
                    img {
                        position: absolute;
                        margin: 57px 0 0 44px;
                    }
                    img:nth-of-type(2) {
                        bottom: 10px;
                        right: -25px;
                    }
                }
                .txt {
                    margin-left: 43px;
                    width: 516px;
                    height: 600px;
                    float: left;
                    padding-left: 55px;
                    background-color: rgba(240,240,240,0.2);
                    h2 {
                        margin-top: 53px;
                        font-family: Pretendard_extraBold;
                        font-size: 46px;
                        color: #000;
                    }
                    ul {
                        margin-top: 42px;

                        li {
                            display: flex;
                            p {
                                span:nth-of-type(1) {
                                    letter-spacing: 1px;
                                    color: #343434;
                                    font-family: Pretendard_bold;
                                    font-size: 22px;
                                }

                                span:nth-of-type(2) {
                                    line-height: 1.73;
                                    font-family: Pretendard_bold;
                                    font-size: 15px;
                                    color: #606060;
                                }
                            }
                        }

                        li + li {
                            margin-top: 29px;
                        }
                    }

                    button {
                        width: 230px;
                        height: 50px;
                        border-radius: 4px;
                        background-image: linear-gradient(to right, #ff7a00 32%, #ffa600);
                        font-family: Pretendard_bold;
                        font-size: 16px;
                        color: #FFFFFF;
                        margin: 37px 0 0 179px;
                    }
                }
            }
        }

        .ctl_page {
            margin: 30px auto;
            width: 1030px;
            text-align: center;

            .menus {
                position: relative;
                display: flex;
                column-gap: 20px;

                > ul {
                    width: 220px;

                    li {
                        padding: 5px 1px 5px 9px;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 46px;
                        border-bottom: 1px solid #e4e4e3;
                        cursor: pointer;

                        .inner {
                            padding: 2px;
                            display: flex;
                            align-items: center;
                            column-gap: 7px;
                            width: 210px;
                            height: 36px;

                            .wrap_icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 32px;
                                height: 32px;
                                background-color: #ffffff;
                                @include border-radius(50%);

                                i {
                                    width: 24px;
                                    height: 24px;
                                    background-position: center;
                                }
                            }

                            p {
                                font-size: 14px;
                                font-family: Pretendard_extraBold;
                                color: #606060;
                                opacity: 0.6;
                            }
                        }
                        .inner.active {
                            @include border-radius(18px);
                            background-color: rgba(255, 166, 0, 0.2);

                            p {
                                color: #443018;
                                opacity: 1;
                            }
                        }
                    }

                    li:last-child {
                        border-bottom: none;
                    }
                }

                .icon_play,
                .icon_pause {
                    position: absolute;
                    right: 17px;
                    bottom: 18px;
                    background-color: rgba(216, 216, 216, 0.3);
                    z-index: 1;
                }

                .slick-list {
                    width: 790px;
                }

                .slick-slider {
                    position: relative;
                }

                .slick-prev,
                .slick-next {
                    position: absolute;
                    top: calc(100% - 30px) !important;
                    transform: translateY(-50%);
                    width: 24px;
                    height: 24px;
                    @include border-radius(4px);
                    background-color: rgba(216, 216, 216, 0.3);
                    z-index: 1;
                }

                .slick-prev:before,
                .slick-next:before {
                    content: "";
                    opacity: 1;
                }

                .slick-prev {
                    left: inherit;
                    right: 80px;
                    background-image: url("../../../assets/images/mobile/icon_more_arrow_left_small_black.svg");
                }

                .slick-next {
                    right: 48px;
                    background-image: url("../../../assets/images/mobile/icon_more_arrow_right_small_black.svg");
                }
            }

            .menu_desc {
                display: flex;
                flex-direction: column;
                row-gap: 53px;
                width: 790px;
                height: 280px;
                padding: 40px 0px 14px 0;
                @include border-radius(20px);
                background-color: rgba(241, 241, 241, 0.3);

                .desc_tab {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 58px;

                    i {
                        width: 70px;
                        height: 69px;
                        background-size: contain;
                    }

                    .inner_desc {
                        width: 504px;
                        text-align: left;

                        h2 {
                            margin-bottom: 3px;
                            font-size: 18px;
                            font-family: Pretendard_extraBold;
                            line-height: 30px;
                            color: #343434;
                        }

                        p {
                            font-size: 14px;
                            font-family: Pretendard_bold;
                            line-height: 18px;
                            color: #606060;
                        }
                    }
                }
            }
        }

        .btn {
            padding-right: 16px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 195px;
            height: 52px;
            border-radius: 27px;
            font-size: 18px;
            font-family: Pretendard_bold;
            color: #ffffff;
            background-image: linear-gradient(
                to right,
                #ff7a00,
                var(--color-Web-Orange) 100%
            );

            i {
                position: absolute;
                right: 16px;
            }
        }
    }

    .mo {
        .banner {
            img {
                width: 100%;
            }

            .item {
                position: relative;

                .btn {
                    position: absolute;
                    left: 45vw;
                    bottom: 28%;
                    width: 40vw;
                    min-width: 144px;
                    height: 9vw;
                    max-height: 50px;
                    min-height: 32px;
                    @include border-radius(10vw);
                    font-size: 3.5vw;
                    font-family: Pretendard_extraBold;
                    color: #ffffff;
                }

                .btn_findx {
                    background-color: #1bb3f3;
                }

                .btn_free_start {
                    background-color: var(--color-Web-Orange);
                }

                .btn_free_start_green {
                    bottom: 29%;
                    background-color: #00cd90;
                }

                .btn_grade {
                    background-image: linear-gradient(
                        to right,
                        #6bd3ff,
                        #00cd90
                    );
                }
            }
        }

        .auto_calc_annual_fee {
            position: relative;

            .search_form {
                position: relative;
                bottom: 7vw;
                display: flex;
                align-items: center;
                margin: auto;
                padding: 0 8px;
                width: 90%;
                min-height: 40px;
                height: 11vw;
                border: 2px solid var(--color-Web-Orange);
                @include border-radius(8px);
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                background-color: #ffffff;
                z-index: 1;

                p {
                    position: absolute;
                    left: 10vw;
                    font-size: 3.8vw;
                    font-family: Pretendard_bold;
                    color: #959595;

                    span {
                        margin-right: 8px;
                        display: inline-block;
                        font-family: Pretendard_extraBold;
                        color: var(--color-Web-Orange);
                    }
                }

                input {
                    width: 90%;
                    font-size: 3.8vw;
                    font-family: Pretendard_bold;
                    color: #959595;
                    border: none;
                    background-color: transparent;
                    z-index: 2;
                }

                input:focus {
                    outline: none;
                }

                .icon_m_search {
                    margin-right: 8px;
                    min-width: 24px;
                    width: 6vw;
                    min-height: 24px;
                    height: 6vw;
                    background-size: contain;
                }
            }

            .use_notify {
                padding: 5.5vw 20px 0 20px;
                position: absolute;
                top: -8px;
                left: 0;
                display: flex;
                align-items: center;
                column-gap: 4px;
                width: 100%;
                height: 17vw;
                font-size: 14px;
                font-family: Pretendard_extraBold;
                color: #959595;
                background: rgba(240, 240, 240, 0.5);
            }
        }

        .keyword_list {
            margin: auto;
            position: relative;
            bottom: 7vw;
            width: 90%;
            height: 250px;
            z-index: 2;

            li:first-child {
                margin-top: 0;
            }
        }

        img[class^="intro_"] {
            margin: 40px auto 0 auto;
            display: block;
            width: 90%;
        }

        .intro_02 {
            width: 80% !important;
        }

        .intro_03 {
            margin: 40px 0 0 7vw !important;
        }

        .intro_04 {
            margin: 70px 0 0 auto !important;
        }

        .intro_06 {
            margin: 70px auto 0 auto !important;
        }

        .intro_07 {
            margin: 0 auto !important;
            width: 100% !important;
        }

        .side_signup {
            background-image: linear-gradient(to right, #6bd3ff, #00cd90);

            p {
                margin-bottom: 3.5vw;
                font-size: 4.5vw;
                font-family: Pretendard_extraBold;
                color: #ffffff;
                text-align: center;
            }

            button {
                margin: auto;
                display: block;
                width: 56vw;
                height: 11.5vw;
                font-family: Pretendard_extraBold;
                background: url("../../../assets/images/mobile/btn_signup.svg")
                    no-repeat;
                background-size: contain;
            }
        }

        .side_signup.first {
            margin-top: 40px;
            padding: 7.5vw 0 5.5vw 0;
            min-height: 134px;
            height: 37vw;
        }

        .side_signup.last {
            padding: 5.5vw 0;
            min-height: 115px;
            height: 30vw;
        }

        .btn_go_signup_yello {
            margin: 60px auto 0 auto;
            display: block;
            min-width: 220px;
            width: 60vw;
            min-height: 42px;
            height: 10vw;
            font-size: 4.5vw;
            font-family: Pretendard_bold;
            color: #ffffff;
            border-radius: 21px;
            background-color: var(--color-Web-Orange);
        }

        .btn_free {
            margin: 50px auto 0 auto;
            display: block;
            width: 202px;
            height: 42px;
            font-family: Pretendard_extraBold;
            background: url("../../../assets/images/mobile/btn_free.svg")
                no-repeat;
            background-size: contain;
        }

        .more_view_area {
            margin: 30px 0;

            .area_title {
                margin-bottom: 24px;
                padding-left: 20px;
                font-size: 20px;
                font-family: Pretendard_extraBold;
            }

            .list {
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                width: 100vw;
                overflow-x: scroll;
                overflow-y: hidden;

                img {
                    height: 155px;
                }

                img + img {
                    padding-left: 20px;
                }
            }
        }

        .slick-dots {
            bottom: 30px !important;

            li {
                margin: 0;
            }

            li.slick-active button:before {
                opacity: 1;
                color: #ffffff;
            }

            li button:before {
                opacity: 0.5;
                color: #ffffff;
            }
        }
    }

    .br {
        margin: 15px auto 0 auto;
        display: block;
        width: 1030px;
        height: 1px;
        border: 1px solid #e7e7e7;
    }
}
