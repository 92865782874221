@import "src/mixin";

#PartList {
  //margin: auto;
  //max-width: 1280px;
  width: 100%;
  //padding: 0 50px;

  @include mobile {
    padding-top: 60px;
  }

  .top_banner {
    position: relative;
    background-image: linear-gradient(to right, #8884d5 9%, #3363c8 45%, #4ab8aa 91%);
    padding: 60px 30px;
    color: #fff;

    @include mobile {
      padding: 30px 20px;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;

      @include mobile {
        display: none;
      }
    }

    h2 {
      margin-bottom: 20px;
      font-size: 42px;
      font-family: Pretendard_extraBold;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      span {
        margin-left: 8px;
        font-size: 28px;
        font-family: Pretendard_bold;
      }
    }

    p {
      font-size: 20px;
      font-family: Pretendard_bold;
      line-height: 24px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .index {
    padding-left: 28px;
    display: flex;
    align-items: center;
    height: 47.5px;
    font-size: 14px;
    font-family: Pretendard_bold;
    border-bottom: solid 1px var(--color-Gallery);
  }

  .head {
    position: relative;
    margin: 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72.5px;
    font-size: 22px;
    font-family: Pretendard_bold;
    color: var(--color-Mine_Shaft);

    input {
      padding: 0 50px 0 20px;
      width: 270px;
      height: 40px;
      font-size: 14px;
      font-family: Pretendard_bold;
      border: 2px solid var(--color-Cornflower-Blue);
      border-radius: 20px;

      @include mobile {
        padding: 0 5px;
        width: 120px;
        font-size: 12px;
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translateY(-50%);
    }
  }

  .task_tbl_box {
    padding: 0 30px;
  }

  table {
    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft) !important;
      }

      @include mobile {
        th:first-child {
          font-size: 0;
        }
      }

      ;
    }

    button {
      display: none;
    }
  }

  #pagination {
    margin-top: 30px;
  }

  .btn_add {
    display: none;
  }

  #TaskTable {
    .interest {
      display: none;
    }

    .table_title {
      padding-left: 15px;
    }
  }
}