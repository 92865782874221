@import "src/mixin";

#SignupTermsPopup {
  padding: 45px 35px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  height: 80vh;
  max-height: 700px;
  border: 1px solid var(--color-Alto);
  background-color: #ffffff;
  z-index: 2;

  @include mobile {
    padding: 25px;
    top: 0;
    left: 0;
    transform: none;
    height: 100vh;
    max-height: 100vh;
    border: none;
  }

  .head {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 22px;
      font-family: Pretendard_bold;
      color: #343434;
    }
  }

  .term {
    height: calc(100% - 70px);
    font-size: 12px;
    line-height: 18px;
    color: var(--color-Scorpion);
    overflow-y: scroll;
  }
}
