@import "src/mixin";
@import "src/include";
#TaskManageChartSlider {

    $gradient: linear-gradient(to right, #4593f5, #6bd3ff);

    .area_title {
        margin: auto;
        padding-bottom: 20px;
        width: 90%;
        font-size: 20px;
        font-family: Pretendard_extraBold;
        line-height: 26px;
        color: #1d334e;

        @include mobile {
            margin-top: 20px;
        }
    }

    .chart_area {
        margin-bottom: 35px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .list {
            .item {
                .content {
                    padding: 0 20px;
                    width: 90%;
                    height: 200px;
                    background-image: $gradient;
                }
            }
        }

        .wrap_chart {
            margin: auto;
            position: relative;
            width: 90vw;

            .chart {
                position: relative;
                padding: 18px 18px 18px 24px;
                width: calc(100vw - 50px);
                @include border-radius(8px);
                background-image: $gradient;

                &.amount {
                    background: white;
                    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

                    h2 {
                        color: #1d334e !important;
                    }
                }

                .header {
                    margin-bottom: 28px;
                    display: flex;
                    align-items: center;

                    h2 {
                        font-size: 16px;
                        font-family: Pretendard_extraBold;
                        line-height: 24px;
                        color: #ffffff;
                    }
                }

                .total_cnt {
                    position: absolute;
                    top: 52%;
                    left: 62px;
                    width: 90px;
                    font-size: 12px;
                    line-height: 42px;
                    color: #ffffff;
                    text-align: center;

                    span {
                        font-size: 32px;
                        font-family: Pretendard_extraBold;
                    }
                }

                .add_label,
                .apply_label,
                .giveup_label {
                    position: absolute;
                    top: 35%;
                    left: 210px;
                    justify-content: center;
                    align-items: center;
                    min-width: 80px;
                    line-height: 26px;
                    white-space: pre;
                    font-size: 12px;
                    font-family: Pretendard_extraBold;
                    color: var(--color-Web-Orange);
                    text-align: center;
                    background: #ffffff;
                    @include border-radius(7px);
                    z-index: 2;
                }

                .add_label:after,
                .apply_label:after,
                .giveup_label:after {
                    content: "";
                    position: absolute;
                    bottom: 30px;
                    left: 10px;
                    width: 0;
                    height: 0;
                    border: 7px solid transparent;
                    border-top-color: #ffffff;
                    border-bottom: 0;
                    margin-left: -20px;
                    margin-bottom: -20px;
                    transform: rotate(90deg);
                }

                .apply_label {
                    top: 50%;
                    color: #ffa012;
                    background-color: #ffd4a6;
                }

                .apply_label:after {
                    border-top-color: #ffd4a6;
                }

                .giveup_label {
                    top: 65%;
                    color: #ffffff;
                    background-color: #ffa94d;
                }

                .giveup_label:after {
                    border-top-color: #ffa94d;
                }
            }
        }
    }

    .legend {
        position: absolute;
        top: 20px;
        right: 16px;
        display: flex;
        column-gap: 12px;
        font-size: 10px;
        font-family: Pretendard_bold;
        line-height: 18px;
        color: #606060;
        pointer-events: none;

        .legend_data {
            display: flex;
            align-items: center;
            column-gap: 4px;

            color: #ffffff;
        }

        .circle {
            width: 10px;
            height: 10px;
            @include border-radius(8px);
        }

        .total {
            background-color: rgba(255, 255, 255, 0.4);
        }

        .organized {
            background-color: #ffffff;
        }
    }

    .slick-list {
        overflow: visible;
    }

    .slick-dots {
        bottom: -30px;
    }

    .slick-dots {
        li {
            margin: 0;
            color: #d8d8d8;
        }

        li.slick-active {
            button:before {
                color: #81cae9;
            }
        }
    }
}
