#InventorStepThreeDone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > h2 {
    font-size: 22px;
    margin: 180px 0 30px 0;
  }
  > h5 {
    margin-bottom: 60px;
  }
  > div {
    button {
      width: 185px;
      height: 45px;
      border-radius: 4px;
      font-size: 16px;
      margin-right: 20px;
      background-color: #fff;
    }
    > button:nth-of-type(1) {
      color: #343434;
      border: solid 1px #959595;
    }
    > button:nth-of-type(2) {
      background-color: #e92f2c;
      color: #fff;
    }
  }
}
