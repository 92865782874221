#HoldingsHome {
  .part {
    padding: 30px;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  }
  .part_tit {
    color: #333d4b;
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
  }
  .gantt_box {
    padding-top: 55px;
  }
  .gantt-chart {
    position: relative;
    width: 100%;
  }

  .timeline {
    position: relative;
    width: 100%;
    height: 60px;
    // background-color: #f0f0f0;
  }

  .month {
    position: absolute;
    height: 100%;
    border-left: 1px solid #e6e6e6;
    text-align: center;
  }

  .year .year-label {
    width: 70px;
    position: absolute;
    top: -50px;
    left: 0;
    transform: translateX(-50%);
    font-family: "Pretendard_Extrabold";
  }

  .month-label {
    position: absolute;
    top: -23px;
    left: 50%;
    width: 34px;
    transform: translateX(-50%);
    font-size: 14px;
    font-family: "Pretendard_regular";
  }

  .item {
    position: relative;
    width: 100%;
    // margin-top: 20px;
    padding-left: 100px;
    border-bottom: 1px solid #e6e6e6;
  }

  .date {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-family: "Pretendard_regular";
  }

  .marker {
    position: absolute;
    top: 14px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: lightgray;
    z-index: 10;
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      background-color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
    }
  }

  .half {
    background-color: #7fe2b2;
    box-shadow: 1px 2px 7px 1px #7fe2b2;
  }

  .third {
    background-color: #ffd644;
    box-shadow: 1px 2px 7px 1px #ffd644;
  }

  .runway {
    background-color: #ff9999;
    box-shadow: 1px 2px 7px 1px #ff9999;
  }
  .runway_bg {
    display: inline-block;
    height: 32px;
    background: #0078f1;
    position: absolute;
    top: 15px;
    opacity: 0.1;
  }

  .legend {
    margin-top: 34px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    .legend-item {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 34px;
      margin-right: 30px;
      font-family: "Pretendard_regular";
    }

    .legend-item .marker {
      margin-right: 10px;
      top: -3px;
      left: 0;
      width: 24px;
      height: 24px;
      &::after {
        width: 14px;
        height: 14px;
      }
    }
    .legend_tit {
      padding-right: 40px;
      margin-right: 40px;
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: 30px;
        background-color: #e6e6e6;
        display: block;
        position: absolute;
        right: 0;
        top: -5px;
      }
    }
  }
  .distribution {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 20px;
    #HoldingsLevel {
      height: 100%;
    }
  }
}
