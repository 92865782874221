.loginPop {
  width: calc(100% - 6px);
  max-width: 480px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 40px;
  border-radius: 30px;
  text-align: center;
  box-shadow: 0 2px 4px 0 #d8d8d8;
  z-index: 9999;

  p {
    margin-bottom: 20px;
  }

  strong {
    font-weight: 600;
  }

  a {
    display: inline-block;
  }

  .btn {
    margin-bottom: 10px;

    a {
      color: #4593f5;
    }

    a.signup {
      background-color: #4593f5;
      border-radius: 20px;
      color: #fff;
      padding: 8px 24px;
    }

    a.login {
      border-bottom: 1px solid #4593f5;
      padding-bottom: 4px;
    }
  }

  .close {
    text-align: right;
    padding-bottom: 10px;

    i {
      cursor: pointer;
    }
  }
}
