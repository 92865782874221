#PaymentAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 250px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 30px;
  > i {
    position: absolute;
    top: 13px;
    right: 13px;
  }
  > h2 {
    font-size: 22px;
  }
  > h5 {
    text-align: center;
    font-size: 18px;
  }
  > div {
    display: flex;
    gap: 5px;
    button {
      padding: 15px 20px;
      color: white;
      font-size: 15px;
      border-radius: 5px;
    }
    button:first-child {
      background-color: darkgrey;
    }
    button:last-child {
      background-color: #0984e3;
    }
  }
}
