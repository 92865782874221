#ResearchTechPatentList {
  //margin: auto;
 // width: 1280px;
 //width:100%;
 margin: 0 50px 0 50px;
  display: flex;
  .wrap_category {
    margin: 18px 0 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .category {
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-family: Pretendard_extraBold;
      font-size: 22px;
      color: #1d334e;
    }
  }

  .button_link {
    display: flex;
    .download_button {
      width: 160px;
      height: 40px;
      padding: 8px 16px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      margin-right: 20px;
      text-align: center;
      cursor: pointer;
      span {
        cursor: pointer;
        background-color: #fff;
        color: #343434;
        font-family: Pretendard_bold;
        font-size: 16px;
      }
      img {
        padding-right: 5px;
        width: 24px;
        height: 24px;
      }
    }
    .link {
      padding: 0 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 40px;
      border-radius: 9px;
      background-color: #eff3fb;

      span {
        display: block;
        width: 87px;
        font-size: 12px;
        font-family: Pretendard_bold;
        color: #606060;
      }
    }
  }
  .wrap_count {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wrap_edit_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    button {
      padding: 5px;
      margin-right: 10px;
      border-radius: 4px;
      color: #FFFFFF;
      font-family: Pretendard_semiBold;
      font-size: 12px;
    }
    button:nth-child(1) {
      background-color: #4792f7;
    }
    button:nth-child(2) {
      background-color: #e92f2c;
    }

    select {
      width: 120px;
      padding: 0 5px;
    }
  }

  table {
    //width: 1000px;
    width:100%;
    margin-top: 10px;
    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }
  .btns {
    margin: 40px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;

    .btn_prev {
      width: 86px;
      height: 40px;
      font-size: 14px;
      font-family: Pretendard_bold;
      border-radius: 4px;
      border: solid 1px var(--color-Alto);
      background-color: #ffffff;
    }

    .btn_next {
      padding: 0 10px;
      height: 40px;
      font-size: 14px;
      font-family: Pretendard_bold;
      color: #ffffff;
      border-radius: 4px;
      background-color: var(--color-Cornflower-Blue);
    }
  }
  .add_count {
    font-family: Pretendard_semiBold;
    font-size: 16px;
    color: #343434;
    span {
      font-family: Pretendard_extraBold;
      font-size: 20px;
      color: #54b7e0;
    }
  }
}
