#PlanInfo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .text_wrapper {
    //width: 1280px;
    width:100%;
    height: 180px;
    background-color: #EBF9FF;
    color: #1d334e;
    .big_text {
      font-size: 30px;
      letter-spacing: -1px;
      font-family: Pretendard_extraBold;
      padding: 29px 0 0 45px;
    }
    .small_text {
      font-size: 16px;
      font-family: AppleSDGothicNeo-Bold;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      padding: 27px 0 42px 45px;
    }
  }
}
