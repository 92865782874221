#HoldingsHomeTop {
  button {
    background-color: transparent;
  }
  .part {
    padding: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .utils {
      display: flex;
      align-items: center;
      .search {
        background-color: #f2f4f6;
        display: flex;
        align-items: center;
        border-radius: 24px;
        padding: 0 12px 0 6px;
        input {
          background-color: transparent;
          border: none;
        }
      }
      .create {
        margin-left: 10px;
        button {
          background-color: var(--color-Bright-Blue);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 16px;
          border-radius: 18px;
          i {
            background-position: center;
            margin-right: 4px;
            margin-top: 1px;
          }
        }
      }
    }
  }
  .user {
    font-size: 24px;
    // margin-bottom: 20px;
    strong {
      font-family: "Pretendard_extraBold";
      display: inline-block;
      padding-left: 6px;
    }
  }
}
