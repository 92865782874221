#MarketInfoRequestEnd {
  margin: 0 auto;
  width: 1280px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  .wrap {
    width: 1060px;
    padding-top: 19px;
    .title {
      font-family: Pretendard_bold;
      font-size: 22px;
      color: #343434;
    }
    .sub_title {
      font-family: Pretendard_semiBold;
      font-size: 14px;
      color: #343434;
      margin-top: 9px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    button {
      margin: 56px 57px 0 0;
      width: 210px;
      height: 40px;
      border-radius: 4px;
      background-color: #4593f5;
      font-family: Pretendard_bold;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }
}
