#AnnualFeeReceipt {
  width: 980px;
  min-height: 990px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  padding: 46px 0 23px 0;
   .title {
     display: flex;
     margin: 0 36px;
     position: relative;
     h2 {
       font-family: Pretendard_bold;
       font-size: 22px;
       color: #343434;
     }
     i {
       position: absolute;
       right: 0;
       top: -25px;
     }
   }
  .company_info {
    margin: 35px 50px 22px 0;
    text-align: right;
    p:nth-child(1) {
      color: rgba(0, 0, 0, 0.87);
      font-family: Pretendard_extraBold;
      font-size: 18px;
      margin-bottom: 9px;
    }
    p:nth-child(2) {
      font-family: Pretendard_semiBold;
      font-size: 14px;
      color: #000;
      line-height: 1.29;
    }
  }
  .annual_fee_receipt_company {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 70px;
    gap: 10px;
    > div {
      display: flex;
      p {
        min-height: 30px;
      }
      p:nth-child(1) {
        min-width: 120px;
        font-family: Pretendard_bold;
        font-size: 14px;
        letter-spacing: 0.25px;
        color: #000;
      }
      p:nth-child(2) {
        font-family: Pretendard_semiBold;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    div:nth-of-type(1) {
      grid-column: 1 / span 2;
    }
    div:nth-of-type(4) {
      margin-bottom: 30px;
    }
  }
  .annual_fee_receipt_table {
    padding: 37px 50px 0 50px;
    table {
      tr {
        height: 45px;
      }
      th:nth-child(even),td:nth-child(even) {
        padding-right: 20px;
      }
      th:nth-child(odd),td:nth-child(odd) {
        padding-left: 20px;
        text-align: left;
      }
      thead th:nth-child(even), tbody td:nth-child(even) {
        text-align: right;
      }
      thead {
        background-color: #fff;
        th {
          color: black;
        }
      }
      tbody {
        border-top: solid 2px #000;
        border-bottom: solid 2px #000;
      }
    }
  }
  .annual_fee_receipt_cost {
    margin: 13px 70px 43px 80px;
    display: flex;
    justify-content: space-between;
    p {
      font-family: Pretendard_extraBold;
      font-size: 14px;
      color: #000;
    }
    span {
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #959595;
    }
  }
  .annual_fee_receipt_file {
    width: 880px;
    min-height: 90px;
    border-radius: 5px;
    background-color: rgba(240,240,240,0.4);
    display: flex;
    margin: auto;
    div:nth-of-type(1) {
      width: 140px;
      padding: 17px 0 0 32px;
      font-family: Pretendard_bold;
      font-size: 14px;
      letter-spacing: 0.25px;
      color: #000;
    }
    div:nth-of-type(2) {
      padding: 17px 0 8px 0;
      p {
        display: flex;
        align-items: center;
        font-family: Pretendard_bold;
        font-size: 14px;
        letter-spacing: 0.25px;
        color: #343434;
        margin-bottom: 9px;
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .annual_fee_receipt_account {
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
    font-family: Pretendard_extraBold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.45px;
    color: #4593f5;
    margin: 41px 0;
  }
}

.annual_fee_receipt_print {
  display: flex;
  justify-content: center;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    height: 50px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    font-family: Pretendard_bold;
    color: #343434;
    font-size: 16px;
    margin-left: 24px;
    i {
      margin-right: 5px;
    }
  }
}

@media print {
  .title {
    justify-content: inherit;
    h2 {
      margin: 0 36px;
    }
  }
  .company_info {
    margin: 35px 80px 22px 0;
  }
  .annual_fee_receipt_print {
    display: none;
  }
  .annual_fee_receipt_file {
    display: none;
    > div {
      display: none;
    }
  }
  .icon_exit_gray {
    display: none;
  }
}
