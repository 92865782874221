#SignupTerms {
    margin: auto;
    padding-top: 104px;
    width: 780px;

    @media screen and (max-width: 850px) {
        padding: 92px 0 40px 0;
        width: 90vw;

        .page_title {
            margin-bottom: 32px !important;
            text-align: center;
            color: #443018;
        }
    }

    .page_title {
        margin-bottom: 7px;
        font-size: 24px;
        font-family: Pretendard_extraBold;
    }

    .pc {

        .wrap_term {
            margin-bottom: 34px;

            .desc {
                margin-bottom: 20px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
            }

           .term {
                padding: 29px 35px;
                overflow-y: scroll;
                height: 318px;
                font-size: 13px;
                line-height: 20px;
                border: solid 0.5px rgba(0, 0, 0, 0.1);
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
            }
           .term::-webkit-scrollbar {
                display: none;
           }
        }
        .checkbox {
            margin-top: 10px;
            margin-left: auto;
            justify-content: flex-start;
            width: 96px;

            .value {
                width: calc(100% - 31px);
                font-family: Pretendard_bold;
                text-align: right;
            }
        }
    }

    .mo {
        .tab {
            margin-bottom: 50px;
            //max-height: 232px;
            border-radius: 4px;
            border: 2px solid #d8d8d8;

            .tab_title {
                padding: 0 20px;
                display: flex;
                align-items: center;
                height: 52px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

                .title {
                    margin-left: 8px;
                    display: flex;
                    align-items: center;
                    width: 95%;
                    font-size: 14px;
                    font-family: Pretendard_extraBold;
                    line-height: 22px;
                    color: #443018;
                }
            }

            .tab_content {
                padding: 24px 20px;
                height: 100px;
                overflow: hidden;
                font-size: 14px;
                color: #747474;
                background-color: #f8f8f8;

                li {
                    display: flex;
                    align-items: center;

                    .txt {
                        margin-left: 8px;
                        width: 75%;
                        font-size: 12px;
                        font-family: Pretendard_bold;
                        line-height: 18px;
                        color: #606060;
                    }

                    .more {
                        font-size: 12px;
                        font-family: Pretendard_bold;
                        color: #959595;
                        text-decoration: underline;
                        background-color: transparent;
                    }
                }

                li+li {
                    margin-top: 12px;
                }
            }

            .tab_content.agree {
                height: 250px;
                overflow-x: hidden;
                overflow-y: scroll;

                li {
                    .txt {
                        width: 100%;
                    }
                }
            }
        }

          .btns {
              display: flex;

              button {
                  pointer-events: auto;
              }

              button+button {
                  margin-left: 16px;
              }
          }
    }

    @import "./common.scss";
}
