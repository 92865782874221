.react-datepicker {

    .date_header {
        position: relative;
        display: flex;
        justify-content: center;

        .btn_date_prev {
            position: absolute;
            top: 5px;
            left: 10px;
            margin: 0px 5px 0px 0px;
            height: 10px;
            border: 0.45rem solid transparent;
            border-right-color: #ccc;
        }

        .btn_date_next {
            position: absolute;
            top: 5px;
            right: 10px;
            margin: 0px 5px 0px 0px;
            height: 10px;
            border: 0.45rem solid transparent;
            border-left-color: #ccc;
        }
    }

    button:focus {
        outline: none;
    }
}
