#HoldingsInvestDistribution {
  height: 100%;
  grid-column: 2 / -1;
  .distribution_chart {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
  }
  .box {
    height: 450px;
    display: flex;
    flex-direction: column;
    .theme {
      padding: 20px;
      color: #fff;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;
      border-radius: 10px;
      > strong {
        align-self: flex-end;
      }
    }
  }
}
