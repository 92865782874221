#NotAdmin {
  text-align: center;
  .confirm {
    background-color: #1f61a2;
    color: #fff;
    padding: 4px 36px;
    border-radius: 24px;
    font-size: 14px;
    display: inline-block;
  }
  .text {
    padding: 10px 0 20px;
    line-height: 24px;
  }
}
