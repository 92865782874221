@import "src/include";

footer {
    border-top: 1px solid rgba(0, 0, 0, 0.3);

    .wrap_footer.pc {
        margin: auto;
        padding: 20px 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 1280px;
        height: 210px;

        .company_name {
            opacity: 1;
        }

        .company_name, .links {
            font-size: 14px;
            font-family: Pretendard_bold;
        }

        .info {
            font-size: 12px;
            line-height: 1.42;
            color: #313131;
            opacity: 0.8;
        }

        .links {
            opacity: 0.8;
        }

        .terms_link {
            position: absolute;
            right: 0;
            bottom: 20px;
            font-size: 12px;
            line-height: 1.42;

            a {
                color: #313131;
            }

            span {
                margin: 8px;
            }
        }

        ul {
            display: flex;

            li {
                a {
                    display: block;
                    width: 64px;
                    color: #313131;
                }
            }

            li+li {
                margin-left: 48px;
            }
        }

        .list_link {
            margin-top: 40px;
            display: flex;
            justify-content: end;
            align-items: center;
            column-gap: 30px;
            margin-right: 30px;
        }
    }

    .wrap_footer.mo {
        position: relative;
        padding: 36px 41px 16px 41px;
        min-width: 360px;
        color: black;
        .btn_toggle {
            position: absolute;
            top: 28px;
            right: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 69px;
            height: 24px;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #ffffff;
            @include border-radius(3.5px);
            border: solid 1px #d8d8d8;
            background-color: #747474;
        }

        .info {

            p {
                font-size: 12px;
                font-family: Pretendard_bold;
                line-height: 18px;
            }

            p+p {
                margin-top: 4px;
            }

            p.text_indent {
                text-indent: 28px;
            }
        }

        .line {
            width: 40px;
            height: 1px;
            margin: 31.5px 0;
            background-color: #d8d8d8;
        }

        .contact {

            div {
                display: flex;
                align-items: center;
                height: 22px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #ffffff;

                i {
                    margin-right: 10px;
                }
            }

            div+div {
                margin-top: 12px;
            }

            .title {
                margin-bottom: 18px;
                font-size: 12px;
                font-family: Pretendard_bold;
                color: #ffffff;
            }
        }

        .links {
            li {
                height: 18px;
                a {
                    font-size: 12px;
                    font-family: Pretendard_extraBold;
                }
            }

            li+li {
                margin-top: 4px;
            }
        }

        .copyright {
            margin-top: 20px;
            font-size: 12px;
            font-family: Pretendard_bold;
        }
    }
}

@media screen and (max-width: 850px){
}
