#InventorStepTwo {
  margin: 30px auto;
  width: 1280px;
  .inventor_info_box_title {
    > h5 {
      font-size: 22px;
      color: #343434;
    }
    > p {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  .custom-tabs {
    width: 1280px;
    border-bottom: 1px solid #d8d8d8;
    .custom-tab {
      width: 200px;
      color: #343434;
      font-size: 16px;
    }
  }
  .company_list {
    margin: 20px 0;
    .input_wrap {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      width: 688px;
      height: 50px;
      border-radius: 5px;
      border: solid 0.5px #d8d8d8;
      background-color: #fff;
      input[type="text"] {
        border: none;
      }
    }
    .list_item {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px 0;
      margin-top: 20px;
      > div {
        width: 270px;
        height: 80px;
        border-radius: 5px;
        box-shadow: 0 2px 3px 1px #d8d8d8;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}
