#MenuTab {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 2px solid #F9F9F9;
    //border-bottom: 1px solid var(--color-Cornflower-Blue);
    z-index: 2;

    .wrap {
        margin: auto;

        //width: 1280px;
        .tab_list_wrapper {
            display: flex;
            justify-content: space-between;
            margin: auto;
        }

        .tab_list_wrapper_login {
            //width: 1280px;
            display: flex;
            justify-content: space-around;
            padding-right: 200px;
        }

        ul {
            display: flex;
            gap: 50px;
            margin-left: 30px;
            justify-content: flex-start;

            li {
                position: relative;
                font-family: Pretendard_semiBold;
                min-width: 110px;
                font-size: 18px;
                color: #343434;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 62px;
                cursor: pointer;

                a {
                    line-height: 41px;
                    white-space: pre;
                }

                &:hover {
                    .child_tab_list {
                        display: block !important;
                        z-index: 10;
                    }
                }

                .notice_icon {
                    position: absolute;
                    top: 2px;
                    right: 0;
                    font-size: 10px;
                    border-radius: 9px;
                    background-color: #ff4949;
                    padding: 3px 5px;
                    color: #FFFFFF;
                }
            }
        }

        ul.gov {
            justify-content: center;
            margin-left: 0;
            grid-gap: 100px;

            >li {
                font-family: Pretendard_light;

                >a {
                    font-weight: normal;
                }

                >i {
                    margin-left: 10px;
                }

                >ul {
                    margin-left: 0;
                }
            }
        }

        .tab_list_service {
            display: flex;
            align-items: center;

            a {
                font-size: 14px;
                text-align: center;
                padding-left: 10px;
                height: 18px;
                color: #606060;
            }

            h2 {
                padding: 0 2px 0 2px;
                color: #f0f0f0;
            }

            .btn_change {
                width: 123px;
                height: 26px;
                margin-left: 2px;

                p {
                    padding: 2px 0;
                    width: 107px;
                    font-size: 12px;
                    font-family: Pretendard_semiBold;

                    span {
                        font-family: Pretendard_semiBold;
                    }
                }
            }

            .btn_findbiz {
                color: #4792f7;
                background-color: rgba(107, 211, 255, 0.2);
            }

            .btn_ipnow {
                color: #ffa600;
                background-color: rgba(255, 166, 0, 0.2);
            }
        }

        #IntroMenu {
            .intro_link {
                margin: auto;
                width: 1280px;
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .intro_menu_left {
                    display: flex;
                    align-items: center;
                }

                img {
                    cursor: pointer;
                    width: 115px;
                    height: 32px;
                }

                .sign {
                    width: auto;
                    display: flex;
                    align-items: center;
                    column-gap: 40px;

                    a {
                        line-height: 36px;
                        display: block;
                        font-size: 14px;
                        font-family: Pretendard_semiBold;
                    }

                    .btn_signup {
                        background-image: linear-gradient(to right, #0078f1, #0d7);
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: Pretendard_semiBold;
                        font-size: 18px;
                    }

                    .btn_login {
                        font-family: Pretendard_semiBold;
                        font-size: 16px;
                        display: block;
                        color: #343434;
                        text-align: center;
                    }
                }

                .bold a {
                    font-family: Pretendard_semiBold;
                }
            }

            .tab_list {
                li {
                    a {
                        font-size: 16px;
                        font-family: Pretendard_semiBold;
                        text-transform: uppercase;
                    }

                    &:hover {
                        .child_tab_list {
                            display: block !important;
                        }
                    }
                }
            }
        }
    }

    .child_tab_list {
        position: absolute;
        top: 100%;
        left: -20px;
        display: none !important;
        width: 200px;
        box-shadow: 0 2px 4px 0 #f0f0f0;
        border: solid 1px #f0f0f0;

        li {
            display: flex;
            align-items: center;
            width: 100% !important;
            height: 40px !important;
            font-size: 14px !important;
            font-family: NanumSquareOTF_acB;
            background-color: #ffffff;

            a {
                padding-left: 20px;
                width: 100%;
                height: 100%;
                text-align: left;
            }
        }
    }

    .btn_change {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 28px;
        font-size: 14px;
        font-family: NanumSquareOTF_acB;
        border-radius: 20px;
        color: #ffffff;
    }

    .btn_ipnow {
        background-color: var(--color-Web-Orange);
    }

    .btn_findbiz {
        background-color: #6bd3ff;
    }
}
