#TopBanner {
  .top_banner {
    background-color: #d1ede9;
    padding: 24px 24px 40px 24px;

    .wrap {
      >strong {
        letter-spacing: -0.94px;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(to left, #0d7, #0078f1);
        font-size: 40px;
      }

      >p {
        font-size: 18px;
        font-weight: 600;
        color: #606060;
      }
    }
  }
}