#MarketInfoPopupDetail {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  width: 80vw;
  max-width: 1020px;
  max-height: 80vh;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 3px 16px 0 rgba(195, 62, 62, 0.12);

  .header {
    margin-bottom: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;

    h2 {
      width: 90%;
      font-size: 22px;
      font-family: Pretendard_bold;
      color: #343434;
    }
  }

  img {
    width: 100%;
    max-height: calc(80vh - 40px - 150px - 15px);
    object-fit: contain;
  }

  .content {
    font-size: 14px;
    line-height: 18px;
    color: #343434;
  }

  .gpt_summary {
    margin-top: 20px;
    font-size: 18px;
    color: #333;
    font-family: "Pretendard_regular";
    line-height: 1.3;
  }
  .btn {
    text-align: center;
    padding-top: 20px;
    text-align: right;
    .word_btn {
      color: #fff;
      background-color: var(--color-Bright-Blue);
      font-size: 16px;
      padding: 10px 16px;
      border-radius: 24px;
    }
  }
}
