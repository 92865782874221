@import "src/include";

#MarketInfoMainCart {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1280px;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  z-index: 1;

  .before_open {
    background-color: #4593f5;
    h2 {
      color: #FFFFFF;
    }
  }
  .after_open {
    background-color: #FFFFFF;
    box-shadow: 3px -3px 0 0 #d8d8d8;
    border-bottom: 1px solid #f0f0f0;
  }

  .MarketCart {
    &_header {
      margin: auto;
      padding: 0 34px 0 41px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 46px;
      box-shadow: 3px -3px 9px 0 #d8d8d8;

      h2 {
        font-family: Pretendard_extraBold;
        font-size: 18px;
        display: flex;
        align-items: center;
        i {
          margin: 0 3px 0 10px;
        }
        span {
          font-family: Pretendard_semiBold;
          font-size: 12px;
        }
      }
    }
    &_body {
      width: 100%;
      padding: 7px 0 0 9px;
      border-bottom: 1px solid #f0f0f0;
      &_header {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-size: 14px;
          font-family: Pretendard_extraBold;
          color: #1d334e;
        }

        .inner {
          display: flex;
          align-items: center;
          column-gap: 10px;

          p {
            display: flex;
            align-items: center;
            column-gap: 5px;

            i {
              min-width: 24px;
            }

            span {
              font-size: 12px;
              color: #606060;
            }
          }

          button {
            width: 132px;
            height: 32px;
            font-size: 14px;
            font-family: Pretendard_bold;
            color: #ffffff;
            border-radius: 4px;
            background-color: #1bb3f3;
          }
        }
      }
    }
    &_footer {
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      button {
        font-family: Pretendard_bold;
        font-size: 14px;
        color: #FFFFFF;
        height: 40px;
        padding: 0 22px;
        margin-left: 10px;
      }
      .upload_btn {
        border-radius: 4px;
        background-color: #54b7e0;
      }
      .go_btn {
        border-radius: 4px;
        background-color: #4593f5;
      }
    }
    @media screen and (max-width: 850px) {
      &_header {
        h2 {
          font-size: 16px;
        }

        a {
          width: 90px;
          font-size: 14px;
        }
      }

      &_body {
        h2 {
          display: none;
        }
      }
    }

  }

  ul {
    padding-bottom: 10px;
    display: flex;
    column-gap: 7px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
