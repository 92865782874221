@import "src/include";

#docTradeTransaction{
	.pc{
		.intro{
			width: 967px;
			margin: 0 auto;
			&_title{
				width: 880px;
				font-size: 22px;
				letter-spacing: 1px;
				margin: 30px auto 15px;
				padding-bottom: 7px;
				border-bottom: 1px solid #e7e7e7;
			}
			&_section{
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 880px;
				margin: 0 auto 10px;

				&.style-title{
					margin: 0 auto 20px;
				}

				&_list{
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 283px;
					height: 38px;
					line-height: 38px;
					margin-right: 17px;
					border-bottom: 1px solid #e7e7e7;
				}
				&_title{
					width: 115px;
					font-family: Pretendard_bold;
					font-size: 14px;
					color: #343434;
				}
				&_info{
					width: calc(100% - 115px);
					font-size: 14px;
					text-align: left;
					&.style{
						font-family: Pretendard_extraBold;
						font-size: 22px;
						color: var(--color-Web-Orange);
						text-align: right;
						padding-right: 15px;
					}
				}
				&_button{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 280px;
					height: 38px;
					font-size: 16px;
					font-family: Pretendard_extraBold;
					color: #fff;
					border-radius: 4px;
					background-color: #4593f5;
				}
				&_content{
					width: 100%;
					padding: 12px 15px;
					border: 1px solid #d8d8d8;
					font-size: 14px;
					color: #343434;
					margin-bottom: 60px;
				}
				&_button-wrap{
					width: 100%;
					height: 32px;
					margin: 0 auto;
				}
				&_listLink{
					display: block;
					width: 120px;
					height: 32px;
					line-height: 32px;
					background-color: var(--color-Niagara);
					color: #fff;
					font-family: Pretendard_semiBold;
					font-size: 16px;
					text-align: center;
					margin: 0 auto;
				}
			}
		}
	}
	.mo{
		.intro{
			margin: 60px auto;
			&_section{
				width: 100%;
				#SlickSlider{
					width: 100%;
					height: 65.27777777777778vw; /*235px*/
					.mo{
						background-color: #f0f0f0;
						height: 100%;
						div{
							height: 100%;
						}
						img{
							height: 100%;
							object-fit: scale-down;
						}
					}
				}
				&_info-wrap{
					border-top: 1px solid #f0f0f0;
					border-bottom: 1px solid #d8d8d8;
					padding: 10px 20px;
				}
				&_info{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 4px;
					&_class{
						display: block;
						height: 18px;
						line-height: 18px;
						font-family: Pretendard_extraBold;
						font-size: 12px;
						color: #4593f5;
					}
					&_date{
						display: block;
						height: 18px;
						line-height: 18px;
						font-family: Pretendard_semiBold;
						font-size: 12px;
						color: #959595;
					}
				}
				&_title{
					display: block;
					height: auto;
					max-height: 40px;
					line-height: 1.43;
					font-family: Pretendard_extraBold;
					font-size: 14px;
					color: #1d334e;
					margin-bottom: 12px;
					@include text-ellipsis(2);
				}
				&_price-wrap{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				&_price{
					font-family: Pretendard_extraBold;
					font-size: 20px;
					color: #05ac9e;
				}
				&_btnDel{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 42px;
					height: 25px;
					font-family: Pretendard_bold;
					font-size: 12px;
					color: #606060;
					border-radius: 12.5px;
					border: 1px solid #aaa;
					background-color: transparent;
				}
				&_format{
					padding: 10px 20px;
					border-bottom: 1px solid #d8d8d8;
					&_title{
						font-family: Pretendard_semiBold;
						font-size: 12px;
						color: #606060;
						margin-bottom: 5px;
					}
					&_text{
						font-family: Pretendard_bold;
						font-size: 14px;
					}
				}
				&_comment{
					padding: 10px 20px;
					&_title{
						font-family: Pretendard_bold;
						font-size: 12px;
						color: #606060;
						margin-bottom: 5px;
					}
					&_text{
						height: 80px;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #606060;
						@include text-ellipsis(5);
					}
				}
			}
			&_btnPurchase{
				display: block;
				width: 160px;
				height: 42px;
				line-height: 42px;
				margin: 0 auto;
				border-radius: 21px;
				text-align: center;
				color: #fff;
				font-family: Pretendard_extraBold;
				font-size: 16px;
				background-image: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
				&_complete{
					display: block;
					width: 160px;
					height: 42px;
					line-height: 42px;
					margin: 0 auto;
					border-radius: 21px;
					text-align: center;
					color: #fff;
					font-family: Pretendard_extraBold;
					font-size: 16px;
					background-image: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
				}
			}
		}
	}
}
