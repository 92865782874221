.marketInfoRequestList {
    display: flex;
    //width: 1030px;
    width:100%;
    h2 {
        margin-bottom: 45px;
        font-size: 22px;
        font-family: Pretendard_bold;
        color: #343434;
    }

    table {
        //width: 970px;
        width:100%;
        thead {
            width: calc(100% - 5px);
        }

        th:nth-of-type(2),
        td:nth-of-type(2){
            padding-left: 10px;
            width: 45%;
            text-align: left;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
            width: 15%;
        }

        th:nth-of-type(4),
        td:nth-of-type(4),
        th:nth-of-type(5),
        td:nth-of-type(5),
        th:nth-of-type(6),
        td:nth-of-type(6) {
            width: 10%;
        }

        td {
            a {
                margin: auto;
                display: block;
                width: 24px;
                height: 24px;
                background-size: contain;
            }
            .not_download {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}
