#TradeClassification {
    padding-bottom: 40px;
    width: 100vw;
    background-color: #ffffff;
    z-index: 2;

    .header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 16px;
            font-family: Pretendard_extraBold;
            color: #443018;
        }

        a {
            position: absolute;
            left: 16px;
            width: 24px;
            height: 24px;
            background-size: contain;
        }
    }

    .list {
        .item {
            .tab_title {
                padding: 0 5%;
                height: 58px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    font-size: 14px;
                    font-family: Pretendard_extraBold;
                    color: #443018;
                }
            }

            .tab_content {
                padding: 0 5%;
                max-height: 0;
                overflow: hidden;
                font-size: 14px;
                color: #747474;
                border-top: solid 1px #d8d8d8;
                background-color: #f8f8f8;
                transition-duration: 0.5s;
                overflow-y: scroll;
            }

            .tab_content.active {
                padding: 5%;
                max-height: 156px;
            }
        }
    }
}
