#AdminIntegrationTask {
    padding-top: 40px;

    .head {
        margin-bottom: 34px;

        .title {
            font-size: 22px;
            font-family: Pretendard_extraBold;
        }
    }

    .form {

        .input_area {
            display: flex;
            align-items: center;
        }

        .input_area+.input_area {
            margin-top: 30px;
        }

        label {
            width: 80px;
            font-size: 18px;
            font-family: Pretendard_bold;
        }

        input[type="text"] {
            width: 100%;
            height: 40px;
            border: 1px solid var(--color-Alto);
        }
    }

    .btn_upload {
        margin: 30px auto 0 auto;
        display: block;
        width: 150px;
        height: 40px;
        font-size: 18px;
        font-family: Pretendard_bold;
        color: #ffffff;
        background-color: var(--color-Web-Orange);
    }
}
