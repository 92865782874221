@import "../../../include";
#FreeUsApplyList {
    margin: auto;
    margin-top: 36px;
    width: 980px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
        }

        .btns {
            display: flex;
            align-items: center;

            & > button {
                width: 132px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
            }

            .btn_tab_setting, .btn_tab_setting_save {
                background-color: #e92f2c;
            }

            .ToggleButtonWithSpan {
                margin-right: 31px;
            }
          }
      }

    .notice {
        margin-top: 7px;
        font-size: 14px;
    }

    .sorting {
        margin: 5px 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;

        .checkbox+.checkbox {
            margin-left: 35px;
        }
    }

    .wrap_list {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 590px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        .list {
            margin-top: 13px;
        }

        table {

            tbody {

                td {

                   p {
                       @include text-ellipsis(2);
                   }
                }
            }
        }
    }
    .wrap_list::-webkit-scrollbar {
        display: none;
    }
}
