@import "../../../include";

#SearchCrawledData {
  position: fixed;
  z-index: 10;
  width: 1060px;
  border-radius: 5px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #e7e7e7;
  background-color: #fff;
  padding: 44px 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  header {
    .header_text {
      display: flex;
      justify-content: space-between;
      margin: 16px 32px;
    }
    h2 {
      font-family: Pretendard_semiBold;
      font-size: 24px;
      color: #1d334e;
      color: #0078f1;
    }
    .search_wrapper {
      display: flex;
      align-items: center;
      border-top: solid 1px #e7e7e7;
      border-bottom: solid 1px #e7e7e7;
      padding: 12px 17px;
    }
    .search_input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border: solid 0.5px #0078f1;
      background-color: #fff;
      width: 1015px;
      height: 42px;
      padding: 0 10px;
      input[type="text"] {
        width: 380px;
        height: 21px;
        border: none;
      }
    }
  }
  section {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    .arrow_icon_wrapper {
      height: 750px;
      display: flex;
      align-items: center;
    }
    article {
      margin: 0;
      h5 {
        margin: 8px 0;
        font-size: 20px;
        color: #343434;
      }
      .list_items {
        width: 480px;
        height: 700px;
        padding: 0 10px;
        border-radius: 4px;
        border: solid 1px #d8d8d8;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .list_item {
        width: 100%;
        height: 130px;
        padding: 5px 10px;
        border-bottom: 1px solid #e7e7e7;
        p {
          margin: 5px 0;
        }
        .list_item_title {
          font-size: 14px;
          color: #343434;
          @include text-ellipsis(1);
          font-family: Pretendard_extraBold;
        }
        .list_item_content {
          font-size: 14px;
          color: #343434;
          margin: 10px 0 20px 0;
          @include text-ellipsis(3);
          font-family: Pretendard_extraLight;
          line-height: 1.29;
        }
        a {
          font-family: Pretendard_extraLight;
          font-size: 12px;
          color: #959595;
          @include text-ellipsis(1);
        }
      }
      .list_item:hover {
        border: solid 1px #0078f1;
        border-radius: 4px;
      }
    }
  }
  footer {
    border: none;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      height: 40px;
      border-radius: 4px;
      background-color: #0078f1;
      padding: 0 20px;
      color: #FFFFFF;
    }
  }
}
