@import "src/include";

#MTradeMarkDetail {
    position: relative;
    background-color: #f0f0f0;
    z-index: 3;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        background-color: #ffffff;

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 22px;
            color: #443018;
        }
    }

    .tabs {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        li {
            width: 50%;
            height: 38px;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #747474;
            text-align: center;
            opacity: 0.9;

            p {
                line-height: 38px;
            }
        }

        li.active {
            border-bottom: 4px solid var(--color-Web-Orange);

            p {
                font-family: Pretendard_extraBold;
                color: #443018;
            }
        }
    }

    .wrap_info {
        padding-top: 19px;
        position: relative;
        top: 60px;
        width: 100%;
        height: 100%;
        background-color: #f0f0f0;

        .list {
            margin: auto;
            position: relative;
            top: -41px;
            width: 90%;

            .item {
                padding: 12px 18px;
                width: 100%;
                font-size: 12px;
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
                @include border-radius(8px);
                background-color: #ffffff;

                .row {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 20px;

                    .title {
                        min-width: 74px;
                        font-family: Pretendard_bold;
                        color: #959595;
                        white-space: pre;
                    }

                    .value {
                        @include text-ellipsis(1);
                        font-family: Pretendard_bold;
                        color: #443018;
                    }

                    .divide {
                        display: flex;
                        align-items: center;
                        width: 50%;
                    }

                    .divide+.divide {
                        margin-left: 8px;
                    }
                }

                .row.row_long {
                    margin-top: 15px !important;

                    .value {
                        @include text-ellipsis(2);
                    }
                }

                .row.row_title {
                    margin-bottom: 11px;

                    .title {
                        font-family: Pretendard_extraBold;
                        color: #606060;
                    }

                    .value {
                        font-size: 14px;
                        font-family: Pretendard_extraBold;
                        color: #443018;
                    }
                }

                .row.row_center {

                    .title {
                        width: 50%;
                    }

                    .value {
                        width: 50%;
                    }
                }

                .row+.row {
                    margin-top: 5px;
                }

                .br {
                    margin: 8px 0;
                    width: 100%;
                    height: 1px;
                    background-color: #d8d8d8;
                }
            }

            .item+.item {
                margin-top: 20px;
            }
        }
    }

    .wrap_annualFee {
        padding: 0 5% 30px 5%;
        position: relative;
        top: 60px;
        background-color: #ffffff;

        canvas {
            position: relative;
            top: -31px;
        }
    }
}

#MTradeMarkDetail.bg_tab1 {
    background-color: #f0f0f0;
}

#MTradeMarkDetail.bg_tab2 {
    background-color: #ffffff;
}
