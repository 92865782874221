#CostDetail {
    margin: auto;
    padding-top: 40px;
    width: 880px;

    .header {
        margin-bottom: 58px;

        h2 {
            margin-bottom: 11px;
            font-size: 22px;
        }

        .wrap_info {

            display: flex;
            justify-content: space-between;

            .info {

                p {
                    display: flex;
                    align-items: center;
                    line-height: 21px;
                    font-size: 16px;

                    i {
                        margin-left: 2px;
                    }
                }
            }

            .btns {
                display: flex;

                span {
                    white-space: pre;
                }

                button {
                    width: 64px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    color: #ffffff;
                }
                .btn_save {
                    background-color: #19c77c;
                }

                .btn_cancle {
                    margin-left: 8px;
                    background-color: #96c719;
                }

                .btn_update {
                    background-color: #96c719;
                }
            }
        }
    }

    .comment {
        margin-top: 61px;
        font-size: 14px;
        color: #e72222;

        h2 {
            margin-bottom: 10px;
            font-family: Pretendard_extraBold;
        }
    }

    @import "../../common/css/rows";

    .rows {

        .row.long {

            > p {
                width: 490px;
            }
        }
    }
}
#CostDetail.edit {

    .btns {
        padding-right: 0 !important;
    }

    .icon_download {
        display: none;
    }
}
