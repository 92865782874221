#AdminManageResearch {
  margin: auto;
  padding-top: 40px;
  width: 880px;

  .articles {
    margin-bottom: 50px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .field {
    position: relative;
    padding: 16px 0;
    display: flex;
    align-items: center;
    column-gap: 14px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;

    h2 {
      width: 80px;
      font-family: Pretendard_bold;
    }
  }

  select {
    padding: 0 15px;
    width: 250px;
    height: 25px;
  }

  input {
    width: calc(100% - 100px);
    height: 30px;
  }

  select, input[type="text"] {
    border: 1px solid var(--color-Alto);
  }

  .btn_add {
    margin: 30px auto 0 auto;
    display: block;
    width: 150px;
    height: 40px;
    font-size: 18px;
    font-family: Pretendard_bold;
    color: #ffffff;
    background-color: var(--color-Web-Orange);
  }

  table {
    margin-top: 40px;
  }
}
