#SideBanner {
  position: fixed;
  top: 25%;
  left: calc(52% + 680px);
  cursor: pointer;
  z-index: 999;
  transform: translateY(40%);
  display: flex;
  flex-direction: column;
  img {
    margin-top: 7px;
    width: 102px;
    height: 209px;
  }
  a {
    img {
      width: 102px;
      height: 185px;
    }
  }
}
