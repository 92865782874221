#AnnualFeeReviewState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 18px;
  font-family: Pretendard_bold;
  font-size: 10px;
  letter-spacing: 0.71px;
  color: #343434;
  border-radius: 5px;
}
.state_incomplete {
  background-color: rgba(255, 166, 39, 0.2);
}
.state_complete {
  background-color: rgba(150, 200, 24, 0.3);
}

.spanHighlight {
  background-color: rgba(107, 211, 255, 0.2);
}
