@import "src/include";

#PopupTrade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 2;

    .dimmed {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .popup {
        padding: 19px 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 490px;
        min-height: 182px;
        text-align: center;
        @include border-radius(8px);
        background-color: #ffffff;
        z-index: 2;

        h2 {
            font-size: 20px;
            font-family: Pretendard_extraBold;
            line-height: 26px;
            color: #443018;
        }

        .desc {
            margin: 20px 0;
            font-size: 14px;
            font-family: Pretendard_bold;
            line-height: 26px;
            color: #959595;
        }

        .name {
            font-size: 14px;
            font-family: Pretendard_bold;
            line-height: 26px;
            color: #959595;
        }

        .price {
            margin: 30px 0;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            line-height: 26px;
            color: var(--color-Web-Orange);
        }

        .sell_price {
            font-size: 20px;
            font-family: Pretendard_extraBold;
            color: var(--color-Web-Orange);
        }

        .request {
            font-size: 14px;
            font-family: Pretendard_bold;
            line-height: 26px;
            color: #959595;
        }

        .alert_notify {
            margin: 5px 0 8px 0;
            font-size: 12px;
            color: #000000;
        }

        .alert {
            margin-bottom: 8px;
            font-size: 14px;
            font-family: Pretendard_bold;
            line-height: 26px;
            color: #959595;
        }

        input {
            margin: 8px 0;
            padding: 0 17px;
            width: 214px;
            height: 38px;
            font-size: 14px;
            color: #959595;
            @include border-radius(3px);
            border: solid 2px #d8d8d8;
        }

        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 16px;
        }

        button {
            width: 105px;
            height: 42px;
            border-radius: 21px;
            font-size: 16px;
            font-family: Pretendard_extraBold;
        }

        .btn_request {
            color: #ffffff;
            background-color: var(--color-Web-Orange);
        }

        .btn_cancel {
            border: solid 2px var(--color-Web-Orange);
            background-color: #ffffff;
        }

        .btn_confirm {
            width: 206px;
            color: #ffffff;
            background-color: var(--color-Web-Orange);
        }

        .icon_exit {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 24px;
            height: 24px;
        }

        .wrap_success {
            padding-top: 29px;

            h2 {
                margin-bottom: 20px;
            }
        }
    }
}
