#CompetitorRelative {
  .wrap {
    display: flex;
    justify-content: center;
    .indicator {
      text-align: center;
      .name {
        width: 100px;
        padding: 10px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 24px;
      }
      & + .indicator {
        margin-left: 20px;
      }
      .data {
        width: 1px;
        height: 400px;
        position: relative;
        background-color: #707070;
        margin: 0 auto 40px;
        > div {
          position: absolute;
          left: 20px;
          border: 1px solid #ababab;
          padding: 4px 12px;
          border-radius: 6px;
          margin-top: 10px;
          &::after {
            content: "";
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            position: absolute;
            left: -34px;
            top: 0px;
          }
          &.company {
            background-color: #d6dcfe;
            &::after {
              background-color: #0078f1;
            }
          }
          &.top {
            background-color: #f4f6ff;
            &::after {
              background-color: #dcdcdc;
            }
          }
          &.half {
            background-color: #f4f6ff;
            &::after {
              background-color: #9e9e9e;
            }
          }
          &:hover {
            z-index: 99;
          }
        }
      }
    }
  }
  .labels {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    > ul {
      display: flex;
      > li {
        position: relative;
        padding-left: 34px;
        &::after {
          content: "";
          width: 28px;
          height: 28px;
          display: block;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: -4px;
        }
        & + li {
          margin-left: 40px;
        }
        &.current {
          &::after {
            background-color: #0078f1;
          }
        }
        &.top {
          &::after {
            background-color: #dcdcdc;
          }
        }
        &.half {
          &::after {
            background-color: #9e9e9e;
          }
        }
      }
    }
  }
}
