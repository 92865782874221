#CyberChangeApply {
    @import "Common.scss";

    .icon_check_green {
        margin: 79px auto 58px auto;
        display: block;
        width: 50px;
        height: 34px;
        background-size: cover;
    }

    h2 {
        margin-bottom: 12px;
        font-size: 24px;
        font-family: Pretendard_extraBold;
        line-height: 32px;
       text-align: center;
    }

    p {
        margin-bottom: 30vh;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }

    .btn {
       margin: auto;
    }
}
