@import "src/include";

#docTradeSampleRegisterAgree{
	width: 880px;
	margin: 0 auto;

	.register{

		&_agree-checkboxWrap{
			display: flex;
			margin-bottom: 21px;

			.checkbox {
				flex-direction: row-reverse;
				justify-content: space-between;
				width: 80px;

				.value {
					font-family: Pretendard_bold;
					color: #343434;
				}
			}
		}

		&_checkbox-explanation{
			width: 800px;
			height: 24px;
			line-height: 24px;
			font-family: Pretendard_bold;
			font-size: 14px;
			color: #343434;
		}

		&_rule-wrap{
			margin-bottom: 42px;
		}

		&_rule{
			display: flex;
			justify-content: start;
			align-items: center;
		}

		&_rule-list{
			width: 140px;
			height: 32px;
			line-height: 32px;
			font-family: Pretendard_bold;
			font-size: 12px;
			color: #979797;

			&_link{
				font-family: Pretendard_extraBold;
				font-size: 12px;
				color: #979797;
				text-decoration: underline;
				background-color: transparent;
			}
		}

		&_btn-submit{
			width: 120px;
			margin: 0 auto;

			button[type=submit]{
				width: 100%;
				height: 32px;
				line-height: 32px;
				text-align: center;
				font-size: 16px;
				font-family: Pretendard_extraBold;
				color: #fff;
				background-color: #19c77c;
				border-radius: 4px;
			}
		}
	}
}
