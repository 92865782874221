.agency_and_director {
  input[type="text"] {
    //width: 365px;
    width:100%;
    margin-right:15px;
  }
}
.plan_two_wrapper {
  textarea::placeholder {
    line-height: 30px;
  }
  .plan_text {
    display: flex;
    padding: 20px 16px 19px 0;
    align-items: center;
    justify-content: space-between;
    div{
      display: flex;
    }
    p {
      font-size: 22px;
      color: #4593F5;
    }
    span {
      text-align: right;
      font-family: Pretendard_bold;
      font-size: 12px;
      color: #606060;
    }
  }
  .info_div {
    p {
      color: white;
      font-family: Pretendard_semiBold;
      font-size: 14px;
    }
  }
  .rnd_plan_item {
    input[type="text"] {
      //width: 365px;
      width:100%;
      margin-right:15px;
    }
  }
  .tech_classification {
    display: flex;
    flex-direction: column;
    .head_text {
      margin: 26px 0 15px 0;
    }
    div {
      display: flex;
      align-items: center;
    }
    .tech_classification_item {
      margin-bottom: 25px;
    }
    .tech_classification_code {
      //width: 270px;
      //width:100%;
      //width:100%;
      margin-left: 2px;
      p {
        margin: 0 5px 0 10px;
        font-size: 14px;
        //width: 111px;
        width:250px;
      }
      input[type="text"] {
        //width: 144px;
        //width:350px;
        width:100%;
        margin-right:15px;
      }
    }
    .text_wrapper {
      //width: 145px;
      width:160px;
    }
  }
  .tech_name {
    display: flex;
    flex-direction: column;

    .tech_name_text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .tech_name_item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .small_text {
        width: 120px;

        input:nth-of-type(1) {
          margin-top: 5px;
        }
      }
    }

    .rnd_name {
      input[type="text"] {
        //width: 851px;
        width:100%;
        margin-right:15px;
      }
    }
  }
  .agency_and_director {
    .plan_grid_items {
      div:nth-child(1) {
        grid-column: 1 / span 2;
      }
      div:nth-child(n+2) {
        input[type="text"]::placeholder {
          text-align: right;
          margin-right: 10px;
        }
      }
    }
  }
  .plan_contest_btn {
    display: flex;
    align-items: center;
    margin-top: 26px;
    .plan_radio_btn {
      display: inline-block;
    }
  }
  .plan_develop_step {
    display: flex;
    align-items: center;
    margin-top: 14px;
    p:nth-child(n+2) {
      margin-left: 10px;
      font-family: Pretendard_bold;
      font-size: 14px;
    }
    input[type="text"] {
      margin-left: 5px;
    }
  }
  .rnd_project_wrapper {
    margin-top: 29px;
    .tech_name_item {
      display: flex;
      align-items: center;
      p {
        width: 110px;
      }
      input[type="text"] {
        //width: 860px;
        width:100%;
        margin-right:15px;
      }
    }
    div:nth-child(n+2) {
      margin-top: 10px;
    }
  }
  .rnd_objective_content {
    margin-top: 20px;
    .rnd_objective_content_head {
      display: flex;
      justify-content: space-between;
      button {
        margin-right: 20px;
      }
    }
    .rnd_objective_content_item {
      display: flex;
      margin-top: 10px;
      position: relative;
      div {
        width: 110px;
        margin-top: 5px;
      }
      .small_text {
        input[type="text"] {
          width: 26px;
          height: 14px;
        }
      }
    }
    button {
      text-align: right;
    }
    textarea {
      height: 138px;
    }
  }
  .rnd_effective {
    margin-top: 23px;
    .rnd_effective_items {
      .rnd_effective_item {
        display: flex;
        position: relative;
        margin-top: 10px;
        div {
          width: 110px;
          margin-top: 5px;
          .info_icon {
            margin: inherit;
          }
        }
        textarea {
          height: 277px;
        }
      }
    }
  }
  .rnd_keyword {
    //width:100%;
    margin: 28px 0 6px 0;
    div {
      display: flex;
      align-items: center;
      margin-top: 5px;
      p {
        //width:50px;
        width:250px;
        margin-right: 13px;
      }
      input[type="text"] {
        //width: 178px;
        width:100%;
        margin-right:15px;
        margin-left: 5px;
      }
    }
  }
  #BringingUpIcon {
    margin: inherit;
  }
}

.end_text {
  margin: 10px 0 0 14px;
  font-family: Pretendard_semiBold;
  font-size: 12px;
  line-height: 1.75;
  color: #606060;
}

.prev_next_button {
  margin-top: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    letter-spacing: 10px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prev_button {
    margin-right: 14px;
  }
}
