#SubsChoice {
  .header {
    display: flex;
    justify-content: space-between;
    letter-spacing: -0.75px;
    font-family: Pretendard_bold;
    font-size: 22px;
    margin: 17px 0 33px 0;
    > h2 {
      color: #4a4a4a;
    }
    > p {
      min-width: 170px;
      color: #1474ff;
    }
  }
  .section {
    min-height: 300px;
    &_items {
      font-family: Pretendard_medium;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        p:nth-child(2), select {
          min-width: 170px;
        }
        select {
          border-radius: 5px;
          background-color: #eee;
          color: #9e9e9e;
          padding: 0 0 0 8px;
          height: 34px;
        }
      }
    }
  }
  .free_text {
    margin-top: 150px;
    text-align: center;
    line-height: 1.5;
  }
}
