#FamilyAddPopup {
    padding: 35px;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 780px;
    width: 80%;
    height: 80vh;
    transform: translate(-50%, -50%);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    z-index: 2;

    .popup_header {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .title {
            font-size: 22px;
        }
        .btns {
            display: flex;
            align-content: center;
            column-gap: 40px;

            .btn_add {
                width: 120px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                background-color: var(--color-Web-Orange);
            }
        }
    }

    .wrap_list {
        height: 80%;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}