@import "src/include";
#Home {

    .tab {
        width: 284px;
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
    }

    .wrap_tab {
        margin: 13px 0;
        display: flex;
    }

    .wrap_tab {
        display: flex;

        .tab {
            height: 130px;

            .header {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 30px;

                span {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    color: #ffffff;
                }

                i {
                    margin-right: 5px;
                }
            }
        }

        .tab_date_manage.agent_date_manage {
            height: 130px;

            a {
                align-items: flex-start;
            }
        }

        .agent_manage_company {
            padding: 15px 25px 21px 13px;

            > .title {
                margin-bottom: 25px;
                font-size: 22px;
                font-family: Pretendard_extraBold;
                color: rgba(0, 0, 0, 0.87);
            }

            .row {
                padding-left: 12px;
                display: flex;
                align-items: center;
                height: 30px;

                .title {
                    margin-right: 20px;
                    width: 65px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    color: rgba(0, 0, 0, 0.87);
                    white-space: pre;
                }

                .content {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.87);
                }
            }

            .btn {
                margin: 28px auto 0 auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 160px;
                height: 32px;
                font-size: 14px;
                font-family: Pretendard_bold;  color: #ffffff;
                border-radius: 16px;
                background-image: linear-gradient(to right, #ff7a00 32%, var(--color-Web-Orange));
            }
        }
    }
}
