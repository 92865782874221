#ResearchTechRelatedPopup {
  position: absolute;
  padding: 30px;
  width: 800px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  .popup_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  table {
    width: 100%;
    th:nth-child(1),td:nth-child(1) {
      text-align: left;
      padding-left: 30px;
    }
    th:nth-child(2) {
      text-align: center;
    }
    button {
      width: 100%;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px;
      font-family: Pretendard_bold;
      font-size: 12px;
      border-radius: 9px;
      background-color: #eff3fb;
    }
  }
}
