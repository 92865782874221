:root {
  //use property: var(--main-bg-color)
  --color-Bondi-Blue: #0095ae;
  --color-Scorpion: #606060;
  --color-Cloud-Burst: #1d334e;
  --color-Gallery: #f0f0f0;
  --color-White-opacity-02: rgba(255, 255, 255, 0.2);
  --color-black-opacity-05: rgba(0, 0, 0, 0.5);
  --color-Gallery-opacity-02: rgba(240, 240, 240, 0.2);
  --color-Alto-opacity-03: rgba(216, 216, 216, 0.3);
  --color-Alto-opacity-01: rgba(216, 216, 216, 0.1);
  --color-Alto: #d8d8d8;
  --color-Dusty-Gray: #959595;
  --color-Light-Gray: #ececec;
  --color-Red: #ff0000;
  --color-Alizarin-Crimson: #e92f2c;
  --color-Red-opacity-08: rgba(255, 0, 0, 0.8);
  --color-Web-Orange: #ffa600;
  --color-Lima: #96c719;
  --color-Web-Orange-opacity-01: rgba(255, 166, 0, 0.1);
  --color-Jade: #00ba78;
  --color-Malibu-opacity-02: rgba(107, 211, 255, 0.2);
  --color-Malibu-opacity-015: rgba(107, 211, 255, 0.15);
  --color-Tarawera: #073e55;
  --color-Silver-Chalice: #aaaaaa;
  --color-Mine_Shaft: #343434;
  --color-Yellow: #fff800;
  --color-Spray: #8ddcf2;
  --color-Malibu: #6bd3ff;
  --color-Piction-Blue: #54b7e0;
  --color-Dodger-Blue: #2d99ff;
  --color-Cornflower-Blue: #4593f5;
  --color-Cornflower-Blue-opactity-02: rgba(69, 147, 245, 0.2);
  --color-Piction-Blue-opacity-08: rgba(84, 183, 224, 0.8);
  --color-Niagara: #05ac9e;
  --color-Piction-Blue-opacity-03: rgba(84, 183, 224, 0.3);
  --color-Piction-Blue-opacity-015: rgba(84, 183, 224, 0.15);
  --color-Bright-Blue: #0078f1;
  --color-Light-Blue: #ebf4ff;
  --color-Bright-Green: #00de73;
}
