#People {

    @media screen and (max-width: 850px){
        padding-top: 60px;
    }

    .page_title {
        padding: 45px 0;
        font-size: 40px;
        font-family: Pretendard_extraBold;
        line-height: 50px;
        color: #343434;
        text-align: center;
    }

    .experts {
        background-color: rgba(240, 240, 240, 0.3);
    }

    .wrap {
        padding: 12px 0;
        min-height: 680px;

        > * {
            margin: auto;
            max-width: 1000px;
            width: 100%;
        }

        > h2 {
            margin-bottom: 23px;
            font-size: 22px;
            font-family: Pretendard_extraBold;
            color: #443018;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: 64px;
            row-gap: 40px;

            li {
                width: 200px;
                text-align: center;

                h2,
                p {
                    text-align: center;
                }

                h2 {
                    font-size: 22px;
                    font-family: Pretendard_bold;
                    color: #343434;
                }

                p {
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    line-height: 20px;
                    color: #606060;
                }

                img {
                    margin-bottom: 22px;
                    width: 150px;
                }
            }
        }
    }

    @media screen and (max-width: 850px) {
        .wrap {
            > h2 {
                padding-left: 15px;
            }

            ul {
                justify-content: center;
                column-gap: 0;
            }

            li {
                width: 170px !important;

                img {
                    width: 130px !important;
                }
            }
        }
    }
}
