@import "src/include";

.informaiton{
	&_row{
		height: 50px;
	}
	&_num-data{
		color: #343434;
	}
	&_title-data{
		padding-left: 15px;
		&_input{
			display: inline-block;
			width: 360px;
			height: 30px;
			border: .5px solid #d8d8d8;
			font-size: 12px;
			padding-left: 5px;
			color: #343434;
			&::placeholder{
				font-size: 12px;
				color: #606060;
			}
		}
	}
	&_payment-data{
		padding-left: 15px;
		&_input{
			width: 114px;
			height: 30px;
			border: .5px solid #d8d8d8;
			font-size: 12px;
			text-align: right;
			color: rgba(0, 0, 0, 0.87);
			padding-right: 5px;
		}
		&_text{
			width: 21px;
			height: 30px;
			line-height: 30px;
			color: rgba(0, 0, 0, 0.87);
			padding-left: 5px;
		}
	}
	&_dataClass-data{
		.dataClassSel{
			width: 84px;
			height: 30px;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.87);
			padding-left: 6px;
			border: .5px solid #d8d8d8;
		}
		.dataClassOption{
			height: 30px;
			line-height: 30px;
		}
	}
	&_introduce-data{
		.input{
			width: 360px;
			height: 30px;
			font-size: 12px;
			padding-left: 5px;
			border: .5px solid #d8d8d8;
		}
	}
	&_del{
		&_btn{
			display: inline-block;
			background-color: transparent;

			.icon_badge_del{
				display: block;
				width: 24px;
				height: 24px;
			}
		}
	}
}