#MatchingBizMyList {
    @import "../../../include";
    margin: auto;
    padding-top: 40px;
    width: 980px;

    @media screen and (max-width: 850px) {
        padding-top: 80px;
    }

    .header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 22px;
            font-family: Pretendard_bold;
        }

        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 32px;
            font-size: 14px;
            color: #fff;
            @include border-radius(4px);
            background-color: #4593f5;
        }
    }

    table {
        th:first-child,
        td:first-child,
        th:nth-of-type(4),
        td:nth-of-type(4),
        th:last-child,
        td:last-child {
            width: 10%;
        }

        th:nth-of-type(2),
        td:nth-of-type(2) {
            text-align: left;
        }

        th:nth-of-type(3),
        td:nth-of-type(3) {
            width: 15%;
        }

    }
}
