#ResearchTechExisting {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px 0 30px;
    font-family: Pretendard_bold;
    color: #343434;
    h2 {
      font-size: 22px;
    }
    .search_wrapper {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        margin-right: 20px;
      }
      .search_input {
        width: 270px;
        height: 40px;
        border-radius: 20px;
        border: solid 2px #4593f5;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 19px;
        input[type="text"] {
          color: #959595;
          width: 201px;
          height: 24px;
          border: none;
        }
      }
    }
  }
  section {
    //width: 1280px;
    width:100%;
    //width:calc(100vw - 100px);
    padding: 15px 40px 110px 20px;
    margin: 10px 0;
    background-color: rgba(240,240,240,0.2);
    /*.category:nth-child(1) {
      height: 100%;
    }
    .category:nth-child(2) {
      height: 150px;
    }
    .category:nth-child(3),
    .category:nth-child(4){
      height: 230px;
    }*/
    .category {
      //width: 1220px;
      //width:2000px;
      width:100%;
      //height:150px;
      height:100%;
      background-color: #fff;
      //padding: 10px;
      padding:10px;
      margin: 20px 0;
      border-radius: 8px;
      box-shadow: 0 2px 3px 1px #d8d8d8;
      .category_title {
        //width: 1200px;
        width:100%;
        height: 50px;
        background-color: #f0f0f0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        p {
          display: flex;
          align-items: center;
          font-family: Pretendard_extraBold;
          font-size: 18px;
          color: #343434;
          i {
            margin-right: 5px;
          }
        }
      }
      .category_list {
        padding: 0;
        grid-column-gap: 6px;
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        margin-top: 10px;

        li {
          padding: 0 10px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          //width: 235px;
          width: calc(25% - 5px);
          height: 70px;
          border-radius: 5px;
          border: none;
          background-color: rgba(107,211,255,0.2);
          font-family: Pretendard_extraBold;
          color: #343434;
          text-align: center;
          word-break: keep-all;
          cursor: pointer;
          div {
            width:100%;
            //padding: 0 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          img {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        li:hover {
          background-image: linear-gradient(to right, #4593f5, #6bd3ff);
          color: white;
        }
        .not_data {
          background-color: rgba(240, 240, 240, 0.2);
          pointer-events:none;
        }
        .not_data:hover {
          background-image: inherit;
          color: #343434;
          cursor: inherit;
        }
      }
    }
  }
}
