@import "src/include";

#TaskNotifySetting {
    margin: 0 auto;
    //width: 1280px;
    width:100%;
    > .header {
        //width: 1280px;
        width:100%;
        height: 180px;
        padding: 36px 0 0 36px;
        background-color: #e6f2ff;
        > h2 {
            @include text-gradient-green;
            font-size: 32px;
            letter-spacing: -0.75px;
        }
        > h5 {
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: -1px;
            color: #343434;
            margin-top: 4px;
        }
    }

    .btns {
        margin-top: 28.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 15px;

        button {
            width: 93px;
            height: 45px;
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 45px;
        }

        .btn_back {
            border: solid 1px #959595;
            @include border-radius(4px);
            background-color: #ffffff;
        }

        .btn_save {
            color: #ffffff;
            @include border-radius(4px);
            background-color: var(--color-Alizarin-Crimson);
        }
    }

    .wrap_keyword {
        padding: 20px 50px;

        > h2 {
            font-size: 22px;
            letter-spacing: -0.25px;
            color: #343434;
            font-family: Pretendard_medium;
        }

        > div {
            //width: 1060px;
            width:100%;
            border-radius: 30px;
            box-shadow: 0 2px 4px 0 #d8d8d8;
            background-color: #fff;
            min-height: 105px;
            margin: 35px auto;
        }
        .keyword_input {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .keyword_input_top {
                width:90%;
                //margin:0 50px 0 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 13px;
            }
            input[type="text"] {
                width:calc(100% - 500px);
                //width: 500px;
                height: 45px;
            }
            button {
                //width: 185px;
                width: 200px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                background-image: linear-gradient(to left, #0d7, #0078f1);
                color: #FFFFFF;
                font-size: 16px;
                i {
                    margin-right: 5px;
                }
            }
        }
        .keyword_input_bottom {
            display: flex;
            //margin: 10px 0 0 230px;
            margin: 10px 0 0 0;
            gap: 15px;
            > label {
                input[type="checkbox"] {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }
        }
        .toggle_btn {
            position: relative;
            display: inline-block;
            width: 42.1px;
            height: 18px;
            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            span {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #FFFFFF;
                transition: 0.3s;
                border-radius: 30px;
                border: 1px solid #0078f1;
            }
            span:before {
                position: absolute;
                content: "";
                width: 10px;
                height: 10px;
                left: 3px;
                bottom: 2.6px;
                background-color: #0078f1;
                border-radius: 50%;
                transition: 0.3s;
            }

            input:checked + span {
                background-color: #0078f1;
            }

            input:checked + span:before {
                right: 10px;
                background: #FFFFFF;
                transform: translateX(24px);
            }
        }
    }
    .keyword_table {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 30px;
        .keyword_table_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            > p {
                font-size: 14px;
                color: #0078f1;
                font-family: Pretendard_regular;
            }
            > select {
                width: 80px;
                font-size: 12px;
                color: #959595;
                //margin-left: 300px;
            }
        }
        table {
            width:100%;
            //width: 1000px;
            thead tr {
                height: 35px;
                background-image: linear-gradient(to right, #0078f1, #01bdb4);
                th {
                    color: #FFFFFF;
                }
            }
        }
        .keyword_pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 0 20px 0;
        }
    }
}
