.PaymentList {
  h2 {
    padding-left: 48px;
    position: relative;
    font-size: 22px;
    &::after {
      content: "";
      width: 32px;
      height: 32px;
      display: block;
      background-color: #e5f1fd;
      position: absolute;
      left: 0;
      top: -2px;
    }
  }
  .tb_box {
    padding: 30px;
    table {
      td {
        text-align: center;
        padding: 10px;
      }
    }
  }
  .btn_box {
    text-align: right;
    margin-bottom: 10px;
    .more_pay {
      color: #fff;
      background-color: #0078f0;
      padding: 10px 20px;
      border-radius: 30px;
      display: inline-block;
      font-size: 14px;
    }
  }
}
