@import "src/mixin";

#RenewalSignupTypeFindBiz {
  margin: auto;
  padding-top: 50px;
  width: 1280px;
  height: 900px;
  background: url("../../../assets/images/login/bg-login.png");
  font-family: Pretendard_semiBold;
  .wrap_content {
    margin: 0 auto;
    width: 540px;
    height: 350px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    padding: 28px 45px 0 45px;
    h2 {
      font-family: Pretendard_bold;
      font-size: 28px;
      color: #343434;
    }
    .box_inner {
      margin-top: 10px;
    }
    .join01 {
      > a {
        display: flex;
        align-items: center;
        padding: 15px 15px 10px 35px;
        border-radius: 15px;
        box-shadow: 0 2px 8px 0 #b4e9ff;
        border: 1px solid #fff;
        background-color: #fff;
        &:hover {
          border: 1px solid #0078f1;
        }
      }
      .text {
        flex: 1 1 289px;
        .round {
          position: relative;
          width: 103px;
          height: 22px;
          border: 1px solid transparent;
          border-radius: 11px;
          background-image: linear-gradient(#fff, #fff),
            linear-gradient(to right, #0078f1 0%, #0d7 100%);
          background-origin: border-box;
          background-clip: content-box, border-box;
          text-align: center;
          span {
            display: block;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            background-image: linear-gradient(to left, #0d7, #0078f1);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.58px;
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        h5 {
          padding: 8px 0 13px;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.33;
          letter-spacing: -1px;
          color: #0078f1;
          span {
            display: inline-block;
            font-size: 10px;
            margin-top: 5px;
            padding-left: 6px;
            color: red;
            vertical-align: top;
          }
        }
        .sub {
          font-size: 16px;
        }
      }
      .img {
        width: 113px;
        flex: 0 0 113px;
      }
    }
    .join02 {
      padding-top: 12px;
      > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px 20px 35px;
        border-radius: 15px;
        box-shadow: 0 2px 8px 0 #b4e9ff;
        border: 1px solid #fff;
        background-color: #fff;
        //background: url('../images/join/join-arrow.svg') no-repeat right 15px center;
        &:hover {
          border: 1px solid #0078f1;
        }
      }
      .text {
        h5 {
          padding-bottom: 5px;
          font-size: 22px;
          font-weight: 500;
          line-height: 1.09;
          letter-spacing: -0.5px;
          color: #606060;
        }
        p {
          font-size: 16px;
          line-height: 1.38;
          color: #606060;
        }
      }
    }
    .counsel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 30px 0;
      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.33;
        letter-spacing: -0.41px;
        color: #606060;
      }
      a {
        font-size: 14px;
        line-height: 1.57;
        color: #0078f1;
        text-decoration: underline;
      }
    }
    .join {
      padding-top: 30px;
      font-size: 12px;
      letter-spacing: 0.26px;
      color: #959595;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        display: flex;
        align-items: center;
        padding-right: 26px;
        font-weight: 800;
        color: #959595;
      }
    }
  }
  &.mo {
    width: 100%;
    padding: 20px;
    .wrap_content {
      width: 100%;
      padding: 20px;
      h2 {
        font-size: 24px;
        margin-bottom: 16px;
      }
      .join {
        justify-content: center;
        > a {
          padding-right: 0;
        }
      }
      .join01 {
        > a {
          padding: 16px;
          justify-content: space-between;
        }
        .text {
          flex: none;
          h5 {
            font-size: 22px;
          }
          .sub {
            font-size: 14px;
          }
        }
      }
      .counsel {
        p {
          font-size: 14px;
        }
      }
    }
  }
}
