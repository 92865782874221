@import "src/include";

#MobilePopupAttach{
	position: fixed;
	top: 0;
	padding-top: 60px;
	height: 100vh;
	background-color: #ffffff;
	z-index: 1;

	.popupAttach{
		&_title{
			font-family: Pretendard_extraBold;
			font-size: 14px;
			color: #1d334e;
			padding: 24px 0 13px 20px;
		}
		&_table{
			&_head{
				height: 40px;
				th{
					font-family: Pretendard_extraBold;
					font-size: 12px;
					&:nth-of-type(2){
						text-align: left;
						padding-left: 10px;
					}
				}
			}
			&_body{
				td{
					font-family: Pretendard_bold;
					font-size: 12px;
					height: 60px;
					&:nth-of-type(1){
						text-align: center;
					}
					&:nth-of-type(2){
						text-align: left;
					}
					.btn_down{
						text-indent: -9999px;
						width: 26px;
						height: 26px;
						background: url(../../../assets/images/common/icon-download_mobile.svg) no-repeat 50% 50%;
						background-size: cover;
					}
				}
			}
		}
	}
}
