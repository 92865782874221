.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-size: 22px;
        font-family: Pretendard_extraBold;
    }

    .btns {
        display: flex;
        align-items: center;

        & > button {
            width: 132px;
            height: 32px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
        }

        .btn_add {
            margin-right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 145px;
            height: 32px;
            font-size: 15px;
            color: #ffffff;
            background-color: #4593f5;
        }

        .btn_excel_download {
            margin-right: 6px;
            background-color: #96c719;
        }

        .btn_tab_setting, .btn_tab_setting_save {
            background-color: #e92f2c;
        }

        .ToggleButtonWithSpan {
            margin-right: 31px;
        }
    }
}
.wrap_search {
    margin: 18px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    select {
        padding-left: 10px;
        width: 100px;
        height: 32px;
        font-size: 14px;
        border: solid 1px rgba(0, 0, 0, 0.54);
    }
    .input_search {
        padding: 0px 10px;
        min-width: 330px;
        width: 40%;
        height: 32px;
        font-size: 14px;
        border: solid 1px rgba(0, 0, 0, 0.54);
        border-left: 0;
    }
    .icon_search_black {
        margin-left: 15px;
        width: 32px;
        height: 32px;
        background: url("../../../assets/images/common/icon_search_black.svg") no-repeat;
    }
    select::placeholder, input::placeholder {
        color: rgba(0, 0, 0, 0.56);
    }
}

.sorting {
    margin: 5px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;

    .checkbox+.checkbox {
        margin-left: 35px;
    }
}

.table-box-wrap {
    margin-top: 13px;
}

.wrap_list {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 590px;
}

table {
    display: table;
    table-layout: fixed;

    th {
        ul {
            overflow-y: scroll;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            max-height: 150px;
            background-color: white;
            color: black;
            border: 1px solid rgb(0, 149, 174);
            z-index: 2;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            li {
                padding: 4px 0;
                display: flex;
                align-items: center;
                min-height: 32px;
                font-size: 12px;
                font-family: Pretendard_semiBold;

                .checkbox {
                    padding: 0 10px;
                    width: 100%;
                    height: 100%;
                    justify-content: flex-start;

                    .checkmark {
                       min-width: 20px;
                    }
                }

                span {
                    font-size: 12px;
                    text-align: left;
                }
            }

            li+li {
                border-top: 1px solid rgb(0, 149, 174);
            }
        }
        ul::-webkit-scrollbar {
            display: none;
        }
    }

    th.dp_none {
        ul {
            display: none;
        }
    }

    tbody {
        height: 50px;

        tr {
            height: 50px;

            td {
                word-break: break-all;

                &.apply_number {
                    white-space: pre;
                }
            }
        }
        tr:not(.more_view):not(.not_styled):hover {
            background-color: rgba(0, 149, 174, 0.1);
            transition-duration: 0.5s;
            cursor: pointer;
        }
        tr.active {
            background-color: rgba(0, 149, 174, 0.1);
        }

        tr.more_view {
            display: none;
            height: 101.5px;
            border: none;

            td {
                padding: 2px 0;
                display: block;
                max-width: 1280px;
                width: 100vw;
                height: 101.5px;
                border-bottom: 1px solid #e7e7e7;

                a {
                    position: relative;
                    display: inline-block;
                    padding: 5px 8px 9px 21px;
                    width: 142px;
                    height: 87px;
                    @include border-radius(5px);

                    .title {
                        font-size: 14px;
                        font-family: Pretendard_bold;
                        text-align: left;
                    }

                    p {
                        position: absolute;
                        bottom: 9px;
                        width: 100px;
                        display: flex;

                        i {
                            top: -7px;
                            right: 0;
                        }
                    }
                }
                a+a {
                    margin-left: 17px;
                }
                button {
                    position: relative;
                    top: 10px;
                    margin-left: 31px;
                }
                .detail_info {
                    border: solid 2px #96c719;
                }
                .process_state {
                    border: solid 2px #e35d5c;
                }
                .relative_file {
                    border: solid 2px #05ac9e;
                }
                .family {
                    border: solid 2px #4292dd;
                }
                .payment {
                    border: solid 2px #1bb6db;
                }
                .grade {
                    border: solid 2px #fea502;
                }
            }
        }
    }
}

i {
    position: absolute;
    top: 2px;
    right: 12px;
}

.no_click {
    pointer-events: none;
    opacity: 0.4;
}
