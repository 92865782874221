@import "src/include";

.docTradeMyTransactionPurchaseRow{
	height: 50px;
	color: rgba(0, 0, 0, 0.87);
	.num-info{
		color: #343434;
	}
	.transaction-info{
		color: #343434;
	}
	.transaction-info{
		text-align: left;
		padding-left: 26px;
		&_text{
			max-width: 430px;
			height: 36px;
			line-height: 36px;
			@include text-ellipsis(1);
		}
	}
	.price-info{
		.style{
			padding-left: 10px;
		}
	}
}