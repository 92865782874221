@import "src/mixin";

#IntroRedirectList {
    padding-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;

    @include mobile {
        padding: 0 0 20px 0;
        flex-direction: column;
    }

    .new_redirect_list {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 746px;

        .wrap {
            display: flex;
            align-items: center;
            column-gap: 5px;
        }

        a {
            position: relative;
            padding: 0 5px 0 11px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 242px;
            height: 100px;
            border-radius: 8px;
            box-shadow: 0 2px 3px 1px rgba(216, 216, 216, 0.3);
            background-color: #fff;

            .icon_new {
                position: absolute;
                top: 0;
                left: 0;
            }

            p {
                font-size: 18px;
                font-family: Pretendard_bold;
            }
        }
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 7px;
        background-color: var(--color-Gallery-opacity-02);

        @include desktop {
            width: 477px;
            max-width: 560px;
        }

        .wrap_img {
            margin-bottom: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            box-shadow: 0 2px 3px 1px var(--color-Alto-opacity-03);
            background-color: #ffffff;
        }

        a {
            padding: 10px 5px 0 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 86px;
            height: 120px;
            text-align: center;

            @include mobile {
                min-width: 157px;
                width: calc(48% - 15px);
            }

            img {
                @include mobile {
                    width: 47px;
                    height: 42px;
                }
            }

            p {
                height: 32px;
                font-size: 13px;
                font-family: Pretendard_bold;
                color: #343434;
            }
        }
    }
}
