#FindbizGraph {
    padding-top: 40px;

    @media screen and (max-width: 850px) {
        padding-top: 80px;
    }

    .findbizGraph {
        width: 950px;
        margin: 0 auto;
        padding: 40px 0;

        &_box-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 880px;
            height: 110px;
            margin: 0 auto 45px;
            padding: 10px 15px;
            background-color: #f1f1f1;
            border-radius: 20px;
        }

        &_box {
            width: calc(100% - 123px);
            height: 100%;
            position: relative;
            padding: 20px 0 20px 160px;

            &::after {
                position: absolute;
                content: '';
                width: 72px;
                height: 65px;
                top: 50%;
                left: 46px;
                transform: translateY(-50%);
                background: url(../../../assets/images/common/icon_topic-bell.svg) no-repeat 50% 50%;
                background-size: cover;
            }

            &_title {
                font-family: Pretendard_extraBold;
                font-size: 18px;
                color: #343434;
                margin-bottom: 10px;
            }

            &_sub {
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #606060;
            }

            &_btn {
                width: 123px;
                height: 24px;
                text-indent: -9999px;
                background: url(../../../assets/images/common/icon_tutorial_yellow.png) no-repeat 50% 50%;
                background-size: cover;
            }
        }
    }
}
