#AdminGPTBoard {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  position: relative;
  .category_tab {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    h2 {
      margin-bottom: 16px;
      font-size: 22px;
      font-family: Pretendard_bold;
    }
    ul {
      li {
        font-size: 14px;
        color: #343434;
        cursor: pointer;
      }

      li.active {
        font-family: Pretendard_bold;
        color: #ffa600;
      }

      li+li{
        margin-top: 15px;
      }
    }
  }
  .btns {
    position: absolute;
    top: 20px;
    right: 100px;
  }
  .btn_post {
    position: relative;
    top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 132px;
    height: 32px;
    font-size: 16px;
    color: #ffffff;
    background-color: var(--color-Alizarin-Crimson);
  }
  .category_name {
    position: absolute;
    top: 20px;
    left: 120px;
    font-size: 18px;
  }
}
