#MarketInfoRequest {
    //margin: auto;
    //width: 1280px;
    width:100%;
    padding:0 50px 0 50px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    .wrap {
        margin-top: 13px;
        //width: 1060px;
        width:100%;
        align-items: center;
        .title {
            margin-bottom: 8px;
            font-size: 22px;
            font-family: Pretendard_bold;
            color: #343434;
        }
        .sub_title {
            font-size: 14px;
            font-family: Pretendard_bold;
            color: #343434;
            letter-spacing: -0.25px;
        }
        .request_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            > div {
                margin-top: 15px;
            }
            input[type="text"],input[type="number"],select {
                height: 42px;
                border-radius: 4px;
                border: solid 1px #d8d8d8;
                font-family: Pretendard_semiBold;
                font-size: 14px;
                color: #343434;
                padding-left: 10px;
            }
            h2{
                min-width: 100px;
                font-family: Pretendard_extraBold;
                font-size: 14px;
                color: #343434;
                line-height: 1.29;
            }
            p {
                width:200px;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
                margin-left: 12px;
            }
            input[type="checkbox"] {
                display: none;
            }
            label {
                display: flex;
                align-items: center;
            }
        }
        .request_title {
            display: flex;
            align-items: center;
            input {
                //width: 851px;
                width:100%;
            }
        }
        .request_category {
            margin-right:30px;
            display: flex;
            align-items: center;
            select {
                //width: 225px;
                width:100%;
                border-radius: 4px;
                border: solid 1px #d8d8d8;
                padding: 12px 10px;
                font-family: Pretendard_semiBold;
                font-size: 14px;
                margin-right: 12px;
            }
            select option[value=""][disabled] {
                display: none;
            }
        }
        .request_content {
            display: flex;
            align-items: flex-start;
            textarea {
                width:100%;
                //width: 849px;
                height: 277px;
                font-family: Pretendard_semiBold;
                font-size: 14px;
                color: #343434;
            }
            h2 {
                margin-top: 5px;
            }
        }
        .request_price {
            display: flex;
            align-items: center;
            //width: 940px;
            width:100%;
            justify-content: space-between;
            > div {
                margin-right:30px;
                display: flex;
                align-items: center;
            }
            input[type="text"] {
                //width: 800px;
                //width:100%;
                //width:225px;
                width:500px;
            }
            i {
                margin-left: 3px;
            }
        }
        .request_page {
            display: flex;
            align-items: center;
            input {
                //width:225px;
                width:500px;
                //width: 1500px;
                //width:100%;
            }
        }
        .request_phone {
            display: flex;
            align-items: center;
            input {
                //width: 851px;
                width:100%;
            }
        }
        .btn_request {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 81px;
            button {
                width: 97px;
                height: 40px;
                font-family: Pretendard_bold;
                font-size: 14px;
                margin-left: 10px;
            }
            button:nth-child(1) {
                color: #343434;
                border-radius: 4px;
                background-color: rgba(107, 211, 255, 0.2);
            }
            button:nth-child(2) {
                color: #fff;
                border-radius: 4px;
                background-color: #4593f5;
            }
        }
    }
}
