.RelativeFileList {
    table {
        margin-top: 23px;

        caption {
            margin-bottom: 25px;
        }

        tbody {
            tr {
                height: 52px;
                td {
                    text-align: center;

                    input {
                        padding: 0 16px;
                        width: 80%;
                        height: 45px;
                        border: solid 1px rgba(0, 0, 0, 0.56);
                    }
                }
            }

            tr:hover .btns button {
                opacity: 1;
            }
        }
    }
}
