@import "src/include";

#GroupwarePims {
  //width: 1280px;
  margin: 0 auto;
  display: flex;

  .commute_wrapper {
    width: 100%;
    // background-color: rgba(216,216,216,0.28);
    padding: 0px 0px;
    @media screen and (max-width: 850px){
      width: 1220px;
    }
    >h2 {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
    }

    #contentsWrap {
      width: 100%;
      @media screen and (max-width: 850px){
        width: 1220px;
      }
    }
    .commute_grid_items {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;

      >div:nth-of-type(1) {
        grid-column: 1 / span 3;
      }

      >div:nth-of-type(5) {
        grid-column: 1 / span 2;
      }
    }

    .commute_grid_item {
      min-height: 180px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      padding: 30px 0;

      .grid_item_title {
        text-align: center;
        font-size: 20px;
        color: #343434;
        font-family: Pretendard_bold;
      }
    }

    .user_info {
      display: flex;
      padding: 33px 62px;

      >div:nth-child(1) {
        width: 66%;
        border-right: 1px solid #f0f0f0;

        >h5 {
          font-size: 16px;
          color: #343434;
          font-family: Pretendard_bold;

          span {
            font-family: Pretendard_regular;
            margin-left: 15px;
            font-size: 14px;
            letter-spacing: -0.7px;
            color: #959595;
          }
        }
      }

      >div:nth-child(2) {
        margin-left: 60px;
        text-align: center;
        line-height: 1.5;
      }

      .user_info_data {
        display: flex;
        align-items: center;
        margin-top: 20px;

        img {
          width: 64.2px;
          height: 63px;
          background-color: #94b2e0;
          border-radius: 50%;
        }

        >.user_name {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          p:first-child {
            font-size: 16px;
            color: #0078f1;
          }

          p:last-child {
            font-size: 26px;
            letter-spacing: -1.3px;
            color: #343434;

            span {
              margin-left: 10px;
              font-size: 16px;
            }
          }
        }

        .user_commute {
          margin-left: 100px;
          margin-top: 20px;
          font-size: 16px;
          letter-spacing: -0.8px;
          color: #606060;

          span {
            font-size: 26px;
            color: #0078f1;
            margin-right: 10px;
          }
        }
      }
    }

    .commute_datetime {
      display: flex;
      flex-direction: column;

      >time:nth-of-type(1) {
        font-size: 16px;
        letter-spacing: -0.8px;
        color: #959595;
      }

      >time:nth-of-type(2) {
        font-size: 22px;
        font-family: GmarketSansTTF_bold;
        @include text-gradient-green;
      }

      button {
        width: 160px;
        height: 45px;
        border-radius: 22.5px;
        background-image: linear-gradient(to right, #0078f1, #01bdb4);
        color: white;
      }
    }

    .percentage_bar_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 17px 0;

      .percentage_bar_text {
        display: flex;
        justify-content: flex-end;
        font-size: 11px;
        color: #606060;
        letter-spacing: -0.55px;
        width: 205px;
        margin-left: 40px;
        margin-bottom: 5px;

        p {
          margin-left: 10px;
        }
      }

      .percentage_bar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 205px;
        background-color: rgba(0, 120, 241, 0.2);
        border-radius: 5px;

        >div {
          height: 8px;
        }

        .percentage_bar_week {
          width: 140px;
          background-image: linear-gradient(to right, #0078f1, #01bdb4);
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .percentage_bar_remain {
          width: 65px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          background-color: #fc1923;
        }
      }
    }

    .doughnut_chart_wrapper,
    .bar_chart_wrapper {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    .doughnut_data {
      display: flex;
      justify-content: center;
      text-align: center;
      gap: 5px;

      >div {
        min-width: 80px;

        p {
          font-size: 14px;
          color: #343434;
          padding: 5px 0;
        }

        p:first-child {
          font-family: Pretendard_regular;
        }

        p:last-child {
          font-family: Pretendard_bold;
        }
      }

      >div:nth-of-type(1) {
        p:first-child {
          border-bottom: 2px solid #0078f1;
        }
      }

      >div:nth-of-type(2) {
        p:first-child {
          border-bottom: 2px solid #fc1923;
        }
      }

      >div:nth-of-type(3) {
        p:first-child {
          border-bottom: 2px solid #ffa600;
        }
      }
    }

    .bar_chart_data {
      display: flex;
      justify-content: center;
      text-align: center;
      gap: 15px;

      >div {
        p {
          margin-top: 10px;
        }

        p:first-child {
          font-size: 14px;
          color: #343434;
          font-family: Pretendard_regular;
        }

        p:last-child {
          color: #606060;
          font-size: 14px;
          font-family: Pretendard_regular;

          span {
            font-size: 16px;
            color: #0078f1;
            font-family: GmarketSansTTF_bold;
          }
        }
      }
    }

    .bar_chart_data_history {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >p {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #959595;
        margin-top: 10px;
        width: 200px;

        span {
          color: #343434;
          margin-left: 20px;
        }
      }
    }

    .commute_table_wrapper {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      >table {
        width: 545px;

        tr,
        tr>td {
          height: 30px;
          font-size: 14px;
        }

        thead>tr {
          background-image: linear-gradient(to right, #0078f1, #01bdb4);

          th {
            color: #FFFFFF;
            border-right: solid 1px #f0f0f0;
          }
        }

        td {
          border: solid 1px #f0f0f0;
        }
      }
    }

    .commute_link_btn {
      display: flex;
      justify-content: center;
      margin-top: 30px;

      button {
        width: 140px;
        height: 32px;
        border-radius: 16px;
        border: solid 1px #0078f1;
        background-color: #fff;
        font-size: 14px;
        font-family: Pretendard_regular;
        letter-spacing: -0.7px;
        color: #0078f1;
      }
    }
  }

  .address_wrapper {
    background-color: rgba(216, 216, 216, 0.28);
    padding: 30px;
  }

  iframe {
    //background-color: #f4f4f4;
  }
}
