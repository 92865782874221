@import "src/include";

#PatentView {
    margin: 36px auto 0 auto;
    width: 1280px;

    @import "../common/css/ListView";

    .border_blue {
        border-bottom: solid 1px var(--color-Niagara);
    }

    .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
    }
}
