#GroupwareApproval {
  //width: 1280px;
  margin: 0 auto;
  display: flex;
  @media screen and (max-width: 850px){
    width: 1500px;
  }
  .approval_wrapper {
    width: 100%;
    background-color: #fff;
    background-color: rgba(216,216,216,0.28);
    padding: 37px 41px;
    > h2 {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
      margin-bottom: 23px;
    }
  }
  .approval_grid_items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    > div {
      min-height: 140px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      position: relative;
      h5 {
        margin: 30px 0;
        text-align: center;
        font-size: 20px;
        color: #343434;
        font-family: Pretendard_bold;
      }
      button {
        width: 80px;
        height: 30px;
        border-radius: 16px;
        background-image: linear-gradient(to right, #0078f1, #01bdb4);
        font-family: Pretendard_medium;
        color: #FFFFFF;
        position: absolute;
        bottom: 21px;
        right: 15px;
      }
    }
    > div:nth-child(n+4) {
      grid-column: 1 / span 3;
    }

    .section {
      background-color: transparent;
      box-shadow: none;
      padding: 1.5rem 0;

      >h2 {
        font-size: 22px;
        letter-spacing: -0.25px;
        color: #343434;
        margin-bottom: 23px;
      }

      .doc-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 1rem;

        .doc-item {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          background-color: white;
          border-radius: 0.5rem;
         // border: 1px solid #e2e8f0;
          transition: all 0.2s;
          min-height: initial;
          box-shadow: none;
          cursor: pointer;

          &:hover {
            border-color: #3182ce;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          i {
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            transform: translateY(4px);
          }

          span {
            flex: 1;
            color: #4a5568;
            word-break: keep-all;
          }
        }
      }
    }
  }
  .approval_apply {
    padding: 23px 15px 0 32px;
    > p {
      display: flex;
      align-items: center;
      font-size: 22px;
      font-family: Pretendard_bold;
      letter-spacing: -1.1px;
      color: #343434;
      i {
        margin-right: 5px;
      }
    }
    .item_head_title {
      font-size: 16px;
      font-family: Pretendard_regular;
      color: #343434;
      margin-top: 15px;
    }
    .item_sub_title {
      font-size: 14px;
      font-family: Pretendard_regular;
      letter-spacing: -0.7px;
      color: #959595;
      margin-top: 10px;
    }
  }
  .approval_notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 355px;
    > div {
      width: 235px;
      height: 50px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      border: solid 1px #0078f1;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      margin-bottom: 10px;
      > p {
        font-family: Pretendard_regular;
        font-size: 16px;
      }
      > p:nth-child(1) {
        color: #343434;
      }
      > p:nth-child(2) {
        letter-spacing: -0.8px;
        color: #606060;
        span {
          font-family: GmarketSansTTF_bold;
          color: #0078f1;
        }
      }
    }
  }
  .approval_table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    > table {
      width: 98%;
      thead tr {
        height: 35px;
        background-image: linear-gradient(to right, #0078f1, #01bdb4);
        th {
          font-family: Pretendard_medium;
          font-size: 14px;
          color: #FFFFFF;
        }
      }
      tbody tr td {
        font-family: Pretendard_regular;
        font-size: 14px;
        color: #343434;
      }
    }
  }
}
