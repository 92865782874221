#ContentDetail {
    margin: auto;
    padding-top: 42px;
    width: 880px;

    .page_title {
        margin-bottom: 31px;
        font-size: 22px;
        font-family: Pretendard_extraBold;
    }

    .post {
        .title {
            margin-bottom: 20px;
            font-size: 22px;
            font-family: Pretendard_bold;
            word-break: keep-all;
            text-align: center;
        }

        .post_info {
            margin-bottom: 43px;
            line-height: 30px;
            font-size: 14px;
            text-align: right;
            border-bottom: 1px solid #e7e7e7;
        }

        .attachment_file {
            padding-top: 16px;
            display: flex;
            border-top: 1px solid #e7e7e7;

            h2 {
                width: 100px;
                line-height: 30px;
                font-size: 14px;
                font-family: Pretendard_bold;
            }

            ul {
                li {
                    line-height: 30px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.87);
                    cursor: pointer;
                }
            }
        }
    }

    .btn_list {
        margin: 30px auto;
        display: block;
        width: 120px;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: var(--color-Web-Orange);
    }

    @media screen and (max-width: 850px) {
        padding-top: 60px;
        width: 100%;

        .post {
            .title {
                margin: 0;
                padding: 10px;
            }

            .post_info {
                margin: 0;
                height: 0;
                font-size: 0;
            }

            .post_content {
                padding: 15px 35px;
                min-height: auto;

                * {
                    letter-spacing: 0.5px !important;
                }
            }

            .attachment_file {
                padding: 16px 10px 0 10px;
            }
        }

    }
}
