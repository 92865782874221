#AnnualFeeManage {
    margin: auto;
    padding-top: 42px;
    width: 1280px;
    .header {
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;
        .title {
            margin-bottom: 12px;
            font-size: 22px;
        }

        > p {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 21px;

            i {
                margin-left: 6px;
            }
        }

        ul {
            margin-top: 10px;
            display: flex;

            li {

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100px;
                    height: 30px;
                    padding: 3px 8px;
                    border-radius: 5px;
                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
                    background-color: #ffffff;

                   p {
                       display: flex;
                       align-items: center;
                       font-size: 14px;
                       font-family: Pretendard_bold;
                       color: rgba(0, 0, 0, 0.87);

                      i {
                          position: absolute;
                          right: 8px;
                      }
                   }
                }
            }

            li+li {
                margin-left: 10px;
            }
        }
        button {
                width: 150px;
                height: 32px;
                font-size: 16px;
                font-family: Pretendard_bold;
                border-radius: 4px;
                color: #ffffff;
                background-color: #e92f2c;
        }
    }

    .annual_fee_state {
        position: relative;
        float: right;
        margin-top: 30px;
        width: 0;

        hr {
            position: absolute;
            top: 12px;
            left: 30px;
            //max-width: 1150px;
            height: 1px;
            color: #fff;
            border: none;
            border-top: 3px dotted var(--color-Web-Orange);
            background-color: #fff;
        }

        .wrap_state {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .state {
                width: 40px;
                line-height: 24px;
                height: 24px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                border-radius: 50px;
                z-index: 3;
            }

            .state.red {
                background-color: #ed3d30;
            }

            .state.current {
                background-color: #00ae02;
            }
        }

        .start_point {
            content: "";
            position: absolute;
            top: 8px;
            left: 30px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--color-Web-Orange);
            z-index: 2;
        }
        .end_point {
            position: absolute;
            top: 8px;
            right: 32px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--color-Web-Orange);
            z-index: 2;
        }
    }
}
