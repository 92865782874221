@import "src/include";

#MobilePCServiceAlert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;

    .popup {
        margin: auto;
        position: relative;
        top: 15%;
        width: 320px;
        @include border-radius(8px);
        background-color: #ffffff;
        overflow: hidden;

        .content {
            padding: 23px 0;
            background-color: #fcfcfc;
            text-align: center;

            .logo {
                width: 84px;
            }

            img {
                margin: auto;
                display: block;
            }

            .bg {
                position: absolute;
                bottom: 32%;
                z-index: 0;
            }

            .bg1 {
                left: 50%;
                transform: translateX(-50%);
            }

            .title {
                margin: 15px 0;
                font-size: 20px;
                font-family: Pretendard_extraBold;
                color: var(--color-Niagara);
            }

            .desc1 {
                font-size: 14px;
                font-family: Pretendard_bold;
                color: var(--color-Scorpion);

                span {
                    font-size: 16px;
                    font-family: Pretendard_extraBold;
                    color: var(--color-Malibu);
                }

                b {
                    color: var(--color-Tarawera);
                }
            }

            .desc2 {
                margin: 10px 0 15px 0;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                line-height: 22px;
                color: var(--color-Scorpion);
            }

            .notify {
                font-size: 13px;
                font-family: Pretendard_bold;
                color: var(--color-Scorpion);

                b {
                    font-family: Pretendard_extraBold;
                    color: var(--color-Mine_Shaft);
                }
            }

            .btn_join {
                margin: 85px auto 10px auto;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 194px;
                height: 42px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #ffffff;
                border-radius: 20px;
                background-color: var(--color-Malibu);
                z-index: 1;
            }
        }

        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 50%;
                height: 49px;

                * {
                    font-size: 12px;
                    font-family: Pretendard_bold;
                    color: #606060;
                }
            }


            .checkbox {
                column-gap: 5px;
            }
        }
    }

    .pc_popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .content {
            background-color: #fff;
            border-radius: 10px;
            color: #343434;
        }
        .notice {
            background-color: #2e86de;
            text-align: center;
            padding: 20px 0;
            color: #FFFFFF;
            font-size: 22px;
            border-radius: 10px 10px 0 0;
        }
        .padding_content {
            padding: 30px 30px 0 30px;
            > h2 {
                font-size: 18px;
                line-height: 1.6;
                font-family: Pretendard_regular;
                span {
                    font-family: Pretendard_semiBold;
                    color: black;
                }
            }
            > .content_info {
                display: flex;
                flex-direction: column;
                margin: 20px 0 20px 0;
                border-top: 1px solid black;
                border-bottom: 1px solid black;
                > div {
                    display: flex;
                    align-items: center;
                    min-height: 50px;
                    > p {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        min-height: 50px;
                        height: 100%;
                        font-family: Pretendard_regular;
                    }
                    > p:nth-of-type(1) {
                        min-width: 100px;
                        text-align: center;
                        background-color: #FAFAFA;
                        border-right: 1px solid #d5d5d5;
                    }
                    > p:nth-of-type(2) {
                        margin-left: 10px;
                    }
                }
                .detail_info {
                    > p:nth-of-type(1) {
                        height: 100px;
                    }
                    > div {
                        margin-left: 10px;
                        line-height: 1.6;
                        font-family: Pretendard_regular;
                        span {
                            margin-left: 10px;
                            display: inline-block;
                        }
                    }
                }
                > div:nth-child(-n+3) {
                    border-bottom: 1px solid #d5d5d5;
                }
            }
            > h5 {
                font-size: 18px;
                line-height: 1.6;
                margin-bottom: 10px;
                font-family: Pretendard_regular;
            }
        }
        .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border-top: 1px solid #d5d5d5;
            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
                width: 50%;
                height: 49px;
                * {
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    color: #606060;
                }
                .icon_exit {
                    width: 18px;
                    height: 18px;
                    background-size: contain;
                    cursor: pointer;
                }
            }
            .checkbox {
                column-gap: 5px;
            }
        }
    }
}
