#MobileInfoEdit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 10000;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 22px;
            color: #443018;
        }
    }

    .form {
        margin: 0 auto 32px auto;
        padding-top: 33px;
        width: 90%;

        .wrap_input {
            position: relative;

            h2 {
                margin-bottom: 8px;
                font-size: 12px;
                font-family: Pretendard_extraBold;
                line-height: 18px;
                color: #443018;
            }

            input {
                padding: 0 12px;
                width: 100%;
                height: 40px;
                font-size: 14px;
                font-family: Pretendard_bold;
                line-height: 20px;
                color: #606060;
                border: 1px solid var(--color-Alto);
                border-radius: 2px;

                &.has_btn {
                    width: calc(100% - 80px);
                }
            }

            input:disabled {
                background-color: #f0f0f0;
            }

            input::placeholder {
                color: #aaaaaa;
            }

            button {
                margin-left: 10px;
                width: 70px;
                height: 40px;
                font-size: 12px;
                font-family: Pretendard_bold;
                color: #ffffff;
                border-radius: 2px;
                background-color: var(--color-Dusty-Gray);
            }

            .alert {
                display: flex;
                align-items: center;

                i {
                    margin-right: 6px;
                }

                p {
                    font-size: 12px;
                    font-family: Pretendard_bold;
                    line-height: 18px;
                    color: #959595;
                }
            }
        }

        .wrap_input+.wrap_input {
            margin-top: 20px;
        }

        .btn {
            margin: 32px auto 12px auto;
            display: block;
            width: 320px;
            height: 40px;
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 22px;
            color: #ffffff;
            border-radius: 4px;
            background-color: var(--color-Web-Orange);
        }
    }
}
