#TestChart {
  margin-left: 25px;

  div {
    margin-top: 25px;
  }

  button {
    width: 80px;
    height: 30px;
  }

  .chart_wrap {
    width: 500px;
  }

  .button_wrap {
    margin-top: 20px;
    display: flex;
    column-gap: 20px;
  }

  .input_list_wrap {
    margin-top: 20px;

  }

}