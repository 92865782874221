@import "../../../include";

.ToggleButton {
    position: relative;
    padding: 2px;
    width: 44px;
    height: 24px;
    border: 1px solid #d8d8d8;
    @include border-radius(15px);
    background-color: #ffffff;
    transition: all 0.3s ease;
    cursor: pointer;
    user-select: none;

    .dialog-button {
        position: absolute;
        top: 1px;
        left: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        cursor: pointer;
        background-color: #ffffff;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        @include border-radius(15px);
        transition: all 0.3s ease;
    }

    .off {
        left: 1px;
        background-color: var(--color-Web-Orange);
    }
}
.ToggleButton.on {
    border: none;
    background-color: var(--color-Web-Orange);
}
