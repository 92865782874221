.estimate_input {
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    min-width: 100px;
    .require {
      color: red;
    }
  }
}
