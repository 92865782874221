// Modal.scss
#PatentDownload {
  .modal__container {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    .modal {
      width: 300px;
      height: 150px;
      background-color: #fff;
      // Modal 창 브라우저 가운데로 조정
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;

      .modal__button {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .space-between {
    justify-content: space-between;
  }

  .chartLabel {
    position: absolute;
    padding-top: 63.5px;
    margin-left: 5px;

  }

  .wow {
    margin-bottom: 4.9px;
    width: 120px;

  }

  .xwow {
    width: 110px;
  }

  .dwow {
    width: 110px;
  }

  .xLabel {

    display: flex;
    padding-left: 12%;
    padding-top: 10px;


    //background-color: rgb(211, 26, 51);
  }


  .Data {

    position: static;
    padding-top: 5px;
    padding-left: 12%;
  }


  .chart_wrap {
    width: 800px;
    height: 400px;
    margin-left: 15%;
    margin-top: 5%;
  }

  .sub_chart {
    width: 250px;
    height: 180px;
  }

  .button_wrap {
    margin-top: 20px;
    display: flex;
    column-gap: 15px;
    margin-left: 5%;
    margin-bottom: 2%;
    flex-direction: column;

  }

  .button_2 {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .imgimg {
    position: absolute;
    top: 10px;
    left: 850px;
  }

  input {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f2f2f2;
    padding: 10px;
    border-radius: 3px;
    margin: 5px;
  }

  .dataLine {
    display: flex;
  }

  .buttonLine {

    background: #00ffff;
    margin-top: 0.5%;
  }

  #MainTitle {
    color: #1D334E;
    font-size: 40px;
    font-weight: bold;
    margin-left: 11%;

  }

  #SubTitle {
    color: #1D334E;
    font-size: 20px;
    font-weight: bold;
    margin-left: 12%;
  }

  .content_title {
    color: #4D99F1;
    font-size: 30px;
    font-weight: bold;
    margin-left: 11%;
  }

  .title_section {
    padding-left: 2rem;
    display: inline-block;
    //background-color: #1D334E;
    margin-left: 11%;
    width: 1250px;
  }

  #hihi1 {
    display: inline-block;
    height: 50px;
    padding-left: 2rem;
    margin: 1rem;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    //background-color: #00ffff;

  }

  #boxbox1 {

    width: 1000px;
    height: 55px;
    //display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #dadada;
    border-radius: 5px;
    margin-left: 11.2rem;
    margin-bottom: 5rem;
    background-color: white;

  }

  .graph_section {
    padding-left: 2rem;
    display: inline-block;
    //background-color: #e21ce2;
    margin-left: 11%;
    display: flex;
    justify-content: row;
    width: 1250px;

  }

  #hihi2 {
    display: inline-block;
    height: 50px;
    padding-left: 2rem;
    margin: 1rem;
    font-weight: bold;
    text-align: center;
    line-height: 50px;
    //background-color: #00ffff;

  }

  #boxbox2 {

    width: 1000px;
    height: 500px;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #dadada;
    border-radius: 5px;
    margin-left: 2rem;
    //background-color: #16f016;
    display: flex;
    justify-content: center;

  }

  #chart12 {
    //background-color: #00ffff;
    width: fit-content;
    margin-right: 20%;
    //position: static;
  }


  //side bar css
  .graph_line { // 그래프 박스 크기
    border: 2px solid #dadada;
    width: 350px;
    height: 180px;
    border-radius: 5px;

  }

  .flex_column2 {
    display: flex;
    flex-direction: column;
  }

  .flex_column {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    margin-right: 25px;
    margin-left: 13.2%;
  }

  .margin {
    margin: 5px;
    padding-top: 35px;
    font-weight: bold;
    font-size: 25px;
  }

  .margin1 {
    color: #4D99F1;
    font-size: 30px;
    font-weight: bold;
    margin-left: 11%;
    margin-bottom: 25px

  }

  .button_side {
    display: flex;
    flex-direction: row;
  }

  .button_right { // 전체 박스 크기
    border: 2px solid #F2F2F2;
    width: 400px;
    font-size: large;
    margin-left: 10rem;
    height: 100%;
    //background-color: #00ffff;

  }

  .hr_border {
    border: 1px solid #F2F2F2;

  }

  .flex_around {
    display: flex;
    justify-content: space-around;
    margin: 5px;
    font-weight: bold;
  }

  .flex_center {
    display: flex;
    justify-content: center;
    margin: 5px;
  }

  .modified_graph {
    font-weight: bold;
  }

  //****************
  #bottom_field {
    background-color: #00ffff;

  }

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}







