#AdminRecommendTask {
    padding-top: 40px;

    .head {
        margin-bottom: 34px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            width: 300px;
            height: 35px;
            border: 1px solid var(--color-Alto);
        }

        .icon_exit {
            position: absolute;
            right: 5px;
        }
    }

    .title {
        font-size: 22px;
        font-family: Pretendard_extraBold;
    }

    table {
        margin-bottom: 30px;
    }

    .btn {
        width: 60px;
        height: 30px;
        color: #ffffff;
    }
}
