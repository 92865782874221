@import "src/include";
@import "src/mixin";

#MailDeny {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .image_div {
    .mail_image {
      margin-top: 160px;
      margin-left: 18px;
    }
  }

  .text_content {
    font-family: Pretendard_bold, serif;
    color: #343434;

    .s1 {
      margin-top: 34px;
      font-size: 22px;
    }

    .s2 {
      margin-top: 21px;
      font-size: 14px;
    }
  }

  .button_div {
    margin-top: 35px;
    text-align: center;
    cursor: pointer;
  }

}
