#AdminDummyAccount {
  width: 980px;
  margin: 40px auto;
  input {
    width: 300px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    font-size: 14px;
    padding-left: 10px;
  }
  .title {
    text-align: center;
    font-family: Pretendard_extraBold;
    font-size: 32px;
  }
  .input_items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 40px 0;
  }
  .input_item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    p {
      min-width: 100px;
    };
    i {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 5px;
      top: 8px;
    }
  }
  .create_button {
    margin-top: 40px;
    width: 120px;
    height: 40px;
    background-color: black;
    color:white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
  table {
    button {
      width: 40px;
      height: 30px;
      color:#FFF;
      border-radius: .25em;
      text-shadow: -1px -1px 0px rgba(0,0,0,0.4);
    }
    button:hover {
      box-shadow:2px 2px 5px rgba(0,0,0,0.20), inset 0 0 0 99999px rgba(0,0,0,0.2);
    }
    .update_btn {
      background-color: rgb(52, 152, 219);
      margin-left: 10px;
    }
    .delete_btn {
      background-color: rgb(192, 57, 43);
    }
    td div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 10px;
      p {
        min-width: 60px;
      }
      i {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 5px;
        top: 10px;
      }
    }
  }
}
