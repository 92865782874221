@import "src/include";

#ResearchTechPatentInfo {
  //width: 1280px;
  //margin: 0 auto;
  margin: 0 50px;
  padding: 20px 0;
  > header {
    padding: 0 30px;
    position: relative;
    p:nth-of-type(1) {
      width: 880px;
      letter-spacing: -0.25px;
      color: #343434;
      font-family: Pretendard_medium;
      font-size: 22px;
      margin-bottom: 13px;
    }
    p:nth-of-type(2) {
      letter-spacing: -0.25px;
      color: #343434;
      font-family: Pretendard_regular;
      font-size: 16px;
    }
    > button {
      position: absolute;
      top: 11px;
      right: 30px;
      font-size: 16px;
      color: #343434;
      border-radius: 10px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      width: 159px;
      height: 37px;
    }
  }
  > .info_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: solid 1px #f0f0f0;
    > div {
      display: flex;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;
      height: 60px;
    }
    .active {
      border-bottom: 3px solid #0078f1;
    }
    button {
      height: 40px;
      width: 100px;
      color: #ffffff;
      background-color: #2d99ff;
      border-radius: 10px;
      font-size: 14px;
    }
    button + button {
      margin: 0 30px;
    }
  }
  aside {
    width: 220px;
    > div {
      display: flex;
      align-items: center;
      height: 49px;
      p {
        margin-left: 10px;
      }
    }
  }
  > .patent_info_wrapper {
    width: 1280px;
    display: flex;
  }
  .patent_info_items {
    padding: 20px;
    background-color: rgba(216, 216, 216, 0.28);
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      border-radius: 15px;
      box-shadow: 0 2px 3px 1px #f0f0f0;
      background-color: #fff;
      min-height: 396px;
      margin-bottom: 20px;
      padding: 20px 24px;
      width: 100%;
      > h5 {
        font-size: 22px;
        color: #4593f5;
        margin-bottom: 31px;
      }
    }
    .patent_info_grid {
      > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px 59px;
        > div {
          display: flex;
          align-items: center;
          min-height: 42px;
          border-bottom: 1px solid #e7e7e7;
          p:nth-child(1) {
            min-width: 120px;
            font-size: 14px;
            letter-spacing: 0.15px;
            color: #343434;
          }
          p:nth-child(2) {
            font-family: Pretendard_regular;
            color: #343434;
          }
        }
        > div:nth-child(3) {
          grid-column: 1 / 2;
        }
        > div:nth-child(8),
        div:nth-child(9) {
          grid-column: 1 / span 3;
        }
      }
    }
    .patent_idea {
      > div {
        display: flex;
        margin-bottom: 23px;
        p:nth-of-type(1) {
          min-width: 120px;
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #343434;
        }
        p:nth-of-type(2) {
          font-family: Pretendard_regular;
          line-height: 1.57;
          letter-spacing: -0.3px;
          font-size: 14px;
        }
      }
    }
  }
  > .patent_spec {
    //width: 1280px;
    width: 100%;
    background-color: rgba(216, 216, 216, 0.28);
    padding: 22px 12px;
    display: flex;
    gap: 24px;
    > div {
      border-radius: 15px;
      box-shadow: 0 2px 3px 1px #f0f0f0;
      background-color: #fff;
      width: 50%;
      > h5 {
        font-size: 22px;
        color: #4593f5;
        padding: 18px 24px;
      }
    }
    .spec_tab {
      display: flex;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #f0f0f0;
      > div {
        display: flex;
        justify-content: center;
        width: 90px;
        font-size: 14px;
      }
    }
    .patent_spec_items {
      margin-top: 34px;
      padding: 18px 24px;
      > div {
        display: flex;
        margin-bottom: 18px;
        p:nth-child(1) {
          min-width: 120px;
          line-height: 1.71;
        }
        p:nth-child(2) {
          //width: 430px;
          width: 100%;
          line-height: 1.71;
          letter-spacing: -0.5px;
          color: #343434;
        }
        span {
          line-height: 1.71;
        }
      }
      > p {
        color: #4593f5;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .tree {
    margin-top: 31px;
    overflow-x: scroll;

    .root {
      margin-bottom: 51px;
      position: relative;

      ul {
        li {
          margin-bottom: 10px;
          position: relative;
          display: flex;
        }
      }

      ul:last-child {
        li:last-child {
          margin-bottom: 0;
        }
      }

      [class^="level_"] {
        margin-left: 75px;
        // margin-bottom: 30px;
        position: relative;
      }
      .level_1 {
        margin-left: 0;
        margin-bottom: 51px;
        display: flex;

        [class^="level_"]:before {
          position: absolute;
          top: 35px;
          left: -37.5px;
          width: 2px;
          height: calc(100% - 72px);
          content: "";
          background-color: #0078f1;
        }
        .level_2 li:last-child:after {
          position: absolute;
          top: 37px;
          left: -37.5px;
          width: 2px;
          height: calc(100% - 72px);
          content: "";
          background-color: #ffffff;
        }
      }
      .level_1:last-child:after {
        position: absolute;
        top: 37px;
        left: 255px;
        width: 2px;
        height: calc(100% - 72px);
        content: "";
        //background-color: #ffffff;
        z-index: 99;
      }
      .level_1:not(.single):before {
        position: absolute;
        top: 35px;
        left: 242px;
        width: 15px;
        height: 2px;
        content: "";
        background-color: #0078f1;
      }
      .level_2 li:first-child:before {
        left: -75px;
        width: 75px;
      }
      [class^="level_"] li::before {
        position: absolute;
        top: 35px;
        left: -37.5px;
        width: 37px;
        height: 2px;
        content: "";
        background-color: #0078f1;
      }
      .level_2 li.dp_0::before {
        background-color: transparent;
        border-bottom: #0078f1 2px dashed;
      }
      .node {
        display: flex;
        flex-direction: column;
        min-width: 182px;
        height: 74px;
        border-radius: 15px;
        box-shadow: 0 2px 8px 0 #b4e9ff;
        border: solid 0.8px #0078f1;
        background-color: #fff;
        padding: 8px 16px;
        cursor: pointer;
        .idx {
          font-size: 16px;
        }
        .flag {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .flag_txt {
            font-size: 14px;
            font-family: Pretendard_regular;
          }
        }
        .no {
          font-size: 14px;
          letter-spacing: -0.25px;
          color: #343434;
        }
        .date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: #0078f1;
        }
      }
    }
    .root:not(.single):after {
      position: absolute;
      top: 35px;
      left: 195px;
      width: 2px;
      height: calc(100% - 71px);
      content: "";
      background-color: var(--color-Web-Orange);
    }
  }
  > .company_info_wrapper {
    > div {
      margin-bottom: 2px;
      h3 {
        font-family: Pretendard_bold;
        font-size: 28px;
        letter-spacing: -0.88px;
        padding: 22px 0 0 15px;
        span {
          color: #0078f1;
        }
      }
      .more_company_btn {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .company_info_items_ipnow {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        margin: 10px 0;
        padding: 10px 0;
        height: 700px;
        overflow-y: scroll;
      }
      .company_info_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        margin: 10px 0;
        padding: 10px 0;
        max-height: 420px;
        overflow-y: scroll;
      }
      .company_info_item {
        display: flex;
        align-items: center;
        width: 1043px;
        height: 102px;
        border-radius: 15px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
        position: relative;
        img {
          width: 63px;
          height: 63px;
          margin: 13px;
        }
        button {
          position: absolute;
          right: 17px;
          width: 104px;
          height: 40px;
          font-size: 14px;
          color: #fff;
          border-radius: 10px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
        }
        > .item_text {
          line-height: 1.5;
          > p:nth-child(1) {
            font-size: 18px;
            letter-spacing: -0.5px;
            color: #343434;
          }
          > p:nth-child(2) {
            font-size: 14px;
            //width: 310px;
            // @include text-ellipsis(1) : 글자 자르기(넓이로 자동조정)
          }
          > .item_text_category {
            > p {
              display: inline-block;
              font-family: Pretendard_bold;
              padding: 3px 9px;
              margin-right: 7px;
              font-size: 12px;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              border: 1px solid transparent;
              border-radius: 14px;
              background: linear-gradient(#ffffff, #ffffff) padding-box,
                linear-gradient(to left, #0d7, #0078f1);
              background-origin: border-box;
            }
          }
        }
      }
    }
    > div {
      background-color: rgba(240, 240, 240, 0.44);
      border-radius: 14px;
    }
  }
  .loading {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    color: black;
  }
  .marketInfoMain_list {
    padding: 5px 0 5px 20px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    row-gap: 15px;
    flex-wrap: wrap;
    background-color: rgba(240, 240, 240, 0.2);
    width: 100%;
    @media screen and (max-width: 850px) {
      justify-content: center;
    }

    p {
      font-size: 18px;
      font-family: Pretendard_bold;
      color: #606060;
    }
  }

  .input_wrapper {
    display: flex;
    align-items: center;
    margin: 30px 0 10px 0;
    > p {
      min-width: 110px;
      display: flex;
      align-items: center;
      position: relative;
    }
    .search {
      //width: 1100px;
      width: 100%;
      height: 42px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      input[type="text"] {
        border: none;
        height: 30px;
        width: 900px;
      }
    }
    .btn_marketinfo_report {
      height: 42px;
      margin-left: 15px;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #ffffff;
      color: #343434;
      i {
        margin-right: 5px;
      }
    }
  }

  .text_box {
    min-width: 400px;
    min-height: 30px;
    background: #c2e1f5;
    border: 4px solid #c2e1f5;
    margin: 0 0 20px 110px;
    //width: 1100px;
    width: calc(100% - 110px);
    border-radius: 5px;
    line-height: 1.5;
    p:nth-child(1) {
      display: flex;
      align-items: center;
      i {
        margin-right: 5px;
      }
    }
    p:nth-child(2) {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: 28px;
      font-family: Pretendard_regular;
    }
  }

  .marketInfoMain_item {
    display: flex;
    flex-direction: column;
    padding-top: 27px;
    width: 240px;
    height: 250px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background-color: #fff;
    .chart_isComplete {
      padding: 2px;
      font-size: 12px;
      width: 26px;
      border-radius: 4px;
      background-color: #ee5253;
      color: #ffffff;
      font-family: Pretendard_semiBold;
    }
    img {
      width: 238px;
      height: 140px;
    }

    .info {
      padding: 5px 10px;
      h2 {
        width: 100%;
        font-size: 14px;
        font-family: Pretendard_bold;
        min-height: 40px;
        line-height: 1.43;
        color: #343434;
        @include text-ellipsis(2);
        word-break: break-word;
      }
    }

    .etc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .etc_btn {
        display: flex;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-right: 5px;
      }
      .content_btn {
        width: 85px;
        height: 24px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #4593f5;
        border-radius: 14px;
        background-color: rgba(107, 211, 255, 0.2);
      }
      .chart_btn {
        width: 85px;
        height: 24px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #4593f5;
        border-radius: 14px;
        border: solid 0.8px #f0f0f0;
        background-color: rgba(240, 240, 240, 0.3);
      }
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}
