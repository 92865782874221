#CompanyCode {
    margin: auto;
    padding-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    width: 1280px;

    .area {
        width: 880px;

        .header {
            margin-bottom: 28px;
            display: flex;
            justify-content: space-between;
            height: 30px;

            h2 {
                font-size: 22px;
                font-family: Pretendard_extraBold;
           }
        }

        .box {
            padding: 24px 137px 23px;
            width: 910px;
            height: 76px;
            border-radius: 5px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
            border: solid 1px #e7e7e7;
            background-color: #ffffff;

            p {
                line-height: 32px;
                font-size: 24px;
                font-family: Pretendard_bold;
                text-align: center;
                color: rgba(0, 0, 0, 0.87);

                input {
                    width: 100px;
                    font-size: 24px;
                    font-family: Pretendard_bold;
                    border: none;
                }

                input:focus {
                    outline: none;
                }
            }
        }

        .company_code_info {
            margin-top: 15px;
            display: flex;
            align-items: center;

            i {
                margin-right: 10px;
            }
        }

        button {
            margin: 30px auto 0 auto;
            display: block;
            width: 132px;
            height: 32px;
            font-size: 16px;
            color: #ffffff;
            background-color: #4593f5;
        }
    }
}
