@import "src/include";

.checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    pointer-events: auto;

  /* Hide the browser's default checkbox */
    input[type="checkbox"] {
        position: absolute;
        display: none;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
        border: none;
        cursor: pointer;
    }

    // 웹 접근성 ie
    input[type="checkbox"]:focus {
        box-shadow: 0 0 0 2px rgba(0, 149, 174, 0.54);
        outline: 1px solid transparent;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        border: none;
        background-color: var(--color-Web-Orange);
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: relative;
        width: 20px;
        height: 20px;
        border: 2px solid #d8d8d8;
        @include border-radius(4px);
        background-color: #ffffff;

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Style the checkmark/indicator */
        &:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .value {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
    }
}


.checkbox:hover {
    input ~ .checkmark {
        background-color: var(--color-Web-Orange);
    }
}

.checkbox.disabled {
    pointer-events: none !important;

    input ~ .checkmark {
        background-color: #b8b8b8;
    }
}

.wrap_tab_btns {
    margin-top: 25px;
    position: relative;
    display: flex;
    flex-flow: wrap;

    .checkbox {
        margin-bottom: 11px;

        .value {
            display: block;
            width: 80px;
        }
    }
}
