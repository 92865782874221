#AnnualFeeReviewSecond {
  position: absolute;
  width: 480px;
  min-height: 530px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #343434;
  padding: 46px 35px;
  h2 {
    font-family: Pretendard_bold;
    font-size: 22px;
  }
  p {
    display: flex;
    align-items: center;
  }
  .annual_fee_review_header {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  .annual_fee_review_body {
    margin-top: 35px;
    > div {
      display: flex;
      margin-top: 20.5px;
    }
    textarea {
      width: 408px;
      height: 168px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      margin-top: 12px;
      font-family: Pretendard_semiBold;
      font-size: 14px;
      color: #343434;
    }
    .review_manage_number {
      border-bottom: solid 1px #d8d8d8;
      padding: 10px 0;
      position: relative;

      p:nth-child(1) {
        font-family: Pretendard_extraBold;
        font-size: 14px;
        margin-right: 20px;
      }

      p:nth-child(2) {
        font-family: Pretendard_semiBold;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
      button {
        position: absolute;
        right: 11px;
        bottom: 14px;
        width: 95px;
        height: 26px;
        border-radius: 4px;
        border: solid 2px #606060;
        background-color: #fff;
        font-family: Pretendard_bold;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: #343434;
      }
    }
    .review_upload_file {
      display: flex;
      align-items: center;
      .file_input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 226px;
        height: 36px;
        border-radius: 5px;
        background-color: rgba(240, 240, 240, 0.8);
      }
      input[type="file"] {
        display: none;
      }
      label {
        font-family: Pretendard_extraBold;
        font-size: 14px;
        letter-spacing: 0.19px;
        color: #343434;
      }
      .file_list_items {
        display: flex;
        flex-direction: column;
        > div {
          display: flex;
          align-items: center;
          border-bottom: solid 1px #d8d8d8;
          padding: 5px;
          p {
            width: 280px;
          }
        }
      }
    }
    .review_manage_comment {
      border-bottom: solid 1px #d8d8d8;
      padding: 10px 0;
      .title {
        font-family: Pretendard_extraBold;
        font-size: 14px;
        margin-right: 20px;
      }
      .commenter {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: Pretendard_semiBold;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
      }
    }
  }
  .review_manage_footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    button {
      width: 84px;
      height: 40px;
      border-radius: 4px;
      background-color: #4593f5;
      color: white;
      font-family: Pretendard_bold;
      font-size: 14px;
    }
  }
}
