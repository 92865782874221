.package {
  > p {
    text-align: center;
    i {
      width: 24px;
      height: 20px;
      background-size: contain;
      background-position: center center;
    }
    span {
      font-family: Pretendard_Regular;
      font-size: 14px;
      letter-spacing: -0.56px;
      color: #4a4a4a;
    }
  }
}
