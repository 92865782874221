@import "src/include";

#TradeItemView {
    padding-bottom: 40px;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;

    .pc {
        margin: auto;
        padding-top: 82px;
        width: 880px;

        > .head {
            margin-bottom: 26px;
            padding-bottom: 7px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            border-bottom: 1px solid #e7e7e7;

            h2 {
                width: 672px;
                font-size: 22px;
                font-family: Pretendard_bold;
                color: #000000;
            }

            p {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
            }

            .btns {
                display: flex;
                column-gap: 8px;

                a,
                button {
                    display: block;
                    width: 64px;
                    font-size: 16px;
                    line-height: 32px;
                    color: #ffffff;
                    text-align: center;
                }

                a {
                    background-color: #96c719;
                }

                button {
                    background-color: #e92f2c;
                }
            }
        }

        > .wrap {
            margin-bottom: 30px;
            display: flex;
            align-items: flex-end;
            column-gap: 13px;

            .image {
                width: 283px;

                h2 {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                }

                img {
                    width: 283px;
                    height: 143px;
                    object-fit: contain;
                    border: solid 1px #e7e7e7;
                }
            }

            .info {
                width: 100%;

                .wrap {
                    display: flex;
                    column-gap: 17px;

                    .row {
                        width: 50%;
                    }
                }

                .wrap.half {
                    .row:last-child {
                        padding: 4px 0 0 0;
                        border: none;
                    }
                }

                .row {
                    padding: 13px 0;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;
                    height: 46px;
                    border-bottom: 1px solid #e7e7e7;

                    h2 {
                        width: 105px;
                        font-size: 14px;
                        font-family: Pretendard_bold;
                        line-height: 16px;
                    }

                    p {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.87);
                    }

                    .alert {
                        width: 172px;
                        height: 26px;
                        font-size: 12px;
                        font-family: Pretendard_extraBold;
                        line-height: 26px;
                        color: #ffffff;
                        text-align: center;
                        @include border-radius(4px);
                        background-color: rgba(198, 119, 0, 0.6);
                    }

                    .price {
                        width: 170px;
                        font-size: 22px;
                        font-family: Pretendard_extraBold;
                        color: var(--color-Web-Orange);
                        text-align: right;
                    }

                    .btn_process {
                        width: 285px;
                        height: 34px;
                        font-size: 16px;
                        font-family: Pretendard_extraBold;
                        color: #ffffff;
                        @include border-radius(4px);
                        background-color: var(--color-Web-Orange);
                    }

                    .alert_trade_disable {
                        width: 70%;
                        height: 34px;
                        font-size: 14px;
                        font-family: Pretendard_extraBold;
                        color: #ffffff;
                        @include border-radius(4px);
                    }

                    .alert_trade_cancel {
                        width: 30%;
                        height: 34px;
                        font-size: 14px;
                        font-family: Pretendard_bold;
                        color: #000000;
                        @include border-radius(4px);
                        border: 1px solid #e7e7e7;
                        background-color: #ffffff;
                    }

                    .alert_trade_finish {
                        width: 100%;
                        height: 34px;
                        font-size: 14px;
                        font-family: Pretendard_bold;
                        color: #ffffff;
                        @include border-radius(4px);
                        background-color: var(--color-Web-Orange);
                    }
                }
            }
        }

        > .comment_area {
            margin-bottom: 15px;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-family: Pretendard_extraBold;
            }

            p {
                padding: 12px 15px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
                border: 1px solid #e7e7e7;
                background-color: #ffffff;
            }
        }

        > .classification_area {
            padding: 15px 0;
            border-bottom: 1px solid #e7e7e7;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-family: Pretendard_extraBold;
            }

            p {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
            }
        }

        > .product_area {
            padding: 30px 0;
            border-bottom: 1px solid #e7e7e7;

            h2 {
                margin-bottom: 15px;
                font-size: 18px;
                font-family: Pretendard_extraBold;
            }

            .item {

                .title {
                    margin-bottom: 12px;
                    font-size: 14px;
                    color: var(--color-Web-Orange);
                }

                .value {
                    display: flex;
                    flex-wrap: wrap;
                    column-gap: 10px;
                    row-gap: 10px;
                    font-size: 14px;
                    line-height: 2;
                    white-space: break-spaces;

                    span {
                        padding: 0 10px;
                        border: 1px solid var(--color-Web-Orange);
                        @include border-radius(16px);
                        background-color: white;
                    }
                }
            }

            .item+.item {
                margin-top: 30px;
            }
        }

        .classification_area,
        .product_area {

            > p,
            .list {
                padding: 15px;
                background-color: rgba(216, 216, 216, 0.1);
            }
        }

        > .btn_list {
            margin: auto;
            display: block;
            width: 120px;
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            background-color: var(--color-Web-Orange);
        }

        .disable {
            background-color: #d8d8d8 !important;
            pointer-events: none;
        }
    }

    .mo {
        .header {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            z-index: 2;

            h2 {
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #443018;
            }

            button {
                position: absolute;
                left: 16px;
                background-size: contain;
            }
        }

        .view {

            .item_img {
                width: 100%;
                height: 235px;
                border-bottom: 1px solid #d8d8d8;
                object-fit: contain;
            }

            .info {
                margin: 9px 0 14px 0;
                padding-left: 3%;

                .tags {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    grid-row-gap: 4px;
                    grid-column-gap: 4px;
                    overflow-x: scroll;

                    li {
                        a {
                            padding: 0 7px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 25px;
                            font-size: 12px;
                            font-family: Pretendard_extraBold;
                            color: #ffffff;
                            white-space: pre;
                            border-radius: 12.5px;
                            background-image: linear-gradient(to right, #ff7a00 32%, var(--color-Web-Orange));

                            i {
                                width: 16px;
                                height: 16px;
                                background-size: contain;
                            }
                        }
                    }
                }
            }

            .item_head {
                padding: 0 3% 10px 5%;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                border-bottom: 1px solid #d8d8d8;

                .wrap_item_info {
                    width: 70%;

                    .item_title {
                        margin-bottom: 10px;
                        font-size: 14px;
                        font-family: Pretendard_extraBold;
                        color: #443018;
                    }

                    .item_price {
                        font-size: 20px;
                        font-family: Pretendard_extraBold;
                        color: var(--color-Web-Orange);
                    }
                }

                .wrap {
                    height: 48px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    .posting_at {
                        width: 91px;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.3px;
                        color: #959595;
                    }

                    button {
                        width: 125px;
                        height: 26px;
                        font-size: 12px;
                        font-family: Pretendard_extraBold;
                        color: #ffffff;
                        border-radius: 4px;
                    }

                    .btn_proposal {
                        background-color: var(--color-Web-Orange);
                    }

                    .btn_disable_proposal {
                        background-color: #d8d8d8;
                    }
                }

                .seller_ctl {
                    display: flex;
                    column-gap: 7px;
                    min-width: 91px;

                    a,
                    button {
                        display: block;
                        width: 42px;
                        height: 25px;
                        font-size: 12px;
                        font-family: Pretendard_bold;
                        line-height: 24px;
                        color: #606060;
                        text-align: center;
                        border-radius: 12.5px;
                        border: solid 1px #aaaaaa;
                        background-color: #ffffff;
                    }
                }
            }

            .trade_item_apply,
            .trade_item_comment {
                padding: 15px 3% 15px 5%;

                h2 {
                    margin-bottom: 8px;
                    font-size: 12px;
                    font-family: Pretendard_extraBold;
                    color: #606060;
                }

                p {
                    font-size: 12px;
                    font-family: Pretendard_bold;
                    color: #606060;
                }
            }

            .trade_item_apply {
                border-bottom: 1px solid #d8d8d8;
            }

            .alert_trade_start {
                margin: 10px auto 0 auto;
                display: block;
                width: 161px;
                height: 42px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #ffffff;
                border-radius: 21px;
                background-image: linear-gradient(to right, #ff7a00 32%, var(--color-Web-Orange));
            }

            .alert_trade_finish {
                margin: 25px auto !important;
                width: 90% !important;
            }

            .alert_trade_confirm {
                margin: 12px auto;
                width: 90%;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #ffffff;
                border-radius: 4px;
                background-color: var(--color-Web-Orange);
            }

            .alert_trade_disable {
                margin: auto;
                width: 100%;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #959595;
                border-radius: 4px;
                background-color: #f0f0f0;
            }

            .alert_wait {
                margin-top: 12px;
                padding: 0 5%;

                .inner {
                    width: 70%;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 6px;
                        min-width: 20px;
                        min-height: 20px;
                    }

                    p {
                        font-size: 12px;
                        color: #959595;
                    }
                }

                .alert_trade_cancel {
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    button {
                        width: 95px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        font-family: Pretendard_bold;
                        color: #747474;
                        border-radius: 4px;
                        border: 1px solid #d8d8d8;
                        background-color: #ffffff;
                    }
                }
            }

            .buy_wait {
                display: flex;
                column-gap: 8px;

                .alert_trade_cancel {
                    margin-top: 0;
                }
            }
        }
    }

    .recv_list {
        border-top: 1px solid #d8d8d8;

        .r_head {
            padding: 15px 3% 15px 5%;
            display: flex;
            justify-content: space-between;

            .first {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    width: 100px;
                    font-size: 14px;
                    font-family: Pretendard_extraBold;
                    color: #443018;
                }

                p {
                    font-size: 14px;
                    font-family: Pretendard_extraBold;
                    color: var(--color-Web-Orange);
                }
            }
        }
        .table-box-wrap{
            width: 90%;
            margin: 0 auto;
        }

        table {
            th:nth-child(2),
            td:nth-child(2) {
                width: 15%;
                max-width: 55px;
            }

            th:nth-child(3),
            td:nth-child(3) {
                width: 25%;
            }

            th:nth-child(4),
            td:nth-child(4) {
                width: 30%;
            }

            td {
                font-size: 10px;
                font-family: Pretendard_bold;

                * {
                    font-size: 10px;
                    font-family: Pretendard_bold;
                }
            }

            td:nth-child(4) {

                button {
                    width: 60px;
                    height: 25px;
                    @include border-radius(12.5px);
                    border: solid 1px #d8d8d8;
                    background-color: #ffffff;
                }

                ul {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    li {
                        padding: 0 5px;
                        min-width: 30px;
                        line-height: 18px;
                        border-radius: 12.5px;
                        border: 1px solid #aaaaaa;
                        cursor: pointer;
                    }

                    li.not_styled {
                        border: none;
                    }

                    li + li {
                        margin-left: 4px;
                    }
                }
            }
        }
    }

    .relation {
        margin: 38px 0 10px 0;

        > h2 {
            margin-bottom: 19px;
            padding-left: 5%;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            color: #443018;
        }

        .wrap {
            padding: 0 5%;
            display: flex;
            column-gap: 16px;
            overflow-x: scroll;
        }

        .relative_item {
            width: 152px;

            img {
                margin-bottom: 8px;
                width: 152px;
                height: 100px;
                object-fit: contain;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
            }

            .category {
                margin-bottom: 4px;
                font-size: 12px;
                font-family: Pretendard_extraBold;
                color: var(--color-Web-Orange);
                @include text-ellipsis(1);
            }

            .name {
                margin-bottom: 4px;
                font-size: 14px;
                font-family: Pretendard_bold;
                color: #606060;
            }

            .price {
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #747474;
            }
        }
    }

    @media screen and (min-width: 850px) {
        padding-bottom: 0;

        .relation {
            margin: auto;
            width: 880px;

            > h2 {
                padding: 0;
            }

            .wrap {
                padding: 1px;
                overflow: hidden;
            }

            .relative_item {
                width: 282px;

                img {
                    width: 282px;
                    height: 169px;
                    border: 1px solid #e7e7e7;
                    box-shadow: none;
                }
            }
        }

        .recv_list {
            margin: 30px auto 0 auto;
            width: 880px;
            border: none;

            .r_head {
                margin-bottom: 16px;
                padding: 0;

                .first {

                    h2 {
                        margin-right: 10px;
                        width: 150px;
                        font-size: 22px;
                    }

                    p {
                        font-size: 22px;
                    }
                }
            }
        }

        table {
            td {
                font-size: 12px !important;

                li {
                    font-size: 14px !important;
                }

                .btn_sell_decision {
                    width: 150px !important;
                    height: 30px !important;
                    font-size: 14px !important;
                    line-height: 30px;
                    color: #ffffff;
                    text-align: center;
                    border: none;
                    border-radius: 0 !important;
                    background-color: var(--color-Web-Orange) !important;
                }

                .btn_common,
                .btn_cancel {
                    width: 64px;
                    height: 30px;
                    font-size: 14px !important;
                    line-height: 30px !important;
                    color: #000000;
                    text-align: center;
                    border: 1px solid #e7e7e7;
                    border-radius: 0 !important;
                    background-color: #ffffff;
                }
            }
        }
    }
}
