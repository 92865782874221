@import "src/include";

#PopupInvestorList {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 49px 59px;
    width: 780px;
    height: 580px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 22px;
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_register {
                width: 120px;
                line-height: 32px;
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                @include border-radius(4px);
                background-color: var(--color-Web-Orange);
            }

            button+button {
               margin-left: 20px;
            }
        }
    }

    .search {
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
            margin-right: 15px;
            padding-left: 20px;
            width: 510px;
            height: 32px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.56);
            border: solid 0.5px rgba(0, 0, 0, 0.54);
        }

        .icon_search_black {
            width: 32px;
            height: 32px;
        }
    }

    .wrap_list {
        height: 350px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .checkbox {

        .checkmark {
            margin-left: 0;
        }
    }

    table {
        th:nth-child(1),
        td:nth-child(1) {
            width: 5%;
        }

        th:nth-child(2),
        td:nth-child(2) {
            width: 8%;
        }

        th:nth-child(3),
        td:nth-child(3) {
            width: 15%;
        }

        th:nth-child(4),
        td:nth-child(4) {
            width: 15%;
        }
    }
}
