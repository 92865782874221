#MAnnualCountView {
    position: relative;
    background-color: #ffffff;
    z-index: 3;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        background-color: #ffffff;

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 22px;
            color: #443018;
        }
    }

    .wrap_chart {
        margin: 21px auto;
        width: 90%;

        .legend {
            margin: 8px;
            display: flex;
            justify-content: flex-end;


            .item {
                display: flex;
                align-items: center;

                .circle {
                    margin-right: 4px;
                    width: 8px;
                    height: 8px;
                }

                .yellow {
                    background-color: #ffac12;
                }

                .blue {
                    background-color: #0095ae;
                }

                p {
                    font-size: 12px;
                    font-family: Pretendard_bold;
                }
            }

            .item+.item {
                margin-left: 8px;
            }
        }

        .alert {
            margin-top: 8px;
            display: flex;
            align-items: center;

            i {
                margin-right: 6px;
            }

            p {
                font-size: 12px;
                color: #747474;
            }
        }
    }

    .wrap_table {
        padding-top: 9.5px;
        border-top: 1px solid #d8d8d8;

        .l_head {
            margin-bottom: 18px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;

            h2 {
                font-size: 18px;
                font-family: Pretendard_extraBold;
                line-height: 24px;
                color: #443018;
            }

            select {
                padding: 0 10px;
                width: 65px;
                height: 26px;
                font-size: 12px;
                font-family: Pretendard_bold;
                color: #747474;
                border-radius: 4px;
                border: solid 1px #d8d8d8;
            }
        }


        .table_title {
            margin-bottom: 15px;
            padding-left: 20px;
            font-family: Pretendard_bold;
        }

        .table-box-wrap {
            width: 90%;
            margin: 0 auto 18px;
            .wrap_list {
                max-height: 160px;
            }
        }

        table {
            thead {
                tr {
                    th {
                        font-size: 12px;
                        font-family: Pretendard_bold;
                        color: #606060;
                    }

                    th:first-child {
                        width: 15%;
                    }
                    th:nth-child(2) {
                        width: 15%;
                    }
                }
            }

            tbody {
                tr {
                    td:first-child {
                        width: 15%;
                    }

                    td:nth-child(2) {
                        width: 15%;
                    }

                    td:nth-child(3) {
                        font-family: Pretendard_bold;
                        color: #606060;
                        text-decoration: underline;
                    }

                    td:nth-child(4) {
                        color: #747474;
                    }
                }

                tr {
                    td {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
