@import "../../../include";

#CyberPointChange {
    @import "Common.scss";

    .page_title {
        margin: auto;
        width: 302px;
        font-size: 24px;
        font-family: Pretendard_extraBold;
        line-height: 32px;
    }

    .desc {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 333px;
        height: 52px;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        border-bottom: 2px solid #00a545;
    }

    .sum_point {
        margin: 18px auto 30px auto;
        width: 302px;
        font-size: 32px;
        font-family: Pretendard_bold;
        line-height: 24px;
        color: #00a545;
        text-align: right;
    }

    .btn_change {
        margin: 0 auto 12px auto;
    }
}
