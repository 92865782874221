#IntroTaskCategory {
    margin: 20px 0 30px 0;

    .task_category_area {
      margin: auto;
      padding: 10px;
      display: flex;
      align-content: center;
      column-gap: 10px;
      width: 1145px;
      border-radius: 8px;
      box-shadow: 0 2px 3px 1px var(--color-Alto);
      background-color: #ffffff;

      .task_category_box_title {
        padding: 10px 15px;
        min-width: 202px;
        border-radius: 5px;
        background-color: var(--color-Jade);

        &.child {
          height: 150px;
          background-image: linear-gradient(to right, #53b2ff, #4995f2);

          i.icon_recommend_category {
            display: none;
          }

          .btn_category {
            margin-top: 50px;
          }
        }

        p {
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 15px;
          color: var(--color-Yellow);
        }

        h2 {
          font-size: 18px;
          font-family: Pretendard_extraBold;
          line-height: 24px;
          color: #ffffff;
        }

        .icon_recommend_category {
          margin: 25px auto 20px auto;
          display: block;
        }

        .btn_category {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 135px;
          height: 30px;
          border-radius: 15px;
          background-color: var(--color-White-opacity-02);

          span {
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #ffffff;
            text-align: center;
          }
        }
      }

      .task_category_box_content_list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
      }

      .task_category_box_content_item {
        position: relative;
        padding: 0 10px 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        width: 220px;
        height: 55px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 var(--color-Gallery);
        background-color: var(--color-Malibu-opacity-02);
        cursor: pointer;

        &:hover {
          background-image: linear-gradient(to right, var(--color-Malibu), var(--color-Cornflower-Blue));
          p {
            color: var(--color-Yellow);
          }

          .item_info {
            h2 {
              color: #ffffff;
            }

            .icon_add {
              background-image: url("../../../../assets/images/common/icon_plus_white.svg");
            }

            .icon_more_arrow_right_black {
              background-image: url("../../../../assets/images/mobile/icon_more_arrow_right_white.svg");
            }
          }
        }

        p {
          position: absolute;
          top: 10px;
          font-size: 12px;
          line-height: 15px;
          color: var(--color-Cornflower-Blue);
        }

        .item_info {
          display: flex;
          justify-content: space-between;
          align-content: center;

          h2 {
            font-size: 14px;
            font-family: Pretendard_bold;
            line-height: 24px;
            color: var(--color-Mine_Shaft);
          }
        }
      }
    }

    .category_recommend_area {
        margin: 0 10px;
        position: relative;
        //display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 2vw;
        width: calc(100% - 20px);
        height: 25vw;
        border-radius: 5px;
        background-image: linear-gradient(to bottom, var(--color-Spray), var(--color-Piction-Blue));

        .bg_image {
            position: absolute;
            right: 0;
            bottom: -60%;
            transform: translateY(-50%);
            width: 23vw;
        }

        h2 {
            font-size: 5.5vw;
            font-family: Pretendard_extraBold;
            color: #ffffff;
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 135px;
            height: 26px;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #ffffff;
            border-radius: 15px;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
