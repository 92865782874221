#AdminTaskService {
  margin: auto;
  padding-top: 50px;
  width: 880px;

  .page_title {
    margin-bottom: 40px;
    height: 40px;
    font-size: 24px;
    font-family: Pretendard_bold;
    color: #443018;
    border-bottom: 1px solid #ddd;
  }

  .articles {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .field {
    padding: 16px 0;
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #ddd;

    span {
      margin-right: 14px;
      padding-right: 14px;
      display: flex;
      align-items: center;
      width: 70px;
      font-family: Pretendard_bold;
      border-right: 1px solid #ddd;
    }

    textarea,
    input,
    select {
      font-size: 14px;
    }

    textarea,
    input[type="text"] {
      padding: 10px;
      width: 100%;
      border: 1px solid #ddd;
    }

    select {
      width: 135px;
      height: 38px;
    }

    p {
      display: flex;
      align-items: center;
      column-gap: 8px;
      width: 800px;
      white-space: pre-wrap;
    }
  }

  .content {
    padding: 25px 0;
  }

  .btn_upload {
    margin: 30px auto 0 auto;
    display: block;
    width: 150px;
    height: 40px;
    font-size: 18px;
    font-family: Pretendard_bold;
    color: #ffffff;
    background-color: var(--color-Web-Orange);
  }
}
