@import "src/include";

#SignupType {

    #findBizChange {
        opacity: 0;
    }

    .pc {
        .notify {
            margin-top: 100px;
            margin-bottom: 50px;
            text-align: center;

            h2 {
                margin-bottom: 15px;
                font-size: 38px;
                font-family: Pretendard_extraBold;
            }
            p {
                font-size: 22px;
                font-family: Pretendard_bold;
            }
        }

        .select_type {
            display: flex;
            justify-content: center;
            column-gap: 50px;

            .tab {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .alert {
                    margin: 6px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 5px;
                    height: 20px;
                    font-size: 12px;
                    font-family: Pretendard_bold;
                    color: #747474;
                }
            }

            h2 {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                font-family: Pretendard_bold;
                line-height: 25px;
                color: #343434;
            }

            img {
                margin: 38px auto;
                display: block;
            }

            p {
                font-size: 14px;
                font-family: Pretendard_extraBold;
                line-height: 20px;
                color: #606060;
                text-align: center;
            }

            a {
                margin: auto;
                display: block;
                width: 93px;
                height: 45px;
                font-size: 16px;
                font-family: Pretendard_extraBold;
                line-height: 45px;
                color: #ffffff;
                text-align: center;
                @include border-radius(4px);
                background-color: var(--color-Web-Orange);
            }
        }
    }

    .mobile {
        @media screen and (max-width: 850px){
            padding: 92px 0 51px 0;
            margin: auto;
            width: 90%;
        }

        .page_title {
            margin-bottom: 32px;
            font-size: 24px;
            font-family: Pretendard_extraBold;
            line-height: 30px;
            color: #443018;
            text-align: center;
        }

        .tab {
            max-height: 232px;
            border-radius: 4px;
            border: 2px solid #d8d8d8;

            .tab_title {
                padding: 0 5%;
                display: flex;
                align-items: center;
                height: 72px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

                .title {
                    display: flex;
                    align-items: center;
                    width: 95%;
                    font-size: 16px;
                    font-family: Pretendard_extraBold;
                    line-height: 22px;
                    color: #443018;

                    i {
                        margin: 0 12px;
                    }
                }
            }

            .tab_content {
                padding: 0 20px;
                height: 0;
                overflow: hidden;
                font-size: 14px;
                color: #747474;
                background-color: #f8f8f8;
                transition-duration: 0.5s;
            }

            .tab_content.active {
                padding: 18px 20px;
                height: 156px;
            }
        }

        .tab+.tab {
            margin-top: 20px;
        }

        .tab.active {
            border: 2px solid var(--color-Web-Orange);
        }

        .icon_company {
            background: url("../../../assets/images/mobile/icon_company.svg") no-repeat;
        }

        .icon_agent {
            background: url("../../../assets/images/mobile/icon_agent.svg") no-repeat;
        }

        .icon_investor {
            background: url("../../../assets/images/mobile/icon_investor.svg") no-repeat;
        }

        .alert {
            margin: 17px 0 34px 0;
            display: flex;
            align-items: center;

            i {
                margin-right: 8px;
            }

            p {
                font-size: 12px;
                font-family: Pretendard_bold;
                line-height: 18px;
                color: #959595;
            }
        }
    }

    @import "./common.scss";
}
