/*!
*   _____       _             __                 _____                               ______ _       _   _
*  |_   _|     | |           / _|               |_   _|                             |  ____| |     | | (_)
*    | |  _ __ | |_ ___ _ __| |_ __ _  ___ ___    | |  ___ ___  _ __  ___   ______  | |__  | | __ _| |_ _  ___ ___  _ __
*    | | | '_ \| __/ _ \ '__|  _/ _` |/ __/ _ \   | | / __/ _ \| '_ \/ __| |______| |  __| | |/ _` | __| |/ __/ _ \| '_ \
*   _| |_| | | | ||  __/ |  | || (_| | (_|  __/  _| || (_| (_) | | | \__ \          | |    | | (_| | |_| | (_| (_) | | | |
*  |_____|_| |_|\__\___|_|  |_| \__,_|\___\___| |_____\___\___/|_| |_|___/          |_|    |_|\__,_|\__|_|\___\___/|_| |_|
*
*                         UIcons 2.5.1 - https://www.flaticon.com/uicons/interface-icons
*/
@font-face {
    font-family: "uicons-regular-straight";
    src: url("../webfonts/uicons-regular-straight.woff2") format("woff2"),
    url("../webfonts/uicons-regular-straight.woff") format("woff"),
    url("../webfonts/uicons-regular-straight.eot#iefix") format("embedded-opentype");
    font-display: swap;
}

    i[class^="fi-rs-"]:before, i[class*=" fi-rs-"]:before, span[class^="fi-rs-"]:before, span[class*="fi-rs-"]:before {
font-family: uicons-regular-straight !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .variable-selector-00:before {
    content: "\fe00";
    }
        .variable-selector-01:before {
    content: "\fe01";
    }
        .variable-selector-02:before {
    content: "\fe02";
    }
        .variable-selector-03:before {
    content: "\fe03";
    }
        .variable-selector-04:before {
    content: "\fe04";
    }
        .variable-selector-05:before {
    content: "\fe05";
    }
        .variable-selector-06:before {
    content: "\fe06";
    }
        .variable-selector-07:before {
    content: "\fe07";
    }
        .variable-selector-08:before {
    content: "\fe08";
    }
        .variable-selector-09:before {
    content: "\fe09";
    }
        .variable-selector-0a:before {
    content: "\fe0a";
    }
        .variable-selector-0b:before {
    content: "\fe0b";
    }
        .variable-selector-0c:before {
    content: "\fe0c";
    }
        .variable-selector-0d:before {
    content: "\fe0d";
    }
        .variable-selector-0e:before {
    content: "\fe0e";
    }
        .variable-selector-0f:before {
    content: "\fe0f";
    }
        .combining-half-marks-00:before {
    content: "\fe20";
    }
        .combining-half-marks-01:before {
    content: "\fe21";
    }
        .combining-half-marks-02:before {
    content: "\fe22";
    }
        .combining-half-marks-03:before {
    content: "\fe23";
    }
        .combining-half-marks-04:before {
    content: "\fe24";
    }
        .combining-half-marks-05:before {
    content: "\fe25";
    }
        .combining-half-marks-06:before {
    content: "\fe26";
    }
        .combining-half-marks-07:before {
    content: "\fe27";
    }
        .combining-half-marks-08:before {
    content: "\fe28";
    }
        .combining-half-marks-09:before {
    content: "\fe29";
    }
        .combining-half-marks-0a:before {
    content: "\fe2a";
    }
        .combining-half-marks-0b:before {
    content: "\fe2b";
    }
        .combining-half-marks-0c:before {
    content: "\fe2c";
    }
        .combining-half-marks-0d:before {
    content: "\fe2d";
    }
        .combining-half-marks-0e:before {
    content: "\fe2e";
    }
        .combining-half-marks-0f:before {
    content: "\fe2f";
    }
        .zero-width-no-break-space-0f:before {
    content: "\feff";
    }
        .fi-rs-0:before {
    content: "\f101";
    }
        .fi-rs-00s-music-disc:before {
    content: "\f102";
    }
        .fi-rs-1:before {
    content: "\f103";
    }
        .fi-rs-2:before {
    content: "\f104";
    }
        .fi-rs-3:before {
    content: "\f105";
    }
        .fi-rs-360-degrees:before {
    content: "\f106";
    }
        .fi-rs-4:before {
    content: "\f107";
    }
        .fi-rs-404:before {
    content: "\f108";
    }
        .fi-rs-5:before {
    content: "\f109";
    }
        .fi-rs-6:before {
    content: "\f10a";
    }
        .fi-rs-60s-music-disc:before {
    content: "\f10b";
    }
        .fi-rs-7:before {
    content: "\f10c";
    }
        .fi-rs-70s-music-disc:before {
    content: "\f10d";
    }
        .fi-rs-8:before {
    content: "\f10e";
    }
        .fi-rs-80s-music-disc:before {
    content: "\f10f";
    }
        .fi-rs-9:before {
    content: "\f110";
    }
        .fi-rs-90s-music-disc:before {
    content: "\f111";
    }
        .fi-rs-a:before {
    content: "\f112";
    }
        .fi-rs-accident:before {
    content: "\f113";
    }
        .fi-rs-acorn:before {
    content: "\f114";
    }
        .fi-rs-ad-paid:before {
    content: "\f115";
    }
        .fi-rs-ad:before {
    content: "\f116";
    }
        .fi-rs-add-document:before {
    content: "\f117";
    }
        .fi-rs-add-folder:before {
    content: "\f118";
    }
        .fi-rs-add-image:before {
    content: "\f119";
    }
        .fi-rs-add:before {
    content: "\f11a";
    }
        .fi-rs-address-book:before {
    content: "\f11b";
    }
        .fi-rs-address-card:before {
    content: "\f11c";
    }
        .fi-rs-admin-alt:before {
    content: "\f11d";
    }
        .fi-rs-admin:before {
    content: "\f11e";
    }
        .fi-rs-age-alt:before {
    content: "\f11f";
    }
        .fi-rs-age-restriction-eighteen:before {
    content: "\f120";
    }
        .fi-rs-age-restriction-seven:before {
    content: "\f121";
    }
        .fi-rs-age-restriction-six:before {
    content: "\f122";
    }
        .fi-rs-age-restriction-sixteen:before {
    content: "\f123";
    }
        .fi-rs-age-restriction-thirteen:before {
    content: "\f124";
    }
        .fi-rs-age-restriction-three:before {
    content: "\f125";
    }
        .fi-rs-age-restriction-twelve:before {
    content: "\f126";
    }
        .fi-rs-age-restriction-twenty-one:before {
    content: "\f127";
    }
        .fi-rs-age-restriction-zero:before {
    content: "\f128";
    }
        .fi-rs-age:before {
    content: "\f129";
    }
        .fi-rs-air-conditioner:before {
    content: "\f12a";
    }
        .fi-rs-air-freshener:before {
    content: "\f12b";
    }
        .fi-rs-air-pollution:before {
    content: "\f12c";
    }
        .fi-rs-airplane-journey:before {
    content: "\f12d";
    }
        .fi-rs-airplane-window-open:before {
    content: "\f12e";
    }
        .fi-rs-airplay:before {
    content: "\f12f";
    }
        .fi-rs-alarm-clock:before {
    content: "\f130";
    }
        .fi-rs-alarm-exclamation:before {
    content: "\f131";
    }
        .fi-rs-alarm-plus:before {
    content: "\f132";
    }
        .fi-rs-alarm-snooze:before {
    content: "\f133";
    }
        .fi-rs-album-circle-plus:before {
    content: "\f134";
    }
        .fi-rs-album-circle-user:before {
    content: "\f135";
    }
        .fi-rs-album-collection:before {
    content: "\f136";
    }
        .fi-rs-album:before {
    content: "\f137";
    }
        .fi-rs-algorithm:before {
    content: "\f138";
    }
        .fi-rs-alicorn:before {
    content: "\f139";
    }
        .fi-rs-alien:before {
    content: "\f13a";
    }
        .fi-rs-align-center:before {
    content: "\f13b";
    }
        .fi-rs-align-justify:before {
    content: "\f13c";
    }
        .fi-rs-align-left:before {
    content: "\f13d";
    }
        .fi-rs-align-slash:before {
    content: "\f13e";
    }
        .fi-rs-ambulance:before {
    content: "\f13f";
    }
        .fi-rs-analyse-alt:before {
    content: "\f140";
    }
        .fi-rs-analyse:before {
    content: "\f141";
    }
        .fi-rs-anatomical-heart:before {
    content: "\f142";
    }
        .fi-rs-anchor:before {
    content: "\f143";
    }
        .fi-rs-angel:before {
    content: "\f144";
    }
        .fi-rs-angle-90:before {
    content: "\f145";
    }
        .fi-rs-angle-circle-down:before {
    content: "\f146";
    }
        .fi-rs-angle-circle-left:before {
    content: "\f147";
    }
        .fi-rs-angle-circle-right:before {
    content: "\f148";
    }
        .fi-rs-angle-circle-up:before {
    content: "\f149";
    }
        .fi-rs-angle-double-left:before {
    content: "\f14a";
    }
        .fi-rs-angle-double-right:before {
    content: "\f14b";
    }
        .fi-rs-angle-double-small-down:before {
    content: "\f14c";
    }
        .fi-rs-angle-double-small-left:before {
    content: "\f14d";
    }
        .fi-rs-angle-double-small-right:before {
    content: "\f14e";
    }
        .fi-rs-angle-double-small-up:before {
    content: "\f14f";
    }
        .fi-rs-angle-down:before {
    content: "\f150";
    }
        .fi-rs-angle-left:before {
    content: "\f151";
    }
        .fi-rs-angle-right:before {
    content: "\f152";
    }
        .fi-rs-angle-small-down:before {
    content: "\f153";
    }
        .fi-rs-angle-small-left:before {
    content: "\f154";
    }
        .fi-rs-angle-small-right:before {
    content: "\f155";
    }
        .fi-rs-angle-small-up:before {
    content: "\f156";
    }
        .fi-rs-angle-square-down:before {
    content: "\f157";
    }
        .fi-rs-angle-square-left:before {
    content: "\f158";
    }
        .fi-rs-angle-square-right:before {
    content: "\f159";
    }
        .fi-rs-angle-square-up:before {
    content: "\f15a";
    }
        .fi-rs-angle-up:before {
    content: "\f15b";
    }
        .fi-rs-angle:before {
    content: "\f15c";
    }
        .fi-rs-angles-up-down:before {
    content: "\f15d";
    }
        .fi-rs-angry:before {
    content: "\f15e";
    }
        .fi-rs-animated-icon:before {
    content: "\f15f";
    }
        .fi-rs-ankh:before {
    content: "\f160";
    }
        .fi-rs-answer-alt:before {
    content: "\f161";
    }
        .fi-rs-answer:before {
    content: "\f162";
    }
        .fi-rs-apartment:before {
    content: "\f163";
    }
        .fi-rs-aperture:before {
    content: "\f164";
    }
        .fi-rs-api:before {
    content: "\f165";
    }
        .fi-rs-apple-books:before {
    content: "\f166";
    }
        .fi-rs-apple-core:before {
    content: "\f167";
    }
        .fi-rs-apple-crate:before {
    content: "\f168";
    }
        .fi-rs-apple-whole:before {
    content: "\f169";
    }
        .fi-rs-apps-add:before {
    content: "\f16a";
    }
        .fi-rs-apps-delete:before {
    content: "\f16b";
    }
        .fi-rs-apps-sort:before {
    content: "\f16c";
    }
        .fi-rs-apps:before {
    content: "\f16d";
    }
        .fi-rs-archive:before {
    content: "\f16e";
    }
        .fi-rs-archway:before {
    content: "\f16f";
    }
        .fi-rs-arrow-alt-circle-down:before {
    content: "\f170";
    }
        .fi-rs-arrow-alt-circle-left:before {
    content: "\f171";
    }
        .fi-rs-arrow-alt-circle-right:before {
    content: "\f172";
    }
        .fi-rs-arrow-alt-circle-up:before {
    content: "\f173";
    }
        .fi-rs-arrow-alt-down:before {
    content: "\f174";
    }
        .fi-rs-arrow-alt-from-bottom:before {
    content: "\f175";
    }
        .fi-rs-arrow-alt-from-left:before {
    content: "\f176";
    }
        .fi-rs-arrow-alt-from-right:before {
    content: "\f177";
    }
        .fi-rs-arrow-alt-from-top:before {
    content: "\f178";
    }
        .fi-rs-arrow-alt-left:before {
    content: "\f179";
    }
        .fi-rs-arrow-alt-right:before {
    content: "\f17a";
    }
        .fi-rs-arrow-alt-square-down:before {
    content: "\f17b";
    }
        .fi-rs-arrow-alt-square-left:before {
    content: "\f17c";
    }
        .fi-rs-arrow-alt-square-right:before {
    content: "\f17d";
    }
        .fi-rs-arrow-alt-square-up:before {
    content: "\f17e";
    }
        .fi-rs-arrow-alt-to-bottom:before {
    content: "\f17f";
    }
        .fi-rs-arrow-alt-to-left:before {
    content: "\f180";
    }
        .fi-rs-arrow-alt-to-right:before {
    content: "\f181";
    }
        .fi-rs-arrow-alt-to-top:before {
    content: "\f182";
    }
        .fi-rs-arrow-alt-up:before {
    content: "\f183";
    }
        .fi-rs-arrow-circle-down:before {
    content: "\f184";
    }
        .fi-rs-arrow-circle-left:before {
    content: "\f185";
    }
        .fi-rs-arrow-circle-right:before {
    content: "\f186";
    }
        .fi-rs-arrow-circle-up:before {
    content: "\f187";
    }
        .fi-rs-arrow-comparison:before {
    content: "\f188";
    }
        .fi-rs-arrow-down-from-arc:before {
    content: "\f189";
    }
        .fi-rs-arrow-down-from-dotted-line:before {
    content: "\f18a";
    }
        .fi-rs-arrow-down-left:before {
    content: "\f18b";
    }
        .fi-rs-arrow-down-small-big:before {
    content: "\f18c";
    }
        .fi-rs-arrow-down-to-dotted-line:before {
    content: "\f18d";
    }
        .fi-rs-arrow-down-to-square:before {
    content: "\f18e";
    }
        .fi-rs-arrow-down-triangle-square:before {
    content: "\f18f";
    }
        .fi-rs-arrow-down:before {
    content: "\f190";
    }
        .fi-rs-arrow-from-bottom:before {
    content: "\f191";
    }
        .fi-rs-arrow-from-left:before {
    content: "\f192";
    }
        .fi-rs-arrow-from-right:before {
    content: "\f193";
    }
        .fi-rs-arrow-from-top:before {
    content: "\f194";
    }
        .fi-rs-arrow-left-from-arc:before {
    content: "\f195";
    }
        .fi-rs-arrow-left-from-line:before {
    content: "\f196";
    }
        .fi-rs-arrow-left-to-arc:before {
    content: "\f197";
    }
        .fi-rs-arrow-left:before {
    content: "\f198";
    }
        .fi-rs-arrow-progress-alt:before {
    content: "\f199";
    }
        .fi-rs-arrow-progress:before {
    content: "\f19a";
    }
        .fi-rs-arrow-right-to-bracket:before {
    content: "\f19b";
    }
        .fi-rs-arrow-right:before {
    content: "\f19c";
    }
        .fi-rs-arrow-small-down:before {
    content: "\f19d";
    }
        .fi-rs-arrow-small-left:before {
    content: "\f19e";
    }
        .fi-rs-arrow-small-right:before {
    content: "\f19f";
    }
        .fi-rs-arrow-small-up:before {
    content: "\f1a0";
    }
        .fi-rs-arrow-square-down:before {
    content: "\f1a1";
    }
        .fi-rs-arrow-square-left:before {
    content: "\f1a2";
    }
        .fi-rs-arrow-square-right:before {
    content: "\f1a3";
    }
        .fi-rs-arrow-square-up:before {
    content: "\f1a4";
    }
        .fi-rs-arrow-to-bottom:before {
    content: "\f1a5";
    }
        .fi-rs-arrow-to-left:before {
    content: "\f1a6";
    }
        .fi-rs-arrow-to-right:before {
    content: "\f1a7";
    }
        .fi-rs-arrow-to-top:before {
    content: "\f1a8";
    }
        .fi-rs-arrow-trend-down:before {
    content: "\f1a9";
    }
        .fi-rs-arrow-trend-up:before {
    content: "\f1aa";
    }
        .fi-rs-arrow-turn-down-left:before {
    content: "\f1ab";
    }
        .fi-rs-arrow-turn-down-right:before {
    content: "\f1ac";
    }
        .fi-rs-arrow-turn-left-up:before {
    content: "\f1ad";
    }
        .fi-rs-arrow-turn-left:before {
    content: "\f1ae";
    }
        .fi-rs-arrow-up-from-dotted-line:before {
    content: "\f1af";
    }
        .fi-rs-arrow-up-from-ground-water:before {
    content: "\f1b0";
    }
        .fi-rs-arrow-up-from-square:before {
    content: "\f1b1";
    }
        .fi-rs-arrow-up-left-from-circle:before {
    content: "\f1b2";
    }
        .fi-rs-arrow-up-left:before {
    content: "\f1b3";
    }
        .fi-rs-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f1b4";
    }
        .fi-rs-arrow-up-right-from-square:before {
    content: "\f1b5";
    }
        .fi-rs-arrow-up-right:before {
    content: "\f1b6";
    }
        .fi-rs-arrow-up-small-big:before {
    content: "\f1b7";
    }
        .fi-rs-arrow-up-square-triangle:before {
    content: "\f1b8";
    }
        .fi-rs-arrow-up-to-arc:before {
    content: "\f1b9";
    }
        .fi-rs-arrow-up-to-dotted-line:before {
    content: "\f1ba";
    }
        .fi-rs-arrow-up:before {
    content: "\f1bb";
    }
        .fi-rs-arrow-upward-growth-crypto:before {
    content: "\f1bc";
    }
        .fi-rs-arrows-alt-h:before {
    content: "\f1bd";
    }
        .fi-rs-arrows-alt-v:before {
    content: "\f1be";
    }
        .fi-rs-arrows-alt:before {
    content: "\f1bf";
    }
        .fi-rs-arrows-cross:before {
    content: "\f1c0";
    }
        .fi-rs-arrows-down-to-people:before {
    content: "\f1c1";
    }
        .fi-rs-arrows-from-dotted-line:before {
    content: "\f1c2";
    }
        .fi-rs-arrows-from-line:before {
    content: "\f1c3";
    }
        .fi-rs-arrows-h-copy:before {
    content: "\f1c4";
    }
        .fi-rs-arrows-h:before {
    content: "\f1c5";
    }
        .fi-rs-arrows-repeat-1:before {
    content: "\f1c6";
    }
        .fi-rs-arrows-repeat:before {
    content: "\f1c7";
    }
        .fi-rs-arrows-retweet:before {
    content: "\f1c8";
    }
        .fi-rs-arrows-split-right-and-left:before {
    content: "\f1c9";
    }
        .fi-rs-arrows-split-up-and-left:before {
    content: "\f1ca";
    }
        .fi-rs-arrows-to-circle:before {
    content: "\f1cb";
    }
        .fi-rs-arrows-to-dotted-line:before {
    content: "\f1cc";
    }
        .fi-rs-arrows-to-eye:before {
    content: "\f1cd";
    }
        .fi-rs-arrows-to-line:before {
    content: "\f1ce";
    }
        .fi-rs-arrows:before {
    content: "\f1cf";
    }
        .fi-rs-artificial-intelligence:before {
    content: "\f1d0";
    }
        .fi-rs-assept-document:before {
    content: "\f1d1";
    }
        .fi-rs-assessment-alt:before {
    content: "\f1d2";
    }
        .fi-rs-assessment:before {
    content: "\f1d3";
    }
        .fi-rs-assign:before {
    content: "\f1d4";
    }
        .fi-rs-assistive-listening-systems:before {
    content: "\f1d5";
    }
        .fi-rs-asterik:before {
    content: "\f1d6";
    }
        .fi-rs-at:before {
    content: "\f1d7";
    }
        .fi-rs-attention-detail:before {
    content: "\f1d8";
    }
        .fi-rs-attribution-pen:before {
    content: "\f1d9";
    }
        .fi-rs-attribution-pencil:before {
    content: "\f1da";
    }
        .fi-rs-aubergine:before {
    content: "\f1db";
    }
        .fi-rs-auction:before {
    content: "\f1dc";
    }
        .fi-rs-audience-megaphone:before {
    content: "\f1dd";
    }
        .fi-rs-audio-description-slash:before {
    content: "\f1de";
    }
        .fi-rs-audit-alt:before {
    content: "\f1df";
    }
        .fi-rs-audit:before {
    content: "\f1e0";
    }
        .fi-rs-austral-sign:before {
    content: "\f1e1";
    }
        .fi-rs-avocado:before {
    content: "\f1e2";
    }
        .fi-rs-award:before {
    content: "\f1e3";
    }
        .fi-rs-axe-battle:before {
    content: "\f1e4";
    }
        .fi-rs-axe:before {
    content: "\f1e5";
    }
        .fi-rs-b:before {
    content: "\f1e6";
    }
        .fi-rs-baby-carriage:before {
    content: "\f1e7";
    }
        .fi-rs-baby:before {
    content: "\f1e8";
    }
        .fi-rs-back-up:before {
    content: "\f1e9";
    }
        .fi-rs-background:before {
    content: "\f1ea";
    }
        .fi-rs-backpack:before {
    content: "\f1eb";
    }
        .fi-rs-bacon:before {
    content: "\f1ec";
    }
        .fi-rs-bacteria:before {
    content: "\f1ed";
    }
        .fi-rs-bacterium:before {
    content: "\f1ee";
    }
        .fi-rs-badge-check:before {
    content: "\f1ef";
    }
        .fi-rs-badge-dollar:before {
    content: "\f1f0";
    }
        .fi-rs-badge-leaf:before {
    content: "\f1f1";
    }
        .fi-rs-badge-percent:before {
    content: "\f1f2";
    }
        .fi-rs-badge-sheriff:before {
    content: "\f1f3";
    }
        .fi-rs-badge:before {
    content: "\f1f4";
    }
        .fi-rs-badger-honey:before {
    content: "\f1f5";
    }
        .fi-rs-badget-check-alt:before {
    content: "\f1f6";
    }
        .fi-rs-badminton:before {
    content: "\f1f7";
    }
        .fi-rs-bag-map-pin:before {
    content: "\f1f8";
    }
        .fi-rs-bag-seedling:before {
    content: "\f1f9";
    }
        .fi-rs-bag-shopping-minus:before {
    content: "\f1fa";
    }
        .fi-rs-bags-shopping:before {
    content: "\f1fb";
    }
        .fi-rs-baguette:before {
    content: "\f1fc";
    }
        .fi-rs-bahai:before {
    content: "\f1fd";
    }
        .fi-rs-baht-sign:before {
    content: "\f1fe";
    }
        .fi-rs-balance-scale-left:before {
    content: "\f1ff";
    }
        .fi-rs-balance-scale-right:before {
    content: "\f200";
    }
        .fi-rs-balcony:before {
    content: "\f201";
    }
        .fi-rs-ball-pile:before {
    content: "\f202";
    }
        .fi-rs-ballet-dance:before {
    content: "\f203";
    }
        .fi-rs-balloon:before {
    content: "\f204";
    }
        .fi-rs-balloons:before {
    content: "\f205";
    }
        .fi-rs-ballot-check:before {
    content: "\f206";
    }
        .fi-rs-ballot:before {
    content: "\f207";
    }
        .fi-rs-ban-bug:before {
    content: "\f208";
    }
        .fi-rs-ban:before {
    content: "\f209";
    }
        .fi-rs-banana:before {
    content: "\f20a";
    }
        .fi-rs-band-aid:before {
    content: "\f20b";
    }
        .fi-rs-bandage-wound:before {
    content: "\f20c";
    }
        .fi-rs-bangladeshi-taka-sign:before {
    content: "\f20d";
    }
        .fi-rs-banjo:before {
    content: "\f20e";
    }
        .fi-rs-bank-app:before {
    content: "\f20f";
    }
        .fi-rs-bank:before {
    content: "\f210";
    }
        .fi-rs-banner-2:before {
    content: "\f211";
    }
        .fi-rs-banner-3:before {
    content: "\f212";
    }
        .fi-rs-banner-4:before {
    content: "\f213";
    }
        .fi-rs-banner-5:before {
    content: "\f214";
    }
        .fi-rs-banner:before {
    content: "\f215";
    }
        .fi-rs-barber-pole:before {
    content: "\f216";
    }
        .fi-rs-barber-shop:before {
    content: "\f217";
    }
        .fi-rs-barcode-read:before {
    content: "\f218";
    }
        .fi-rs-barcode-scan:before {
    content: "\f219";
    }
        .fi-rs-barcode:before {
    content: "\f21a";
    }
        .fi-rs-barefoot:before {
    content: "\f21b";
    }
        .fi-rs-bars-filter:before {
    content: "\f21c";
    }
        .fi-rs-bars-progress:before {
    content: "\f21d";
    }
        .fi-rs-bars-sort:before {
    content: "\f21e";
    }
        .fi-rs-bars-staggered:before {
    content: "\f21f";
    }
        .fi-rs-baseball-alt:before {
    content: "\f220";
    }
        .fi-rs-baseball:before {
    content: "\f221";
    }
        .fi-rs-basket-shopping-minus:before {
    content: "\f222";
    }
        .fi-rs-basket-shopping-plus:before {
    content: "\f223";
    }
        .fi-rs-basket-shopping-simple:before {
    content: "\f224";
    }
        .fi-rs-basketball-hoop:before {
    content: "\f225";
    }
        .fi-rs-basketball:before {
    content: "\f226";
    }
        .fi-rs-bat:before {
    content: "\f227";
    }
        .fi-rs-bath:before {
    content: "\f228";
    }
        .fi-rs-battery-bolt:before {
    content: "\f229";
    }
        .fi-rs-battery-empty:before {
    content: "\f22a";
    }
        .fi-rs-battery-exclamation:before {
    content: "\f22b";
    }
        .fi-rs-battery-full:before {
    content: "\f22c";
    }
        .fi-rs-battery-half:before {
    content: "\f22d";
    }
        .fi-rs-battery-quarter:before {
    content: "\f22e";
    }
        .fi-rs-battery-slash:before {
    content: "\f22f";
    }
        .fi-rs-battery-three-quarters:before {
    content: "\f230";
    }
        .fi-rs-beacon:before {
    content: "\f231";
    }
        .fi-rs-beauty-mask:before {
    content: "\f232";
    }
        .fi-rs-bed-alt:before {
    content: "\f233";
    }
        .fi-rs-bed-bunk:before {
    content: "\f234";
    }
        .fi-rs-bed-empty:before {
    content: "\f235";
    }
        .fi-rs-bed:before {
    content: "\f236";
    }
        .fi-rs-bee:before {
    content: "\f237";
    }
        .fi-rs-beer-mug-empty:before {
    content: "\f238";
    }
        .fi-rs-beer:before {
    content: "\f239";
    }
        .fi-rs-bell-concierge:before {
    content: "\f23a";
    }
        .fi-rs-bell-notification-call:before {
    content: "\f23b";
    }
        .fi-rs-bell-notification-social-media:before {
    content: "\f23c";
    }
        .fi-rs-bell-ring:before {
    content: "\f23d";
    }
        .fi-rs-bell-school-slash:before {
    content: "\f23e";
    }
        .fi-rs-bell-school:before {
    content: "\f23f";
    }
        .fi-rs-bell-slash:before {
    content: "\f240";
    }
        .fi-rs-bell:before {
    content: "\f241";
    }
        .fi-rs-bells:before {
    content: "\f242";
    }
        .fi-rs-bench-tree:before {
    content: "\f243";
    }
        .fi-rs-benefit-diamond-alt:before {
    content: "\f244";
    }
        .fi-rs-benefit-diamond:before {
    content: "\f245";
    }
        .fi-rs-benefit-hand:before {
    content: "\f246";
    }
        .fi-rs-benefit-increase:before {
    content: "\f247";
    }
        .fi-rs-benefit-porcent:before {
    content: "\f248";
    }
        .fi-rs-benefit:before {
    content: "\f249";
    }
        .fi-rs-betamax:before {
    content: "\f24a";
    }
        .fi-rs-bible:before {
    content: "\f24b";
    }
        .fi-rs-bicycle-journey:before {
    content: "\f24c";
    }
        .fi-rs-big-drop:before {
    content: "\f24d";
    }
        .fi-rs-bike:before {
    content: "\f24e";
    }
        .fi-rs-biking-mountain:before {
    content: "\f24f";
    }
        .fi-rs-biking:before {
    content: "\f250";
    }
        .fi-rs-bill-arrow:before {
    content: "\f251";
    }
        .fi-rs-billiard:before {
    content: "\f252";
    }
        .fi-rs-bin-bottles:before {
    content: "\f253";
    }
        .fi-rs-binary-circle-check:before {
    content: "\f254";
    }
        .fi-rs-binary-lock:before {
    content: "\f255";
    }
        .fi-rs-binary-slash:before {
    content: "\f256";
    }
        .fi-rs-binary:before {
    content: "\f257";
    }
        .fi-rs-binoculars:before {
    content: "\f258";
    }
        .fi-rs-bio-leaves:before {
    content: "\f259";
    }
        .fi-rs-bio:before {
    content: "\f25a";
    }
        .fi-rs-biohazard:before {
    content: "\f25b";
    }
        .fi-rs-bird:before {
    content: "\f25c";
    }
        .fi-rs-bitcoin-sign:before {
    content: "\f25d";
    }
        .fi-rs-blanket:before {
    content: "\f25e";
    }
        .fi-rs-blender-phone:before {
    content: "\f25f";
    }
        .fi-rs-blender:before {
    content: "\f260";
    }
        .fi-rs-blinds-open:before {
    content: "\f261";
    }
        .fi-rs-blinds-raised:before {
    content: "\f262";
    }
        .fi-rs-blinds:before {
    content: "\f263";
    }
        .fi-rs-block-brick-fire:before {
    content: "\f264";
    }
        .fi-rs-block-brick:before {
    content: "\f265";
    }
        .fi-rs-block-microphone:before {
    content: "\f266";
    }
        .fi-rs-block-question:before {
    content: "\f267";
    }
        .fi-rs-block-quote:before {
    content: "\f268";
    }
        .fi-rs-block:before {
    content: "\f269";
    }
        .fi-rs-blockchain-3:before {
    content: "\f26a";
    }
        .fi-rs-blog-pencil:before {
    content: "\f26b";
    }
        .fi-rs-blog-text:before {
    content: "\f26c";
    }
        .fi-rs-blood-dropper:before {
    content: "\f26d";
    }
        .fi-rs-blood-test-tube-alt:before {
    content: "\f26e";
    }
        .fi-rs-blood-test-tube:before {
    content: "\f26f";
    }
        .fi-rs-blood:before {
    content: "\f270";
    }
        .fi-rs-blueberries:before {
    content: "\f271";
    }
        .fi-rs-blueprint:before {
    content: "\f272";
    }
        .fi-rs-bold:before {
    content: "\f273";
    }
        .fi-rs-bolt-auto:before {
    content: "\f274";
    }
        .fi-rs-bolt-slash:before {
    content: "\f275";
    }
        .fi-rs-bolt:before {
    content: "\f276";
    }
        .fi-rs-bomb:before {
    content: "\f277";
    }
        .fi-rs-bone-break:before {
    content: "\f278";
    }
        .fi-rs-bone:before {
    content: "\f279";
    }
        .fi-rs-bong:before {
    content: "\f27a";
    }
        .fi-rs-bonus-alt:before {
    content: "\f27b";
    }
        .fi-rs-bonus-star:before {
    content: "\f27c";
    }
        .fi-rs-bonus:before {
    content: "\f27d";
    }
        .fi-rs-book-alt:before {
    content: "\f27e";
    }
        .fi-rs-book-arrow-right:before {
    content: "\f27f";
    }
        .fi-rs-book-arrow-up:before {
    content: "\f280";
    }
        .fi-rs-book-atlas:before {
    content: "\f281";
    }
        .fi-rs-book-bookmark:before {
    content: "\f282";
    }
        .fi-rs-book-circle-arrow-right:before {
    content: "\f283";
    }
        .fi-rs-book-circle-arrow-up:before {
    content: "\f284";
    }
        .fi-rs-book-copy:before {
    content: "\f285";
    }
        .fi-rs-book-dead:before {
    content: "\f286";
    }
        .fi-rs-book-font:before {
    content: "\f287";
    }
        .fi-rs-book-heart:before {
    content: "\f288";
    }
        .fi-rs-book-medical:before {
    content: "\f289";
    }
        .fi-rs-book-open-cover:before {
    content: "\f28a";
    }
        .fi-rs-book-open-reader:before {
    content: "\f28b";
    }
        .fi-rs-book-plus:before {
    content: "\f28c";
    }
        .fi-rs-book-quran:before {
    content: "\f28d";
    }
        .fi-rs-book-section:before {
    content: "\f28e";
    }
        .fi-rs-book-spells:before {
    content: "\f28f";
    }
        .fi-rs-book-tanakh:before {
    content: "\f290";
    }
        .fi-rs-book-user:before {
    content: "\f291";
    }
        .fi-rs-book:before {
    content: "\f292";
    }
        .fi-rs-Booking:before {
    content: "\f293";
    }
        .fi-rs-bookmark-slash:before {
    content: "\f294";
    }
        .fi-rs-bookmark:before {
    content: "\f295";
    }
        .fi-rs-books-medical:before {
    content: "\f296";
    }
        .fi-rs-books:before {
    content: "\f297";
    }
        .fi-rs-boot-heeled:before {
    content: "\f298";
    }
        .fi-rs-boot:before {
    content: "\f299";
    }
        .fi-rs-booth-curtain:before {
    content: "\f29a";
    }
        .fi-rs-border-all:before {
    content: "\f29b";
    }
        .fi-rs-border-bottom:before {
    content: "\f29c";
    }
        .fi-rs-border-center-h:before {
    content: "\f29d";
    }
        .fi-rs-border-center-v:before {
    content: "\f29e";
    }
        .fi-rs-border-inner:before {
    content: "\f29f";
    }
        .fi-rs-border-left:before {
    content: "\f2a0";
    }
        .fi-rs-border-none:before {
    content: "\f2a1";
    }
        .fi-rs-border-outer:before {
    content: "\f2a2";
    }
        .fi-rs-border-right:before {
    content: "\f2a3";
    }
        .fi-rs-border-style-alt:before {
    content: "\f2a4";
    }
        .fi-rs-border-style:before {
    content: "\f2a5";
    }
        .fi-rs-border-top:before {
    content: "\f2a6";
    }
        .fi-rs-boss:before {
    content: "\f2a7";
    }
        .fi-rs-bottle-droplet:before {
    content: "\f2a8";
    }
        .fi-rs-bottle:before {
    content: "\f2a9";
    }
        .fi-rs-bow-arrow:before {
    content: "\f2aa";
    }
        .fi-rs-bowl-chopsticks-noodles:before {
    content: "\f2ab";
    }
        .fi-rs-bowl-chopsticks:before {
    content: "\f2ac";
    }
        .fi-rs-bowl-rice:before {
    content: "\f2ad";
    }
        .fi-rs-bowl-scoop:before {
    content: "\f2ae";
    }
        .fi-rs-bowl-scoops:before {
    content: "\f2af";
    }
        .fi-rs-bowl-soft-serve:before {
    content: "\f2b0";
    }
        .fi-rs-bowl-spoon:before {
    content: "\f2b1";
    }
        .fi-rs-bowling-ball:before {
    content: "\f2b2";
    }
        .fi-rs-bowling-pins:before {
    content: "\f2b3";
    }
        .fi-rs-bowling:before {
    content: "\f2b4";
    }
        .fi-rs-box-alt:before {
    content: "\f2b5";
    }
        .fi-rs-box-ballot:before {
    content: "\f2b6";
    }
        .fi-rs-box-check:before {
    content: "\f2b7";
    }
        .fi-rs-box-circle-check:before {
    content: "\f2b8";
    }
        .fi-rs-box-dollar:before {
    content: "\f2b9";
    }
        .fi-rs-box-fragile:before {
    content: "\f2ba";
    }
        .fi-rs-box-heart:before {
    content: "\f2bb";
    }
        .fi-rs-box-open-full:before {
    content: "\f2bc";
    }
        .fi-rs-box-open:before {
    content: "\f2bd";
    }
        .fi-rs-box-tissue:before {
    content: "\f2be";
    }
        .fi-rs-box-up:before {
    content: "\f2bf";
    }
        .fi-rs-box:before {
    content: "\f2c0";
    }
        .fi-rs-boxes:before {
    content: "\f2c1";
    }
        .fi-rs-boxing-glove:before {
    content: "\f2c2";
    }
        .fi-rs-bracket-curly-right:before {
    content: "\f2c3";
    }
        .fi-rs-bracket-curly:before {
    content: "\f2c4";
    }
        .fi-rs-bracket-round-right:before {
    content: "\f2c5";
    }
        .fi-rs-bracket-round:before {
    content: "\f2c6";
    }
        .fi-rs-bracket-square-right:before {
    content: "\f2c7";
    }
        .fi-rs-bracket-square:before {
    content: "\f2c8";
    }
        .fi-rs-brackets-curly:before {
    content: "\f2c9";
    }
        .fi-rs-brackets-round:before {
    content: "\f2ca";
    }
        .fi-rs-brackets-square:before {
    content: "\f2cb";
    }
        .fi-rs-braille-a:before {
    content: "\f2cc";
    }
        .fi-rs-braille-b:before {
    content: "\f2cd";
    }
        .fi-rs-braille-c:before {
    content: "\f2ce";
    }
        .fi-rs-braille-d:before {
    content: "\f2cf";
    }
        .fi-rs-braille-e:before {
    content: "\f2d0";
    }
        .fi-rs-braille-g:before {
    content: "\f2d1";
    }
        .fi-rs-braille-h:before {
    content: "\f2d2";
    }
        .fi-rs-braille-i:before {
    content: "\f2d3";
    }
        .fi-rs-braille-j:before {
    content: "\f2d4";
    }
        .fi-rs-braille-k:before {
    content: "\f2d5";
    }
        .fi-rs-braille-l:before {
    content: "\f2d6";
    }
        .fi-rs-braille-m:before {
    content: "\f2d7";
    }
        .fi-rs-braille-n-alt:before {
    content: "\f2d8";
    }
        .fi-rs-braille-n:before {
    content: "\f2d9";
    }
        .fi-rs-braille-o:before {
    content: "\f2da";
    }
        .fi-rs-braille-p:before {
    content: "\f2db";
    }
        .fi-rs-braille-q:before {
    content: "\f2dc";
    }
        .fi-rs-braille-r:before {
    content: "\f2dd";
    }
        .fi-rs-braille-s:before {
    content: "\f2de";
    }
        .fi-rs-braille-t:before {
    content: "\f2df";
    }
        .fi-rs-braille-u:before {
    content: "\f2e0";
    }
        .fi-rs-braille-v:before {
    content: "\f2e1";
    }
        .fi-rs-braille-w:before {
    content: "\f2e2";
    }
        .fi-rs-braille-x:before {
    content: "\f2e3";
    }
        .fi-rs-braille-y:before {
    content: "\f2e4";
    }
        .fi-rs-braille-z:before {
    content: "\f2e5";
    }
        .fi-rs-braille:before {
    content: "\f2e6";
    }
        .fi-rs-brain-circuit:before {
    content: "\f2e7";
    }
        .fi-rs-brain-lightning:before {
    content: "\f2e8";
    }
        .fi-rs-brain:before {
    content: "\f2e9";
    }
        .fi-rs-brake-warning:before {
    content: "\f2ea";
    }
        .fi-rs-branching:before {
    content: "\f2eb";
    }
        .fi-rs-brand:before {
    content: "\f2ec";
    }
        .fi-rs-branding:before {
    content: "\f2ed";
    }
        .fi-rs-bread-loaf:before {
    content: "\f2ee";
    }
        .fi-rs-bread-slice-butter:before {
    content: "\f2ef";
    }
        .fi-rs-bread-slice:before {
    content: "\f2f0";
    }
        .fi-rs-bread:before {
    content: "\f2f1";
    }
        .fi-rs-bridge-circle-check:before {
    content: "\f2f2";
    }
        .fi-rs-bridge-collapse:before {
    content: "\f2f3";
    }
        .fi-rs-bridge-suspension:before {
    content: "\f2f4";
    }
        .fi-rs-bridge:before {
    content: "\f2f5";
    }
        .fi-rs-briefcase-arrow-right:before {
    content: "\f2f6";
    }
        .fi-rs-briefcase-blank:before {
    content: "\f2f7";
    }
        .fi-rs-briefcase:before {
    content: "\f2f8";
    }
        .fi-rs-brightness-low:before {
    content: "\f2f9";
    }
        .fi-rs-brightness:before {
    content: "\f2fa";
    }
        .fi-rs-bring-forward:before {
    content: "\f2fb";
    }
        .fi-rs-bring-front:before {
    content: "\f2fc";
    }
        .fi-rs-broadcast-tower:before {
    content: "\f2fd";
    }
        .fi-rs-broccoli:before {
    content: "\f2fe";
    }
        .fi-rs-broken-arm:before {
    content: "\f2ff";
    }
        .fi-rs-broken-chain-link-wrong:before {
    content: "\f300";
    }
        .fi-rs-broken-image:before {
    content: "\f301";
    }
        .fi-rs-broken-leg:before {
    content: "\f302";
    }
        .fi-rs-broom-ball:before {
    content: "\f303";
    }
        .fi-rs-browser-ui:before {
    content: "\f304";
    }
        .fi-rs-browser:before {
    content: "\f305";
    }
        .fi-rs-browsers:before {
    content: "\f306";
    }
        .fi-rs-brush:before {
    content: "\f307";
    }
        .fi-rs-bucket:before {
    content: "\f308";
    }
        .fi-rs-budget-alt:before {
    content: "\f309";
    }
        .fi-rs-budget:before {
    content: "\f30a";
    }
        .fi-rs-bug-slash:before {
    content: "\f30b";
    }
        .fi-rs-bug:before {
    content: "\f30c";
    }
        .fi-rs-bugs:before {
    content: "\f30d";
    }
        .fi-rs-build-alt:before {
    content: "\f30e";
    }
        .fi-rs-build:before {
    content: "\f30f";
    }
        .fi-rs-builder:before {
    content: "\f310";
    }
        .fi-rs-building-circle-arrow-right:before {
    content: "\f311";
    }
        .fi-rs-building-ngo:before {
    content: "\f312";
    }
        .fi-rs-building:before {
    content: "\f313";
    }
        .fi-rs-bulb:before {
    content: "\f314";
    }
        .fi-rs-bullet:before {
    content: "\f315";
    }
        .fi-rs-bullhorn:before {
    content: "\f316";
    }
        .fi-rs-bullseye-arrow:before {
    content: "\f317";
    }
        .fi-rs-bullseye-pointer:before {
    content: "\f318";
    }
        .fi-rs-bullseye:before {
    content: "\f319";
    }
        .fi-rs-burger-alt:before {
    content: "\f31a";
    }
        .fi-rs-burger-fries:before {
    content: "\f31b";
    }
        .fi-rs-burger-glass:before {
    content: "\f31c";
    }
        .fi-rs-burger-menu:before {
    content: "\f31d";
    }
        .fi-rs-burrito:before {
    content: "\f31e";
    }
        .fi-rs-burst:before {
    content: "\f31f";
    }
        .fi-rs-bus-alt:before {
    content: "\f320";
    }
        .fi-rs-bus:before {
    content: "\f321";
    }
        .fi-rs-business-time:before {
    content: "\f322";
    }
        .fi-rs-Butter:before {
    content: "\f323";
    }
        .fi-rs-butterfly:before {
    content: "\f324";
    }
        .fi-rs-c:before {
    content: "\f325";
    }
        .fi-rs-cabin:before {
    content: "\f326";
    }
        .fi-rs-cactus:before {
    content: "\f327";
    }
        .fi-rs-cage-empty:before {
    content: "\f328";
    }
        .fi-rs-cake-birthday:before {
    content: "\f329";
    }
        .fi-rs-cake-slice:before {
    content: "\f32a";
    }
        .fi-rs-cake-wedding:before {
    content: "\f32b";
    }
        .fi-rs-calculator-bill:before {
    content: "\f32c";
    }
        .fi-rs-calculator-math-tax:before {
    content: "\f32d";
    }
        .fi-rs-calculator-money:before {
    content: "\f32e";
    }
        .fi-rs-calculator-simple:before {
    content: "\f32f";
    }
        .fi-rs-calculator:before {
    content: "\f330";
    }
        .fi-rs-calendar-arrow-down:before {
    content: "\f331";
    }
        .fi-rs-calendar-arrow-up:before {
    content: "\f332";
    }
        .fi-rs-calendar-birhtday-cake:before {
    content: "\f333";
    }
        .fi-rs-calendar-call:before {
    content: "\f334";
    }
        .fi-rs-calendar-check:before {
    content: "\f335";
    }
        .fi-rs-calendar-clock:before {
    content: "\f336";
    }
        .fi-rs-calendar-day:before {
    content: "\f337";
    }
        .fi-rs-calendar-days:before {
    content: "\f338";
    }
        .fi-rs-calendar-event-tax:before {
    content: "\f339";
    }
        .fi-rs-calendar-exclamation:before {
    content: "\f33a";
    }
        .fi-rs-calendar-gavel-legal:before {
    content: "\f33b";
    }
        .fi-rs-calendar-heart:before {
    content: "\f33c";
    }
        .fi-rs-calendar-image:before {
    content: "\f33d";
    }
        .fi-rs-calendar-lines-pen:before {
    content: "\f33e";
    }
        .fi-rs-calendar-lines:before {
    content: "\f33f";
    }
        .fi-rs-calendar-minus:before {
    content: "\f340";
    }
        .fi-rs-calendar-payment-loan:before {
    content: "\f341";
    }
        .fi-rs-calendar-pen:before {
    content: "\f342";
    }
        .fi-rs-calendar-plus:before {
    content: "\f343";
    }
        .fi-rs-calendar-salary:before {
    content: "\f344";
    }
        .fi-rs-calendar-shift-swap:before {
    content: "\f345";
    }
        .fi-rs-calendar-star:before {
    content: "\f346";
    }
        .fi-rs-calendar-swap:before {
    content: "\f347";
    }
        .fi-rs-calendar-update:before {
    content: "\f348";
    }
        .fi-rs-calendar-week:before {
    content: "\f349";
    }
        .fi-rs-calendar-xmark:before {
    content: "\f34a";
    }
        .fi-rs-calendar:before {
    content: "\f34b";
    }
        .fi-rs-calendars:before {
    content: "\f34c";
    }
        .fi-rs-call-duration:before {
    content: "\f34d";
    }
        .fi-rs-call-forbidden:before {
    content: "\f34e";
    }
        .fi-rs-call-history:before {
    content: "\f34f";
    }
        .fi-rs-call-incoming:before {
    content: "\f350";
    }
        .fi-rs-call-missed:before {
    content: "\f351";
    }
        .fi-rs-call-outgoing:before {
    content: "\f352";
    }
        .fi-rs-camcorder:before {
    content: "\f353";
    }
        .fi-rs-camera-cctv:before {
    content: "\f354";
    }
        .fi-rs-camera-movie:before {
    content: "\f355";
    }
        .fi-rs-camera-retro:before {
    content: "\f356";
    }
        .fi-rs-camera-rotate:before {
    content: "\f357";
    }
        .fi-rs-camera-security:before {
    content: "\f358";
    }
        .fi-rs-camera-slash:before {
    content: "\f359";
    }
        .fi-rs-camera-viewfinder:before {
    content: "\f35a";
    }
        .fi-rs-camera:before {
    content: "\f35b";
    }
        .fi-rs-campfire:before {
    content: "\f35c";
    }
        .fi-rs-camping:before {
    content: "\f35d";
    }
        .fi-rs-can-food:before {
    content: "\f35e";
    }
        .fi-rs-candle-holder:before {
    content: "\f35f";
    }
        .fi-rs-candle-lotus-yoga:before {
    content: "\f360";
    }
        .fi-rs-candle-pose-yoga:before {
    content: "\f361";
    }
        .fi-rs-candy-alt:before {
    content: "\f362";
    }
        .fi-rs-candy-bar:before {
    content: "\f363";
    }
        .fi-rs-candy-cane:before {
    content: "\f364";
    }
        .fi-rs-candy-corn:before {
    content: "\f365";
    }
        .fi-rs-candy-sweet:before {
    content: "\f366";
    }
        .fi-rs-candy:before {
    content: "\f367";
    }
        .fi-rs-Cannabis:before {
    content: "\f368";
    }
        .fi-rs-canned-food:before {
    content: "\f369";
    }
        .fi-rs-cannon:before {
    content: "\f36a";
    }
        .fi-rs-capsules:before {
    content: "\f36b";
    }
        .fi-rs-car-alt:before {
    content: "\f36c";
    }
        .fi-rs-car-battery:before {
    content: "\f36d";
    }
        .fi-rs-car-bolt:before {
    content: "\f36e";
    }
        .fi-rs-car-building:before {
    content: "\f36f";
    }
        .fi-rs-car-bump:before {
    content: "\f370";
    }
        .fi-rs-car-bus:before {
    content: "\f371";
    }
        .fi-rs-car-circle-bolt:before {
    content: "\f372";
    }
        .fi-rs-car-crash:before {
    content: "\f373";
    }
        .fi-rs-car-garage:before {
    content: "\f374";
    }
        .fi-rs-car-journey:before {
    content: "\f375";
    }
        .fi-rs-car-mechanic:before {
    content: "\f376";
    }
        .fi-rs-car-rear:before {
    content: "\f377";
    }
        .fi-rs-car-side-bolt:before {
    content: "\f378";
    }
        .fi-rs-car-side:before {
    content: "\f379";
    }
        .fi-rs-car-tilt:before {
    content: "\f37a";
    }
        .fi-rs-car-tunnel:before {
    content: "\f37b";
    }
        .fi-rs-car-wash:before {
    content: "\f37c";
    }
        .fi-rs-car:before {
    content: "\f37d";
    }
        .fi-rs-caravan-alt:before {
    content: "\f37e";
    }
        .fi-rs-caravan:before {
    content: "\f37f";
    }
        .fi-rs-card-club:before {
    content: "\f380";
    }
        .fi-rs-card-diamond:before {
    content: "\f381";
    }
        .fi-rs-card-heart:before {
    content: "\f382";
    }
        .fi-rs-card-spade:before {
    content: "\f383";
    }
        .fi-rs-cardinal-compass:before {
    content: "\f384";
    }
        .fi-rs-cards-blank:before {
    content: "\f385";
    }
        .fi-rs-career-growth:before {
    content: "\f386";
    }
        .fi-rs-career-path:before {
    content: "\f387";
    }
        .fi-rs-caret-circle-down:before {
    content: "\f388";
    }
        .fi-rs-caret-circle-right:before {
    content: "\f389";
    }
        .fi-rs-caret-circle-up:before {
    content: "\f38a";
    }
        .fi-rs-caret-down:before {
    content: "\f38b";
    }
        .fi-rs-caret-left:before {
    content: "\f38c";
    }
        .fi-rs-caret-quare-up:before {
    content: "\f38d";
    }
        .fi-rs-caret-right:before {
    content: "\f38e";
    }
        .fi-rs-caret-square-down:before {
    content: "\f38f";
    }
        .fi-rs-caret-square-left_1:before {
    content: "\f390";
    }
        .fi-rs-caret-square-left:before {
    content: "\f391";
    }
        .fi-rs-caret-square-right:before {
    content: "\f392";
    }
        .fi-rs-caret-up:before {
    content: "\f393";
    }
        .fi-rs-carrot:before {
    content: "\f394";
    }
        .fi-rs-cars-crash:before {
    content: "\f395";
    }
        .fi-rs-cars:before {
    content: "\f396";
    }
        .fi-rs-cart-arrow-down:before {
    content: "\f397";
    }
        .fi-rs-cart-minus:before {
    content: "\f398";
    }
        .fi-rs-cart-shopping-fast:before {
    content: "\f399";
    }
        .fi-rs-case-study:before {
    content: "\f39a";
    }
        .fi-rs-cash-register:before {
    content: "\f39b";
    }
        .fi-rs-cassette-tape:before {
    content: "\f39c";
    }
        .fi-rs-cassette-vhs:before {
    content: "\f39d";
    }
        .fi-rs-castle:before {
    content: "\f39e";
    }
        .fi-rs-cat-dog:before {
    content: "\f39f";
    }
        .fi-rs-cat-head:before {
    content: "\f3a0";
    }
        .fi-rs-cat-space:before {
    content: "\f3a1";
    }
        .fi-rs-cat:before {
    content: "\f3a2";
    }
        .fi-rs-catalog-alt:before {
    content: "\f3a3";
    }
        .fi-rs-catalog-magazine:before {
    content: "\f3a4";
    }
        .fi-rs-catalog:before {
    content: "\f3a5";
    }
        .fi-rs-category-alt:before {
    content: "\f3a6";
    }
        .fi-rs-category:before {
    content: "\f3a7";
    }
        .fi-rs-cauldron:before {
    content: "\f3a8";
    }
        .fi-rs-cedi-sign:before {
    content: "\f3a9";
    }
        .fi-rs-cello:before {
    content: "\f3aa";
    }
        .fi-rs-cent-sign:before {
    content: "\f3ab";
    }
        .fi-rs-chair-office:before {
    content: "\f3ac";
    }
        .fi-rs-chair:before {
    content: "\f3ad";
    }
        .fi-rs-chalkboard-user:before {
    content: "\f3ae";
    }
        .fi-rs-chalkboard:before {
    content: "\f3af";
    }
        .fi-rs-challenge-alt:before {
    content: "\f3b0";
    }
        .fi-rs-challenge:before {
    content: "\f3b1";
    }
        .fi-rs-channel:before {
    content: "\f3b2";
    }
        .fi-rs-charging-station:before {
    content: "\f3b3";
    }
        .fi-rs-chart-area:before {
    content: "\f3b4";
    }
        .fi-rs-chart-bullet:before {
    content: "\f3b5";
    }
        .fi-rs-chart-candlestick:before {
    content: "\f3b6";
    }
        .fi-rs-chart-connected:before {
    content: "\f3b7";
    }
        .fi-rs-chart-gantt:before {
    content: "\f3b8";
    }
        .fi-rs-chart-histogram:before {
    content: "\f3b9";
    }
        .fi-rs-chart-kanban:before {
    content: "\f3ba";
    }
        .fi-rs-chart-line-up-down:before {
    content: "\f3bb";
    }
        .fi-rs-chart-line-up:before {
    content: "\f3bc";
    }
        .fi-rs-chart-mixed-up-circle-currency:before {
    content: "\f3bd";
    }
        .fi-rs-chart-mixed-up-circle-dollar:before {
    content: "\f3be";
    }
        .fi-rs-chart-mixed:before {
    content: "\f3bf";
    }
        .fi-rs-chart-network:before {
    content: "\f3c0";
    }
        .fi-rs-chart-pie-alt:before {
    content: "\f3c1";
    }
        .fi-rs-chart-pie-simple-circle-currency:before {
    content: "\f3c2";
    }
        .fi-rs-chart-pie-simple-circle-dollar:before {
    content: "\f3c3";
    }
        .fi-rs-chart-pie:before {
    content: "\f3c4";
    }
        .fi-rs-chart-pyramid:before {
    content: "\f3c5";
    }
        .fi-rs-chart-radar:before {
    content: "\f3c6";
    }
        .fi-rs-chart-scatter-3d:before {
    content: "\f3c7";
    }
        .fi-rs-chart-scatter-bubble:before {
    content: "\f3c8";
    }
        .fi-rs-chart-scatter:before {
    content: "\f3c9";
    }
        .fi-rs-chart-set-theory:before {
    content: "\f3ca";
    }
        .fi-rs-chart-simple-horizontal:before {
    content: "\f3cb";
    }
        .fi-rs-chart-simple:before {
    content: "\f3cc";
    }
        .fi-rs-chart-tree-map:before {
    content: "\f3cd";
    }
        .fi-rs-chart-tree:before {
    content: "\f3ce";
    }
        .fi-rs-chart-user:before {
    content: "\f3cf";
    }
        .fi-rs-chart-waterfall:before {
    content: "\f3d0";
    }
        .fi-rs-chat-arrow-down:before {
    content: "\f3d1";
    }
        .fi-rs-chat-arrow-grow:before {
    content: "\f3d2";
    }
        .fi-rs-chat-bubble-call:before {
    content: "\f3d3";
    }
        .fi-rs-chatbot-speech-bubble:before {
    content: "\f3d4";
    }
        .fi-rs-chatbot:before {
    content: "\f3d5";
    }
        .fi-rs-cheap-bill:before {
    content: "\f3d6";
    }
        .fi-rs-cheap-dollar:before {
    content: "\f3d7";
    }
        .fi-rs-cheap-stack-dollar:before {
    content: "\f3d8";
    }
        .fi-rs-cheap-stack:before {
    content: "\f3d9";
    }
        .fi-rs-cheap:before {
    content: "\f3da";
    }
        .fi-rs-check-circle:before {
    content: "\f3db";
    }
        .fi-rs-check-double:before {
    content: "\f3dc";
    }
        .fi-rs-check-in-calendar:before {
    content: "\f3dd";
    }
        .fi-rs-check-out-calendar:before {
    content: "\f3de";
    }
        .fi-rs-check:before {
    content: "\f3df";
    }
        .fi-rs-checkbox:before {
    content: "\f3e0";
    }
        .fi-rs-checklist-task-budget:before {
    content: "\f3e1";
    }
        .fi-rs-cheese-alt:before {
    content: "\f3e2";
    }
        .fi-rs-cheese:before {
    content: "\f3e3";
    }
        .fi-rs-cheeseburger:before {
    content: "\f3e4";
    }
        .fi-rs-cherry:before {
    content: "\f3e5";
    }
        .fi-rs-chess-bishop:before {
    content: "\f3e6";
    }
        .fi-rs-chess-board:before {
    content: "\f3e7";
    }
        .fi-rs-chess-clock-alt:before {
    content: "\f3e8";
    }
        .fi-rs-chess-clock:before {
    content: "\f3e9";
    }
        .fi-rs-chess-king-alt:before {
    content: "\f3ea";
    }
        .fi-rs-chess-king:before {
    content: "\f3eb";
    }
        .fi-rs-chess-knight-alt:before {
    content: "\f3ec";
    }
        .fi-rs-chess-knight:before {
    content: "\f3ed";
    }
        .fi-rs-chess-pawn-alt:before {
    content: "\f3ee";
    }
        .fi-rs-chess-pawn:before {
    content: "\f3ef";
    }
        .fi-rs-chess-piece:before {
    content: "\f3f0";
    }
        .fi-rs-chess-queen-alt:before {
    content: "\f3f1";
    }
        .fi-rs-chess-queen:before {
    content: "\f3f2";
    }
        .fi-rs-chess-rook-alt:before {
    content: "\f3f3";
    }
        .fi-rs-chess-rook:before {
    content: "\f3f4";
    }
        .fi-rs-chess:before {
    content: "\f3f5";
    }
        .fi-rs-chevron-double-down:before {
    content: "\f3f6";
    }
        .fi-rs-chevron-double-up:before {
    content: "\f3f7";
    }
        .fi-rs-child-head:before {
    content: "\f3f8";
    }
        .fi-rs-child:before {
    content: "\f3f9";
    }
        .fi-rs-chimney:before {
    content: "\f3fa";
    }
        .fi-rs-chip:before {
    content: "\f3fb";
    }
        .fi-rs-chocolate:before {
    content: "\f3fc";
    }
        .fi-rs-choir-singing:before {
    content: "\f3fd";
    }
        .fi-rs-choose-alt:before {
    content: "\f3fe";
    }
        .fi-rs-choose:before {
    content: "\f3ff";
    }
        .fi-rs-church:before {
    content: "\f400";
    }
        .fi-rs-circle-0:before {
    content: "\f401";
    }
        .fi-rs-circle-1:before {
    content: "\f402";
    }
        .fi-rs-circle-2:before {
    content: "\f403";
    }
        .fi-rs-circle-3:before {
    content: "\f404";
    }
        .fi-rs-circle-4:before {
    content: "\f405";
    }
        .fi-rs-circle-5:before {
    content: "\f406";
    }
        .fi-rs-circle-6:before {
    content: "\f407";
    }
        .fi-rs-circle-7:before {
    content: "\f408";
    }
        .fi-rs-circle-8:before {
    content: "\f409";
    }
        .fi-rs-circle-9:before {
    content: "\f40a";
    }
        .fi-rs-circle-a:before {
    content: "\f40b";
    }
        .fi-rs-circle-b:before {
    content: "\f40c";
    }
        .fi-rs-circle-bolt:before {
    content: "\f40d";
    }
        .fi-rs-circle-book-open:before {
    content: "\f40e";
    }
        .fi-rs-circle-bookmark:before {
    content: "\f40f";
    }
        .fi-rs-circle-c:before {
    content: "\f410";
    }
        .fi-rs-circle-calendar:before {
    content: "\f411";
    }
        .fi-rs-circle-camera:before {
    content: "\f412";
    }
        .fi-rs-circle-cross:before {
    content: "\f413";
    }
        .fi-rs-circle-d:before {
    content: "\f414";
    }
        .fi-rs-circle-dashed:before {
    content: "\f415";
    }
        .fi-rs-circle-divide:before {
    content: "\f416";
    }
        .fi-rs-circle-e:before {
    content: "\f417";
    }
        .fi-rs-circle-ellipsis-vertical:before {
    content: "\f418";
    }
        .fi-rs-circle-ellipsis:before {
    content: "\f419";
    }
        .fi-rs-circle-envelope:before {
    content: "\f41a";
    }
        .fi-rs-circle-exclamation-check:before {
    content: "\f41b";
    }
        .fi-rs-circle-f:before {
    content: "\f41c";
    }
        .fi-rs-circle-g:before {
    content: "\f41d";
    }
        .fi-rs-circle-h:before {
    content: "\f41e";
    }
        .fi-rs-circle-half-stroke:before {
    content: "\f41f";
    }
        .fi-rs-circle-half:before {
    content: "\f420";
    }
        .fi-rs-circle-heart:before {
    content: "\f421";
    }
        .fi-rs-circle-i:before {
    content: "\f422";
    }
        .fi-rs-circle-j:before {
    content: "\f423";
    }
        .fi-rs-circle-k:before {
    content: "\f424";
    }
        .fi-rs-circle-l:before {
    content: "\f425";
    }
        .fi-rs-circle-m:before {
    content: "\f426";
    }
        .fi-rs-circle-microphone-lines:before {
    content: "\f427";
    }
        .fi-rs-circle-microphone:before {
    content: "\f428";
    }
        .fi-rs-circle-n:before {
    content: "\f429";
    }
        .fi-rs-circle-nodes:before {
    content: "\f42a";
    }
        .fi-rs-circle-o:before {
    content: "\f42b";
    }
        .fi-rs-circle-overlap:before {
    content: "\f42c";
    }
        .fi-rs-circle-p:before {
    content: "\f42d";
    }
        .fi-rs-circle-phone-flip:before {
    content: "\f42e";
    }
        .fi-rs-circle-phone-hangup:before {
    content: "\f42f";
    }
        .fi-rs-circle-phone:before {
    content: "\f430";
    }
        .fi-rs-circle-q:before {
    content: "\f431";
    }
        .fi-rs-circle-quarter:before {
    content: "\f432";
    }
        .fi-rs-circle-quarters-alt:before {
    content: "\f433";
    }
        .fi-rs-circle-r:before {
    content: "\f434";
    }
        .fi-rs-circle-s:before {
    content: "\f435";
    }
        .fi-rs-circle-small:before {
    content: "\f436";
    }
        .fi-rs-circle-star:before {
    content: "\f437";
    }
        .fi-rs-circle-t:before {
    content: "\f438";
    }
        .fi-rs-circle-three-quarters:before {
    content: "\f439";
    }
        .fi-rs-circle-trash:before {
    content: "\f43a";
    }
        .fi-rs-circle-u:before {
    content: "\f43b";
    }
        .fi-rs-circle-user:before {
    content: "\f43c";
    }
        .fi-rs-circle-v:before {
    content: "\f43d";
    }
        .fi-rs-circle-video:before {
    content: "\f43e";
    }
        .fi-rs-circle-w:before {
    content: "\f43f";
    }
        .fi-rs-circle-waveform-lines:before {
    content: "\f440";
    }
        .fi-rs-circle-wifi-circle-wifi:before {
    content: "\f441";
    }
        .fi-rs-circle-x:before {
    content: "\f442";
    }
        .fi-rs-circle-xmark:before {
    content: "\f443";
    }
        .fi-rs-circle-y:before {
    content: "\f444";
    }
        .fi-rs-circle-z:before {
    content: "\f445";
    }
        .fi-rs-circle:before {
    content: "\f446";
    }
        .fi-rs-citrus-slice:before {
    content: "\f447";
    }
        .fi-rs-citrus:before {
    content: "\f448";
    }
        .fi-rs-city:before {
    content: "\f449";
    }
        .fi-rs-clapperboard-play:before {
    content: "\f44a";
    }
        .fi-rs-clapperboard:before {
    content: "\f44b";
    }
        .fi-rs-clarinet:before {
    content: "\f44c";
    }
        .fi-rs-claw-marks:before {
    content: "\f44d";
    }
        .fi-rs-clean:before {
    content: "\f44e";
    }
        .fi-rs-clear-alt:before {
    content: "\f44f";
    }
        .fi-rs-clip-file:before {
    content: "\f450";
    }
        .fi-rs-clip-mail:before {
    content: "\f451";
    }
        .fi-rs-clip:before {
    content: "\f452";
    }
        .fi-rs-clipboard-check:before {
    content: "\f453";
    }
        .fi-rs-clipboard-exclamation:before {
    content: "\f454";
    }
        .fi-rs-clipboard-list-check:before {
    content: "\f455";
    }
        .fi-rs-clipboard-list:before {
    content: "\f456";
    }
        .fi-rs-clipboard-prescription:before {
    content: "\f457";
    }
        .fi-rs-clipboard-question:before {
    content: "\f458";
    }
        .fi-rs-clipboard-user:before {
    content: "\f459";
    }
        .fi-rs-clipboard:before {
    content: "\f45a";
    }
        .fi-rs-clipoard-wrong:before {
    content: "\f45b";
    }
        .fi-rs-clock-desk:before {
    content: "\f45c";
    }
        .fi-rs-clock-eight-thirty:before {
    content: "\f45d";
    }
        .fi-rs-clock-eleven-thirty:before {
    content: "\f45e";
    }
        .fi-rs-clock-eleven:before {
    content: "\f45f";
    }
        .fi-rs-clock-five-thirty:before {
    content: "\f460";
    }
        .fi-rs-clock-five:before {
    content: "\f461";
    }
        .fi-rs-clock-four-thirty:before {
    content: "\f462";
    }
        .fi-rs-clock-nine-thirty:before {
    content: "\f463";
    }
        .fi-rs-clock-nine:before {
    content: "\f464";
    }
        .fi-rs-clock-one-thirty:before {
    content: "\f465";
    }
        .fi-rs-clock-one:before {
    content: "\f466";
    }
        .fi-rs-clock-seven-thirty:before {
    content: "\f467";
    }
        .fi-rs-clock-seven:before {
    content: "\f468";
    }
        .fi-rs-clock-six-thirty:before {
    content: "\f469";
    }
        .fi-rs-clock-six:before {
    content: "\f46a";
    }
        .fi-rs-clock-ten-thirty:before {
    content: "\f46b";
    }
        .fi-rs-clock-ten:before {
    content: "\f46c";
    }
        .fi-rs-clock-three-thirty:before {
    content: "\f46d";
    }
        .fi-rs-clock-three:before {
    content: "\f46e";
    }
        .fi-rs-clock-time-tracking:before {
    content: "\f46f";
    }
        .fi-rs-clock-twelve-thirty:before {
    content: "\f470";
    }
        .fi-rs-clock-twelve:before {
    content: "\f471";
    }
        .fi-rs-clock-two-thirty:before {
    content: "\f472";
    }
        .fi-rs-clock-two:before {
    content: "\f473";
    }
        .fi-rs-clock-up-arrow:before {
    content: "\f474";
    }
        .fi-rs-clock:before {
    content: "\f475";
    }
        .fi-rs-clone:before {
    content: "\f476";
    }
        .fi-rs-closed-captioning-slash:before {
    content: "\f477";
    }
        .fi-rs-clothes-hanger:before {
    content: "\f478";
    }
        .fi-rs-cloud-back-up-alt:before {
    content: "\f479";
    }
        .fi-rs-cloud-back-up:before {
    content: "\f47a";
    }
        .fi-rs-cloud-check:before {
    content: "\f47b";
    }
        .fi-rs-cloud-code:before {
    content: "\f47c";
    }
        .fi-rs-cloud-disabled:before {
    content: "\f47d";
    }
        .fi-rs-cloud-download-alt:before {
    content: "\f47e";
    }
        .fi-rs-cloud-download:before {
    content: "\f47f";
    }
        .fi-rs-cloud-drizzle:before {
    content: "\f480";
    }
        .fi-rs-cloud-exclamation:before {
    content: "\f481";
    }
        .fi-rs-cloud-gear-automation:before {
    content: "\f482";
    }
        .fi-rs-cloud-hail-mixed:before {
    content: "\f483";
    }
        .fi-rs-cloud-hail:before {
    content: "\f484";
    }
        .fi-rs-cloud-meatball:before {
    content: "\f485";
    }
        .fi-rs-cloud-moon-rain:before {
    content: "\f486";
    }
        .fi-rs-cloud-moon:before {
    content: "\f487";
    }
        .fi-rs-cloud-question:before {
    content: "\f488";
    }
        .fi-rs-cloud-rain:before {
    content: "\f489";
    }
        .fi-rs-cloud-rainbow:before {
    content: "\f48a";
    }
        .fi-rs-cloud-share:before {
    content: "\f48b";
    }
        .fi-rs-cloud-showers-heavy:before {
    content: "\f48c";
    }
        .fi-rs-cloud-showers-water:before {
    content: "\f48d";
    }
        .fi-rs-cloud-showers:before {
    content: "\f48e";
    }
        .fi-rs-cloud-sleet:before {
    content: "\f48f";
    }
        .fi-rs-cloud-snow:before {
    content: "\f490";
    }
        .fi-rs-cloud-sun-rain:before {
    content: "\f491";
    }
        .fi-rs-cloud-sun:before {
    content: "\f492";
    }
        .fi-rs-cloud-upload-alt:before {
    content: "\f493";
    }
        .fi-rs-cloud-upload:before {
    content: "\f494";
    }
        .fi-rs-cloud:before {
    content: "\f495";
    }
        .fi-rs-clouds-moon:before {
    content: "\f496";
    }
        .fi-rs-clouds-sun:before {
    content: "\f497";
    }
        .fi-rs-clouds:before {
    content: "\f498";
    }
        .fi-rs-clover-alt:before {
    content: "\f499";
    }
        .fi-rs-club:before {
    content: "\f49a";
    }
        .fi-rs-cocktail-alt:before {
    content: "\f49b";
    }
        .fi-rs-cocktail:before {
    content: "\f49c";
    }
        .fi-rs-coconut:before {
    content: "\f49d";
    }
        .fi-rs-code-branch:before {
    content: "\f49e";
    }
        .fi-rs-code-commit:before {
    content: "\f49f";
    }
        .fi-rs-code-compare:before {
    content: "\f4a0";
    }
        .fi-rs-code-fork:before {
    content: "\f4a1";
    }
        .fi-rs-code-merge:before {
    content: "\f4a2";
    }
        .fi-rs-code-pull-request-closed:before {
    content: "\f4a3";
    }
        .fi-rs-code-pull-request-draft:before {
    content: "\f4a4";
    }
        .fi-rs-code-pull-request:before {
    content: "\f4a5";
    }
        .fi-rs-code-simple:before {
    content: "\f4a6";
    }
        .fi-rs-coffee-bean:before {
    content: "\f4a7";
    }
        .fi-rs-coffee-beans:before {
    content: "\f4a8";
    }
        .fi-rs-coffee-heart:before {
    content: "\f4a9";
    }
        .fi-rs-coffee-pot:before {
    content: "\f4aa";
    }
        .fi-rs-coffee:before {
    content: "\f4ab";
    }
        .fi-rs-coffin-cross:before {
    content: "\f4ac";
    }
        .fi-rs-coffin:before {
    content: "\f4ad";
    }
        .fi-rs-coin-up-arrow:before {
    content: "\f4ae";
    }
        .fi-rs-coin:before {
    content: "\f4af";
    }
        .fi-rs-coins:before {
    content: "\f4b0";
    }
        .fi-rs-colon-sign:before {
    content: "\f4b1";
    }
        .fi-rs-columns-3:before {
    content: "\f4b2";
    }
        .fi-rs-comet:before {
    content: "\f4b3";
    }
        .fi-rs-command:before {
    content: "\f4b4";
    }
        .fi-rs-comment-alt-check:before {
    content: "\f4b5";
    }
        .fi-rs-comment-alt-dots:before {
    content: "\f4b6";
    }
        .fi-rs-comment-alt-edit:before {
    content: "\f4b7";
    }
        .fi-rs-comment-alt-medical:before {
    content: "\f4b8";
    }
        .fi-rs-comment-alt-middle-top:before {
    content: "\f4b9";
    }
        .fi-rs-comment-alt-middle:before {
    content: "\f4ba";
    }
        .fi-rs-comment-alt-minus:before {
    content: "\f4bb";
    }
        .fi-rs-comment-alt-music:before {
    content: "\f4bc";
    }
        .fi-rs-comment-alt:before {
    content: "\f4bd";
    }
        .fi-rs-comment-arrow-down:before {
    content: "\f4be";
    }
        .fi-rs-comment-arrow-up-right:before {
    content: "\f4bf";
    }
        .fi-rs-comment-arrow-up:before {
    content: "\f4c0";
    }
        .fi-rs-comment-check:before {
    content: "\f4c1";
    }
        .fi-rs-comment-code:before {
    content: "\f4c2";
    }
        .fi-rs-comment-dollar:before {
    content: "\f4c3";
    }
        .fi-rs-comment-dots:before {
    content: "\f4c4";
    }
        .fi-rs-comment-exclamation:before {
    content: "\f4c5";
    }
        .fi-rs-comment-heart:before {
    content: "\f4c6";
    }
        .fi-rs-comment-image:before {
    content: "\f4c7";
    }
        .fi-rs-comment-info:before {
    content: "\f4c8";
    }
        .fi-rs-comment-medical:before {
    content: "\f4c9";
    }
        .fi-rs-comment-minus:before {
    content: "\f4ca";
    }
        .fi-rs-comment-pen:before {
    content: "\f4cb";
    }
        .fi-rs-comment-question:before {
    content: "\f4cc";
    }
        .fi-rs-comment-quote:before {
    content: "\f4cd";
    }
        .fi-rs-comment-slash:before {
    content: "\f4ce";
    }
        .fi-rs-comment-smile:before {
    content: "\f4cf";
    }
        .fi-rs-comment-sms:before {
    content: "\f4d0";
    }
        .fi-rs-comment-text:before {
    content: "\f4d1";
    }
        .fi-rs-comment-user:before {
    content: "\f4d2";
    }
        .fi-rs-comment-xmark:before {
    content: "\f4d3";
    }
        .fi-rs-comment:before {
    content: "\f4d4";
    }
        .fi-rs-comments-dollar:before {
    content: "\f4d5";
    }
        .fi-rs-comments-question-check:before {
    content: "\f4d6";
    }
        .fi-rs-comments-question:before {
    content: "\f4d7";
    }
        .fi-rs-comments:before {
    content: "\f4d8";
    }
        .fi-rs-compass-alt:before {
    content: "\f4d9";
    }
        .fi-rs-compass-east:before {
    content: "\f4da";
    }
        .fi-rs-compass-north:before {
    content: "\f4db";
    }
        .fi-rs-compass-slash:before {
    content: "\f4dc";
    }
        .fi-rs-compass-south:before {
    content: "\f4dd";
    }
        .fi-rs-compass-west:before {
    content: "\f4de";
    }
        .fi-rs-completed:before {
    content: "\f4df";
    }
        .fi-rs-compliance-clipboard:before {
    content: "\f4e0";
    }
        .fi-rs-compliance-document:before {
    content: "\f4e1";
    }
        .fi-rs-compliance:before {
    content: "\f4e2";
    }
        .fi-rs-compress-alt:before {
    content: "\f4e3";
    }
        .fi-rs-compress:before {
    content: "\f4e4";
    }
        .fi-rs-computer-classic:before {
    content: "\f4e5";
    }
        .fi-rs-computer-mouse:before {
    content: "\f4e6";
    }
        .fi-rs-computer-speaker:before {
    content: "\f4e7";
    }
        .fi-rs-computer:before {
    content: "\f4e8";
    }
        .fi-rs-concierge-bell:before {
    content: "\f4e9";
    }
        .fi-rs-condition-alt:before {
    content: "\f4ea";
    }
        .fi-rs-condition:before {
    content: "\f4eb";
    }
        .fi-rs-confetti:before {
    content: "\f4ec";
    }
        .fi-rs-confidential-discussion:before {
    content: "\f4ed";
    }
        .fi-rs-console-controller:before {
    content: "\f4ee";
    }
        .fi-rs-constellation:before {
    content: "\f4ef";
    }
        .fi-rs-contact-lens-eyes:before {
    content: "\f4f0";
    }
        .fi-rs-container-storage:before {
    content: "\f4f1";
    }
        .fi-rs-convert-document:before {
    content: "\f4f2";
    }
        .fi-rs-convert-shapes:before {
    content: "\f4f3";
    }
        .fi-rs-conveyor-belt-alt:before {
    content: "\f4f4";
    }
        .fi-rs-conveyor-belt-arm:before {
    content: "\f4f5";
    }
        .fi-rs-conveyor-belt-empty:before {
    content: "\f4f6";
    }
        .fi-rs-conveyor-belt:before {
    content: "\f4f7";
    }
        .fi-rs-cookie-alt:before {
    content: "\f4f8";
    }
        .fi-rs-cookie:before {
    content: "\f4f9";
    }
        .fi-rs-copy-alt:before {
    content: "\f4fa";
    }
        .fi-rs-copy-image:before {
    content: "\f4fb";
    }
        .fi-rs-copy:before {
    content: "\f4fc";
    }
        .fi-rs-copyright:before {
    content: "\f4fd";
    }
        .fi-rs-corn:before {
    content: "\f4fe";
    }
        .fi-rs-corporate-alt:before {
    content: "\f4ff";
    }
        .fi-rs-corporate:before {
    content: "\f500";
    }
        .fi-rs-couch:before {
    content: "\f501";
    }
        .fi-rs-court-sport:before {
    content: "\f502";
    }
        .fi-rs-cow-alt:before {
    content: "\f503";
    }
        .fi-rs-cow:before {
    content: "\f504";
    }
        .fi-rs-cowbell-circle-plus:before {
    content: "\f505";
    }
        .fi-rs-cowbell-more:before {
    content: "\f506";
    }
        .fi-rs-cowbell:before {
    content: "\f507";
    }
        .fi-rs-crab:before {
    content: "\f508";
    }
        .fi-rs-crate-empty:before {
    content: "\f509";
    }
        .fi-rs-cream:before {
    content: "\f50a";
    }
        .fi-rs-credit-card-buyer:before {
    content: "\f50b";
    }
        .fi-rs-credit-card-eye:before {
    content: "\f50c";
    }
        .fi-rs-credit-card:before {
    content: "\f50d";
    }
        .fi-rs-cricket:before {
    content: "\f50e";
    }
        .fi-rs-crm-alt:before {
    content: "\f50f";
    }
        .fi-rs-crm-computer:before {
    content: "\f510";
    }
        .fi-rs-CRM:before {
    content: "\f511";
    }
        .fi-rs-croissant:before {
    content: "\f512";
    }
        .fi-rs-cross-religion:before {
    content: "\f513";
    }
        .fi-rs-cross-small:before {
    content: "\f514";
    }
        .fi-rs-cross:before {
    content: "\f515";
    }
        .fi-rs-crossed-eye:before {
    content: "\f516";
    }
        .fi-rs-crow:before {
    content: "\f517";
    }
        .fi-rs-crown:before {
    content: "\f518";
    }
        .fi-rs-crutch:before {
    content: "\f519";
    }
        .fi-rs-crutches:before {
    content: "\f51a";
    }
        .fi-rs-cruzeiro-sign:before {
    content: "\f51b";
    }
        .fi-rs-crypto-calendar:before {
    content: "\f51c";
    }
        .fi-rs-cryptocurrency:before {
    content: "\f51d";
    }
        .fi-rs-crystal-ball:before {
    content: "\f51e";
    }
        .fi-rs-cube:before {
    content: "\f51f";
    }
        .fi-rs-cubes-stacked:before {
    content: "\f520";
    }
        .fi-rs-cubes:before {
    content: "\f521";
    }
        .fi-rs-cucumber:before {
    content: "\f522";
    }
        .fi-rs-cup-straw-swoosh:before {
    content: "\f523";
    }
        .fi-rs-cup-straw:before {
    content: "\f524";
    }
        .fi-rs-cup-togo:before {
    content: "\f525";
    }
        .fi-rs-cupcake-alt:before {
    content: "\f526";
    }
        .fi-rs-cupcake:before {
    content: "\f527";
    }
        .fi-rs-curling:before {
    content: "\f528";
    }
        .fi-rs-cursor-finger:before {
    content: "\f529";
    }
        .fi-rs-cursor-plus:before {
    content: "\f52a";
    }
        .fi-rs-cursor-text-alt:before {
    content: "\f52b";
    }
        .fi-rs-cursor-text:before {
    content: "\f52c";
    }
        .fi-rs-cursor:before {
    content: "\f52d";
    }
        .fi-rs-curve-alt:before {
    content: "\f52e";
    }
        .fi-rs-curve-arrow:before {
    content: "\f52f";
    }
        .fi-rs-curve:before {
    content: "\f530";
    }
        .fi-rs-custard:before {
    content: "\f531";
    }
        .fi-rs-customer-care:before {
    content: "\f532";
    }
        .fi-rs-customer-service:before {
    content: "\f533";
    }
        .fi-rs-customization-cogwheel:before {
    content: "\f534";
    }
        .fi-rs-customization:before {
    content: "\f535";
    }
        .fi-rs-customize-computer:before {
    content: "\f536";
    }
        .fi-rs-customize-edit:before {
    content: "\f537";
    }
        .fi-rs-customize:before {
    content: "\f538";
    }
        .fi-rs-CV:before {
    content: "\f539";
    }
        .fi-rs-cvv-card:before {
    content: "\f53a";
    }
        .fi-rs-d:before {
    content: "\f53b";
    }
        .fi-rs-dagger:before {
    content: "\f53c";
    }
        .fi-rs-daily-calendar:before {
    content: "\f53d";
    }
        .fi-rs-damage:before {
    content: "\f53e";
    }
        .fi-rs-dart:before {
    content: "\f53f";
    }
        .fi-rs-dashboard-monitor:before {
    content: "\f540";
    }
        .fi-rs-dashboard-panel:before {
    content: "\f541";
    }
        .fi-rs-dashboard:before {
    content: "\f542";
    }
        .fi-rs-data-transfer:before {
    content: "\f543";
    }
        .fi-rs-database:before {
    content: "\f544";
    }
        .fi-rs-debt:before {
    content: "\f545";
    }
        .fi-rs-deer-rudolph:before {
    content: "\f546";
    }
        .fi-rs-deer:before {
    content: "\f547";
    }
        .fi-rs-degree-credential:before {
    content: "\f548";
    }
        .fi-rs-delete-document:before {
    content: "\f549";
    }
        .fi-rs-delete-right:before {
    content: "\f54a";
    }
        .fi-rs-delete-user:before {
    content: "\f54b";
    }
        .fi-rs-delete:before {
    content: "\f54c";
    }
        .fi-rs-democrat:before {
    content: "\f54d";
    }
        .fi-rs-department-structure:before {
    content: "\f54e";
    }
        .fi-rs-department:before {
    content: "\f54f";
    }
        .fi-rs-deposit-alt:before {
    content: "\f550";
    }
        .fi-rs-deposit:before {
    content: "\f551";
    }
        .fi-rs-description-alt:before {
    content: "\f552";
    }
        .fi-rs-description:before {
    content: "\f553";
    }
        .fi-rs-desk:before {
    content: "\f554";
    }
        .fi-rs-desktop-arrow-down:before {
    content: "\f555";
    }
        .fi-rs-desktop-wallpaper:before {
    content: "\f556";
    }
        .fi-rs-detergent:before {
    content: "\f557";
    }
        .fi-rs-devices:before {
    content: "\f558";
    }
        .fi-rs-dewpoint:before {
    content: "\f559";
    }
        .fi-rs-dharmachakra:before {
    content: "\f55a";
    }
        .fi-rs-diagram-cells:before {
    content: "\f55b";
    }
        .fi-rs-diagram-lean-canvas:before {
    content: "\f55c";
    }
        .fi-rs-diagram-nested:before {
    content: "\f55d";
    }
        .fi-rs-diagram-next:before {
    content: "\f55e";
    }
        .fi-rs-diagram-predecessor:before {
    content: "\f55f";
    }
        .fi-rs-diagram-previous:before {
    content: "\f560";
    }
        .fi-rs-diagram-project:before {
    content: "\f561";
    }
        .fi-rs-diagram-sankey:before {
    content: "\f562";
    }
        .fi-rs-diagram-subtask:before {
    content: "\f563";
    }
        .fi-rs-diagram-successor:before {
    content: "\f564";
    }
        .fi-rs-diagram-venn:before {
    content: "\f565";
    }
        .fi-rs-dial-high:before {
    content: "\f566";
    }
        .fi-rs-dial-low:before {
    content: "\f567";
    }
        .fi-rs-dial-max:before {
    content: "\f568";
    }
        .fi-rs-dial-med-low:before {
    content: "\f569";
    }
        .fi-rs-dial-med:before {
    content: "\f56a";
    }
        .fi-rs-dial-min:before {
    content: "\f56b";
    }
        .fi-rs-dial-off:before {
    content: "\f56c";
    }
        .fi-rs-dial:before {
    content: "\f56d";
    }
        .fi-rs-dialogue-exchange:before {
    content: "\f56e";
    }
        .fi-rs-diamond-exclamation:before {
    content: "\f56f";
    }
        .fi-rs-diamond-turn-right:before {
    content: "\f570";
    }
        .fi-rs-diamond:before {
    content: "\f571";
    }
        .fi-rs-diary-bookmark-down:before {
    content: "\f572";
    }
        .fi-rs-diary-bookmarks:before {
    content: "\f573";
    }
        .fi-rs-diary-clasp:before {
    content: "\f574";
    }
        .fi-rs-dice-alt:before {
    content: "\f575";
    }
        .fi-rs-dice-d10:before {
    content: "\f576";
    }
        .fi-rs-dice-d12:before {
    content: "\f577";
    }
        .fi-rs-dice-d20:before {
    content: "\f578";
    }
        .fi-rs-dice-d4:before {
    content: "\f579";
    }
        .fi-rs-dice-d6:before {
    content: "\f57a";
    }
        .fi-rs-dice-d8:before {
    content: "\f57b";
    }
        .fi-rs-dice-four:before {
    content: "\f57c";
    }
        .fi-rs-dice-one:before {
    content: "\f57d";
    }
        .fi-rs-dice-six:before {
    content: "\f57e";
    }
        .fi-rs-dice-three:before {
    content: "\f57f";
    }
        .fi-rs-dice-two:before {
    content: "\f580";
    }
        .fi-rs-dice:before {
    content: "\f581";
    }
        .fi-rs-dictionary-alt:before {
    content: "\f582";
    }
        .fi-rs-dictionary-open:before {
    content: "\f583";
    }
        .fi-rs-dictionary:before {
    content: "\f584";
    }
        .fi-rs-digging:before {
    content: "\f585";
    }
        .fi-rs-digital-tachograph:before {
    content: "\f586";
    }
        .fi-rs-dinner:before {
    content: "\f587";
    }
        .fi-rs-diploma:before {
    content: "\f588";
    }
        .fi-rs-direction-signal-arrow:before {
    content: "\f589";
    }
        .fi-rs-direction-signal:before {
    content: "\f58a";
    }
        .fi-rs-disc-drive:before {
    content: "\f58b";
    }
        .fi-rs-disco-ball:before {
    content: "\f58c";
    }
        .fi-rs-discover:before {
    content: "\f58d";
    }
        .fi-rs-disease:before {
    content: "\f58e";
    }
        .fi-rs-disk:before {
    content: "\f58f";
    }
        .fi-rs-display-arrow-down:before {
    content: "\f590";
    }
        .fi-rs-display-chart-up:before {
    content: "\f591";
    }
        .fi-rs-display-code:before {
    content: "\f592";
    }
        .fi-rs-display-medical:before {
    content: "\f593";
    }
        .fi-rs-display-slash:before {
    content: "\f594";
    }
        .fi-rs-distribute-spacing-horizontal:before {
    content: "\f595";
    }
        .fi-rs-distribute-spacing-vertical:before {
    content: "\f596";
    }
        .fi-rs-divide:before {
    content: "\f597";
    }
        .fi-rs-dizzy:before {
    content: "\f598";
    }
        .fi-rs-dna:before {
    content: "\f599";
    }
        .fi-rs-do-not-disturb-doorknob:before {
    content: "\f59a";
    }
        .fi-rs-do-not-disturb:before {
    content: "\f59b";
    }
        .fi-rs-do-not-enter:before {
    content: "\f59c";
    }
        .fi-rs-doctor:before {
    content: "\f59d";
    }
        .fi-rs-document-circle-wrong:before {
    content: "\f59e";
    }
        .fi-rs-document-folder-gear:before {
    content: "\f59f";
    }
        .fi-rs-document-gavel:before {
    content: "\f5a0";
    }
        .fi-rs-document-gear:before {
    content: "\f5a1";
    }
        .fi-rs-document-paid:before {
    content: "\f5a2";
    }
        .fi-rs-document-signed:before {
    content: "\f5a3";
    }
        .fi-rs-document:before {
    content: "\f5a4";
    }
        .fi-rs-dog-bowl-empty:before {
    content: "\f5a5";
    }
        .fi-rs-dog-leashed:before {
    content: "\f5a6";
    }
        .fi-rs-dog:before {
    content: "\f5a7";
    }
        .fi-rs-dollar:before {
    content: "\f5a8";
    }
        .fi-rs-dolly-flatbed-alt:before {
    content: "\f5a9";
    }
        .fi-rs-dolly-flatbed-empty:before {
    content: "\f5aa";
    }
        .fi-rs-dolly-flatbed:before {
    content: "\f5ab";
    }
        .fi-rs-dolphin:before {
    content: "\f5ac";
    }
        .fi-rs-domino-effect:before {
    content: "\f5ad";
    }
        .fi-rs-donate:before {
    content: "\f5ae";
    }
        .fi-rs-dong-sign:before {
    content: "\f5af";
    }
        .fi-rs-donut:before {
    content: "\f5b0";
    }
        .fi-rs-door-closed:before {
    content: "\f5b1";
    }
        .fi-rs-door-open:before {
    content: "\f5b2";
    }
        .fi-rs-dorm-room:before {
    content: "\f5b3";
    }
        .fi-rs-dot-circle:before {
    content: "\f5b4";
    }
        .fi-rs-dot-pending:before {
    content: "\f5b5";
    }
        .fi-rs-dove:before {
    content: "\f5b6";
    }
        .fi-rs-down-from-bracket:before {
    content: "\f5b7";
    }
        .fi-rs-down-left-and-up-right-to-center:before {
    content: "\f5b8";
    }
        .fi-rs-down-left:before {
    content: "\f5b9";
    }
        .fi-rs-down-right:before {
    content: "\f5ba";
    }
        .fi-rs-down-to-line:before {
    content: "\f5bb";
    }
        .fi-rs-down:before {
    content: "\f5bc";
    }
        .fi-rs-download:before {
    content: "\f5bd";
    }
        .fi-rs-drafting-compass:before {
    content: "\f5be";
    }
        .fi-rs-dragon:before {
    content: "\f5bf";
    }
        .fi-rs-draw-polygon:before {
    content: "\f5c0";
    }
        .fi-rs-draw-square:before {
    content: "\f5c1";
    }
        .fi-rs-drawer-alt:before {
    content: "\f5c2";
    }
        .fi-rs-drawer-empty:before {
    content: "\f5c3";
    }
        .fi-rs-drawer:before {
    content: "\f5c4";
    }
        .fi-rs-dreidel:before {
    content: "\f5c5";
    }
        .fi-rs-drink-alt:before {
    content: "\f5c6";
    }
        .fi-rs-driver-man:before {
    content: "\f5c7";
    }
        .fi-rs-driver-woman:before {
    content: "\f5c8";
    }
        .fi-rs-drone-alt:before {
    content: "\f5c9";
    }
        .fi-rs-drone-front:before {
    content: "\f5ca";
    }
        .fi-rs-drone:before {
    content: "\f5cb";
    }
        .fi-rs-drop-down:before {
    content: "\f5cc";
    }
        .fi-rs-dropdown-select:before {
    content: "\f5cd";
    }
        .fi-rs-dropdown:before {
    content: "\f5ce";
    }
        .fi-rs-drum-steelpan:before {
    content: "\f5cf";
    }
        .fi-rs-drum:before {
    content: "\f5d0";
    }
        .fi-rs-drumstick-bite:before {
    content: "\f5d1";
    }
        .fi-rs-drumstick:before {
    content: "\f5d2";
    }
        .fi-rs-dryer-alt:before {
    content: "\f5d3";
    }
        .fi-rs-dryer:before {
    content: "\f5d4";
    }
        .fi-rs-duck:before {
    content: "\f5d5";
    }
        .fi-rs-dumbbell-fitness:before {
    content: "\f5d6";
    }
        .fi-rs-dumbbell-horizontal:before {
    content: "\f5d7";
    }
        .fi-rs-dumbbell-ray:before {
    content: "\f5d8";
    }
        .fi-rs-dumbbell-weightlifting:before {
    content: "\f5d9";
    }
        .fi-rs-dumpster-fire:before {
    content: "\f5da";
    }
        .fi-rs-dumpster:before {
    content: "\f5db";
    }
        .fi-rs-dungeon:before {
    content: "\f5dc";
    }
        .fi-rs-duplicate:before {
    content: "\f5dd";
    }
        .fi-rs-duration-alt:before {
    content: "\f5de";
    }
        .fi-rs-duration:before {
    content: "\f5df";
    }
        .fi-rs-e-learning:before {
    content: "\f5e0";
    }
        .fi-rs-e:before {
    content: "\f5e1";
    }
        .fi-rs-ear-deaf:before {
    content: "\f5e2";
    }
        .fi-rs-ear-muffs:before {
    content: "\f5e3";
    }
        .fi-rs-ear-sound:before {
    content: "\f5e4";
    }
        .fi-rs-ear:before {
    content: "\f5e5";
    }
        .fi-rs-earbuds:before {
    content: "\f5e6";
    }
        .fi-rs-earnings:before {
    content: "\f5e7";
    }
        .fi-rs-earth-africa:before {
    content: "\f5e8";
    }
        .fi-rs-earth-americas:before {
    content: "\f5e9";
    }
        .fi-rs-earth-asia:before {
    content: "\f5ea";
    }
        .fi-rs-earth-europa:before {
    content: "\f5eb";
    }
        .fi-rs-eclipse-alt:before {
    content: "\f5ec";
    }
        .fi-rs-eclipse:before {
    content: "\f5ed";
    }
        .fi-rs-edit-alt:before {
    content: "\f5ee";
    }
        .fi-rs-edit-message:before {
    content: "\f5ef";
    }
        .fi-rs-edit:before {
    content: "\f5f0";
    }
        .fi-rs-effect:before {
    content: "\f5f1";
    }
        .fi-rs-egg-fried:before {
    content: "\f5f2";
    }
        .fi-rs-egg:before {
    content: "\f5f3";
    }
        .fi-rs-eject:before {
    content: "\f5f4";
    }
        .fi-rs-elephant:before {
    content: "\f5f5";
    }
        .fi-rs-elevator:before {
    content: "\f5f6";
    }
        .fi-rs-email-pending:before {
    content: "\f5f7";
    }
        .fi-rs-email-refresh:before {
    content: "\f5f8";
    }
        .fi-rs-emergency-call:before {
    content: "\f5f9";
    }
        .fi-rs-employee-alt:before {
    content: "\f5fa";
    }
        .fi-rs-employee-handbook:before {
    content: "\f5fb";
    }
        .fi-rs-employee-man-alt:before {
    content: "\f5fc";
    }
        .fi-rs-employee-man:before {
    content: "\f5fd";
    }
        .fi-rs-employees-woman-man:before {
    content: "\f5fe";
    }
        .fi-rs-employees:before {
    content: "\f5ff";
    }
        .fi-rs-empty-set:before {
    content: "\f600";
    }
        .fi-rs-endless-loop:before {
    content: "\f601";
    }
        .fi-rs-engine-warning:before {
    content: "\f602";
    }
        .fi-rs-engine:before {
    content: "\f603";
    }
        .fi-rs-english:before {
    content: "\f604";
    }
        .fi-rs-enter:before {
    content: "\f605";
    }
        .fi-rs-envelope-ban:before {
    content: "\f606";
    }
        .fi-rs-envelope-bulk:before {
    content: "\f607";
    }
        .fi-rs-envelope-dot:before {
    content: "\f608";
    }
        .fi-rs-envelope-download:before {
    content: "\f609";
    }
        .fi-rs-envelope-heart:before {
    content: "\f60a";
    }
        .fi-rs-envelope-marker:before {
    content: "\f60b";
    }
        .fi-rs-envelope-open-dollar:before {
    content: "\f60c";
    }
        .fi-rs-envelope-open-text:before {
    content: "\f60d";
    }
        .fi-rs-envelope-open:before {
    content: "\f60e";
    }
        .fi-rs-envelope-plus:before {
    content: "\f60f";
    }
        .fi-rs-envelope:before {
    content: "\f610";
    }
        .fi-rs-envelopes:before {
    content: "\f611";
    }
        .fi-rs-equality:before {
    content: "\f612";
    }
        .fi-rs-equals:before {
    content: "\f613";
    }
        .fi-rs-equestrian-statue:before {
    content: "\f614";
    }
        .fi-rs-eraser:before {
    content: "\f615";
    }
        .fi-rs-error-camera:before {
    content: "\f616";
    }
        .fi-rs-escalator:before {
    content: "\f617";
    }
        .fi-rs-ethernet:before {
    content: "\f618";
    }
        .fi-rs-euro:before {
    content: "\f619";
    }
        .fi-rs-europe-flag:before {
    content: "\f61a";
    }
        .fi-rs-european-union:before {
    content: "\f61b";
    }
        .fi-rs-excavator:before {
    content: "\f61c";
    }
        .fi-rs-exchange-alt:before {
    content: "\f61d";
    }
        .fi-rs-exchange-cryptocurrency:before {
    content: "\f61e";
    }
        .fi-rs-exchange:before {
    content: "\f61f";
    }
        .fi-rs-exclamation:before {
    content: "\f620";
    }
        .fi-rs-exit-alt:before {
    content: "\f621";
    }
        .fi-rs-exit:before {
    content: "\f622";
    }
        .fi-rs-expand-arrows-alt:before {
    content: "\f623";
    }
        .fi-rs-expand-arrows:before {
    content: "\f624";
    }
        .fi-rs-expand:before {
    content: "\f625";
    }
        .fi-rs-expense-bill:before {
    content: "\f626";
    }
        .fi-rs-expense:before {
    content: "\f627";
    }
        .fi-rs-explosion:before {
    content: "\f628";
    }
        .fi-rs-external-hard-drive:before {
    content: "\f629";
    }
        .fi-rs-external-world:before {
    content: "\f62a";
    }
        .fi-rs-eye-alert:before {
    content: "\f62b";
    }
        .fi-rs-eye-dropper-half:before {
    content: "\f62c";
    }
        .fi-rs-eye-dropper:before {
    content: "\f62d";
    }
        .fi-rs-eye:before {
    content: "\f62e";
    }
        .fi-rs-eyes:before {
    content: "\f62f";
    }
        .fi-rs-f:before {
    content: "\f630";
    }
        .fi-rs-fabric:before {
    content: "\f631";
    }
        .fi-rs-face-angry-horns:before {
    content: "\f632";
    }
        .fi-rs-face-anguished:before {
    content: "\f633";
    }
        .fi-rs-face-anxious-sweat:before {
    content: "\f634";
    }
        .fi-rs-face-astonished:before {
    content: "\f635";
    }
        .fi-rs-face-awesome:before {
    content: "\f636";
    }
        .fi-rs-face-beam-hand-over-mouth:before {
    content: "\f637";
    }
        .fi-rs-face-confounded:before {
    content: "\f638";
    }
        .fi-rs-face-confused:before {
    content: "\f639";
    }
        .fi-rs-face-cowboy-hat:before {
    content: "\f63a";
    }
        .fi-rs-face-disappointed:before {
    content: "\f63b";
    }
        .fi-rs-face-disguise:before {
    content: "\f63c";
    }
        .fi-rs-face-downcast-sweat:before {
    content: "\f63d";
    }
        .fi-rs-face-drooling:before {
    content: "\f63e";
    }
        .fi-rs-face-explode:before {
    content: "\f63f";
    }
        .fi-rs-face-expressionless:before {
    content: "\f640";
    }
        .fi-rs-face-eyes-xmarks:before {
    content: "\f641";
    }
        .fi-rs-face-fearful:before {
    content: "\f642";
    }
        .fi-rs-face-glasses:before {
    content: "\f643";
    }
        .fi-rs-face-grin-tongue-wink:before {
    content: "\f644";
    }
        .fi-rs-face-hand-yawn:before {
    content: "\f645";
    }
        .fi-rs-face-head-bandage:before {
    content: "\f646";
    }
        .fi-rs-face-hushed:before {
    content: "\f647";
    }
        .fi-rs-face-icicles:before {
    content: "\f648";
    }
        .fi-rs-face-lying:before {
    content: "\f649";
    }
        .fi-rs-face-mask:before {
    content: "\f64a";
    }
        .fi-rs-face-monocle:before {
    content: "\f64b";
    }
        .fi-rs-face-nauseated:before {
    content: "\f64c";
    }
        .fi-rs-face-nose-steam:before {
    content: "\f64d";
    }
        .fi-rs-face-party:before {
    content: "\f64e";
    }
        .fi-rs-face-pensive:before {
    content: "\f64f";
    }
        .fi-rs-face-persevering:before {
    content: "\f650";
    }
        .fi-rs-face-pleading:before {
    content: "\f651";
    }
        .fi-rs-face-raised-eyebrow:before {
    content: "\f652";
    }
        .fi-rs-face-relieved:before {
    content: "\f653";
    }
        .fi-rs-face-sad-sweat:before {
    content: "\f654";
    }
        .fi-rs-face-scream:before {
    content: "\f655";
    }
        .fi-rs-face-shush:before {
    content: "\f656";
    }
        .fi-rs-face-sleeping:before {
    content: "\f657";
    }
        .fi-rs-face-sleepy:before {
    content: "\f658";
    }
        .fi-rs-face-smile-halo:before {
    content: "\f659";
    }
        .fi-rs-face-smile-hearts:before {
    content: "\f65a";
    }
        .fi-rs-face-smile-horns:before {
    content: "\f65b";
    }
        .fi-rs-face-smile-tear:before {
    content: "\f65c";
    }
        .fi-rs-face-smile-tongue:before {
    content: "\f65d";
    }
        .fi-rs-face-smile-upside-down:before {
    content: "\f65e";
    }
        .fi-rs-face-smiling-hands:before {
    content: "\f65f";
    }
        .fi-rs-face-smirking:before {
    content: "\f660";
    }
        .fi-rs-face-sunglasses-alt:before {
    content: "\f661";
    }
        .fi-rs-face-sunglasses:before {
    content: "\f662";
    }
        .fi-rs-face-swear:before {
    content: "\f663";
    }
        .fi-rs-face-thermometer:before {
    content: "\f664";
    }
        .fi-rs-face-thinking:before {
    content: "\f665";
    }
        .fi-rs-face-tissue:before {
    content: "\f666";
    }
        .fi-rs-face-tongue-money:before {
    content: "\f667";
    }
        .fi-rs-face-tongue-sweat:before {
    content: "\f668";
    }
        .fi-rs-face-unamused:before {
    content: "\f669";
    }
        .fi-rs-face-viewfinder:before {
    content: "\f66a";
    }
        .fi-rs-face-vomit:before {
    content: "\f66b";
    }
        .fi-rs-face-weary:before {
    content: "\f66c";
    }
        .fi-rs-face-woozy:before {
    content: "\f66d";
    }
        .fi-rs-face-worried:before {
    content: "\f66e";
    }
        .fi-rs-face-zany:before {
    content: "\f66f";
    }
        .fi-rs-face-zipper:before {
    content: "\f670";
    }
        .fi-rs-fail:before {
    content: "\f671";
    }
        .fi-rs-falafel:before {
    content: "\f672";
    }
        .fi-rs-family-dress:before {
    content: "\f673";
    }
        .fi-rs-family-pants:before {
    content: "\f674";
    }
        .fi-rs-family:before {
    content: "\f675";
    }
        .fi-rs-fan-table:before {
    content: "\f676";
    }
        .fi-rs-fan:before {
    content: "\f677";
    }
        .fi-rs-farfalle:before {
    content: "\f678";
    }
        .fi-rs-farm:before {
    content: "\f679";
    }
        .fi-rs-faucet-drip:before {
    content: "\f67a";
    }
        .fi-rs-faucet:before {
    content: "\f67b";
    }
        .fi-rs-fax:before {
    content: "\f67c";
    }
        .fi-rs-feather-pointed:before {
    content: "\f67d";
    }
        .fi-rs-feather:before {
    content: "\f67e";
    }
        .fi-rs-features-alt:before {
    content: "\f67f";
    }
        .fi-rs-features:before {
    content: "\f680";
    }
        .fi-rs-fee-receipt:before {
    content: "\f681";
    }
        .fi-rs-fee:before {
    content: "\f682";
    }
        .fi-rs-feedback-alt:before {
    content: "\f683";
    }
        .fi-rs-feedback-cycle-loop:before {
    content: "\f684";
    }
        .fi-rs-feedback-hand:before {
    content: "\f685";
    }
        .fi-rs-feedback-review:before {
    content: "\f686";
    }
        .fi-rs-feedback:before {
    content: "\f687";
    }
        .fi-rs-fence:before {
    content: "\f688";
    }
        .fi-rs-ferris-wheel:before {
    content: "\f689";
    }
        .fi-rs-field-hockey:before {
    content: "\f68a";
    }
        .fi-rs-fighter-jet:before {
    content: "\f68b";
    }
        .fi-rs-file-ai:before {
    content: "\f68c";
    }
        .fi-rs-file-audio:before {
    content: "\f68d";
    }
        .fi-rs-file-binary:before {
    content: "\f68e";
    }
        .fi-rs-file-chart-line:before {
    content: "\f68f";
    }
        .fi-rs-file-chart-pie:before {
    content: "\f690";
    }
        .fi-rs-file-circle-info:before {
    content: "\f691";
    }
        .fi-rs-file-cloud:before {
    content: "\f692";
    }
        .fi-rs-file-code:before {
    content: "\f693";
    }
        .fi-rs-file-csv:before {
    content: "\f694";
    }
        .fi-rs-file-download:before {
    content: "\f695";
    }
        .fi-rs-file-edit:before {
    content: "\f696";
    }
        .fi-rs-file-eps:before {
    content: "\f697";
    }
        .fi-rs-file-excel:before {
    content: "\f698";
    }
        .fi-rs-file-exclamation:before {
    content: "\f699";
    }
        .fi-rs-file-export:before {
    content: "\f69a";
    }
        .fi-rs-file-image:before {
    content: "\f69b";
    }
        .fi-rs-file-import:before {
    content: "\f69c";
    }
        .fi-rs-file-invoice-dollar:before {
    content: "\f69d";
    }
        .fi-rs-file-invoice:before {
    content: "\f69e";
    }
        .fi-rs-file-loop:before {
    content: "\f69f";
    }
        .fi-rs-file-medical-alt:before {
    content: "\f6a0";
    }
        .fi-rs-file-medical:before {
    content: "\f6a1";
    }
        .fi-rs-file-minus:before {
    content: "\f6a2";
    }
        .fi-rs-file-pdf:before {
    content: "\f6a3";
    }
        .fi-rs-file-powerpoint:before {
    content: "\f6a4";
    }
        .fi-rs-file-prescription:before {
    content: "\f6a5";
    }
        .fi-rs-file-psd:before {
    content: "\f6a6";
    }
        .fi-rs-file-recycle:before {
    content: "\f6a7";
    }
        .fi-rs-file-signature:before {
    content: "\f6a8";
    }
        .fi-rs-file-spreadsheet:before {
    content: "\f6a9";
    }
        .fi-rs-file-upload:before {
    content: "\f6aa";
    }
        .fi-rs-file-user:before {
    content: "\f6ab";
    }
        .fi-rs-file-video:before {
    content: "\f6ac";
    }
        .fi-rs-file-word:before {
    content: "\f6ad";
    }
        .fi-rs-file-xls:before {
    content: "\f6ae";
    }
        .fi-rs-file-zip-alt:before {
    content: "\f6af";
    }
        .fi-rs-file-zip-save:before {
    content: "\f6b0";
    }
        .fi-rs-file-zipper:before {
    content: "\f6b1";
    }
        .fi-rs-file:before {
    content: "\f6b2";
    }
        .fi-rs-files-medical:before {
    content: "\f6b3";
    }
        .fi-rs-fill:before {
    content: "\f6b4";
    }
        .fi-rs-film-canister:before {
    content: "\f6b5";
    }
        .fi-rs-film-slash:before {
    content: "\f6b6";
    }
        .fi-rs-film:before {
    content: "\f6b7";
    }
        .fi-rs-films:before {
    content: "\f6b8";
    }
        .fi-rs-filter-list:before {
    content: "\f6b9";
    }
        .fi-rs-filter-slash:before {
    content: "\f6ba";
    }
        .fi-rs-filter:before {
    content: "\f6bb";
    }
        .fi-rs-filters:before {
    content: "\f6bc";
    }
        .fi-rs-finger-droplet:before {
    content: "\f6bd";
    }
        .fi-rs-fingerprint-remove:before {
    content: "\f6be";
    }
        .fi-rs-fingerprint-security-risk:before {
    content: "\f6bf";
    }
        .fi-rs-fingerprint-verified:before {
    content: "\f6c0";
    }
        .fi-rs-fingerprint:before {
    content: "\f6c1";
    }
        .fi-rs-fire-burner:before {
    content: "\f6c2";
    }
        .fi-rs-fire-extinguisher:before {
    content: "\f6c3";
    }
        .fi-rs-fire-flame-curved:before {
    content: "\f6c4";
    }
        .fi-rs-fire-flame-simple:before {
    content: "\f6c5";
    }
        .fi-rs-fire-hydrant:before {
    content: "\f6c6";
    }
        .fi-rs-fire-smoke:before {
    content: "\f6c7";
    }
        .fi-rs-fireplace:before {
    content: "\f6c8";
    }
        .fi-rs-first-award:before {
    content: "\f6c9";
    }
        .fi-rs-first-laurel:before {
    content: "\f6ca";
    }
        .fi-rs-first-medal:before {
    content: "\f6cb";
    }
        .fi-rs-first:before {
    content: "\f6cc";
    }
        .fi-rs-fish-bones:before {
    content: "\f6cd";
    }
        .fi-rs-fish-cooked:before {
    content: "\f6ce";
    }
        .fi-rs-fish:before {
    content: "\f6cf";
    }
        .fi-rs-fishing-rod:before {
    content: "\f6d0";
    }
        .fi-rs-fist-move:before {
    content: "\f6d1";
    }
        .fi-rs-flag-alt:before {
    content: "\f6d2";
    }
        .fi-rs-flag-checkered:before {
    content: "\f6d3";
    }
        .fi-rs-flag-usa:before {
    content: "\f6d4";
    }
        .fi-rs-flag:before {
    content: "\f6d5";
    }
        .fi-rs-flame:before {
    content: "\f6d6";
    }
        .fi-rs-flashlight:before {
    content: "\f6d7";
    }
        .fi-rs-flask-gear:before {
    content: "\f6d8";
    }
        .fi-rs-flask-poison:before {
    content: "\f6d9";
    }
        .fi-rs-flask-potion:before {
    content: "\f6da";
    }
        .fi-rs-flask:before {
    content: "\f6db";
    }
        .fi-rs-flatbread-stuffed:before {
    content: "\f6dc";
    }
        .fi-rs-flatbread:before {
    content: "\f6dd";
    }
        .fi-rs-flip-horizontal:before {
    content: "\f6de";
    }
        .fi-rs-floor-alt:before {
    content: "\f6df";
    }
        .fi-rs-floor-layer:before {
    content: "\f6e0";
    }
        .fi-rs-floor:before {
    content: "\f6e1";
    }
        .fi-rs-floppy-disk-circle-arrow-right:before {
    content: "\f6e2";
    }
        .fi-rs-floppy-disk-circle-xmark:before {
    content: "\f6e3";
    }
        .fi-rs-floppy-disk-pen:before {
    content: "\f6e4";
    }
        .fi-rs-floppy-disks:before {
    content: "\f6e5";
    }
        .fi-rs-florin-sign:before {
    content: "\f6e6";
    }
        .fi-rs-flowchart:before {
    content: "\f6e7";
    }
        .fi-rs-flower-bouquet:before {
    content: "\f6e8";
    }
        .fi-rs-flower-butterfly:before {
    content: "\f6e9";
    }
        .fi-rs-flower-daffodil:before {
    content: "\f6ea";
    }
        .fi-rs-flower-tulip:before {
    content: "\f6eb";
    }
        .fi-rs-flower:before {
    content: "\f6ec";
    }
        .fi-rs-flushed:before {
    content: "\f6ed";
    }
        .fi-rs-flute:before {
    content: "\f6ee";
    }
        .fi-rs-flux-capacitor:before {
    content: "\f6ef";
    }
        .fi-rs-fly-insect:before {
    content: "\f6f0";
    }
        .fi-rs-flying-disc:before {
    content: "\f6f1";
    }
        .fi-rs-fog:before {
    content: "\f6f2";
    }
        .fi-rs-folder-archive:before {
    content: "\f6f3";
    }
        .fi-rs-folder-camera:before {
    content: "\f6f4";
    }
        .fi-rs-folder-directory:before {
    content: "\f6f5";
    }
        .fi-rs-folder-download:before {
    content: "\f6f6";
    }
        .fi-rs-folder-fire:before {
    content: "\f6f7";
    }
        .fi-rs-folder-gear:before {
    content: "\f6f8";
    }
        .fi-rs-folder-heart:before {
    content: "\f6f9";
    }
        .fi-rs-folder-loop:before {
    content: "\f6fa";
    }
        .fi-rs-folder-math:before {
    content: "\f6fb";
    }
        .fi-rs-folder-minus:before {
    content: "\f6fc";
    }
        .fi-rs-folder-music:before {
    content: "\f6fd";
    }
        .fi-rs-folder-open:before {
    content: "\f6fe";
    }
        .fi-rs-folder-plus-circle:before {
    content: "\f6ff";
    }
        .fi-rs-folder-times:before {
    content: "\f700";
    }
        .fi-rs-folder-tree:before {
    content: "\f701";
    }
        .fi-rs-folder-upload:before {
    content: "\f702";
    }
        .fi-rs-folder-xmark-circle:before {
    content: "\f703";
    }
        .fi-rs-folder-xmark:before {
    content: "\f704";
    }
        .fi-rs-folder:before {
    content: "\f705";
    }
        .fi-rs-folders:before {
    content: "\f706";
    }
        .fi-rs-follow-folder:before {
    content: "\f707";
    }
        .fi-rs-followcollection:before {
    content: "\f708";
    }
        .fi-rs-following:before {
    content: "\f709";
    }
        .fi-rs-fondue-pot:before {
    content: "\f70a";
    }
        .fi-rs-football:before {
    content: "\f70b";
    }
        .fi-rs-footprint:before {
    content: "\f70c";
    }
        .fi-rs-foreign-language-audio:before {
    content: "\f70d";
    }
        .fi-rs-fork-spaghetti:before {
    content: "\f70e";
    }
        .fi-rs-fork:before {
    content: "\f70f";
    }
        .fi-rs-forklift:before {
    content: "\f710";
    }
        .fi-rs-form:before {
    content: "\f711";
    }
        .fi-rs-fort:before {
    content: "\f712";
    }
        .fi-rs-forward-fast:before {
    content: "\f713";
    }
        .fi-rs-forward:before {
    content: "\f714";
    }
        .fi-rs-fox:before {
    content: "\f715";
    }
        .fi-rs-frame:before {
    content: "\f716";
    }
        .fi-rs-franc-sign:before {
    content: "\f717";
    }
        .fi-rs-free-delivery:before {
    content: "\f718";
    }
        .fi-rs-free:before {
    content: "\f719";
    }
        .fi-rs-french-fries:before {
    content: "\f71a";
    }
        .fi-rs-french:before {
    content: "\f71b";
    }
        .fi-rs-friday:before {
    content: "\f71c";
    }
        .fi-rs-frog:before {
    content: "\f71d";
    }
        .fi-rs-frown:before {
    content: "\f71e";
    }
        .fi-rs-ftp:before {
    content: "\f71f";
    }
        .fi-rs-fuel-gauge:before {
    content: "\f720";
    }
        .fi-rs-function-process:before {
    content: "\f721";
    }
        .fi-rs-function-square:before {
    content: "\f722";
    }
        .fi-rs-function:before {
    content: "\f723";
    }
        .fi-rs-funnel-dollar:before {
    content: "\f724";
    }
        .fi-rs-fusilli:before {
    content: "\f725";
    }
        .fi-rs-g:before {
    content: "\f726";
    }
        .fi-rs-galaxy-alt:before {
    content: "\f727";
    }
        .fi-rs-galaxy-planet:before {
    content: "\f728";
    }
        .fi-rs-galaxy-star:before {
    content: "\f729";
    }
        .fi-rs-galaxy:before {
    content: "\f72a";
    }
        .fi-rs-gallery-thumbnails:before {
    content: "\f72b";
    }
        .fi-rs-gallery:before {
    content: "\f72c";
    }
        .fi-rs-game-board-alt:before {
    content: "\f72d";
    }
        .fi-rs-gamepad:before {
    content: "\f72e";
    }
        .fi-rs-garage-car:before {
    content: "\f72f";
    }
        .fi-rs-garage-open:before {
    content: "\f730";
    }
        .fi-rs-garage:before {
    content: "\f731";
    }
        .fi-rs-garlic-alt:before {
    content: "\f732";
    }
        .fi-rs-garlic:before {
    content: "\f733";
    }
        .fi-rs-gas-pump-alt:before {
    content: "\f734";
    }
        .fi-rs-gas-pump-slash:before {
    content: "\f735";
    }
        .fi-rs-gas-pump:before {
    content: "\f736";
    }
        .fi-rs-gauge-circle-bolt:before {
    content: "\f737";
    }
        .fi-rs-gauge-circle-minus:before {
    content: "\f738";
    }
        .fi-rs-gauge-circle-plus:before {
    content: "\f739";
    }
        .fi-rs-gauze-bandage:before {
    content: "\f73a";
    }
        .fi-rs-gavel:before {
    content: "\f73b";
    }
        .fi-rs-gay-couple:before {
    content: "\f73c";
    }
        .fi-rs-gears:before {
    content: "\f73d";
    }
        .fi-rs-gem:before {
    content: "\f73e";
    }
        .fi-rs-general:before {
    content: "\f73f";
    }
        .fi-rs-german:before {
    content: "\f740";
    }
        .fi-rs-ghost:before {
    content: "\f741";
    }
        .fi-rs-gif-square:before {
    content: "\f742";
    }
        .fi-rs-gif:before {
    content: "\f743";
    }
        .fi-rs-gift-box-benefits:before {
    content: "\f744";
    }
        .fi-rs-gift-card:before {
    content: "\f745";
    }
        .fi-rs-gift:before {
    content: "\f746";
    }
        .fi-rs-gifts:before {
    content: "\f747";
    }
        .fi-rs-gingerbread-man:before {
    content: "\f748";
    }
        .fi-rs-glass-champagne:before {
    content: "\f749";
    }
        .fi-rs-glass-cheers:before {
    content: "\f74a";
    }
        .fi-rs-glass-citrus:before {
    content: "\f74b";
    }
        .fi-rs-glass-empty:before {
    content: "\f74c";
    }
        .fi-rs-glass-half:before {
    content: "\f74d";
    }
        .fi-rs-glass-water-droplet:before {
    content: "\f74e";
    }
        .fi-rs-glass-whiskey-rocks:before {
    content: "\f74f";
    }
        .fi-rs-glass-whiskey:before {
    content: "\f750";
    }
        .fi-rs-glass:before {
    content: "\f751";
    }
        .fi-rs-glasses:before {
    content: "\f752";
    }
        .fi-rs-globe-alt:before {
    content: "\f753";
    }
        .fi-rs-globe-pointer:before {
    content: "\f754";
    }
        .fi-rs-globe-snow:before {
    content: "\f755";
    }
        .fi-rs-globe:before {
    content: "\f756";
    }
        .fi-rs-goal-net:before {
    content: "\f757";
    }
        .fi-rs-golf-ball:before {
    content: "\f758";
    }
        .fi-rs-golf-club:before {
    content: "\f759";
    }
        .fi-rs-golf-hole:before {
    content: "\f75a";
    }
        .fi-rs-golf:before {
    content: "\f75b";
    }
        .fi-rs-gopuram:before {
    content: "\f75c";
    }
        .fi-rs-government-budget:before {
    content: "\f75d";
    }
        .fi-rs-government-flag:before {
    content: "\f75e";
    }
        .fi-rs-government-user:before {
    content: "\f75f";
    }
        .fi-rs-gps-navigation:before {
    content: "\f760";
    }
        .fi-rs-graduation-cap:before {
    content: "\f761";
    }
        .fi-rs-gramophone:before {
    content: "\f762";
    }
        .fi-rs-grape:before {
    content: "\f763";
    }
        .fi-rs-graph-curve:before {
    content: "\f764";
    }
        .fi-rs-graphic-style:before {
    content: "\f765";
    }
        .fi-rs-graphic-tablet:before {
    content: "\f766";
    }
        .fi-rs-grate-droplet:before {
    content: "\f767";
    }
        .fi-rs-grate:before {
    content: "\f768";
    }
        .fi-rs-greater-than-equal:before {
    content: "\f769";
    }
        .fi-rs-greater-than:before {
    content: "\f76a";
    }
        .fi-rs-greek-helmet:before {
    content: "\f76b";
    }
        .fi-rs-grid-alt:before {
    content: "\f76c";
    }
        .fi-rs-grid-dividers:before {
    content: "\f76d";
    }
        .fi-rs-grid:before {
    content: "\f76e";
    }
        .fi-rs-grill-hot-alt:before {
    content: "\f76f";
    }
        .fi-rs-grill:before {
    content: "\f770";
    }
        .fi-rs-grimace:before {
    content: "\f771";
    }
        .fi-rs-grin-alt:before {
    content: "\f772";
    }
        .fi-rs-grin-beam-sweat:before {
    content: "\f773";
    }
        .fi-rs-grin-beam:before {
    content: "\f774";
    }
        .fi-rs-grin-hearts:before {
    content: "\f775";
    }
        .fi-rs-grin-squint-tears:before {
    content: "\f776";
    }
        .fi-rs-grin-squint:before {
    content: "\f777";
    }
        .fi-rs-grin-stars:before {
    content: "\f778";
    }
        .fi-rs-grin-tears:before {
    content: "\f779";
    }
        .fi-rs-grin-tongue-squint:before {
    content: "\f77a";
    }
        .fi-rs-grin-tongue-wink:before {
    content: "\f77b";
    }
        .fi-rs-grin-tongue:before {
    content: "\f77c";
    }
        .fi-rs-grin-wink:before {
    content: "\f77d";
    }
        .fi-rs-grin:before {
    content: "\f77e";
    }
        .fi-rs-grip-dots-vertical:before {
    content: "\f77f";
    }
        .fi-rs-grip-dots:before {
    content: "\f780";
    }
        .fi-rs-grip-horizontal:before {
    content: "\f781";
    }
        .fi-rs-grip-lines-vertical:before {
    content: "\f782";
    }
        .fi-rs-grip-lines:before {
    content: "\f783";
    }
        .fi-rs-grip-vertical:before {
    content: "\f784";
    }
        .fi-rs-grocery-bag:before {
    content: "\f785";
    }
        .fi-rs-grocery-basket:before {
    content: "\f786";
    }
        .fi-rs-group-arrows-rotate:before {
    content: "\f787";
    }
        .fi-rs-group-call:before {
    content: "\f788";
    }
        .fi-rs-group-community-social-media:before {
    content: "\f789";
    }
        .fi-rs-growth-chart-invest:before {
    content: "\f78a";
    }
        .fi-rs-guarani-sign:before {
    content: "\f78b";
    }
        .fi-rs-guide-alt:before {
    content: "\f78c";
    }
        .fi-rs-guide:before {
    content: "\f78d";
    }
        .fi-rs-guitar-electric:before {
    content: "\f78e";
    }
        .fi-rs-guitar:before {
    content: "\f78f";
    }
        .fi-rs-guitars:before {
    content: "\f790";
    }
        .fi-rs-gun-squirt:before {
    content: "\f791";
    }
        .fi-rs-gym:before {
    content: "\f792";
    }
        .fi-rs-h-square:before {
    content: "\f793";
    }
        .fi-rs-h:before {
    content: "\f794";
    }
        .fi-rs-h1:before {
    content: "\f795";
    }
        .fi-rs-h2:before {
    content: "\f796";
    }
        .fi-rs-h3:before {
    content: "\f797";
    }
        .fi-rs-h4:before {
    content: "\f798";
    }
        .fi-rs-hair-clipper:before {
    content: "\f799";
    }
        .fi-rs-hamburger-soda:before {
    content: "\f79a";
    }
        .fi-rs-hamburger:before {
    content: "\f79b";
    }
        .fi-rs-hammer-brush:before {
    content: "\f79c";
    }
        .fi-rs-hammer-crash:before {
    content: "\f79d";
    }
        .fi-rs-hammer-war:before {
    content: "\f79e";
    }
        .fi-rs-hammer:before {
    content: "\f79f";
    }
        .fi-rs-hamsa:before {
    content: "\f7a0";
    }
        .fi-rs-hand-back-fist:before {
    content: "\f7a1";
    }
        .fi-rs-hand-back-point-down:before {
    content: "\f7a2";
    }
        .fi-rs-hand-back-point-left:before {
    content: "\f7a3";
    }
        .fi-rs-hand-back-point-ribbon:before {
    content: "\f7a4";
    }
        .fi-rs-hand-back-point-right:before {
    content: "\f7a5";
    }
        .fi-rs-hand-bandage-wound:before {
    content: "\f7a6";
    }
        .fi-rs-hand-bill:before {
    content: "\f7a7";
    }
        .fi-rs-hand-dots:before {
    content: "\f7a8";
    }
        .fi-rs-hand-fingers-crossed:before {
    content: "\f7a9";
    }
        .fi-rs-hand-fist:before {
    content: "\f7aa";
    }
        .fi-rs-hand-heart:before {
    content: "\f7ab";
    }
        .fi-rs-hand-holding-box:before {
    content: "\f7ac";
    }
        .fi-rs-hand-holding-document:before {
    content: "\f7ad";
    }
        .fi-rs-hand-holding-droplet:before {
    content: "\f7ae";
    }
        .fi-rs-hand-holding-heart:before {
    content: "\f7af";
    }
        .fi-rs-hand-holding-magic:before {
    content: "\f7b0";
    }
        .fi-rs-hand-holding-medical:before {
    content: "\f7b1";
    }
        .fi-rs-hand-holding-seeding:before {
    content: "\f7b2";
    }
        .fi-rs-hand-holding-skull:before {
    content: "\f7b3";
    }
        .fi-rs-hand-holding-usd:before {
    content: "\f7b4";
    }
        .fi-rs-hand-holding-water:before {
    content: "\f7b5";
    }
        .fi-rs-hand-horns:before {
    content: "\f7b6";
    }
        .fi-rs-hand-lizard:before {
    content: "\f7b7";
    }
        .fi-rs-hand-love:before {
    content: "\f7b8";
    }
        .fi-rs-hand-middle-finger:before {
    content: "\f7b9";
    }
        .fi-rs-hand-paper:before {
    content: "\f7ba";
    }
        .fi-rs-hand-peace:before {
    content: "\f7bb";
    }
        .fi-rs-hand-point-ribbon:before {
    content: "\f7bc";
    }
        .fi-rs-hand-present:before {
    content: "\f7bd";
    }
        .fi-rs-hand-scissors:before {
    content: "\f7be";
    }
        .fi-rs-hand-sparkles:before {
    content: "\f7bf";
    }
        .fi-rs-hand-spock:before {
    content: "\f7c0";
    }
        .fi-rs-hand-wave:before {
    content: "\f7c1";
    }
        .fi-rs-hand:before {
    content: "\f7c2";
    }
        .fi-rs-handcuffs:before {
    content: "\f7c3";
    }
        .fi-rs-handmade:before {
    content: "\f7c4";
    }
        .fi-rs-hands-bubbles:before {
    content: "\f7c5";
    }
        .fi-rs-hands-clapping:before {
    content: "\f7c6";
    }
        .fi-rs-hands-heart:before {
    content: "\f7c7";
    }
        .fi-rs-hands-holding-diamond:before {
    content: "\f7c8";
    }
        .fi-rs-hands-holding:before {
    content: "\f7c9";
    }
        .fi-rs-hands-together-heart:before {
    content: "\f7ca";
    }
        .fi-rs-hands-usd:before {
    content: "\f7cb";
    }
        .fi-rs-handshake-angle:before {
    content: "\f7cc";
    }
        .fi-rs-handshake-deal-loan:before {
    content: "\f7cd";
    }
        .fi-rs-handshake-house:before {
    content: "\f7ce";
    }
        .fi-rs-handshake-simple-slash:before {
    content: "\f7cf";
    }
        .fi-rs-handshake-trust:before {
    content: "\f7d0";
    }
        .fi-rs-handshake:before {
    content: "\f7d1";
    }
        .fi-rs-happy:before {
    content: "\f7d2";
    }
        .fi-rs-hard-hat:before {
    content: "\f7d3";
    }
        .fi-rs-hashtag-lock:before {
    content: "\f7d4";
    }
        .fi-rs-hastag:before {
    content: "\f7d5";
    }
        .fi-rs-hat-beach:before {
    content: "\f7d6";
    }
        .fi-rs-hat-birthday:before {
    content: "\f7d7";
    }
        .fi-rs-hat-chef:before {
    content: "\f7d8";
    }
        .fi-rs-hat-cowboy-side:before {
    content: "\f7d9";
    }
        .fi-rs-hat-cowboy:before {
    content: "\f7da";
    }
        .fi-rs-hat-santa:before {
    content: "\f7db";
    }
        .fi-rs-hat-winter:before {
    content: "\f7dc";
    }
        .fi-rs-hat-witch:before {
    content: "\f7dd";
    }
        .fi-rs-hat-wizard:before {
    content: "\f7de";
    }
        .fi-rs-hdd:before {
    content: "\f7df";
    }
        .fi-rs-head-side-brain:before {
    content: "\f7e0";
    }
        .fi-rs-head-side-cough-slash:before {
    content: "\f7e1";
    }
        .fi-rs-head-side-cough:before {
    content: "\f7e2";
    }
        .fi-rs-head-side-headphones:before {
    content: "\f7e3";
    }
        .fi-rs-head-side-heart:before {
    content: "\f7e4";
    }
        .fi-rs-head-side-mask:before {
    content: "\f7e5";
    }
        .fi-rs-head-side-medical:before {
    content: "\f7e6";
    }
        .fi-rs-head-side-thinking:before {
    content: "\f7e7";
    }
        .fi-rs-head-side-virus:before {
    content: "\f7e8";
    }
        .fi-rs-head-side:before {
    content: "\f7e9";
    }
        .fi-rs-head-vr:before {
    content: "\f7ea";
    }
        .fi-rs-heading:before {
    content: "\f7eb";
    }
        .fi-rs-headphones:before {
    content: "\f7ec";
    }
        .fi-rs-headset:before {
    content: "\f7ed";
    }
        .fi-rs-heart-arrow:before {
    content: "\f7ee";
    }
        .fi-rs-heart-brain:before {
    content: "\f7ef";
    }
        .fi-rs-heart-crack:before {
    content: "\f7f0";
    }
        .fi-rs-heart-half-stroke:before {
    content: "\f7f1";
    }
        .fi-rs-heart-half:before {
    content: "\f7f2";
    }
        .fi-rs-heart-health-muscle:before {
    content: "\f7f3";
    }
        .fi-rs-heart-lock:before {
    content: "\f7f4";
    }
        .fi-rs-heart-partner-handshake:before {
    content: "\f7f5";
    }
        .fi-rs-heart-rate:before {
    content: "\f7f6";
    }
        .fi-rs-heart-slash:before {
    content: "\f7f7";
    }
        .fi-rs-heart-upside-down:before {
    content: "\f7f8";
    }
        .fi-rs-heart:before {
    content: "\f7f9";
    }
        .fi-rs-heat:before {
    content: "\f7fa";
    }
        .fi-rs-helicopter-side:before {
    content: "\f7fb";
    }
        .fi-rs-helmet-battle:before {
    content: "\f7fc";
    }
        .fi-rs-hexagon-check:before {
    content: "\f7fd";
    }
        .fi-rs-hexagon-divide:before {
    content: "\f7fe";
    }
        .fi-rs-hexagon-exclamation:before {
    content: "\f7ff";
    }
        .fi-rs-hexagon:before {
    content: "\f800";
    }
        .fi-rs-high-definition:before {
    content: "\f801";
    }
        .fi-rs-high-five-celebration-yes:before {
    content: "\f802";
    }
        .fi-rs-high-five:before {
    content: "\f803";
    }
        .fi-rs-highlighter-line:before {
    content: "\f804";
    }
        .fi-rs-highlighter:before {
    content: "\f805";
    }
        .fi-rs-hiking:before {
    content: "\f806";
    }
        .fi-rs-hippo:before {
    content: "\f807";
    }
        .fi-rs-hockey-mask:before {
    content: "\f808";
    }
        .fi-rs-hockey-puck:before {
    content: "\f809";
    }
        .fi-rs-hockey-stick-puck:before {
    content: "\f80a";
    }
        .fi-rs-hockey-sticks:before {
    content: "\f80b";
    }
        .fi-rs-holding-hand-gear:before {
    content: "\f80c";
    }
        .fi-rs-holding-hand-revenue:before {
    content: "\f80d";
    }
        .fi-rs-holly-berry:before {
    content: "\f80e";
    }
        .fi-rs-home-heart:before {
    content: "\f80f";
    }
        .fi-rs-home-location-alt:before {
    content: "\f810";
    }
        .fi-rs-home-location:before {
    content: "\f811";
    }
        .fi-rs-home:before {
    content: "\f812";
    }
        .fi-rs-honey-pot:before {
    content: "\f813";
    }
        .fi-rs-hood-cloak:before {
    content: "\f814";
    }
        .fi-rs-horizontal-rule:before {
    content: "\f815";
    }
        .fi-rs-horse-head:before {
    content: "\f816";
    }
        .fi-rs-horse-saddle:before {
    content: "\f817";
    }
        .fi-rs-horse:before {
    content: "\f818";
    }
        .fi-rs-horseshoe-broken:before {
    content: "\f819";
    }
        .fi-rs-horseshoe:before {
    content: "\f81a";
    }
        .fi-rs-hose-reel:before {
    content: "\f81b";
    }
        .fi-rs-hose:before {
    content: "\f81c";
    }
        .fi-rs-hospital-symbol:before {
    content: "\f81d";
    }
        .fi-rs-hospital-user:before {
    content: "\f81e";
    }
        .fi-rs-hospital:before {
    content: "\f81f";
    }
        .fi-rs-hospitals:before {
    content: "\f820";
    }
        .fi-rs-hot-tub:before {
    content: "\f821";
    }
        .fi-rs-hotdog:before {
    content: "\f822";
    }
        .fi-rs-hotel:before {
    content: "\f823";
    }
        .fi-rs-hourglass-end:before {
    content: "\f824";
    }
        .fi-rs-hourglass-start:before {
    content: "\f825";
    }
        .fi-rs-hourglass:before {
    content: "\f826";
    }
        .fi-rs-house-blank:before {
    content: "\f827";
    }
        .fi-rs-house-building:before {
    content: "\f828";
    }
        .fi-rs-house-chimney-blank:before {
    content: "\f829";
    }
        .fi-rs-house-chimney-crack:before {
    content: "\f82a";
    }
        .fi-rs-house-chimney-heart:before {
    content: "\f82b";
    }
        .fi-rs-house-chimney-medical:before {
    content: "\f82c";
    }
        .fi-rs-house-chimney-user:before {
    content: "\f82d";
    }
        .fi-rs-house-chimney-window:before {
    content: "\f82e";
    }
        .fi-rs-house-chimney:before {
    content: "\f82f";
    }
        .fi-rs-house-circle-check:before {
    content: "\f830";
    }
        .fi-rs-house-circle-exclamation:before {
    content: "\f831";
    }
        .fi-rs-house-circle-xmark:before {
    content: "\f832";
    }
        .fi-rs-house-crack-alt:before {
    content: "\f833";
    }
        .fi-rs-house-crack:before {
    content: "\f834";
    }
        .fi-rs-house-day:before {
    content: "\f835";
    }
        .fi-rs-house-fire:before {
    content: "\f836";
    }
        .fi-rs-house-flag:before {
    content: "\f837";
    }
        .fi-rs-house-flood:before {
    content: "\f838";
    }
        .fi-rs-house-laptop:before {
    content: "\f839";
    }
        .fi-rs-house-leave:before {
    content: "\f83a";
    }
        .fi-rs-house-lock:before {
    content: "\f83b";
    }
        .fi-rs-house-medical:before {
    content: "\f83c";
    }
        .fi-rs-house-night:before {
    content: "\f83d";
    }
        .fi-rs-house-return:before {
    content: "\f83e";
    }
        .fi-rs-house-signal:before {
    content: "\f83f";
    }
        .fi-rs-house-tree:before {
    content: "\f840";
    }
        .fi-rs-house-tsunami:before {
    content: "\f841";
    }
        .fi-rs-house-turret:before {
    content: "\f842";
    }
        .fi-rs-house-user:before {
    content: "\f843";
    }
        .fi-rs-house-window:before {
    content: "\f844";
    }
        .fi-rs-hr-group:before {
    content: "\f845";
    }
        .fi-rs-hr-person:before {
    content: "\f846";
    }
        .fi-rs-hr:before {
    content: "\f847";
    }
        .fi-rs-hryvnia:before {
    content: "\f848";
    }
        .fi-rs-humanitarian-mission:before {
    content: "\f849";
    }
        .fi-rs-humidity:before {
    content: "\f84a";
    }
        .fi-rs-hundred-points:before {
    content: "\f84b";
    }
        .fi-rs-hurricane:before {
    content: "\f84c";
    }
        .fi-rs-i:before {
    content: "\f84d";
    }
        .fi-rs-ice-cream:before {
    content: "\f84e";
    }
        .fi-rs-ice-skate:before {
    content: "\f84f";
    }
        .fi-rs-icicles:before {
    content: "\f850";
    }
        .fi-rs-icon-star:before {
    content: "\f851";
    }
        .fi-rs-id-badge:before {
    content: "\f852";
    }
        .fi-rs-id-card-clip-alt:before {
    content: "\f853";
    }
        .fi-rs-idea-exchange:before {
    content: "\f854";
    }
        .fi-rs-igloo:before {
    content: "\f855";
    }
        .fi-rs-image-slash:before {
    content: "\f856";
    }
        .fi-rs-images-user:before {
    content: "\f857";
    }
        .fi-rs-images:before {
    content: "\f858";
    }
        .fi-rs-improve-user:before {
    content: "\f859";
    }
        .fi-rs-inbox-full:before {
    content: "\f85a";
    }
        .fi-rs-inbox-in:before {
    content: "\f85b";
    }
        .fi-rs-inbox-out:before {
    content: "\f85c";
    }
        .fi-rs-inbox:before {
    content: "\f85d";
    }
        .fi-rs-inboxes:before {
    content: "\f85e";
    }
        .fi-rs-incense-sticks-yoga:before {
    content: "\f85f";
    }
        .fi-rs-incognito:before {
    content: "\f860";
    }
        .fi-rs-indent:before {
    content: "\f861";
    }
        .fi-rs-indian-rupee-sign:before {
    content: "\f862";
    }
        .fi-rs-industry-alt:before {
    content: "\f863";
    }
        .fi-rs-industry-windows:before {
    content: "\f864";
    }
        .fi-rs-infinity:before {
    content: "\f865";
    }
        .fi-rs-info-guide:before {
    content: "\f866";
    }
        .fi-rs-info:before {
    content: "\f867";
    }
        .fi-rs-information:before {
    content: "\f868";
    }
        .fi-rs-inhaler:before {
    content: "\f869";
    }
        .fi-rs-input-numeric:before {
    content: "\f86a";
    }
        .fi-rs-input-pipe:before {
    content: "\f86b";
    }
        .fi-rs-input-text:before {
    content: "\f86c";
    }
        .fi-rs-insert-alt:before {
    content: "\f86d";
    }
        .fi-rs-insert-arrows:before {
    content: "\f86e";
    }
        .fi-rs-insert-button-circle:before {
    content: "\f86f";
    }
        .fi-rs-insert-credit-card:before {
    content: "\f870";
    }
        .fi-rs-insert-square:before {
    content: "\f871";
    }
        .fi-rs-insert:before {
    content: "\f872";
    }
        .fi-rs-insight-alt:before {
    content: "\f873";
    }
        .fi-rs-insight-head:before {
    content: "\f874";
    }
        .fi-rs-insight:before {
    content: "\f875";
    }
        .fi-rs-integral:before {
    content: "\f876";
    }
        .fi-rs-interactive:before {
    content: "\f877";
    }
        .fi-rs-interlining:before {
    content: "\f878";
    }
        .fi-rs-internet-speed-wifi:before {
    content: "\f879";
    }
        .fi-rs-interpersonal-skill:before {
    content: "\f87a";
    }
        .fi-rs-interrogation:before {
    content: "\f87b";
    }
        .fi-rs-intersection:before {
    content: "\f87c";
    }
        .fi-rs-introduction-handshake:before {
    content: "\f87d";
    }
        .fi-rs-introduction:before {
    content: "\f87e";
    }
        .fi-rs-inventory-alt:before {
    content: "\f87f";
    }
        .fi-rs-invest:before {
    content: "\f880";
    }
        .fi-rs-investment:before {
    content: "\f881";
    }
        .fi-rs-invite-alt:before {
    content: "\f882";
    }
        .fi-rs-invite:before {
    content: "\f883";
    }
        .fi-rs-iot-alt:before {
    content: "\f884";
    }
        .fi-rs-iot:before {
    content: "\f885";
    }
        .fi-rs-ip-address:before {
    content: "\f886";
    }
        .fi-rs-iron:before {
    content: "\f887";
    }
        .fi-rs-island-tropical:before {
    content: "\f888";
    }
        .fi-rs-issue-loupe:before {
    content: "\f889";
    }
        .fi-rs-it-alt:before {
    content: "\f88a";
    }
        .fi-rs-it-computer:before {
    content: "\f88b";
    }
        .fi-rs-it:before {
    content: "\f88c";
    }
        .fi-rs-italian-lira-sign:before {
    content: "\f88d";
    }
        .fi-rs-italian:before {
    content: "\f88e";
    }
        .fi-rs-italic:before {
    content: "\f88f";
    }
        .fi-rs-j:before {
    content: "\f890";
    }
        .fi-rs-jam:before {
    content: "\f891";
    }
        .fi-rs-japanese:before {
    content: "\f892";
    }
        .fi-rs-jar-alt:before {
    content: "\f893";
    }
        .fi-rs-jar-wheat:before {
    content: "\f894";
    }
        .fi-rs-javascript:before {
    content: "\f895";
    }
        .fi-rs-joint:before {
    content: "\f896";
    }
        .fi-rs-joker:before {
    content: "\f897";
    }
        .fi-rs-journal-alt:before {
    content: "\f898";
    }
        .fi-rs-journal:before {
    content: "\f899";
    }
        .fi-rs-journey:before {
    content: "\f89a";
    }
        .fi-rs-joystick:before {
    content: "\f89b";
    }
        .fi-rs-jpg:before {
    content: "\f89c";
    }
        .fi-rs-jug-alt:before {
    content: "\f89d";
    }
        .fi-rs-jug-bottle:before {
    content: "\f89e";
    }
        .fi-rs-jug:before {
    content: "\f89f";
    }
        .fi-rs-jumping-rope:before {
    content: "\f8a0";
    }
        .fi-rs-k:before {
    content: "\f8a1";
    }
        .fi-rs-kaaba:before {
    content: "\f8a2";
    }
        .fi-rs-kazoo:before {
    content: "\f8a3";
    }
        .fi-rs-kerning:before {
    content: "\f8a4";
    }
        .fi-rs-key-lock-crypto:before {
    content: "\f8a5";
    }
        .fi-rs-key-skeleton-left-right:before {
    content: "\f8a6";
    }
        .fi-rs-key:before {
    content: "\f8a7";
    }
        .fi-rs-keyboard-brightness-low:before {
    content: "\f8a8";
    }
        .fi-rs-keyboard-brightness:before {
    content: "\f8a9";
    }
        .fi-rs-keyboard-down:before {
    content: "\f8aa";
    }
        .fi-rs-keyboard-left:before {
    content: "\f8ab";
    }
        .fi-rs-keyboard:before {
    content: "\f8ac";
    }
        .fi-rs-keynote:before {
    content: "\f8ad";
    }
        .fi-rs-kidneys:before {
    content: "\f8ae";
    }
        .fi-rs-kip-sign:before {
    content: "\f8af";
    }
        .fi-rs-kiss-beam:before {
    content: "\f8b0";
    }
        .fi-rs-kiss-wink-heart:before {
    content: "\f8b1";
    }
        .fi-rs-kiss:before {
    content: "\f8b2";
    }
        .fi-rs-kitchen-set:before {
    content: "\f8b3";
    }
        .fi-rs-kite:before {
    content: "\f8b4";
    }
        .fi-rs-kiwi-bird:before {
    content: "\f8b5";
    }
        .fi-rs-kiwi-fruit:before {
    content: "\f8b6";
    }
        .fi-rs-knife-kitchen:before {
    content: "\f8b7";
    }
        .fi-rs-knife:before {
    content: "\f8b8";
    }
        .fi-rs-knitting:before {
    content: "\f8b9";
    }
        .fi-rs-korean:before {
    content: "\f8ba";
    }
        .fi-rs-kpi-evaluation:before {
    content: "\f8bb";
    }
        .fi-rs-kpi:before {
    content: "\f8bc";
    }
        .fi-rs-l:before {
    content: "\f8bd";
    }
        .fi-rs-lab-coat:before {
    content: "\f8be";
    }
        .fi-rs-label:before {
    content: "\f8bf";
    }
        .fi-rs-lacrosse-stick-ball:before {
    content: "\f8c0";
    }
        .fi-rs-lacrosse-stick:before {
    content: "\f8c1";
    }
        .fi-rs-lambda:before {
    content: "\f8c2";
    }
        .fi-rs-lamp-desk:before {
    content: "\f8c3";
    }
        .fi-rs-lamp-floor:before {
    content: "\f8c4";
    }
        .fi-rs-lamp-street:before {
    content: "\f8c5";
    }
        .fi-rs-lamp:before {
    content: "\f8c6";
    }
        .fi-rs-land-layer-location:before {
    content: "\f8c7";
    }
        .fi-rs-land-layers:before {
    content: "\f8c8";
    }
        .fi-rs-land-location:before {
    content: "\f8c9";
    }
        .fi-rs-land-mine-on:before {
    content: "\f8ca";
    }
        .fi-rs-landmark-alt:before {
    content: "\f8cb";
    }
        .fi-rs-language-exchange:before {
    content: "\f8cc";
    }
        .fi-rs-language:before {
    content: "\f8cd";
    }
        .fi-rs-laptop-arrow-down:before {
    content: "\f8ce";
    }
        .fi-rs-laptop-binary:before {
    content: "\f8cf";
    }
        .fi-rs-laptop-code:before {
    content: "\f8d0";
    }
        .fi-rs-laptop-medical:before {
    content: "\f8d1";
    }
        .fi-rs-laptop-mobile:before {
    content: "\f8d2";
    }
        .fi-rs-laptop-slash:before {
    content: "\f8d3";
    }
        .fi-rs-laptop:before {
    content: "\f8d4";
    }
        .fi-rs-lari-sign:before {
    content: "\f8d5";
    }
        .fi-rs-lasso-sparkles:before {
    content: "\f8d6";
    }
        .fi-rs-lasso:before {
    content: "\f8d7";
    }
        .fi-rs-last-square:before {
    content: "\f8d8";
    }
        .fi-rs-laugh-beam:before {
    content: "\f8d9";
    }
        .fi-rs-laugh-squint:before {
    content: "\f8da";
    }
        .fi-rs-laugh-wink:before {
    content: "\f8db";
    }
        .fi-rs-laugh:before {
    content: "\f8dc";
    }
        .fi-rs-laurel-user:before {
    content: "\f8dd";
    }
        .fi-rs-lawyer-man:before {
    content: "\f8de";
    }
        .fi-rs-lawyer-woman:before {
    content: "\f8df";
    }
        .fi-rs-layer-minus:before {
    content: "\f8e0";
    }
        .fi-rs-layer-plus:before {
    content: "\f8e1";
    }
        .fi-rs-layers:before {
    content: "\f8e2";
    }
        .fi-rs-lead-funnel:before {
    content: "\f8e3";
    }
        .fi-rs-lead-management:before {
    content: "\f8e4";
    }
        .fi-rs-lead:before {
    content: "\f8e5";
    }
        .fi-rs-leader-alt:before {
    content: "\f8e6";
    }
        .fi-rs-leader-speech:before {
    content: "\f8e7";
    }
        .fi-rs-leader:before {
    content: "\f8e8";
    }
        .fi-rs-leaderboard-alt:before {
    content: "\f8e9";
    }
        .fi-rs-leaderboard-trophy:before {
    content: "\f8ea";
    }
        .fi-rs-leaderboard:before {
    content: "\f8eb";
    }
        .fi-rs-leadership-alt:before {
    content: "\f8ec";
    }
        .fi-rs-leadership:before {
    content: "\f8ed";
    }
        .fi-rs-leaf-heart:before {
    content: "\f8ee";
    }
        .fi-rs-leaf-maple:before {
    content: "\f8ef";
    }
        .fi-rs-leaf-oak:before {
    content: "\f8f0";
    }
        .fi-rs-leaf:before {
    content: "\f8f1";
    }
        .fi-rs-leafy-green:before {
    content: "\f8f2";
    }
        .fi-rs-leave:before {
    content: "\f8f3";
    }
        .fi-rs-left-from-bracket:before {
    content: "\f8f4";
    }
        .fi-rs-left:before {
    content: "\f8f5";
    }
        .fi-rs-legal-case:before {
    content: "\f8f6";
    }
        .fi-rs-legal:before {
    content: "\f8f7";
    }
        .fi-rs-lemon:before {
    content: "\f8f8";
    }
        .fi-rs-lesbian-couple:before {
    content: "\f8f9";
    }
        .fi-rs-less-than-equal:before {
    content: "\f8fa";
    }
        .fi-rs-less-than:before {
    content: "\f8fb";
    }
        .fi-rs-lesson-class:before {
    content: "\f8fc";
    }
        .fi-rs-lesson:before {
    content: "\f8fd";
    }
        .fi-rs-letter-case:before {
    content: "\f8fe";
    }
        .fi-rs-lettuce:before {
    content: "\f8ff";
    }
        .fi-rs-level-down-alt:before {
    content: "\f900";
    }
        .fi-rs-level-down:before {
    content: "\f901";
    }
        .fi-rs-level-up-alt:before {
    content: "\f902";
    }
        .fi-rs-level-up:before {
    content: "\f903";
    }
        .fi-rs-license:before {
    content: "\f904";
    }
        .fi-rs-life-ring:before {
    content: "\f905";
    }
        .fi-rs-life:before {
    content: "\f906";
    }
        .fi-rs-light-ceiling:before {
    content: "\f907";
    }
        .fi-rs-light-emergency-on:before {
    content: "\f908";
    }
        .fi-rs-light-emergency:before {
    content: "\f909";
    }
        .fi-rs-light-switch-off:before {
    content: "\f90a";
    }
        .fi-rs-light-switch-on:before {
    content: "\f90b";
    }
        .fi-rs-light-switch:before {
    content: "\f90c";
    }
        .fi-rs-lightbulb-cfl-on:before {
    content: "\f90d";
    }
        .fi-rs-lightbulb-cfl:before {
    content: "\f90e";
    }
        .fi-rs-lightbulb-dollar:before {
    content: "\f90f";
    }
        .fi-rs-lightbulb-exclamation:before {
    content: "\f910";
    }
        .fi-rs-lightbulb-head:before {
    content: "\f911";
    }
        .fi-rs-lightbulb-on:before {
    content: "\f912";
    }
        .fi-rs-lightbulb-question:before {
    content: "\f913";
    }
        .fi-rs-lightbulb-setting:before {
    content: "\f914";
    }
        .fi-rs-lightbulb-slash:before {
    content: "\f915";
    }
        .fi-rs-lighthouse:before {
    content: "\f916";
    }
        .fi-rs-lights-holiday:before {
    content: "\f917";
    }
        .fi-rs-limit-hand:before {
    content: "\f918";
    }
        .fi-rs-limit-speedometer:before {
    content: "\f919";
    }
        .fi-rs-line-width:before {
    content: "\f91a";
    }
        .fi-rs-link-alt:before {
    content: "\f91b";
    }
        .fi-rs-link-horizontal-slash:before {
    content: "\f91c";
    }
        .fi-rs-link-horizontal:before {
    content: "\f91d";
    }
        .fi-rs-link-slash-alt:before {
    content: "\f91e";
    }
        .fi-rs-link-slash:before {
    content: "\f91f";
    }
        .fi-rs-link:before {
    content: "\f920";
    }
        .fi-rs-lion-head:before {
    content: "\f921";
    }
        .fi-rs-lion:before {
    content: "\f922";
    }
        .fi-rs-lips-silence:before {
    content: "\f923";
    }
        .fi-rs-lips:before {
    content: "\f924";
    }
        .fi-rs-lipstick:before {
    content: "\f925";
    }
        .fi-rs-lira-sign:before {
    content: "\f926";
    }
        .fi-rs-list-check:before {
    content: "\f927";
    }
        .fi-rs-list-dropdown:before {
    content: "\f928";
    }
        .fi-rs-list-music:before {
    content: "\f929";
    }
        .fi-rs-list-timeline:before {
    content: "\f92a";
    }
        .fi-rs-list:before {
    content: "\f92b";
    }
        .fi-rs-litecoin-sign:before {
    content: "\f92c";
    }
        .fi-rs-live-alt:before {
    content: "\f92d";
    }
        .fi-rs-live:before {
    content: "\f92e";
    }
        .fi-rs-loading:before {
    content: "\f92f";
    }
        .fi-rs-loan:before {
    content: "\f930";
    }
        .fi-rs-lobster:before {
    content: "\f931";
    }
        .fi-rs-location-alt:before {
    content: "\f932";
    }
        .fi-rs-location-arrow:before {
    content: "\f933";
    }
        .fi-rs-location-crosshairs-slash:before {
    content: "\f934";
    }
        .fi-rs-location-crosshairs:before {
    content: "\f935";
    }
        .fi-rs-location-dot-slash:before {
    content: "\f936";
    }
        .fi-rs-location-exclamation:before {
    content: "\f937";
    }
        .fi-rs-location-pin-call:before {
    content: "\f938";
    }
        .fi-rs-lock-alt:before {
    content: "\f939";
    }
        .fi-rs-lock-hashtag:before {
    content: "\f93a";
    }
        .fi-rs-lock-open-alt:before {
    content: "\f93b";
    }
        .fi-rs-lock:before {
    content: "\f93c";
    }
        .fi-rs-locust:before {
    content: "\f93d";
    }
        .fi-rs-log-out:before {
    content: "\f93e";
    }
        .fi-rs-loop-square:before {
    content: "\f93f";
    }
        .fi-rs-loveseat:before {
    content: "\f940";
    }
        .fi-rs-low-vision:before {
    content: "\f941";
    }
        .fi-rs-luchador:before {
    content: "\f942";
    }
        .fi-rs-luggage-cart:before {
    content: "\f943";
    }
        .fi-rs-luggage-rolling:before {
    content: "\f944";
    }
        .fi-rs-lungs-virus:before {
    content: "\f945";
    }
        .fi-rs-lungs:before {
    content: "\f946";
    }
        .fi-rs-m:before {
    content: "\f947";
    }
        .fi-rs-mace:before {
    content: "\f948";
    }
        .fi-rs-magic-wand:before {
    content: "\f949";
    }
        .fi-rs-magnet-user:before {
    content: "\f94a";
    }
        .fi-rs-magnet:before {
    content: "\f94b";
    }
        .fi-rs-magnifying-glass-eye:before {
    content: "\f94c";
    }
        .fi-rs-magnifying-glass-wave:before {
    content: "\f94d";
    }
        .fi-rs-mail-plus-circle:before {
    content: "\f94e";
    }
        .fi-rs-mailbox-envelope:before {
    content: "\f94f";
    }
        .fi-rs-mailbox-flag-up:before {
    content: "\f950";
    }
        .fi-rs-mailbox:before {
    content: "\f951";
    }
        .fi-rs-makeup-brush:before {
    content: "\f952";
    }
        .fi-rs-man-head:before {
    content: "\f953";
    }
        .fi-rs-man-scientist:before {
    content: "\f954";
    }
        .fi-rs-manat-sign:before {
    content: "\f955";
    }
        .fi-rs-mandolin:before {
    content: "\f956";
    }
        .fi-rs-mango:before {
    content: "\f957";
    }
        .fi-rs-manhole:before {
    content: "\f958";
    }
        .fi-rs-map-location-track:before {
    content: "\f959";
    }
        .fi-rs-map-marker-check:before {
    content: "\f95a";
    }
        .fi-rs-map-marker-cross:before {
    content: "\f95b";
    }
        .fi-rs-map-marker-edit:before {
    content: "\f95c";
    }
        .fi-rs-map-marker-home:before {
    content: "\f95d";
    }
        .fi-rs-map-marker-minus:before {
    content: "\f95e";
    }
        .fi-rs-map-marker-plus:before {
    content: "\f95f";
    }
        .fi-rs-map-marker-question:before {
    content: "\f960";
    }
        .fi-rs-map-marker-slash:before {
    content: "\f961";
    }
        .fi-rs-map-marker-smile:before {
    content: "\f962";
    }
        .fi-rs-map-marker:before {
    content: "\f963";
    }
        .fi-rs-map-pin:before {
    content: "\f964";
    }
        .fi-rs-map-point:before {
    content: "\f965";
    }
        .fi-rs-map:before {
    content: "\f966";
    }
        .fi-rs-marker-time:before {
    content: "\f967";
    }
        .fi-rs-marker:before {
    content: "\f968";
    }
        .fi-rs-marketplace-alt:before {
    content: "\f969";
    }
        .fi-rs-marketplace-store:before {
    content: "\f96a";
    }
        .fi-rs-marketplace:before {
    content: "\f96b";
    }
        .fi-rs-mars-double:before {
    content: "\f96c";
    }
        .fi-rs-mars-stroke-right:before {
    content: "\f96d";
    }
        .fi-rs-mars-stroke-up:before {
    content: "\f96e";
    }
        .fi-rs-mars:before {
    content: "\f96f";
    }
        .fi-rs-martini-glass-citrus:before {
    content: "\f970";
    }
        .fi-rs-martini-glass-empty:before {
    content: "\f971";
    }
        .fi-rs-mask-carnival:before {
    content: "\f972";
    }
        .fi-rs-mask-face:before {
    content: "\f973";
    }
        .fi-rs-mask-snorkel:before {
    content: "\f974";
    }
        .fi-rs-massage:before {
    content: "\f975";
    }
        .fi-rs-match-fire:before {
    content: "\f976";
    }
        .fi-rs-mattress-pillow:before {
    content: "\f977";
    }
        .fi-rs-measuring-tape:before {
    content: "\f978";
    }
        .fi-rs-meat:before {
    content: "\f979";
    }
        .fi-rs-medal:before {
    content: "\f97a";
    }
        .fi-rs-medical-star:before {
    content: "\f97b";
    }
        .fi-rs-medicine:before {
    content: "\f97c";
    }
        .fi-rs-meditation:before {
    content: "\f97d";
    }
        .fi-rs-meeting-alt:before {
    content: "\f97e";
    }
        .fi-rs-meeting:before {
    content: "\f97f";
    }
        .fi-rs-megaphone-announcement-leader:before {
    content: "\f980";
    }
        .fi-rs-megaphone:before {
    content: "\f981";
    }
        .fi-rs-meh-blank:before {
    content: "\f982";
    }
        .fi-rs-meh-rolling-eyes:before {
    content: "\f983";
    }
        .fi-rs-meh:before {
    content: "\f984";
    }
        .fi-rs-melon-alt:before {
    content: "\f985";
    }
        .fi-rs-melon:before {
    content: "\f986";
    }
        .fi-rs-member-list:before {
    content: "\f987";
    }
        .fi-rs-member-search:before {
    content: "\f988";
    }
        .fi-rs-membership-vip:before {
    content: "\f989";
    }
        .fi-rs-membership:before {
    content: "\f98a";
    }
        .fi-rs-memo-circle-check:before {
    content: "\f98b";
    }
        .fi-rs-memo-pad:before {
    content: "\f98c";
    }
        .fi-rs-memo:before {
    content: "\f98d";
    }
        .fi-rs-memory:before {
    content: "\f98e";
    }
        .fi-rs-menu-dots-vertical:before {
    content: "\f98f";
    }
        .fi-rs-menu-dots:before {
    content: "\f990";
    }
        .fi-rs-mercury:before {
    content: "\f991";
    }
        .fi-rs-message-alert:before {
    content: "\f992";
    }
        .fi-rs-message-arrow-down:before {
    content: "\f993";
    }
        .fi-rs-message-arrow-up-right:before {
    content: "\f994";
    }
        .fi-rs-message-arrow-up:before {
    content: "\f995";
    }
        .fi-rs-message-bot:before {
    content: "\f996";
    }
        .fi-rs-message-code:before {
    content: "\f997";
    }
        .fi-rs-message-dollar:before {
    content: "\f998";
    }
        .fi-rs-message-heart:before {
    content: "\f999";
    }
        .fi-rs-message-image:before {
    content: "\f99a";
    }
        .fi-rs-message-question:before {
    content: "\f99b";
    }
        .fi-rs-message-quote:before {
    content: "\f99c";
    }
        .fi-rs-message-slash:before {
    content: "\f99d";
    }
        .fi-rs-message-sms:before {
    content: "\f99e";
    }
        .fi-rs-message-star:before {
    content: "\f99f";
    }
        .fi-rs-message-text:before {
    content: "\f9a0";
    }
        .fi-rs-message-xmark:before {
    content: "\f9a1";
    }
        .fi-rs-messages-dollar:before {
    content: "\f9a2";
    }
        .fi-rs-messages-question:before {
    content: "\f9a3";
    }
        .fi-rs-messages:before {
    content: "\f9a4";
    }
        .fi-rs-meteor:before {
    content: "\f9a5";
    }
        .fi-rs-meter-bolt:before {
    content: "\f9a6";
    }
        .fi-rs-meter-droplet:before {
    content: "\f9a7";
    }
        .fi-rs-meter-fire:before {
    content: "\f9a8";
    }
        .fi-rs-meter:before {
    content: "\f9a9";
    }
        .fi-rs-method:before {
    content: "\f9aa";
    }
        .fi-rs-microchip-ai:before {
    content: "\f9ab";
    }
        .fi-rs-microchip:before {
    content: "\f9ac";
    }
        .fi-rs-microphone-alt:before {
    content: "\f9ad";
    }
        .fi-rs-microphone-slash:before {
    content: "\f9ae";
    }
        .fi-rs-microphone:before {
    content: "\f9af";
    }
        .fi-rs-microscope:before {
    content: "\f9b0";
    }
        .fi-rs-microwave:before {
    content: "\f9b1";
    }
        .fi-rs-milk-alt:before {
    content: "\f9b2";
    }
        .fi-rs-milk:before {
    content: "\f9b3";
    }
        .fi-rs-mill-sign:before {
    content: "\f9b4";
    }
        .fi-rs-mind-share:before {
    content: "\f9b5";
    }
        .fi-rs-minus-circle:before {
    content: "\f9b6";
    }
        .fi-rs-minus-hexagon:before {
    content: "\f9b7";
    }
        .fi-rs-minus-small:before {
    content: "\f9b8";
    }
        .fi-rs-minuss:before {
    content: "\f9b9";
    }
        .fi-rs-mistletoe:before {
    content: "\f9ba";
    }
        .fi-rs-mix:before {
    content: "\f9bb";
    }
        .fi-rs-mixer:before {
    content: "\f9bc";
    }
        .fi-rs-mobile-button:before {
    content: "\f9bd";
    }
        .fi-rs-mobile-notch:before {
    content: "\f9be";
    }
        .fi-rs-mobile:before {
    content: "\f9bf";
    }
        .fi-rs-mockup:before {
    content: "\f9c0";
    }
        .fi-rs-mode-alt:before {
    content: "\f9c1";
    }
        .fi-rs-mode-landscape:before {
    content: "\f9c2";
    }
        .fi-rs-mode-portrait:before {
    content: "\f9c3";
    }
        .fi-rs-mode:before {
    content: "\f9c4";
    }
        .fi-rs-model-cube-arrows:before {
    content: "\f9c5";
    }
        .fi-rs-model-cube-space:before {
    content: "\f9c6";
    }
        .fi-rs-model-cube:before {
    content: "\f9c7";
    }
        .fi-rs-module:before {
    content: "\f9c8";
    }
        .fi-rs-monday:before {
    content: "\f9c9";
    }
        .fi-rs-money-bill-simple:before {
    content: "\f9ca";
    }
        .fi-rs-money-bill-transfer:before {
    content: "\f9cb";
    }
        .fi-rs-money-bill-wave-alt:before {
    content: "\f9cc";
    }
        .fi-rs-money-bill-wave:before {
    content: "\f9cd";
    }
        .fi-rs-money-bills-simple:before {
    content: "\f9ce";
    }
        .fi-rs-money-bills:before {
    content: "\f9cf";
    }
        .fi-rs-money-check-edit-alt:before {
    content: "\f9d0";
    }
        .fi-rs-money-check-edit:before {
    content: "\f9d1";
    }
        .fi-rs-money-check:before {
    content: "\f9d2";
    }
        .fi-rs-money-coin-transfer:before {
    content: "\f9d3";
    }
        .fi-rs-money-from-bracket:before {
    content: "\f9d4";
    }
        .fi-rs-money-simple-from-bracket:before {
    content: "\f9d5";
    }
        .fi-rs-money-transfer-alt:before {
    content: "\f9d6";
    }
        .fi-rs-money-transfer-coin-arrow:before {
    content: "\f9d7";
    }
        .fi-rs-money-transfer-smartphone:before {
    content: "\f9d8";
    }
        .fi-rs-money-wings:before {
    content: "\f9d9";
    }
        .fi-rs-money:before {
    content: "\f9da";
    }
        .fi-rs-monkey:before {
    content: "\f9db";
    }
        .fi-rs-monument:before {
    content: "\f9dc";
    }
        .fi-rs-moon-stars:before {
    content: "\f9dd";
    }
        .fi-rs-moon:before {
    content: "\f9de";
    }
        .fi-rs-moped:before {
    content: "\f9df";
    }
        .fi-rs-mortar-pestle:before {
    content: "\f9e0";
    }
        .fi-rs-mortgage:before {
    content: "\f9e1";
    }
        .fi-rs-mosque-alt:before {
    content: "\f9e2";
    }
        .fi-rs-mosque-moon:before {
    content: "\f9e3";
    }
        .fi-rs-mosque:before {
    content: "\f9e4";
    }
        .fi-rs-mosquito-net:before {
    content: "\f9e5";
    }
        .fi-rs-mosquito:before {
    content: "\f9e6";
    }
        .fi-rs-motorcycle:before {
    content: "\f9e7";
    }
        .fi-rs-mound:before {
    content: "\f9e8";
    }
        .fi-rs-mountain-city:before {
    content: "\f9e9";
    }
        .fi-rs-mountain:before {
    content: "\f9ea";
    }
        .fi-rs-mountains:before {
    content: "\f9eb";
    }
        .fi-rs-mouse-field:before {
    content: "\f9ec";
    }
        .fi-rs-mouse:before {
    content: "\f9ed";
    }
        .fi-rs-mov-file:before {
    content: "\f9ee";
    }
        .fi-rs-move-to-folder-2:before {
    content: "\f9ef";
    }
        .fi-rs-move-to-folder:before {
    content: "\f9f0";
    }
        .fi-rs-moving:before {
    content: "\f9f1";
    }
        .fi-rs-mp3-file:before {
    content: "\f9f2";
    }
        .fi-rs-mp3-player:before {
    content: "\f9f3";
    }
        .fi-rs-mp4-file:before {
    content: "\f9f4";
    }
        .fi-rs-mug-alt:before {
    content: "\f9f5";
    }
        .fi-rs-mug-hot-alt:before {
    content: "\f9f6";
    }
        .fi-rs-mug-hot:before {
    content: "\f9f7";
    }
        .fi-rs-mug-marshmallows:before {
    content: "\f9f8";
    }
        .fi-rs-mug-tea-saucer:before {
    content: "\f9f9";
    }
        .fi-rs-mug-tea:before {
    content: "\f9fa";
    }
        .fi-rs-mug:before {
    content: "\f9fb";
    }
        .fi-rs-multiple-alt:before {
    content: "\f9fc";
    }
        .fi-rs-multiple:before {
    content: "\f9fd";
    }
        .fi-rs-multitasking:before {
    content: "\f9fe";
    }
        .fi-rs-muscle:before {
    content: "\f9ff";
    }
        .fi-rs-mushroom-alt:before {
    content: "\fa00";
    }
        .fi-rs-mushroom-cloud:before {
    content: "\fa01";
    }
        .fi-rs-mushroom:before {
    content: "\fa02";
    }
        .fi-rs-music-alt:before {
    content: "\fa03";
    }
        .fi-rs-music-file:before {
    content: "\fa04";
    }
        .fi-rs-music-magnifying-glass:before {
    content: "\fa05";
    }
        .fi-rs-music-note-slash:before {
    content: "\fa06";
    }
        .fi-rs-music-note:before {
    content: "\fa07";
    }
        .fi-rs-music-slash:before {
    content: "\fa08";
    }
        .fi-rs-music:before {
    content: "\fa09";
    }
        .fi-rs-n:before {
    content: "\fa0a";
    }
        .fi-rs-naira-sign:before {
    content: "\fa0b";
    }
        .fi-rs-narwhal:before {
    content: "\fa0c";
    }
        .fi-rs-navigation:before {
    content: "\fa0d";
    }
        .fi-rs-nesting-dolls:before {
    content: "\fa0e";
    }
        .fi-rs-network-analytic:before {
    content: "\fa0f";
    }
        .fi-rs-network-cloud:before {
    content: "\fa10";
    }
        .fi-rs-network:before {
    content: "\fa11";
    }
        .fi-rs-neuter:before {
    content: "\fa12";
    }
        .fi-rs-newsletter-subscribe:before {
    content: "\fa13";
    }
        .fi-rs-newspaper-open:before {
    content: "\fa14";
    }
        .fi-rs-newspaper:before {
    content: "\fa15";
    }
        .fi-rs-nfc-lock:before {
    content: "\fa16";
    }
        .fi-rs-nfc-magnifying-glass:before {
    content: "\fa17";
    }
        .fi-rs-nfc-pen:before {
    content: "\fa18";
    }
        .fi-rs-nfc-slash:before {
    content: "\fa19";
    }
        .fi-rs-nfc-trash:before {
    content: "\fa1a";
    }
        .fi-rs-nfc:before {
    content: "\fa1b";
    }
        .fi-rs-night-day:before {
    content: "\fa1c";
    }
        .fi-rs-no-fee:before {
    content: "\fa1d";
    }
        .fi-rs-no-iron:before {
    content: "\fa1e";
    }
        .fi-rs-no-people:before {
    content: "\fa1f";
    }
        .fi-rs-no-smoking:before {
    content: "\fa20";
    }
        .fi-rs-noise-cancelling-headphones:before {
    content: "\fa21";
    }
        .fi-rs-noodles:before {
    content: "\fa22";
    }
        .fi-rs-nose:before {
    content: "\fa23";
    }
        .fi-rs-not-equal:before {
    content: "\fa24";
    }
        .fi-rs-not-found-alt:before {
    content: "\fa25";
    }
        .fi-rs-not-found-magnifying-glass:before {
    content: "\fa26";
    }
        .fi-rs-not-found:before {
    content: "\fa27";
    }
        .fi-rs-notdef:before {
    content: "\fa28";
    }
        .fi-rs-note-medical:before {
    content: "\fa29";
    }
        .fi-rs-note-sticky:before {
    content: "\fa2a";
    }
        .fi-rs-note:before {
    content: "\fa2b";
    }
        .fi-rs-notebook-alt:before {
    content: "\fa2c";
    }
        .fi-rs-notebook:before {
    content: "\fa2d";
    }
        .fi-rs-notes-medical:before {
    content: "\fa2e";
    }
        .fi-rs-notes:before {
    content: "\fa2f";
    }
        .fi-rs-o:before {
    content: "\fa30";
    }
        .fi-rs-object-exclude:before {
    content: "\fa31";
    }
        .fi-rs-object-group:before {
    content: "\fa32";
    }
        .fi-rs-object-intersect:before {
    content: "\fa33";
    }
        .fi-rs-object-subtract:before {
    content: "\fa34";
    }
        .fi-rs-object-ungroup:before {
    content: "\fa35";
    }
        .fi-rs-object-union:before {
    content: "\fa36";
    }
        .fi-rs-objects-column:before {
    content: "\fa37";
    }
        .fi-rs-octagon-check:before {
    content: "\fa38";
    }
        .fi-rs-octagon-divide:before {
    content: "\fa39";
    }
        .fi-rs-octagon-exclamation:before {
    content: "\fa3a";
    }
        .fi-rs-octagon-minus:before {
    content: "\fa3b";
    }
        .fi-rs-octagon-plus:before {
    content: "\fa3c";
    }
        .fi-rs-octagon-xmark:before {
    content: "\fa3d";
    }
        .fi-rs-octagon:before {
    content: "\fa3e";
    }
        .fi-rs-office-chair:before {
    content: "\fa3f";
    }
        .fi-rs-oil-can:before {
    content: "\fa40";
    }
        .fi-rs-oil-temp:before {
    content: "\fa41";
    }
        .fi-rs-olive-branch-dove:before {
    content: "\fa42";
    }
        .fi-rs-olive-oil:before {
    content: "\fa43";
    }
        .fi-rs-olive:before {
    content: "\fa44";
    }
        .fi-rs-olives:before {
    content: "\fa45";
    }
        .fi-rs-om:before {
    content: "\fa46";
    }
        .fi-rs-omega:before {
    content: "\fa47";
    }
        .fi-rs-onboarding:before {
    content: "\fa48";
    }
        .fi-rs-onion:before {
    content: "\fa49";
    }
        .fi-rs-opacity:before {
    content: "\fa4a";
    }
        .fi-rs-open-mail-clip:before {
    content: "\fa4b";
    }
        .fi-rs-operating-system-upgrade:before {
    content: "\fa4c";
    }
        .fi-rs-operation:before {
    content: "\fa4d";
    }
        .fi-rs-order-history:before {
    content: "\fa4e";
    }
        .fi-rs-organization-chart:before {
    content: "\fa4f";
    }
        .fi-rs-ornament:before {
    content: "\fa50";
    }
        .fi-rs-otp:before {
    content: "\fa51";
    }
        .fi-rs-otter:before {
    content: "\fa52";
    }
        .fi-rs-outdent:before {
    content: "\fa53";
    }
        .fi-rs-oval-alt:before {
    content: "\fa54";
    }
        .fi-rs-oval:before {
    content: "\fa55";
    }
        .fi-rs-oven:before {
    content: "\fa56";
    }
        .fi-rs-overline:before {
    content: "\fa57";
    }
        .fi-rs-overview:before {
    content: "\fa58";
    }
        .fi-rs-p:before {
    content: "\fa59";
    }
        .fi-rs-package:before {
    content: "\fa5a";
    }
        .fi-rs-padlock-check:before {
    content: "\fa5b";
    }
        .fi-rs-page-break:before {
    content: "\fa5c";
    }
        .fi-rs-pager:before {
    content: "\fa5d";
    }
        .fi-rs-paid:before {
    content: "\fa5e";
    }
        .fi-rs-paint-brush:before {
    content: "\fa5f";
    }
        .fi-rs-paint-roller:before {
    content: "\fa60";
    }
        .fi-rs-paintbrush-pencil:before {
    content: "\fa61";
    }
        .fi-rs-palette:before {
    content: "\fa62";
    }
        .fi-rs-pallet-alt:before {
    content: "\fa63";
    }
        .fi-rs-pallet:before {
    content: "\fa64";
    }
        .fi-rs-pan-food:before {
    content: "\fa65";
    }
        .fi-rs-pan-frying:before {
    content: "\fa66";
    }
        .fi-rs-pan:before {
    content: "\fa67";
    }
        .fi-rs-pancakes:before {
    content: "\fa68";
    }
        .fi-rs-panorama:before {
    content: "\fa69";
    }
        .fi-rs-paper-plane-launch:before {
    content: "\fa6a";
    }
        .fi-rs-paper-plane-top:before {
    content: "\fa6b";
    }
        .fi-rs-paper-plane:before {
    content: "\fa6c";
    }
        .fi-rs-paperclip-vertical:before {
    content: "\fa6d";
    }
        .fi-rs-parachute-box:before {
    content: "\fa6e";
    }
        .fi-rs-paragraph-left:before {
    content: "\fa6f";
    }
        .fi-rs-paragraph:before {
    content: "\fa70";
    }
        .fi-rs-parking-circle-slash:before {
    content: "\fa71";
    }
        .fi-rs-parking-circle:before {
    content: "\fa72";
    }
        .fi-rs-parking-slash:before {
    content: "\fa73";
    }
        .fi-rs-parking:before {
    content: "\fa74";
    }
        .fi-rs-party-bell:before {
    content: "\fa75";
    }
        .fi-rs-party-horn:before {
    content: "\fa76";
    }
        .fi-rs-passport:before {
    content: "\fa77";
    }
        .fi-rs-password-alt:before {
    content: "\fa78";
    }
        .fi-rs-password-computer:before {
    content: "\fa79";
    }
        .fi-rs-password-email:before {
    content: "\fa7a";
    }
        .fi-rs-password-smartphone:before {
    content: "\fa7b";
    }
        .fi-rs-password:before {
    content: "\fa7c";
    }
        .fi-rs-paste:before {
    content: "\fa7d";
    }
        .fi-rs-pattern:before {
    content: "\fa7e";
    }
        .fi-rs-pause-circle:before {
    content: "\fa7f";
    }
        .fi-rs-pause-square:before {
    content: "\fa80";
    }
        .fi-rs-pause:before {
    content: "\fa81";
    }
        .fi-rs-paw-claws:before {
    content: "\fa82";
    }
        .fi-rs-paw-heart:before {
    content: "\fa83";
    }
        .fi-rs-paw:before {
    content: "\fa84";
    }
        .fi-rs-payment-pos:before {
    content: "\fa85";
    }
        .fi-rs-payroll-calendar:before {
    content: "\fa86";
    }
        .fi-rs-payroll-check:before {
    content: "\fa87";
    }
        .fi-rs-payroll:before {
    content: "\fa88";
    }
        .fi-rs-peace:before {
    content: "\fa89";
    }
        .fi-rs-peach:before {
    content: "\fa8a";
    }
        .fi-rs-peanut:before {
    content: "\fa8b";
    }
        .fi-rs-peanuts:before {
    content: "\fa8c";
    }
        .fi-rs-peapod:before {
    content: "\fa8d";
    }
        .fi-rs-pear:before {
    content: "\fa8e";
    }
        .fi-rs-pedestal:before {
    content: "\fa8f";
    }
        .fi-rs-pen-circle:before {
    content: "\fa90";
    }
        .fi-rs-pen-clip-slash:before {
    content: "\fa91";
    }
        .fi-rs-pen-clip:before {
    content: "\fa92";
    }
        .fi-rs-pen-fancy-slash:before {
    content: "\fa93";
    }
        .fi-rs-pen-fancy:before {
    content: "\fa94";
    }
        .fi-rs-pen-field:before {
    content: "\fa95";
    }
        .fi-rs-pen-nib-slash:before {
    content: "\fa96";
    }
        .fi-rs-pen-nib:before {
    content: "\fa97";
    }
        .fi-rs-pen-slash:before {
    content: "\fa98";
    }
        .fi-rs-pen-square:before {
    content: "\fa99";
    }
        .fi-rs-pen-swirl:before {
    content: "\fa9a";
    }
        .fi-rs-pencil-paintbrush:before {
    content: "\fa9b";
    }
        .fi-rs-pencil-ruler:before {
    content: "\fa9c";
    }
        .fi-rs-pencil-slash:before {
    content: "\fa9d";
    }
        .fi-rs-pencil:before {
    content: "\fa9e";
    }
        .fi-rs-pending:before {
    content: "\fa9f";
    }
        .fi-rs-pennant:before {
    content: "\faa0";
    }
        .fi-rs-people-arrows-left-right:before {
    content: "\faa1";
    }
        .fi-rs-people-carry-box:before {
    content: "\faa2";
    }
        .fi-rs-people-dress:before {
    content: "\faa3";
    }
        .fi-rs-people-network-partner:before {
    content: "\faa4";
    }
        .fi-rs-people-pants:before {
    content: "\faa5";
    }
        .fi-rs-people-poll:before {
    content: "\faa6";
    }
        .fi-rs-people-pulling:before {
    content: "\faa7";
    }
        .fi-rs-people-roof:before {
    content: "\faa8";
    }
        .fi-rs-people:before {
    content: "\faa9";
    }
        .fi-rs-pepper-alt:before {
    content: "\faaa";
    }
        .fi-rs-pepper-hot:before {
    content: "\faab";
    }
        .fi-rs-pepper:before {
    content: "\faac";
    }
        .fi-rs-percent-10:before {
    content: "\faad";
    }
        .fi-rs-percent-100:before {
    content: "\faae";
    }
        .fi-rs-percent-20:before {
    content: "\faaf";
    }
        .fi-rs-percent-25:before {
    content: "\fab0";
    }
        .fi-rs-percent-30:before {
    content: "\fab1";
    }
        .fi-rs-percent-40:before {
    content: "\fab2";
    }
        .fi-rs-percent-50:before {
    content: "\fab3";
    }
        .fi-rs-percent-60:before {
    content: "\fab4";
    }
        .fi-rs-percent-70:before {
    content: "\fab5";
    }
        .fi-rs-percent-75:before {
    content: "\fab6";
    }
        .fi-rs-percent-80:before {
    content: "\fab7";
    }
        .fi-rs-percent-90:before {
    content: "\fab8";
    }
        .fi-rs-percentage:before {
    content: "\fab9";
    }
        .fi-rs-person-battery:before {
    content: "\faba";
    }
        .fi-rs-person-burst:before {
    content: "\fabb";
    }
        .fi-rs-person-carry-box:before {
    content: "\fabc";
    }
        .fi-rs-person-circle-check:before {
    content: "\fabd";
    }
        .fi-rs-person-circle-exclamation:before {
    content: "\fabe";
    }
        .fi-rs-person-circle-minus:before {
    content: "\fabf";
    }
        .fi-rs-person-circle-plus:before {
    content: "\fac0";
    }
        .fi-rs-person-circle-question:before {
    content: "\fac1";
    }
        .fi-rs-person-circle-xmark:before {
    content: "\fac2";
    }
        .fi-rs-person-cv:before {
    content: "\fac3";
    }
        .fi-rs-person-dolly-empty:before {
    content: "\fac4";
    }
        .fi-rs-person-dolly:before {
    content: "\fac5";
    }
        .fi-rs-person-dragging-bag:before {
    content: "\fac6";
    }
        .fi-rs-person-dress-simple:before {
    content: "\fac7";
    }
        .fi-rs-person-dress:before {
    content: "\fac8";
    }
        .fi-rs-person-luggage:before {
    content: "\fac9";
    }
        .fi-rs-person-lunge:before {
    content: "\faca";
    }
        .fi-rs-person-pilates:before {
    content: "\facb";
    }
        .fi-rs-person-praying:before {
    content: "\facc";
    }
        .fi-rs-person-pregnant:before {
    content: "\facd";
    }
        .fi-rs-person-seat-reclined:before {
    content: "\face";
    }
        .fi-rs-person-seat:before {
    content: "\facf";
    }
        .fi-rs-person-shelter:before {
    content: "\fad0";
    }
        .fi-rs-person-sign:before {
    content: "\fad1";
    }
        .fi-rs-person-simple:before {
    content: "\fad2";
    }
        .fi-rs-person-stress:before {
    content: "\fad3";
    }
        .fi-rs-person-walking-with-cane:before {
    content: "\fad4";
    }
        .fi-rs-peseta-sign:before {
    content: "\fad5";
    }
        .fi-rs-peso-sign:before {
    content: "\fad6";
    }
        .fi-rs-pets:before {
    content: "\fad7";
    }
        .fi-rs-pharmacy:before {
    content: "\fad8";
    }
        .fi-rs-phone-call:before {
    content: "\fad9";
    }
        .fi-rs-phone-cross:before {
    content: "\fada";
    }
        .fi-rs-phone-flip:before {
    content: "\fadb";
    }
        .fi-rs-phone-guide:before {
    content: "\fadc";
    }
        .fi-rs-phone-heart-message:before {
    content: "\fadd";
    }
        .fi-rs-phone-office:before {
    content: "\fade";
    }
        .fi-rs-phone-pause:before {
    content: "\fadf";
    }
        .fi-rs-phone-plus:before {
    content: "\fae0";
    }
        .fi-rs-phone-rotary:before {
    content: "\fae1";
    }
        .fi-rs-phone-slash:before {
    content: "\fae2";
    }
        .fi-rs-photo-capture:before {
    content: "\fae3";
    }
        .fi-rs-photo-film-music:before {
    content: "\fae4";
    }
        .fi-rs-photo-video:before {
    content: "\fae5";
    }
        .fi-rs-physics:before {
    content: "\fae6";
    }
        .fi-rs-Pi:before {
    content: "\fae7";
    }
        .fi-rs-piano-keyboard:before {
    content: "\fae8";
    }
        .fi-rs-piano:before {
    content: "\fae9";
    }
        .fi-rs-pickaxe:before {
    content: "\faea";
    }
        .fi-rs-picking-box:before {
    content: "\faeb";
    }
        .fi-rs-picking:before {
    content: "\faec";
    }
        .fi-rs-picnic:before {
    content: "\faed";
    }
        .fi-rs-picpeople-filled:before {
    content: "\faee";
    }
        .fi-rs-picpeople:before {
    content: "\faef";
    }
        .fi-rs-picture:before {
    content: "\faf0";
    }
        .fi-rs-pie:before {
    content: "\faf1";
    }
        .fi-rs-pig-bank-bulb:before {
    content: "\faf2";
    }
        .fi-rs-pig-face:before {
    content: "\faf3";
    }
        .fi-rs-pig:before {
    content: "\faf4";
    }
        .fi-rs-piggy-bank-budget:before {
    content: "\faf5";
    }
        .fi-rs-piggy-bank:before {
    content: "\faf6";
    }
        .fi-rs-pills:before {
    content: "\faf7";
    }
        .fi-rs-pinata:before {
    content: "\faf8";
    }
        .fi-rs-pineapple-alt:before {
    content: "\faf9";
    }
        .fi-rs-pineapple:before {
    content: "\fafa";
    }
        .fi-rs-ping-pong:before {
    content: "\fafb";
    }
        .fi-rs-pipe-smoking:before {
    content: "\fafc";
    }
        .fi-rs-pixabay:before {
    content: "\fafd";
    }
        .fi-rs-pizza-slice:before {
    content: "\fafe";
    }
        .fi-rs-place-of-worship:before {
    content: "\faff";
    }
        .fi-rs-plan-strategy:before {
    content: "\fb00";
    }
        .fi-rs-plan:before {
    content: "\fb01";
    }
        .fi-rs-plane-alt:before {
    content: "\fb02";
    }
        .fi-rs-plane-arrival:before {
    content: "\fb03";
    }
        .fi-rs-plane-departure:before {
    content: "\fb04";
    }
        .fi-rs-plane-prop:before {
    content: "\fb05";
    }
        .fi-rs-plane-slash:before {
    content: "\fb06";
    }
        .fi-rs-plane-tail:before {
    content: "\fb07";
    }
        .fi-rs-plane:before {
    content: "\fb08";
    }
        .fi-rs-planet-moon:before {
    content: "\fb09";
    }
        .fi-rs-planet-ringed:before {
    content: "\fb0a";
    }
        .fi-rs-plant-care:before {
    content: "\fb0b";
    }
        .fi-rs-plant-growth:before {
    content: "\fb0c";
    }
        .fi-rs-plant-seed-invest:before {
    content: "\fb0d";
    }
        .fi-rs-plant-wilt:before {
    content: "\fb0e";
    }
        .fi-rs-plate-empty:before {
    content: "\fb0f";
    }
        .fi-rs-plate-utensils:before {
    content: "\fb10";
    }
        .fi-rs-plate-wheat:before {
    content: "\fb11";
    }
        .fi-rs-plate:before {
    content: "\fb12";
    }
        .fi-rs-play-alt:before {
    content: "\fb13";
    }
        .fi-rs-play-circle:before {
    content: "\fb14";
    }
        .fi-rs-play-microphone:before {
    content: "\fb15";
    }
        .fi-rs-play-pause:before {
    content: "\fb16";
    }
        .fi-rs-play:before {
    content: "\fb17";
    }
        .fi-rs-playing-cards:before {
    content: "\fb18";
    }
        .fi-rs-plug-alt:before {
    content: "\fb19";
    }
        .fi-rs-plug-cable:before {
    content: "\fb1a";
    }
        .fi-rs-plug-circle-bolt:before {
    content: "\fb1b";
    }
        .fi-rs-plug-circle-check:before {
    content: "\fb1c";
    }
        .fi-rs-plug-circle-exclamation:before {
    content: "\fb1d";
    }
        .fi-rs-plug-circle-minus:before {
    content: "\fb1e";
    }
        .fi-rs-plug-circle-plus:before {
    content: "\fb1f";
    }
        .fi-rs-plug-connection:before {
    content: "\fb20";
    }
        .fi-rs-plug:before {
    content: "\fb21";
    }
        .fi-rs-plus-hexagon:before {
    content: "\fb22";
    }
        .fi-rs-plus-minus:before {
    content: "\fb23";
    }
        .fi-rs-plus-small:before {
    content: "\fb24";
    }
        .fi-rs-plus:before {
    content: "\fb25";
    }
        .fi-rs-png-file:before {
    content: "\fb26";
    }
        .fi-rs-podcast:before {
    content: "\fb27";
    }
        .fi-rs-podium-star:before {
    content: "\fb28";
    }
        .fi-rs-podium-victory-leader:before {
    content: "\fb29";
    }
        .fi-rs-podium:before {
    content: "\fb2a";
    }
        .fi-rs-point-of-sale-bill:before {
    content: "\fb2b";
    }
        .fi-rs-point-of-sale-signal:before {
    content: "\fb2c";
    }
        .fi-rs-point-of-sale:before {
    content: "\fb2d";
    }
        .fi-rs-pointer-loading:before {
    content: "\fb2e";
    }
        .fi-rs-pointer-text:before {
    content: "\fb2f";
    }
        .fi-rs-poker-chip:before {
    content: "\fb30";
    }
        .fi-rs-police-box:before {
    content: "\fb31";
    }
        .fi-rs-poll-h:before {
    content: "\fb32";
    }
        .fi-rs-pollution:before {
    content: "\fb33";
    }
        .fi-rs-pompebled:before {
    content: "\fb34";
    }
        .fi-rs-poo-bolt:before {
    content: "\fb35";
    }
        .fi-rs-poo:before {
    content: "\fb36";
    }
        .fi-rs-pool-8-ball:before {
    content: "\fb37";
    }
        .fi-rs-poop:before {
    content: "\fb38";
    }
        .fi-rs-popcorn:before {
    content: "\fb39";
    }
        .fi-rs-popsicle:before {
    content: "\fb3a";
    }
        .fi-rs-portal-enter:before {
    content: "\fb3b";
    }
        .fi-rs-portal-exit:before {
    content: "\fb3c";
    }
        .fi-rs-portrait:before {
    content: "\fb3d";
    }
        .fi-rs-portuguese:before {
    content: "\fb3e";
    }
        .fi-rs-postal-address:before {
    content: "\fb3f";
    }
        .fi-rs-pot:before {
    content: "\fb40";
    }
        .fi-rs-potato:before {
    content: "\fb41";
    }
        .fi-rs-pound:before {
    content: "\fb42";
    }
        .fi-rs-ppt-file:before {
    content: "\fb43";
    }
        .fi-rs-practice:before {
    content: "\fb44";
    }
        .fi-rs-praying-hands:before {
    content: "\fb45";
    }
        .fi-rs-prescription-bottle-alt:before {
    content: "\fb46";
    }
        .fi-rs-prescription-bottle-pill:before {
    content: "\fb47";
    }
        .fi-rs-prescription-bottle:before {
    content: "\fb48";
    }
        .fi-rs-prescription:before {
    content: "\fb49";
    }
        .fi-rs-presentation:before {
    content: "\fb4a";
    }
        .fi-rs-preview:before {
    content: "\fb4b";
    }
        .fi-rs-previous-square:before {
    content: "\fb4c";
    }
        .fi-rs-print-magnifying-glass:before {
    content: "\fb4d";
    }
        .fi-rs-print-slash:before {
    content: "\fb4e";
    }
        .fi-rs-print:before {
    content: "\fb4f";
    }
        .fi-rs-priority-arrow:before {
    content: "\fb50";
    }
        .fi-rs-priority-arrows:before {
    content: "\fb51";
    }
        .fi-rs-priority-importance:before {
    content: "\fb52";
    }
        .fi-rs-problem-solving:before {
    content: "\fb53";
    }
        .fi-rs-procedures:before {
    content: "\fb54";
    }
        .fi-rs-process:before {
    content: "\fb55";
    }
        .fi-rs-productivity:before {
    content: "\fb56";
    }
        .fi-rs-progress-complete:before {
    content: "\fb57";
    }
        .fi-rs-progress-download:before {
    content: "\fb58";
    }
        .fi-rs-progress-upload:before {
    content: "\fb59";
    }
        .fi-rs-projector:before {
    content: "\fb5a";
    }
        .fi-rs-protractor:before {
    content: "\fb5b";
    }
        .fi-rs-pulse:before {
    content: "\fb5c";
    }
        .fi-rs-pump-medical:before {
    content: "\fb5d";
    }
        .fi-rs-pump:before {
    content: "\fb5e";
    }
        .fi-rs-pumpkin-alt-2:before {
    content: "\fb5f";
    }
        .fi-rs-pumpkin-alt:before {
    content: "\fb60";
    }
        .fi-rs-pumpkin:before {
    content: "\fb61";
    }
        .fi-rs-puzzle-alt:before {
    content: "\fb62";
    }
        .fi-rs-puzzle-piece-integration:before {
    content: "\fb63";
    }
        .fi-rs-puzzle-piece:before {
    content: "\fb64";
    }
        .fi-rs-puzzle-pieces:before {
    content: "\fb65";
    }
        .fi-rs-pyramid:before {
    content: "\fb66";
    }
        .fi-rs-q:before {
    content: "\fb67";
    }
        .fi-rs-qr-scan:before {
    content: "\fb68";
    }
        .fi-rs-QR:before {
    content: "\fb69";
    }
        .fi-rs-qrcode:before {
    content: "\fb6a";
    }
        .fi-rs-question-square:before {
    content: "\fb6b";
    }
        .fi-rs-question:before {
    content: "\fb6c";
    }
        .fi-rs-queue-alt:before {
    content: "\fb6d";
    }
        .fi-rs-queue-line:before {
    content: "\fb6e";
    }
        .fi-rs-queue-signal:before {
    content: "\fb6f";
    }
        .fi-rs-queue:before {
    content: "\fb70";
    }
        .fi-rs-quill-pen-story:before {
    content: "\fb71";
    }
        .fi-rs-quiz-alt:before {
    content: "\fb72";
    }
        .fi-rs-quiz:before {
    content: "\fb73";
    }
        .fi-rs-quote-right:before {
    content: "\fb74";
    }
        .fi-rs-r:before {
    content: "\fb75";
    }
        .fi-rs-rabbit-fast:before {
    content: "\fb76";
    }
        .fi-rs-rabbit:before {
    content: "\fb77";
    }
        .fi-rs-raccoon:before {
    content: "\fb78";
    }
        .fi-rs-racquet:before {
    content: "\fb79";
    }
        .fi-rs-radar-monitoring-track:before {
    content: "\fb7a";
    }
        .fi-rs-radar:before {
    content: "\fb7b";
    }
        .fi-rs-radiation-alt:before {
    content: "\fb7c";
    }
        .fi-rs-radiation:before {
    content: "\fb7d";
    }
        .fi-rs-radio-alt:before {
    content: "\fb7e";
    }
        .fi-rs-radio-button:before {
    content: "\fb7f";
    }
        .fi-rs-radio:before {
    content: "\fb80";
    }
        .fi-rs-radish:before {
    content: "\fb81";
    }
        .fi-rs-rainbow:before {
    content: "\fb82";
    }
        .fi-rs-raindrops:before {
    content: "\fb83";
    }
        .fi-rs-ram:before {
    content: "\fb84";
    }
        .fi-rs-ramp-loading:before {
    content: "\fb85";
    }
        .fi-rs-rank:before {
    content: "\fb86";
    }
        .fi-rs-ranking-podium-empty:before {
    content: "\fb87";
    }
        .fi-rs-ranking-podium:before {
    content: "\fb88";
    }
        .fi-rs-ranking-star:before {
    content: "\fb89";
    }
        .fi-rs-ranking-stars:before {
    content: "\fb8a";
    }
        .fi-rs-raygun:before {
    content: "\fb8b";
    }
        .fi-rs-razor-barber:before {
    content: "\fb8c";
    }
        .fi-rs-react:before {
    content: "\fb8d";
    }
        .fi-rs-rec:before {
    content: "\fb8e";
    }
        .fi-rs-receipt:before {
    content: "\fb8f";
    }
        .fi-rs-recipe-book:before {
    content: "\fb90";
    }
        .fi-rs-recipe:before {
    content: "\fb91";
    }
        .fi-rs-record-vinyl:before {
    content: "\fb92";
    }
        .fi-rs-rectabgle-vertical:before {
    content: "\fb93";
    }
        .fi-rs-rectangle-barcode:before {
    content: "\fb94";
    }
        .fi-rs-rectangle-code:before {
    content: "\fb95";
    }
        .fi-rs-rectangle-history-circle-plus:before {
    content: "\fb96";
    }
        .fi-rs-rectangle-horizontal:before {
    content: "\fb97";
    }
        .fi-rs-rectangle-list:before {
    content: "\fb98";
    }
        .fi-rs-rectangle-panoramic:before {
    content: "\fb99";
    }
        .fi-rs-rectangle-pro:before {
    content: "\fb9a";
    }
        .fi-rs-rectangle-vertical-history:before {
    content: "\fb9b";
    }
        .fi-rs-rectangle-vertical:before {
    content: "\fb9c";
    }
        .fi-rs-rectangle-xmark:before {
    content: "\fb9d";
    }
        .fi-rs-rectangles-mixed:before {
    content: "\fb9e";
    }
        .fi-rs-recycle-bin:before {
    content: "\fb9f";
    }
        .fi-rs-recycle:before {
    content: "\fba0";
    }
        .fi-rs-redo-alt:before {
    content: "\fba1";
    }
        .fi-rs-redo:before {
    content: "\fba2";
    }
        .fi-rs-reel:before {
    content: "\fba3";
    }
        .fi-rs-refer-arrow:before {
    content: "\fba4";
    }
        .fi-rs-refer:before {
    content: "\fba5";
    }
        .fi-rs-referral-alt:before {
    content: "\fba6";
    }
        .fi-rs-referral-user:before {
    content: "\fba7";
    }
        .fi-rs-referral:before {
    content: "\fba8";
    }
        .fi-rs-reflect-horizontal-alt:before {
    content: "\fba9";
    }
        .fi-rs-reflect-horizontal:before {
    content: "\fbaa";
    }
        .fi-rs-reflect-vertical:before {
    content: "\fbab";
    }
        .fi-rs-reflect:before {
    content: "\fbac";
    }
        .fi-rs-refresh:before {
    content: "\fbad";
    }
        .fi-rs-refrigerator:before {
    content: "\fbae";
    }
        .fi-rs-refund-alt:before {
    content: "\fbaf";
    }
        .fi-rs-refund:before {
    content: "\fbb0";
    }
        .fi-rs-region-pin-alt:before {
    content: "\fbb1";
    }
        .fi-rs-region-pin:before {
    content: "\fbb2";
    }
        .fi-rs-registered:before {
    content: "\fbb3";
    }
        .fi-rs-registration-paper:before {
    content: "\fbb4";
    }
        .fi-rs-remove-folder:before {
    content: "\fbb5";
    }
        .fi-rs-remove-user:before {
    content: "\fbb6";
    }
        .fi-rs-rent-signal:before {
    content: "\fbb7";
    }
        .fi-rs-rent:before {
    content: "\fbb8";
    }
        .fi-rs-replace:before {
    content: "\fbb9";
    }
        .fi-rs-replay-10:before {
    content: "\fbba";
    }
        .fi-rs-replay-30:before {
    content: "\fbbb";
    }
        .fi-rs-replay-5:before {
    content: "\fbbc";
    }
        .fi-rs-reply-all:before {
    content: "\fbbd";
    }
        .fi-rs-republican:before {
    content: "\fbbe";
    }
        .fi-rs-research-arrows-circle:before {
    content: "\fbbf";
    }
        .fi-rs-reservation-smartphone:before {
    content: "\fbc0";
    }
        .fi-rs-reservation-table:before {
    content: "\fbc1";
    }
        .fi-rs-resistance-band:before {
    content: "\fbc2";
    }
        .fi-rs-resize:before {
    content: "\fbc3";
    }
        .fi-rs-resources:before {
    content: "\fbc4";
    }
        .fi-rs-responsability:before {
    content: "\fbc5";
    }
        .fi-rs-restaurant:before {
    content: "\fbc6";
    }
        .fi-rs-restock:before {
    content: "\fbc7";
    }
        .fi-rs-restroom-simple:before {
    content: "\fbc8";
    }
        .fi-rs-resume:before {
    content: "\fbc9";
    }
        .fi-rs-revenue-alt:before {
    content: "\fbca";
    }
        .fi-rs-revenue-euro:before {
    content: "\fbcb";
    }
        .fi-rs-Revenue:before {
    content: "\fbcc";
    }
        .fi-rs-review:before {
    content: "\fbcd";
    }
        .fi-rs-rewind-button-circle:before {
    content: "\fbce";
    }
        .fi-rs-rewind:before {
    content: "\fbcf";
    }
        .fi-rs-rhombus:before {
    content: "\fbd0";
    }
        .fi-rs-ribbon:before {
    content: "\fbd1";
    }
        .fi-rs-right-from-bracket:before {
    content: "\fbd2";
    }
        .fi-rs-right:before {
    content: "\fbd3";
    }
        .fi-rs-ring-diamond:before {
    content: "\fbd4";
    }
        .fi-rs-ring:before {
    content: "\fbd5";
    }
        .fi-rs-rings-wedding:before {
    content: "\fbd6";
    }
        .fi-rs-risk-alt:before {
    content: "\fbd7";
    }
        .fi-rs-risk:before {
    content: "\fbd8";
    }
        .fi-rs-road-barrier:before {
    content: "\fbd9";
    }
        .fi-rs-road-sign-left:before {
    content: "\fbda";
    }
        .fi-rs-road:before {
    content: "\fbdb";
    }
        .fi-rs-robot:before {
    content: "\fbdc";
    }
        .fi-rs-robotic-arm:before {
    content: "\fbdd";
    }
        .fi-rs-rocket-holding-hand:before {
    content: "\fbde";
    }
        .fi-rs-rocket-lunch:before {
    content: "\fbdf";
    }
        .fi-rs-rocket:before {
    content: "\fbe0";
    }
        .fi-rs-roller-coaster:before {
    content: "\fbe1";
    }
        .fi-rs-room-service:before {
    content: "\fbe2";
    }
        .fi-rs-rotate-exclamation:before {
    content: "\fbe3";
    }
        .fi-rs-rotate-left:before {
    content: "\fbe4";
    }
        .fi-rs-rotate-reverse:before {
    content: "\fbe5";
    }
        .fi-rs-rotate-right:before {
    content: "\fbe6";
    }
        .fi-rs-rotate-square:before {
    content: "\fbe7";
    }
        .fi-rs-roulette:before {
    content: "\fbe8";
    }
        .fi-rs-route-highway:before {
    content: "\fbe9";
    }
        .fi-rs-route-interstate:before {
    content: "\fbea";
    }
        .fi-rs-route:before {
    content: "\fbeb";
    }
        .fi-rs-router:before {
    content: "\fbec";
    }
        .fi-rs-rss-alt:before {
    content: "\fbed";
    }
        .fi-rs-rss:before {
    content: "\fbee";
    }
        .fi-rs-ruble-sign:before {
    content: "\fbef";
    }
        .fi-rs-rugby-helmet:before {
    content: "\fbf0";
    }
        .fi-rs-rugby:before {
    content: "\fbf1";
    }
        .fi-rs-ruler-combined:before {
    content: "\fbf2";
    }
        .fi-rs-ruler-horizontal:before {
    content: "\fbf3";
    }
        .fi-rs-ruler-triangle:before {
    content: "\fbf4";
    }
        .fi-rs-ruler-vertical:before {
    content: "\fbf5";
    }
        .fi-rs-rules-alt:before {
    content: "\fbf6";
    }
        .fi-rs-rules:before {
    content: "\fbf7";
    }
        .fi-rs-running-track:before {
    content: "\fbf8";
    }
        .fi-rs-running:before {
    content: "\fbf9";
    }
        .fi-rs-rupee-sign:before {
    content: "\fbfa";
    }
        .fi-rs-rupiah-sign:before {
    content: "\fbfb";
    }
        .fi-rs-russian:before {
    content: "\fbfc";
    }
        .fi-rs-rv:before {
    content: "\fbfd";
    }
        .fi-rs-s:before {
    content: "\fbfe";
    }
        .fi-rs-sack-dollar:before {
    content: "\fbff";
    }
        .fi-rs-sack:before {
    content: "\fc00";
    }
        .fi-rs-sad-cry:before {
    content: "\fc01";
    }
        .fi-rs-sad-tear:before {
    content: "\fc02";
    }
        .fi-rs-sad:before {
    content: "\fc03";
    }
        .fi-rs-safe-box:before {
    content: "\fc04";
    }
        .fi-rs-sailboat:before {
    content: "\fc05";
    }
        .fi-rs-salad:before {
    content: "\fc06";
    }
        .fi-rs-salary-alt:before {
    content: "\fc07";
    }
        .fi-rs-salt-pepper:before {
    content: "\fc08";
    }
        .fi-rs-salt-shaker:before {
    content: "\fc09";
    }
        .fi-rs-sandwich-alt:before {
    content: "\fc0a";
    }
        .fi-rs-sandwich:before {
    content: "\fc0b";
    }
        .fi-rs-satellite-dish:before {
    content: "\fc0c";
    }
        .fi-rs-satellite:before {
    content: "\fc0d";
    }
        .fi-rs-saturday:before {
    content: "\fc0e";
    }
        .fi-rs-sauce:before {
    content: "\fc0f";
    }
        .fi-rs-sausage:before {
    content: "\fc10";
    }
        .fi-rs-sax-hot:before {
    content: "\fc11";
    }
        .fi-rs-saxophone:before {
    content: "\fc12";
    }
        .fi-rs-scale-comparison-alt:before {
    content: "\fc13";
    }
        .fi-rs-scale-comparison:before {
    content: "\fc14";
    }
        .fi-rs-scale:before {
    content: "\fc15";
    }
        .fi-rs-scalpel-path:before {
    content: "\fc16";
    }
        .fi-rs-scalpel:before {
    content: "\fc17";
    }
        .fi-rs-scanner-gun:before {
    content: "\fc18";
    }
        .fi-rs-scanner-image:before {
    content: "\fc19";
    }
        .fi-rs-scanner-keyboard:before {
    content: "\fc1a";
    }
        .fi-rs-scanner-touchscreen:before {
    content: "\fc1b";
    }
        .fi-rs-scarecrow:before {
    content: "\fc1c";
    }
        .fi-rs-scarf:before {
    content: "\fc1d";
    }
        .fi-rs-school-bus:before {
    content: "\fc1e";
    }
        .fi-rs-school-lock:before {
    content: "\fc1f";
    }
        .fi-rs-school:before {
    content: "\fc20";
    }
        .fi-rs-scissors:before {
    content: "\fc21";
    }
        .fi-rs-scooter:before {
    content: "\fc22";
    }
        .fi-rs-screen-share:before {
    content: "\fc23";
    }
        .fi-rs-screen:before {
    content: "\fc24";
    }
        .fi-rs-screencast:before {
    content: "\fc25";
    }
        .fi-rs-screw-alt:before {
    content: "\fc26";
    }
        .fi-rs-screw:before {
    content: "\fc27";
    }
        .fi-rs-screwdriver:before {
    content: "\fc28";
    }
        .fi-rs-scribble:before {
    content: "\fc29";
    }
        .fi-rs-script:before {
    content: "\fc2a";
    }
        .fi-rs-scroll-document-story:before {
    content: "\fc2b";
    }
        .fi-rs-scroll-old:before {
    content: "\fc2c";
    }
        .fi-rs-scroll-torah:before {
    content: "\fc2d";
    }
        .fi-rs-scroll:before {
    content: "\fc2e";
    }
        .fi-rs-scrubber:before {
    content: "\fc2f";
    }
        .fi-rs-sculpture:before {
    content: "\fc30";
    }
        .fi-rs-scythe:before {
    content: "\fc31";
    }
        .fi-rs-sd-card:before {
    content: "\fc32";
    }
        .fi-rs-sd-cards:before {
    content: "\fc33";
    }
        .fi-rs-seal-exclamation:before {
    content: "\fc34";
    }
        .fi-rs-seal-question:before {
    content: "\fc35";
    }
        .fi-rs-seal:before {
    content: "\fc36";
    }
        .fi-rs-search-alt:before {
    content: "\fc37";
    }
        .fi-rs-search-dollar:before {
    content: "\fc38";
    }
        .fi-rs-search-heart:before {
    content: "\fc39";
    }
        .fi-rs-search-location:before {
    content: "\fc3a";
    }
        .fi-rs-search:before {
    content: "\fc3b";
    }
        .fi-rs-seat-airline:before {
    content: "\fc3c";
    }
        .fi-rs-seatbelt-safety-driver:before {
    content: "\fc3d";
    }
        .fi-rs-second-award:before {
    content: "\fc3e";
    }
        .fi-rs-second-laurel:before {
    content: "\fc3f";
    }
        .fi-rs-second-medal:before {
    content: "\fc40";
    }
        .fi-rs-second:before {
    content: "\fc41";
    }
        .fi-rs-seedling:before {
    content: "\fc42";
    }
        .fi-rs-selection:before {
    content: "\fc43";
    }
        .fi-rs-sell:before {
    content: "\fc44";
    }
        .fi-rs-seller-store:before {
    content: "\fc45";
    }
        .fi-rs-seller:before {
    content: "\fc46";
    }
        .fi-rs-selling:before {
    content: "\fc47";
    }
        .fi-rs-send-back:before {
    content: "\fc48";
    }
        .fi-rs-send-backward:before {
    content: "\fc49";
    }
        .fi-rs-send-money-smartphone:before {
    content: "\fc4a";
    }
        .fi-rs-send-money:before {
    content: "\fc4b";
    }
        .fi-rs-sensor-alert:before {
    content: "\fc4c";
    }
        .fi-rs-sensor-fire:before {
    content: "\fc4d";
    }
        .fi-rs-sensor-on:before {
    content: "\fc4e";
    }
        .fi-rs-sensor-smoke:before {
    content: "\fc4f";
    }
        .fi-rs-sensor:before {
    content: "\fc50";
    }
        .fi-rs-settings-sliders:before {
    content: "\fc51";
    }
        .fi-rs-settings:before {
    content: "\fc52";
    }
        .fi-rs-share-alt-square:before {
    content: "\fc53";
    }
        .fi-rs-share-square:before {
    content: "\fc54";
    }
        .fi-rs-share:before {
    content: "\fc55";
    }
        .fi-rs-sheep:before {
    content: "\fc56";
    }
        .fi-rs-shekel-sign:before {
    content: "\fc57";
    }
        .fi-rs-shelves-empty:before {
    content: "\fc58";
    }
        .fi-rs-shelves:before {
    content: "\fc59";
    }
        .fi-rs-shield-alt:before {
    content: "\fc5a";
    }
        .fi-rs-shield-cat:before {
    content: "\fc5b";
    }
        .fi-rs-shield-check:before {
    content: "\fc5c";
    }
        .fi-rs-shield-cross:before {
    content: "\fc5d";
    }
        .fi-rs-shield-divided-four:before {
    content: "\fc5e";
    }
        .fi-rs-shield-dog:before {
    content: "\fc5f";
    }
        .fi-rs-shield-exclamation:before {
    content: "\fc60";
    }
        .fi-rs-shield-interrogation:before {
    content: "\fc61";
    }
        .fi-rs-shield-keyhole:before {
    content: "\fc62";
    }
        .fi-rs-shield-minus:before {
    content: "\fc63";
    }
        .fi-rs-shield-plus:before {
    content: "\fc64";
    }
        .fi-rs-shield-security-risk:before {
    content: "\fc65";
    }
        .fi-rs-shield-slash:before {
    content: "\fc66";
    }
        .fi-rs-shield-trust:before {
    content: "\fc67";
    }
        .fi-rs-shield-virus:before {
    content: "\fc68";
    }
        .fi-rs-shield-xmark:before {
    content: "\fc69";
    }
        .fi-rs-shield:before {
    content: "\fc6a";
    }
        .fi-rs-ship-side:before {
    content: "\fc6b";
    }
        .fi-rs-ship:before {
    content: "\fc6c";
    }
        .fi-rs-shipping-fast:before {
    content: "\fc6d";
    }
        .fi-rs-shipping-timed:before {
    content: "\fc6e";
    }
        .fi-rs-shirt-long-sleeve:before {
    content: "\fc6f";
    }
        .fi-rs-shirt-running:before {
    content: "\fc70";
    }
        .fi-rs-shirt-tank-top:before {
    content: "\fc71";
    }
        .fi-rs-shirt:before {
    content: "\fc72";
    }
        .fi-rs-shish-kebab:before {
    content: "\fc73";
    }
        .fi-rs-shoe-prints:before {
    content: "\fc74";
    }
        .fi-rs-shop-lock:before {
    content: "\fc75";
    }
        .fi-rs-shop-slash:before {
    content: "\fc76";
    }
        .fi-rs-shop:before {
    content: "\fc77";
    }
        .fi-rs-shopping-bag-add:before {
    content: "\fc78";
    }
        .fi-rs-shopping-bag:before {
    content: "\fc79";
    }
        .fi-rs-shopping-basket:before {
    content: "\fc7a";
    }
        .fi-rs-shopping-cart-add:before {
    content: "\fc7b";
    }
        .fi-rs-shopping-cart-buyer:before {
    content: "\fc7c";
    }
        .fi-rs-shopping-cart-check:before {
    content: "\fc7d";
    }
        .fi-rs-shopping-cart:before {
    content: "\fc7e";
    }
        .fi-rs-shovel-snow:before {
    content: "\fc7f";
    }
        .fi-rs-shovel:before {
    content: "\fc80";
    }
        .fi-rs-shower-down:before {
    content: "\fc81";
    }
        .fi-rs-shower:before {
    content: "\fc82";
    }
        .fi-rs-shredder:before {
    content: "\fc83";
    }
        .fi-rs-shrimp:before {
    content: "\fc84";
    }
        .fi-rs-shuffle:before {
    content: "\fc85";
    }
        .fi-rs-shuttle-van:before {
    content: "\fc86";
    }
        .fi-rs-shuttlecock:before {
    content: "\fc87";
    }
        .fi-rs-Sickle:before {
    content: "\fc88";
    }
        .fi-rs-sidebar-flip:before {
    content: "\fc89";
    }
        .fi-rs-sidebar:before {
    content: "\fc8a";
    }
        .fi-rs-sigma:before {
    content: "\fc8b";
    }
        .fi-rs-sign-hanging:before {
    content: "\fc8c";
    }
        .fi-rs-sign-in-alt:before {
    content: "\fc8d";
    }
        .fi-rs-sign-out-alt:before {
    content: "\fc8e";
    }
        .fi-rs-sign-posts-wrench:before {
    content: "\fc8f";
    }
        .fi-rs-sign-posts:before {
    content: "\fc90";
    }
        .fi-rs-sign-up:before {
    content: "\fc91";
    }
        .fi-rs-signal-alt-1:before {
    content: "\fc92";
    }
        .fi-rs-signal-alt-2:before {
    content: "\fc93";
    }
        .fi-rs-signal-alt-slash:before {
    content: "\fc94";
    }
        .fi-rs-signal-alt:before {
    content: "\fc95";
    }
        .fi-rs-signal-bars-fair:before {
    content: "\fc96";
    }
        .fi-rs-signal-bars-good:before {
    content: "\fc97";
    }
        .fi-rs-signal-bars-weak:before {
    content: "\fc98";
    }
        .fi-rs-signal-stream-slash:before {
    content: "\fc99";
    }
        .fi-rs-signal-stream:before {
    content: "\fc9a";
    }
        .fi-rs-signature-lock:before {
    content: "\fc9b";
    }
        .fi-rs-signature-slash:before {
    content: "\fc9c";
    }
        .fi-rs-signature:before {
    content: "\fc9d";
    }
        .fi-rs-sim-card:before {
    content: "\fc9e";
    }
        .fi-rs-sim-cards:before {
    content: "\fc9f";
    }
        .fi-rs-sink:before {
    content: "\fca0";
    }
        .fi-rs-siren-on:before {
    content: "\fca1";
    }
        .fi-rs-Siren:before {
    content: "\fca2";
    }
        .fi-rs-site-alt:before {
    content: "\fca3";
    }
        .fi-rs-site-browser:before {
    content: "\fca4";
    }
        .fi-rs-site:before {
    content: "\fca5";
    }
        .fi-rs-sitemap:before {
    content: "\fca6";
    }
        .fi-rs-skateboard:before {
    content: "\fca7";
    }
        .fi-rs-skating:before {
    content: "\fca8";
    }
        .fi-rs-skeleton-ribs:before {
    content: "\fca9";
    }
        .fi-rs-skeleton:before {
    content: "\fcaa";
    }
        .fi-rs-skewer:before {
    content: "\fcab";
    }
        .fi-rs-ski-boot-ski:before {
    content: "\fcac";
    }
        .fi-rs-ski-jump:before {
    content: "\fcad";
    }
        .fi-rs-ski-lift:before {
    content: "\fcae";
    }
        .fi-rs-skiing-nordic:before {
    content: "\fcaf";
    }
        .fi-rs-skiing:before {
    content: "\fcb0";
    }
        .fi-rs-skill-alt:before {
    content: "\fcb1";
    }
        .fi-rs-skill-user:before {
    content: "\fcb2";
    }
        .fi-rs-skill:before {
    content: "\fcb3";
    }
        .fi-rs-skin-acne:before {
    content: "\fcb4";
    }
        .fi-rs-skin-arrow:before {
    content: "\fcb5";
    }
        .fi-rs-skin-drop:before {
    content: "\fcb6";
    }
        .fi-rs-skin-hair:before {
    content: "\fcb7";
    }
        .fi-rs-skin-laser:before {
    content: "\fcb8";
    }
        .fi-rs-skin-uv:before {
    content: "\fcb9";
    }
        .fi-rs-skin:before {
    content: "\fcba";
    }
        .fi-rs-skip-15-seconds:before {
    content: "\fcbb";
    }
        .fi-rs-skull-cow:before {
    content: "\fcbc";
    }
        .fi-rs-skull-crossbones:before {
    content: "\fcbd";
    }
        .fi-rs-skull-heart:before {
    content: "\fcbe";
    }
        .fi-rs-skull:before {
    content: "\fcbf";
    }
        .fi-rs-slash:before {
    content: "\fcc0";
    }
        .fi-rs-sledding:before {
    content: "\fcc1";
    }
        .fi-rs-sleeping-bag:before {
    content: "\fcc2";
    }
        .fi-rs-sleeping-cat:before {
    content: "\fcc3";
    }
        .fi-rs-sleigh:before {
    content: "\fcc4";
    }
        .fi-rs-sliders-h-square:before {
    content: "\fcc5";
    }
        .fi-rs-sliders-v-square:before {
    content: "\fcc6";
    }
        .fi-rs-sliders-v:before {
    content: "\fcc7";
    }
        .fi-rs-slot-machine:before {
    content: "\fcc8";
    }
        .fi-rs-smart-home-alt:before {
    content: "\fcc9";
    }
        .fi-rs-smart-home:before {
    content: "\fcca";
    }
        .fi-rs-smartphone:before {
    content: "\fccb";
    }
        .fi-rs-smile-beam:before {
    content: "\fccc";
    }
        .fi-rs-smile-plus:before {
    content: "\fccd";
    }
        .fi-rs-smile-wink:before {
    content: "\fcce";
    }
        .fi-rs-smiley-comment-alt:before {
    content: "\fccf";
    }
        .fi-rs-smog:before {
    content: "\fcd0";
    }
        .fi-rs-smoke:before {
    content: "\fcd1";
    }
        .fi-rs-smoking-ban:before {
    content: "\fcd2";
    }
        .fi-rs-smoking:before {
    content: "\fcd3";
    }
        .fi-rs-snake:before {
    content: "\fcd4";
    }
        .fi-rs-snap:before {
    content: "\fcd5";
    }
        .fi-rs-snooze:before {
    content: "\fcd6";
    }
        .fi-rs-snow-blowing:before {
    content: "\fcd7";
    }
        .fi-rs-snowboarding:before {
    content: "\fcd8";
    }
        .fi-rs-snowflake-droplets:before {
    content: "\fcd9";
    }
        .fi-rs-snowflake:before {
    content: "\fcda";
    }
        .fi-rs-snowflakes:before {
    content: "\fcdb";
    }
        .fi-rs-snowman-alt:before {
    content: "\fcdc";
    }
        .fi-rs-snowman-head:before {
    content: "\fcdd";
    }
        .fi-rs-snowmobile:before {
    content: "\fcde";
    }
        .fi-rs-snowplow:before {
    content: "\fcdf";
    }
        .fi-rs-soap-alt:before {
    content: "\fce0";
    }
        .fi-rs-soap:before {
    content: "\fce1";
    }
        .fi-rs-social-network:before {
    content: "\fce2";
    }
        .fi-rs-socks:before {
    content: "\fce3";
    }
        .fi-rs-sofa-size:before {
    content: "\fce4";
    }
        .fi-rs-sofa:before {
    content: "\fce5";
    }
        .fi-rs-solar-panel-sun:before {
    content: "\fce6";
    }
        .fi-rs-solar-panel:before {
    content: "\fce7";
    }
        .fi-rs-solar-system:before {
    content: "\fce8";
    }
        .fi-rs-sold-house:before {
    content: "\fce9";
    }
        .fi-rs-sold-signal:before {
    content: "\fcea";
    }
        .fi-rs-sort-alpha-down-alt:before {
    content: "\fceb";
    }
        .fi-rs-sort-alpha-down:before {
    content: "\fcec";
    }
        .fi-rs-sort-alpha-up-alt:before {
    content: "\fced";
    }
        .fi-rs-sort-alpha-up:before {
    content: "\fcee";
    }
        .fi-rs-sort-alt:before {
    content: "\fcef";
    }
        .fi-rs-sort-amount-down-alt:before {
    content: "\fcf0";
    }
        .fi-rs-sort-amount-down:before {
    content: "\fcf1";
    }
        .fi-rs-sort-amount-up-alt:before {
    content: "\fcf2";
    }
        .fi-rs-sort-amount-up:before {
    content: "\fcf3";
    }
        .fi-rs-sort-circle-down:before {
    content: "\fcf4";
    }
        .fi-rs-sort-circle-up:before {
    content: "\fcf5";
    }
        .fi-rs-sort-circle:before {
    content: "\fcf6";
    }
        .fi-rs-sort-down:before {
    content: "\fcf7";
    }
        .fi-rs-sort-numeric-down-alt:before {
    content: "\fcf8";
    }
        .fi-rs-sort-numeric-down:before {
    content: "\fcf9";
    }
        .fi-rs-sort-shapes-down:before {
    content: "\fcfa";
    }
        .fi-rs-sort-shapes-up:before {
    content: "\fcfb";
    }
        .fi-rs-sort-size-down:before {
    content: "\fcfc";
    }
        .fi-rs-sort-size-up:before {
    content: "\fcfd";
    }
        .fi-rs-sort:before {
    content: "\fcfe";
    }
        .fi-rs-soup:before {
    content: "\fcff";
    }
        .fi-rs-source-data:before {
    content: "\fd00";
    }
        .fi-rs-source-document-alt:before {
    content: "\fd01";
    }
        .fi-rs-source-document:before {
    content: "\fd02";
    }
        .fi-rs-spa:before {
    content: "\fd03";
    }
        .fi-rs-space-shuttle:before {
    content: "\fd04";
    }
        .fi-rs-space-station-moon-alt:before {
    content: "\fd05";
    }
        .fi-rs-space-station-moon:before {
    content: "\fd06";
    }
        .fi-rs-spade:before {
    content: "\fd07";
    }
        .fi-rs-spaghetti-monster-flying:before {
    content: "\fd08";
    }
        .fi-rs-spain-map:before {
    content: "\fd09";
    }
        .fi-rs-spanish:before {
    content: "\fd0a";
    }
        .fi-rs-sparkles:before {
    content: "\fd0b";
    }
        .fi-rs-spartan-helmet:before {
    content: "\fd0c";
    }
        .fi-rs-speaker:before {
    content: "\fd0d";
    }
        .fi-rs-speakers:before {
    content: "\fd0e";
    }
        .fi-rs-speech-bubble-story:before {
    content: "\fd0f";
    }
        .fi-rs-speedometer-arrow:before {
    content: "\fd10";
    }
        .fi-rs-speedometer-kpi:before {
    content: "\fd11";
    }
        .fi-rs-sphere:before {
    content: "\fd12";
    }
        .fi-rs-spider-black-widow:before {
    content: "\fd13";
    }
        .fi-rs-spider-web:before {
    content: "\fd14";
    }
        .fi-rs-spider:before {
    content: "\fd15";
    }
        .fi-rs-spinner:before {
    content: "\fd16";
    }
        .fi-rs-split-up-relation:before {
    content: "\fd17";
    }
        .fi-rs-split:before {
    content: "\fd18";
    }
        .fi-rs-splotch:before {
    content: "\fd19";
    }
        .fi-rs-spoon:before {
    content: "\fd1a";
    }
        .fi-rs-spray-can-sparkles:before {
    content: "\fd1b";
    }
        .fi-rs-spray-can:before {
    content: "\fd1c";
    }
        .fi-rs-spring-calendar:before {
    content: "\fd1d";
    }
        .fi-rs-sprinkler:before {
    content: "\fd1e";
    }
        .fi-rs-spy:before {
    content: "\fd1f";
    }
        .fi-rs-square-0:before {
    content: "\fd20";
    }
        .fi-rs-square-1:before {
    content: "\fd21";
    }
        .fi-rs-square-2:before {
    content: "\fd22";
    }
        .fi-rs-square-3:before {
    content: "\fd23";
    }
        .fi-rs-square-4:before {
    content: "\fd24";
    }
        .fi-rs-square-5:before {
    content: "\fd25";
    }
        .fi-rs-square-6:before {
    content: "\fd26";
    }
        .fi-rs-square-7:before {
    content: "\fd27";
    }
        .fi-rs-square-8:before {
    content: "\fd28";
    }
        .fi-rs-square-9:before {
    content: "\fd29";
    }
        .fi-rs-square-a:before {
    content: "\fd2a";
    }
        .fi-rs-square-b:before {
    content: "\fd2b";
    }
        .fi-rs-square-bolt:before {
    content: "\fd2c";
    }
        .fi-rs-square-c:before {
    content: "\fd2d";
    }
        .fi-rs-square-code:before {
    content: "\fd2e";
    }
        .fi-rs-square-d:before {
    content: "\fd2f";
    }
        .fi-rs-square-dashed-circle-plus:before {
    content: "\fd30";
    }
        .fi-rs-square-dashed:before {
    content: "\fd31";
    }
        .fi-rs-square-divide:before {
    content: "\fd32";
    }
        .fi-rs-square-e:before {
    content: "\fd33";
    }
        .fi-rs-square-ellipsis-vertical:before {
    content: "\fd34";
    }
        .fi-rs-square-ellipsis:before {
    content: "\fd35";
    }
        .fi-rs-square-exclamation:before {
    content: "\fd36";
    }
        .fi-rs-square-f:before {
    content: "\fd37";
    }
        .fi-rs-square-g:before {
    content: "\fd38";
    }
        .fi-rs-square-h:before {
    content: "\fd39";
    }
        .fi-rs-square-heart:before {
    content: "\fd3a";
    }
        .fi-rs-square-i:before {
    content: "\fd3b";
    }
        .fi-rs-square-info:before {
    content: "\fd3c";
    }
        .fi-rs-square-j:before {
    content: "\fd3d";
    }
        .fi-rs-square-k:before {
    content: "\fd3e";
    }
        .fi-rs-square-kanban:before {
    content: "\fd3f";
    }
        .fi-rs-square-l:before {
    content: "\fd40";
    }
        .fi-rs-square-m:before {
    content: "\fd41";
    }
        .fi-rs-square-minus:before {
    content: "\fd42";
    }
        .fi-rs-square-n:before {
    content: "\fd43";
    }
        .fi-rs-square-o:before {
    content: "\fd44";
    }
        .fi-rs-square-p:before {
    content: "\fd45";
    }
        .fi-rs-square-phone-hangup:before {
    content: "\fd46";
    }
        .fi-rs-square-plus:before {
    content: "\fd47";
    }
        .fi-rs-square-poll-horizontal:before {
    content: "\fd48";
    }
        .fi-rs-square-poll-vertical:before {
    content: "\fd49";
    }
        .fi-rs-square-q:before {
    content: "\fd4a";
    }
        .fi-rs-square-quote:before {
    content: "\fd4b";
    }
        .fi-rs-square-r:before {
    content: "\fd4c";
    }
        .fi-rs-square-root:before {
    content: "\fd4d";
    }
        .fi-rs-square-s:before {
    content: "\fd4e";
    }
        .fi-rs-square-small:before {
    content: "\fd4f";
    }
        .fi-rs-square-star:before {
    content: "\fd50";
    }
        .fi-rs-square-t:before {
    content: "\fd51";
    }
        .fi-rs-square-terminal:before {
    content: "\fd52";
    }
        .fi-rs-square-u:before {
    content: "\fd53";
    }
        .fi-rs-square-up-right:before {
    content: "\fd54";
    }
        .fi-rs-square-v:before {
    content: "\fd55";
    }
        .fi-rs-square-w:before {
    content: "\fd56";
    }
        .fi-rs-square-x:before {
    content: "\fd57";
    }
        .fi-rs-square-y:before {
    content: "\fd58";
    }
        .fi-rs-square-z:before {
    content: "\fd59";
    }
        .fi-rs-square:before {
    content: "\fd5a";
    }
        .fi-rs-squid:before {
    content: "\fd5b";
    }
        .fi-rs-squircle:before {
    content: "\fd5c";
    }
        .fi-rs-squirrel:before {
    content: "\fd5d";
    }
        .fi-rs-staff:before {
    content: "\fd5e";
    }
        .fi-rs-stage-concert:before {
    content: "\fd5f";
    }
        .fi-rs-stage-theatre:before {
    content: "\fd60";
    }
        .fi-rs-stage:before {
    content: "\fd61";
    }
        .fi-rs-stairs:before {
    content: "\fd62";
    }
        .fi-rs-stamp:before {
    content: "\fd63";
    }
        .fi-rs-standard-definition:before {
    content: "\fd64";
    }
        .fi-rs-star-and-crescent:before {
    content: "\fd65";
    }
        .fi-rs-star-christmas:before {
    content: "\fd66";
    }
        .fi-rs-star-comment-alt:before {
    content: "\fd67";
    }
        .fi-rs-star-exclamation:before {
    content: "\fd68";
    }
        .fi-rs-star-octogram:before {
    content: "\fd69";
    }
        .fi-rs-star-of-david:before {
    content: "\fd6a";
    }
        .fi-rs-star-rating-call:before {
    content: "\fd6b";
    }
        .fi-rs-star-sharp-half-stroke:before {
    content: "\fd6c";
    }
        .fi-rs-star-sharp-half:before {
    content: "\fd6d";
    }
        .fi-rs-star-shooting:before {
    content: "\fd6e";
    }
        .fi-rs-star:before {
    content: "\fd6f";
    }
        .fi-rs-starfighter:before {
    content: "\fd70";
    }
        .fi-rs-stars:before {
    content: "\fd71";
    }
        .fi-rs-state-country:before {
    content: "\fd72";
    }
        .fi-rs-stationary-bike:before {
    content: "\fd73";
    }
        .fi-rs-stats:before {
    content: "\fd74";
    }
        .fi-rs-steak:before {
    content: "\fd75";
    }
        .fi-rs-steam-iron:before {
    content: "\fd76";
    }
        .fi-rs-steering-wheel:before {
    content: "\fd77";
    }
        .fi-rs-step-backward:before {
    content: "\fd78";
    }
        .fi-rs-step-forward:before {
    content: "\fd79";
    }
        .fi-rs-sterling-sign:before {
    content: "\fd7a";
    }
        .fi-rs-stethoscope:before {
    content: "\fd7b";
    }
        .fi-rs-sticker:before {
    content: "\fd7c";
    }
        .fi-rs-stocking:before {
    content: "\fd7d";
    }
        .fi-rs-stomach:before {
    content: "\fd7e";
    }
        .fi-rs-stop-circle:before {
    content: "\fd7f";
    }
        .fi-rs-stop-square:before {
    content: "\fd80";
    }
        .fi-rs-stop:before {
    content: "\fd81";
    }
        .fi-rs-stopwatch:before {
    content: "\fd82";
    }
        .fi-rs-store-alt:before {
    content: "\fd83";
    }
        .fi-rs-store-buyer:before {
    content: "\fd84";
    }
        .fi-rs-store-lock:before {
    content: "\fd85";
    }
        .fi-rs-store-slash:before {
    content: "\fd86";
    }
        .fi-rs-story-book:before {
    content: "\fd87";
    }
        .fi-rs-story-fairy-tale:before {
    content: "\fd88";
    }
        .fi-rs-story-fantasy:before {
    content: "\fd89";
    }
        .fi-rs-strategy-chess-risk:before {
    content: "\fd8a";
    }
        .fi-rs-strawberry:before {
    content: "\fd8b";
    }
        .fi-rs-street-view:before {
    content: "\fd8c";
    }
        .fi-rs-stretcher:before {
    content: "\fd8d";
    }
        .fi-rs-strikethrough:before {
    content: "\fd8e";
    }
        .fi-rs-stroopwafel:before {
    content: "\fd8f";
    }
        .fi-rs-student-alt:before {
    content: "\fd90";
    }
        .fi-rs-student:before {
    content: "\fd91";
    }
        .fi-rs-subfolder:before {
    content: "\fd92";
    }
        .fi-rs-subscript:before {
    content: "\fd93";
    }
        .fi-rs-subscription-alt:before {
    content: "\fd94";
    }
        .fi-rs-subscription:before {
    content: "\fd95";
    }
        .fi-rs-subtitles-slash:before {
    content: "\fd96";
    }
        .fi-rs-subtitles:before {
    content: "\fd97";
    }
        .fi-rs-subway:before {
    content: "\fd98";
    }
        .fi-rs-suitcase-alt:before {
    content: "\fd99";
    }
        .fi-rs-summary-check:before {
    content: "\fd9a";
    }
        .fi-rs-summer:before {
    content: "\fd9b";
    }
        .fi-rs-sun-dust:before {
    content: "\fd9c";
    }
        .fi-rs-sun-plant-wilt:before {
    content: "\fd9d";
    }
        .fi-rs-sun-salutation-yoga:before {
    content: "\fd9e";
    }
        .fi-rs-sun:before {
    content: "\fd9f";
    }
        .fi-rs-sunday:before {
    content: "\fda0";
    }
        .fi-rs-sunglasses-alt:before {
    content: "\fda1";
    }
        .fi-rs-sunglasses:before {
    content: "\fda2";
    }
        .fi-rs-sunrise-alt:before {
    content: "\fda3";
    }
        .fi-rs-sunrise:before {
    content: "\fda4";
    }
        .fi-rs-sunscreen:before {
    content: "\fda5";
    }
        .fi-rs-sunset:before {
    content: "\fda6";
    }
        .fi-rs-superscript:before {
    content: "\fda7";
    }
        .fi-rs-supplier-alt:before {
    content: "\fda8";
    }
        .fi-rs-supplier:before {
    content: "\fda9";
    }
        .fi-rs-surfing:before {
    content: "\fdaa";
    }
        .fi-rs-surprise:before {
    content: "\fdab";
    }
        .fi-rs-surveillance-camera:before {
    content: "\fdac";
    }
        .fi-rs-survey-xmark:before {
    content: "\fdad";
    }
        .fi-rs-sushi-alt:before {
    content: "\fdae";
    }
        .fi-rs-sushi-roll:before {
    content: "\fdaf";
    }
        .fi-rs-sushi:before {
    content: "\fdb0";
    }
        .fi-rs-svg:before {
    content: "\fdb1";
    }
        .fi-rs-swap:before {
    content: "\fdb2";
    }
        .fi-rs-swatchbook:before {
    content: "\fdb3";
    }
        .fi-rs-swimmer:before {
    content: "\fdb4";
    }
        .fi-rs-swimming-pool:before {
    content: "\fdb5";
    }
        .fi-rs-swing:before {
    content: "\fdb6";
    }
        .fi-rs-swipe-down:before {
    content: "\fdb7";
    }
        .fi-rs-swipe-left:before {
    content: "\fdb8";
    }
        .fi-rs-swipe-right:before {
    content: "\fdb9";
    }
        .fi-rs-swipe-up:before {
    content: "\fdba";
    }
        .fi-rs-sword:before {
    content: "\fdbb";
    }
        .fi-rs-symbol:before {
    content: "\fdbc";
    }
        .fi-rs-symbols:before {
    content: "\fdbd";
    }
        .fi-rs-synagogue:before {
    content: "\fdbe";
    }
        .fi-rs-syringe-injection-blood:before {
    content: "\fdbf";
    }
        .fi-rs-syringe:before {
    content: "\fdc0";
    }
        .fi-rs-system-cloud:before {
    content: "\fdc1";
    }
        .fi-rs-t-rex:before {
    content: "\fdc2";
    }
        .fi-rs-t:before {
    content: "\fdc3";
    }
        .fi-rs-tab-folder:before {
    content: "\fdc4";
    }
        .fi-rs-table-columns:before {
    content: "\fdc5";
    }
        .fi-rs-table-layout:before {
    content: "\fdc6";
    }
        .fi-rs-table-list:before {
    content: "\fdc7";
    }
        .fi-rs-table-picnic:before {
    content: "\fdc8";
    }
        .fi-rs-table-pivot:before {
    content: "\fdc9";
    }
        .fi-rs-table-rows:before {
    content: "\fdca";
    }
        .fi-rs-table-tree:before {
    content: "\fdcb";
    }
        .fi-rs-table:before {
    content: "\fdcc";
    }
        .fi-rs-tablet-android-alt:before {
    content: "\fdcd";
    }
        .fi-rs-tablet-android:before {
    content: "\fdce";
    }
        .fi-rs-tablet-rugged:before {
    content: "\fdcf";
    }
        .fi-rs-tablet:before {
    content: "\fdd0";
    }
        .fi-rs-tachometer-alt-average:before {
    content: "\fdd1";
    }
        .fi-rs-tachometer-alt-fastest:before {
    content: "\fdd2";
    }
        .fi-rs-tachometer-alt-slow:before {
    content: "\fdd3";
    }
        .fi-rs-tachometer-alt-slowest:before {
    content: "\fdd4";
    }
        .fi-rs-tachometer-average:before {
    content: "\fdd5";
    }
        .fi-rs-tachometer-fast:before {
    content: "\fdd6";
    }
        .fi-rs-tachometer-fastest:before {
    content: "\fdd7";
    }
        .fi-rs-tachometer-slow:before {
    content: "\fdd8";
    }
        .fi-rs-tachometer-slowest:before {
    content: "\fdd9";
    }
        .fi-rs-tachometer:before {
    content: "\fdda";
    }
        .fi-rs-taco:before {
    content: "\fddb";
    }
        .fi-rs-tags:before {
    content: "\fddc";
    }
        .fi-rs-talent-alt:before {
    content: "\fddd";
    }
        .fi-rs-talent-hand:before {
    content: "\fdde";
    }
        .fi-rs-talent:before {
    content: "\fddf";
    }
        .fi-rs-tally-1:before {
    content: "\fde0";
    }
        .fi-rs-tally-2:before {
    content: "\fde1";
    }
        .fi-rs-tally-3:before {
    content: "\fde2";
    }
        .fi-rs-tally-4:before {
    content: "\fde3";
    }
        .fi-rs-tally:before {
    content: "\fde4";
    }
        .fi-rs-tamale:before {
    content: "\fde5";
    }
        .fi-rs-tank-water:before {
    content: "\fde6";
    }
        .fi-rs-tap:before {
    content: "\fde7";
    }
        .fi-rs-tape:before {
    content: "\fde8";
    }
        .fi-rs-target-audience:before {
    content: "\fde9";
    }
        .fi-rs-target:before {
    content: "\fdea";
    }
        .fi-rs-tattoo-machine:before {
    content: "\fdeb";
    }
        .fi-rs-tax-alt:before {
    content: "\fdec";
    }
        .fi-rs-tax:before {
    content: "\fded";
    }
        .fi-rs-taxi-bus:before {
    content: "\fdee";
    }
        .fi-rs-taxi:before {
    content: "\fdef";
    }
        .fi-rs-team-check-alt:before {
    content: "\fdf0";
    }
        .fi-rs-team-check:before {
    content: "\fdf1";
    }
        .fi-rs-teddy-bear:before {
    content: "\fdf2";
    }
        .fi-rs-teeth-open:before {
    content: "\fdf3";
    }
        .fi-rs-telescope:before {
    content: "\fdf4";
    }
        .fi-rs-temperature-down:before {
    content: "\fdf5";
    }
        .fi-rs-temperature-frigid:before {
    content: "\fdf6";
    }
        .fi-rs-temperature-high:before {
    content: "\fdf7";
    }
        .fi-rs-temperature-list:before {
    content: "\fdf8";
    }
        .fi-rs-temperature-low:before {
    content: "\fdf9";
    }
        .fi-rs-temperature-up:before {
    content: "\fdfa";
    }
        .fi-rs-template-alt:before {
    content: "\fdfb";
    }
        .fi-rs-template:before {
    content: "\fdfc";
    }
        .fi-rs-tenge:before {
    content: "\fdfd";
    }
        .fi-rs-tennis:before {
    content: "\fdfe";
    }
        .fi-rs-tent-arrow-down-to-line:before {
    content: "\fdff";
    }
        .fi-rs-tent-arrow-left-right:before {
    content: "\fe10";
    }
        .fi-rs-tent-arrow-turn-left:before {
    content: "\fe11";
    }
        .fi-rs-tent-arrows-down:before {
    content: "\fe12";
    }
        .fi-rs-tents:before {
    content: "\fe13";
    }
        .fi-rs-terminal:before {
    content: "\fe14";
    }
        .fi-rs-terrace:before {
    content: "\fe15";
    }
        .fi-rs-test-tube:before {
    content: "\fe16";
    }
        .fi-rs-test:before {
    content: "\fe17";
    }
        .fi-rs-text-box-dots:before {
    content: "\fe18";
    }
        .fi-rs-text-box-edit:before {
    content: "\fe19";
    }
        .fi-rs-text-box:before {
    content: "\fe1a";
    }
        .fi-rs-text-check:before {
    content: "\fe1b";
    }
        .fi-rs-text-height:before {
    content: "\fe1c";
    }
        .fi-rs-text-shadow:before {
    content: "\fe1d";
    }
        .fi-rs-text-size:before {
    content: "\fe1e";
    }
        .fi-rs-text-slash:before {
    content: "\fe1f";
    }
        .fi-rs-text-width:before {
    content: "\fe30";
    }
        .fi-rs-text:before {
    content: "\fe31";
    }
        .fi-rs-theater-masks:before {
    content: "\fe32";
    }
        .fi-rs-thermometer-alt:before {
    content: "\fe33";
    }
        .fi-rs-thermometer-empty:before {
    content: "\fe34";
    }
        .fi-rs-thermometer-full:before {
    content: "\fe35";
    }
        .fi-rs-thermometer-half:before {
    content: "\fe36";
    }
        .fi-rs-thermometer-quarter:before {
    content: "\fe37";
    }
        .fi-rs-thermometer-three-quarters:before {
    content: "\fe38";
    }
        .fi-rs-theta:before {
    content: "\fe39";
    }
        .fi-rs-third-award:before {
    content: "\fe3a";
    }
        .fi-rs-third-laurel:before {
    content: "\fe3b";
    }
        .fi-rs-third-medal:before {
    content: "\fe3c";
    }
        .fi-rs-third:before {
    content: "\fe3d";
    }
        .fi-rs-thought-bubble:before {
    content: "\fe3e";
    }
        .fi-rs-three-leaf-clover:before {
    content: "\fe3f";
    }
        .fi-rs-thumbs-up-trust:before {
    content: "\fe40";
    }
        .fi-rs-thumbtack:before {
    content: "\fe41";
    }
        .fi-rs-thunderstorm-moon:before {
    content: "\fe42";
    }
        .fi-rs-thunderstorm-risk:before {
    content: "\fe43";
    }
        .fi-rs-thunderstorm-sun:before {
    content: "\fe44";
    }
        .fi-rs-thunderstorm:before {
    content: "\fe45";
    }
        .fi-rs-thursday:before {
    content: "\fe46";
    }
        .fi-rs-ticket-airline:before {
    content: "\fe47";
    }
        .fi-rs-ticket-alt:before {
    content: "\fe48";
    }
        .fi-rs-ticket:before {
    content: "\fe49";
    }
        .fi-rs-tickets-airline:before {
    content: "\fe4a";
    }
        .fi-rs-tickets:before {
    content: "\fe4b";
    }
        .fi-rs-tie:before {
    content: "\fe4c";
    }
        .fi-rs-tilde:before {
    content: "\fe4d";
    }
        .fi-rs-time-add:before {
    content: "\fe4e";
    }
        .fi-rs-time-check:before {
    content: "\fe4f";
    }
        .fi-rs-time-delete:before {
    content: "\fe50";
    }
        .fi-rs-time-fast:before {
    content: "\fe51";
    }
        .fi-rs-time-forward-sixty:before {
    content: "\fe52";
    }
        .fi-rs-time-forward-ten:before {
    content: "\fe53";
    }
        .fi-rs-time-forward:before {
    content: "\fe54";
    }
        .fi-rs-time-half-past:before {
    content: "\fe55";
    }
        .fi-rs-time-oclock:before {
    content: "\fe56";
    }
        .fi-rs-time-past:before {
    content: "\fe57";
    }
        .fi-rs-time-quarter-past:before {
    content: "\fe58";
    }
        .fi-rs-time-quarter-to:before {
    content: "\fe59";
    }
        .fi-rs-time-twenty-four:before {
    content: "\fe5a";
    }
        .fi-rs-time-watch-calendar:before {
    content: "\fe5b";
    }
        .fi-rs-timer-clock-call:before {
    content: "\fe5c";
    }
        .fi-rs-times-hexagon:before {
    content: "\fe5d";
    }
        .fi-rs-tint-slash:before {
    content: "\fe5e";
    }
        .fi-rs-tip-coin:before {
    content: "\fe5f";
    }
        .fi-rs-tire-flat:before {
    content: "\fe60";
    }
        .fi-rs-tire-pressure-warning:before {
    content: "\fe61";
    }
        .fi-rs-tire-rugged:before {
    content: "\fe62";
    }
        .fi-rs-tire:before {
    content: "\fe63";
    }
        .fi-rs-tired:before {
    content: "\fe64";
    }
        .fi-rs-to-do-alt:before {
    content: "\fe65";
    }
        .fi-rs-to-do:before {
    content: "\fe66";
    }
        .fi-rs-together-people:before {
    content: "\fe67";
    }
        .fi-rs-toggle-off:before {
    content: "\fe68";
    }
        .fi-rs-toggle-on:before {
    content: "\fe69";
    }
        .fi-rs-toilet-paper-blank-under:before {
    content: "\fe6a";
    }
        .fi-rs-toilet-paper-blank:before {
    content: "\fe6b";
    }
        .fi-rs-toilet-paper-slash:before {
    content: "\fe6c";
    }
        .fi-rs-toilet-paper-under-slash:before {
    content: "\fe6d";
    }
        .fi-rs-toilet-paper-under:before {
    content: "\fe6e";
    }
        .fi-rs-toilet-paper-xmark:before {
    content: "\fe6f";
    }
        .fi-rs-toilet:before {
    content: "\fe70";
    }
        .fi-rs-toilets-portable:before {
    content: "\fe71";
    }
        .fi-rs-token:before {
    content: "\fe72";
    }
        .fi-rs-tomato:before {
    content: "\fe73";
    }
        .fi-rs-tombstone-alt:before {
    content: "\fe74";
    }
        .fi-rs-tombstone:before {
    content: "\fe75";
    }
        .fi-rs-tool-box:before {
    content: "\fe76";
    }
        .fi-rs-tool-crop:before {
    content: "\fe77";
    }
        .fi-rs-tool-marquee:before {
    content: "\fe78";
    }
        .fi-rs-tools:before {
    content: "\fe79";
    }
        .fi-rs-tooth:before {
    content: "\fe7a";
    }
        .fi-rs-toothbrush:before {
    content: "\fe7b";
    }
        .fi-rs-torch-inspiration-leader:before {
    content: "\fe7c";
    }
        .fi-rs-torii-gate:before {
    content: "\fe7d";
    }
        .fi-rs-tornado:before {
    content: "\fe7e";
    }
        .fi-rs-total:before {
    content: "\fe7f";
    }
        .fi-rs-tour-guide-people:before {
    content: "\fe80";
    }
        .fi-rs-tour-virtual:before {
    content: "\fe81";
    }
        .fi-rs-tower-control:before {
    content: "\fe82";
    }
        .fi-rs-track:before {
    content: "\fe83";
    }
        .fi-rs-tractor:before {
    content: "\fe84";
    }
        .fi-rs-trademark:before {
    content: "\fe85";
    }
        .fi-rs-traffic-cone:before {
    content: "\fe86";
    }
        .fi-rs-traffic-light-go:before {
    content: "\fe87";
    }
        .fi-rs-traffic-light-slow:before {
    content: "\fe88";
    }
        .fi-rs-traffic-light-stop:before {
    content: "\fe89";
    }
        .fi-rs-traffic-light:before {
    content: "\fe8a";
    }
        .fi-rs-trailer:before {
    content: "\fe8b";
    }
        .fi-rs-train-journey:before {
    content: "\fe8c";
    }
        .fi-rs-train-side:before {
    content: "\fe8d";
    }
        .fi-rs-train-station-building:before {
    content: "\fe8e";
    }
        .fi-rs-train-station:before {
    content: "\fe8f";
    }
        .fi-rs-train-subway-tunnel:before {
    content: "\fe90";
    }
        .fi-rs-train-track:before {
    content: "\fe91";
    }
        .fi-rs-train-tram:before {
    content: "\fe92";
    }
        .fi-rs-train:before {
    content: "\fe93";
    }
        .fi-rs-tram:before {
    content: "\fe94";
    }
        .fi-rs-transaction-euro:before {
    content: "\fe95";
    }
        .fi-rs-transaction-globe:before {
    content: "\fe96";
    }
        .fi-rs-transaction-yen:before {
    content: "\fe97";
    }
        .fi-rs-transform:before {
    content: "\fe98";
    }
        .fi-rs-transformation-block:before {
    content: "\fe99";
    }
        .fi-rs-transformation-circle:before {
    content: "\fe9a";
    }
        .fi-rs-transformation-design:before {
    content: "\fe9b";
    }
        .fi-rs-transformation-shapes:before {
    content: "\fe9c";
    }
        .fi-rs-transformer-bolt:before {
    content: "\fe9d";
    }
        .fi-rs-transgender:before {
    content: "\fe9e";
    }
        .fi-rs-translate:before {
    content: "\fe9f";
    }
        .fi-rs-transporter-1:before {
    content: "\fea0";
    }
        .fi-rs-transporter-2:before {
    content: "\fea1";
    }
        .fi-rs-transporter-3:before {
    content: "\fea2";
    }
        .fi-rs-transporter-4:before {
    content: "\fea3";
    }
        .fi-rs-transporter-5:before {
    content: "\fea4";
    }
        .fi-rs-transporter-6:before {
    content: "\fea5";
    }
        .fi-rs-transporter-7:before {
    content: "\fea6";
    }
        .fi-rs-transporter-empty:before {
    content: "\fea7";
    }
        .fi-rs-transporter:before {
    content: "\fea8";
    }
        .fi-rs-trash-bag:before {
    content: "\fea9";
    }
        .fi-rs-trash-can-check:before {
    content: "\feaa";
    }
        .fi-rs-trash-can-clock:before {
    content: "\feab";
    }
        .fi-rs-trash-can-list:before {
    content: "\feac";
    }
        .fi-rs-trash-can-plus:before {
    content: "\fead";
    }
        .fi-rs-trash-can-slash:before {
    content: "\feae";
    }
        .fi-rs-trash-check:before {
    content: "\feaf";
    }
        .fi-rs-trash-clock:before {
    content: "\feb0";
    }
        .fi-rs-trash-empty:before {
    content: "\feb1";
    }
        .fi-rs-trash-list:before {
    content: "\feb2";
    }
        .fi-rs-trash-plus:before {
    content: "\feb3";
    }
        .fi-rs-trash-restore-alt:before {
    content: "\feb4";
    }
        .fi-rs-trash-restore:before {
    content: "\feb5";
    }
        .fi-rs-trash-slash:before {
    content: "\feb6";
    }
        .fi-rs-trash-undo-alt:before {
    content: "\feb7";
    }
        .fi-rs-trash-undo:before {
    content: "\feb8";
    }
        .fi-rs-trash-xmark:before {
    content: "\feb9";
    }
        .fi-rs-trash:before {
    content: "\feba";
    }
        .fi-rs-treadmill:before {
    content: "\febb";
    }
        .fi-rs-treasure-chest:before {
    content: "\febc";
    }
        .fi-rs-treatment:before {
    content: "\febd";
    }
        .fi-rs-tree-alt:before {
    content: "\febe";
    }
        .fi-rs-tree-christmas:before {
    content: "\febf";
    }
        .fi-rs-tree-deciduous:before {
    content: "\fec0";
    }
        .fi-rs-tree:before {
    content: "\fec1";
    }
        .fi-rs-trees-alt:before {
    content: "\fec2";
    }
        .fi-rs-trees:before {
    content: "\fec3";
    }
        .fi-rs-triangle-music:before {
    content: "\fec4";
    }
        .fi-rs-triangle-person-digging:before {
    content: "\fec5";
    }
        .fi-rs-triangle-warning:before {
    content: "\fec6";
    }
        .fi-rs-triangle:before {
    content: "\fec7";
    }
        .fi-rs-tricycle:before {
    content: "\fec8";
    }
        .fi-rs-trillium:before {
    content: "\fec9";
    }
        .fi-rs-troph-cap:before {
    content: "\feca";
    }
        .fi-rs-trophy-achievement-skill:before {
    content: "\fecb";
    }
        .fi-rs-trophy-star:before {
    content: "\fecc";
    }
        .fi-rs-trophy:before {
    content: "\fecd";
    }
        .fi-rs-truck-arrow-left:before {
    content: "\fece";
    }
        .fi-rs-truck-arrow-right:before {
    content: "\fecf";
    }
        .fi-rs-truck-bolt:before {
    content: "\fed0";
    }
        .fi-rs-truck-box:before {
    content: "\fed1";
    }
        .fi-rs-truck-check:before {
    content: "\fed2";
    }
        .fi-rs-truck-container-empty:before {
    content: "\fed3";
    }
        .fi-rs-truck-container:before {
    content: "\fed4";
    }
        .fi-rs-truck-couch:before {
    content: "\fed5";
    }
        .fi-rs-truck-droplet:before {
    content: "\fed6";
    }
        .fi-rs-truck-fire:before {
    content: "\fed7";
    }
        .fi-rs-truck-flatbed:before {
    content: "\fed8";
    }
        .fi-rs-truck-front:before {
    content: "\fed9";
    }
        .fi-rs-truck-ladder:before {
    content: "\feda";
    }
        .fi-rs-truck-loading:before {
    content: "\fedb";
    }
        .fi-rs-truck-medical:before {
    content: "\fedc";
    }
        .fi-rs-truck-monster:before {
    content: "\fedd";
    }
        .fi-rs-truck-moving:before {
    content: "\fede";
    }
        .fi-rs-truck-pickup:before {
    content: "\fedf";
    }
        .fi-rs-truck-plow:before {
    content: "\fee0";
    }
        .fi-rs-truck-ramp:before {
    content: "\fee1";
    }
        .fi-rs-truck-side:before {
    content: "\fee2";
    }
        .fi-rs-truck-tow:before {
    content: "\fee3";
    }
        .fi-rs-truck-utensils:before {
    content: "\fee4";
    }
        .fi-rs-trumpet:before {
    content: "\fee5";
    }
        .fi-rs-trust-alt:before {
    content: "\fee6";
    }
        .fi-rs-trust:before {
    content: "\fee7";
    }
        .fi-rs-tshirt:before {
    content: "\fee8";
    }
        .fi-rs-tty-answer:before {
    content: "\fee9";
    }
        .fi-rs-tty:before {
    content: "\feea";
    }
        .fi-rs-tubes:before {
    content: "\feeb";
    }
        .fi-rs-tuesday:before {
    content: "\feec";
    }
        .fi-rs-tugrik-sign:before {
    content: "\feed";
    }
        .fi-rs-turkey:before {
    content: "\feee";
    }
        .fi-rs-turn-left:before {
    content: "\feef";
    }
        .fi-rs-turn-right:before {
    content: "\fef0";
    }
        .fi-rs-turntable:before {
    content: "\fef1";
    }
        .fi-rs-turtle:before {
    content: "\fef2";
    }
        .fi-rs-tv-music:before {
    content: "\fef3";
    }
        .fi-rs-tv-retro:before {
    content: "\fef4";
    }
        .fi-rs-typewriter:before {
    content: "\fef5";
    }
        .fi-rs-u:before {
    content: "\fef6";
    }
        .fi-rs-ufo-beam:before {
    content: "\fef7";
    }
        .fi-rs-ufo:before {
    content: "\fef8";
    }
        .fi-rs-ui-ux:before {
    content: "\fef9";
    }
        .fi-rs-umbrella-beach:before {
    content: "\fefa";
    }
        .fi-rs-umbrella:before {
    content: "\fefb";
    }
        .fi-rs-under-construction:before {
    content: "\fefc";
    }
        .fi-rs-underline:before {
    content: "\fefd";
    }
        .fi-rs-undo-alt:before {
    content: "\fefe";
    }
        .fi-rs-undo:before {
    content: "\ff00";
    }
        .fi-rs-unicorn:before {
    content: "\ff01";
    }
        .fi-rs-uniform-martial-arts:before {
    content: "\ff02";
    }
        .fi-rs-universal-access:before {
    content: "\ff03";
    }
        .fi-rs-unlock:before {
    content: "\ff04";
    }
        .fi-rs-up-from-bracket:before {
    content: "\ff05";
    }
        .fi-rs-up-left:before {
    content: "\ff06";
    }
        .fi-rs-up-right-from-square:before {
    content: "\ff07";
    }
        .fi-rs-up-right:before {
    content: "\ff08";
    }
        .fi-rs-up:before {
    content: "\ff09";
    }
        .fi-rs-upload:before {
    content: "\ff0a";
    }
        .fi-rs-url:before {
    content: "\ff0b";
    }
        .fi-rs-usb-pendrive:before {
    content: "\ff0c";
    }
        .fi-rs-usd-circle:before {
    content: "\ff0d";
    }
        .fi-rs-usd-square:before {
    content: "\ff0e";
    }
        .fi-rs-user-add:before {
    content: "\ff0f";
    }
        .fi-rs-user-alien:before {
    content: "\ff10";
    }
        .fi-rs-user-astronaut:before {
    content: "\ff11";
    }
        .fi-rs-user-check:before {
    content: "\ff12";
    }
        .fi-rs-user-chef:before {
    content: "\ff13";
    }
        .fi-rs-user-coach:before {
    content: "\ff14";
    }
        .fi-rs-user-cowboy:before {
    content: "\ff15";
    }
        .fi-rs-user-crown:before {
    content: "\ff16";
    }
        .fi-rs-user-gear:before {
    content: "\ff17";
    }
        .fi-rs-user-graduate:before {
    content: "\ff18";
    }
        .fi-rs-user-headset:before {
    content: "\ff19";
    }
        .fi-rs-user-helmet-safety:before {
    content: "\ff1a";
    }
        .fi-rs-user-injured:before {
    content: "\ff1b";
    }
        .fi-rs-user-interface-ui:before {
    content: "\ff1c";
    }
        .fi-rs-user-key:before {
    content: "\ff1d";
    }
        .fi-rs-user-language:before {
    content: "\ff1e";
    }
        .fi-rs-user-lock:before {
    content: "\ff1f";
    }
        .fi-rs-user-md-chat:before {
    content: "\ff20";
    }
        .fi-rs-user-md:before {
    content: "\ff21";
    }
        .fi-rs-user-minus:before {
    content: "\ff22";
    }
        .fi-rs-user-music:before {
    content: "\ff23";
    }
        .fi-rs-user-ninja:before {
    content: "\ff24";
    }
        .fi-rs-user-nurse:before {
    content: "\ff25";
    }
        .fi-rs-user-pen:before {
    content: "\ff26";
    }
        .fi-rs-user-pilot-tie:before {
    content: "\ff27";
    }
        .fi-rs-user-pilot:before {
    content: "\ff28";
    }
        .fi-rs-user-police:before {
    content: "\ff29";
    }
        .fi-rs-user-robot-xmarks:before {
    content: "\ff2a";
    }
        .fi-rs-user-robot:before {
    content: "\ff2b";
    }
        .fi-rs-user-salary:before {
    content: "\ff2c";
    }
        .fi-rs-user-shield:before {
    content: "\ff2d";
    }
        .fi-rs-user-skill-gear:before {
    content: "\ff2e";
    }
        .fi-rs-user-slash:before {
    content: "\ff2f";
    }
        .fi-rs-user-speaking:before {
    content: "\ff30";
    }
        .fi-rs-user-tag:before {
    content: "\ff31";
    }
        .fi-rs-user-time:before {
    content: "\ff32";
    }
        .fi-rs-user-trust:before {
    content: "\ff33";
    }
        .fi-rs-user-unlock:before {
    content: "\ff34";
    }
        .fi-rs-user-visor:before {
    content: "\ff35";
    }
        .fi-rs-user-xmark:before {
    content: "\ff36";
    }
        .fi-rs-user:before {
    content: "\ff37";
    }
        .fi-rs-users-alt:before {
    content: "\ff38";
    }
        .fi-rs-users-class:before {
    content: "\ff39";
    }
        .fi-rs-users-gear:before {
    content: "\ff3a";
    }
        .fi-rs-users-loyalty:before {
    content: "\ff3b";
    }
        .fi-rs-users-medical:before {
    content: "\ff3c";
    }
        .fi-rs-users-slash:before {
    content: "\ff3d";
    }
        .fi-rs-users:before {
    content: "\ff3e";
    }
        .fi-rs-utensils-slash:before {
    content: "\ff3f";
    }
        .fi-rs-utensils:before {
    content: "\ff40";
    }
        .fi-rs-utility-pole-double:before {
    content: "\ff41";
    }
        .fi-rs-utility-pole:before {
    content: "\ff42";
    }
        .fi-rs-ux-browser:before {
    content: "\ff43";
    }
        .fi-rs-ux:before {
    content: "\ff44";
    }
        .fi-rs-v:before {
    content: "\ff45";
    }
        .fi-rs-vacuum-robot:before {
    content: "\ff46";
    }
        .fi-rs-vacuum:before {
    content: "\ff47";
    }
        .fi-rs-value-absolute:before {
    content: "\ff48";
    }
        .fi-rs-vault:before {
    content: "\ff49";
    }
        .fi-rs-vector-alt:before {
    content: "\ff4a";
    }
        .fi-rs-vector-circle:before {
    content: "\ff4b";
    }
        .fi-rs-vector-polygon:before {
    content: "\ff4c";
    }
        .fi-rs-vector:before {
    content: "\ff4d";
    }
        .fi-rs-venus-double:before {
    content: "\ff4e";
    }
        .fi-rs-venus-mars:before {
    content: "\ff4f";
    }
        .fi-rs-venus:before {
    content: "\ff50";
    }
        .fi-rs-vest-patches:before {
    content: "\ff51";
    }
        .fi-rs-vest:before {
    content: "\ff52";
    }
        .fi-rs-video-arrow-down-left:before {
    content: "\ff53";
    }
        .fi-rs-video-arrow-up-right:before {
    content: "\ff54";
    }
        .fi-rs-video-camera-alt:before {
    content: "\ff55";
    }
        .fi-rs-video-camera:before {
    content: "\ff56";
    }
        .fi-rs-video-duration:before {
    content: "\ff57";
    }
        .fi-rs-video-plus:before {
    content: "\ff58";
    }
        .fi-rs-video-slash:before {
    content: "\ff59";
    }
        .fi-rs-videoconference:before {
    content: "\ff5a";
    }
        .fi-rs-vihara:before {
    content: "\ff5b";
    }
        .fi-rs-violin:before {
    content: "\ff5c";
    }
        .fi-rs-virus-slash:before {
    content: "\ff5d";
    }
        .fi-rs-virus:before {
    content: "\ff5e";
    }
        .fi-rs-viruses:before {
    content: "\ff5f";
    }
        .fi-rs-vision-target:before {
    content: "\ff60";
    }
        .fi-rs-visit:before {
    content: "\ff61";
    }
        .fi-rs-voicemail:before {
    content: "\ff62";
    }
        .fi-rs-volcano:before {
    content: "\ff63";
    }
        .fi-rs-volleyball:before {
    content: "\ff64";
    }
        .fi-rs-volume-down:before {
    content: "\ff65";
    }
        .fi-rs-volume-mute:before {
    content: "\ff66";
    }
        .fi-rs-volume-off:before {
    content: "\ff67";
    }
        .fi-rs-volume-slash:before {
    content: "\ff68";
    }
        .fi-rs-volume:before {
    content: "\ff69";
    }
        .fi-rs-vote-nay:before {
    content: "\ff6a";
    }
        .fi-rs-vote-yea:before {
    content: "\ff6b";
    }
        .fi-rs-vr-cardboard:before {
    content: "\ff6c";
    }
        .fi-rs-w:before {
    content: "\ff6d";
    }
        .fi-rs-waffle:before {
    content: "\ff6e";
    }
        .fi-rs-wagon-covered:before {
    content: "\ff6f";
    }
        .fi-rs-walker:before {
    content: "\ff70";
    }
        .fi-rs-walkie-talkie:before {
    content: "\ff71";
    }
        .fi-rs-walking:before {
    content: "\ff72";
    }
        .fi-rs-wallet-arrow:before {
    content: "\ff73";
    }
        .fi-rs-wallet-buyer:before {
    content: "\ff74";
    }
        .fi-rs-wallet:before {
    content: "\ff75";
    }
        .fi-rs-warehouse-alt:before {
    content: "\ff76";
    }
        .fi-rs-warranty:before {
    content: "\ff77";
    }
        .fi-rs-washer:before {
    content: "\ff78";
    }
        .fi-rs-waste-pollution:before {
    content: "\ff79";
    }
        .fi-rs-waste:before {
    content: "\ff7a";
    }
        .fi-rs-watch-calculator:before {
    content: "\ff7b";
    }
        .fi-rs-watch-smart:before {
    content: "\ff7c";
    }
        .fi-rs-watch:before {
    content: "\ff7d";
    }
        .fi-rs-water-bottle:before {
    content: "\ff7e";
    }
        .fi-rs-water-ladder:before {
    content: "\ff7f";
    }
        .fi-rs-water-lower:before {
    content: "\ff80";
    }
        .fi-rs-water-rise:before {
    content: "\ff81";
    }
        .fi-rs-water:before {
    content: "\ff82";
    }
        .fi-rs-watermelon:before {
    content: "\ff83";
    }
        .fi-rs-wave-sine:before {
    content: "\ff84";
    }
        .fi-rs-wave-square:before {
    content: "\ff85";
    }
        .fi-rs-wave-triangle:before {
    content: "\ff86";
    }
        .fi-rs-wave:before {
    content: "\ff87";
    }
        .fi-rs-waveform-path:before {
    content: "\ff88";
    }
        .fi-rs-waveform:before {
    content: "\ff89";
    }
        .fi-rs-web-design:before {
    content: "\ff8a";
    }
        .fi-rs-web-test:before {
    content: "\ff8b";
    }
        .fi-rs-webcam-slash:before {
    content: "\ff8c";
    }
        .fi-rs-webcam:before {
    content: "\ff8d";
    }
        .fi-rs-webhook:before {
    content: "\ff8e";
    }
        .fi-rs-wednesday:before {
    content: "\ff8f";
    }
        .fi-rs-whale:before {
    content: "\ff90";
    }
        .fi-rs-wheat-awn-circle-exclamation:before {
    content: "\ff91";
    }
        .fi-rs-wheat-awn-slash:before {
    content: "\ff92";
    }
        .fi-rs-wheat-awn:before {
    content: "\ff93";
    }
        .fi-rs-wheat-slash:before {
    content: "\ff94";
    }
        .fi-rs-wheat:before {
    content: "\ff95";
    }
        .fi-rs-wheelchair-move:before {
    content: "\ff96";
    }
        .fi-rs-wheelchair-sleeping:before {
    content: "\ff97";
    }
        .fi-rs-wheelchair:before {
    content: "\ff98";
    }
        .fi-rs-whistle:before {
    content: "\ff99";
    }
        .fi-rs-white-space:before {
    content: "\ff9a";
    }
        .fi-rs-wifi-1:before {
    content: "\ff9b";
    }
        .fi-rs-wifi-2:before {
    content: "\ff9c";
    }
        .fi-rs-wifi-alt:before {
    content: "\ff9d";
    }
        .fi-rs-wifi-exclamation:before {
    content: "\ff9e";
    }
        .fi-rs-wifi-slash:before {
    content: "\ff9f";
    }
        .fi-rs-wifi:before {
    content: "\ffa0";
    }
        .fi-rs-wind-turbine:before {
    content: "\ffa1";
    }
        .fi-rs-wind-warning:before {
    content: "\ffa2";
    }
        .fi-rs-wind:before {
    content: "\ffa3";
    }
        .fi-rs-window-alt:before {
    content: "\ffa4";
    }
        .fi-rs-window-frame-open:before {
    content: "\ffa5";
    }
        .fi-rs-window-frame:before {
    content: "\ffa6";
    }
        .fi-rs-window-maximize:before {
    content: "\ffa7";
    }
        .fi-rs-window-minimize:before {
    content: "\ffa8";
    }
        .fi-rs-window-restore:before {
    content: "\ffa9";
    }
        .fi-rs-windsock:before {
    content: "\ffaa";
    }
        .fi-rs-wine-bottle:before {
    content: "\ffab";
    }
        .fi-rs-wine-glass-crack:before {
    content: "\ffac";
    }
        .fi-rs-wine-glass-empty:before {
    content: "\ffad";
    }
        .fi-rs-wisdom:before {
    content: "\ffae";
    }
        .fi-rs-wishlist-heart:before {
    content: "\ffaf";
    }
        .fi-rs-wishlist-star:before {
    content: "\ffb0";
    }
        .fi-rs-woman-head:before {
    content: "\ffb1";
    }
        .fi-rs-woman-scientist:before {
    content: "\ffb2";
    }
        .fi-rs-won-sign:before {
    content: "\ffb3";
    }
        .fi-rs-work-in-progress:before {
    content: "\ffb4";
    }
        .fi-rs-workflow-alt:before {
    content: "\ffb5";
    }
        .fi-rs-workflow-setting-alt:before {
    content: "\ffb6";
    }
        .fi-rs-workflow-setting:before {
    content: "\ffb7";
    }
        .fi-rs-workflow:before {
    content: "\ffb8";
    }
        .fi-rs-workshop:before {
    content: "\ffb9";
    }
        .fi-rs-world:before {
    content: "\ffba";
    }
        .fi-rs-worm:before {
    content: "\ffbb";
    }
        .fi-rs-wreath:before {
    content: "\ffbc";
    }
        .fi-rs-wrench-alt:before {
    content: "\ffbd";
    }
        .fi-rs-wrench-simple:before {
    content: "\ffbe";
    }
        .fi-rs-x:before {
    content: "\ffbf";
    }
        .fi-rs-y:before {
    content: "\ffc0";
    }
        .fi-rs-yen:before {
    content: "\ffc1";
    }
        .fi-rs-yin-yang:before {
    content: "\ffc2";
    }
        .fi-rs-z:before {
    content: "\ffc3";
    }
        .fi-rs-zip-file:before {
    content: "\ffc4";
    }
        .fi-rs-zoom-in:before {
    content: "\ffc5";
    }
        .fi-rs-zoom-out:before {
    content: "\ffc6";
    }
