.icon_detail {
  background: url("assets/images/common/icon-detail.svg") no-repeat;
}

.icon_traffic_lights {
  background: url("assets/images/common/icon-traffic-lights.svg") no-repeat;
}

.icon_attached_file {
  background: url("assets/images/common/icon-attached-file.svg") no-repeat;
}

.icon_admin {
  background: url("assets/images/common/icon-admin.svg") no-repeat;
}

.icon_home {
  background: url("assets/images/common/icon-home.svg") no-repeat;
}

.icon_patent_payment {
  background: url("assets/images/common/icon-patent-payment.svg") no-repeat;
}

.icon_badge_question {
  background: url("assets/images/common/icon-badge-question.png") no-repeat;
}

.icon_question_mark {
  background: url("assets/images/common/icon-question-with-bg.svg") no-repeat;
}

.icon_question_mark_blue {
  background: url("assets/images/common/icon-question-mark-blue.svg") no-repeat;
}

.icon_next_white {
  background: url("assets/images/common/icon-next-white.svg") no-repeat;
}

.icon_next_black {
  background: url("assets/images/common/icon-next-black.svg") no-repeat;
}

.icon_hourglass {
  background: url("assets/images/common/icon-hourglass.svg") no-repeat;
}

.icon_hourglass_small {
  background: url("assets/images/common/icon-hourglass-small.svg") no-repeat;
}

.icon_payment {
  background: url("assets/images/common/icon-payment.svg") no-repeat;
}

.icon_search_black_small {
  background: url("assets/images/common/icon-search-small.svg") no-repeat;
}

.icon_search_black {
  background: url("assets/images/common/icon_search_black.svg") no-repeat;
}

.icon_search_blue {
  background: url("assets/images/common/icon-search-blue.svg") no-repeat;
}

.icon_noun {
  background: url("assets/images/common/icon-noun.svg") no-repeat;
}

.icon_blue_add {
  background: url("assets/images/common/icon-blue-add.svg") no-repeat;
}

.icon_gray_add {
  background: url("assets/images/common/icon-add-gray.svg") no-repeat;
}

.icon_white_add {
  background: url("assets/images/common/icon-add-white.svg") no-repeat;
}

.icon_badge_more_blue {
  background: url("assets/images/common/icon-badge-more-blue.svg") no-repeat;
}

.icon_check_black {
  background: url("assets/images/common/icon-check.svg") no-repeat;
}

.icon_check_white {
  background: url("assets/images/common/icon-check-white.svg") no-repeat;
}

.icon_check_green {
  background: url("assets/images/common/icon-check-green.svg") no-repeat;
}

.icon_check_blue {
  background: url("assets/images/common/icon-checkbox-off-blue.svg") no-repeat;
}

.icon_check_orange {
  background: url("assets/images/common/icon-checkbox-off-orange.svg") no-repeat;
}

.icon_checkbox_gray {
  background: url("assets/images/common/icon-checkbox-off-gray.svg") no-repeat;
}

.icon_edit {
  background: url("assets/images/common/icon-edit.svg") no-repeat;
}

.icon_edit_square {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-edit.png") no-repeat;
}

.icon_exit {
  background: url("assets/images/common/icon-exit.svg") no-repeat;
}

.icon_exit_white {
  background: url("assets/images/common/icon-exit-white.svg") no-repeat;
}

.icon_exit_gray {
  background: url("assets/images/common/icon-exit-gray.svg") no-repeat;
}

.icon_send {
  background: url("assets/images/chat/icon-send.svg") no-repeat;
}

.icon_share {
  background: url("assets/images/common/icon-share.svg") no-repeat;
}

i[class^="icon_flag_"] {
  background-position: center;
}

.icon_flag_al,
.icon_flag_AL {
  background: url("assets/images/common/icon-flag-al.png") no-repeat;
}

.icon_flag_at,
.icon_flag_AT {
  background: url("assets/images/common/icon-flag-at.png") no-repeat;
}

.icon_flag_be,
.icon_flag_BE {
  background: url("assets/images/common/icon-flag-be.png") no-repeat;
}

.icon_flag_bg,
.icon_flag_BG {
  background: url("assets/images/common/icon-flag-bg.png") no-repeat;
}

.icon_flag_ch,
.icon_flag_CH {
  background: url("assets/images/common/icon-flag-ch.png") no-repeat;
}

.icon_flag_cy,
.icon_flag_CY {
  background: url("assets/images/common/icon-flag-cy.png") no-repeat;
}

.icon_flag_cz,
.icon_flag_CZ {
  background: url("assets/images/common/icon-flag-cz.png") no-repeat;
}

.icon_flag_dk,
.icon_flag_DK {
  background: url("assets/images/common/icon-flag-dk.png") no-repeat;
}

.icon_flag_ee,
.icon_flag_EE {
  background: url("assets/images/common/icon-flag-ee.png") no-repeat;
}

.icon_flag_es,
.icon_flag_ES {
  background: url("assets/images/common/icon-flag-es.png") no-repeat;
}

.icon_flag_fi,
.icon_flag_FI {
  background: url("assets/images/common/icon-flag-fi.png") no-repeat;
}

.icon_flag_fr,
.icon_flag_FR {
  background: url("assets/images/common/icon-flag-fr.png") no-repeat;
}

.icon_flag_gb,
.icon_flag_GB {
  background: url("assets/images/common/icon-flag-gb.png") no-repeat;
}

.icon_flag_gr,
.icon_flag_GR {
  background: url("assets/images/common/icon-flag-gr.png") no-repeat;
}

.icon_flag_hr,
.icon_flag_HR {
  background: url("assets/images/common/icon-flag-hr.png") no-repeat;
}

.icon_flag_hu,
.icon_flag_HU {
  background: url("assets/images/common/icon-flag-hu.png") no-repeat;
}

.icon_flag_ie,
.icon_flag_IE {
  background: url("assets/images/common/icon-flag-ie.png") no-repeat;
}

.icon_flag_is,
.icon_flag_IS {
  background: url("assets/images/common/icon-flag-is.png") no-repeat;
}

.icon_flag_it,
.icon_flag_IT {
  background: url("assets/images/common/icon-flag-it.png") no-repeat;
}

.icon_flag_li,
.icon_flag_LI {
  background: url("assets/images/common/icon-flag-li.png") no-repeat;
}

.icon_flag_lt,
.icon_flag_LT {
  background: url("assets/images/common/icon-flag-lt.png") no-repeat;
}

.icon_flag_lu,
.icon_flag_LU {
  background: url("assets/images/common/icon-flag-lu.png") no-repeat;
}

.icon_flag_lv,
.icon_flag_LV {
  background: url("assets/images/common/icon-flag-lv.png") no-repeat;
}

.icon_flag_mc,
.icon_flag_MC {
  background: url("assets/images/common/icon-flag-mc.png") no-repeat;
}

.icon_flag_mk,
.icon_flag_MK {
  background: url("assets/images/common/icon-flag-mk.png") no-repeat;
}

.icon_flag_mt,
.icon_flag_MT {
  background: url("assets/images/common/icon-flag-mt.png") no-repeat;
}

.icon_flag_nl,
.icon_flag_NL {
  background: url("assets/images/common/icon-flag-nl.png") no-repeat;
}

.icon_flag_no,
.icon_flag_NO {
  background: url("assets/images/common/icon-flag-no.png") no-repeat;
}

.icon_flag_pl,
.icon_flag_PL {
  background: url("assets/images/common/icon-flag-pl.png") no-repeat;
}

.icon_flag_pt,
.icon_flag_PT {
  background: url("assets/images/common/icon-flag-pt.png") no-repeat;
}

.icon_flag_ro,
.icon_flag_RO {
  background: url("assets/images/common/icon-flag-ro.png") no-repeat;
}

.icon_flag_rs,
.icon_flag_RS {
  background: url("assets/images/common/icon-flag-rs.png") no-repeat;
}

.icon_flag_se,
.icon_flag_SE {
  background: url("assets/images/common/icon-flag-se.png") no-repeat;
}

.icon_flag_si,
.icon_flag_SI {
  background: url("assets/images/common/icon-flag-si.png") no-repeat;
}

.icon_flag_sk,
.icon_flag_SK {
  background: url("assets/images/common/icon-flag-sk.png") no-repeat;
}

.icon_flag_sm,
.icon_flag_SM {
  background: url("assets/images/common/icon-flag-sm.png") no-repeat;
}

.icon_flag_tr,
.icon_flag_TR {
  background: url("assets/images/common/icon-flag-tr.png") no-repeat;
}

.icon_flag_sg,
.icon_flag_SG {
  background: url("assets/images/common/icon-flag-sg.png") no-repeat;
}

.icon_flag_kr,
.icon_flag_KR {
  background: url("assets/images/common/icon-flag-kr.png") no-repeat;
}

.icon_flag_us,
.icon_flag_US {
  background: url("assets/images/common/icon-flag-us.png") no-repeat;
}

.icon_flag_jp,
.icon_flag_JP {
  background: url("assets/images/common/icon-flag-jp.png") no-repeat;
}

.icon_flag_cn,
.icon_flag_CN {
  background: url("assets/images/common/icon-flag-cn.png") no-repeat;
}

.icon_flag_ep,
.icon_flag_EP {
  background: url("assets/images/common/icon-flag-ep.png") no-repeat;
}

.icon_flag_de,
.icon_flag_DE {
  background: url("assets/images/common/icon-flag-de.png") no-repeat;
}

.icon_flag_gb,
.icon_flag_GB {
  background: url("assets/images/common/icon-flag-gb.png") no-repeat;
}

.icon_flag_mx,
.icon_flag_MX {
  background: url("assets/images/common/icon-flag-mx.png") no-repeat;
}

.icon_flag_au,
.icon_flag_AU {
  background: url("assets/images/common/icon-flag-au.png") no-repeat;
}

.icon_flag_br,
.icon_flag_BR {
  background: url("assets/images/common/icon-flag-br.png") no-repeat;
}

.icon_flag_ca,
.icon_flag_CA {
  background: url("assets/images/common/icon-flag-ca.png") no-repeat;
}

.icon_flag_il,
.icon_flag_IL {
  background: url("assets/images/common/icon-flag-il.png") no-repeat;
}

.icon_flag_IN,
.icon_flag_IN {
  background: url("assets/images/common/icon-flag-in.png") no-repeat;
}

.icon_flag_mo,
.icon_flag_MO {
  background: url("assets/images/common/icon-flag-mo.png") no-repeat;
}

.icon_flag_nz,
.icon_flag_NZ {
  background: url("assets/images/common/icon-flag-nz.png") no-repeat;
}

.icon_flag_ru,
.icon_flag_RU {
  background: url("assets/images/common/icon-flag-ru.png") no-repeat;
}

.icon_flag_sg,
.icon_flag_SG {
  background: url("assets/images/common/icon-flag-sg.png") no-repeat;
}

.icon_flag_tw,
.icon_flag_TW {
  background: url("assets/images/common/icon-flag-tw.png") no-repeat;
}

.icon_flag_vn,
.icon_flag_VN {
  background: url("assets/images/common/icon-flag-vn.png") no-repeat;
}

.icon_flag_sp,
.icon_flag_SP {
  background: url("assets/images/common/icon-flag-sp.png") no-repeat;
}

.icon_flag_ph,
.icon_flag_PH {
  background: url("assets/images/common/icon-flag-ph.png") no-repeat;
}

.icon_flag_hk,
.icon_flag_HK {
  background: url("assets/images/common/icon-flag-hk.png") no-repeat;
}

.icon_flag_ea,
.icon_flag_EA {
  width: 16px;
  height: 16px;
  background: url("assets/images/common/icon-flag-ea.png") no-repeat;
  background-size: contain;
}

.icon_flag_za,
.icon_flag_ZA {
  background: url("assets/images/common/icon-flag-za.png") no-repeat;
}

.icon_flag_pct,
.icon_flag_PCT {
  display: none;
}

.icon_download {
  background: url("assets/images/common/icon-download.svg") no-repeat;
}

.icon_download_with_white_bg {
  background: url("assets/images/common/icon-download-with-white-bg.svg")
    no-repeat;
}

.icon_download_attached {
  background: url("assets/images/common/icon-download-attached.svg") no-repeat;
}

.icon_download_attached_blue {
  background: url("assets/images/common/icon-download-attached-blue.svg")
    no-repeat;
}

.icon_download_attached_white {
  background: url("assets/images/common/icon-download-attached-white.svg")
    no-repeat;
}

.icon_download_blue {
  background: url("assets/images/common/icon-download-blue.svg") no-repeat;
}

.icon_upload {
  background: url("assets/images/common/icon-upload.svg") no-repeat;
}

.icon_more {
  background: url("assets/images/common/icon-expand-more.svg") no-repeat;
}

.icon_more_gray {
  background: url("assets/images/common/icon-expand-more-gray.svg") no-repeat;
}

.icon_more_white {
  background: url("assets/images/common/icon-expand-more-white.svg") no-repeat;
}

.icon_more_red {
  background: url("assets/images/common/icon-more-red.svg") no-repeat;
}

.icon_more_black {
  background: url("assets/images/common/icon-more-black.svg") no-repeat;
}

.icon_more_dot {
  background: url("assets/images/common/icon-more-dot.svg") no-repeat;
}

.icon_minus {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-minus.svg") no-repeat;
  background-size: cover;
}

.icon_menu_square {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-menu-square.png") no-repeat;
  background-size: cover;
}

.icon_plus_blue {
  background: url("assets/images/common/icon-plus-blue.png") no-repeat;
  background-size: cover;
}

.icon_plus_gray {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-plus-gray.svg") no-repeat;
  background-size: cover;
}

.icon_next {
  background: url("assets/images/common/icon-next.svg") no-repeat;
}

.icon_plus {
  background: url("assets/images/common/icon-plus.svg") no-repeat;
}

.icon_calendar {
  background: url("assets/images/common/icon-calendar.svg") no-repeat;
}

.icon_badge_ok {
  background: url("assets/images/common/icon-badge-ok.svg") no-repeat;
}

.icon_badge_del {
  background: url("assets/images/common/icon-badge-del.svg") no-repeat;
}

.icon_badge_info {
  background: url("assets/images/common/icon-badge-information.png") no-repeat;
}

.icon_badge_info_black {
  background: url("assets/images/common/icon-badge-information-black.png")
    no-repeat;
}

.icon_badge_info_green {
  background: url("assets/images/common/icon-badge-information-green.png")
    no-repeat;
}

.icon_badge_info_gray {
  background: url("assets/images/common/icon-badge-information-gray.svg")
    no-repeat;
}

.icon_badge_info_white {
  background: url("assets/images/common/icon-badge-information-white.svg")
    no-repeat;
}

.icon_info_with_bg {
  background: url("assets/images/common/icon-information-with-bg.svg") no-repeat;
}

.icon_info_with_white_bg {
  background: url("assets/images/common/icon-info-small-with-blue-bg.svg")
    no-repeat;
}

.icon_info_with_blue_bg {
  background: url("assets/images/common/icon-info-small-with-blue-bg.svg")
    no-repeat center center;
}

.icon_badge_open {
  background: url("assets/images/common/button-badge-open.svg") no-repeat;
}

.icon_message {
  background: url("assets/images/common/icon_message.svg") no-repeat;
  background-size: contain;
}

.icon_check_black {
  background: url("assets/images/common/icon_check_black.svg") no-repeat;
}

.icon_checkbox_blue {
  background: url("assets/images/common/icon-checkbox-on-blue.svg") no-repeat;
}

.icon_minimize {
  background: url("assets/images/chat/icon-minimize.svg") no-repeat;
}

.icon_m_menu {
  background: url("assets/images/common/icon_m_menu.svg") no-repeat;
}

.icon_m_bell {
  background: url("assets/images/mobile/icon_m_bell.svg") no-repeat;
}

.icon_m_bell_on {
  background: url("assets/images/mobile/icon_m_bell_on.svg") no-repeat;
}

.icon_m_call {
  background: url("assets/images/mobile/icon_m_call.svg") no-repeat;
}

.icon_m_fax {
  background: url("assets/images/mobile/icon_m_fax.svg") no-repeat;
}

.icon_m_mail {
  background: url("assets/images/mobile/icon_m_mail.svg") no-repeat;
}

.icon_m_search {
  background: url("assets/images/mobile/icon_search.svg") no-repeat;
}

.icon_more_arrow_up {
  background: url("assets/images/mobile/icon_more_arrow_up_black.svg") no-repeat;
}

.icon_more_arrow_up_white {
  background: url("assets/images/common/icon_more_arrow_up_white.svg") no-repeat;
}

.icon_more_arrow_down {
  background: url("assets/images/mobile/icon_arrow_down.svg") no-repeat;
}

.icon_more_arrow_down_black {
  background: url("assets/images/mobile/icon_arrow_down_black.svg") no-repeat;
}

.icon_more_arrow_left_black {
  transform: rotate(180deg);
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
}

.icon_arrow_left_gray {
  background: url("assets/images/mobile/icon_more_arrow_left_small_black.svg")
    no-repeat center center;
}

.icon_more_arrow_right {
  background: url("assets/images/mobile/icon_arrow_right.svg") no-repeat;
}

.icon_more_arrow_right_black {
  background: url("assets/images/mobile/icon_more_arrow_right_black.svg")
    no-repeat;
  background-size: contain;
}

.icon_more_arrow_left_small_black {
  background: url("assets/images/mobile/icon_more_arrow_left_small_black.svg")
    no-repeat;
}

.icon_more_arrow_right_small_black {
  background: url("assets/images/mobile/icon_more_arrow_right_small_black.svg")
    no-repeat;
}

.icon_more_arrow_right_white {
  background: url("assets/images/mobile/icon_more_arrow_right_white.svg")
    no-repeat;
}

.icon_arrow_right {
  background: url("assets/images/common/icon_arrow_right.svg") no-repeat;
}

.icon_arrow_right_orange {
  background: url("assets/images/common/icon_arrow_right_orange.svg") no-repeat;
}

.icon_arrow_right_blue {
  background: url("assets/images/common/icon-arrow-right-blue.svg") no-repeat;
}

.icon_arrow_right_s {
  background: url("assets/images/common/icon-arrow-right-s.svg") no-repeat;
}

.icon_arrow_right_s_with_bg {
  background: url("assets/images/common/icon-arrow-right-s-with-bg.svg")
    no-repeat;
}

.icon_lock {
  background: url("assets/images/mobile/icon_lock.svg") no-repeat;
}

.icon_alert_circle {
  width: 24px;
  height: 24px;
  background: url("assets/images/mobile/icon_alert_circle.svg") no-repeat;
}

.icon_condition {
  background: url("assets/images/mobile/icon_condition.svg") no-repeat;
  background-size: contain;
}

.icon_condition_white {
  background: url("assets/images/common/icon_condition_white.svg") no-repeat;
}

.icon_pencil {
  background: url("assets/images/common/icon-pencil.svg") no-repeat;
}

.icon_pencil-black {
  background: url("assets/images/common/icon-pencil-bk.svg") no-repeat;
}

.icon_pencil_outline {
  background: url("assets/images/common/icon_pencil_outline.svg") no-repeat
    center center;
}

.icon_badge_del_white {
  background: url("assets/images/common/icon-badge-del-white.svg") no-repeat;
}

.icon_camera {
  background: url("assets/images/common/icon-camera.svg") no-repeat;
}

.icon_posting_pencil {
  width: 32px;
  height: 32px;
  background: url("assets/images/common/icon-posting.svg") no-repeat;
}

.icon_link {
  background: url("assets/images/common/icon-link.svg") no-repeat;
}

.icon_link_clip {
  width: 42px;
  height: 42px;
  background: url("assets/images/common/icon-link-clip.png") no-repeat;
}

.icon_orange_add {
  background: url("assets/images/common/icon_plus_orange.svg") no-repeat;
}

.icon_blue_add {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-plus-bg-blue.svg") no-repeat;
}

.icon_big_blue_add {
  background: url("assets/images/common/icon-big-plus-bg-blue.svg") no-repeat;
}

.icon_search_white {
  background: url("assets/images/common/icon-search-white.svg") no-repeat;
}

.icon_search_gray {
  background: url("assets/images/common/icon-search-gray.svg") no-repeat;
}

.icon_setting {
  background: url("assets/images/common/icon-setting.svg");
  background-size: contain;
}

.icon_can_duplicates {
  width: 78px;
  background: url("assets/images/common/icon_can_duplicates.png") no-repeat;
}

.icon_fav_on {
  background: url("assets/images/common/icon-fav-on.svg") no-repeat;
}

.icon_fav_on_tack {
  background: url("assets/images/common/icon-fav-on-tack.png") no-repeat;
}

.icon_fav_off {
  background: url("assets/images/common/icon-fav-off.svg") no-repeat;
}

.icon_fav_off_tack {
  background: url("assets/images/common/icon-fav-off-tack.png") no-repeat;
}

.icon_red_dot {
  background: url("assets/images/common/icon_red_dot.svg") no-repeat;
}

.icon_task {
  background: url("assets/images/common/icon-task.svg") no-repeat;
}

.icon_idea {
  background: url("assets/images/common/icon-idea.svg") no-repeat;
}

.icon_bulb {
  background: url("assets/images/intro/icon_bulb.svg") no-repeat;
  background-size: contain;
}

.icon_bulb_blue {
  background: url("assets/images/taskManage/icon_bulb_blue.svg") no-repeat;
  background-size: contain;
}

.icon_bulb_check {
  background: url("assets/images/common/icon_bulb_check.png") no-repeat;
}

.icon_grade_auto {
  background: url("assets/images/intro/icon_grade_auto.svg") no-repeat;
  background-size: contain;
}

.icon_task {
  background: url("assets/images/intro/icon_task.svg") no-repeat;
  background-size: contain;
}

.icon_trade {
  background: url("assets/images/intro/icon_trade.svg") no-repeat;
  background-size: contain;
}

.icon_pay_manage {
  background: url("assets/images/intro/icon_pay_manage.svg") no-repeat;
  background-size: contain;
}

.icon_invest {
  background: url("assets/images/intro/icon_invest.svg") no-repeat;
  background-size: contain;
}

.icon_two_person {
  background: url("assets/images/intro/icon_two_person.svg") no-repeat;
  background-size: contain;
}

.icon_play {
  background: url("assets/images/intro/icon_play.svg") no-repeat;
  background-size: contain;
}

.icon_pause {
  background: url("assets/images/intro/icon_pause.svg") no-repeat;
  background-size: contain;
}

.icon_print {
  background: url("assets/images/common/icon-print-outline.svg") no-repeat;
}

.icon_annual {
  background: url("assets/images/intro/icon_annual.svg") no-repeat;
  background-size: contain;
}

.icon_ip_loans {
  background: url("assets/images/intro/icon_ip_loans.svg") no-repeat;
  background-size: contain;
}

.icon_open_market {
  background: url("assets/images/intro/icon_open_market.svg") no-repeat;
  background-size: contain;
}

.icon_company_matching {
  background: url("assets/images/intro/icon_company_matching.svg") no-repeat;
  background-size: contain;
}

.icon_open_market {
  background: url("assets/images/intro/icon_open_market.svg") no-repeat;
  background-size: contain;
}

.icon_plus_white {
  background: url("assets/images/common/icon_plus_white.svg") no-repeat;
}

.icon_personal {
  background: url("assets/images/common/icon_personal.png") no-repeat;
}

.icon_combined_shape {
  background: url("assets/images/common/bg_combined_shape.svg") no-repeat;
  background-size: contain;
}

.icon_refresh {
  width: 16px;
  height: 16px;
  background: url("assets/images/common/icon_refresh.svg") no-repeat;
  background-size: contain;
}
.icon_refresh_gray {
  background: url("assets/images/common/icon_refresh_gray.svg") no-repeat center
    center;
}

.icon_kakao {
  width: 32px;
  height: 32px;
  background: url("assets/images/signup/ic_kakaotalk.svg") no-repeat;
}

.icon_electric_bulb {
  background: url("assets/images/common/icon_electric_bulb.svg") no-repeat;
}

.icon_document {
  background: url("assets/images/doctrade/icon_document.svg") no-repeat;
  background-size: contain;
}

.icon_attention {
  background: url("assets/images/common/icon-attention.svg") no-repeat 50% 50%;
}

.icon_cyber-check {
  background: url("assets/images/cyber/icon_check.svg") no-repeat 50% 50%;
}

.icon_i {
  background: url("assets/images/common/icon-i.svg") no-repeat 50% 50%;
}

.icon_money {
  background: url("assets/images/common/icon-money.svg") no-repeat 50% 50%;
}

.icon_add {
  background: url("assets/images/common/icon-add.svg") no-repeat 50% 50%;
}

.icon_kakaotalk {
  width: 38px;
  height: 38px;
  background: url("assets/images/common/ic-kakaotalk.png") no-repeat 50% 50%;
  background-size: contain;
}

.icon_notify {
  width: 18px;
  height: 19px;
  background: url("assets/images/common/icon-notification-blue.svg") no-repeat
    50% 50%;
  background-size: contain;
}

.icon_notification_blue_full {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-notification-blue-full.svg")
    no-repeat;
}

.icon_notification_white_full {
  background: url("assets/images/common/icon-notification-white-full.svg")
    no-repeat;
}

.icon_arrow_down_white {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-down-white.svg") no-repeat
    50% 50%;
}

.icon_arrow_up_gray {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-up-gray.svg") no-repeat;
}

.icon_arrow_down_gray {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-arrow-down-gray.svg") no-repeat;
}

.icon_user_outline_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/login/icon-user-outline-blue.svg") no-repeat;
  background-size: contain;
}

.icon_user_outline {
  width: 24px;
  height: 24px;
  background: url("assets/images/login/icon-user-outline.svg") no-repeat;
  background-size: contain;
}

.icon_user_outline_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/login/icon-user-outline-blue.svg") no-repeat;
  background-size: contain;
}

.icon_user_18px {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-user-18px.png") no-repeat;
}

.icon_matching {
  width: 24px;
  height: 24px;
  background: url("assets/images/intro/tab-matching.svg") no-repeat;
  background-size: contain;
}

.icon_matching_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/intro/tab-matching-blue.svg") no-repeat;
  background-size: contain;
}

.icon_notification {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-notification.svg") no-repeat;
  background-size: contain;
}

.icon_notification_on {
  width: 18px;
  height: 18px;
  background: url("assets/images/common/icon-notification-on.svg") no-repeat;
  background-size: contain;
}

.icon_notification_blue {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-notification-blue.svg") no-repeat;
  background-size: contain;
}

.icon_double_arrow_right {
  background: url("assets/images/common/icon-double-arrow-right.svg") no-repeat;
}

.icon_double_arrow_right_white {
  width: 24px;
  height: 24px;
  background: url("assets/images/common/icon-double-arrow-right-white.svg")
    no-repeat;
  background-size: contain;
}

.icon_double_arrow_right_blue {
  background: url("assets/images/common/icon-double-arrow-right-blue.svg")
    no-repeat;
}

.icon_recommend_category {
  width: 67px;
  height: 68px;
  background: url("assets/images/common/icon-recommend-category.svg") no-repeat;
  background-size: contain;
}

.icon_category {
  background: url("assets/images/common/icon-category-outline.svg") no-repeat;
}

.icon_category_paper {
  background: url("assets/images/common/icon-category-paper.svg") no-repeat;
}

.icon_del_small_with_bg_gray {
  background: url("assets/images/common/icon-del-small-with-bg-gray.svg")
    no-repeat;
}

.icon_del_small_with_bg_white {
  background: url("assets/images/common/icon-del-small-with-bg-white.svg")
    no-repeat;
}

.icon_del_small_with_bg {
  background: url("assets/images/common/icon-del-small-with-bg.svg") no-repeat;
}

.icon_del_big_gray {
  width: 29px;
  height: 29px;
  background: url("assets/images/common/icon-big-del-gray.svg") no-repeat;
}

.icon_del_trashcan {
  background: url("assets/images/common/icon-del-trashcan.svg") no-repeat;
  background-size: contain;
}

.icon_del_small_white {
  background: url("assets/images/common/icon-del-small-white.svg") no-repeat;
}

.icon_reference {
  background: url("assets/images/common/icon-reference.svg") no-repeat;
}

.icon_view {
  background: url("assets/images/common/icon-eye-view.png") no-repeat;
}

.icon_view_gray {
  background: url("assets/images/common/icon-show.svg") no-repeat;
}

.icon_hidden {
  background: url("assets/images/common/icon-eye-hidden.png") no-repeat;
}

.icon-write-outline {
  background: url("assets/images/common/icon-write-outline.svg") no-repeat;
}

.icon_write {
  background: url("assets/images/common/icon-write.svg") no-repeat;
  background-size: contain;
}

.icon_copy {
  background: url("assets/images/common/icon-copy.svg") no-repeat center center;
}

.icon_government {
  background: url("assets/images/common/icon-government.svg") no-repeat;
}

.icon_list {
  background: url("assets/images/common/icon-list.svg") no-repeat;
}

.icon_menu_file {
  background: url("assets/images/common/icon-menu-file.svg") no-repeat center
    center;
}

.icon_menu_clock {
  background: url("assets/images/common/icon-menu-clock.svg") no-repeat center
    center;
}

.icon_menu_clock_gray {
  background: url("assets/images/common/icon-menu-clock-gray.svg") no-repeat
    center center;
}

.icon_menu_folder {
  background: url("assets/images/common/icon-menu-folder.svg") no-repeat center
    center;
}

.icon_menu_folder_gray {
  background: url("assets/images/common/icon-menu-folder-gray.svg") no-repeat
    center center;
}

.icon_menu_pencil {
  background: url("assets/images/common/icon-menu-pencil.svg") no-repeat center
    center;
}

.icon_menu_briefcase {
  background: url("assets/images/common/icon-menu-briefcase.svg") no-repeat
    center center;
}

.icon_menu_lightning {
  background: url("assets/images/common/icon-menu-lightning.svg") no-repeat
    center center;
}

.icon_menu_building_blue {
  background: url("assets/images/common/icon-menu-building-blue.svg") no-repeat
    center center;
}

.icon_menu_building_green {
  background: url("assets/images/common/icon-menu-building-green.svg") no-repeat
    center center;
}

.icon_mail_blue {
  background: url("assets/images/common/icon-mail-blue.svg") no-repeat center
    center;
}

.icon_3d_biznavi {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-biznavi.png") no-repeat
    center center;
}

.icon_3d_calendar {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-calendar.png") no-repeat
    center center;
}

.icon_3d_cloud {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-cloud.png") no-repeat
    center center;
}

.icon_3d_mail {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-mail.png") no-repeat
    center center;
}

.icon_3d_paper_aircraft {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper-aircraft.png")
    no-repeat center center;
}

.icon_3d_pencil {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-pencil.png") no-repeat
    center center;
}

.icon_3d_pie_graph {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-pie-graph.png")
    no-repeat center center;
}

.icon_3d_project {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-project.png") no-repeat
    center center;
}

.icon_3d_bar_graph {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-bar-graph.png")
    no-repeat center center;
}

.icon_3d_binocular {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-binocular.png")
    no-repeat center center;
}

.icon_3d_book {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-book.png") no-repeat
    center center;
}

.icon_3d_book_blue {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-book-blue.png")
    no-repeat center center;
}

.icon_3d_book_green {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-book-green.png")
    no-repeat center center;
}

.icon_3d_box {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-box.png") no-repeat
    center center;
}

.icon_3d_briefcase {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-briefcase.png")
    no-repeat center center;
}

.icon_3d_calculator {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-calculator.png")
    no-repeat center center;
}

.icon_3d_cart {
  width: 90px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-cart.png") no-repeat
    center center;
}

.icon_3d_clock {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-clock.png") no-repeat
    center center;
}

.icon_3d_decoration_star {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-decoration-star.png")
    no-repeat center center;
}

.icon_3d_doughnut_graph {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-doughnut-graph.png")
    no-repeat center center;
}

.icon_3d_folder {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-folder.png") no-repeat
    center center;
}

.icon_3d_gavel {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-gavel.png") no-repeat
    center center;
}

.icon_3d_handshake {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-handshake.png")
    no-repeat center center;
}

.icon_3d_heart {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-heart.png") no-repeat
    center center;
}

.icon_3d_info {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-info.png") no-repeat
    center center;
}

.icon_3d_like {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-like.png") no-repeat
    center center;
}

.icon_3d_loudspeaker {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-loudspeaker.png")
    no-repeat center center;
}

.icon_3d_magnifier_black {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-magnifier-black.png")
    no-repeat center center;
}

.icon_3d_magnifier_blue {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-magnifier-blue.png")
    no-repeat center center;
}

.icon_3d_magnifier_green {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-magnifier-green.png")
    no-repeat center center;
}

.icon_3d_magnifier_orange {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-calculator.png")
    no-repeat center center;
}

.icon_3d_magnifier_purple {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-magnifier-purple.png")
    no-repeat center center;
}

.icon_3d_message {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-message.png") no-repeat
    center center;
}

.icon_3d_message_dot {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-message-dot.png")
    no-repeat center center;
}

.icon_3d_paper {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper.png") no-repeat
    center center;
}

.icon_3d_paper_purple {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper-purple.png")
    no-repeat center center;
}

.icon_3d_paper_blue {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper-blue.png")
    no-repeat center center;
}

.icon_3d_paper_green {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper-green.png")
    no-repeat center center;
}

.icon_3d_paper_list {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-paper-list.png")
    no-repeat center center;
}

.icon_3d_pie_graph_pink {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-pie-graph-pink.png")
    no-repeat center center;
}

.icon_3d_pie_graph_purple {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-pie-graph-purple.png")
    no-repeat center center;
}

.icon_3d_pie_graph_green {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-pie-graph-green.png")
    no-repeat center center;
}

.icon_3d_play {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-play.png") no-repeat
    center center;
}

.icon_3d_plus_blue {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-plus-blue.png")
    no-repeat center center;
}

.icon_3d_plus_green {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-plus-green.png")
    no-repeat center center;
}

.icon_3d_puzzle {
  width: 90px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-puzzle.png") no-repeat
    center center;
}

.icon_3d_receipt {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-receipt.png") no-repeat
    center center;
}

.icon_3d_rocket {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-rocket.png") no-repeat
    center center;
}

.icon_3d_star {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-star.png") no-repeat
    center center;
}

.icon_3d_stopwatch {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-stopwatch.png")
    no-repeat center center;
}

.icon_3d_tack {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-tack.png") no-repeat
    center center;
}

.icon_3d_talk {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-talk.png") no-repeat
    center center;
}

.icon_3d_target {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-target.png") no-repeat
    center center;
}

.icon_3d_temple {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-temple.png") no-repeat
    center center;
}

.icon_3d_to_do_list {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-to-do-list.png")
    no-repeat center center;
}

.icon_3d_camera {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-camera.png") no-repeat
    center center;
}

.icon_3d_graph {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-graph.png") no-repeat
    center center;
}

.icon_3d_cloud_n {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-cloud-n.png") no-repeat
    center center;
}

.icon_3d_magnifier {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-magnifier.png")
    no-repeat center center;
}

.icon_3d_house {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-house.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_bulb {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-bulb.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_shield {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-shield.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_money {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-money.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_news {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-news.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_matching {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-matching.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_phone {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-phone.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_lawfirm {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-lawfirm.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_vacation {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-vacation.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_notebook {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-notebook.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_manage {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-manage.png") no-repeat
    center center;
  background-size: contain;
}

.icon_3d_cashflow {
  width: 83px;
  height: 83px;
  background: url("assets/images/common/icon-3d/icon-3d-cashflow.png") no-repeat
    center center;
  background-size: contain;
}

.icon_widget_calendar {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-calendar.png") no-repeat;
}

.icon_widget_clock {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-clock.png") no-repeat;
}

.icon_widget_cake {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-cake.png") no-repeat;
  background-size: contain;
}

.icon_widget_briefcase {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-briefcase.png") no-repeat;
}

.icon_widget_medicine {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-medicine.png") no-repeat;
}

.icon_widget_pencil {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-pencil.png") no-repeat;
}

.icon_widget_parasol {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-parasol.png") no-repeat;
}

.icon_widget_paper {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon-widget-paper.png") no-repeat;
}

.widget_list_task {
  background: url("assets/images/common/widget-list-task.svg") no-repeat;
}

.widget_list_market {
  background: url("assets/images/common/widget-list-market.svg") no-repeat;
}

.widget_list_patent {
  background: url("assets/images/common/widget-list-patent.svg") no-repeat;
}

.no_chart_image {
  width: 120px;
  height: 106px;
  background-size: contain;
  background: url("assets/images/common/no-chart-image.png") no-repeat center
    center;
}

.item_arrow {
  background: url("assets/images/intro/item_arrow.png") no-repeat center center;
}

.icon_heart {
  background: url("assets/images/companyInfo/heart_icon.png") no-repeat center
    center;
}
.icon_heart_red {
  background: url("assets/images/companyInfo/icon-heart-red.png") no-repeat
    center center;
}

.icon_user {
  background: url("assets/images/companyInfo/user_icon.png") no-repeat center
    center;
}

.icon_download_arrow {
  background: url("assets/images/companyInfo/download_icon.png") no-repeat
    center center;
}

.icon_mod {
  background: url("assets/images/companyInfo/mod_icon.png") no-repeat center
    center;
}

.icon_slider_prev {
  background: url("assets/images/competitor/icon_prev.png") no-repeat center
    center;
}

.icon_slider_next {
  background: url("assets/images/competitor/icon_next.png") no-repeat center
    center;
}
.icon_in_all {
  background: url("assets/images/cashflow/icon_in_all.png") no-repeat center
    center;
}
.icon_page_start {
  background: url("assets/images/common/icon_page_start.svg") no-repeat center
    center;
}
.icon_page_next {
  background: url("assets/images/common/icon_page_next.svg") no-repeat center
    center;
}
.icon_page_end {
  background: url("assets/images/common/icon_page_end.svg") no-repeat center
    center;
}
.icon_page_prev {
  background: url("assets/images/common/icon_page_prev.svg") no-repeat center
    center;
}
.icon_delete {
  background: url("assets/images/common/icon_delete.svg") no-repeat;
}
.icon_folder_yellow {
  background: url("assets/images/competitor/icon_folder_yellow.png") no-repeat
    center center;
}
.holdings_home {
  background: url("assets/images/holdings/holdings_home.png") no-repeat center
    center;
}
.holdings_home_on {
  background: url("assets/images/holdings/holdings_home_on.png") no-repeat
    center center;
}
.holdings_status {
  background: url("assets/images/holdings/holdings_status.png") no-repeat center
    center;
}
.holdings_status_on {
  background: url("assets/images/holdings/holdings_status_on.png") no-repeat
    center center;
}
.holdings_room {
  background: url("assets/images/holdings/holdings_room.png") no-repeat center
    center;
}
.holdings_room_on {
  background: url("assets/images/holdings/holdings_room_on.png") no-repeat
    center center;
}
.holdings_invest {
  background: url("assets/images/holdings/holdings_invest.png") no-repeat center
    center;
}
.holdings_invest_on {
  background: url("assets/images/holdings/holdings_invest_on.png") no-repeat
    center center;
}
.holdings_report {
  background: url("assets/images/holdings/holdings_report.png") no-repeat center
    center;
}
.holdings_report_on {
  background: url("assets/images/holdings/holdings_report_on.png") no-repeat
    center center;
}
.holdings_set {
  background: url("assets/images/holdings/holdings_set.png") no-repeat center
    center;
}
.holdings_set_on {
  background: url("assets/images/holdings/holdings_set_on.png") no-repeat center
    center;
}
.holdings_invest_0 {
  background: url("assets/images/holdings/holdings_invest_0.png") no-repeat
    center center;
}
.holdings_invest_1 {
  background: url("assets/images/holdings/holdings_invest_1.png") no-repeat
    center center;
}
.holdings_invest_2 {
  background: url("assets/images/holdings/holdings_invest_2.png") no-repeat
    center center;
}
.holdings_invest_3 {
  background: url("assets/images/holdings/holdings_invest_3.png") no-repeat
    center center;
}
.icon_building {
  background: url("assets/images/companyInfo/icon_building.png") no-repeat
    center center;
}
.icon_excel_editor {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon_excel_editor.png") no-repeat;
}
.icon_web_editor {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon_web_editor.png") no-repeat;
}
.icon_bookmark {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon_bookmark.png") no-repeat;
  cursor: pointer;
}
.icon_empty_bookmark {
  width: 24px;
  height: 32px;
  background: url("assets/images/common/icon_empty_bookmark.png") no-repeat;
  cursor: pointer;
}