#ResearchTechMain {
 // margin: 0 auto;
  //width: 1280px;
  margin: 0 50px 0 50px;
  display: flex;
  .wrap_category {
    margin-top: 18px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .category {
      font-family: Pretendard_extraBold;
      font-size: 22px;
    }
    a {
      padding: 0 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 30px;
      border-radius: 9px;
      background-color: #eff3fb;

      span {
        display: block;
        width: 87px;
        font-size: 12px;
        font-family: Pretendard_bold;
        color: #606060;
      }
    }
  }

  .keyword_find {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    padding: 30px 40px 0 20px;
    h2 {
      font-family: Pretendard_bold;
      font-size: 14px;
      color: #343434;
    }
    .search_wrapper {
      width: 850px;
      height: 42px;
      margin-left: 17px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      input[type="text"] {
        width: 820px;
        height: 18px;
        font-family: Pretendard_semiBold;
        font-size: 14px;
        color: #343434;
        border: none;
      }
    }
  }

  table {
    margin: 15px auto 0 auto;
    //width: 970px;
    width: calc(100% - 70px);
    thead {
      background-color: var(--color-Gallery);

      th {
        color: var(--color-Mine_Shaft);
      }
    }

    th:nth-child(2),
    td:nth-child(2),
    th:nth-child(3),
    td:nth-child(3) {
      padding-left: 20px;
      text-align: left;
    }
    .relate_research {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-family: Pretendard_bold;
      font-size: 12px;
      border-radius: 9px;
      background-color: #eff3fb;
    }
  }
}
