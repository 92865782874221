@import "src/include";

#ConsortiumMain{
	.pc{
		.consortiumMain{
			width: 950px;
			margin: 0 auto;
			padding: 40px 0;
			&_box-wrap{
				display: flex;
				width: 880px;
				height: 110px;
				margin: 0 auto 45px;
				padding: 10px 15px;
				border-radius: 20px;
				background-color: #f1f1f1;
				justify-content: center;
				align-items: flex-start;
			}
			&_box{
				position: relative;
				width: calc(100% - 123px);
				height: 100%;
				padding: 5px 0 5px 165px;
				&::after{
					position: absolute;
					content: '';
					width: 60px;
					height: 53px;
					top: 50%;
					left: 35px;
					transform: translateY(-50%);
					background: url(../../../assets/images/consortium/icon_consortium_box.svg)no-repeat 50% 50%;
					background-size: cover;
				}
				&_title{
					font-family: Pretendard_extraBold;
					font-size: 18px;
					color: #343434;
					padding-bottom: 10px;
				}
				&_text{
					font-family: Pretendard_bold;
					font-size: 14px;
					color: #606060;
				}
			}
			&_btn{
				width: 123px;
				button{
					text-indent: -9999px;
					width: 100%;
					height: 24px;
					background: url(../../../assets/images/common/icon-tutorial-btn.png) no-repeat 50% 50%;
					background-size: cover;
				}
			}
			&_title-wrap{
				width: 880px;
				margin: 0 auto 30px;
			}
			&_title{
				display: flex;
				justify-content: space-between;
				align-items: center;
				&_text-wrap{
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
				&_text{
					font-family: Pretendard_bold;
					font-size: 22px;
					color: #343434;
					margin-right: 10px;
				}
				&_menu{
					display: flex;
					justify-content: center;
					align-items: center;
					&_list{
						&_button{
							margin: 0 15px;
							font-family: Pretendard_bold;
							font-size: 18px;
							color: #343434;
							background-color: transparent;
						}
						&.on{
							.consortiumMain_title_menu_list_button{
								font-family: Pretendard_extraBold;
								color: var(--color-Web-Orange);
							}
						}
					}
				}
				&_btn-wrap {
					display: flex;
					column-gap: 10px;

					a {
						display: flex;
						justify-content: center;
						align-items: center;
					}
					.btn_register{
						width: 140px;
						height: 32px;
						color: #fff;
						background-color: #4593f5;
						font-family: Pretendard_bold;
						font-size: 14px;
						text-align: center;
						border-radius: 4px;
					}
					.btn_supervise{
						width: 140px;
						height: 32px;
						color: #fff;
						background-color: #4593f5;
						font-family: Pretendard_bold;
						font-size: 14px;
						text-align: center;
						border-radius: 4px;
					}
					&_bookmark{
						position: relative;
						width: 140px;
						height: 32px;
						line-height: 30px;
						font-family: Pretendard_extraBold;
						font-size: 14px;
						color: #343434;
						padding: 0 20px 0 40px;
						background-color: transparent;
						border: 2px solid var(--color-Web-Orange);
						border-radius: 4px;
						&::after{
							position: absolute;
							content: '';
							width: 16px;
							height: 16px;
							top: 50%;
							left: 15px;
							transform: translateY(-50%);
							background: url(../../../assets/images/common/icon-fav-on.svg) no-repeat 50% 50%;
							background-size: cover;
						}
					}
				}
			}
			&_search-wrap{
				margin-bottom: 40px;
			}
			&_search{
				display: flex;
				justify-content: center;
				align-items: center;
				&_title{
					font-family: Pretendard_bold;
					font-size: 18px;
					color: #343434;
					margin-right: 20px;
				}
				&_select{
					width: 185px;
					height: 32px;
					border: 1px solid #d8d8d8;
					border-radius: 4px;
					padding-left: 10px;
					margin-right: 5px;
					font-family: Pretendard_bold;
					color: #343434;
				}
				&_box{
					display: flex;
					height: 32px;
					justify-content: center;
					align-items: center;
					border: 1px solid #d8d8d8;
					border-radius: 4px;
					margin-right: 5px;
					&_select{
						width: 110px;
						height: 100%;
						padding-left: 10px;
						border: none;
						background-color: transparent;
						font-family: Pretendard_bold;
						color: #343434;
					}
					&_input{
						width: 380px;
						height: 100%;
						padding-left: 10px;
						border: none;
						background-color: transparent;
					}
				}
				&_button{
					width: 24px;
					height: 24px;
					text-indent: -9999px;
					background-color: transparent;
					background: url(../../../assets/images/common/icon-search.svg) no-repeat 50% 50%;
					background-size: cover;
				}
			}
			&_table-top{
				display: flex;
				width: 880px;
				margin: 0 auto 10px;
				justify-content: space-between;
				align-items: center;
				&_title{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					.title{
						font-family: Pretendard_bold;
						font-size: 18px;
						color: #343434;
						margin-right: 10px;
					}
					.text{
						font-family: Pretendard_semiBold;
						font-size: 14px;
						color: #606060;
						em{
							font-family: Pretendard_extraBold;
							font-size: 18px;
							color: var(--color-Web-Orange);
							margin-left: 5px;
						}
					}
				}
				&_select-wrap{
					width: 110px;
					height: 24px;
				}
				&_select{
					font-family: Pretendard_bold;
					font-size: 14px;
					width: 100%;
					color: #343434;
				}
			}
			&_table{
				border-top: 1px solid #d8d8d8;
				margin-bottom: 60px;

				&_list{
					display: flex;
					height: 140px;
					justify-content: center;
					align-items: flex-start;
					padding: 10px 20px;
					border-bottom: 1px solid #d8d8d8;

					&.no_data {
						display: flex;
						justify-content: center;
						align-items: center;
						font-family: Pretendard_bold;
						color: #747474;
					}
					&_img{
						width: 165px;
						height: 100%;
						border: 1px solid #d8d8d8;
						margin-right: 10px;
						a{
							display: inline-block;
							width: 100%;
							height: 100%;
						}
						img{
							width: 100%;
							height: 100%;
							object-fit: contain;
						}
					}
					&_info{
						width: calc(100% - 199px);
						.list{
							&_info{
								height: 48px;
							}
							&_title{
								display: inline-block;
								height: 24px;
								line-height: 24px;
								font-family: Pretendard_bold;
								font-size: 14px;
								color: #343434;
								margin-bottom: 5px;
							}
							&_text{
								height: 13px;
								font-size: 12px;
								color: rgba(0, 0, 0, 0.87);
								max-width: 660px;
								overflow: hidden;
								white-space: break-spaces;
							}

							&_table{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex-wrap: wrap;
								width: 700px;
								&_title{
									font-family: Pretendard_extraBold;
									font-size: 12px;
									width: 50px;
									height: 24px;
									line-height: 24px;
									color: #4593f5;
								}
								&_info{
									font-size: 12px;
									width: 250px;
									height: 24px;
									line-height: 24px;
									padding-left: 5px;
									@include text-ellipsis(1);
									color: rgba(0, 0, 0, 0.87);
									&:nth-of-type(2n){
										width: 350px;
									}
								}
							}
						}
					}
					&_fav{
						.btn_fav{
							width: 24px;
							height: 24px;
							text-indent: -9999px;
							overflow: hidden;
							&.on{
								background: url(../../../assets/images/common/icon-fav-on.svg) no-repeat 50% 50%;
								background-size: cover;
							}
							&.off{
								background: url(../../../assets/images/common/icon-fav-off.svg) no-repeat 50% 50%;
								background-size: cover;
							}
						}
					}
				}
			}
		}
	}
	.mo{
		.consortiumMain{
			width: 100vw;
			overflow: hidden;
			&_top{
				width: 100%;
				height: 122px;
				margin: 60px 0 0;
				padding-top: 32px;
				text-align: center;
				background-image: linear-gradient(to top, rgba(129, 202, 233, 0.2), rgba(128, 206, 183, 0.2));
			}
			&_title{
				font-family: Pretendard_extraBold;
				font-size: 20px;
				color: #1d334e;
				margin-bottom: 12px;
			}
			&_subtitle{
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #606060;
				text-align: center;
			}
			&_content{
				&_selectBox{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
					margin-bottom: 12px;
				}
				&_option{
					display: block;
					width : calc(50% - 20px);
					height : 38px;
					line-height: 38px;
					&_button{
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						font-family: Pretendard_extraBold;
						font-size: 12px;
						color: #747474;
						text-align: center;
						background-color: transparent;
						&.active{
							&::after{
								position: absolute;
								content: '';
								width: 100%;
								height: 4px;
								bottom: 0;
								left: 50%;
								transform: translateX(-50%);
								background-color: #6bd3ff;
							}
						}
					}
				}
				&_search{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 320px;
					height: 40px;
					border: 1px solid #d8d8d8;
					border-radius: 4px;
					margin: 0 auto 12.5px;
					overflow: hidden;
					&_select{
						width: 90px;
						height: 100%;
						font-family: Pretendard_extraBold;
						font-size: 12px;
						color: #606060;
						padding-left: 16px;
					}
					&_input{
						width: 190px;
						height: 100%;
						border: none;
						font-size: 14px;
						&::placeholder{
							font-size: 14px;
							color: #959595;
						}
					}
					&_button{
						position: relative;
						width: 40px;
						height: 100%;
						background: #fff;
						text-indent: -9999px;
						&::after{
							position: absolute;
							content: '';
							width: 24px;
							height: 24px;
							top: 50%;
							left: 50%;
							transform: translateX(-50%) translateY(-50%);
							background: url(../../../assets/images/common/icon-search.svg) no-repeat 50% 50%;
							background-size: cover;
						}
					}
				}
				&_table{
					width: 100%;
					padding: 0 20px;
					margin-bottom: 25.5px;
					&_link{
						display: block;
					}
					&_list{
						padding: 11.5px 0;
						border-bottom: 1px solid #d8d8d8;
						.list-title-wrap{
							display: flex;
							justify-content: space-between;
							align-items: center;
							margin-bottom: 5px;
						}
						.list-name{
							font-family: Pretendard_extraBold;
							font-size: 14px;
							color: #1d334e;
						}
						.list-field{
							font-family: Pretendard_bold;
							font-size: 14px;
							color: #707070;
							margin-bottom: 2px;
						}
						.list-text{
							height: 26px;
							font-family: Pretendard_bold;
							font-size: 12px;
							color: #707070;
							@include text-ellipsis(2);
							margin-bottom: 5px;
						}
						.list_table{
							display: flex;
							justify-content: center;
							align-items: center;
							flex-wrap: wrap;
							&_title{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: 120px;
								height: 20px;
								font-family: Pretendard_bold;
								font-size: 14px;
								color: #747474;
								margin-bottom: 5px;
							}
							&_info{
								display: flex;
								justify-content: flex-start;
								align-items: center;
								width: calc(100% - 120px);
								height: 20px;
								line-height: 20px;
								font-family: Pretendard_bold;
								font-size: 12px;
								color: #707070;
								margin-bottom: 5px;
								@include text-ellipsis(1);
							}
						}
					}
				}
			}
			#pagination{
				margin-bottom: 13px;
			}
			&_addition{
				position: relative;
				width: calc(100% - 40px);
				line-height: 25px;
				font-family: Pretendard_bold;
				font-size: 12px;
				color: #959595;
				margin: 0 auto 24px;
				padding-left: 30px;
				word-break: keep-all;
				&::after{
					position: absolute;
					content: '';
					width: 24px;
					height: 24px;
					top: 0;
					left: 0;
					background: url(../../../assets/images/common/icon-badge-information_gray.svg) no-repeat 50% 50%;
					background-size: cover;
				}
			}
		}
	}
}
