.agency_and_director {
  input[type="text"] {
    width: 365px;
  }
}
.plan_one_wrapper {
  .plan_text {
    display: flex;
    padding: 20px 0 19px 0;
    align-items: center;
    p {
      font-size: 22px;
      color: #4593F5;
    }
    span {
      text-align: right;
      font-family: Pretendard_bold;
      font-size: 12px;
      color: #606060;
    }
  }
  .plan_btn_wrapper {
    width:200px;
    display: flex;
    margin: 0 9px 0 8px;
  }
  .plan_secure {
    display: flex;
    align-items: center;
    .plan_secure_text {
      width:150px;
      font-size: 14px;
      color: #343434;
      margin-right: 3px;
    }
  }
  .rnd_plan_item {
    input[type="text"] {
      //width: 365px;
      //width:500px;
      //width: 555px; /* 고정된 너비 설정 */
      width:100%;
      margin-right: 15px;
      max-width: 100%; /* 최대 너비 설정 */
    }
  }
  .plan_select {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    font-family: Pretendard_extraBold;
    .plan_contest_btn {
      display: flex;
      align-items: center;
      span {
        font-family: Pretendard_bold;
        margin: 0 4px 0 10px;
      }
    }
  }
  .tech_classification {
    display: flex;
    flex-direction: column;
    .big_text {
      margin: 26px 0 15px 0;
    }
    div {
      display: flex;
      align-items: center;
    }
    .tech_classification_item {
      margin-bottom: 25px;
    }
    .tech_classification_code {
      //width: 260px;
      width:600px;
      margin-left: 14px;
      p {
        margin-left: 5px;
        font-size: 14px;
        //width: 120px;
        width:200px;
        margin-right: 19px;
      }
      input[type="text"] {
        //width: 113px;
        width:100%;
        margin-right:15px;
      }
    }
    .text_wrapper {
      //width: 145px;
      width:200px;
    }
  }
  .tech_name {
    display: flex;
    flex-direction: column;
    .tech_name_text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      p:nth-of-type(2) {
        margin: 0 10px 0 10px;
      }
    }
    .tech_name_item {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-bottom: 10px;
      .small_text {
        width: 98px;
        letter-spacing: 30px;
        input:nth-of-type(1) {
          margin-top: 5px;
        }
      }
    }
    .rnd_name {
      input[type="text"] {
        //width: 851px;
        width:100%;
        margin-right:15px;
      }
    }
  }
  .grid_wrapper {
    display: flex;
    flex-direction: column;
    .plan_grid_items {
      margin-top: 8px;
      margin-bottom: 20px;
    }
  }
  .plan_grid_items {
    .plan_grid_item {
      p {
        span {
          padding: inherit;
        }
      }
    }
  }
  .research_text {
    display: flex;
    justify-content: space-between;
    .research_text_left {
      display: flex;
      align-items: center;
    }
    .research_text_right {
      display: flex;
      align-items: center;
      p {
        margin-right: 14px;
      }
      span {
        letter-spacing: 10px;
      }
      button {
        margin-right: 14px;
      }
    }
  }
  .research_period_wrapper {
    margin-top: 5px;
  }
  .research_period_table {
    //width: 970px;
    width:calc(100% - 20px);
    font-size: 14px;
    th, td {
      font-size: 14px;
      font-family: Pretendard_semiBold;
      .front {
        width: 50px;
      }
      .back {
        width: 42px;
      }
      span {
        padding-left: 5px;
      }
    }
    .react-datepicker__input-container {
      input {
        background-image: url("../../../../../assets/images/businessPlan/icon-calendar.svg");
        background-position: right 10px center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        width: 200px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid #d8d8d8;
        padding: 5px;
      }
      span {
        font-size: 14px;
        color: #959595;
        letter-spacing: inherit;
      }
    }
    thead {
      background-color: #fff;
      th {
        color: #343434;
        font-weight: 400;
      }
    }
    .table_item_all {
      background-color: #f0f0f0;
    }
  }
  .step {
    background-color: rgba(240, 240, 240, 0.2);
  }
  .research_cost {
    margin-top: 20px;
  }
  .research_cost_table {
    width:calc(100% - 20px);
    //width: 970px;
    margin-top: 5px;
    thead {
      background-color: #f0f0f0;
      th {
        color: #343434;
      }
    }
    tbody {
      tr > td{
        font-family: Pretendard_semiBold;
        font-size: 14px;
      }
      span {
        padding-left: 5px;
        letter-spacing: 2px;
      }
      input[type="text"] {
        width: 70px;
      }
    }
  }
  .research_agency {
    margin-top: 20px;
    .research_agency_table {
      //width: 970px;
      width:calc(100% - 15px);
      margin-top: 5px;
      thead {
        background-color: #f0f0f0;
        th {
          color: #343434;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      tbody {
        tr > td{
          font-family: Pretendard_semiBold;
          font-size: 14px;
        }
        select {
          font-family: Pretendard_semiBold;
          font-size: 13px;
          padding: 10px 20px 10px 0;
        }
        .role {
          width: 105px;
          height: 42px;
          border: 1px solid #d8d8d8;
          border-radius: 4px;
          padding: 5px;
        }
      }
    }
  }
  .research_joint {
    margin-top: 23px;
    .research_joint_text {
      display: flex;
    }
  }
}

.end_text {
  margin: 10px 0 0 14px;
  font-family: Pretendard_semiBold;
  font-size: 12px;
  line-height: 1.75;
  color: #606060;
}

.prev_next_button {
  margin-top: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    letter-spacing: 10px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prev_button {
    margin-right: 14px;
  }
}
