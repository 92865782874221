#RenewalSidebar {
  width: 240px;
  background-color: rgba(255,255,255,0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  .userInfo {
    width: 214px;
    height: 288px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #343434;
    .userImage {
      width: 79px;
      height: 79px;
      border-radius: 50%;
      background-color: #fff;
      margin-bottom: 12px;
    }
    .department {
      font-size: 10px;
      border-radius: 5px;
      background-color: rgba(107, 211, 255, 0.2);
      display: inline-block;
      padding: 2px;
    }
    .user_name {
      font-family: NotoSansKR;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 28px;
        font-weight: bold;
      }
    }
    .dateTime {
      width: 240px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 17px;
      time:nth-child(1) {
        color: #959595;
        font-family: Pretendard_bold;
        font-size: 12px;
      }
      time:nth-child(2) {
        font-family: Pretendard_extraBold;
        font-size: 18px;
      }
    }
    .vacation {
      width: 240px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 17px;
      p:nth-child(1) {
        font-family: NotoSansKR;
        font-size: 16px;
      }
      p:nth-child(2) {
        font-family: NotoSansKR;
        font-size: 15px;
        color: #606060;
        span {
          font-size: 20px;
          color: #0078f1;
        }
      }
    }
  }
  .recentUseCategory {
    margin-top: 25px;
  }
  .virtualOffice {
    margin-top: 30px;
    width: 170px;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url("../../../assets/images/intro/renewal/virtual-office.png");
    p {
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-family: Pretendard_bold;
      font-size: 20px;
      line-height: 1.2;
      color: #fff;
    }
    i {
      position: absolute;
      right: 25px;
      bottom: 10px;
    }
  }
}
