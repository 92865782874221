#AdminStatistic {
    padding-top: 40px;

    .title {
      margin-bottom: 34px;
      font-size: 22px;
      font-family: Pretendard_extraBold;
    }

    th:nth-of-type(2),
    td:nth-of-type(2) {
        text-align: center !important;
    }
}
