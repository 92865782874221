@import "src/include";

#docTradeMyTransaction{
	width: 100%;
	height: 100%;
	margin: 0 auto;

	.mytransaction{
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 1080px;
		height: 100%;
		margin: 0 auto;
		&_aside{
			width: 200px;
			height: 100%;
			padding: 70px 0 0 30px;
			&_list-wrap{
				&_title{
					display: inline-block;
					font-family: Pretendard_extraBold;
					font-size: 22px;
					color: #343434;
					margin-bottom: 15px;
				}
			}
			&_list{
				height: 45px;
				line-height: 45px;
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #343434;
				.mytransaction_aside_list_link{
					display: inline-block;
					height: 100%;
					background-color: #ffffff;
				}

				&:hover, &:focus, &.on{
					.mytransaction_aside_list_link{
						color: var(--color-Web-Orange);
					}
				}
			}
		}
		&_section-wrap{
			width: calc(100% - 200px);
			height: 100%;
			padding-top: 70px;
		}
		&_section-purchase{
			margin-bottom: 90px;
		}
		&_section{
			width: 880px;
			margin: 0 auto;
			&_title{
				font-family: Pretendard_bold;
				font-size: 22px;
				color: #343434;
				margin-bottom: 47px;
			}
		}
	}
}
