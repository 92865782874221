#myPageMenu {
    min-width: 150px;

    h2 {
        margin-bottom: 16px;
        font-size: 22px;
        font-family: Pretendard_bold;
    }

    ul {
        li {
            font-size: 14px;
            letter-spacing: 0.25px;
            color: #000000;
            cursor: pointer;

            a {
                display: block;
            }
        }

        li.active {
           a {
               font-family: Pretendard_bold;
               color: var(--color-Web-Orange);
           }
        }

        li+li {
            margin-top: 15px;
        }
    }
}
