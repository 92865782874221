#EstimateDone {
  height: 80vh;
  background-color: #f7faff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
  h2 {
    background-image: linear-gradient(to right, #0d7, #0078f1);
    font-size: 40px;
    font-weight: 600;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  p {
    text-align: center;
    font-size: 26px;
    line-height: 1.4;
  }
  button {
    width: 200px;
    height: 42px;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    background: #1474FF;
  }
}
