.marketInfoEditList {
  //width: 1030px;
  width:100%;
  .header {
    font-family: Pretendard_bold;
    color: #343434;
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  table {
    //width: 970px;
    width:100%;
    margin: 25px 0;
    .btn_load {
      width: 47px;
      height: 24px;
      font-family: Pretendard_bold;
      font-size: 12px;
      color: #FFFFFF;
      border-radius: 8px;
      background-color: #05ac9e;
    }
  }
}
