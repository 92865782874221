#WizardPop {
  width: 100%;
  height: calc(100vh - 53px);
  position: absolute;
  left: 0;
  top: 0;

  .close {
    position: absolute;
    right: 6px;
    top: 8px;
    background: transparent;
  }
}
