#MobileMyPage {
    padding-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 9999;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 60px;

        button {
            position: absolute;
            left: 20px;
            background-size: contain;
        }

        h2 {
            font-size: 16px;
            font-family: Pretendard_extraBold;
            line-height: 22px;
            color: #443018;
        }
    }

    .myinfo {
        padding: 28px 20px 27px;
        min-height: 144px;
        color: #ffffff;
        background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange));

        .access_account {
            margin-bottom: 8px;

            h2 {
                margin-bottom: 4px;
                font-size: 24px;
                font-family: Pretendard_extraBold;
                line-height: 30px;
                color: #ffffff;
            }

            p {
                font-size: 18px;
                font-family: Pretendard_bold;
                line-height: 24px;
                color: #ffffff;
            }
        }

        .link {
            display: flex;
            align-items: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 5px;
                font-size: 12px;
                font-family: Pretendard_bold;
                line-height: 24px;
                color: #ffffff;

                .icon_more_arrow_right_white {
                    width: 20px;
                    height: 20px;
                    background-size: contain;
                }
            }
        }
    }

    .company_code {
        margin: 14px auto 0 auto;
        width: 90%;

        .my_code {
            padding: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .wrap_code {
                display: flex;

                p {
                    margin-right: 6px;
                    font-size: 16px;
                    font-family: Pretendard_bold;
                    color: #606060;
                }

                input {
                    margin-right: 8px;
                    width: 70px;
                    font-size: 16px;
                    font-family: Pretendard_extraBold;
                    color: #606060;
                    border: none;
                    background-color: transparent;
                }

                input:focus {
                    outline: none;
                }
            }

            button {
                width: 83px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: Pretendard_extraBold;
                line-height: 20px;
                color: #959595;
                border-radius: 2px;
                border: solid 1px #959595;
                background-color: #ffffff;
            }
        }

        .alert {
            padding: 4px;
            font-size: 12px;
            font-family: Pretendard_bold;
            line-height: 18px;
            color: #606060;
            text-align: center;
        }

        .box {
            border-radius: 4px;
            background-color: #f0f0f0;
        }

        .box+.box {
            margin-top: 10px;
        }
    }

    .product {
        margin: 20px auto 40px auto;
        width: 90%;

        .title {
            margin-bottom: 8px;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            line-height: 26px;
            color: #606060;
        }

        .alert {
            display: flex;

            i {
                margin-right: 6px;
            }

            p {
                font-size: 12px;
                font-family: Pretendard_bold;
                line-height: 18px;
                color: #959595;
            }
        }

        .list {
            margin-top: 11.5px;

            li {
                .item {
                    display: flex;
                    align-items: center;
                    height: 35px;

                    .idx {
                        margin-right: 16px;
                        width: 24px;
                        font-size: 12px;
                        font-family: Pretendard_extraBold;
                        line-height: 18px;
                        color: #959595;
                    }

                    .name {
                        font-size: 14px;
                        font-family: Pretendard_extraBold;
                        line-height: 20px;
                        color: #606060;
                    }
                }
            }

            li:first-child {
                border-top: 1px solid #d8d8d8;
            }

            li:last-child {
                border-bottom: 1px solid #d8d8d8;
            }

            li+li {
                border-top: 1px solid #d8d8d8;
            }
        }

        .list {
            max-height: 200px;
            overflow: hidden;
            overflow-y: scroll;
        }
    }

    .use_price {
        margin: 20px auto 48px auto;
        width: 90%;

        .title {
            margin-bottom: 20px;
            font-size: 20px;
            font-family: Pretendard_extraBold;
            line-height: 26px;
            color: #606060;
        }

        .box {
            padding: 14px 16px;
            border-radius: 4px;
            background-color: #f0f0f0;

            .not_price {
                font-size: 18px;
                font-family: Pretendard_extraBold;
                line-height: 24px;
                color: #443018;
            }

            .price_info {
                margin-bottom: 13px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .price_type {
                    font-size: 18px;
                    font-family: Pretendard_extraBold;
                    line-height: 24px;
                    color: #443018;
                }

                .price {
                    font-size: 20px;
                    font-family: Pretendard_extraBold;
                    line-height: 26px;
                    color: #443018;
                }
            }

            .price_content {
                margin-bottom: 4px;
                font-size: 14px;
                font-family: Pretendard_extraBold;
                line-height: 20px;
                color: #606060;
            }

            .expire_date {
                font-family: Pretendard_extraBold;
                font-size: 12px;
                line-height: 18px;
                color: #959595;

                span {
                    margin-left: 8px;
                    font-family: Pretendard_bold;
                }
            }
        }
    }
}
