#ProductClassificationPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    height: 500px;
    padding: 43px 74px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 22px;
        }
    }

    .classification_list {
        display: flex;
        flex-flow: wrap;

        li {
            margin-left: 24px;
            border-bottom: solid 1px #e7e7e7;

            p {
                margin: 2px 0;
                width: 85px;
                line-height: 39px;
                font-size: 14px;
                font-family: Pretendard_bold;
                text-align: center;
                cursor: pointer;
            }

            p:hover {
                background-color: rgba(0, 149, 174, 0.1);
            }
        }

        li:nth-child(6n + 1) {
            margin-left: 0;
        }
    }
}
