@import "src/include";

#docTradeSamplePurchase {
	.pc{
		.membership_dc {
			display: flex;
			justify-content: flex-end;
			margin-right: 55px;

			.desc {
				text-align: left;
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #343434;
			}

			.price {
				text-align: right;
				font-size: 14px;
				color: var(--color-Niagara);
			}
		}

		.sample-purchase {
			width: 950px;
			padding-top: 45px;
			margin: 0 auto;
			&_title {
				font-family: Pretendard_extraBold;
				font-size: 22px;
				color: #343434;
				margin-bottom: 20px;
				padding-left: 14px;
			}
			&_sub-title {
				font-family: Pretendard_bold;
				font-size: 18px;
				color: #343434;
				margin-bottom: 15px;
				padding-left: 14px;
			}
			&_table {
				margin-bottom: 24px;
				&_list-head{
					.style{
						font-family: Pretendard_semiBold;
						font-size: 12px;
						text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
					}
				}
				&_list-content {
					&_content{
						display: flex;
						justify-content: start;
						align-items: center;
						height: 140px;
						.img-wrap{
							width: 165px;
							height: 120px;
							border: 1px solid #d8d8d8;
							.img{
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
						.info-wrap{
							width: calc(100% - 165px);
							min-height: 120px;
							.info{
								font-family: Pretendard_bold;
								color: #343434;
								padding-left: 18px;
								&_title{
									height: 24px;
									line-height: 24px;
									text-align-last: left;
									font-size: 14px;
									letter-spacing: -.25px;
								}
								&_content{
									height: 45px;
									line-height: 1.25;
									text-align-last: left;
									margin-bottom: 3px;
									@include text-ellipsis(3);
								}
								&_format{
									height: 24px;
									line-height: 24px;
									text-align-last: left;
								}
							}
						}
					}

					&_price{
						display: inline-block;

						.price{
							display: block;
							position: relative;
							height: 24px;
							line-height: 24px;
							font-family: Pretendard_bold;
							font-size: 14px;
							color: var(--color-Niagara);
						}

						&-wrap {
							padding-right: 55px;
							text-align: right;
						}
					}
				}
			}
			&_price{
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 24px;
				margin-bottom: 77px;
				&_title{
					width: 50%;
					height: 100%;
					padding-left: 15px;
					.title{
						font-family: Pretendard_extraBold;
						font-size: 18px;
						letter-spacing: .45px;
						color: #343434;
						padding-right: 5px;
					}
					.title-sub{
						font-size: 14px;
						color: #606060;
						padding-right: 40px;
					}
					.refund{
						font-family: Pretendard_bold;
						font-size: 12px;
						color: #343434;
						&_link{
							font-family: Pretendard_extraBold;
							text-decoration: underline;
							color: #343434;
							padding-left: 5px;
						}
					}
				}
				&_info{
					padding-right: 55px;
					width: 220px;
					height: 100%;
					text-align: right;

					.price{
						font-family: Pretendard_extraBold;
						font-size: 18px;
						text-align: right;
						color: var(--color-Niagara);
					}
				}
			}
			&_btn-warp{
				width: 201px;
				height: 45px;
				margin: 0 auto;
				text-align: center;
				font-size: 16px;
				font-family: Pretendard_bold;
				&_prev{
					display: inline-block;
					width: 93px;
					height: 100%;
					margin-right: 15px;
					border: 1px solid #959595;
					border-radius: 4px;
					.btn-prev{
						display: block;
						width: 100%;
						height: 100%;
						line-height: 45px;
						color: #343434;
					}
				}
				&_payment{
					display: inline-block;
					width: 93px;
					height: 100%;

					.btn-payment{
						display: block;
						width: 100%;
						height: 100%;
						font-size: 16px;
						line-height: 45px;
						color: #fff;
						border: 1px solid var(--color-Alizarin-Crimson);
						@include border-radius(4px);
						background-color: var(--color-Alizarin-Crimson);
					}
				}
			}
			&_point{
				border-top: 1px solid #e7e7e7;
				border-bottom: 1px solid #e7e7e7;
				margin-bottom: 24px;
				&_title{
					font-family: Pretendard_bold;
					font-size: 18px;
					color: #343434;
					padding: 15px 0 14px;
				}
				&_table{
					tr{
						border-bottom: none;
					}
					th{
						text-align: left;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #343434;
					}
					td{
						font-size: 12px;
						color: rgba(0, 0, 0, 0.87);
					}
				}
				&_hold{
					.sample-purchase_point_input{
						input[type=text]{
							border: .5px solid transparent;
							pointer-events: none;
						}
					}
					td{
						text-align: left;
					}
				}
				&_input{
					display: inline-block;
					text-align: left;
					input[type=text]{
						padding: 0 10px;
						width: 114px;
						height: 30px;
						border: .5px solid #d8d8d8;
						margin-right: 5px;
						font-family: Pretendard_bold;
						font-size: 12px;
						color: #343434;
						text-align: right;
					}
				}
				&_use{
					td{
						display: flex;
						justify-content: start;
						align-items: center;
						text-align: left;
						&.style{
							text-align: right;
							font-size: 14px;
							color: var(--color-Niagara);
							padding-right: 55px;
						}
					}
					.use-btn{
						width: 64px;
						height: 24px;
						line-height: 24px;
						background-color: #ffa600;
						color: #fff;
						border-radius: 4px;
						font-size: 12px;
						margin: 3px 10px;
					}
					.remove-btn_link{
						margin: 3px 0;
						position: relative;
						width: 24px;
						height: 24px;
						text-indent: -9999px;
						background-color: transparent;

						&::after{
							position: absolute;
							content: '';
							width: 100%;
							height: 100%;
							top: 50%;
							left: 50%;
							transform: translateX(-50%) translateY(-50%);
							background: url(../../../assets/images/common/icon-badge-del.svg) no-repeat 50% 50%;
							background-size: cover;
						}
					}
				}
			}
		}
	}
	.mo{

		.membership_dc {
			display: flex;
			justify-content: flex-end;

			.desc {
				font-family: Pretendard_bold;
				font-size: 14px;
				line-height: 1.43;
				color: #1d334e;
				margin-right: 10px;
			}

			.price {
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #4593f5;
				margin-right: 20px;
			}
		}

		.sample-purchase{
			padding: 60px 0;
			&_title{
				width: calc(100% - 40px);
				margin: 24px auto 13px;
				font-family: Pretendard_extraBold;
				font-size: 14px;
				color: #1d334e;
			}
			&_table{
				margin-bottom: 12px;
				&_head{
					tr{
						color: #747474 !important;
						background: #f0f0f0 !important;
						th{
							color: #747474 !important;
							background: #f0f0f0 !important;
							&:nth-of-type(1){
								text-align: left;
								padding-left: 20px;
							}
						}
					}
				}
				&_body{
					tr{
						border-bottom: 1px solid #d8d8d8;
						td{
							font-family: Pretendard_bold;
							color: #606060;
							margin: 10px 0;
							&:nth-of-type(1){
								height: 50px;
								text-align: left;
								padding-left: 20px;
								line-height: 20px;
								@include text-ellipsis(2);
							}
							&:nth-of-type(3){
								font-family: Pretendard_semiBold;
								color: #4593f5;
							}
						}
					}
				}
			}
			&_price{
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 20px;
				margin-bottom: 23px;
				&_title{
					.title-wrap{
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 5px;
					}
					.title{
						font-family: Pretendard_extraBold;
						font-size: 14px;
						line-height: 1.43;
						color: #1d334e;
						margin-right: 10px;
					}
					.title-sub{
						font-family: Pretendard_bold;
						font-size: 12px;
						color: #747474;
					}
					.refund{
						position: relative;
						font-family: Pretendard_semiBold;
						font-size: 12px;
						color: #959595;
						padding-left: 29px;
						&:after{
							position: absolute;
							content: '';
							width: 24px;
							height: 24px;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							background: url(../../../assets/images/common/icon-badge-information_gray.svg) no-repeat 50% 50%;
							background-size: cover;
						}
						&_link{
							font-family: Pretendard_semiBold;
							font-size: 12px;
							color: #959595;
							text-decoration: underline;
							margin-left: 5px;
						}
					}
				}
				&_info{
					.price{
						font-family: Pretendard_extraBold;
						font-size: 14px;
						color: #4593f5;
					}
				}
			}
			&_point{
				padding: 0 20px;
				margin-bottom: 30px;
				&_title{
					font-family: Pretendard_extraBold;
					font-size: 14px;
					color: #1d334e;
					margin-bottom: 8px;
				}
				&_hold{
					margin-bottom: 5px;
					border-bottom: none;
					th{
						text-align: left;
						label{
							font-family: Pretendard_extraBold;
							font-size: 12px;
							color: #747474;
						}
					}
					.sample-purchase_point_input{
						text-align: right;
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #606060;
						input[type="text"]{
							border: none;
							text-align: right;
							margin-right: 5px;
							font-family: Pretendard_bold;
							font-size: 14px;
							color: #606060;
						}
					}
				}
				&_use{
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;
					width: calc(100vw - 40px);
					margin-bottom: 5px;
					border-bottom: none;
					th{
						display: block;
						text-align: left;
						width: 100%;
						margin-bottom: 5px;
						label{
							font-family: Pretendard_extraBold;
							font-size: 12px;
							color: #747474;
						}
					}
					td{
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
					}
					.sample-purchase_point_input{
						font-family: Pretendard_bold;
						font-size: 14px;
						color: #606060;
						input[type="text"]{
							width: 214px;
							height: 38px;
							border: 2px solid #d8d8d8;
							border-radius: 3px;
							font-family: Pretendard_semiBold;
							font-size: 14px;
							color: #959595;
							padding-left: 7px;
							margin-right: 5px;
							&::placeholder{
								font-family: Pretendard_semiBold;
								font-size: 14px;
								color: #959595;
							}
						}
					}
					.use-btn{
						width: 75px;
						height: 26px;
						background-color: #4593f5;
						color: #fff;
						font-size: 12px;
						border-radius: 4px;
					}
				}
			}
			&_payment{
				width: 100%;
				margin: 60px auto 0;
				text-align: center;
				.btn-payment{
					width: 161px;
					height: 42px;
					background-image: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
					font-size: 16px;
					color: #fff;
					border-radius: 21px;
					margin: 0 auto;
				}
			}
		}
	}
}
