@import "src/include";
#TaskCondition {
    margin: 26px auto 0 auto;
    padding: 7px 0;
    width: 1100px;
    @include border-radius(5px);
    background-color: #ffffff;

    .head {
        margin-bottom: 10px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 22px;
            font-family: Pretendard_bold;
        }

        button {
            width: 141px;
            height: 32px;
        }
    }

    .wrap_data {
      padding: 0 15px;
      display: flex;
      align-items: center;
      column-gap: 15px;
      height: 55px;
      border-bottom: 1px solid #e7e7e7;

      .data {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 527px;
        border: none;
      }

    }

    .data {
      padding: 0 15px;
      display: flex;
      border-bottom: 1px solid #e7e7e7;

      > h2 {
        min-width: 80px;
        width: 80px;
        height: 30px;
        font-size: 14px;
        font-family: Pretendard_bold;
        line-height: 30px;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 6px;

        label {
          display: flex;
          align-items: center;
          min-width: 60px;
          height: 30px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.87);

          input {
            margin-right: 5px;
          }
        }

        > p {
          font-size: 14px;
          font-family: Pretendard_bold;
        }
      }
      .icon_can_duplicates {
        position: absolute;
        left: 15px;
        bottom: 8px;
      }
    }

    .data.annual {
        padding: 12px 15px;
        display: flex;
        align-items: center;

        > div {
            display: flex;
            align-items: center;
            column-gap: 24px;
        }
    }

    .data.sales,
    .data.export {
        width: 538px;
        align-items: center;

        > div {
            align-items: center;
            flex-wrap: nowrap;
            column-gap: 24px;
            width: 100%;

            label {
                min-width: 66.17px;
            }
        }
    }

    .data.region {
        position: relative;

        > div {
            padding-bottom: 8px;

            label:nth-child(14) {
               margin-left: 72px;
            }
        }
    }

    .data.field {
        position: relative;
    }

    .data.c_q {
        padding: 6px 15px;
        position: relative;
        min-height: 74px;
        height: 74px;
        overflow: hidden;

        > div {
            width: 760px;

            label {
                text-align: center;
            }
        }

        button {
            margin: 3px auto auto 20px;
            width: 50px;
            height: 24px;
            font-size: 12px;
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
        }
    }

    .data.department_name {
        padding: 6px 15px;
        position: relative;
        max-height: 300px;
        overflow: hidden;

        > div {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        > div::-webkit-scrollbar {
            width: 12px !important;
        }

        button {
            margin: 3px auto auto 20px;
            width: 50px;
            height: 24px;
            font-size: 12px;
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
        }
    }

    .data.flex_align {
        align-items: center;
    }

    .data.support_target {
        height: 42px;
        border: none;

        > div {
            label {
                text-align: center;
            }
        }
    }

    .data.date {
        align-items: center;

        > div {
            align-items: center;

            > p {
                font-family: Pretendard_semiBold;
            }
        }
    }

    .data.industry {
        padding: 6px 15px;
        position: relative;

        > div {
            label {
                min-width: 240px;
            }
        }
    }

    .data.extend {
        height: auto;
    }

    .wrap_slider {

        > div {
          width: 250px;
        }

        ul {
            display: flex;
            justify-content: space-between;

            li {
                font-size: 12px;
                color: #606060;
            }
        }
    }

    .wrap_date {
        display: flex;
        align-items: center;
        column-gap: 12px;
        font-size: 14px;
    }

    .wrap_date.disable,
    .wrap_slider.disable {
        opacity: 0.4;
        pointer-events: none;
    }

    .btn {
        width: 141px;
        height: 32px;
        font-size: 14px;
        font-family: Pretendard_bold;
        color: #ffffff;
        @include border-radius(4px);
        background-color: var(--color-Web-Orange);
    }
}
