#main {
  width: 100%;
  font-family: "Pretendard_Regular";
  img {
    max-width: 100%;
  }
  .sec {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    padding: 100px 0;
    a {
      display: inline-block;
    }
    strong {
      font-family: "Pretendard_bold";
    }
    .wh {
      color: #fff;
    }
    .sec_tit {
      font-size: 24px;
    }
    .sec_name {
      display: block;
      font-size: 36px;
      padding: 16px 0;
    }
    .sec_desc {
      font-size: 18px;
      color: #6b6b6b;
      margin-bottom: 30px;
    }
    .wrap {
      max-width: 1400px;
      margin: 0 auto;
    }
    .img_m {
      margin-bottom: 50px;
    }
    .start {
      background: url("../../../assets/images/intro/grand/icon_start.png")
        no-repeat center left 30px;
      padding: 16px 40px 16px 60px;
    }
    &.top {
      max-width: none;
      padding-top: 100px;
      background-image: linear-gradient(
        180deg,
        rgba(0, 120, 241, 0.7),
        rgba(245, 245, 245, 0.7)
      );
      text-align: center;
      position: relative;
      z-index: 10;
      * {
        z-index: 10;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 600px;
        background: url("../../../assets/images/intro/grand/bg_txt.png");
        position: absolute;
        left: 0;
        top: 45%;
        transform: translateY(-50%);
        z-index: -1;
      }

      .top_txt {
        font-size: 80px;
        display: block;
        margin-bottom: 30px;
        span {
          color: #a9ffa7;
        }
      }

      .sol {
        font-size: 40px;
        display: block;
      }
      .top_btns {
        margin: 30px 0 80px 0;
        a {
          padding: 16px 40px;
          background-color: #fff;
          border-radius: 40px;
          &.question {
            background-image: url("../../../assets/images/intro/grand/icon_q.png");
            background-position: center left 30px;
            background-repeat: no-repeat;
            padding: 16px 40px 16px 60px;
          }
          &.blue {
            color: #fff;
            background-color: #0174f1;
            margin-right: 20px;
            padding: 16px 40px 16px 60px;
          }
        }
      }
      .task_list {
        margin: 40px auto 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        max-width: 1600px;
        grid-gap: 30px;
        > li {
          background-color: #fff;
          border-radius: 28px;
          padding: 26px;
          cursor: pointer;
          > strong {
            display: block;
            font-size: 24px;
          }
          .desc {
            color: #6b6b6b;
            padding: 10px 0 20px;
          }
          .possible {
            background-color: #f6fbff;
            display: inline-block;
            padding: 16px 20px;
            border-radius: 40px;
            > span {
              background-image: linear-gradient(270deg, #0d7, #0078f1);
              background-clip: text;
              -webkit-text-fill-color: transparent;
              display: inline-block;
              font-family: "Pretendard_bold";
            }
          }
        }
      }
      .giveup {
        font-size: 40px;
        padding-top: 40px;
      }
    }

    &.work {
      background-color: #f5f5f5;
      max-width: none;
      padding-top: 60px;
    }

    &.func {
      max-width: none;
      background-color: #f5f5f5;
      .wrap {
        text-align: center;
        max-width: 1000px;
      }
      .func_list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 30px;
        > li {
          background-color: #fff;
          border-radius: 30px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          img {
            width: 80px;
            margin-bottom: 20px;
          }
          .plus {
            background-image: linear-gradient(270deg, #0d7, #0078f1);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
            font-family: "Pretendard_bold";
            font-size: 28px;
          }
        }
      }
    }

    &.free {
      max-width: 1000px;
      padding: 100px 0 0;
      img {
        width: 300px;
      }
      .free_info {
        position: relative;
        > strong {
          background-image: linear-gradient(270deg, #0d7, #0078f1);
          background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          font-size: 36px;
          margin-bottom: 30px;
        }
        > a {
          background-color: #0078f1;
          color: #fff;
          border-radius: 40px;
        }
        &::after {
          content: "";
          width: 300px;
          height: 300px;
          display: block;
          background: url("../../../assets/images/intro/grand/rocket.png")
            no-repeat center center / contain;
          position: absolute;
          left: 0;
          top: -76px;
        }
      }
    }

    &.mobile {
      padding: 0 0 100px;
    }
  }
}
