#AnnualCountList {
    margin: auto;
    padding-top: 42px;
    width: 1280px;

    .tab {
        margin-bottom: 3px;
        display: flex;
        justify-content: flex-end;
        li+li {
            margin-left: 11px;
        }

        .btn {
            width: 112px;
            height: 30px;
            font-size: 14px;
            font-family: Pretendard_bold;
            color: rgba(0, 0, 0, 0.87);
            border-radius: 5px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
        }
    }

    .title {
        margin-bottom: 50px;
        font-size: 22px;
    }

    .table_title {
        margin-bottom: 17px;
        font-size: 22px;
    }

    .wrap_table+.wrap_table {
        margin-top: 41px;
    }
}
