#PopupPatentSelect {
    margin: auto;
    padding: 46px 57px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 780px;
    height: 480px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 53px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
            font-size: 22px;
        }

        .btns {
            display: flex;
            align-items: center;

            .btn_success {
                margin-right: 21px;
                width: 120px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                background-color: var(--color-Web-Orange);
            }
        }
    }

    .row {
        display: flex;

        h2 {
            margin-right: 5px;
            min-width: 80px;
            line-height: 44px;
            font-size: 14px;
            font-family: Pretendard_bold;
        }

        select {
            padding: 6px 11px 6px 21px;
            width: 220px;
            height: 44px;
            font-size: 14px;
            border: solid 1px #d8d8d8;
            background-color: #ffffff;
        }

        .wrap_table {
            height: 220px;
            overflow-x: hidden;
            overflow-y: scroll;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            table {
                width: 580px;

                tr.active {
                    background-color: rgba(0, 149, 174, 0.1);
                    transition-duration: .5s;
                }
            }
        }
        .wrap_table::-webkit-scrollbar {
            display: none;
        }
    }
    .row+.row {
        margin-top: 29px;
    }

    .checkbox {
        .checkmark {
            margin-right: 0;
        }
    }
}
