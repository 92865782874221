#CostInputList {
    margin: auto;
    padding-top: 40px;
    width: 940px;

   > h2 {
        margin-bottom: 36px;
        font-size: 22px;
        font-family: Pretendard_bold;
   }

    .wrap_search {
        margin: 18px 0 13px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        select {
            padding-left: 10px;
            width: 100px;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
        }
        .input_search {
            padding: 0px 10px;
            min-width: 330px;
            width: 40%;
            height: 32px;
            font-size: 14px;
            border: solid 1px rgba(0, 0, 0, 0.54);
            border-left: 0;
        }
        .icon_search_black {
            margin-left: 15px;
            width: 32px;
            height: 32px;
            background: url("../../../assets/images/common/icon_search_black.svg") no-repeat;
        }
        select::placeholder, input::placeholder {
            color: rgba(0, 0, 0, 0.56);
        }
    }

    .sorting {
        margin-bottom: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;

        .checkbox+.checkbox {
            margin-left: 35px;
        }
    }
}
