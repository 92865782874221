.subs_section_items {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;
  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .name {
      color: #4a4a4a;
      font-size: 20px;
      font-family: Pretendard_bold;
    }
    > p {
      min-width: 120px;
      margin-top: 10px;
      font-size: 20px;
      font-family: Pretendard_bold;
    }
  }
  div {
    > p {
      margin-top: 10px;
      color: #9e9e9e;
      font-size: 15px;
      font-family: Pretendard_regular;
    }
    .wide_text {
      width: 250px;
    }
  }
}
