#InventorStepFour {
  margin: 30px auto;
  width: 1280px;
  .inventor_info_box_title {
    position: relative;
    > h5 {
      font-size: 22px;
      color: #343434;
    }
    > p {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
    .edit_btn {
      width: 85px;
      height: 32px;
      position: absolute;
      top: -50px;
      right: 0;
      border-radius: 4px;
      background-color: #96c818;
    }
  }
  .input_box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 0;
    margin-top: 30px;
    > div {
      display: flex;
      align-items: center;
      p {
        min-width: 100px;
        min-height: 42px;
        padding: 12px 0px;
      }
      input[type="text"] {
        border:none;
        border-bottom: 1px solid #d8d8d8;
      }
    }
    > div:last-child {
      grid-column: 1 / span 3;
      input[type="text"] {
        width: 85%;
      }
    }
  }
  .patent_list {
    margin-top: 30px;
    table {
      margin-top: 20px;
      > thead, tbody {
        height: 40px;
      }
      thead {
        background-color: #000;
        font-size: 14px;
        th {
          color: white;
        }
      }
    }
  }
  button {
    display: block;
    margin: 60px auto 0 auto;
    width: 93px;
    height: 45px;
    border-radius: 4px;
    background-color: #e92f2c;
    font-size: 16px;
    color: #fff;
  }
}
