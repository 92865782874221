@import "src/include";

#MyTrade {
    padding-top: 38px;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;

    .pc {
        margin: auto;
        display: flex;
        width: 1280px;
        column-gap: 15px;

        .menu {
            width: 150px;

            h2 {
                margin-bottom: 16px;
                font-size: 22px;
                font-family: Pretendard_bold;
                line-height: 30px;
            }

            ul {
                li {
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    cursor: pointer;
                }

                li.active {
                    color: var(--color-Web-Orange);
                }

                li+li {
                    margin-top: 15px;
                }
            }
        }

        .wrap {
            > h2 {
                margin-bottom: 25px;
                font-size: 22px;
                font-family: Pretendard_bold;
            }

            ul {
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                width: 300px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
                background-color: #ffffff;

                li {
                    width: 50%;
                    line-height: 30px;
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    text-align: center;
                    cursor: pointer;
                }

                li.active {
                    border-bottom: 2px solid var(--color-Web-Orange);
                }
            }
        }

        .table-box-wrap {
            width: 880px;

            .wrap_list {
                max-height: 450px;
            }
        }

        .icon_more_arrow_right_black {
            margin: auto;
            display: block;
            width: 24px !important;
            height: 24px !important;
            background-size: contain;
        }

        .wrap_keyword_input {
            margin-bottom: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            .keyword_input {
                padding: 0 22px;
                width: 674px;
                height: 45px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: solid 1px #d8d8d8;
                background-color: #ffffff;
            }

            .btn_add {
                width: 120px;
                height: 45px;
                color: #ffffff;
                font-size: 16px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: var(--color-Web-Orange);
            }
        }

        .keyword_list {

            th:first-child,
            td:first-child {
                padding-left: 5%;
                width: auto;
                text-align: left;
            }

            th:last-child,
            td:last-child {
                width: 10%;
            }
        }

        th:first-child,
        td:first-child,
        th:last-child,
        td:last-child {
            width: 10%;
        }
    }

    .mo {
        .header {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 60px;
            background-color: #ffffff;
            z-index: 2;

            h2 {
                font-size: 16px;
                font-family: Pretendard_extraBold;
                color: #443018;
            }

            button {
              position: absolute;
              left: 16px;
              background-size: contain;
            }
        }

        .tab {
            padding: 0 5%;
            position: fixed;
            top: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            z-index: 2;

            li {
                width: 50%;
                height: 38px;
                font-size: 12px;
                line-height: 38px;
                color: #443018;
                text-align: center;
            }

            li.active {
                font-family: Pretendard_extraBold;
                border-bottom: solid 4px var(--color-Web-Orange);
            }
        }

        .box {

            .b_head {
                padding: 0 5%;
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inner {
                    display: flex;
                    align-items: center;

                    h2 {
                        margin-right: 10px;
                        width: 100px;
                        font-size: 18px;
                        font-family: Pretendard_extraBold;
                        color: #443018;
                    }

                    p {
                        font-size: 14px;
                        font-family: Pretendard_extraBold;
                        line-height: 26px;
                        color: var(--color-Web-Orange);
                    }
                }
            }

            .b_body {
                padding: 0 5%;
                height: 0;
                overflow: hidden;

                .alert_not_info {
                    font-size: 14px;
                    font-family: Pretendard_bold;
                    color: #747474;
                }

                li {

                    a {
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 11px;
                            min-width: 95px;
                            width: 95px;
                            min-height: 62px;
                            height: 62px;
                            object-fit: contain;
                        }

                        .inner {
                            width: calc(100% - 95px - 11px - 35px);

                            .category {
                                font-size: 12px;
                                line-height: 18px;
                                color: var(--color-Web-Orange);
                                @include text-ellipsis(1);
                            }

                            .item_title {
                                margin-top: 2px;
                                font-size: 14px;
                                font-family: Pretendard_extraBold;
                                line-height: 20px;
                                color: #443018;
                                @include text-ellipsis(1);
                            }

                            .item_price {
                                margin-top: 2px;
                                font-size: 16px;
                                font-family: Pretendard_extraBold;
                                line-height: 20px;
                                color: #747474;
                            }
                        }

                        .circle {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            min-width: 35px;
                            min-height: 35px;
                            font-size: 12px;
                            font-family: Pretendard_extraBold;
                            color: #ffffff;
                            @include border-radius(50%);
                            background-color: var(--color-Web-Orange);
                        }
                    }
                }

                li+li {
                    margin-top: 15px;
                }
            }
        }

        .box.active {
            .b_body {
                padding: 18px 5%;
                height: auto;
                border-top: 1px solid #d8d8d8;
            }
        }

        .box+.box {
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
        }
    }
}
