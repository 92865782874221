#CompetitorRecent {
  .recent {
    padding: 30px;
    border-radius: 10px;
  }
  .url {
    color: var(--color-Bright-Blue);
    text-align: center;
    padding: 6px 14px;
    border-radius: 32px;
    border: 1px solid var(--color-Bright-Blue);
    display: inline-block;
  }
  .tbl_box {
    max-height: 706px;
    overflow-y: auto;
    table {
      th {
        position: sticky;
        top: 0;
        background-color: #f0f0f0;
      }
    }
  }
}
