#ResearchTechCategoryList {
  //margin: auto;
  //width: 1280px;
  //width:100vw;
  .research_wrapper {
    //width: 1280px;
   width:100%;
    display: flex;
  }
  .research_list {
    //width: 1280px;
    width:100%;
    padding:0 50px 0 50px;
    display: flex;
    flex-direction: column;
    .research_tab {
      //width: 1280px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #f0f0f0;
      p {
        height: 60px;
        width: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px #f0f0f0;
        font-family: Pretendard_bold;
        font-size: 16px;
        color: #343434;
      };
      .active {
        border-bottom: 3px solid #4593f5;
      }
    }
  }
  .relate_keyword_wrapper {
    display: flex;
    margin-top: 15px;
    color: #4593f5;
    font-weight: bold;
    grid-column-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;

    .relate_keyword {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 10px;
      border: 1px solid #4593f5;
      border-radius: 4px;
      height: 30px;

      &:hover {
        color: #ffffff;
        background-color: #4593f5;
      }
    }
  }

  .title {
    font-size: 22px;
    font-family: Pretendard_bold;
    color: #343434;
  }

  .head {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    &.keyword {
      flex-direction: column;

      .help {
        margin-top: 5px;
        .text {
          font-family: Pretendard_semiBold, sans-serif;
          color: #606060;
          font-size: 14px;

          &.red {
            color: #e92f2c
          }

          &.green {
            color: #05ac6d;
          }
        }
      }
    }

    .search {
      margin-top: 20px;
      display: flex;
      align-items: center;
      column-gap: 10px;


      input {
        padding: 0 10px;
        display: flex;
        align-items: center;
        width: 50vw;
        max-width: 400px;
        height: 32px;
        font-size: 14px;
        color: #959595;
        border: 1px solid #d8d8d8;
      }
    }

    .links {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 45px;
      font-size: 14px;
      font-family: Pretendard_extraBold;
      border-radius: 4px;

      &.btn_my {
        color: #4593f5;
        border: 2px solid #4593f5;
      }

      &.btn_write {
        color: #ffffff;
        background-image: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
      }

      .icon_posting_pencil {
        width: 24px;
        height: 24px;
        background-size: contain;
      }
    }
  }

  .wrap_category {
    width: 100vw;
    max-width: 880px;
  }
  .category {
    .category_b_title {
      padding: 0 10px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      width: 100%;
      height: 40px;
      background-color: #f0f0f0;

      p {
        font-size: 14px;
        font-family: Pretendard_bold;
        color: #343434;
      }
    }

    .category_s_list {
      margin: 10px 0 40px 0;
      padding: 0 10px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;

      li {
        padding: 0 10px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 135px;
        height: 40px;
        font-size: 14px;
        font-family: Pretendard_extraBold;
        color: #343434;
        text-align: center;
        border: 1px solid #4593f5;
        border-radius: 4px;
        word-break: keep-all;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          background-color: #4593f5;
        }

        img {
          position: absolute;
          top: -10px;
          left: -15px;
        }
      }
      .not_data {

      }
    }
  }

  @media screen and (max-width: 850px) {
    padding-top: 80px;

    .title {
      margin-bottom: 20px;
      padding-left: 20px;
      font-size: 14px;
      font-family: Pretendard_extraBold;
      color: #1d334e;
    }

    .head, .mo{
      .search{
        margin-left: 20px;
      }
    }

    .category {
      .category_b_title {
        border-bottom: 1px solid #d8d8d8;
      }

      .category_s_list {
        margin: 0;
        padding: 0;
        display: block;

        > li {
          position: relative;
          padding-left: 20px;
          display: block;
          width: 100%;
          line-height: 40px;
          text-align: left;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #d8d8d8;

          &:after {
            content: "";
            position: absolute;
            top: 8px;
            right: 16px;
            width: 24px;
            height: 24px;
            background: url("../../../assets/images/mobile/icon_more_arrow_right_black.svg") center;
          }

          p {
            width: 40px;
            height: 18px;
            font-size: 10px;
            font-family: Pretendard_extraBold;
            line-height: 18px;
            color: #ffffff;
            text-align: center;
            border-radius: 8px;
            background-color: #aaaaaa;
          }

          &.not_data {
            display: flex;
            justify-content: left;
            align-content: center;
            column-gap: 15px;
            color: #aaaaaa;
          }
        }
      }
    }
  }
}
