#Top {
  .step_page .top_bar {
    margin-bottom: 0;
  }

  .top_bar {
    color: #fff;
    padding: 12px 24px;
    margin-bottom: 55px;

    h3 {
      font-size: 22px;
      margin-right: 200px;
    }

    .wrap {
      display: flex;
      align-items: center;

      > p {
        font-size: 18px;
      }

      .start {
        color: #fff;
        border: 1px solid #fff;
        padding: 6px 12px;
        border-radius: 20px;
        margin-left: 14px;
      }
      .alert {
        display: flex;
        justify-content: center;
        align-items: center;
        > p {
          margin-right: 30px;
          > strong {
            color: #00dd77;
            font-size: 22px;
          }
        }
        .to_update {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.5);
          padding: 8px 23px;
          border-radius: 20px;
        }
      }
    }
  }
}
