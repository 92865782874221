.CompetitorStatistic {
  strong {
    font-family: "Pretendard_ExtraBold";
  }
  padding: 40px 0;
  .subject {
    display: flex;
    align-items: center;
    > strong {
      font-size: 28px;
    }
  }
  .sub_tit {
    font-size: 24px;
    font-family: "Pretendard_Regular";
    margin-bottom: 16px;
  }
  .view {
    border-bottom: 1px solid var(--color-Light-Gray);
  }
  .rank {
    .rank_list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 14px;
      > li {
        border-radius: 20px;
        padding: 20px;
        position: relative;
        .tit {
          margin-bottom: 16px;
        }
        .btn_box {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          .more {
            background: transparent;
            display: inline-flex;
            align-items: center;
          }
        }
        .comp_name_list {
          margin-bottom: 40px;
          > li {
            font-family: "Pretendard_Regular";
            > strong {
              min-width: 24px;
              display: inline-block;
            }
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .distribution {
    border-radius: 20px;
  }
  .pie_box {
    border-radius: 20px;
    > ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 14px;
      > li {
        border-radius: 20px;
        > canvas {
          margin: 0 auto;
        }
      }
    }
  }
}
