#PaymentResult {
  background-color: #f7faff;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  > h2 {
    object-fit: contain;
    font-family: Pretendard_extraBold;
    font-size: 32px;
    background-image: linear-gradient(to left, #0d7, #0078f1);
    font-weight: 800;
    line-height: 1.14;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 50px;
  }
  .data_box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    gap: 24px;
    border-radius: 20px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    width: 800px;
    height: 354px;
    > div {
      display: flex;
      p:first-child {
        font-family: Pretendard_bold;
        font-size: 25px;
        min-width: 200px;
      }
      p:last-child {
        font-family: Pretendard_regular;
        font-size: 25px;
      }
    }
  }
  .button_box {
    display: flex;
    gap: 24px;
    width: 800px;
    margin-top: 40px;
    button {
      width: 50%;
      height: 54px;
      color: white;
      border-radius: 12px;
      font-size: 20px;
    }
    button:first-child {
      background-color: #a6a6a6;
    }
    button:last-child {
      background-color: #1474ff;
    }
  }
}
