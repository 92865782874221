@import "../../../include";

#CompanyChoice {
    margin: auto;
    width: 880px;

    .pc {
        padding-top: 13px;
    }

    .title {
        margin-bottom: 18px;
        font-size: 22px;
    }

    .desc {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 1.17;
        letter-spacing: 0.5px;
        color: #00ae02;

        i {
            margin-right: 5px;
        }
    }

    .list {
        padding: 0 2px 10px 2px;
        display: flex;
        flex-wrap: wrap;
        min-height: 140px;
        max-height: 700px;
        overflow-x: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        .item {
            position: relative;
            display: flex;
            align-items: center;
            width: 216px;
            height: 58px;
            border-radius: 5px;
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            cursor: pointer;

            p {
                padding-left: 10px;
                align-items: center;
                width: 185px;
                max-height: 35px;
                font-size: 16px;
                @include text-ellipsis(2);
            }

            i {
                position: absolute;
                top: 16px;
                right: 6px;
            }
        }

        .item:nth-child(n + 5) {
            margin-top: 20px;
        }

        .item {
            margin-left: 4px;
        }
        .item:nth-child(4n + 1) {
            margin-left: 0;
        }

    }

    .list::-webkit-scrollbar {
        display: none;
    }

    .alert {
        margin: 15px;
        display: flex;

        i {
            margin-right: 8px;
        }

        p {
            font-size: 12px;
            font-family: Pretendard_bold;
            line-height: 18px;
            color: #959595;
        }
    }
}
