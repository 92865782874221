@import "src/include";
.pointConversionRow{
    &_retention{
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
        .pointNum{
            display: inline-block;
        }
    }
    &_withdraw{
        input {
            display: inline-block;
            width: 114px;
            height: 30px;
            font-size: 12px;
            border: .5px solid #d8d8d8;
            padding-left: 12px;
            margin-right: 6px;
        }
    }
}