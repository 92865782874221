@import "src/include";

.docTradeMyTransactionWithdrawalList{
	.table_content{
		&_number{
			color: #343434;
		}
		&_account-number{
			color: #343434;
		}
	}
	td{
		height: 50px;
		color: rgba(0, 0, 0, 0.87);
	}
	.style{
		&_blue{
			color: var(--color-Niagara);
		}
		&_red{
			color: var(--color-Alizarin-Crimson);
		}
	}
}