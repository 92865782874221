#GovProjectPop {
  width: 540px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.25);
  // background-image: linear-gradient(131deg, #8884d5 9%, #3363c8 46%, #4ab8aa 81%);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 26px;
  color: #fff;
  // padding: 80px 50px 0;
  z-index: 99;

  p {
    line-height: 1.4;
    margin-bottom: 30px;
    font-family: "Pretendard_regular";
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  strong {
    color: #70fff2;
    font-family: "Pretendard_extraBold";
  }

  .go {
    > img {
      border-radius: 20px;
    }

    // width: 136px;
    // margin: 0 auto;
    // font-size: 26px;
    // color: #3b66c9;
    // background-color: #70fff2;
    // padding: 10px 20px;
    // border-radius: 10px;
    // font-family: "Pretendard_extraBold";
    // box-shadow: 0 0 16px 0 #70fff2;
  }

  .close_box {
    position: absolute;
    left: 24px;
    top: 18px;
    font-size: 16px;
    display: flex;
    align-items: center;

    label {
      display: flex;
      align-items: center;
    }

    input[type="checkbox"] {
      margin-right: 4px;
    }
  }

  .icon_exit_white {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }
}
