#AnnualFeeBilling {
  position: absolute;
  width: 480px;
  min-height: 530px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #343434;
  padding: 46px 35px;
  .annual_fee_billing_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-family: Pretendard_bold;
      font-size: 22px;
    }
    p {
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  .annual_fee_billing_body {
    margin-top: 40px;
    > div {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    p {
      min-width: 100px;
      font-family: Pretendard_extraBold;
      font-size: 14px;
      color: #343434;
    }
    input[type="text"] {
      width: 300px;
    }
    select {
      width: 100px;
      height: 42px;
      padding: 5px;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      outline: none;
    }
  }
  .annual_fee_billing_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    button {
      border-radius: 4px;
      background-color: #4593f5;
      width: 84px;
      height: 40px;
      color: #FFFFFF;
      font-family: Pretendard_bold;
      font-size: 14px;
    }
  }
  .file_input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 226px;
    height: 36px;
    border-radius: 5px;
    background-color: rgba(240, 240, 240, 0.8);
  }
  input[type="file"] {
    display: none;
  }
  label {
    font-family: Pretendard_extraBold;
    font-size: 14px;
    letter-spacing: 0.19px;
    color: #343434;
  }
  .file_list_items {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #d8d8d8;
      padding: 5px;
      p {
        width: 280px;
      }
    }
  }
}
