@import "src/include";

#ChangeFavorite {
  position: fixed;
  background-color: tomato;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  min-height: 800px;
  border-radius: 15px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 10;
  > header {
    padding: 23px 20px 23px 38px;
    display: flex;
    justify-content: space-between;
    > h2 {
      @include text-gradient-green;
      font-size: 24px;
      font-family: Pretendard_extraBold;
    }
  }
  > div {
    h5 {
      padding: 21px 0 0 38px;
    }
  }
  > .my_favorite {
    max-height: 196px;
    min-height: 196px;
    background-color: #e6f2ff;
    padding: 10px;
    overflow-y: auto;
    .favoriteList {
      display: flex;
      margin-top: 5px;
      text-align: center;
      justify-content: flex-start;
      gap: 4px;
      flex-wrap: wrap;
      // width: 879px;
      // height: 150px;
      > div {
        width: 120px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative !important;
        top: auto !important;
        left: auto !important;
        > i {
          width: 36px;
          height: 36px;
          &::before {
            font-size: 36px;
            color: #0078f1;
          }
        }
        > p {
          padding-top: 10px;
        }
      }
      > div:hover {
        border-radius: 30px;
        background-color: rgba(107, 211, 255, 0.2);
      }
      .icon_checkbox_blue {
        position: absolute;
        top: 10px;
        right: 5px;
      }
    }
  }
  .choice_favorite_list {
    max-height: 400px;
    overflow-y: scroll;
    margin-top: 20px;
    > ul {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      text-align: center;
      gap: 13px;
      padding: 0 10px;
      > li {
        position: relative;
        cursor: pointer;
        > i {
          width: auto;
          height: auto;
          &::before {
            font-size: 36px;
            color: #7c7c7c;
            background: #f0f7ff;
            padding: 16px;
            border-radius: 46px;
            display: inline-block;
          }
        }
        > p {
          word-break: keep-all;
          padding-top: 6px;
        }
      }
      .icon_big_blue_add {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
    }
  }
  .save_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    button {
      width: 97px;
      height: 40px;
      border-radius: 4px;
      background-color: #0078f1;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
