@import "src/mixin";

#PDFDocument {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: #ffffff;
  overflow: scroll;

  @include mobile {
    display: flex;
    align-items: center;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }

  .btn_left {
    position: fixed;
    top: calc(50% - 12px);
    left: 2%;
  }

  .btn_right {
    position: fixed;
    top: calc(50% - 12px);
    right: 2%;
  }

  .icon_exit {
    position: fixed;
    top: 2%;
    right: 2%;
  }

  .page_info {
    margin: auto;
    padding: 10px;
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.4);

    @include mobile {
      bottom: 10%;
    }
  }

  .pdf_image {
    width: 100%;
  }
}