@import "src/mixin";
@import "src/include";

#SignupCompany {
  $max-width: 540px;
  @include mobile {
    padding-bottom: 15px;
  }

  .form {
    margin: 30px auto;
    display: flex;
    max-width: 1040px;
    border: 1px solid var(--color-Gallery);

    @include mobile {
      margin: 0;
      padding: 20px 5%;
      display: block;
      border: none;
    }

    .left_content,
    .right_content {
      padding: 25px 35px;
      width: 50%;

      @include mobile {
        padding: 0;
        width: 100%;
      }
    }
    .form_ipnow {
      margin: auto;
      padding: 30px 45px;
      max-width: $max-width;
      border: 1px solid var(--color-Gallery);

      @include mobile {
        padding: 10px 5%;
        margin-top: 20px;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        p {
          font-size: 16px;
          font-family: Pretendard_bold;
          line-height: 24px;
          color: #343434;
        }

        h2 {
          margin-bottom: 25px;
          font-size: 30px;
          font-family: Pretendard_extraBold;
          line-height: 40px;
          color: #443018;

          img {
            width: 50px;
          }
        }
      }
    }
    .left_content {
      border-right: 1px solid var(--color-Gallery);

      @include mobile {
        border: none;
      }
    }

    .wrap_input {
      label {
        margin-bottom: 10px;
        position: relative;
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        font-family: Pretendard_bold;
        color: #343434;;

        @include mobile {
          font-size: 12px;
        }

        &.require {
          &::before {
            content: '';
            position: absolute;
            left: 100%;
            width: 12px;
            height: 16px;
            background: url("../../../assets/images/common/icon_red_dot.svg") no-repeat;
          }
        }
      }

      .error {
        font-size: 12px;
        font-family: Pretendard_bold;
        line-height: 25px;
        color: var(--color-Red-opacity-08);
      }

      .input_area {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.search {
          button {
            right: 10px;
          }
        }
      }

      input {
        padding-right: 80px;
        width: 100%;
        height: 40px;
        font-size: 14px;
        border: none;
        border-bottom: 1px solid var(--color-Gallery);

        @include mobile {
          padding: 0 80px 0 10px;
          border: 1px solid var(--color-Alto);
          border-radius: 4px;
        }
      }

      input::placeholder {
        color: var(--color-Dusty-Gray);
      }

      @include mobile {
        .has_btn {
          width: calc(100% - 80px);
        }
      }

      button:not([class^="icon_"]) {
        width: 69px;
        height: 32px;
        font-size: 12px;
        color: #ffffff;
        border-radius: 4px;
        background-color: var(--color-Cornflower-Blue);
      }

      button {
        position: absolute;
        right: 0;

        &.confirm {
          background-color: var(--color-Jade);
        }
      }

      .notify {
        font-size: 12px;
        font-family: Pretendard_bold;
        line-height: 25px;
        color: var(--color-Cornflower-Blue);
        text-align: right;
      }
    }

    .wrap_input+.wrap_input {
      margin-top: 25px;

      @include mobile {
        margin-top: 20px;
      }
    }

    .notify {
      font-size: 12px;
      font-family: Pretendard_bold;
      line-height: 25px;
      color: var(--color-Cornflower-Blue);
      text-align: right;
    }
  }

  .btn_check.btn_no {
    background-color: var(--color-Jade) !important;
    pointer-events: none;
  }

  .wrap_agree {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

    @include mobile {
      margin-top: 0;
    }

    label {
      font-size: 14px;
      font-family: Pretendard_bold;

      span {
        color: var(--color-Cornflower-Blue);
      }
    }
  }

  .btn_join {
    margin: 25px auto 0 auto;
    display: block;
    width: 90vw;
    max-width: 450px;
    height: 50px;
    font-size: 16px;
    font-family: Pretendard_bold;
    color: #ffffff;
    border-radius: 4px;
    background-color: var(--color-Piction-Blue);
    &.btn_disabled {
      background-color: var(--color-Alto);
      pointer-events: none;
    }
  }

  > .mo {
    padding-top: 95px;
    min-height: 1000px;

    .wrap_step {
      padding-bottom: 50px;

      .step {
        margin: auto;
        width: 90%;

        .step_title {
          margin-bottom: 28px;
          font-size: 24px;
          font-family: Pretendard_extraBold;
          color: #443018;
          text-align: center;
        }

        .btn_kakao {
          margin-top: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 13px;
          min-width: 320px;
          width: 100%;
          height: 42px;
          font-size: 16px;
          font-family: Pretendard_extraBold;
          line-height: 22px;
          color: #443018;
          @include border-radius(4px);
          background-color: #fae255;
        }

        .field {
          position: relative;

          .required {
            position: absolute;
            top: 3px;
            right: 0;
            font-size: 12px;
            color: #606060;
          }

          .f_head,
          .f_title {
            margin-bottom: 8px;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            line-height: 18px;
            color: #443018;
          }

          .wrap_input {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            border-radius: 4px;
            background-color: #ffffff;

            &.search {
              padding-right: 12px;
              border: 2px solid var(--color-Alto);

              input {
                border: none;
              }
            }

            > p {
              padding: 0 20px;
              width: 100%;
              height: 40px;
              font-size: 14px;
              line-height: 40px;
              color: #606060;
              border-radius: 4px;
              border: 2px solid #d8d8d8;
              background-color: #f0f0f0;
            }

            > p.birth {
              padding-left: 45px;
              background-color: #ffffff;

              .react-datepicker-wrapper {
                position: absolute;
                top: 7px;
                left: 7px;
              }
            }

            .keyword_list {
              position: absolute;
              top: 100%;
              padding: 0 20px;
              width: 100%;
              max-height: 218px;
              overflow-x: hidden;
              overflow-y: scroll;
              @include border-radius(4px);
              border: 2px solid #d8d8d8;
              border-top: none;
              background-color: #ffffff;
              z-index: 10;

              li {
                padding: 10px 0;
                width: 100%;
                min-height: 40px;
                font-size: 14px;
                color: #747474;

                p {
                  margin-top: 4px;
                  span {
                    font-family: Pretendard_bold;
                  }
                }
              }

              li+li {
                border-top: 1px solid #d8d8d8;
              }
            }
          }

          .wrap_input.search {
            input {
              width: calc(100% - 40px);
              z-index: 1;
            }
          }

          .wrap_view {
            margin-bottom: 29px;

            ul {
              max-height: 146px;
              overflow-x: hidden;
              overflow-y: scroll;
              border-radius: 4px;
              background-color: #ffffff;

              li {
                padding: 8px 16px;
                display: flex;
                align-items: center;
                min-height: 40px;

                .empty {
                  font-size: 12px;
                  font-family: Pretendard_bold;
                  line-height: 18px;
                  color: #464646;
                }

                .info {
                  width: 90%;

                  h2 {
                    font-size: 12px;
                    font-family: Pretendard_bold;
                    line-height: 18px;
                    color: #464646;
                  }

                  p {
                    font-size: 12px;
                    line-height: 18px;
                    color: #959595;
                  }
                }

                .state {
                  font-size: 12px;
                  font-family: Pretendard_bold;
                  line-height: 18px;
                  color: #757575;
                }
              }

              li+li {
                border-top: 1px solid #d8d8d8;
              }
            }

            button {
              margin: 10px auto 0 auto;
              display: block;
              font-size: 12px;
              font-family: Pretendard_extraBold;
              line-height: 18px;
              color: #747474;
              background-color: transparent;
              text-decoration: underline;
            }
          }

          .wrap_input_list {

            .wrap_add {
              padding: 0 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 40px;
              border-radius: 4px;
              background-color: #ffffff;

              .icon_plus {
                background: url("../../../assets/images/mobile/icon_plus.svg") no-repeat;
              }

              .btn_add_txt {
                width: 56px;
                font-size: 14px;
                font-family: Pretendard_extraBold;
                line-height: 20px;
                color: var(--color-Web-Orange);
                white-space: pre;
                background-color: transparent;
              }

              .add_product {
                margin: 0 12px;
                width: calc(100% - 56px);
                height: 32px;
                font-size: 14px;
                font-family: Pretendard_bold;
                line-height: 20px;
                color: #606060;
                border: none;
              }
            }

            ul {
              li {
                position: relative;
                padding: 0 16px;
                display: flex;
                align-items: center;
                height: 40px;
                background-color: #ffffff;

                input {
                  padding-left: 56px;
                  padding-right: 40px;
                  width: 100%;
                  height: 38px;
                  font-size: 14px;
                  color: #959595;
                  border: none;
                  border-bottom: 1px solid #c9c9c9;
                }

                .icon_exit {
                  position: absolute;
                  right: 16px;
                  width: 20px;
                  height: 20px;
                  background-size: contain;
                }
              }
            }
          }

          .wrap_input_validator {
            display: flex;

            input {
              width: calc(75% - 8px);
              height: 40px;
            }

            button {
              margin-left: 8px;
              width: 25%;
              height: 40px;
              font-size: 14px;
              font-family: Pretendard_extraBold;
              color: #ffffff;
              border-radius: 4px;
              background-color: var(--color-Web-Orange);
            }
          }

          .wrap_select {

            select {
              padding: 0 20px;
              width: 100%;
              height: 40px;
              font-size: 14px;
              font-family: Pretendard_bold;
              color: #606060;
              border-radius: 4px;
              background-color: #ffffff;
            }
          }

          .alert_msg {
            margin-top: 8px;
            font-size: 12px;
            font-family: Pretendard_bold;
            line-height: 18px;
            color: var(--color-Web-Orange);
          }

          .alert {
            margin-top: 8px;
            display: flex;
            align-items: center;

            i {
              margin-right: 6px;
            }

            p {
              font-size: 12px;
              font-family: Pretendard_bold;
              line-height: 18px;
              color: #959595;
            }
          }
        }

        .field+.field {
          margin-top: 20px;
        }

        .alert {
          margin: 8px 0 12px 0;
          display: flex;
          align-items: center;

          i {
            margin-right: 6px;
          }

          p {
            font-size: 12px;
            font-family: Pretendard_bold;
            line-height: 18px;
            color: #959595;
          }
        }

        .btn {
          margin-top: 24px;
        }
      }
    }

    input {
      padding: 0 20px;
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: #606060;
      border-radius: 4px;
      border: 2px solid #d8d8d8;
      background-color: #ffffff;
    }

    input::placeholder {
      color: #959595;
    }

    .notify {
      font-size: 12px;
      font-family: Pretendard_bold;
      line-height: 25px;
      color: var(--color-Cornflower-Blue);
      text-align: right;
    }
  }
}
