#TaskDownload {

  margin-left: 25px;

  div {
    margin-top: 25px;
  }

  button {
    width: 150px;
    height: 40px;
  }
}