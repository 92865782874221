#CyberPointHistory {
    @import "Common.scss";
    height: 100vh;

    .wrap_date {
        margin: 15px auto;
        padding: 0 15px;
        min-width: 333px;

        h2 {
            font-size: 14px;
            font-family: Pretendard_bold;
        }

        ul {
            margin: 10px 0;
            display: flex;
            align-items: center;
            width: 159px;
            @include border-radius(3px);
            border: 1px solid #e1e1e1;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 53px;
                height: 31px;
                font-size: 12px;
                cursor: pointer;
            }

            li+li {
                border-left: 1px solid #e1e1e1;
            }
        }

        .flex {
            align-items: center;
            column-gap: 8px;
        }

        .date {
            display: flex;
            align-items: center;
            column-gap: 4px;
            width: 50vw;
            max-width: 140px;
            border: 1px solid #e1e1e1;

            p {
                width: 70%;
                font-size: 14px;
                text-align: center;
            }

            .icon_calendar {
                margin-top: 3px;
            }
        }

        .btn_select {
            width: 45px;
            height: 32px;
            font-size: 13px;
            border: 1px solid #e1e1e1;
            background: #f6f6f6;
        }
    }

    .table-box-wrap {
        margin-top: 14px;

        th:first-child,
        td:first-child {
            width: 50%;
        }
    }

    .blue {
        color: #0049ff;
    }

    .green {
        color: #00a545;
    }

    .red {
        color: #ff0000;
    }
}
