@import "src/include";

#DeadLineSetting {
    margin: auto;
    padding-top: 39px;
    width: 880px;

    .area_box {
        margin: 0 auto 40px auto;
        padding: 19px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 40px;
        max-width: 880px;
        height: 140px;
        border-radius: 20px;
        background-color: rgb(241, 241, 241, 0.3);
    }

    .header {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;

        .title {
            font-size: 22px;
            font-family: Pretendard_bold;
            color: #343434;
        }

        .btn_setting_complete {
            width: 132px;
            line-height: 32px;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            background-color: #05ac9e;
        }
    }

    .wrap_setting {
        display: flex;
        max-width: 780px;
        height: 400px;
        overflow-x: scroll;

        .title {
            font-size: 14px;
            font-family: Pretendard_bold;
        }

        .row {
            div {
                width: 140px;
                line-height: 45px;
                height: 45px;
                text-align: center;
            }
            div+div {
                border-bottom: solid 1px #e7e7e7;
            }

            .title {

                .icon_badge_del {
                    position: relative;
                    top: 7px;
                    left: 10px;
                }
            }
        }

        .row:first-child {

            div {
                text-align: left;
            }
        }

        select {
            width: 100px;
            height: calc(100% - 2px);
            text-indent: 15px;
        }

        .btn_add_update {
            width: 80px;
            line-height: 32px;
            background-color: #ffffff;
            @include border-radius(16px);
            border: solid 1px #d8d8d8;
        }
    }
}
