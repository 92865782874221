#CompetitorMy {
  h3.title {
    margin-bottom: 18px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: -0.25px;
    padding: 0 0 0 44px;
    position: relative;
    &::after {
      content: "";
      width: 32px;
      height: 32px;
      display: block;
      background-color: var(--color-Bright-Blue);
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: -3px;
    }
  }
  .sub_desc {
    color: #555;
  }

  #MyClt {
    .ctl_list {
      margin-top: 20px;
      > ul {
        > li {
          .group {
            background-color: #fff;
            border-radius: 10px;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .open_util {
              display: flex;
              align-items: center;
              .del_btn {
                color: var(--color-Alizarin-Crimson);
                border: 1px solid var(--color-Alizarin-Crimson);
                background-color: transparent;
                padding: 8px 12px;
                border-radius: 20px;
                margin-right: 10px;
              }
            }
          }
          & + li {
            margin-top: 20px;
          }
          .show_list {
            background: transparent;
            &.rotate {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    .children_tbl {
      padding-top: 10px;
      max-height: 228px;
      overflow-y: auto;
      table {
        th {
          text-align: center;
          background: #666;
          color: #fff;
        }
        td {
          background-color: #fff;
        }
      }
    }
    .btn_box {
      text-align: right;
      > button {
        color: #fff;
        background-color: var(--color-Bright-Blue);
        padding: 10px 18px;
        border-radius: 20px;
        margin-left: 10px;
        &.del {
          background-color: var(--color-Alizarin-Crimson);
          color: #fff;
        }
      }
    }
    .url {
      color: var(--color-Bright-Blue);
      text-align: center;
      padding: 6px 14px;
      border-radius: 32px;
      border: 1px solid var(--color-Bright-Blue);
      display: inline-block;
    }
  }
}
