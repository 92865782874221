#IpnowIntroTechPopUp {
  margin: inherit;
  padding: inherit;
  position: fixed;
  width: 50vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  z-index: 9999;
  left: 50%;
  top: 25%;
  transform: translateX( -50% );
  i {
    position: absolute;
    top: 33%;
    right: 17%;
  }
}
