#FamilyPopup {
    padding: 36px 29px 73px 36px;
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 780px;
    width: 80%;
    max-height: 678px;
    transform: translate(-50%, -50%);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .popup_header {
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: Pretendard_extraBold;
        }

        .btns {
            display: flex;
            align-items: center;
            column-gap: 40px;

            .btn_add {
                width: 120px;
                height: 32px;
                font-size: 16px;
                color: #ffffff;
                text-align: center;
                background-color: var(--color-Web-Orange);
            }
        }
    }

    .popup_content {
        padding: 0 37px;

        label {
            clear: both;
            margin-bottom: 35px;
            display: flex;
            line-height: 44px;
            font-size: 14px;
            font-family: Pretendard_bold;

            span {
                margin-right: 5px;
                display: inline-block;
                width: 80px;
            }
            select {
                padding-left: 21px;
                width: 220px;
                height: 44px;
                font-size: 14px;
                border: 1px solid var(--color-Web-Orange);
            }
        }

        #scrollable {
            > div {
                background-color: #ffffff;
            }
        }
    }

    .wrap_list {
        clear: both;
    }
}
