.researchTechRequestList {
    //width: 1280px;
    width:100%;
    padding:0 50px;
    display: flex;
    margin: 0 auto;
    .researchTechRequestList_content{
        margin-top: 18px;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .download_cnt {
                font-family: Pretendard_semiBold;
                font-size: 16px;
                color: #343434;
                span {
                    font-family: Pretendard_extraBold;
                    font-size: 20px;
                    color: #54b7e0;
                }
            }
        }
    }

    &_content {
        h2 {
            margin-bottom: 20px;
            font-size: 22px;
            font-family: Pretendard_bold;
            color: #343434;
            span {
                font-size: 14px;
                font-family: Pretendard_bold;
            }
        }
    }

    table {
        th:nth-child(2),
        td:nth-child(2),
        th:nth-child(3),
        td:nth-child(3) {
            text-align: left;
        }
        td {
            font-family: Pretendard_semiBold;
            font-size: 13px;
            color: #343434;
        }
        td:nth-child(3) {
            font-family: Pretendard_bold;
        }
        .btn_load button{
            width: 47px;
            height: 24px;
            border-radius: 8px;
            background-color: #05ac9e;
            font-family: Pretendard_bold;
            font-size: 12px;
            color: #fff;
        }
    }
}
