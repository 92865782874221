#InventorDone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > h2 {
    font-size: 30px;
    margin: 180px 0 30px 0;
  }
  > h5 {
    margin-bottom: 60px;
  }
  > button {
    width: 155px;
    height: 45px;
    border-radius: 4px;
    background-color: #e92f2c;
    color: #fff;
    font-size: 16px;
  }
}
