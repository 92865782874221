@import "src/include";

#lawfirmMain{
    .pc{
        .lawfirmMain{
            width: 950px;
            margin: 0 auto;
            padding: 40px 0;
            &_box-wrap{
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 880px;
                height: 110px;
                margin: 0 auto 45px;
                padding: 10px 15px;
                background-color: #f1f1f1;
                border-radius: 20px;
            }
            &_box{
                width: calc(100% - 123px);
                height: 100%;
                position: relative;
                padding: 20px 0 20px 160px;
                &::after{
                    position: absolute;
                    content: '';
                    width: 72px;
                    height: 65px;
                    top: 50%;
                    left: 46px;
                    transform: translateY(-50%);
                    background: url(../../../assets/images/lawfirm/ico_lawfirm_box.png)no-repeat 50% 50%;
                    background-size: cover;
                }
                &_title{
                    font-family: Pretendard_extraBold;
                    font-size: 18px;
                    color: #343434;
                    margin-bottom: 10px;
                }
                &_sub{
                    font-family: Pretendard_bold;
                    font-size: 14px;
                    color: #606060;
                }
                &_btn{
                    width: 123px;
                    height: 24px;
                    text-indent: -9999px;
                    background: url(../../../assets/images/common/icon_tutorial_yellow.png) no-repeat 50% 50%;
                    background-size: cover;
                }
            }
            &_content{
                &_title{
                    font-family: Pretendard_bold;
                    font-size: 22px;
                    color: #343434;
                    margin-bottom: 35px;
                }
                &_list_wrap {
                    display: flex;
                    flex-direction: column;
                    row-gap: 15px;
                }
                &_list{
                    position: relative;
                    display: flex;
                    height: 185px;
                    justify-content: flex-start;
                    align-items: center;
                    box-shadow: 1px 2px 5px 0 rgba(52, 52, 52, 0.3);
                    background-color: #fff;
                    &_img{
                        width: 255px;
                        height: 100%;
                        background-color: #e8e8e8;
                        a{
                            display: block;
                            width : 100%;
                            height: 100%;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    &_text-wrap{
                        display: flex;
                        position: relative;
                        width: calc(100% - 255px);
                        height: 100%;
                        padding: 16px 23px 16px 14px;
                    }
                    &_text-area{
                        width: calc(100% - 120px);
                        &_title{
                            font-family: Pretendard_extraBold;
                            font-size: 16px;
                            color: #343434;
                            margin-bottom: 20px;
                        }
                        .table_list{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;
                            &_title{
                                width: 60px;
                                font-family: Pretendard_extraBold;
                                font-size: 14px;
                                color: var(--color-Web-Orange);
                                margin-bottom: 14px;
                            }
                            &_info{
                                width: calc(100% - 60px);
                                font-family: Pretendard_semiBold;
                                font-size: 14px;
                                color: #343434;
                                margin-bottom: 14px;
                            }
                        }
                    }
                    &_file-area{
                        width: 120px;
                        &_title{
                            font-family: Pretendard_extraBold;
                            font-size: 14px;
                            color: var(--color-Web-Orange);
                            margin-right: 20px;
                        }
                        &_btn{
                            width: 24px;
                            height: 24px;
                            background: url(../../../assets/images/common/icon-download.svg) no-repeat 50% 50%;
                            background-size: cover;
                            text-indent: -9999px;
                        }
                    }
                    .btnMore{
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 120px;
                        height: 45px;
                        right: 23px;
                        bottom: 16px;
                        color: #fff;
                        background-color: var(--color-Web-Orange);
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
