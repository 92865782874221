#CompetitorStrategyPop {
  width: 280px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 999;
  background-color: #fff;
  padding: 30px;
  > div {
    padding: 6px 0;
    display: flex;
    align-items: center;
    & + div {
      border-top: 1px solid #eee;
    }
    > input {
      margin-right: 4px;
    }
  }
  .btn {
    justify-content: space-between;
    margin-bottom: 10px;
    > strong {
      display: block;
      color: #0078f1;
      font-size: 18px;
    }
  }
  .set {
    display: block;
    background-color: #0078f1;
    color: #fff;
    margin-top: 10px;
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
}
