#GroupwareCashflow {
  a {
    display: inline-block;
  }

  button {
    &.add {
      color: #fff;
      background-color: #00dd77;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      font-size: 18px;
      line-height: 30px;
    }
    &.del {
      color: #fff;
      background-color: #ff4949;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      font-size: 18px;
      line-height: 30px;
      &.act_child {
        margin-left: 8px;
      }
    }

    &.all {
      background-color: #0078f1;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      font-size: 18px;
      line-height: 30px;
      margin-right: 4px;
    }

    .icon_in_all {
      width: 20px;
      background-size: contain;
      margin-top: 2px;
    }
  }

  .content_box {
    display: flex;
    .report_box {
      width: calc(100% - 360px);
    }
  }

  .btn_list {
    margin-left: 10px;

    &.act {
      width: 100%;
      text-align: right;
      padding: 10px 0;
      border-bottom: 1px solid #ebecf1;
      .box {
        max-width: 1466px;
        // max-width: 1489px;
      }
    }

    &.double {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }

  .wrap {
    width: 100%;
    margin: 0 auto;
  }

  .green {
    color: #00dd77;
  }

  .blue {
    color: #0078f1;
  }

  .red {
    color: #ff4949;
  }

  .yellow {
    color: #ffa600;
  }

  .stable {
    color: #0078f1;
  }

  .danger {
    color: #ff4343;
  }

  .warn {
    color: #ffad17;
  }

  .good {
    color: #3cca97;
  }

  .green_bg {
    background-color: #00dd77;
  }

  .blue_bg {
    background-color: #0078f1;
  }

  .bg_gray {
    background-color: rgb(216 216 216 / 28%);
  }
  .week_red_bg {
    background-color: #ffe7e8;
  }
  .week_green_bg {
    background-color: #f1ffe2;
  }

  .gradient_txt {
    background-image: linear-gradient(274deg, #0d7, #0078f1);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .rounded {
    border-radius: 6px;
  }

  .none {
    display: none;
  }

  .align_right {
    text-align: right;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  input {
    border: 1px solid #ebecf1;
    border-radius: 5px;

    &.item_name {
      width: 180px;
    }
  }

  .title_box {
    display: flex;
    align-items: center;
    > span {
      margin-right: 10px;
    }
    > input {
      width: calc(100% - 37px);
    }
  }
  .add_box {
    text-align: right;

    &.mr-6 {
      margin-bottom: 6px;
    }
  }

  .step_cont {
    .wrap {
      padding: 24px;
    }

    .tit_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h4 {
        font-size: 22px;
      }
    }

    .desc {
      margin-bottom: 6px;
      display: block;

      > span {
        font-family: "Pretendard_regular";
        font-size: 16px;
        margin-left: 10px;
        color: #ff4949;
      }
    }

    .temp_save {
      color: #fff;
      padding: 8px 20px;
      border-radius: 20px;

      > span {
        font-weight: normal;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .get_balance {
      background-color: #f1eaff;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div > strong {
        color: #7235df;
        margin-right: 10px;
      }

      .get_btn {
        color: #fff;
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #7235df;
      }
    }

    .total {
      font-size: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;

      .detail_btn {
        font-size: 16px;
        color: #fff;
        padding: 4px 12px;
        margin-left: 8px;
      }
    }

    .add_money {
      font-size: 14px;

      table {
        width: 100%;
        border-top: 1px solid #ebecf1;

        th {
          text-align: left;
          border-bottom: 1px solid #ebecf1;
          padding: 6px 0;

          span {
            font-size: 12px;
            font-weight: normal;
            max-width: 70px;
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
          }
        }

        td {
          border-bottom: 1px solid #ebecf1;
          padding: 6px 6px;

          select {
            min-width: auto;
            height: 42px;
            padding: 0 22px 0 12px;
            border: 1px solid #ebecf1;
            border-radius: 5px;
          }

          .etc_txt {
            min-width: 86px;
            display: inline-block;
          }

          .etc_in {
            width: calc(100% - 196px);
            margin-left: 16px;
          }
        }
      }

      .bank {
        border: 1px solid #ebecf1;
        border-radius: 5px;

        input,
        select {
          border: none;
        }
      }
    }
  }

  &.report {
    .info > a,
    .report_top > a {
      color: #fff;
      padding: 8px 20px;
      border-radius: 20px;
    }
  }

  .date {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      padding: 4px;
      margin: 0 4px;
    }
    button {
      padding: 6px 12px;
      border-radius: 10px;
      color: #fff;
      background-color: #0078f1;
    }
  }

  .history > strong {
    display: block;
    margin-bottom: 10px;
  }

  .history_tbl {
    width: 100%;
    border-spacing: 0;

    th {
      background-color: #ebecf1;
      padding: 10px;
    }

    td {
      text-align: center;
      border-bottom: 1px solid #ebecf1;
      padding: 10px;

      .report_view {
        background-color: #0078f1;
        color: #fff;
        padding: 6px 16px;
        border-radius: 20px;
        font-size: 14px;
      }
    }
  }

  .insite_list > {
    strong {
      display: block;
      margin-bottom: 10px;
      text-align: left;
    }

    ul > li {
      max-width: 300px;
      text-align: left;
      background-color: #fff;
      padding: 10px;
      border-radius: 6px;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
  .part_box {
    .label {
      width: 180px;
      font-weight: 600;
    }
    .top {
      display: flex;
      align-items: center;
      > ul {
        width: 100%;
        display: flex;
        align-items: center;
        > li {
          width: 95px;
          text-align: center;
          margin-right: 10px;
          padding: 10px 0;
          font-weight: 600;
        }
      }
    }
  }
  .item_box {
    .parent {
      display: flex;
      align-items: center;
      padding: 4px 0;
      border-bottom: 1px solid #ebecf1;
      > ul {
        display: flex;
        align-items: center;
        > li {
          margin-right: 24px;
          > input {
            width: 80px;
            padding: 0 10px;
            font-size: 12px;
          }
        }
      }
      .label {
        background: url(../../../../../assets/images/cashflow/arrow_drop.png)
          no-repeat center left;
        padding-left: 30px;
        cursor: pointer;
      }
    }
  }

  .child_box {
    .child {
      padding: 4px 0;
      border-bottom: 1px solid #ebecf1;
      display: flex;
      align-items: center;

      > ul {
        display: flex;
        align-items: center;
        > li {
          margin-right: 24px;
          > input {
            width: 80px;
            padding: 0 10px;
            font-size: 12px;
          }
        }
      }

      .label {
        background: url("../../../../../assets/images/cashflow/arrow_drop_2.png")
          no-repeat center left 10px;
        padding-left: 36px;
        > input {
          width: 140px;
          font-weight: 600;
        }
      }
    }
  }

  .step_btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    &.only_next {
      justify-content: flex-end;
    }

    > button {
      border: 1px solid #606060;
      padding: 10px 29px 10px 20px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.next {
        color: #fff;
        border: none;
        padding: 10px 20px 10px 29px;

        &.end {
          height: 46px;
          padding: 10px 20px;
        }
      }
    }
  }

  .cashflow_list {
    padding: 30px;
    table {
      th {
        padding: 10px;
      }
      td {
        padding: 10px;
        border-bottom: 1px solid #f1eaff;
      }
      .left {
        text-align: left;
      }
      .btn {
        color: #fff;
        background-color: #0078f1;
        padding: 8px 16px;
        border-radius: 10px;

        &.del_btn {
          background-color: #ff4949;
        }

        &.view_btn {
          background-color: #0d7;
        }
      }
    }

    &.history {
      max-height: 320px;
      overflow: auto;
      padding: 0 0 30px;
      table {
        thead {
          position: sticky;
          top: 0;
        }
      }
    }
  }

  .steps {
    padding: 12px 0;

    .wrap {
      > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > li {
          width: 19%;
          padding: 14px;
          &.business {
            width: 33%;
          }

          .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #606060;
            font-size: 12px;
            margin-bottom: 12px;
          }

          .bar {
            height: 8px;
            background-color: #959595;
          }

          &.on {
            .name {
              color: #0078f1;
            }

            .bar {
              background-color: #0078f1;
            }
          }
        }
      }
    }
  }

  .cashflow_report {
    .calc_box {
      padding: 20px;
      > ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > li {
          width: 20%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          position: relative;

          .cash_name {
            font-size: 18px;
            margin-bottom: 10px;
          }
          .cash {
            text-align: right;
            strong {
              font-size: 26px;
            }
          }
          &.plus {
            &::after {
              content: "+";
              width: 42px;
              height: 42px;
              display: block;
              color: #fff;
              font-size: 32px;
              background-color: #00dd77;
              position: absolute;
              right: -160px;
              bottom: 0;
              line-height: 1.2;
              text-align: center;
              border-radius: 44px;
            }
          }

          &.minus {
            &::after {
              content: "-";
              width: 42px;
              height: 42px;
              display: block;
              color: #fff;
              font-size: 32px;
              background-color: #ff4949;
              position: absolute;
              right: -160px;
              bottom: 0;
              line-height: 1.2;
              text-align: center;
              border-radius: 44px;
            }
          }
        }
      }

      .info_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .status_code {
          font-size: 20px;
          font-family: "Pretendard_bold";
        }
      }
    }

    .data_box {
      padding: 20px;
      .data_tit {
        font-size: 18px;
        color: #0078f1;
        margin-bottom: 20px;
        display: block;
      }

      .mon_tbl_box {
        display: flex;
        margin-bottom: 30px;

        .item {
          .month {
            justify-content: center;
            background-color: #ebecf1;
            padding: 10px;
            border-left: 1px solid #fff;
          }

          > div {
            min-height: 56px;
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #ebecf1;
            border-right: 1px solid #ebecf1;
            font-size: 14px;
            word-break: keep-all;
          }

          &.head {
            > div {
              min-width: 64px;
              min-height: 56px;
              display: flex;
              align-items: center;
              background-color: #ebecf1;
              padding: 10px 20px 10px 10px;
              border-bottom: 1px solid #fff;
            }
          }

          &.mon_box {
            width: calc((100% - 64px) / 12);
          }
        }
      }

      .graph_box {
        .unit {
          text-align: right;
          font-size: 14px;
          padding: 10px 0;
        }
      }
    }
  }
  .rAlign {
    justify-content: flex-end;
    text-align: right;
  }

  .financing {
    padding-left: 24px;
    > strong {
      display: block;
      font-size: 20px;
      padding-bottom: 10px;
    }
    .link_list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      > li {
        border-radius: 30px;
        max-width: 320px;
        &:nth-child(1) {
          background-color: #dce5fb;
        }
        &:nth-child(2) {
          background-color: #e8e2fd;
        }
        &:nth-child(3) {
          background-color: #ccf2f3;
        }
        &:nth-child(4) {
          background-color: #ffeae3;
        }
        &:nth-child(5) {
          background-color: #e0fee1;
        }
        > a {
          padding: 30px;
          display: block;
          > div {
            > strong {
              font-size: 20px;
            }
            > p {
              word-break: keep-all;
              padding: 10px 0;
              line-height: 1.6;
              font-family: "Pretendard_light";
            }
          }
          .go {
            text-align: right;
          }
        }
      }
    }
    .text {
      > p {
        line-height: 1.4;
        font-size: 20px;
      }
      > strong {
        font-size: 32px;
        display: block;
        margin: 30px 0;
      }
    }
  }

  .timing {
    background-color: #fff;
    border-radius: 10px;
    margin-left: 24px;
    margin-bottom: 20px;
    text-align: center;
    padding: 50px;
    > strong {
      display: block;
      margin-bottom: 10px;
    }
    > p {
      color: #0078f1;
      font-size: 22px;
      font-family: "Pretendard_bold";
      > span {
        font-family: "Pretendard_regular";
        color: #4a4949;
        font-size: 16px;
      }
    }
  }

  .most_use {
    > div {
      background-color: #fff;
      padding: 30px;
      border-radius: 10px;
      margin-left: 24px;
      margin-bottom: 20px;
      > p {
        color: #707070;
        margin-bottom: 10px;
      }
      > strong {
        font-family: "Pretendard_bold";
        font-size: 20px;
      }
    }
  }

  #HistoryAnalyze {
    .chart {
      overflow: auto;
      max-width: 1163px;
      max-height: 380px;
      border-top: 1px solid #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      padding: 0 0 20px;
      position: relative;
      .no_data {
        text-align: center;
        color: #707070;
        padding: 30px 0;
      }
      .axisX {
        display: flex;
        align-items: flex-start;
        margin-left: 100px;
        position: sticky;
        top: 0;
        z-index: 888;
        background: #fff;
        padding-top: 20px;
        .year-container {
          .year-title {
            margin-bottom: 10px;
            font-family: "Pretendard_extraBold";
          }
          .months {
            display: flex;
            align-items: center;
            .month {
              text-align: center;
              color: #4a4949;
              font-size: 14px;
              position: relative;
              border-bottom: 2px solid #d7d7d7;
              padding-bottom: 10px;
              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background-color: #d7d7d7;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
      }
      .analyze_list {
        position: relative;
        display: flex;
        align-items: center;
        .runway_period {
          color: #4a4949;
          font-size: 14px;
          flex: none;
          position: sticky;
          left: 0;
          top: 0;
          padding: 7px 0;
          z-index: 955;
          background-color: #fff;
          border-right: 1px solid #d7d7d7;
        }
        .marks {
          .mark {
            width: 20px;
            height: 20px;
            position: absolute;
            top: 5px;
            border-radius: 20px;
            &.runway {
              background-color: #ff9999;
              z-index: 99;
            }
            &.half {
              background-color: #7fe2b2;
              z-index: 77;
            }
            &.third {
              background-color: #ffd644;
              z-index: 55;
            }
          }
        }
        .sections {
          display: flex;
          align-items: center;
          .months {
            display: flex;
            align-items: center;
            .month {
              height: 30px;
              position: relative;
              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background-color: #d7d7d7;
                position: absolute;
                left: 0;
                top: 0;
              }
            }
          }
        }
        .period_bg {
          display: inline-block;
          height: 20px;
          position: absolute;
          top: 5px;
          background-color: #d8e3ff;
        }
      }
    }
    .marks_info {
      margin-top: 20px;
      > ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        > li {
          display: flex;
          align-items: center;
          margin-right: 8px;
          > div {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin-right: 8px;
            &.runway {
              background-color: #ff9999;
              z-index: 99;
            }
            &.half {
              background-color: #7fe2b2;
              z-index: 77;
            }
            &.third {
              background-color: #ffd644;
              z-index: 55;
            }
          }
          > p {
            color: #4a4949;
          }
        }
      }
    }
  }
}

#GroupwareWorks {
  .commute_wrapper {
    .rounded {
      border-radius: 6px;
    }
  }
}
