@import "src/include";

#IpLoan {
  color: #333d4b;
  padding: 40px 0;
  font-family: "Pretendard_regular";
  strong {
    font-family: "Pretendard_bold";
  }
  section {
    padding-top: 40px;
    padding-bottom: 40px;
    &:first-child {
      padding-top: 0;
    }
  }
  input:checked ~ .checkmark {
    border: none;
    background-color: var(--color-Bright-Blue);
  }
  .wrap {
    width: 1280px;
    margin: 0 auto;
  }
  .bg_gray {
    background-color: #f9fafb;
  }
  .loading {
    text-align: center;
  }
  .section_tit {
    color: #333d4b;
    display: block;
    padding-bottom: 10px;
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 20px;
    font-size: 18px;
    .tit_desc {
      font-family: "Pretendard_light";
      display: block;
      color: #6b7684;
      padding-top: 8px;
      font-size: 16px;
      > strong {
        color: #333d4b;
      }
    }
  }
  #Steps {
    .top {
      margin-bottom: 40px;
      > p {
        color: #6b7684;
      }
      .biznavi {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        > strong {
          font-size: 30px;
          display: inline-block;
          padding-left: 6px;
          margin-bottom: -5px;
        }
      }
    }
    .help {
      .steps {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        > li {
          background: linear-gradient(to right, #cfdeff, #edf8f1);
          padding: 30px;
          border-radius: 20px;
          > strong {
            font-size: 18px;
            display: block;
            line-height: 1.4;
          }
          > p {
            padding-top: 10px;
            color: #6b7684;
          }
          &:last-child {
            grid-column: 1 / 4;
          }
        }
      }
    }
  }
  #Banks {
    .bank_list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      > li {
        display: flex;
        justify-items: center;
        position: relative;
        background: #fff;
        justify-content: center;
        height: 220px;
        align-items: flex-end;
        padding: 30px;
        border-radius: 20px;
        &.related {
          border: 1px solid var(--color-Bright-Blue);
        }
        > img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        > p {
          color: #9f9f9f;
          z-index: 99;
          text-align: center;
        }
        .tag {
          color: #fff;
          background-color: var(--color-Bright-Blue);
          display: inline-block;
          padding: 6px 12px;
          border-radius: 24px;
          position: absolute;
          top: 18px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 90;
        }
      }
    }
  }
  #InvestmentView {
    i {
      position: static;
    }
    button {
      background-color: transparent;
    }
    .wrap_search {
      background-color: #f2f4f6;
      padding: 6px 12px;
      border-radius: 32px;
      select,
      input {
        background-color: transparent;
        border: none;
      }
    }
    .btns {
      button {
        border-radius: 20px;
        height: 36px;
        padding: 0 16px;
        width: auto;
        &.ToggleButton {
          height: 24px;
        }
      }
      .btn_save {
        background-color: var(--color-Bright-Blue);
        color: #fff;
      }
      .btn_update {
        color: var(--color-Bright-Blue);
        border: 1px solid var(--color-Bright-Blue);
      }
      .btn_download {
        color: #333d4b;
        border: 1px solid #8b95a1;
      }
      .btn_tab_setting,
      .btn_tab_setting_save {
        background-color: var(--color-Bright-Blue);
        color: #fff;
      }
    }
  }
  .ToggleButton {
    .off {
      background-color: var(--color-Bright-Blue);
    }
  }
  .ToggleButtonWithSpan {
    span {
      font-size: 16px;
    }
  }
  .table-box-wrap {
    padding-top: 0;
    padding-bottom: 40px;
  }
  .wrap_list {
    overflow-x: auto;
    max-height: 520px;
    table.list {
      width: 1600px;
      thead {
        position: static;
        tr {
          width: auto;
          display: table-row;
          th {
            position: sticky;
            top: 0;
            background-color: #f9fafb;
            z-index: 90;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              background-color: #f9fafb;
              z-index: 99;
            }
            &:nth-child(1) {
              left: 0;
            }
            &:nth-child(2) {
              left: 60px;
            }
            &:nth-child(3) {
              left: 120px;
            }
            &:nth-child(4) {
              left: 190px;
            }
            &:nth-child(5) {
              left: 250px;
            }
            &:nth-child(6) {
              left: 310px;
            }
          }
        }
      }
      td {
        border: none;
        &.rowspan {
          vertical-align: top;
          padding-top: 24px;
        }
        &.td1,
        &.td2,
        &.td3,
        &.td4,
        &.td5,
        &.td6 {
          position: sticky;
          background-color: #f9fafb;
        }
        &.td1 {
          left: 0;
        }
        &.td2 {
          left: 60px;
        }
        &.td3 {
          left: 120px;
        }
        &.td4 {
          left: 190px;
        }
        &.td5 {
          left: 250px;
        }
        &.td6 {
          left: 310px;
        }
      }
    }
  }
  #DocumentStep {
    > ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 22px;
      > li {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f4f7ff;
        padding: 26px;
        border-radius: 30px;
        position: relative;
        .top {
          margin-bottom: 14px;
        }
        .btn {
          display: inline-block;
          padding: 8px 18px;
          border-radius: 28px;
        }
        .regist {
          background-color: var(--color-Bright-Blue);
          color: #fff;
        }
        .modify {
          background-color: #fff;
          color: var(--color-Bright-Blue);
          border: 1px solid var(--color-Bright-Blue);
        }
        .download {
          background-color: #fff;
          border: 1px solid #6b7684;
        }
        .bottom {
          font-family: "Pretendard_light";
          > p {
            display: flex;
            align-items: center;
          }
        }
        &.end {
          background-color: var(--color-Bright-Blue);
          color: #fff;
        }
        &::after {
          content: "";
          width: 24px;
          height: 24px;
          display: block;
          position: absolute;
          background: url("../../../assets/images/mobile/icon_arrow_right.svg");
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .Tooltip {
          width: 16px;
          height: 16px;
          margin-left: 4px;
          i {
            width: 16px;
            height: 16px;
          }
          .tooltip_box {
            width: 340px;
            padding: 20px;
            border-radius: 20px;
            background-color: #6b7684;
            color: #fff;
            word-break: keep-all;
            text-align: left;
            z-index: 99;
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            &::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #6b7684;
              display: inline-block;
              position: absolute;
              right: 100%;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  #MarketMovement {
    .top_txt {
      > strong {
        font-size: 18px;
        display: block;
        margin-bottom: 6px;
      }
      > p {
        font-family: "Pretendard_light";
      }
    }
    .tag_list {
      margin: 20px 0;
      > ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        > li {
          padding: 8px 12px;
          color: var(--color-Bright-Blue);
          border: 1px solid var(--color-Bright-Blue);
          border-radius: 24px;
          cursor: pointer;
          &.selected {
            color: #fff;
            background-color: var(--color-Bright-Blue);
          }
        }
      }
    }
    .info_box {
      padding: 20px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      & + .info_box {
        margin-top: 20px;
      }
      &.domestic {
        background-color: #eefbfc;
      }
      &.overseas {
        background-color: #f4f7ff;
      }
      .info_tit {
        display: block;
        font-size: 18px;
        padding-bottom: 20px;
      }
      > p {
        font-family: "Pretendard_light";
        line-height: 1.4;
      }
      .more {
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 8px 12px;
        color: var(--color-Bright-Blue);
        background-color: #cfdeff;
        border-radius: 24px;
      }
    }
    .market_list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      > li {
        padding: 20px;
        border: 1px solid #e5e8eb;
        border-radius: 20px;
        .list_tit {
          font-size: 18px;
          display: block;
          padding-bottom: 10px;
        }
        .txt {
          height: 49px;
          font-family: "Pretendard_light";
          padding: 10px 0;
          margin-bottom: 10px;
          @include text-ellipsis(2);
        }
        > img {
          max-width: 240px;
          height: 190px;
          margin: 0 auto;
          display: block;
        }
        .btn {
          text-align: right;
          > button {
            padding: 4px 12px;
            border: 1px solid var(--color-Bright-Blue);
            color: var(--color-Bright-Blue);
            border-radius: 24px;
            background-color: transparent;
          }
        }
      }
    }
    .comp_list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px;
      > li {
        padding: 8px 12px;
        border: 1px solid #e5e8eb;
        border-radius: 24px;
        background-color: transparent;
      }
    }
  }
  #IntroIP {
    width: auto;
    .wrap_content {
      display: block;
      .info {
        width: auto;
        & + .info {
          margin-top: 40px;
        }
        .header {
          h2 {
            font-size: 18px;
          }
          a {
            width: auto;
            height: auto;
            padding: 4px 12px;
            background-color: transparent;
            color: var(--color-Bright-Blue);
            border: 1px solid var(--color-Bright-Blue);
            border-radius: 24px;
            font-size: 14px;
            display: inline-block;
          }
        }
      }
      .content_list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        .item {
          align-items: center;
          flex-direction: column;
          & + .item {
            margin: 0;
          }
          .img_box {
            width: 100%;
            height: 290px;
            position: relative;
            border-radius: 20px;
            margin-bottom: 16px;
            img {
              max-width: 100%;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 20px;
            }
          }
          .item_info {
            display: block;
            width: 100%;
            text-align: left;
            h2 {
              font-size: 18px;
              word-break: keep-all;
              margin-bottom: 16px;
            }
            p {
              font-family: "Pretendard_light";
            }
          }
        }
      }
    }
  }
  #DetailPop {
    width: 840px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9999;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 #d8d8d8;
    .image {
      text-align: center;
      margin: 10px 0;
      img {
        width: 100%;
        max-height: 500px;
      }
    }
    .title {
      > strong {
        display: block;
        font-size: 18px;
      }
    }
    .content {
      word-break: keep-all;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      background: transparent;
    }
  }
  #PatentListOfProduct {
    table {
      thead {
        position: sticky;
        left: 0;
        top: 0;
      }
      th,
      td {
        padding: 6px;
      }
    }
    .related_btn {
      padding: 6px 10px;
      border: 1px solid var(--color-Bright-Blue);
      color: var(--color-Bright-Blue);
      border-radius: 24px;
      &.related {
        color: #fff;
        background-color: var(--color-Bright-Blue);
      }
    }
    .sort_btn {
      display: inline-flex;
      align-items: center;
      > i {
        width: 12px;
        height: 12px;
        margin-left: 4px;
        &::before {
          color: #62676e;
        }
      }
    }
  }
}
