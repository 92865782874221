#Estimate {
  height: 80vh;
  background-color: #f7faff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  header {
    text-align: center;
    font-size: 22px;
    h2 {
      background-image: linear-gradient(to right, #0d7, #0078f1);
      font-size: 32px;
      font-weight: 600;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
    }
    h5 {
      font-family: Pretendard_regular;
    }
  }
  section {
    width: 600px;
    .info_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 40px;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .input_wrapper {
      background-color: #fff;
      padding: 30px 50px;
      border-radius: 0 20px / 20px 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 50px;
    }
  }
  .checkbox_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div:first-child {
      padding-bottom: 10px;
      border-bottom: 1px solid black;
      font-family: Pretendard_bold;
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-left: 105px;
      > div {
        display: flex;
        p {
          min-width: 300px;
        }
        span {
          cursor: pointer;
        }
      }
    }
    input[type="checkbox"] {
      margin: 0 10px;
      width: 22px;
      height: 22px;
    }
  }
  footer {
    width: 600px;
    border: none;
    display: flex;
    gap: 20px;
    > * {
      flex-grow: 1;
    }
    button {
      height: 42px;
      color: white;
      font-size: 20px;
      border-radius: 10px;
    }
    button:first-child {
      background: #A6A6A6;
    }
    button:last-child {
      background: #1474FF;
    }
  }
}
