@use "src/headerBase";
@import "src/include";
#CompetitorList {
  margin: 0 auto;
  width: 100vw;
  .tutorial-box {
    display: flex;
    width: 100vw;
    height: 307px;
    background-image: url("../../../assets/images/common/banner.png");
    background-size: 1868px;
    .wrap_text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 73px;
      margin-top: 55px;
      width: 295.7px;
      height: 59px;
      align-items: center;
      justify-content: center;
    }
    h2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard_Bold;
      font-size: 49px;
      //@include text-gradient-green;
      color: #232439;
      margin-bottom: 4px;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
    .content {
      margin-top: 43px;
      margin-left: 10%;
      justify-content: center;
      align-items: center;
      width: 703px;
      height: 237px;
    }
    .title p {
      width: 703px;
      height: 123px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 20px;
    }
    .title span:nth-child(1) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(3) {
      font-family: Pretendard_Regular;
      font-size: 27px;
      color: #343434;
    }
    .title span:nth-child(5) {
      font-family: Pretendard_SemiBold;
      font-size: 27px;
      color: #232439;
    }

    img {
      position: absolute;
      top: 30px; /* Adjust the top position as needed */
      right: 83px; /* Adjust the left position as needed */
      width: 348px;
      height: 260px;
    }
  }
}
#CompetitorLists {
  //width: 1280px;
  //margin: 0 auto;
  width:100vw;
  .search_wrapper {
    display: flex;
    align-items: center;
    //justify-content: center;
    height: 96px;
    padding: 0 50px 0 50px;
    background-color: #f4fffa;
    > p {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
      display: flex;
      align-items: center;
    }
    > .search_input {
      margin-left: 82px;
      border-radius: 15px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      //width: 829px;
      width:80%;
      height: 42px;
      display: flex;
      align-items: center;
      input[type="text"] {
        border: none;
        //width: 790px;
        width:98%;
        height: 21px;
        font-family: Pretendard_regular;
        font-size: 14px;
        color: #54b7e0;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Pretendard_bold;
      font-size: 12px;
      color: #959595;
      height: 24px;
      padding: 0 6px;
      border-radius: 9px;
      background-color: #eff3fb;
      margin-left: 13px;
    }
  }
  .company_info_wrapper {
    .loading {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 32px;
      font-weight: bold;
      color: black;
    }
    > div {
      margin-bottom: 100px;
      h5 {
        font-family: Pretendard_bold;
        font-size: 28px;
        letter-spacing: -0.88px;
        padding: 22px 0 0 95px;
        span {
          color: #0078f1;
        }
      }
      .company_info_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        margin: 33px 50px 0 50px;
        padding: 33px 0;
        max-height: 600px;
        overflow-y: scroll;
      }
      .company_info_item {
        display: flex;
        align-items: center;
        //width: 1043px;
        width:90vw;
        height: 102px;
        border-radius: 15px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
        position: relative;
        img {
          width: 63px;
          height: 63px;
          margin: 13px;
        }
        button {
          position: absolute;
          right: 17px;
          width: 104px;
          height: 40px;
          font-size: 14px;
          color: #fff;
          border-radius: 10px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
        }
        > .item_text {
          line-height: 1.5;
          > p:nth-child(1) {
            font-size: 18px;
            letter-spacing: -0.5px;
            color: #343434;
          }
          > p:nth-child(2) {
            font-size: 14px;
            width: 310px;
            @include text-ellipsis(1);
          }
          > .item_text_category {
            > p {
              display: inline-block;
              font-family: Pretendard_bold;
              padding: 3px 9px;
              margin-right: 7px;
              font-size: 12px;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              border: 1px solid transparent;
              border-radius: 14px;
              background: linear-gradient(#ffffff, #ffffff) padding-box,
                linear-gradient(to left, #0d7, #0078f1);
              background-origin: border-box;
            }
          }
        }
      }
    }
    > div:nth-child(even) {
      background-color: rgba(240, 240, 240, 0.44);
    }
  }
}
