#IncriselPopupLog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  table {
    width: 960px;
    margin-top: 40px;
    border: 1px solid #e7e7e7;
  }
  .age {
    width: 500px;
    tr {
      border-bottom: 1px solid #e7e7e7;
    }
    td {
      width: 300px;
    }
    .count, .count td {
      width: 500px;
      border-bottom: none;
    }
  }
}
