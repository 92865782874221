@import "src/include";

#ConsortiumManage{
	.consortiumManage{
		position: relative;
		width: 880px;
		margin: 70px auto 0;
		&_tab{
			position: absolute;
			width: 170px;
			top: 0;
			left: -170px;
			&_title{
				font-family: Pretendard_extraBold;
				font-size: 22px;
				color: #343434;
				height: 32px;
				line-height: 32px;
				margin-bottom: 16px;
			}
			&_list-wrap{

			}
			&_list{
				padding-bottom: 10px;
				&.on{
					.consortiumManage_tab_btn{
						color: var(--color-Web-Orange);
					}
				}
			}
			&_btn{
				height: 30px;
				line-height: 30px;
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #343434;
				background-color: transparent;
			}
		}
		&_content{
			margin-bottom: 30px;
			width: 100%;
			&_top{
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 40px;
			}
			&_title{
				font-family: Pretendard_bold;
				font-size: 22px;
				color: #343434;
			}
			&_btn{
				width: 140px;
				height: 32px;
				line-height: 32px;
				background-color: #4593f5;
				font-family: Pretendard_bold;
				font-size: 14px;
				color: #fff;
				text-align: center;
				border-radius: 4px;
			}
		}
		&_table{
			&_row{
				.consortiumManage_table_col{
					&:nth-of-type(2){
						text-align: left;
						padding-left: 30px;
					}
				}
			}
			&_col{
				&_btn{
					width: 24px;
					height: 24px;
					background: url(../../../assets/images/common/icon-arrow-next-bk.svg) no-repeat 50% 50%;
					background-size: cover;
					text-indent: -9999px;
				}
			}
			&_body{
				.consortiumManage_table_row{
					height: 50px;
				}
			}
		}
	}
}
