#ResearchTechEditHeader {
  width:100%;
  //width: 1280px;
  height: 84px;
  //margin: 0 auto;
  //padding: 10px 30px;
  padding: 10px  50px;
  border: 1px solid #f0f0f0;
  > div {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 22px;
      letter-spacing: -0.25px;
      color: #343434;
    }
    button {
      width: 159px;
      height: 37px;
      border-radius: 10px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      font-size: 16px;
      color: #343434;
    }
  }
  > p {
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #343434;
  }
}
