@import "src/include";

#ContentPhotoList {
    > .mo{
        margin-bottom: 32px;
    }
    &.m-col-1-2{
        margin-bottom: 20px;
        .contentPhotoList{
            &_title{
                font-family: Pretendard_extraBold;
                font-size: 20px;
                color: #1d334e;
                margin: 0 20px 20px;
            }
            &_table{
                display: flex;
                border-top: none;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 34px;
                padding: 0 20px;
                flex-wrap: wrap;
            }
            &_list{
                width: calc(50% - 10px);
                height: 46.66666666666667vw; /*168px(360)*/
                padding: 0;
                overflow: hidden;
                border-bottom: none;
                align-items: flex-start;
                > .mo{
                    width: 100%;
                }
                &_img{
                    position: relative;
                    width: 100%;
                    height: 27.77777777777778vw; /*100px(360)*/
                    margin-bottom: 2.222222222222222vw; /*8px(360)*/
                    border-radius: 4px;
                    overflow: hidden;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        width: 24px;
                        height: 24px;
                        background: url(../../../assets/images/businessDescription/ico_play.png) no-repeat 50% 50%;
                        background-size: cover;
                        left: 10px;
                        bottom: 10px;
                    }
                }
                &_info{
                    font-family: Pretendard_extraBold;
                    font-size: 12px;
                    color: #1bb3f3;
                    margin-bottom: 1.111111111111111vw; /*4px(360)*/
                    @include text-ellipsis(1);
                }
                &_title{
                    font-family: Pretendard_extraBold;
                    font-size: 14px;
                    color: #1d334e;
                    @include text-ellipsis(2);
                }
                &_sub{
                    display: none;
                }
            }
        }
    }
    &.m-col-1-1{
        &.style{
            .contentPhotoList{
                &_list{
                    > .mo{
                        .contentPhotoList_list-wrap{
                            .contentPhotoList_list_text{
                                .contentPhotoList_list_title{
                                    @include text-ellipsis(1);
                                    margin: 0.5555555555555556vw 0; /*2px(360)*/
                                }
                                .contentPhotoList_list_info{
                                    font-family: Pretendard_bold;
                                    color: #707070;
                                    margin: 0 0 2.222222222222222vw; /*8px(360)*/
                                }
                            }

                            .contentPhotoList_list_sub{
                                display: block;
                                .contentPhotoList_list_date-wrap{
                                    display: flex;
                                    height: 26px;
                                    justify-content: space-between;
                                    align-items: center;
                                    .contentPhotoList_list_day{
                                        display: block;
                                        .day{
                                            font-family: Pretendard_extraBold;
                                            font-size: 14px;
                                            color: #1d334e;
                                            padding: 3px 12px;
                                            border: 2px solid #6bd3ff;
                                            border-radius: 13px;
                                        }
                                        .today{
                                            font-family: Pretendard_extraBold;
                                            font-size: 12px;
                                            color: #fff;
                                            padding: 5px 12px;
                                            background-color: #ff4d00;
                                            border-radius: 12px;
                                        }
                                    }
                                    .contentPhotoList_list_date{
                                        font-family: Pretendard_bold;
                                        font-size: 12px;
                                        color: #707070;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .contentPhotoList{
            &_title{
                font-family: Pretendard_extraBold;
                font-size: 20px;
                color: #1d334e;
                margin: 0 20px;
            }
            &_table{
                border-top: none;
            }
            &_list{
                display: block;
                height: 27.77777777777778vw; /*100px(360)*/
                padding: 4.861111111111111vw 20px; /*17.5px(360)*/
                > .mo{
                    .contentPhotoList_list-wrap{
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        .contentPhotoList_list_img{
                            width: 26.66666666666667vw; /*96px(360)*/
                            height: 17.5vw; /*63px(360)*/
                            margin-right: 0;
                            border-radius: 4px;
                            overflow: hidden;
                            a{
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .contentPhotoList_list_text{
                            width: calc(100% - 26.66666666666667vw);
                            padding-left: 16px;
                            .contentPhotoList_list_info{
                                font-family: Pretendard_extraBold;
                                font-size: 12px;
                                color: #1bb3f3;
                                margin: 0.5555555555555556vw 0; /*2px(360)*/
                                @include text-ellipsis(1);
                            }
                            .contentPhotoList_list_title{
                                font-family: Pretendard_extraBold;
                                font-size: 14px;
                                color: #1d334e;
                                max-height: 40px;
                                @include text-ellipsis(2);
                            }
                        }
                        .contentPhotoList_list_sub{
                            display: none;
                        }
                    }
                }
            }
        }
    }
	&.col-1-4{
		.contentPhotoList{
            &_fav{
                display: none;
            }
            &_table-top{
                height: 32px;
                margin-bottom: 25px;
            }
			&_table{
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				border-top: none;
			}
			&_list{
				width: calc((100% - 18px) / 4);
				height: auto;
				justify-content: flex-start;
				align-items: flex-start;
				flex-wrap: wrap;
				border: 1px solid #e6e6e6;
				padding: 6px;
				margin: 0 6px 15px 0;
                > .pc{
                    width: 100%;
                    height: 248px;
                }
				&:nth-of-type(4n){
					margin-right: 0;
				}
				&_img{
					width: 100%;
					height: 153px;
					margin: 0;
					a{
						display: block;
						width: 100%;
						height: 100%;
					}
				}
				&_box{
					width: 100%;
					height: 87px;
					&_title{
						height: 32px;
						padding: 0;
						margin: 8px 0 15px;
                        @include text-ellipsis(2);
					}
					&_date{
                        display: none;
					}
                    &_info{
                        font-family: Pretendard_semiBold;
                        color: #606060;
                        margin-bottom: 0;
                        @include text-ellipsis(2);
                    }
                    &_day{
                        display: none;
                    }
					&_fav{
						display: none;
					}
				}
			}
		}
	}
    &.col-1-2{
        .contentPhotoList{
            &_search{
                margin: 0 auto 25px;
            }
            &_table-top{
                height: 32px;
                margin-bottom: 25px;
            }
            &_table{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-top: none;
            }
            &_list{
                width: calc(50% - 7.5px);
                border-top: 1px solid #d8d8d8;
                border-bottom: none;
                align-items: flex-start;
                padding: 10px;
                > .pc{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    .contentPhotoList_list_img{
                        height: 120px;
                    }
                }
                &:nth-last-child(1){
                    border-bottom: 1px solid #d8d8d8;
                }
                &:nth-last-child(2){
                    border-bottom: 1px solid #d8d8d8;
                }
                &_box{
                    &_title{
                        padding: 5px 0 0;
                        margin-bottom: 20px;
                        @include text-ellipsis(1);
                    }
                }
            }
        }
    }
    &.col-1-1{
        .contentPhotoList{
            &_fav{
                display: none;
            }
            &_list{
                width: 100%;
                > .pc{
                    display: flex;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
                &_box{
                    &_fav{
                        display: none;
                    }
                }
            }
        }
    }
    .contentPhotoList {
        &_title-wrap {
            display: flex;
            width: 100%;
            height: 32px;
            margin: 0 auto 30px;
            justify-content: space-between;
            align-items: center;
        }

        &_text-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &_title {
            display: inline-block;
            margin-right: 20px;
            font-family: Pretendard_bold;
            font-size: 22px;
            color: #343434;
        }

        &_menu {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_list {
                margin: 0 20px;

                button[type="button"] {
                    font-family: Pretendard_bold;
                    font-size: 18px;
                    color: #343434;
                    background-color: transparent;
                }

                &.on {
                    button[type="button"] {
                        font-family: Pretendard_extraBold;
                        color: var(--color-Web-Orange);
                    }
                }
            }
        }

        &_fav {
            &_btn {
                position: relative;
                width: 140px;
                height: 32px;
                line-height: 30px;
                font-family: Pretendard_extraBold;
                font-size: 14px;
                color: #343434;
                padding: 0 20px 0 40px;
                background-color: transparent;
                border: 2px solid #ffa600;
                border-radius: 4px;
                &::after {
                    position: absolute;
                    content: "";
                    width: 16px;
                    height: 16px;
                    top: 50%;
                    left: 15px;
                    transform: translateY(-50%);
                    background: url(../../../assets/images/common/icon-fav-on.svg)
                        no-repeat 50% 50%;
                    background-size: cover;
                }
            }
        }

        &_search {
            display: flex;
            width: 530px;
            height: 32px;
            margin: 0 auto 34px;
            justify-content: center;
            align-items: center;

            &_input-wrap {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                border: 1px solid #d8d8d8;
                border-radius: 4px;
                margin-right: 5px;
            }

            &_select {
                width: 110px;
                height: 100%;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
                padding-left: 10px;
                border: none;
            }

            &_input {
                width: 380px;
                height: 100%;

                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background-color: transparent;
                    padding-left: 10px;
                }
            }

            &_btn {
                width: 24px;
                height: 24px;
                text-indent: -9999px;
                background-color: transparent;
                background: url(../../../assets/images/common/icon-search.svg) no-repeat 50% 50%;
                background-size: cover;
            }
        }

        &_table-top {
            height: 24px;
            margin-bottom: 10px;
            text-align: right;

            &_select {
                width: 110px;
                height: 100%;
                font-family: Pretendard_bold;
                font-size: 14px;
                color: #343434;
                padding-left: 10px;
            }
        }

        &_table {
            border-top: 1px solid #d8d8d8;
            margin-bottom: 40px;
        }

        &_list {
            display: flex;
            height: 140px;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
            padding: 10px 20px;

            &_img {
                width: 165px;
                height: 100%;
                border: 1px solid #d8d8d8;
                margin-right: 10px;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &_box {
                width: calc(100% - 175px);
                height: 100%;

                &_title {
                    font-family: Pretendard_bold;
                    font-size: 14px;
                    color: #343434;
                    padding: 5px 0;
                    margin-bottom: 15px;
                }

                &_info-wrap {
                    font-family: Pretendard_bold;
                    font-size: 12px;
                    color: #343434;
                }

                &_date {
                    margin-bottom: 10px;
                }

                &_info {
                    margin-bottom: 15px;
                }

                &_day {
                    font-family: Pretendard_semiBold;

                    .strong {
                        font-family: Pretendard_bold;
                        font-size: 14px;
                        color: var(--color-Web-Orange);
                        padding-left: 10px;
                    }
                }

                &_fav {
                    .btn_fav {
                        width: 24px;
                        height: 24px;
                        overflow: hidden;
                        text-indent: -9999px;
                        &.on {
                            background: url(../../../assets/images/common/icon-fav-on.svg)
                                no-repeat 50% 50%;
                            background-size: cover;
                        }
                        &.off {
                            background: url(../../../assets/images/common/icon-fav-off.svg)
                                no-repeat 50% 50%;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}
