@import "src/include";

#docTradeMyTransactionPurchaseList{
	.mytransaction{
		&_section-purchase{
			margin-bottom: 90px;
		}
		&_section{
			width: 880px;
			margin: 0 auto;
			&_title{
				font-family: Pretendard_bold;
				font-size: 22px;
				color: #343434;
				margin-bottom: 47px;
			}
			&_table{
				&_thead{
					.transaction-title{
						text-align: left;
						padding-left: 26px;
					}

				}
			}
		}
	}
}
