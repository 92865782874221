#RenewalServiceMain {
  &.PersonalDashboard {
    > header {
      .header_profile  {
        .profile_info {
          margin: 52px 0 0 24px;
        }
      }
    }
    .dashboard_box_tab {
      .dashboard_tab {
        > div {
          &.half_box_title {
            width: 100%;
            padding: 0 15px;
            margin: 0;
            box-shadow: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
    .dashboard_wrapper {
      .dashboard_box_tab {
        .dashboard_tab_item {
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          .half_box_title {
            &.new {
              justify-content: flex-start;
            }
            .cnt {
              color: #fff;
              background-color: var(--color-Alizarin-Crimson);
              display: inline-block;
              padding: 4px 8px;
              border-radius: 10px;
              font-size: 12px;
              margin-left: 10px;
            }
          }
          .dashboard_tab_item_list {
            max-height: 300px;
            overflow-y: auto;
          }
        }
      }
    }
  }
}