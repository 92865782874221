@import "src/include";

#CompetitorInfo {
  width: 1280px;
  margin: 0 auto;

  >header {
    .competitor_title {
      display: flex;
      justify-content: space-between;
      padding: 12px 26px;

      >div {
        display: flex;

        .oval {
          width: 63px;
          height: 63px;
          border: solid 1px #d8d8d8;
          border-radius: 50%;
        }

        p {
          font-size: 22px;
          letter-spacing: -0.25px;
          color: #343434;
          margin-left: 9px;
        }

        i {
          margin-left: 6px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 18px;
          font-size: 16px;
        }

        .btn_download {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
        }

        .btn_notify {
          width: 212px;
          height: 40px;
          border-radius: 10px;
          background-image: linear-gradient(to left, #0d7, #0078f1);
          color: #FFFFFF;
        }

        button {
          width: 131px;
          height: 40px;
          border-radius: 10px;
          background-color: #0078f1;
          color: #fff;
        }
      }
    }

    .info_tab {
      display: flex;
      height: 60px;
      border-bottom: 1px solid #f0f0f0;

      >p {
        width: 140px;
        height: 60px;
        font-size: 14px;
        color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active {
        border-bottom: 3px solid #0078f1;
      }
    }
  }

  >section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(216, 216, 216, 0.28);
    gap: 22px;
    padding: 22px 0;

    article {
      width: 1010px;
      border-radius: 15px;
      box-shadow: 0 2px 3px 1px #f0f0f0;
      background-color: #fff;
      padding-bottom: 33px;

      h5 {
        font-size: 22px;
        color: #4593f5;
        margin: 18px 0 31px 24px;
      }

      .empty_data {
        text-align: center;
        font-size: 18px;
      }

      .empty_data_chart {
        text-align: center;
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 50px;
      }

      .empty_data_chart_black {
        text-align: center;
        font-size: 18px;
        margin-top: 50px;
      }
    }

    .competitor_info_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px 59px;
      padding: 0 25px;

      >div {
        display: flex;
        align-items: center;
        min-height: 42px;
        border-bottom: 1px solid #e7e7e7;

        >p:nth-child(1) {
          width: 120px;
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #343434;
        }

        >p:nth-child(2),
        a {
          font-family: Pretendard_regular;
          color: #343434;
          font-size: 14px;
        }
      }

      >div:nth-child(3),
      div:nth-child(5) {
        grid-column: 1 / 2;
      }

      >div:nth-child(8),
      div:nth-child(9) {
        grid-column: 1 / span 3;
      }
    }

    .keyword_wrapper {
      display: flex;
      gap: 7px;

      p {
        text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
        font-family: Pretendard_bold;
        color: #343434;
        padding: 6px 8px;
        font-size: 12px;
        border: 1px solid transparent;
        border-radius: 14px;
        background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
        background-origin: border-box;
      }
    }

    .analysis {
      padding: 0 25px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 11px;

      >p {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 10px;
        width: 159px;
        height: 74px;
        border-radius: 37px;
        background-color: #eaf8ff;
        font-size: 16px;
      }
    }

    .employee {
      display: flex;
      gap: 20px;

      .employee_info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 17px 9px;

        p:nth-child(1) {
          width: 150px;
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #343434;
        }

        p:nth-child(2) {
          width: 150px;
          font-size: 12px;
          text-align: right;
          letter-spacing: 0.15px;
          color: #959595;

          span {
            font-size: 26px;
            color: #343434;
          }
        }
      }
    }

    .financial_wrapper {
      table {
        tbody {
          tr {
            td:nth-child(1) {
              background-color: #f0f0f0;
            }

            td {
              border: 1px solid #f0f0f0;
            }
          }
        }
      }
    }

    .sub_title {
      display: flex;
      justify-content: space-between;
      padding: 0 25px 9px 25px;

      >p:nth-child(1) {
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #343434;
      }

      >p:nth-child(2) {
        font-size: 12px;
        color: #959595;
      }
    }

    .chart_wrapper {
      width: 928px;
      height: 266px;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;
      background-color: #fff;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >p {
        font-size: 14px;
        color: #343434;
        margin-top: 10px;
      }
    }

    .tech_skills {
      display: flex;
      padding: 0 30px;

      >div {
        width: 50%;

        >p {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #343434;
          margin-bottom: 10px;
        }
      }

      .total_text {
        text-align: center;
        margin-top: 19px;
        font-size: 14px;
        letter-spacing: -0.3px;
        color: #343434;

        span {
          margin: 0 10px;
          color: #0078f1;
          font-size: 16px;
        }
      }
    }

    .skill_eval {
      display: flex;
      justify-content: center;
      gap: 38px;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          letter-spacing: -0.25px;
          color: #343434;
        }

        .oval {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          background-color: #eaf8ff;
          border-radius: 50%;
          font-size: 18px;
          font-family: GmarketSansTTF_bold;
          margin: 23px 0;
          color: #0078f1;
        }

        span {
          font-size: 12px;
          color: #959595;
        }
      }

      >div:nth-child(1) {
        .oval {
          width: 82px;
          height: 82px;
          background-color: #0078f1;
          font-size: 22px;
          color: #fff;
          margin: 10px 0;
        }
      }
    }

    .company_skill_wrapper {
      >#GradeCompany {
        display: block;
        margin: 0 auto;
        width: 90%;

        .table_grade {
          width: 800px;
          margin: 38px 0 0 0;

          .data+.data {
            margin-left: 13px;
          }
        }
      }
    }

    .skill_info {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      gap: 10px;

      .skill_info_header {
        display: flex;

        p {
          font-size: 14px;
          letter-spacing: 0.15px;
          color: #4593f5;
        }

        p:nth-child(1) {
          width: 248px;
        }

        p:nth-child(2) {
          width: 271px;
        }

        p:nth-child(3) {
          width: 420px;
        }
      }

      .skill_info_items {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .skill_info_item {
        display: flex;
        border-bottom: solid 1px #d8d8d8;
        padding: 5px;

        >div {
          padding: 10px;
        }

        div:nth-child(1) {
          width: 248px;
          height: 161px;
          border-radius: 45px;
          border: solid 1px #d8d8d8;
          display: block;
          margin: auto;
        }

        div:nth-child(2) {
          width: 271px;
          display: block;
          margin: auto;
        }

        div:nth-child(3) {
          width: 420px;
        }
      }
    }

    .patent_chart_wrapper {
      display: flex;
      padding: 0 20px;
    }

    .patent_trade {
      background-image: linear-gradient(to right, #ff7a00 32%, #ffa600 100%);
      box-shadow: 0 2px 4px 0 rgba(52, 52, 52, 0.15);
      padding: 11.3px 15.8px 26.4px 16.5px;
      border: none;

      canvas {
        position: relative;
        left: -10px;
        top: 5px;
      }
    }

    .annual_fee_and_year {
      position: relative;
      padding: 10.6px 0.6px 9px 5.4px;

      >canvas {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }

    .chart {
      position: relative;
      width: 233.8px;
      height: 176.1px;
      margin: 14px 12px 23.9px 0;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;

      .chart_category_name {
        font-size: 14px;
        color: #fff;
      }

      .total_cnt {
        position: absolute;
        top: 50%;
        left: 35px;
        width: 90px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
      }

      .add_label,
      .apply_label,
      .giveup_label {
        position: absolute;
        top: 35%;
        left: 155px;
        justify-content: center;
        align-items: center;
        width: 61px;
        height: 18px;
        white-space: pre;
        font-size: 12px;
        font-family: Pretendard_extraBold;
        color: var(--color-Web-Orange);
        text-align: center;
        background: #ffffff;
        z-index: 2;
        border-radius: 4px;
      }

      .add_label:after,
      .apply_label:after,
      .giveup_label:after {
        content: "";
        position: absolute;
        bottom: 26px;
        left: 10px;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: #ffffff;
        border-bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px;
        transform: rotate(90deg);
      }

      .apply_label {
        top: 50%;
        color: #ffa600;
        background-color: rgba(255, 255, 255, 0.8);
      }

      .apply_label:after {
        border-top-color: rgba(255, 255, 255, 0.8);
      }

      .giveup_label {
        top: 65%;
        color: #ffffff;
        background-color: #ffa94d;
      }

      .giveup_label:after {
        border-top-color: #ffa94d;
      }
    }

    .patent_list {
      >p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      .circle {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 24px;
        border-radius: 16px;
        pointer-events: none;
        color: #FFFFFF;
        font-family: NanumSquareOTF_acEB;
        font-size: 12px;
      }
    }

    .trademark_wrapper {
      padding: 0 0 20px 25px;
      max-height: 600px;
      overflow-y: scroll;

      h6 {
        color: #4593f5;
        font-size: 14px;
        min-width: 110px;
      }

      .trademark_item {
        margin-top: 23px;
      }

      .trademark_name {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        height: 40px;
        border-bottom: 1px solid #f0f0f0;
      }

      .trademark_info {
        >.product_list {
          margin-top: 10px;
          display: flex;

          >p {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 110px;
            font-size: 14px;
            color: #343434;
          }

          >div {
            >p {
              display: inline-block;
              font-family: Pretendard_bold;
              padding: 6px 9px;
              margin: 0 7px 7px 0;
              font-size: 12px;
              text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
              border: 1px solid transparent;
              border-radius: 14px;
              background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
              background-origin: border-box;
            }
          }
        }
      }
    }

    .design_wrapper {
      padding: 0 25px 20px 25px;
      max-height: 600px;
      overflow-y: scroll;

      .design_item {
        margin-top: 20px;
      }

      .design_title {
        display: flex;
        justify-content: space-between;
        height: 42px;
        border-bottom: 1px solid #f0f0f0;
        align-items: center;

        h6 {
          color: #4593f5;
          font-size: 14px;
          min-width: 110px;
        }

        >div {
          display: flex;

          >.design_name {
            font-size: 14px;
            color: #343434;
          }
        }
      }

      .design_category {
        .design_status {
          display: inline-block;
          font-family: Pretendard_bold;
          padding: 6px 9px;
          margin: 0 7px 0 0;
          font-size: 12px;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
          border: 1px solid transparent;
          border-radius: 14px;
          background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
          background-origin: border-box;
        }

      }

      .design_content {
        display: flex;
        gap: 24px;
        margin-top: 10px;

        >div:first-child {
          width: 248px;
          height: 161px;
          border-radius: 45px;
          border: solid 1px #d8d8d8;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        >div:last-child {
          font-size: 14px;
          letter-spacing: -0.3px;
          color: #343434;
          line-height: 1.57;
        }
      }
    }

    //.design_wrapper {
    //  padding: 0 25px 20px 25px;
    //  max-height: 600px;
    //  overflow-y: scroll;
    //
    //  table {
    //    thead {
    //      height: 42px;
    //    }
    //  }
    //}
    .competition_rate {
      >p {
        margin: 10px 0 10px 25px;
      }

      .auction_chart_wrapper,
      .grid_chart_wrapper {
        >div {
          width: 320px;
          height: 266px;
          border: solid 0.8px #d8d8d8;
          background-color: #fff;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;

          p {
            position: absolute;
            font-size: 14px;
            color: #343434;
            bottom: 5px;
          }
        }
      }

      .auction_chart_wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

      .grid_chart_wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;
        gap: 10px;
      }
    }

    .auction_status_table {
      max-height: 400px;
      overflow-y: scroll;
    }

    .growth_rate {
      >p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      >div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px 25px;

        >div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 460px;
          height: 287px;
          border-radius: 15px;
          border: solid 0.8px #d8d8d8;
          background-color: #fff;

          span {
            font-size: 12px;
            color: #959595;
          }
        }
      }
    }

    .auction_status {
      >p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      >div {
        display: flex;
        margin-top: 20px;
      }

      &_text {
        justify-content: center;
        gap: 200px;

        >p {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #0078f1;

          span:nth-child(1) {
            font-size: 26px;
            color: #343434;
            margin-left: 20px;
          }

          span:nth-child(2) {
            font-size: 12px;
            color: #959595;
          }
        }
      }

      &_box {
        padding: 0 19px;
        gap: 19px;

        >div {
          width: 234px;
          height: 144px;
          border-radius: 15px;
          border: solid 0.8px #d8d8d8;
          padding: 10px;
          position: relative;

          p:nth-child(1) {
            display: inline-block;
            font-size: 12px;
            font-family: Pretendard_extraBold;
            color: #0078f1;
            padding: 6px 8px;
            border-radius: 14px;
            background-color: #e6f2ff;
          }

          p:nth-child(2) {
            margin-top: 15px;
            font-size: 14px;
            color: #343434;
            line-height: 1.29;
            font-family: Pretendard_regular;
            @include text-ellipsis(3)
          }

          p:nth-child(3) {
            position: absolute;
            right: 5px;
            bottom: 5px;
            font-size: 26px;
            text-align: right;
            color: #343434;
            letter-spacing: -0.5px;
          }
        }
      }

      &_table {
        margin-top: 20px;
      }
    }

    .competitor_list {
      >p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }
    }

    .company_info_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 13px;
      padding: 10px 0;
      max-height: 400px;
      overflow-y: scroll;
    }

    .company_info_item {
      display: flex;
      align-items: center;
      width: 900px;
      height: 102px;
      border-radius: 15px;
      border: solid 0.8px #d8d8d8;
      background-color: #fff;
      position: relative;

      img {
        width: 63px;
        height: 63px;
        margin: 13px;
      }

      button {
        position: absolute;
        right: 17px;
        width: 104px;
        height: 40px;
        font-size: 14px;
        color: #fff;
        border-radius: 10px;
        background-color: #0078f1;
      }

      >.item_text {
        line-height: 1.5;

        >p:nth-child(1) {
          font-size: 18px;
          letter-spacing: -0.5px;
          color: #343434;
        }

        >p:nth-child(2) {
          font-size: 14px;
          width: 310px;
          @include text-ellipsis(1)
        }

        >.item_text_category {
          width: 800px;

          >p {
            display: inline-block;
            font-family: Pretendard_bold;
            padding: 3px 9px;
            margin-right: 7px;
            font-size: 12px;
            text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
            border: 1px solid transparent;
            border-radius: 14px;
            background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
            background-origin: border-box;
          }
        }
      }
    }

    .growth_compare_rate {
      >p {
        margin: 10px 0 10px 25px;
        font-size: 18px;
      }

      >div:nth-of-type(1) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 928px;
        height: 251px;
        margin: 0 auto;
        border-radius: 30px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
      }

      >div:nth-of-type(2) {
        margin: 11px 0 0 45px;
        font-size: 14px;
        font-family: Pretendard_regular;
        line-height: 1.57;
        letter-spacing: -0.3px;
        color: #343434;
      }
    }

    .competitor_news {
      display: flex;
      padding: 0 25px;
      gap: 12px;

      >div {
        width: 340px;
        height: 322px;
        border-radius: 15px;
        border: solid 0.8px #d8d8d8;
        background-color: #fff;
        padding: 14px;
        position: relative;

        >p:nth-child(1) {
          font-size: 16px;
          color: #343434;
          margin-bottom: 20px;
        }

        >p:nth-child(2) {
          font-size: 14px;
          color: #343434;
          line-height: 1.29;
          @include text-ellipsis(10)
        }

        >p:nth-child(3) {
          position: absolute;
          font-size: 12px;
          color: #959595;
          right: 18px;
          bottom: 16px;
        }
      }
    }
  }

  .no_chart_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}