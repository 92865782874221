@import "src/include";

#CompetitorMain {
  //width: 1280px;
  width:100%;
  margin: 0 auto;
  > div:nth-child(odd) {
    background-color: rgba(240,240,240,0.44);

  }
  .header_wrap {
    //width:723.33px;
    padding: 47px 0;
    > h2 {
      width:1100px;
      margin-left: calc((100% - 1100px) / 2); 
      font-size: 43px;
      letter-spacing: -1.54px;
      color: #343434;
      //margin-left: 113px;
    }
    .header_grid {
      width:1100px;
      display: grid;
      margin-left: calc((100% - 1100px) / 2); 
      //padding: 0 90px;
      margin-top: 12px;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 245px;
      gap: 33px 30px;
      > div:first-child {
        position: relative;
        grid-column: 1 / span 2;
        border-radius: 15px;
        box-shadow: 0 2px 4px 0 #d8d8d8;
        background-color: #0078f1;
        padding-left: 25px;
        > div:first-child {
          > p {
            color: white;
          }

          > p:nth-child(1) {
            font-size: 43px;
          margin-top: 46px;
          display: flex;
          align-items: center;
          span {
            font-size: 32px;
            letter-spacing: -0.86px;
            color: #ffd438;
            margin-left: 5px;
          }
          .new_red {
            width: 39px;
            height: 18px;
            font-size: 10px;
            border-radius: 9px;
            background-color: #ff4949;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 20px 10px;
            font-family: Pretendard_regular;
          }
        }
      }
        > div:nth-child(2) {
          line-height:0px;
          font-size: 16px;
          margin-top: 13px;
        }
        .search_wrapper {
          height: 42px;
          border-radius: 15px;
          border: solid 1px #d8d8d8;
          background-color: #fff;
          display: flex;
          align-items: center;
          padding: 0 10px;
          margin-top: 30px;
          &.blogin {
            width: 780px;
            input[type="text"] {
              width: 720px;
              height: 21px;
              border: none;
            }
          }
          &.alogin {
            width: 403px;
            input[type="text"] {
              width: 360px;
              height: 21px;
              border: none;
            }
          }
        }
      }
      > div:first-child:before {
        position: absolute;
        background: url("../../../assets/images/competitor/img-search.png") no-repeat center;
        background-size: 269px 245px;
        content:'';
        width: 269px;
        height: 245px;
        right: 0;
      }
      > .header_list_wrapper {
        > h5 {
          font-size: 28px;
          font-family: Pretendard_bold;
          letter-spacing: -1px;
          color: #343434;
          margin-bottom: 12px;
        }
        .header_list_item {
          width: 322px;
          height: 63px;
          border-radius: 15px;
          border: solid 0.8px #d8d8d8;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;
          margin-bottom: 6px;
          > div {
            display: flex;
            align-items: center;
            img {
              width: 63px;
              height: 63px;
            }
            p {
              font-size: 18px;
              letter-spacing: -0.5px;
              color: #343434;
              margin-left: 5px;
            }
          }
          .one_click_text {
            display: flex;
            align-items: center;
            font-family: Pretendard_medium;
            font-size: 22px;
            letter-spacing: -0.25px;
            color: #343434;
            span {
              font-family: Pretendard_regular;
              font-size: 14px;
              margin-right: 10px;
              letter-spacing: -0.7px;
              color: #606060;
            }
          }
          img {
            width: 54px;
            height: 54px;
          }
        }
      }
      > div:nth-child(3) {
        border-radius: 15px;
        background-color: #eaf8ff;
        padding: 18px 25px;
      }
      //> div:last-child {
      //  grid-column: 2 / span 2;
      //  border-radius: 15px;
      //  box-shadow: 0 2px 8px 0 #b4e9ff;
      //  background-color: #fff;
      //  padding: 16px 20px;
      //  display: flex;
      //  flex-direction: column;
      //  justify-content: space-between;
      //  > p {
      //    font-size: 32px;
      //    letter-spacing: -0.86px;
      //    color: #343434;
      //    font-family: Pretendard_extraBold;
      //    span {
      //      @include text-gradient-green;
      //      font-size: 43px;
      //    }
      //  }
      //  > div {
      //    display: flex;
      //    align-items: flex-end;
      //    justify-content: space-between;
      //    p {
      //      font-size: 14px;
      //      color: #343434;
      //      font-family: Pretendard_regular;
      //    }
      //  }
      //}
    }
  }
  .competitor_list {
    display: flex;
    justify-content: center;
    gap: 24px;
    padding: 35px 0;
    > div {
      width: 533px;
      > h5 {
        font-family: Pretendard_bold;
        letter-spacing: -1px;
        color: #343434;
        font-size: 28px;
        span {
          color: #0078f1;
        }
      }
    }
  }
  .company_info_items {
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin-top: 33px;
  }
  .company_info_item {
    display: flex;
    align-items: center;
    width: 533px;
    height: 102px;
    border-radius: 15px;
    border: solid 0.8px #d8d8d8;
    background-color: #fff;
    position: relative;
    img {
      width: 63px;
      height: 63px;
      margin: 13px;
    }
    button {
      position: absolute;
      right: 17px;
      width: 104px;
      height: 40px;
      font-size: 14px;
      color: #fff;
      border-radius: 10px;
      background-image: linear-gradient(to left, #0d7, #0078f1);
    }
    > .item_text {
      line-height: 1.5;
      > p:nth-child(1) {
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #343434;
      }
      > p:nth-child(2) {
        font-size: 14px;
        width: 310px;
        @include text-ellipsis(1)
      }
      > .item_text_category {
        > p {
          display: inline-block;
          font-family: Pretendard_bold;
          padding: 3px 9px;
          margin-right: 7px;
          font-size: 12px;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
          border: 1px solid transparent;
          border-radius: 14px;
          background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to left, #0d7, #0078f1);
          background-origin: border-box;
        }
      }
    }
  }
  .search_company_list {
    padding: 25px;
    h5 {
      margin-left: calc((100% - 1030px) / 2); 
      font-family: Pretendard_bold;
      font-size: 28px;
      letter-spacing: -1px;
      color: #343434;
      //margin-left: 95px;
    }
    .company_info_items {
      align-items: center;
    }
    .company_info_item {
      width: 1043px;
      button {
        width: 131px;
        height: 40px;
        border-radius: 10px;
        background-color: #0078f1;
        background-image: none;
      }
    }
  }
}
