#MarketInfoCategory {
    //margin: auto;
    //width: 1280px;
    width:100% ;
    .title {
        font-size: 22px;
        font-family: Pretendard_bold;
        color: #343434;
    }
    .marketInfo_wrapper {
        display: flex;
        //width: 1280px;
        //width:100%;
        margin:0 50px 0 50px;
    }
    .marketInfo_section {
       //width: 1060px;
        width:100%;
    }
    .marketInfo_tab {
        display: flex;
        height: 60px;
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            border-bottom: 1px solid #d8d8d8;
        }
        .active {
            border-bottom: 3px solid #4593f5;
        }
    }
    .head {
        margin: 20px 0 6px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        //width: 1060px;
        width:80vw;
        //padding:0 px 0 50px;

        .search_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //width: 1060px;
            width:100%;
            column-gap: 10px;
            padding: 0 50px 0 30px;
            .search {
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                border: 2px solid #4593f5;
                border-radius: 20px;
                width: 270px;
                height: 40px;
            }
            input {
                padding-left: 5px;
                width: 201px;
                height: 24px;
                font-size: 14px;
                color: #343434;
                border: none;
            }
            input[type="text"]::placeholder {
                font-family: Pretendard_bold;
                font-size: 14px;
                line-height: 1.14;
                letter-spacing: -0.25px;
                color: #959595;
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 45px;
            font-size: 14px;
            font-family: Pretendard_extraBold;
            border-radius: 4px;

            &.btn_my {
                color: #4593f5;
                border: 2px solid #4593f5;
            }

            &.btn_write {
                color: #ffffff;
                background-image: linear-gradient(to right, #6bd3ff -4%, #00cd90 104%);
            }

            .icon_posting_pencil {
                width: 24px;
                height: 24px;
                background-size: contain;
            }
        }
    }

    .wrap_category {
        width: 100%;
        //max-width: 1060px;
        background-color: rgba(240, 240, 240, 0.2);
        padding: 10px;
    }

    .category {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 3px 1px #d8d8d8;
        margin: 0 40px 20px 0px;
        padding: 8px 10px;
        .category_b_title {
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 8px;
            //width: 960px;
            //width:100%;
            height: 50px;
            border-radius: 5px;
            background-color: #f0f0f0;
            .category_b_title_name {
                display: flex;
                align-items: center;
                i {
                    margin-right: 5px;
                }
            }
            p {
                font-size: 18px;
                font-family: Pretendard_bold;
                color: #343434;
            }
        }

        .category_s_list {
            margin: 10px 0 0 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: 6px;
            row-gap: 6px;

            li {
                padding: 10px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                //width:235px;
                width: calc(25% - 5px);
                height: 70px;
                font-size: 14px;
                font-family: Pretendard_extraBold;
                color: #343434;
                text-align: center;
                background-color: rgba(107, 211, 255, 0.2);
                border-radius: 5px;
                word-break: keep-all;
                cursor: pointer;
                //margin-right:5px;

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                span {
                    padding-left: 10px;
                }
            }
            li:hover {
                border-radius: 5px;
                background-image: linear-gradient(to right, #4593f5, #6bd3ff);
                color: white;
            }
            .not_data {
                background-color: rgba(240, 240, 240, 0.2);
            }
            .not_data:hover {
                background-image: inherit;
            }
        }
    }

    @media screen and (max-width: 850px) {
        padding-top: 80px;

        .title {
            margin-bottom: 20px;
            padding-left: 20px;
            font-size: 14px;
            font-family: Pretendard_extraBold;
            color: #1d334e;
        }

        .category {
            .category_b_title {
                border-bottom: 1px solid #d8d8d8;
            }

            .category_s_list {
                margin: 0;
                padding: 0;
                display: block;

                > li {
                    position: relative;
                    padding-left: 20px;
                    display: block;
                    width: 100%;
                    line-height: 40px;
                    text-align: left;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #d8d8d8;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 8px;
                        right: 16px;
                        width: 24px;
                        height: 24px;
                        background: url("../../../assets/images/mobile/icon_more_arrow_right_black.svg")
                        center;
                    }

                    p {
                        width: 40px;
                        height: 18px;
                        font-size: 10px;
                        font-family: Pretendard_extraBold;
                        line-height: 18px;
                        color: #ffffff;
                        text-align: center;
                        border-radius: 8px;
                        background-color: #aaaaaa;
                    }

                    &.not_data {
                        display: flex;
                        justify-content: left;
                        align-content: center;
                        column-gap: 15px;
                        color: #aaaaaa;
                    }
                }
            }
        }
    }
}
