#AdminAnnualFeeList {
  margin: auto;
  width: 1280px;
  padding-top: 45px;
  .list_header {
    font-family: Pretendard_bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
    p {
      font-size: 22px;
      color: #343434;
    };
    button {
      width: 150px;
      height: 32px;
      border-radius: 4px;
      background-color: #e92f2c;
      font-family: inherit;
      font-size: 16px;
      color: #FFFFFF;
    }
  }
  .annual_change_view {
    width: 100%;
    display: flex;
    font-family: Pretendard_bold;
    font-size: 16px;
    color: #343434;
    margin-bottom: 24px;
    > p {
      width: 50%;
      text-align: center;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 15px;
      cursor: pointer;
    };
  }

  .annual_list_table {
    th {
      background-color: #f0f0f0;
      font-family: Pretendard_bold;
      font-size: 14px;
      color: #343434;
      height: 46px;
    };
    th:nth-child(2), td:nth-child(2) {
      text-align: left;
    }
    th:nth-child(10), th:nth-child(12) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    tbody tr:hover {
      background-color: rgba(197, 223, 248, 0.5);
      cursor: pointer;
    }
    .button_review {
      width: 46px;
      height: 18px;
      background-color: rgba(255, 166, 39, 0.2);
      border-radius: 5px;
      font-family: Pretendard_bold;
      font-size: 10px;
      letter-spacing: 0.71px;
      color: #343434;
      z-index: 100;
    }
    .annual_receipt {
      p {
        font-family: Pretendard_extraBold;
        font-size: 12px;
        letter-spacing: -0.4px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 4px;
      }
      button {
        width: 74px;
        height: 18px;
        border-radius: 5px;
        background-color: rgba(240,240,240,0.8);
        font-family: Pretendard_bold;
        font-size: 10px;
        letter-spacing: 0.71px;
        color: #343434;
      }
    }
    .billing_button {
      button {
        width: 74px;
        height: 30px;
        border-radius: 5px;
        font-family: Pretendard_bold;
        font-size: 12px;
      }
    }
  }
}
