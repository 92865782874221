#SidebarRnd {
  padding-right: 30px;
  padding-top: 30px;
  > ul {
    > li {
      padding: 10px 0;
      a {
        color: #565656;
      }
      &.on {
        > a {
          color: #4593f5;
        }
      }
      > a {
        display: flex;
        align-items: center;
        > img {
          margin-right: 4px;
        }
        &.on {
          > a {
            color: #4593f5;
          }
        }
      }
      > ul {
        > li {
          padding: 4px 0 4px 10px;
          &.on {
            > a {
              color: #4593f5;
            }
          }
        }
      }
    }
  }
}
