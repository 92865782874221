#AnnualFeeReview {
  position: absolute;
  width: 480px;
  min-height: 530px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #343434;
  padding: 46px 35px;
  h2 {
    font-family: Pretendard_bold;
    font-size: 22px;
  }
  p {
    display: flex;
    align-items: center;
  }
  .annual_fee_review_header {
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-family: Pretendard_semiBold;
      font-size: 12px;
      color: #606060;
      i {
        margin-right: 7px;
      }
    }
  }
  .annual_fee_review_body {
    margin-top: 35px;
    > div {
      display: flex;
    }
    .review_manage_number {
      border-bottom: solid 1px #d8d8d8;
      padding: 10px 0;
      p:nth-child(1) {
        font-family: Pretendard_extraBold;
        font-size: 14px;
        margin-right: 20px;
      }
      p:nth-child(2) {
        font-family: Pretendard_semiBold;
        font-size: 14px;
        letter-spacing: 0.3px;
      }
    }
    .annual_fee_list {
      margin-top: 11px;
      flex-direction: column;
      .title {
          font-family: Pretendard_extraBold;
          font-size: 14px;
      }
      > div {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        margin-top: 12.5px;
      }
      .annual_fee_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 102px;
        height: 51px;
        border-radius: 8px;
        border: solid 1px #d8d8d8;
        padding: 8px 12px;
        p {
          font-family: Pretendard_semiBold;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.87);
          display: inherit;
          align-items: inherit;
        }
      }
      .highlight {
        border: solid 1px #4593f5;
      }
    }
  }
}
