@import "src/include";

#MarketInfoKeywordSearch {
  padding-bottom: 50px;
  .input_wrapper {
    width:100%;
    display: flex;
    //justify-content: center;
    align-items: center;
    margin-top: 20px;
    p {
      min-width: 110px;
    }
    .search {
      //width: 750px;
      width:100%;
      height: 42px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      input[type="text"] {
        border: none;
        height: 30px;
        width: 700px;
      }
    }
    .btn_marketinfo_report {
      height: 42px;
      margin-left: 15px;
      width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 4px;
      border: solid 1px #d8d8d8;
      background-color: #FFFFFF;
      color: #343434;
      i {
        margin-right: 5px;
      }
    }
  }
  .marketInfo_list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
    @media screen and (min-width: 1920px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .marketInfoMain_item {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    width:90%;
    height: 285px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    background-color: #fff;
    .chart_isComplete {
      padding: 2px;
      font-size:12px;
      width: 26px;
      border-radius: 4px;
      background-color: #ee5253;
      color: #FFFFFF;
      font-family: Pretendard_semiBold;
    }
    img {
      width: 100%;
      height: 140px;
    }

    .info {
      display: flex;
      align-items: center;
      padding: 0 3px;
      border-bottom: 1px solid black;
      h2 {
        width: 100%;
        font-size: 14px;
        font-family: Pretendard_bold;
        min-height: 40px;
        line-height: 1.43;
        color: #343434;
        @include text-ellipsis(2);
        word-break: break-word;
      }
    }
    .gpt_summary {
      font-size: 14px;
      @include text-ellipsis(3);
      word-break: break-word;
    }
    .etc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      .etc_btn {
        display: flex;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-right: 5px;
      }
      .content_btn {
        width: 85px;
        height: 24px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #4593f5;
        border-radius: 14px;
        background-color: rgba(107,211,255,0.2);
      }
      .chart_btn {
        width: 85px;
        height: 24px;
        font-family: Pretendard_extraBold;
        font-size: 12px;
        color: #4593f5;
        border-radius: 14px;
        border: solid 0.8px #f0f0f0;
        background-color: rgba(240, 240, 240, 0.3);
      }
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}
