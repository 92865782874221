#Waiting {
    width: 100vw;
    height: 100vh;
    background-color: #f7faff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 100px;
    > p {
        line-height: 1.5;
        font-size: 22px;
        text-align: center;
    }
    > button {
        width: 300px;
        height: 55px;
        padding: 10px 0;
        background-color: #1474ff;
        color: white;
        border-radius: 5px;
        font-size: 20px;
    }
}
