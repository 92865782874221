
#InventionListView {
    margin: auto;
    margin-top: 36px;
    width: 1280px;

    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 22px;
            font-family: Pretendard_extraBold;
        }

        .btns {
            display: flex;
            align-items: center;

            > button {
                padding: 0 8px;
                min-width: 132px;
                height: 32px;
                font-size: 15px;
                color: #ffffff;
                text-align: center;
            }

            button+button {
                margin-left: 8px;
            }

            .btn_excel_download {
                margin-right: 6px;
                background-color: #96c719;
            }
            .btn_tab_setting {
                font-size: 16px;
            }
            .btn_invention_form_upload {
                background-color: var(--color-Web-Orange);
            }
            .btn_invention_form_download {
                background-color: #05ac9e;
            }
            .btn_invention_add {
                background-color: #4593f5;
            }
            .btn_tab_setting, .btn_tab_setting_save {
                background-color: #e92f2c;
            }

            .ToggleButtonWithSpan {
                margin-right: 31px;
            }
        }
    }

    > .wrap_tab_btns {
        padding: 0 25px;

        .checkbox {
            margin-right: 25px;

            .value {
                width: auto;
                white-space: pre;
            }
        }
    }

    .wrap_list {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 600px;

        tr {
            cursor: pointer;
        }

        tbody {
            tr:hover {
                background-color: rgba(0, 149, 174, 0.1);
                transition-duration: 0.5s;
                cursor: pointer;
            }
        }
    }
}
