#HoldingsComp {
  button {
    border-radius: 24px;
    padding: 6px 12px;
  }
  .download {
    color: #fff;
    background-color: var(--color-Bright-Blue);
  }
  .updated {
    color: var(--color-Bright-Blue);
    background-color: #f0f7fe;
  }
  .go_network {
    background-color: #f2f4f6;
  }
}
