@import "../../../include";

#CyberSignup {
    @import "Common.scss";

    .form {
        margin: 39px auto 27px auto;
    }

    .btn {
        margin: 0 auto 13px auto;
    }

    .btn_no {
        background-color: darkgrey;
        pointer-events: none;
    }

    .alert_msg {
        margin-top: 8px;
        font-size: 12px;
        font-family: Pretendard_bold;
        line-height: 18px;
        color: #ffa600;
    }

    .checked {
        background-color: #e8e8e8 !important;
        pointer-events: none;
    }
}
