#ResearchTechRequestEdit {
  margin: auto;
  display: flex;
  //max-width: 1280px;
  //padding-bottom: 100px;
  width:100%;
  padding: 0 50px 100px 50px;
  .edit_wrapper {
    width:100%;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
  }
  h2 {
    font-family: Pretendard_bold;
    font-size: 22px;
    color: #343434;
    span {
      font-family: Pretendard_semiBold;
      font-size: 14px;
    }
  }

  .contents {
    .page_title {
      margin-bottom: 20px;
      font-size: 22px;
      font-family: Pretendard_bold;
      line-height: 25px;
    }

    .field:nth-child(3) {
      align-items: flex-start;
    }
    .field {
      display: flex;
      align-items: center;
      margin-top: 25px;

      p {
        min-width: 110px;
        font-family: Pretendard_extraBold;
        font-size: 14px;
      }

      input {
        //width: 850px;
        width:100%;
        height: 42px;
        border: 1px solid var(--color-Alto);

        &::placeholder {
          color: var(--color-Dusty-Gray);
        }
      }

      .inputs {
        width:100%;
        display: flex;
        align-items: center;
        column-gap: 4px;

        input {
          //width: 210px;
          width:100%;
        }
      }

      textarea {
        padding: 15px;
        //width: 850px;
        width:100%;
        height: 246px;
        border: 1px solid var(--color-Alto);
        resize: none;

        &::placeholder {
          color: var(--color-Dusty-Gray);
        }
      }

      input,
      textarea {
        font-size: 14px;
      }
    }
    .edit_ex {
      margin-top: 5px;
      text-align: right;
      font-family: Pretendard_bold;
      color: #4593f5;
      text-shadow: 0 2px 1px rgba(0, 0, 0, 0.02);
      font-size: 12px;
    }
  }

  .btn_next {
    margin: 60px auto 0 auto;
    display: block;
    width: 101px;
    height: 40px;
    font-family: Pretendard_bold;
    font-size: 14px;
    color: #ffffff;
    border-radius: 4px;
    background-color: #4593f5;

    &::placeholder {
      color: var(--color-Dusty-Gray);
    }
  }
}
