#Price {
  width: 100%;
  min-height: 100vh;
  background-color: #f7faff;
  padding: 55px 0;
  > header {
    width: 1350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .header_left {
      .page_title {
        display: flex;
        align-items: center;
        font-family: Pretendard_medium;
        font-size: 23px;
        margin-bottom: 20px;
        color: #969695;
        img {
          margin-right: 20px;
        }
      }
      .counter_wrapper {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
    .header_right {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #969695;
      font-family: Pretendard_medium;
      cursor: pointer;
    }
  }
  > section {
    width: 1350px;
    margin: 50px auto;
    .section_header {
      display: flex;
      > p {
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Pretendard_bold;
        font-size: 18px;
        margin-left: 58px;
      }
    }
    .tab {
      display: flex;
      width: 925px;
      > div {
        flex: 1;
      }
      &_left {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          flex: 1;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 12px 12px 0 0;
          background-color: #eee;
          cursor: pointer;
          span {
            font-family: Pretendard_bold;
            font-size: 18px;
            color: #4a4a4a;
          }
        }
        .active {
          background-color: #fff;
        }
        div:nth-of-type(2) {
          span {
            background: linear-gradient(to left, #22bd7f 66%, #0186ee 18%);;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
        p {
          margin: 0 30px;
          color: #4a4a4a;
          letter-spacing: -0.8px;
          font-size: 20px;
          font-family: Pretendard_Bold;
        }
      }
    }
    > .section {
      display: flex;
    }
    .section_left {
      width: 925px;
      min-height: 750px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0 0 30px 0 rgba(189, 188, 188, 0.3);
      background-color: #fff;
      padding: 30px 0;
      border-radius: 0 20px 20px 20px;
      .subs_package_title {
        color: #4a4a4a;
        letter-spacing: -0.34px;
        font-size: 18px;
        font-family: Pretendard_semiBold;
        text-align: center;
        margin: 0 0 19px 0;
      }
      .service_package_title {
        color: #4a4a4a;
        margin-bottom: 10px;
        > h5 {
          font-size: 18px;
          font-family: Pretendard_semiBold;
        }
        > p {
          text-align: center;
          margin-top: 10px;
          i {
            width: 24px;
            height: 20px;
            background-size: contain;
            background-position: center center;
          }
          span {
            font-size: 14px;
            font-family: Pretendard_Regular;
            letter-spacing: -0.56px;
            color: #4a4a4a;
          }
        }
      }
      > div {
        flex: 1;
        padding: 0 30px;
      }
      > div:nth-child(1) {
        border-right: 1px solid #707070;
      }
      .sub_label {
        width: 402px;
        height: 94px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;
        background-image: inherit;
        color: #4a4a4a;
      }
    }
    .section_right {
      margin-left: 58px;
    }
    .cost_wrapper {
      width: 378px;
      padding: 42px 14.6px 34.3px 23.5px;
      border-radius: 20px;
      box-shadow: 0 0 30px 0 rgba(189, 188, 188, 0.3);
      background-color: #fff;
      > .cost {
        min-height: 250px;
        > h2 {
          display: inline-block;
          background: linear-gradient(to left, #22bd7f 66%, #0186ee 18%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Pretendard_bold;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      .total_cost {
        padding-top: 23px;
        border-top: 1px solid #1474ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Pretendard_bold;
        > p:nth-of-type(1) {
          font-size: 20px;
          letter-spacing: -0.1px;
          color: #9e9e9e;
        }
        > p:nth-of-type(2) {
          font-size: 25px;
          letter-spacing: -0.5px;
          text-align: right;
          color: #4a4a4a;
        }
      }
    }
    .button_wrapper {
      margin-top: 37px;
      > button {
        width: 378px;
        height: 69px;
        border-radius: 10px;
        box-shadow: 0 0 30px 0 rgba(189, 188, 188, 0.3);
        background-color: #1474ff;
        font-family: Pretendard_bold;
        font-size: 27px;
        letter-spacing: -0.14px;
        color: #fff;
      }
    }
    .choice_wrapper {
      > div {
        min-height: 300px;
        > h2 {
          display: inline-block;
          background: linear-gradient(to left, #22bd7f 66%, #0186ee 18%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Pretendard_bold;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
.custom-text {
  color: #0078F0;
  font-family: Pretendard_bold;
  font-size: 16px;
}
