#PopupComment {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 51px 61px 26px 61px;
    width: 780px;
    height: 410px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;

    .header {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 22px;
        }


        .btns {
            display: flex;
            align-items: center;

            .btn_edit {
                margin-right: 30px;
                width: 64px;
                line-height: 32px;
                font-size: 16px;
                text-align: center;
                color: #ffffff;
                background-color: #96c719;
            }
        }
    }

    .comment {

        h2 {
            margin-bottom: 17px;
            font-size: 14px;
            font-family: Pretendard_bold;
        }

        p {
            overflow-y: scroll;
            width: 647px;
            height: 191px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.87);
        }

        textarea {
            width: 647px;
            height: 191px;
            padding: 6px 16px;
            border: solid 1px #d8d8d8;
            resize: none;
        }
    }

    .btn_edit_request {
        margin: 15px auto 0 auto;
        display: block;
        width: 132px;
        height: 32px;
        font-size: 16px;
        color: #ffffff;
        background-color: var(--color-Alizarin-Crimson);
    }
}
