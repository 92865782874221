@import "src/mixin";

#ApprovalList {
    margin: 36px auto 0;
    max-width: 1080px;

    @include mobile {
        margin-top: 80px;
    }

    .head {
        margin-bottom: 30px;
        font-size: 22px;
        font-family: Pretendard_extraBold;
    }

    table {
        margin-bottom: 30px;

        tbody {
            tr:not(.not_styled) {
                cursor: pointer;
            }
        }
    }
}
