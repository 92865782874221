.radio_label {
  display: block;
  width: 402px;
  height: 94px;
  padding: 22px 0 0 25px;
  position: relative;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 17px;
  background-image: linear-gradient(171deg, #a6aab0 17%, #829bb8 45%, #5087c2 72%);
  h2 {
    display: flex;
    align-items: center;
    font-size: 22px;
    font-family: Pretendard_Medium;
    letter-spacing: -0.42px;
  }
  h5 {
    font-size: 15px;
    letter-spacing: -0.15px;
    font-family: Pretendard_Light;
    margin-top: 6px;
  }
  input[type="radio"], input[type="checkbox"] {
    position: absolute;
    top: 22px;
    right: 25px;
    width: 20px;
    height: 20px;
  }
}

.checked {
  background-image: linear-gradient(171deg, #22b691 17%, #258fff 72%);
}
