#InventorStepOne {
  margin: 0 auto;
  width: 1280px;
  .step_box {
    margin: 30px auto;
    padding: 0 63px;
    border-radius: 20px;
    background-color: rgba(241, 241, 241, 0.3);
    width: 1004px;
    height: 204px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      text-align: center;
      > span {
        font-size: 16px;
        color: #606060;
        margin-bottom: 4px;
      }
      > p {
        font-size: 18px;
        line-height: 1.25;
      }
    }
  }
  .inventor_info_box {
    width: 1004px;
    margin: 30px auto;
    &_title {
      > h5 {
        font-size: 22px;
        color: #343434;
      }
      > p {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606060;
        i {
          margin-right: 7px;
        }
      }
    }
    .input_box {
      margin-top: 20px;
      border-radius: 15px;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
      border: solid 1px #e7e7e7;
      background-color: #fff;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      padding: 40px;
      > div {
        display: flex;
        align-items: center;
        p {
          min-width: 100px;
          font-size: 14px;
        }
        input[type="text"] {
          width: 250px;
          border: none;
          border-bottom: 1px solid #d8d8d8;
        }
      }
      > div:first-child {
        grid-column: 1 / span 2;
      }
      > div:last-child {
        grid-column: 1 / span 2;
        input[type="text"] {
          width: 100%;
        }
      }
    }
  }
  button {
    display: block;
    margin: 0 auto;
    width: 93px;
    height: 45px;
    border-radius: 4px;
    background-color: #e92f2c;
    font-size: 16px;
    color: #fff;
  }
}
