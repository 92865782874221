@import "src/include";

.docTradeMyTransactionSaleRow{
	height: 50px;
	color: rgba(0, 0, 0, 0.87);
	.num-info{
		color: #343434;
	}
	.transaction-info{
		color: #343434;
	}
	.transaction-info{
		text-align: left;
		padding-left: 26px;
		&_text{
			max-width: 430px;
			height: 36px;
			line-height: 36px;
			@include text-ellipsis(1);
		}
	}
	.price-info{
		.style{
			padding-left: 10px;
		}
	}
	.detail-info{
		&_link{
			position: relative;
			display: inline-block;
			width: 40px;
			height: 40px;
			text-indent: -9999px;

			&::after{
				position: absolute;
				content: '';
				width: 25px;
				height: 25px;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				background: url(../../../assets/images/common/icon-arrow-next-bk.svg) no-repeat 50% 50%;
				background-size: cover;
			}
		}
	}
}