@import "src/include.scss";

.header_base {
  width: 1280px;
  height: 180px;
  background-color: rgba(107,211,255,0.14);
  padding: 36px;
  margin: 0 auto;
  h2 {
    font-family: Pretendard_semiBold;
    font-size: 32px;
    @include text-gradient-green;
    margin-bottom: 4px;
  }
  p {
    font-family: Pretendard_medium;
    line-height: 1.38;
    letter-spacing: -1px;
    color: #343434;
  }
}
