#AllDownload {

  margin-left: 25px;

  div {
    margin-top: 10px;
  }

  button {
    width: 150px;
    height: 40px;
  }

  .header_wrap {
    display: flex;
    column-gap: 25px;
  }

  .select_company {
    display: flex;

    .company_list {
      padding: 10px;
      overflow-y: auto;
      border: #999999 1px solid;
      width: 600px;
      height: 300px;

      .item {
        cursor: pointer;
        padding: 5px;
        box-shadow: 0 0 12px rgb(0 0 0 / 10%);

        div {
          margin-top: 0px !important;
        }
      }
    }

    .checked_wrap {
      margin-left: 15px;

      .checked_company_list {
        padding: 10px;
        overflow-y: auto;
        border: #999999 1px solid;
        width: 600px;
        height: 300px;

        .item {
          cursor: pointer;
          padding: 5px;
          box-shadow: 0 0 12px rgb(0 0 0 / 10%);

          div {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}