#Terms {
  margin: auto;
  padding-top: 29px;
  width: 710px;

  h2 {
    margin-bottom: 46px;
    font-size: 24px;
    font-family: Pretendard_bold;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }

  p {
    // padding: 20px;
    // max-height: 600px;
    // overflow-x: hidden;
    // overflow-y: scroll;
    // font-size: 14px;
    // line-height: 18px;
    // color: rgba(0, 0, 0, 0.95);
  }

  table {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-top: 10px;
    tr {
      &:last-child {
        border-bottom: none;
      }
    }
    th {
      color: #444;
      font-size: 14px;
      width: 80px;
      background-color: #f7f7f7;
      padding-left: 14px;
      &.long {
        width: auto;
      }
    }
    td {
      color: rgba(102, 102, 102, 0.8);
      font-size: 14px;
      text-align: left;
      font-family: "Pretendard_Regular";
      padding-left: 14px;
      word-break: keep-all;
    }
  }

  .btn_confirm {
    margin: 50px auto 47px auto;
    display: block;
    width: 208px;
    height: 42px;
    font-size: 16px;
    font-family: Pretendard_extraBold;
    color: #ffffff;
    border-radius: 21px;
    background-color: var(--color-Web-Orange);
  }

  .summary {
    background: #faf9f6;
    font-size: 14px;
    padding: 30px 20px;
    margin-bottom: 30px;
    line-height: 1.5384;
    font-family: "Pretendard_Regular";
    > strong {
      display: block;
      color: #000;
    }
  }

  .textlist {
    > ul {
      > li {
        margin-top: 2rem;
        font-size: 14px;
        line-height: 1.5384;
        font-family: "Pretendard_Regular";
        &:first-child {
          margin-top: 0;
        }
        h3 {
          margin-bottom: 14px;
          font-size: 14px;
          font-family: "Pretendard_Bold";
          color: #000;
        }
        p {
          margin-top: 10px;
        }
        .list {
          > li {
            margin: 5px 0 10px;
          }
          .inner_list {
            > li {
              margin-top: 10px;
            }
          }
        }
      }
    }
    .inner_list {
      li {
        padding-left: 14px;
      }
    }
  }

  @media screen and (max-width: 850px) {
    margin: auto;
    padding-top: 92px;
    width: 90vw;

    h2 {
      margin-bottom: 32px;
      font-size: 24px;
      font-family: Pretendard_extraBold;
      line-height: 30px;
      color: #443018;
    }

    p {
      height: 327px;
      padding: 18px 23px;
      font-size: 12px;
      font-family: Pretendard_bold;
      color: #747474;
      border-radius: 4px;
      background-color: #f0f0f0;
    }
  }
}
