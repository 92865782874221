@import "src/mixin";

#SignupInputHead {
  background-color: var(--color-Alto-opacity-01);

  @include mobile {
    padding-top: 95px;
    background-color: transparent;
  }

  .wrap {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1280px;
    height: 180px;
    row-gap: 16px;

    @include mobile {
      height: auto;
      text-align: center;
    }

    h2 {
      font-size: 30px;
      font-family: Pretendard_extraBold;
      color: var(--color-Cloud-Burst);
    }

    p {
      font-size: 16px;
      font-family: Pretendard_bold;
      color: #343434;
    }
  }
}
