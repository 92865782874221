#ChartTest {
  width: 700px;
  background: #f7f7f7;
  padding: 46px 20px 46px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
  .chart_header {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Pretendard_bold;
    span:nth-child(1) {
      font-size: 23px;
      color: #57330e;
      margin-bottom: 6px;
      text-align: left;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #6c6c6c;
      text-align: right;
    }
  }
  .tab_count,.tab_payment {
    background-color: #fff;
    border-radius: 8px;
  }

  .chart_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 566px;
    gap: 20px;
    align-content: center;
    margin-top: 20px;

    .wrap_chart {
      position: relative;
      padding: 16px 20px;
      width: 283px;
      height: 245px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
      background-image: linear-gradient(to right, #ff7a00, var(--color-Web-Orange));
      border-radius: 8px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;

        h2 {
          font-size: 20px;
          font-family: Pretendard_extraBold;
          line-height: 24px;
          color: #ffffff;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 85px;
          font-size: 12px;
          font-family: Pretendard_bold;
          color: #ffffff;
        }
      }

      .chart {
        position: relative;

        canvas {
          position: relative;
          left: -20px;
          top: 10px;
        }

        .total_cnt {
          position: absolute;
          top: 48%;
          left: 33px;
          width: 90px;
          font-size: 12px;
          color: #ffffff;
          text-align: center;

          span {
            font-size: 24px;
            font-family: Pretendard_extraBold;
          }
        }

        .add_label,
        .apply_label,
        .giveup_label {
          position: absolute;
          top: 10%;
          left: 182px;
          justify-content: center;
          align-items: center;
          min-width: 70px;
          line-height: 24px;
          white-space: pre;
          font-size: 12px;
          font-family: Pretendard_extraBold;
          color: var(--color-Web-Orange);
          text-align: center;
          background: #ffffff;
          z-index: 2;
        }

        .add_label:after,
        .apply_label:after,
        .giveup_label:after {
          content: "";
          position: absolute;
          bottom: 29px;
          left: 10px;
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-top-color: #ffffff;
          border-bottom: 0;
          margin-left: -20px;
          margin-bottom: -20px;
          transform: rotate(90deg);
        }

        .apply_label {
          top: 35%;
          color: #ffa012;
          background-color: #ffd4a6;
        }

        .apply_label:after {
          border-top-color: #ffd4a6;
        }

        .giveup_label {
          top: 60%;
          color: #ffffff;
          background-color: #ffa94d;
        }

        .giveup_label:after {
          border-top-color: #ffa94d;
        }
      }
    }

    .tab_payment, .tab_count {
      padding: 9px 22px;
      height: 245px;
      .header {
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;

        a {
          margin-left: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 112px !important;
          height: 24px;
          font-size: 12px;
          background: url("../../../assets/images/common/icon-more-black.svg") no-repeat right;
        }
      }

      p {
        font-size: 14px;
        color: #000000;
        text-align: center;
      }
    }
  }
}
.dataVoucher {
  margin-top: 20px;
  button {
    width: 130px;
    height: 40px;
    color: black;
    background-color: #D5EAF3;
    border-radius: 4px;
  }
}

