.package_items {
  display: grid;
  gap: 10px 8px;
  grid-template-columns: repeat(4, 1fr);
  margin: 18px 0 30px 0;
  .package_item {
    border-radius: 14px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    height: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
      width: 55px;
      height: 56px;
      background-size: contain;
    }
    span {
      font-family: Pretendard_Medium;
      font-size: 11px;
      margin-top: 4px;
    }
  }
}
