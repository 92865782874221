#HoldingsLevel {
  .chart_box {
    position: relative;
  }
  .chart_label {
    > span {
      display: inline-block;
      font-size: 14px;
      color: #555;
    }
  }
}
