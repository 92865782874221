#GroupwareVacation {
  //width: 1280px;
  margin: 0 auto;
  display: flex;
  @media screen and (max-width: 850px){
    width: 1500px;
  }
  .vacation_wrapper {
    width: 100%;
    background-color: #fff;
    background-color: rgba(216, 216, 216, 0.28);
    padding: 37px 41px;

    >div {
      margin-bottom: 40px;

      h5 {
        font-size: 22px;
        letter-spacing: -0.25px;
        color: #343434;
        margin-bottom: 23px;
      }
    }
  }

  .vacation_grid_my,
  .vacation_grid_apply {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    >div {
      width: 295px;
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 #d8d8d8;
      background-color: #fff;
      position: relative;
    }
  }

  .link_detail_btn {
    width: 140px;
    height: 32px;
    border-radius: 16px;
    background-color: #0078f1;
    color: #FFFFFF;
    position: absolute;
    bottom: 30px;
  }

  .vacation_grid_my {
    >div {
      width: calc(100% - 20px);
      height: 355px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0;
      margin-right: 20px;

      >p {
        font-family: Pretendard_bold;
        font-size: 20px;
        color: #343434;
        margin-bottom: 30px;
      }
    }
  }

  .vacation_remain {
    text-align: center;

    p {
      margin-bottom: 10px;
    }

    p:nth-of-type(1) {
      font-size: 16px;
      letter-spacing: -0.8px;
      color: #606060;

      span {
        font-family: GmarketSansTTF_bold;
        font-size: 26px;
        color: #0078f1;
        margin-right: 10px;
      }
    }

    p:nth-of-type(2) {
      font-size: 14px;
      color: #606060;
    }
  }

  .percentage_bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;

    >div {
      width: 350px;
      height: 8px;
      border-radius: 5px;
      background-color: rgba(0, 120, 241, 0.2);

      >div {
        height: 8px;
        border-radius: 5px;
        background-image: linear-gradient(to left, #38c4da, #0078f1);
      }
    }

    >p {
      font-family: GmarketSansTTF_bold;
      font-size: 16px;
      color: #343434;

      span {
        color: #0078f1;
      }
    }
  }

  .vacation_usage {
    width: 100%;
    padding: 0 45px;
    margin-top: 10px;

    >div {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      p:nth-of-type(1) {
        font-size: 16px;
        color: #343434;
      }

      p:nth-of-type(2) {
        font-size: 16px;
        letter-spacing: -0.8px;
        color: #606060;

        span {
          font-family: GmarketSansTTF_bold;
          color: #0078f1;
          margin-right: 4px;
        }
      }
    }
  }

  .vacation_approval {
    >div {
      width: 100%;
      display: flex;
      padding: 0 25px;
      justify-content: space-between;
      margin-bottom: 15px;

      >p:nth-of-type(1) {
        font-size: 14px;
        letter-spacing: -0.7px;
        color: #959595;
      }

      >p:nth-of-type(2) {
        font-size: 16px;
        color: #343434;
        width: 80px;
        text-align: left;
      }

      .approval_circle {
        width: 60px;
        font-size: 12px;
        color: #343434;
        border-radius: 12px;
        text-align: center;
        padding: 2px 0;
      }

      .return {
        background-color: rgba(255, 212, 56, 0.4);
      }

      .ing {
        background-color: rgba(47, 244, 78, 0.35);
      }

      .done {
        background-color: rgba(0, 120, 241, 0.2)
      }

      .used {
        background-color: rgba(52, 52, 52, 0.2)
      }
    }
  }

  .vacation_people {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    > div {
      margin-bottom: 40px;
      padding: 0 25px;

      > p {
        font-family: Pretendard_bold;
        font-size: 18px;
        color: #343434;
      }

       > div {
        display: flex;
         margin: 10px 0;

         > p {
           font-size: 14px;
           letter-spacing: -0.7px;
           line-height: 1.3;
           min-width: 100px;
           color: #959595;
        }
         .vacatior_list {
           display: flex;
           flex-direction: column;
           width: 100%;
           > div {
             display: flex;
             align-items: center;
             justify-content: space-between;
             padding: 0 40px;
             margin-bottom: 10px;
             p {
               display: flex;
               align-items: center;
               height: 24px;
               i {
                 margin-right: 20px;
               }
             }
           }
           @media screen and (max-width: 1550px) {
             > div {
               flex-direction: column;
             }
           }
         }
      }
    }
  }

  .vacation_grid_apply {
    row-gap: 15px;

    >div {
      width: calc(100% - 20px);
      height: 140px;
      padding: 23px 15px 0 32px;
      position: relative;

      >p {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-family: Pretendard_bold;
        letter-spacing: -1.1px;
        color: #343434;

        i {
          margin-right: 5px;
        }
      }

      .item_head_title {
        font-size: 16px;
        font-family: Pretendard_regular;
        color: #343434;
        margin-top: 15px;
      }

      .item_sub_title {
        font-size: 14px;
        font-family: Pretendard_regular;
        letter-spacing: -0.7px;
        color: #959595;
        position: absolute;
        bottom: 25px;
        left: 25px;
      }

      button {
        width: 80px;
        height: 30px;
        border-radius: 16px;
        background-image: linear-gradient(to right, #0078f1, #01bdb4);
        color: #FFFFFF;
        position: absolute;
        bottom: 21px;
        right: 15px;
      }
    }
  }
}
