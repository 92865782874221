#ResearchTechAddPatent {
  width: 480px;
  height: 300px;
  z-index: 10;
  background-color: #fff;
  border: solid 1px #d8d8d8;
  padding: 46px 34px 0 34px;
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  .add_patent_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: Pretendard_bold;
      font-size: 22px;
      color: #343434;
    }
  }
  .sub_title {
    font-family: Pretendard_semiBold;
    font-size: 14px;
  }
  .input_wrap {
    margin-top: 40px;
    width: 410px;
    height: 42px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    display: flex;
    align-items: center;
    input[type="text"] {
      width: 90%;
      height: 18px;
      border:none;
    }
  }
  .btn_wrapper {
    display: flex;
    justify-content: center;
    button {
      width: 84px;
      height: 40px;
      border-radius: 4px;
      background-color: #4593f5;
      color: white;
      margin-top: 46px;
    }
  }
}
