@import "../../../include";

#PatentGrade {
    margin: auto;
    padding-top: 48px;
    width: 910px;

    .header {
        margin-bottom: 55px;

        .title {
            font-size: 22px;
            font-family: Pretendard_bold;
            line-height: 25px;
        }

        .patent_info {
            margin-top: 3px;
            display: flex;
            align-items: center;
        }
    }

    .inner_txt {
        h2 {
            margin-bottom: 5px;
            font-size: 20px;
            font-family: Pretendard_bold;
            line-height: 30px;
        }

        p {
            margin-bottom: 10px;
            font-size: 16px;
            color: #343434;
        }
    }

    .wrap_box {
        margin-bottom: 30px;
        display: flex;
        column-gap: 10px;

        .one-third {
            padding: 18px 21px 16px 19px;
            display: flex;
            column-gap: 18px;
            width: 298px;
            height: 200px;

            .inner {
                margin-top: 7px;
            }
        }

        .two-third {
            padding: 18px 15px 86px 26px;
            width: 602px;
            height: 200px;

            > h2 {
                margin-bottom: 25px;
                font-family: Pretendard_bold;
            }

            ul {
                display: flex;
                align-items: center;
                column-gap: 15px;

                li:first-child {
                    width: 66px;
                    text-align: left;
                }

                li {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                    text-align: center;
                }

                li:last-child {
                    white-space: pre;
                }
            }

            ul.grade {

                li {
                    font-family: Pretendard_bold;
                    @include border-radius(50%);
                }

                li:last-child {
                    margin-left: 10px;
                }
            }

            ul+ul {
                margin-top: 7px;
            }
        }
    }

    .box {
        @include border-radius(5px);
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #e7e7e7;
        background-color: #ffffff;

        .inner {
            display: inline-block;

            .circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 120px;
                font-size: 46px;
                font-family: Pretendard_extraBold;
                @include border-radius(50%);
            }

            p {
                margin-top: 9px;
                font-size: 14px;
                font-family: Pretendard_bold;
                line-height: 30px;
                text-align: center;
            }
        }

        .circle_grade {
            li {
                padding: 8px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 115px;
                line-height: 30px;
                border-bottom: 1px solid #e7e7e7;

                h2 {
                    font-size: 14px;
                    font-family: Pretendard_bold;
                }

                p {
                    font-size: 16px;
                    color: #343434;
                }
            }

            li:first-child {
                padding-top: 0;
            }
        }
    }

    .now_grade {
        padding: 19px;
        display: flex;
        align-items: center;
        column-gap: 18px;
        width: 298px;

        img {
            margin-left: 40px;
        }
    }
}
