#HoldingsStatic {
  .chart_box {
    width: 100%;
  }
  .set {
    position: relative;
  }
  .tabs {
    display: flex;
    margin-bottom: 36px;
    align-items: center;
    justify-content: center;
    button {
      padding: 10px 20px;
      margin-right: 10px;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid var(--color-Bright-Blue);
      border-radius: 5px;
      border-radius: 24px;
      &.active {
        background-color: var(--color-Bright-Blue);
        color: white;
        border-color: var(--color-Bright-Blue);
      }
    }
  }
  .selectors {
    display: flex;
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: 0;
    select {
      width: 160px;
      padding: 10px;
      margin-right: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #f0f0f0;
      &:focus {
        outline: none;
        border-color: var(--color-Bright-Blue);
      }
    }
  }
}
