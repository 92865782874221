#HoldingsInvest {
  .part {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
  .invest_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    > li {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      align-items: center;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
      > i {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      .invest {
        > strong {
          display: block;
          font-size: 22px;
          font-family: "Pretendard_extraBold";
          margin-bottom: 10px;
        }
        > p {
          font-family: "Pretendard_regular";
        }
      }
    }
  }
}
