#CompetitorSearchPop {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 999;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    border-bottom: 1px solid var(--color-Dusty-Gray);
    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--color-Dusty-Gray);
    }
    > p {
      margin-right: 33px;
    }
  }
  .fields {
    > ul {
      > li {
        display: flex;
        align-items: center;
        .field_name {
          min-width: 120px;
        }
        & + li {
          margin-top: 24px;
        }
        .field {
          flex: auto;
        }
        select {
          width: 100%;
          background-color: var(--color-Light-Blue);
          padding: 10px;
          border: 1px solid var(--color-Light-Gray);
          border-radius: 4px;
        }
      }
    }
  }
  .btn_box {
    border-top: 1px solid var(--color-Dusty-Gray);
    text-align: center;
    padding: 20px;
    > button {
      font-size: 18px;
      border-radius: 10px;
      padding: 20px 30px;
    }
  }
}
