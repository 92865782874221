// Breakpoints
$breakpoint-mobile: 335px;
$breakpoint-tablet: 850px;
$breakpoint-desktop: 1024px;

// Mobile common left and right padding
:root {
  --left-right-padding: 10px;
  --mobile-header-height: 60px;
  --mobile-min-content-height: calc(100vh - 70px);
}
