#CompetitorSearch {
  padding: 40px 0;
  strong {
    font-family: "Pretendard_ExtraBold";
  }
  .content_box {
    padding: 0 30px;
  }
  .dimmed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 10;
  }
  .top_txt {
    font-size: 28px;
    margin-bottom: 30px;
    display: block;
  }
  .sch_box {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    .sch_tit {
      font-family: "Pretendard_Regular";
      margin-right: 40px;
      width: 210px;
    }
    > button {
      width: 100px;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
  .keyword {
    width: 100%;
    border: 2px solid var(--color-Bright-Blue);
    border-radius: 20px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    > input {
      width: 100%;
      border: none;
      border-radius: 20px;
    }
    > button {
      background-color: transparent;
    }
  }
  .comp_list {
    .num {
      display: flex;
      align-items: center;
      font-size: 28px;
      > button {
        border-radius: 10px;
      }
    }
  }
  table {
    thead {
      position: sticky;
      top: 0;
    }
    td {
      padding: 6px;
      cursor: pointer;
    }
  }
  .comp_frame {
    width: 96%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 20px;
    .close {
      text-align: right;
    }
  }
}

.tag_list {
  display: flex;
  flex-wrap: wrap;
  .tag {
    padding: 8px 16px;
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
    position: relative;
    &.on::after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      background-color: #ed592c;
      position: absolute;
      right: -5px;
      top: -5px;
      border-radius: 10px;
    }
  }
  &.condition {
    > li {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      > i {
        margin-left: 6px;
      }
    }
  }
}
