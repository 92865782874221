#CompetitorInterest {
  .interest {
    padding: 30px;
    border-radius: 10px;
  }
  .url {
    color: var(--color-Bright-Blue);
    text-align: center;
    padding: 6px 14px;
    border-radius: 32px;
    border: 1px solid var(--color-Bright-Blue);
    display: inline-block;
  }
  .btn_box {
    text-align: right;
    margin-bottom: 20px;
    > button {
      color: #fff;
      background-color: var(--color-Bright-Blue);
      padding: 10px 18px;
      border-radius: 20px;
      margin-left: 10px;
    }
    .del {
      background-color: var(--color-Alizarin-Crimson);
      color: #fff;
    }
  }
}
