@import "../../../include";

#TutorialTab {
    margin: 22px auto 0 auto;
    padding: 0 11px 0 18px;
    position: relative;
    display: flex;
    align-items: center;
    width: 967px;
    height: 65px;
    @include border-radius(5px);
    background-color: #e9fcff;


    .title {
        margin-right: 25px;
        width: 156px;
        font-size: 22px;
        font-family: Pretendard_extraBold;
        color: #1d334e;
    }

    .desc {
        margin-right: 229px;
        width: 406px;
        font-size: 16px;
        font-family: Pretendard_extraBold;
        color: #343434;
        z-index: 2;
    }

    .btn_tutorial {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 32px;
        font-size: 14px;
        font-family: Pretendard_bold;
        color: #ffffff;
        @include border-radius(16px);
        background-color: #1d334e;
        z-index: 2;
    }

    &:before {
        content : "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../../../assets/images/home/bg_tutorial.png") no-repeat;
        background-position: right;
    }
}
