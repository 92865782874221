#GroupwareBoardDetail {
  margin: auto;
  padding-top: 50px;
  width: 1200px;
  .articles {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
  }

  .field {
    padding: 16px 0;
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #ddd;

    span {
      margin-right: 14px;
      padding-right: 14px;
      display: flex;
      align-items: center;
      width: 70px;
      font-family: Pretendard_bold;
      border-right: 1px solid #ddd;
    }

    input,
    select {
      font-size: 14px;
    }

    input[type="text"] {
      padding: 10px;
      width: 100%;
      border: 1px solid #ddd;
    }

    select {
      width: 100px;
      height: 38px;
    }

    p {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }
  }

  .content {
    padding: 25px 0;
  }

  .btns {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 48px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
    }
  }

  .btn_post {
    background-color: #4593f5;
  }

  .btn_list {
    background-color: #96c719;
  }
}
