@import "src/include";

.MarketInfoCartItem {
  position: relative;
  padding: 5px;
  width: 160px;
  height: 210px;
  border-radius: 5px;
  border: solid 1px #f0f0f0;
  background-color: #fff;

  &_header {
    display: flex;
    justify-content: space-between;
    input[type="checkbox"] {
      display: none;
    }
  }

  img {
    width: 153px;
    height: 110px;
    object-fit: contain;
  }

  h2 {
    font-size: 14px;
    font-family: Pretendard_bold;
    color: #343434;
    line-height: 1.43;
    @include text-ellipsis(2);
    word-break: break-word;
    min-height: 40px;
  }

  p {
    height: 24px;
    font-size: 12px;
    font-family: Pretendard_bold;
    line-height: 24px;
    color: #606060;
    text-align: right;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;

      span {
        font-size: 12px;
        font-family: Pretendard_bold;
        color: #606060;
      }
    }

    .checkbox {
      column-gap: 5px;
      .value {
        font-size: 12px;
        color: var(--color-Web-Orange);
      }
    }
  }
  .chart_edit_btn {
    width: 85px;
    height: 24px;
    font-family: Pretendard_extraBold;
    font-size: 12px;
    color: #4593f5;
    border-radius: 14px;
    border: solid 0.8px #f0f0f0;
    background-color: rgba(240, 240, 240, 0.3);
  }
}
