@import "../../../include";

#TradeMarkDetail {
    margin: auto;
    padding-top: 40px;
    width: 882px;

    > .header {
        margin-bottom: 23px;
        height: 115px;

        .title {
            margin-bottom: 11px;
            font-size: 22px;
        }

        .info {
            position: relative;
            float: left;
            font-size: 16px;

          .manage_no {
              display: flex;
              align-items: center;

              i {
                  margin-left: 10px;
              }
          }
            .select_nation {
               width: 100px;
                padding-left: 10px;
                color: black;
           }
        .state {
            margin-top: 11px;
            position: relative;
            padding: 0 6px;
            min-width: 106px;
            line-height: 24px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            @include border-radius(16px);
            background-color: #00ae02;
            cursor: pointer;

            .icon_more {
                position: absolute;
                top: -4px;
                right: -24px;
                width: 24px;
                height: 24px;
            }
        }
        ul {
            position: absolute;
            top: 80px;
            background-color: #ffffff;
            z-index: 9;

            button {
                display: block;
                width: 120px;
                height: 32px;
                font-size: 12px;
                border: solid 1px #d8d8d8;
                background-color: #ffffff;
            }
            button + button {
                border-top: none;
            }

            button:hover {
                background-color: rgba(0, 149, 174, 0.2);
                transition-duration: 0.5s;
            }
        }
    }

        .btns {
            float: right;
            display: flex;
            position: relative;
            align-items: center;

            button {
                font-size: 16px;
                color: #ffffff;
            }
            .btn_edit {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #96c719;
            }
            .btn_save {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #19c77c;
            }
            .btn_cancle {
                margin-left: 12px;
                width: 64px;
                line-height: 32px;
                background-color: #96c719;
            }
            .btn_add {
                margin-left: 8px;
                width: 120px;
                line-height: 32px;
                background-color: #e92f2c;
            }
            .btn_add_category {
                width: 120px;
                line-height: 32px;
                border: solid 1px #e92f2c;
                color: #000000;
                background-color: #ffffff;
            }
            ul {
                position: absolute;
                right: 0;
                bottom: -34px;
                width: 120px;
            }
        }
    }

    .form.not_download {
        select {
            //margin-right: 25px;
            padding-right: 25px;
            width: 200px;
            height: 32px;
            font-size: 14px;
            white-space: pre-wrap;
        }
        .icon_download {
            display: none;
        }
    }

    .field {
        .header {
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: auto;

            > .title {
                margin: 0;
                display: flex;
                font-size: 22px;

                i {
                    margin-left: 10px;
                }
            }
        }
    }

    .field+.field {
        margin-top: 61px;
    }

    .rows {
        display: flex;
        height: 48px;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 283px;
            height: 46px;
            font-size: 14px;
            border-bottom: solid 1px #e7e7e7;

            select {
                //margin-right: 25px;
                padding-right: 25px;
                width: 200px;
                height: 32px;
                font-size: 14px;
                white-space: pre-wrap;
            }

            .title {
                display: flex;
                align-items: center;
                width: 86px;
                white-space: pre;
                font-family: Pretendard_bold;
            }

            > p {
                //padding-right: 25px;
                @include text-ellipsis(2);
            }

            .btns {
                //padding-right: 15px;
                display: flex;
                align-items: center;

                button {
                    margin-left: 5px;
                }

                button+button {
                    margin-left: 6px;
                }

                p {
                    margin-right: 6px;
                }

                .ToggleButtonWithSpan {
                  padding-right: 0;
                }
            }

            .ToggleButtonWithSpan {
              //padding-right: 15px;
            }

            .small_input {
                width: 80px;
            }
        }

        .row.no_data {
            border-bottom: none;
        }

        .row+.row {
            margin-left: 15px;
        }

        .row.colspan2 {
            justify-content: flex-start;
            width: 66%;
        }
        .thumbnail {
            margin-left: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 283px;
            height: 143px;
            border: solid 1px #979797;

            span {
                opacity: 0.4;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
            }
            img {
                width: 281px;
                height: 141px;
                object-fit: contain;
            }
        }
    }

    input {
        padding: 0 8px;
        width: 135px;
        height: 30px;
        font-size: 14px;
        text-align: left;
        border: solid 1px rgba(0, 0, 0, 0.56);
    }

    select.long {
        width: 100%;
        height: 32px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
    }

    select:invalid{
        opacity: 0.5;
    }

    .ToggleButtonWithSpan {
        span {
            font-size: 14px;
        }

        .not_editing {
            display: none;
        }
    }
}
